import { GridCellProps } from '@progress/kendo-react-grid';
import { SHToolTip } from 'app/components/SHToolTip';
import styled from 'styled-components';

type Props = {
  className?: string;
  e: GridCellProps;
};

const EmailCell = ({ className, e }: Props) => {
  const data = e.dataItem;
  return (
    <td className={className ?? ''}>
      <SHToolTip position="top">
        <EmailWrapper title={data?.email}>{data?.email}</EmailWrapper>
      </SHToolTip>
    </td>
  );
};

export default EmailCell;
const EmailWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  min-width: 150px;
  max-width: 200px;
  color: #0068ff;
`;
