import styled from 'styled-components';
import { TextField } from 'app/components/TextField';
import '@fontsource/roboto';
import React from 'react';
import { useState } from 'react';
import { CheckBox } from 'app/components/CheckBox';
import { BackNextButton } from 'app/components/BackNextButton';
import SHdropdown from 'app/components/SHdropdown';
import { GeneralLookup, RegisterRider } from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';

type Props = {
  onClickBackParent?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onClickNxtParent?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  riderData: RegisterRider;
  setRiderData: React.Dispatch<React.SetStateAction<RegisterRider>>;
  stateData?: GeneralLookup[];
};
const ParentDetails = ({
  onClickBackParent,
  onClickNxtParent,
  riderData,
  setRiderData,
  stateData,
}: Props) => {
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const handleChange = event => {
    const { name, value } = event.target;
    console.log('changed', value);
    setRiderData(prevData => ({
      ...prevData,
      parent: { ...prevData.parent, [name]: value },
    }));
  };

  const [valid, setValid] = useState<boolean>(false);
  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [riderData]);
  const handleCheckbox = e => {
    if (e.value) {
      setRiderData({
        ...riderData,
        parent: {
          ...riderData.parent,
          state: riderData.state,
          street: riderData.street,
          city: riderData.city,
          zip: riderData.zip,
        },
      });
    } else {
      setRiderData({
        ...riderData,
        parent: {
          ...riderData.parent,
          state: {
            id: '',
            name: '',
            code: null,
            children: [],
            otherName: null,
            isAdminLive: false,
          },
          street: '',
          city: '',
          zip: '',
        },
      });
    }
  };
  const handlePhChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
      setRiderData({
        ...riderData,
        parent: {
          ...riderData.parent,
          phone: e.target.value,
        },
      });
    }
  };
  const handleZipChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 9 && /^[a-zA-Z0-9]*$/.test(inputValue)) {
      setRiderData({
        ...riderData,
        parent: {
          ...riderData.parent,
          zip: e.target.value,
        },
      });
    }
  };
  return (
    <MainWrapper className="form-MainWrapper">
      <OverflowWrapper className="OverflowWrapper">
        <TextFieldGrid className="TextFieldGrid">
          <TextFieldWrapper className="TextFieldWrapper">
            <StyledTextField
              className="StyledTextField"
              onChange={handleChange}
              value={riderData?.parent?.firstName!}
              label="Parent/Guardian First Name"
              type="text"
              name="firstName"
              validations={'required|max:30'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper">
            <StyledTextField
              className="StyledTextField"
              onChange={handleChange}
              value={riderData?.parent?.lastName!}
              label="Parent/Guardian Last Name"
              type="text"
              name="lastName"
              validations={'required|max:30'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper">
            <StyledTextField
              className="StyledTextField"
              onChange={handleChange}
              value={riderData?.parent?.email!}
              label="Parent/Guardian Email"
              type="email"
              name="email"
              validations={'required|email'}
              validator={simpleValidator.current}
              maxLength={60}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper">
            <StyledTextField
              className="StyledTextField"
              onChange={handlePhChange}
              value={riderData?.parent?.phone!}
              label="Parent/Guardian Ph#"
              type="tel"
              name="phone"
              validations={'required|numeric|min:10|max:10'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
        <HomeAddressWrapper className="horizontal-line">
          <div className="home-sub-wrapper">
            <p className="home-address">Parent's Home Address</p>
            <CheckBox
              label="(same as rider address)"
              onChange={handleCheckbox}
              checked={riderData.parent.state === riderData.state}
            />
          </div>
        </HomeAddressWrapper>
        <TextFieldGrid className="TextFieldGrid">
          <TextFieldWrapper className="TextFieldWrapper">
            <StyledTextField
              className="StyledTextField"
              onChange={handleChange}
              value={riderData?.parent?.street!}
              label="Street Address"
              type="text"
              name="street"
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper">
            <StyledTextField
              className="StyledTextField"
              onChange={handleChange}
              value={riderData?.parent?.city!}
              label="City"
              type="text"
              name="city"
              validations={'required|max:40'}
              validator={simpleValidator.current}
              maxLength={40}
            />
          </TextFieldWrapper>

          <TextFieldWrapper className="TextFieldWrapper">
            <SHdropdown
              className="drop-down"
              label="State"
              data={stateData}
              onDataChange={e => {
                setRiderData({
                  ...riderData,
                  parent: {
                    ...riderData.parent,
                    state: e,
                  },
                });
              }}
              defaultValue={riderData?.parent.state!}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>

          <TextFieldWrapper className="TextFieldWrapper">
            <StyledTextField
              className="StyledTextField"
              onChange={handleZipChange}
              value={riderData?.parent.zip!}
              label="Zip"
              type="text"
              name="zip"
              validations={'required|alpha_num|min:5|max:9'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
      </OverflowWrapper>
      <ButtonWrapper className="bg-color-change">
        <BackNextButton
          classNameBack="btn-back"
          classNameNxt="btn-nxt"
          onClickNxt={onClickNxtParent}
          onClickBack={onClickBackParent}
          next="Next"
          back="Back"
          disableNext={valid}
        />
      </ButtonWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  color: #9597a6;
  width: 100%;
  height: calc(100vh - 187px);
  position: relative;
  padding: 20px 0px 0px;
  // position: relative;
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    // padding-top: 20px;
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    // padding-top: 20px;
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    // padding-top: 20px;
    label {
      font-size: 14px;
    }
  }
`;
const OverflowWrapper = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
  padding: 20px 60px 100px 60px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 5px;
    background: transparent !important;
    border-radius: 10px;
  }
`;

const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;
const TextFieldWrapper = styled.div`
  width: 50%;
  margin-bottom: 20px;
`;
const HomeAddressWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 12px;
  .home-sub-wrapper {
    display: flex;
    align-items: flex-start;
    > div {
      margin: 0px;
    }
  }
  label {
    color: #e98d04;
    font-style: oblique;
  }

  ::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #585858;
  }

  p {
    margin: 0 10px 0px 0px;
    white-space: nowrap;
    color: #4f4f4f;
  }
`;
const StyledTextField = styled(TextField)`
  /* flex: 0 0 calc(50% - 20px);
  margin: 0px 0px 10px !important; */
`;

const DropdownWrapper = styled.div`
  flex: 0 0 calc(50% - 20px);
  p {
    margin: 0px;
    font-family: 'Roboto';
    font-size: 14px;
  }
  .drop-down {
    margin: 0px;
    width: 100% !important;
    height: 35px;
    svg {
      width: 35px !important;
      height: auto;
      position: absolute;
      top: 20px;
      right: -25px;
    }
  }
`;
const ButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;
  position: absolute;
  bottom: -10px;
  left: 0px;
  z-index: 2;
  background: linear-gradient(
    to bottom,
    rgb(36 36 36 / 27%) 0%,
    rgb(42 42 42) 100%
  );
  border: 1px solid rgb(42 42 42);
  border-radius: 0 0 20px 20px;
  backdrop-filter: blur(5px);

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
  }

  @media (min-width: 576px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;

export default ParentDetails;
