import { GridCellProps } from '@progress/kendo-react-grid';
import { SHToolTip } from 'app/components/SHToolTip';
import styled from 'styled-components';
type Props = {
  className?: string;
  e: GridCellProps;
  onClick?: (e) => void;
};

const DivisionCell = ({ className, e, onClick }: Props) => {
  const data = e?.dataItem;
  return (
    <td className={className ?? ''}>
      <SHToolTip position="top">
        <DivisionWrapper title={data?.classOne}>
          {data?.division}
        </DivisionWrapper>
      </SHToolTip>
      <SHToolTip position="top">
        <DivisionWrapper title={data?.classTwo}>
          {data?.classTwo}
        </DivisionWrapper>
      </SHToolTip>
    </td>
  );
};

export default DivisionCell;
const DivisionWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  color: #475467;
`;
