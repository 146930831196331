import { useErrorHandlingSlice } from 'app/error/slice';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useApi from 'services/mavapi/useApi';
import {
  GeneralLookup,
  ManageTeamService,
  TeamCoach,
  TeamRequest,
} from 'services/openapi';
import { useNavigateToRoute } from 'utils/common';

const useTeamDetailsApi = () => {
  const [loading, setLoading] = useState(false);
  const [, setError] = useState<unknown | null>();
  const [visible, setVisible] = useState(false);
  const [isMainCoach, setIsMainCoach] = useState<boolean>(false);
  const { error: apiError, handleRequest } = useApi();
  const {
    manageTeamGetTeamCoachList,
    manageTeamDeleteTeamCoach,
    manageTeamGetTeamProfile,
    manageTeamAddCoachToTeam,
  } = ManageTeamService;
  const [teamCoachList, setTeamCoachList] = useState<Array<TeamCoach>>();
  const [teamProfileData, setTeamProfileData] = useState<TeamRequest>();
  const [coachList, setCoachList] = useState(false);
  const [coachTypeData, setcoachTypeData] = useState<GeneralLookup | undefined>(
    undefined,
  );
  const [coachNameData, setcoachNameData] = useState<GeneralLookup | undefined>(
    undefined,
  );
  const dispatch = useDispatch();
  const { actions } = useErrorHandlingSlice();
  const redirect = useNavigateToRoute();
  const [teamDataLoading, setTeamDataLoading] = useState(false);
  const fetchManageGetTeamCoaches = (id: string) => {
    setLoading(true);
    handleRequest(manageTeamGetTeamCoachList(id))
      .then(res => {
        if (res) {
          setTeamCoachList(res);
        }
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };

  const getTeamProfileData = (id: string, action?: string) => {
    setTeamDataLoading(true);
    handleRequest(manageTeamGetTeamProfile(id))
      .then(res => {
        if (res) {
          setTeamProfileData(res);
        }
        setTeamDataLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setTeamDataLoading(false);
      });
  };

  const deleteTeamCoach = (id?: string) => {
    setLoading(true);
    handleRequest(manageTeamDeleteTeamCoach(id ?? ''))
      .then(res => {
        if (res) {
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Coach deleted successfully',
              show: true,
              errorType: 'success',
            }),
          );
          setCoachList(!coachList);
        }
        setLoading(false);
      })
      .catch(apiError => {
        setError(apiError);
        setLoading(false);
      });
  };

  //manageTeamAddCoachToTeam
  const addCoachToTeam = (id: string, coachId?: string, isMain?: boolean) => {
    handleRequest(manageTeamAddCoachToTeam(id, coachId, isMain))
      .then(res => {
        if (res === 'Success') {
          setCoachList(!coachList);
          setVisible(false);
          setIsMainCoach(false);
          setcoachTypeData(undefined);
          setcoachNameData(undefined);
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Coach added successfully',
              show: true,
              errorType: 'success',
            }),
          );
        } else {
          setCoachList(!coachList);
          setVisible(false);
          setIsMainCoach(false);
          setcoachTypeData(undefined);
          setcoachNameData(undefined);
          dispatch(
            actions.dispatchToast({
              errorTxt: res.description,
              show: true,
              errorType: 'error',
            }),
          );
        }
      })
      .catch(() => {
        setVisible(false);
        setIsMainCoach(false);
        setError(apiError);
      });
  };

  return {
    fetchManageGetTeamCoaches,
    teamCoachList,
    loading,
    visible,
    setVisible,
    deleteTeamCoach,
    setCoachList,
    coachList,
    getTeamProfileData,
    teamProfileData,
    addCoachToTeam,
    coachTypeData,
    setcoachTypeData,
    coachNameData,
    setcoachNameData,
    isMainCoach,
    setIsMainCoach,
    teamDataLoading,
  };
};
export default useTeamDetailsApi;
