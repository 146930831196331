import { GridCellProps } from '@progress/kendo-react-grid';
import AdminCustomButton from 'app/components/AdminCustomButton';
import { AdminTextField } from 'app/components/AdminTextField';
import { useEffect, useState } from 'react';
import { Sponsor } from 'services/openapi';
import styled from 'styled-components';

type Props = {
  className?: string;
  e: GridCellProps;
  onClick?: (e) => void;
  chosenId: string | undefined;
  onCancelClick: (e) => void;
  onSaveClick: (e) => void;
  sponsorPayload: Sponsor | undefined;
  onSave?: boolean;
};

const EditButtonCell = ({
  className,
  e,
  onClick,
  chosenId,
  onCancelClick,
  onSaveClick,
  sponsorPayload,
  onSave,
}: Props) => {
  return (
    <td className={className ?? ''}>
      {e.dataItem.sponsorId !== chosenId ? (
        <AdminCustomButton onClick={onClick} buttonType={'secondary'}>
          Edit
        </AdminCustomButton>
      ) : (
        <ActionWrapper>
          <AdminCustomButton buttonType="cancel" onClick={onCancelClick}>
            Cancel
          </AdminCustomButton>
          <AdminCustomButton
            buttonType="save"
            onClick={onSaveClick}
            disabled={sponsorPayload?.name === '' && onSave}
          >
            Save
          </AdminCustomButton>
        </ActionWrapper>
      )}
    </td>
  );
};

export default EditButtonCell;
const ActionWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
