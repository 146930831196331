/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Alumni } from '../models/Alumni';
import type { GeneralRider } from '../models/GeneralRider';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AlumniService {

    /**
     * API to Register Alumni
     * @param requestBody
     * @returns string Created
     * @throws ApiError
     */
    public static alumniCreateAlumni(
        requestBody?: Alumni,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/alumni',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get alumni
     * @param seasonId
     * @param alumniId
     * @returns Alumni Success
     * @throws ApiError
     */
    public static alumniGetAlumni(
        seasonId: string,
        alumniId: string,
    ): CancelablePromise<Alumni> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/alumni/{alumniId}/season/{seasonId}',
            path: {
                'seasonId': seasonId,
                'alumniId': alumniId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * API to get Alumni List
     * @param seasonId
     * @returns GeneralRider Success
     * @throws ApiError
     */
    public static alumniGetAlumniList(
        seasonId?: string,
    ): CancelablePromise<Array<GeneralRider>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/alumni/alumniList',
            query: {
                'seasonId': seasonId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * API to upload Alumni Photo
     * @param alumniId
     * @param formData
     * @returns any Created
     * @throws ApiError
     */
    public static alumniUploadTeamPhoto(
        alumniId: string,
        formData?: {
            file?: Blob;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/alumni/{alumniId}/photo',
            path: {
                'alumniId': alumniId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

}
