import styled from 'styled-components';
import { LabelNormal } from '../SHLabel';
import { SHToolTip } from '../SHToolTip';
import UserPlaceholder from '../../assets/icons/user-placeholder.jpeg';

export type AdminProfileInfoProps = {
  name?: string;
  subLabel?: string;
  src?: string;
  className?: string;
  sublabelColor?: string;
};

export const AdminProfileInfo = ({
  name,
  subLabel,
  src,
  className,
  sublabelColor,
}: AdminProfileInfoProps) => {
  return (
    <MainWrapper className={className}>
      <div className="name">
        <div className="avatar profile-img">
          <img alt="default-img" src={src ? src : UserPlaceholder} />
        </div>
        <div className="name-grid">
          <SHToolTip position="top" className="label-test">
            <LabelNormal
              className="name-label"
              style={{ fontWeight: '500' }}
              title={name}
            >
              {name}
            </LabelNormal>
          </SHToolTip>
          {subLabel && (
            <SHToolTip position="top">
              <LabelNormal
                className="sub-name-label"
                style={{ fontWeight: '500' }}
                title={subLabel}
                color={sublabelColor}
              >
                {subLabel}
              </LabelNormal>
            </SHToolTip>
          )}
        </div>
      </div>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  .name {
    font-size: 14px;
    background: none !important;
    display: flex;
    align-items: center;
    gap: 10px;
    white-space: pre-line;
    .name-label,
    .label-test {
      font-family: 'Inter';
      font-size: 13px;
      margin: 0px;
      color: #475467;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      min-width: 150px;
      max-width: 200px;
    }
  }
  .profile-img {
    align-items: center;
    display: flex;
    img {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      background-size: contain;
      object-fit: cover;
    }
  }
  .sub-name-label {
    margin: 0px;
    font-size: 13px;
    color: #8e8e8e;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    min-width: 150px;
    max-width: 200px;
  }
  .name-grid {
    display: grid;
    .tooltip-style {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }
  }
`;
