import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface LoaderProps {
  textWidth?: number;
  textHeight?: number;
  labelWidth?: number;
  labelHeight?: number;
  lineContent?: number;
  isLabel?: boolean;
}

export const FormSkeletonLoader = ({
  textWidth,
  textHeight,
  labelHeight,
  labelWidth,
  lineContent,
  isLabel,
}: LoaderProps) => {
  const lineContentRows = lineContent ? lineContent : 1;
  return (
    <CardWrapper>
      <TextWrapper>
        {isLabel && (
          <Skeleton
            width={labelWidth ? labelWidth : '10%'}
            height={labelHeight ? labelHeight : 20}
            className="label"
          />
        )}
        {Array.from({ length: lineContentRows }).map((_, index) => (
          <Skeleton
            width={textWidth ? textWidth : '100%'}
            height={textHeight ? textHeight : 44}
            className="text-line"
          />
        ))}
      </TextWrapper>
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: top;
  .react-loading-skeleton {
    --base-color: #b7bdbe2b;
    --highlight-color: #f5f5f58f;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  .label {
    width: 100%;
    margin-bottom: 4px;
  }
`;
