import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import '@fontsource/roboto';
import { InfoLabel } from 'app/components/SHLabel';
import SHdropdown from 'app/components/SHdropdown';
import { TextField } from 'app/components/TextField';
import { BackNextButton } from 'app/components/BackNextButton';

import { ApiError, CoachRegistration, GeneralLookup } from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';
import RadioButtonGroup from 'app/components/RadioButtonGroup';
import Cookies from 'js-cookie';

type Props = {
  formNumber?: number;
  setFormNumber?: React.Dispatch<React.SetStateAction<number>>;
  handleNextForm?: (event) => void;
  handleBackForm?: (event) => void;
  coachRegisterPayload: CoachRegistration;
  setCoachRegisterPayload: React.Dispatch<
    React.SetStateAction<CoachRegistration>
  >;
  teamData?: GeneralLookup[];
  stateData?: GeneralLookup[];
  coachDetails: GeneralLookup[];
  isReturningValue: boolean | null;
  handleGetReturningCoachDetails: (coachId: string) => void;
  setCoachDefault: React.Dispatch<React.SetStateAction<GeneralLookup>>;
  coachDefault: GeneralLookup;
  setIsMainCoach: React.Dispatch<React.SetStateAction<boolean | null>>;
  isMainCoach: boolean | null;
};

export const PersonalInfoForm = ({
  formNumber,
  setFormNumber,
  handleNextForm,
  handleBackForm,
  coachRegisterPayload,
  setCoachRegisterPayload,
  teamData,
  stateData,
  coachDetails,
  isReturningValue,
  handleGetReturningCoachDetails,
  setCoachDefault,
  coachDefault,
  setIsMainCoach,
  isMainCoach,
}: Props) => {
  const [valid, setValid] = useState<boolean>(false);

  //Simple validator Ref
  const simpleValidator = React.useRef(new SimpleReactValidator());

  useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(formValid);
  }, [coachRegisterPayload, coachDefault]);

  useEffect(() => {
    setCoachRegisterPayload({
      ...coachRegisterPayload,
    });
  }, [coachDefault]);

  const handleCoachChange = data => {
    if (data) {
      setCoachDefault(data);
      const coachRes = JSON.stringify(data);
      Cookies.set('coachDropdownData', coachRes);
      setCoachRegisterPayload(prevData => ({
        ...prevData,
        coachIdOld: data?.id,
      }));
      handleGetReturningCoachDetails(data?.id);
    }
  };
  console.log(coachRegisterPayload, 'coachRegisterPayloadss');

  const handleZipChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 9 && /^[a-zA-Z0-9]*$/.test(inputValue)) {
      setCoachRegisterPayload({
        ...coachRegisterPayload,
        zip: inputValue,
      });
    }
  };
  const handlePhChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
      setCoachRegisterPayload({
        ...coachRegisterPayload,
        primaryPhone: inputValue,
      });
    }
  };
  const handleEmergencyPhChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
      setCoachRegisterPayload({
        ...coachRegisterPayload,
        emergencyContactPhone: inputValue,
      });
    }
  };
  const handleReturningSelection = event => {
    setCoachRegisterPayload(prevData => ({
      ...prevData,
      isMainCoach: event.value,
    }));
    setIsMainCoach(event.value);
  };
  const handleTeamChange = data => {
    setCoachRegisterPayload({
      ...coachRegisterPayload,
      team: data,
    });
  };
  return (
    <MainWrapper id="PersonalInfoFormMainWrapper">
      <p className="Team-Heading">
        {isReturningValue ? 'Returning Member' : 'New Member'}
      </p>
      {isReturningValue !== null && (
        <>
          <SubMainWrapper className="SubMainWrapper">
            <BorderWrapper className="inner-BorderWrapper">
              <div>
                <InfoLabelWrapper id="InfoLabelWrapper">
                  <div>
                    <InfoLabel>
                      Payment of $70 fee and proof of insurance must be
                      accompanied with this form
                    </InfoLabel>
                  </div>
                </InfoLabelWrapper>
                <>
                  <CoachWrapper>
                    <p>Are you a Main Coach ?</p>
                    <RadioButtonWrapper>
                      <RadioButtonGroup
                        onChange={handleReturningSelection}
                        value={isMainCoach}
                      />
                    </RadioButtonWrapper>
                  </CoachWrapper>
                  <TextFieldGrid>
                    {isReturningValue ? (
                      <>
                        <TextFieldWrapper>
                          <SHdropdown
                            data={coachDetails}
                            onDataChange={handleCoachChange}
                            defaultValue={coachDefault}
                            className="drop-down"
                            label="Coach Name"
                            validations={'required'}
                            validator={simpleValidator.current}
                          />
                        </TextFieldWrapper>
                        <TextFieldWrapper>
                          <StyledTextField
                            onChange={e =>
                              setCoachRegisterPayload({
                                ...coachRegisterPayload,
                                firstName: e.target.value,
                              })
                            }
                            value={coachRegisterPayload?.firstName!}
                            label="First Name"
                            type="text"
                            name="firstName"
                            validations={'required|max:30'}
                            validator={simpleValidator.current}
                          />
                        </TextFieldWrapper>
                        <TextFieldWrapper>
                          <StyledTextField
                            onChange={e =>
                              setCoachRegisterPayload({
                                ...coachRegisterPayload,
                                lastName: e.target.value,
                              })
                            }
                            value={coachRegisterPayload?.lastName!}
                            label="Last Name"
                            type="text"
                            name="lastName"
                            validations={'required|max:30'}
                            validator={simpleValidator.current}
                          />
                        </TextFieldWrapper>
                      </>
                    ) : (
                      <>
                        <TextFieldWrapper>
                          <StyledTextField
                            onChange={e =>
                              setCoachRegisterPayload({
                                ...coachRegisterPayload,
                                firstName: e.target.value,
                              })
                            }
                            value={coachRegisterPayload?.firstName}
                            label="First Name"
                            type="text"
                            name="firstName"
                            validations={'required|max:30'}
                            validator={simpleValidator.current}
                          />
                        </TextFieldWrapper>
                        <TextFieldWrapper>
                          <StyledTextField
                            onChange={e =>
                              setCoachRegisterPayload({
                                ...coachRegisterPayload!,
                                lastName: e?.target?.value!,
                              })
                            }
                            value={coachRegisterPayload?.lastName}
                            label="Last Name"
                            type="text"
                            name="lastName"
                            validations={'required|max:30'}
                            validator={simpleValidator.current}
                          />
                        </TextFieldWrapper>
                      </>
                    )}
                    <TextFieldWrapper>
                      <SHdropdown
                        onDataChange={handleTeamChange}
                        data={teamData}
                        label="Team"
                        defaultValue={coachRegisterPayload.team}
                        validations={'required'}
                        validator={simpleValidator.current}
                      />
                    </TextFieldWrapper>
                  </TextFieldGrid>

                  <MainAddressWrapper>
                    <div className="horizontal-line">
                      <span>Coach Address</span>
                    </div>
                    <TextFieldGrid>
                      <TextFieldWrapper>
                        <StyledTextField
                          onChange={e =>
                            setCoachRegisterPayload({
                              ...coachRegisterPayload,
                              street: e.target.value,
                            })
                          }
                          value={coachRegisterPayload?.street!}
                          label=" Address"
                          type="text"
                          name="Address"
                          validations={'required'}
                          validator={simpleValidator.current}
                        />
                      </TextFieldWrapper>
                      <TextFieldWrapper>
                        <StyledTextField
                          onChange={e =>
                            setCoachRegisterPayload({
                              ...coachRegisterPayload,
                              city: e.target.value,
                            })
                          }
                          value={coachRegisterPayload?.city!}
                          label="City"
                          type="text"
                          name="City"
                          validations={'required|max:40'}
                          validator={simpleValidator.current}
                        />
                      </TextFieldWrapper>
                      <TextFieldWrapper>
                        <SHdropdown
                          onDataChange={data => {
                            if (coachRegisterPayload) {
                              setCoachRegisterPayload({
                                ...coachRegisterPayload,
                                state: data,
                              });
                            }
                          }}
                          data={stateData}
                          label="State"
                          defaultValue={coachRegisterPayload.state}
                          validations={'required'}
                          validator={simpleValidator.current}
                        />
                      </TextFieldWrapper>
                      <TextFieldWrapper>
                        <StyledTextField
                          onChange={handleZipChange}
                          value={coachRegisterPayload?.zip!}
                          label="Zip"
                          type="text"
                          name="Zip"
                          validations={'required|alpha_num|min:5|max:9'}
                          validator={simpleValidator.current}
                        />
                      </TextFieldWrapper>
                      <TextFieldWrapper>
                        <StyledTextField
                          onChange={handlePhChange}
                          value={coachRegisterPayload?.primaryPhone?.replaceAll(
                            /[^\w\d]/g,
                            '',
                          )}
                          label="Phone Number:"
                          type="tel"
                          name="phoneNumber"
                          validations={'required|numeric|min:10|max:10'}
                          validator={simpleValidator.current}
                        />
                      </TextFieldWrapper>
                      <TextFieldWrapper>
                        <StyledTextField
                          onChange={e =>
                            setCoachRegisterPayload({
                              ...coachRegisterPayload,
                              email: e.target.value,
                            })
                          }
                          value={coachRegisterPayload?.email!}
                          label="Email:"
                          type="email"
                          name="email"
                          validations={'required|email'}
                          validator={simpleValidator.current}
                          maxLength={60}
                        />
                      </TextFieldWrapper>
                      <TextFieldWrapper>
                        <StyledTextField
                          onChange={e =>
                            setCoachRegisterPayload({
                              ...coachRegisterPayload,
                              emergencyContactName: e.target.value,
                            })
                          }
                          value={
                            coachRegisterPayload?.emergencyContactName ?? ''
                          }
                          label="Emergency Contact"
                          type="text"
                          name="Emergency Contact"
                          validations={'required'}
                          validator={simpleValidator.current}
                        />
                      </TextFieldWrapper>
                      <TextFieldWrapper>
                        <StyledTextField
                          onChange={handleEmergencyPhChange}
                          value={coachRegisterPayload?.emergencyContactPhone?.replaceAll(
                            /[^\w\d]/g,
                            '',
                          )}
                          label="PH:"
                          type="tel"
                          name="Ph"
                          validations={'required|numeric|min:10|max:10'}
                          validator={simpleValidator.current}
                        />
                      </TextFieldWrapper>
                    </TextFieldGrid>
                  </MainAddressWrapper>
                </>
              </div>
            </BorderWrapper>
          </SubMainWrapper>
          <BottomRightWrapper id="BottomRightWrapper">
            <ButtonWrapper>
              <BackNextButton
                classNameBack="btn-back"
                classNameNxt="btn-nxt"
                onClickNxt={handleNextForm}
                onClickBack={handleBackForm}
                next="Next"
                back="Cancel"
                disableNext={!valid ? true : false}
              />
            </ButtonWrapper>
          </BottomRightWrapper>
        </>
      )}
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  color: #9597a6;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  height: calc(100vh - 206px);
  position: relative;
  .Team-Heading {
    margin: 0px;
    padding: 0px !important;
    text-align: center;
    color: #9597a6;
  }
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
  .first-para,
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .first-para,
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .first-para,
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .first-para,
    label {
      font-size: 14px;
    }
  }
`;
const CoachWrapper = styled.div`
  margin: 0px 12px;
`;
const SubMainWrapper = styled.div`
  height: 100%;
  width: 100%;
`;
const BorderWrapper = styled.div`
  height: calc(100vh - 210px);
  width: 100%;
  > div {
    height: 100%;
    width: 100%;
  }
  overflow: auto;
  border-radius: 20px;
  padding: 20px 20px 100px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: #201e1e !important;
    border-radius: 10px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    padding: 20px 20px 0px 20px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding: 20px 20px 0px 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 20px 20px 0px 20px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding: 20px 20px 0px 20px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 20px 20px 0px 20px;
  }
`;
const InfoLabelWrapper = styled.div`
  padding: 0 15px 10px 15px;
  width: 100%;
  div {
    border-bottom: 1px solid #434343;
    padding: 5px 0;
  }
`;
const RadioButtonWrapper = styled.div`
  display: flex;
  padding-bottom: 3%;
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  .k-radio {
    background-color: transparent;
    border: 1px solid #ced4da;
  }
  .k-radio:checked,
  .k-radio.k-checked {
    border-color: 1px solid #0d6efd;
    color: white;
    background-color: #0d6efd;
  }
  .k-radio-list {
    flex-flow: row-reverse;
  }
  .k-radio-item .k-radio-label {
    color: #ffffff;
  }
  .RadioButtonGroupWrapper {
    margin: 0;
    ul {
      padding: 0 !important;
    }
  }
`;

const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0 70px;

  @media (min-width: 992px) and (max-width: 1199px) {
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
  }
`;
const TextFieldWrapper = styled.div`
  width: 50% !important;
  margin-bottom: 20px;
  &.styled-TextFieldWrapper {
    display: grid;
    align-items: end;
  }

  @media (min-width: 100px) and (max-width: 992px) {
    width: 100% !important;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 50% !important;
  }
`;

const StyledTextField = styled(TextField)`
  flex: 0 0 50%;
  &.styled-field {
    margin-bottom: 0px !important;
  }
`;

const MainAddressWrapper = styled.div`
  margin-bottom: 100px;
  .horizontal-line {
    display: flex;
    align-items: baseline;
    margin: 10px 12px 0px 12px;
  }
  .horizontal-line span {
    margin: 0 10px 0 0;
    white-space: nowrap;
    color: #4f4f4f;
    font-size: 15px;
  }
  .horizontal-line::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #434343;
    height: 3px;
  }
`;
const BottomRightWrapper = styled.div`
  z-index: 1;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 20px 20px;
  backdrop-filter: blur(10px);
  position: absolute;
  bottom: -30px;
  &.bg-color {
    background: rgb(42 42 42) 100%;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    height: 40px;
    &.bg-color-change {
      bottom: 25px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 45px;
    &.bg-color-change {
      bottom: 30px;
      padding-top: 20px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 60px;
    &.bg-color-change {
      bottom: 20px;
      padding-top: 20px;
    }
  }
`;
const ButtonWrapper = styled.div``;
