import { GridCellProps } from '@progress/kendo-react-grid';
import { DeleteIcon } from 'app/assets/icons';
import styled from 'styled-components';

type Props = {
  className?: string;
  e: GridCellProps;
  onViewClick?: (e) => void;
  onDeleteClick?: (item: string) => void;
  isAssigned?: boolean;
  isPastDataFlag?:boolean;
};
const IconCell = ({
  className,
  e,
  onViewClick,
  onDeleteClick,
  isAssigned,
  isPastDataFlag,
}: Props) => {
  const data = e.dataItem; // use later

  return (
    <td className={className ?? ''}>
      <WrapperCard>
       {!isPastDataFlag && <div
          className="action-icon-wrapper"
          onClick={() => onDeleteClick?.(data)}
        >
          <DeleteIcon />
        </div>}
      </WrapperCard>
    </td>
  );
};

// Icon style
export default IconCell;
const WrapperCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  .action-icon-wrapper {
    margin: 0 10px;
    padding: 8px 8px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    :hover {
      background-color: rgb(228, 223, 223);
    }
    margin: 0 10px;
  }
`;
