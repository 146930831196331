import { CommonPageWrapper, HeadingWrapper } from 'admin/components/wrappers';
import CustomBreadCrumb from 'app/components/CustomBreadCrumb';
import { PageMainLabel } from 'app/components/SHLabel';
import BackButton from 'app/components/BackButton';
import styled from 'styled-components';
import AdminCustomButton from 'app/components/AdminCustomButton';
import SimpleReactValidator from 'simple-react-validator';
import React, { useRef, useState } from 'react';
import { UserProfile } from 'services/openapi';
import { DropDownModel } from 'app/components/SHdropdown';
import { CommonLoader } from 'app/components/CommonLoader';
import useCommonLookUpDatas from 'hooks/useCommonLookupData';
import ProfileUpdateForm from './ProfileUpdateForm';
import useUserProfileApi from 'admin/hooks/useUserProfileApi';
import {
  userProfileDefaultData,
  breadCrumpitemsUser,
} from './statiDefaultData';

export const ProfileUpdatePage = () => {
  const simpleValidator = useRef(new SimpleReactValidator());
  const {
    userViewData,
    getUserProfileViewData,
    loading,
    userViewLoading,
    EditUser,
  } = useUserProfileApi();
  const [imageUploadBlobPayload, setImageUploadBlobPayload] =
    useState<Blob | null>(null);
  const [userPayload, setUserPayload] = useState<UserProfile>(
    userProfileDefaultData,
  );
  const [userPayloadDefault, setUserPayloadDefault] = useState<UserProfile>(
    userProfileDefaultData,
  );
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [triggor, setTriggor] = useState(false);
  const [triggorTwo, setTriggorTwo] = useState(false);
  const [userPreDataList, setUserPreDataList] = useState<DropDownModel[]>([]);
  const {
    getAllLookupData,
    lookUps,
    loading: lookUpLoading,
  } = useCommonLookUpDatas();

  const handleSaveClick = () => {
    const isValid = simpleValidator.current.allValid();
    if (isValid) {
      EditUser(userPayload, (imageUploadBlobPayload as Blob) ?? undefined);
    } else {
      simpleValidator.current.showMessages();
    }
    setTriggor(!triggor);
  };
  const handleCancelClick = () => {
    setImgSrc(userViewData?.imageUrl!);
    setUserPayload(userPayloadDefault);
    setTriggorTwo(!triggorTwo);
    simpleValidator.current.hideMessages();
    simpleValidator.current.visibleFields = [];
    setImageUploadBlobPayload(null);
  };
  React.useEffect(() => {
    if (userViewData) {
      setUserPayload(userViewData);
      setUserPayloadDefault(userViewData as UserProfile);
      setImgSrc(userViewData.imageUrl ?? '');
    }
  }, [userViewData]);
  React.useEffect(() => {
    getUserProfileViewData();
  }, []);
  React.useEffect(() => {
    getAllLookupData('STATE');
  }, []);

  return (
    <>
      <CommonPageWrapper>
        {loading && <CommonLoader loaderType="main" />}
        <HeadingWrapper>
          <PageMainLabel>User Information</PageMainLabel>
          <CustomBreadCrumb preData={breadCrumpitemsUser} />
        </HeadingWrapper>
        <ContentWrapper>
          <BtnWrapper>
            <div>
              <BackButton isNavHistory />
            </div>
            <div className="btn-action">
              <AdminCustomButton
                buttonType="cancel"
                onClick={handleCancelClick}
              >
                Cancel
              </AdminCustomButton>
              <AdminCustomButton buttonType="save" onClick={handleSaveClick}>
                Save
              </AdminCustomButton>
            </div>
          </BtnWrapper>
          <ProfileUpdateForm
            simpleValidator={simpleValidator}
            setUserPayload={setUserPayload}
            userPayload={userPayload}
            setImageUploadBlobPayload={setImageUploadBlobPayload}
            setImgSrc={setImgSrc}
            imgSrc={imgSrc}
            setUserPreDataList={setUserPreDataList}
            userPreDataList={userPreDataList}
            loading={userViewLoading || lookUpLoading}
            lookUps={lookUps}
          />
        </ContentWrapper>
      </CommonPageWrapper>
    </>
  );
};
const ContentWrapper = styled.div`
  width: 100%;
  padding: 15px 30px 15px 0;
`;
const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 15px 5px;
  border-bottom: 1px solid #eaecf0;
  .btn-action {
    display: flex;
    align-items: center;
    gap: 15px;
  }
`;
