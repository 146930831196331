import { CardSubWrapper, CardWrapper } from 'admin/components/wrappers';
import styled from 'styled-components';
import { BackNumberCell } from './Cells/BackNumberCell';
import { RiderNameCell } from './Cells/RiderNameCell';
import { TeamNameCell } from './Cells/TeamNameCell';
import { CommonCell } from './Cells/CommonCell';
import { PositionCell } from './Cells/PositionCell';
import { MultipleDataGrid } from 'app/components/MultipleDataGrid/Index';
import { HighSchoolTeam } from 'services/openapi';
import { TableSkeletonLoader } from 'app/components/TableSkeletonLoader';

type Props = { highPointList: HighSchoolTeam | undefined; loading: boolean };
export const HighPointPrJrPage = ({ highPointList, loading }: Props) => {
  const srElementaryPearlRiderCells = [
    {
      field: 'name',
      title: 'Back#',
      cell: e => <BackNumberCell e={e} />,
      width: '50',
    },
    {
      field: 'teamName',
      title: 'Rider Name',
      cell: e => <RiderNameCell e={e} />,
      width: '150',
    },
    {
      field: 'teamId',
      title: 'Team',
      cell: e => <TeamNameCell e={e} />,
      width: '200',
    },
    {
      field: 'name',
      title: 'Ranch Riding',
      cell: e => <CommonCell value={e.dataItem.ranchRiding} />,
      width: '100',
    },
  ];
  const srRankCells = [
    {
      field: 'name',
      title: 'Pattern Hoursemanship',
      cell: e => <CommonCell value={e.dataItem.horsemanship} />,
      width: '100',
    },
    {
      field: 'name',
      title: 'Sum',
      cell: e => <CommonCell value={e.dataItem.sum} />,
      width: '40',
    },
    {
      field: 'name',
      title: 'Rank',
      cell: e => <CommonCell value={e.dataItem.rank} />,
      width: '60',
    },
  ];
  const srPosition = {
    field: 'name',
    title: 'Position',
    cell: e => <PositionCell e={e} />,
    width: '150',
  };
  const jrElementaryPearlRiderCells = [
    {
      field: 'name',
      title: 'Back#',
      cell: e => <BackNumberCell e={e} />,
      width: '50',
    },
    {
      field: 'teamName',
      title: 'Rider Name',
      cell: e => <RiderNameCell e={e} />,
      width: '150',
    },
    {
      field: 'teamId',
      title: 'Team',
      cell: e => <TeamNameCell e={e} />,
      width: '200',
    },
    {
      field: 'name',
      title: 'Reining',
      cell: e => <CommonCell value={e.dataItem.reining} />,
      width: '100',
    },
  ];
  const jrRankCells = [
    {
      field: 'name',
      title: 'Pattern Hoursemanship',
      cell: e => <CommonCell value={e.dataItem.horsemanship} />,
      width: '100',
    },
    {
      field: 'name',
      title: 'Sum',
      cell: e => <CommonCell value={e.dataItem.sum} />,
      width: '40',
    },
    {
      field: 'name',
      title: 'Rank',
      cell: e => <CommonCell value={e.dataItem.rank} />,
      width: '60',
    },
  ];
  const jrPosition = {
    field: 'name',
    title: 'Position',
    cell: e => <PositionCell e={e} />,
    width: '150',
  };
  return (
    <>
      <CardWrapper style={{ paddingTop: '15px' }}>
        <CardSubWrapper className="d-flex"></CardSubWrapper>
        {loading ? (
          <TableSkeletonLoader rowContent={8} width={100} headingWidth={80} />
        ) : (
          <>
            <GridWrapper className="jr-rider-table">
              <MultipleDataGrid
                showPagination={false}
                data={highPointList?.seniorHighRiders!}
                pageSize={4}
                columns1={jrElementaryPearlRiderCells}
                columns2={jrRankCells}
                position={jrPosition}
                isPosition
                colomn1title={'SR Rider Information'}
                colomn2title={'Rank'}
              />
            </GridWrapper>
            <GridWrapper className="sr-rider-table">
              <MultipleDataGrid
                showPagination={false}
                data={highPointList?.juniorHighRiders!}
                pageSize={10}
                columns1={srElementaryPearlRiderCells}
                columns2={srRankCells}
                position={srPosition}
                isPosition
                colomn1title={'JR Rider Information'}
                colomn2title={'Rank'}
              />
            </GridWrapper>
          </>
        )}
      </CardWrapper>
    </>
  );
};
const GridWrapper = styled.div`
  &.jr-rider-table {
    margin-bottom: 60px;
    tr[aria-rowindex='1'] {
      th[aria-colindex='1'] {
        background: #e7ecff;
      }
      th[aria-colindex='8'] {
        padding-bottom: 34px;
      }
      .k-column-title {
        font-size: 14px;
        font-weight: 600;
        line-height: 14.52px;
        color: #000000;
      }
    }
    .k-grid-content {
      max-height: calc(68vh - 362px) !important;
    }
  }
  &.sr-rider-table {
    margin-bottom: 60px;
    tr[aria-rowindex='1'] {
      th[aria-colindex='1'] {
        background: #e7ecff;
      }
      th[aria-colindex='8'] {
        padding-bottom: 34px;
      }
      .k-column-title {
        font-size: 14px;
        font-weight: 600;
        line-height: 14.52px;
        color: #000000;
      }
    }
    .k-grid-content {
      max-height: calc(68vh - 362px) !important;
    }
  }
  .k-grid-header tr th {
    border-top: 1px solid #eaecf0;
  }
  .k-grid-container .k-grid-content tr td:nth-last-child(1) {
    padding: 0px 24px 0px 0px !important;
  }

  .k-grid-content tr td.team-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
`;
