import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from '@progress/kendo-react-buttons';

export type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void; // to handle onClick functions
  children?: React.ReactNode; // make the component able to receive children elements
  buttonType?:
    | 'primary'
    | 'secondary'
    | 'icon'
    | 'edit'
    | 'card'
    | 'nav-icon'
    | 'delete'
    | 'cancel'
    | 'save'
    | 'uploadFile'
    | 'danger'; // two styling options (you can create as many as you want)
  fillMode?: null | 'solid' | 'outline' | 'flat' | 'link';
  disabled?: boolean; // make the button disabled or not
  isActive?: boolean; // change button styles when isActive
  icon?: string;
  className?: string;
  id?: string;
  borderType?: 'primary';
  permissionId?: string;
  svgIcon?: React.ReactNode;
  onBlur?: React.FocusEventHandler<HTMLButtonElement>;
  selected?: boolean;
  wrapperRef?: React.RefObject<HTMLDivElement>;
};
//Reusable ref component
const AdminCustomButton = React.forwardRef<Button, ButtonProps>(
  (
    {
      children,
      fillMode,
      className,
      id,
      disabled,
      buttonType,
      svgIcon,
      isActive,
      selected,
      onClick,
      onBlur,
      wrapperRef,
    }: ButtonProps,
    ref,
  ) => {
    return (
      <WrapperDiv
        ref={wrapperRef}
        className={`${className ?? ''} ${isActive ? 'isActive' : ''}${
          selected ? 'selected' : ''
        }`}
        id={id}
      >
        <Container
          buttonType={buttonType}
          onBlur={onBlur}
          ref={ref} // Forward the ref to the inner button element
          onClick={onClick}
          className={buttonType}
          fillMode={fillMode ? fillMode : 'flat'}
          disabled={disabled}
        >
          {svgIcon ?? ''} {children}
        </Container>
      </WrapperDiv>
    );
  },
);

export default AdminCustomButton;
const WrapperDiv = styled.div`
  button {
    height: 31px;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    box-shadow: none;
    border-radius: 8px;
    min-width: 70px;
    .k-button-text {
      :has(svg) {
        display: flex;
        align-items: center;
      }
    }

    &.delete {
      border: 1px solid red !important;
      border-radius: 4px;
      &:hover {
        background: red !important;
      }
      .k-button-text {
        background: #000000 !important;
        &:hover {
          color: white !important;
        }
      }
    }
  }
  &.bordered-button {
    button {
      width: 80px !important;
      border: 1px solid #4764ff !important;
      background: #ffffff !important;
      color: #000000 !important;
      &:hover {
        background: #4764ff !important;
        color: #ffffff !important;
      }
    }
    .k-button-flat::after {
      box-shadow: none !important;
    }
  }
  &.active-inactive {
    button {
      width: 80px !important;
      border: 1px solid #4764ff !important;
      background: #ffffff !important;
      color: #000000 !important;
      &:hover {
        background: #4764ff !important;
        color: #ffffff !important;
      }
    }
    .k-button-flat::after {
      box-shadow: none !important;
    }
  }
  &.Inactive-button {
    button {
      width: 80px !important;
      border: 1px solid #dfdfdf !important;
      background: #ffffff !important;
      .k-button-text {
        color: #a3a3a3 !important;
      }
    }
    .k-button:hover::before {
      background: #ffffff !important;
    }
    .k-button-flat::after {
      box-shadow: none !important;
    }
  }
  &.filter-button {
    button {
      width: 125px;
      border: 1px solid #dfdfdf !important;
      background-color: #ffffff !important;
      &:hover {
        background: transparent !important;
      }
    }
    .k-button-text {
      color: #1e1e1e !important;
    }
    svg {
      margin-right: 10px;
    }
    .k-button-flat::after {
      box-shadow: none !important;
    }
  }
  &.dropdown-filter-button {
    button {
      width: auto !important;
      border-radius: 6px;
      color: rgb(28 28 28);
      padding: 0 10px !important;
      font-size: 12px;
      text-align: left;
      outline: none !important;
      box-shadow: none !important;
    }
  }
  &.fill-button {
    margin-right: 10px;
    button {
      width: 125px;
      background-color: #4764ff !important;
      color: #ffffff !important;

      &:hover {
        background: #ffffff !important;
        border: 1px solid #4764ff !important;
        color: #1e1e1e !important;
      }
    }
    svg {
      margin-right: 10px;
    }
    .k-button-flat::after {
      box-shadow: none !important;
    }
  }
`;
export const Container = styled(Button)<ButtonProps>`
  &.k-button {
    ${props => props.buttonType && BUTTONTYPE[props.buttonType]}
  }
`;
const BUTTONTYPE = {
  primary: css`
    background: #001fff !important;
    border-color: #001fff !important;
    color: #ffffff !important;
    padding: 0 10px;
    border-radius: 6px;
    &:hover {
      background: #ffffff !important;
      border: 1px solid #4764ff !important;
      color: #1e1e1e !important;
    }
  `,
  secondary: css`
    color: #ffffff;
    border: 1px solid #7f91f3 !important;
    font-weight: 400;
    background-color: #7f91f3 !important;
    border-radius: 6px;
    &:hover {
      background: #ffffff !important;
      border: 1px solid #4764ff !important;
      color: #1e1e1e !important;
      transition: 0.5s;
      svg {
        transition: 0.5s;
        filter: brightness(0.5);
      }
    }
  `,
  cancel: css`
    color: #a3a3a3;
    border: 1px solid #dfdfdf !important;
    font-weight: 500;
    background-color: #ffffff !important;
    border-radius: 8px;
    font-size: 14px;
    padding: 0 15px;
    font-family: 'Inter';
    &:hover {
      background: #ffffff !important;
      border: 1px solid #4764ff !important;
      color: #1e1e1e !important;
      svg {
        filter: brightness(0.5);
      }
    }
  `,
  save: css`
    color: #ffffff;
    border: 1px solid #4764ff !important;
    font-weight: 500;
    background-color: #4764ff !important;
    border-radius: 8px;
    font-size: 14px;
    padding: 0 20px;
    font-family: 'Inter';
    line-height: 17.07px;
    &:hover {
      background: #ffffff !important;
      border: 1px solid #4764ff !important;
      color: #1e1e1e !important;
      svg {
        filter: brightness(0.5);
      }
    }
  `,
  uploadFile: css`
    color: #ffffff;
    border: 1px solid #001fff !important;
    font-weight: 500;
    background-color: #001fff !important;
    border-radius: 8px;
    font-size: 16px;
    font-family: 'Inter';
    padding: 0 20px;
    &:hover {
    }
  `,
  danger: css`
    color: #ffffff;
    background-color: #c15757 !important;
    border: solid 2px #c15757;
    font-weight: 500;
    border-radius: 8px;
    font-size: 16px;
    font-family: 'Inter';
    padding: 0 20px;
    &:hover {
    }
  `,

  icon: css`
    background: none;
    border: none;
    padding: 0px;
  `,
  editPopup: css`
    background: none;
    padding: 0px;
    margin: 0px;
  `,
};
