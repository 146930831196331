/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EWD } from '../models/EWD';
import type { GeneralEWDs } from '../models/GeneralEWDs';
import type { GeneralRider } from '../models/GeneralRider';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EwdService {

    /**
     * API to Register EWD
     * @param requestBody
     * @returns string Created
     * @throws ApiError
     */
    public static ewdCreateEwd(
        requestBody?: EWD,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ewd/EWD',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to search ewd
     * @param seasonId
     * @param backNumber
     * @param name
     * @param categoryId
     * @param divisionId
     * @param teamId
     * @returns EWD Success
     * @throws ApiError
     */
    public static ewdSearchEwd(
        seasonId?: string,
        backNumber?: string,
        name?: string,
        categoryId?: string,
        divisionId?: string,
        teamId?: string,
    ): CancelablePromise<Array<EWD>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ewd/EWD',
            query: {
                'seasonId': seasonId,
                'backNumber': backNumber,
                'name': name,
                'categoryId': categoryId,
                'divisionId': divisionId,
                'teamId': teamId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * API to create EWD diagnosis
     * @param requestBody
     * @returns string Created
     * @throws ApiError
     */
    public static ewdCreateEwdDiagnosis(
        requestBody?: GeneralEWDs,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ewd/diagnosis',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get diagnosis
     * @param seasonId
     * @param riderId
     * @returns GeneralEWDs Success
     * @throws ApiError
     */
    public static ewdSearchEwdDiagnosis(
        seasonId?: string,
        riderId?: string,
    ): CancelablePromise<GeneralEWDs> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ewd/ewdDiagnosis',
            query: {
                'seasonId': seasonId,
                'riderId': riderId,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get EWD List
     * @param seasonId
     * @returns GeneralRider Success
     * @throws ApiError
     */
    public static ewdGetEwdList(
        seasonId?: string,
    ): CancelablePromise<Array<GeneralRider>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ewd/ewdList',
            query: {
                'seasonId': seasonId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * API to upload EWD Photo
     * @param ewdId
     * @param formData
     * @returns any Created
     * @throws ApiError
     */
    public static ewdUploadEwdPhoto(
        ewdId: string,
        formData?: {
            file?: Blob;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/ewd/{ewdId}/photo',
            path: {
                'ewdId': ewdId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param formData
     * @returns any Created
     * @throws ApiError
     */
    public static ewdUploadDiagnosisData(
        formData?: {
            File: Blob;
            ApplicationId: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/ewd/upload/diagnosisForm',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

}
