import * as React from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import ChangePasswordIcon from './assets/img/change-password.png';
import LogoutIcon from './assets/img/logout.png';

interface ChildrenProps {
  setShowProfileDropDown: React.Dispatch<React.SetStateAction<boolean>>;
  menuRef: React.MutableRefObject<null>;
  onChangePassword: (e: any) => void;
  isCoachLogin?: boolean;
}

export function ProfileDropdown({
  setShowProfileDropDown,
  menuRef,
  onChangePassword,
}: ChildrenProps) {
  const navigate = useNavigate();
  const handleLogOut = () => {
    Cookies.remove('coachUserId');
    Cookies.remove('coachUserName');
    Cookies.remove('coachUserRole');
    Cookies.remove('coachAccessToken');
    navigate('/login');
    Cookies.remove('userId');
    Cookies.remove('userName');
    Cookies.remove('userRole');
    Cookies.remove('sifID');
    Cookies.remove('joinedDate');
    Cookies.remove('paymentCancel');
    Cookies.remove('JoinedDate');
    Cookies.remove('coachLoginRes');
  };
  const handleChangePassword = e => {
    onChangePassword?.(e);
  };
  return (
    <Wrapper ref={menuRef}>
      <ProfileWrapper>
        {/* <FloatLabel className="profile-label">
          <img alt="" />
          Profile
        </FloatLabel> */}
        <FloatLabel className="profile-label" onClick={handleChangePassword}>
          <img src={ChangePasswordIcon} alt="change-password" />
          Change Password
        </FloatLabel>
        <FloatLabel className="profile-label" onClick={handleLogOut}>
          <img src={LogoutIcon} alt="logout" />
          Logout
        </FloatLabel>
      </ProfileWrapper>
    </Wrapper>
  );
}
export const FloatLabel = styled.span`
  font-weight: 400;
  font-size: 11.5px;
  text-transform: capitalize;
  display: block;
  line-height: unset;
  position: relative;
  margin: 0 0 -5px 0;
  z-index: 1;
`;
const Wrapper = styled.div`
  position: absolute;
  border: 1px solid #dddddd;
  padding: 15px 15px;
  background: white;
  border-radius: 10px 1px 10px 10px;
  top: 60px;
  right: 38px;
  width: 200px;
  text-align: center;
  z-index: 1030;
  &::before {
    content: '';
    position: absolute;
    height: 20px;
    width: 20px;
    background-size: contain;
    right: -2px;
    top: -20px;
    z-index: 0;
  }
  &.wrapper-light {
    &::before {
    }
  }
  &.wrapper-dark {
    &::before {
    }
    label {
      img {
        -webkit-filter: invert(0.6) contrast(500%);
      }
    }
  }
`;

const ProfileWrapper = styled.div`
  & > {
    .profile-label {
      padding: 8px 10px;
      cursor: pointer;
      margin: 0;
      text-align: left;
      font-size: 14px;
      display: flex;
      align-items: center;
      & > {
        img {
          width: 15px;
          margin: 0 10px 0 0;
          max-height: 15px;
        }
      }
    }
  }
`;
