import { GridCellProps } from '@progress/kendo-react-grid';
import StatusChip from 'app/components/StatusChip';
import { GeneralLookup } from 'services/openapi';

type Props = {
  classname?: string;
  e: GridCellProps;
  lookup: GeneralLookup[];
  onRowClick?: () => void;
};
enum StatusId {
  Active = 'bc1e49f9-0f4a-4222-ba22-c4f4af0b4f75',
  ActiveNotPaid = 'KCBJACBJA',
  Inactive = 'f7f4cf58-a6fd-4646-9e25-d9517f92709e',
}

export const StatusCell = ({ classname, e, onRowClick }: Props) => {
  const data = e?.dataItem?.status;
  const getStatusType = () => {
    let type =
      data?.id === StatusId.Active
        ? 'active-paid'
        : data?.id === StatusId.ActiveNotPaid //feature holded
        ? 'active-not-paid'
        : data?.id === StatusId.Inactive
        ? 'inactive'
        : '';
    return type;
  };

  return (
    <td className={classname ?? ''}>
      <StatusChip StatusType={getStatusType()} text={data?.name ?? ''} />
    </td>
  );
};
