import { GridCellProps } from '@progress/kendo-react-grid';
import { AdminTextField } from 'app/components/AdminTextField';
import { SHToolTip } from 'app/components/SHToolTip';
import { SponsorList } from 'services/openapi';
import styled from 'styled-components';

type Props = {
  className?: string;
  e: GridCellProps;
  onClick?: (e) => void;
  setSponserName: React.Dispatch<React.SetStateAction<string>>;
  sponserName: string;
  disabled?: boolean | undefined;
  setSponserDataArray: React.Dispatch<
    React.SetStateAction<SponsorList[] | undefined>
  >;
  sponserDataArray: SponsorList[] | undefined;
  chosenId: string | undefined;
  handleSponsorName: (e: any, event: any) => void;
};

const SponserCell = ({ className, e, chosenId, handleSponsorName }: Props) => {
  const data = e.dataItem.sponsorName;

  const handleDisabled = () => {
    return e.dataItem.sponsorId !== chosenId;
  };

  const handleOnChange = (e, event) => {
    handleSponsorName(e, event);
  };

  return (
    <td className={className ?? ''}>
      {chosenId === e.dataItem.sponsorId ? (
        <AdminTextField
          onChange={event => {
            handleOnChange(e, event);
          }}
          value={data}
          disabled={handleDisabled()}
          autoFocus={true}
        ></AdminTextField>
      ) : (
        <SHToolTip position="top">
          <CustomDiv title={data} className="custom-cell-style">
            {data}
          </CustomDiv>
        </SHToolTip>
      )}
    </td>
  );
};

export default SponserCell;
const CustomDiv = styled.div`
  height: 48px;
  line-height: 48px !important;
`;
