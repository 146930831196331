import { GridCellProps } from '@progress/kendo-react-grid';
import AdminCustomButton from 'app/components/AdminCustomButton';
import { AdminTextField } from 'app/components/AdminTextField';
import { SHToolTip } from 'app/components/SHToolTip';
import React from 'react';
import { RiderPoint, SponsorList } from 'services/openapi';
import styled from 'styled-components';

type Props = {
  className?: string;
  e: GridCellProps;
  onClick?: (e) => void;
  setSponserName?: React.Dispatch<React.SetStateAction<string>>;
  sponserName?: string;
  disabled?: boolean | undefined;
  setSponserDataArray?: React.Dispatch<
    React.SetStateAction<SponsorList[] | undefined>
  >;
  sponserDataArray?: SponsorList[] | undefined;
  setShowRiderId: React.Dispatch<React.SetStateAction<string | undefined>>;
  showRiderId: string | undefined;
  onSaveClick: (requestBody: RiderPoint) => void;
};

export const ShowsScoreActionCell = ({
  className,
  e,
  setShowRiderId,
  showRiderId,
  onSaveClick,
}: Props) => {
  let dataDafault = e.dataItem;
  let teamPoint =
    e.dataItem.totalPoints === null ? null : e.dataItem.totalPoints;
  const [data, setData] = React.useState<number | null>(teamPoint);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const handleOnChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 5 && /^\d*$/.test(inputValue)) {
      let value = parseFloat(inputValue);
      setData(inputValue === '' ? null : value);
    }
  };
  React.useEffect(() => {
    if (showRiderId && e.dataItem.showRiderId === showRiderId) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [showRiderId]);
  return (
    <td className={className ?? ''}>
      <MainWrapper>
        {isEdit ? (
          <AdminTextField
            onChange={e => handleOnChange(e)}
            value={data === null || data === 0 ? '' : data}
            autoFocus={true}
            type="number"
            onKeyDown={e => {
              if (['e', 'E', '+', '-'].includes(e.key)) {
                e.preventDefault();
              }
            }}
          ></AdminTextField>
        ) : (
          <SHToolTip position="top">
            <CustomDiv className="custom-cell-style">
              {data === null || data === 0 ? '-' : data}
            </CustomDiv>
          </SHToolTip>
        )}
        {!isEdit ? (
          <AdminCustomButton
            onClick={() => {
              setIsEdit(true);
              setShowRiderId(e.dataItem.showRiderId);
            }}
            buttonType={'secondary'}
          >
            Edit
          </AdminCustomButton>
        ) : (
          <ActionWrapper>
            <AdminCustomButton
              buttonType="cancel"
              onClick={() => {
                setIsEdit(false);
                setData(teamPoint);
                setShowRiderId(undefined);
              }}
            >
              Cancel
            </AdminCustomButton>
            <AdminCustomButton
              buttonType="save"
              onClick={() => {
                let payload: RiderPoint = {
                  showRiderId: dataDafault.showRiderId,
                  statsDate: dataDafault.showDate,
                  totalPoints: data ?? undefined,
                  teamTransferStatus: false,
                };

                setIsEdit(false);
                onSaveClick(payload);
                setShowRiderId(undefined);
              }}
            >
              Save
            </AdminCustomButton>
          </ActionWrapper>
        )}
      </MainWrapper>
    </td>
  );
};

const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const CustomDiv = styled.div`
  height: 48px;
  line-height: 48px !important;
  overflow: hidden;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const ActionWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding-left: 12px;
`;
