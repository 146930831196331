import React, { useState } from 'react';
import styled from 'styled-components';
import '@fontsource/oswald';
import '@fontsource/roboto';
import { ShowyedaLogo } from 'app/components/ShowyedaLogo';
import { Outlet } from 'react-router-dom';
import logo from './assets/img/bg/header.png';
// import horseBg from './assets/img/bg/horse.png';
import horseBg from './assets/img/bg/AlumniRetunring.jpg';
import { useLocation } from 'react-router-dom';
import '@fontsource/arimo';

type Props = {};

const AlumniLayout = (props: Props) => {
  var currentUrl = '/alumni-registration/returning-member';
  const location = useLocation();
  console.log(location, 'my location');

  return (
    <MainWrapper>
      <LeftWrapper className="LeftWrapper">
        <ShowyedaLogo />
        <div className="left-header-block">
          {/* <p>
            <span className="note-bold-text"> R2.02 D</span> A RUSH FEE $25 WILL
            BE ADDED TO THE MEMBERSHIP IF A RIDER IS REQUESTING TO ENTER A SHOW
            AFTER THE SHOW CLOSING DATE AND THEIR MEMBERSHIP IS NOT CURRENTLY
            SUBMITTED AND IN THE DATABASE.
          </p> */}
        </div>
      </LeftWrapper>
      <RightWrapper className="RightWrapper">
        <BorderWrapper className="BorderWrapper">
          <TopWrapper className="TopWrapper">
            <ToplabelWrapper>
              <h3 className="toplabel-header">
                {location.pathname === currentUrl
                  ? 'Alumni Registration Form 2023-2024 '
                  : 'Alumni Registration Form 2023-2024'}
              </h3>
            </ToplabelWrapper>
          </TopWrapper>
          <Outlet />
        </BorderWrapper>
      </RightWrapper>
    </MainWrapper>
  );
};

const LeftWrapper = styled.div`
  width: 36%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: url(${horseBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  .left-header-block {
    background: black;
    opacity: 0.7;
    padding: 0 20px;
    position: absolute;
    top: 30%;
  }

  p {
    color: #898989;
    font-size: 18px;
    font-family: 'Oswald';
    text-align: justify;

    .note-bold-text {
      font-size: 18px;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
  span {
    color: #ffffff;
    font-family: 'Oswald';
    font-size: 14px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 20px 20px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    padding: 40px 28px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    padding: 60px 35px;
  }
`;

const RightWrapper = styled.div`
  width: 64%;
  height: 100%;
  background: #2a2a2a;
  padding: 30px 35px 20px;
  p {
    color: #9597a6;
    font-family: 'Roboto';
    font-size: 14px;
  }

  @media (min-width: 100px) and (max-width: 319px) {
    padding: 30px 15px 20px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding: 30px 15px 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 30px 15px 20px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding: 30px 15px 20px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 30px 15px 20px;
  }
`;
const BorderWrapper = styled.div`
  height: 100%;
  border: 1px solid #585858;
  border-radius: 20px;
  /* padding: 20px 60px 10px; */
  /* display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center; */
  position: relative;
  /* overflow: hidden; */
`;

const TopWrapper = styled.div`
  /* background: #2a2a2a; */
  padding: 0px 10px;
  position: absolute;
  margin: 0 auto;
  width: 100%;
  top: -18px;
  /* position: absolute;
  top: -12px;
  left: 230px; */
  h3 {
    font-weight: 500;
    color: #ffffff;
    margin: 0px !important;
    font-family: 'Roboto';
    text-align: center;
  }
`;
const ToplabelWrapper = styled.div`
  position: relative;
  margin: 0 0 0 0;
  background: none;
  display: flex;
  justify-content: center;
  h3 {
    display: inline-grid;
    background: #2a2a2a !important;
    font-weight: 500;
    color: #ffffff;
    padding-top: 10px;
    margin: 0px !important;
    font-family: 'Roboto';
    font-family: 'Arimo' !important;
    font-size: 22px;
    text-align: center;
    padding: 0 15px;
  }
  .insurance-span {
    font-size: 13px !important;
    letter-spacing: 0.2px;
    font-family: 'Arimo' !important;
    line-height: 25px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .insurance-span {
      font-size: 10px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .insurance-span {
      font-size: 9px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .insurance-span {
      font-size: 10px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .insurance-span {
      font-size: 11px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    /* padding: 15px 40px 0px; */
    h3 {
      font-size: 16px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    padding: 20px 60px 0px;
    h3 {
      font-size: 17px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    padding: 20px 80px 0px;
    h3 {
      font-size: 18px;
    }
  }
`;
const MainWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  //Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    ${LeftWrapper} {
      display: none;
    }
    .TopWrapper {
      top: -8px;
      .toplabel-header {
        font-size: 12px;
      }
    }
    ${RightWrapper} {
      width: 100%;
      padding: 30px 15px 20px 15px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    ${LeftWrapper} {
      display: none;
    }
    ${RightWrapper} {
      width: 100%;
      padding: 30px 15px 20px 15px;
    }
    .TopWrapper {
      top: -10px;
      .toplabel-header {
        font-size: 14px;
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    ${LeftWrapper} {
      display: none;
    }
    ${RightWrapper} {
      width: 100%;
      padding: 30px 15px 20px 15px;
    }

    .TopWrapper {
      top: -10px;
      .toplabel-header {
        font-size: 14px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    ${LeftWrapper} {
      .left-header-block {
        span,
        p {
          font-size: 12px;
        }
      }
    }

    .TopWrapper {
      top: -10px;
      .toplabel-header {
        font-size: 18px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    ${LeftWrapper} {
      .left-header-block {
        span,
        p {
          font-size: 14px;
        }
      }
    }
    .TopWrapper {
      top: -10px;
      .toplabel-header {
        font-size: 19px;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    ${LeftWrapper} {
      .left-header-block {
        span,
        p {
          font-size: 15px;
        }
      }
    }
    .TopWrapper {
      top: -30px;
      .toplabel-header {
        font-size: 20px;
      }
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    ${LeftWrapper} {
      .left-header-block {
        span,
        p {
          font-size: 16px;
        }
      }
    }

    .TopWrapper {
      top: -30px;
      .toplabel-header {
        font-size: 21px;
      }
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    ${LeftWrapper} {
      .left-header-block {
        span,
        p {
          font-size: 17px;
        }
      }
    }

    .TopWrapper {
      top: -10px;
      .toplabel-header {
        font-size: 23px;
      }
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    ${LeftWrapper} {
      .left-header-block {
        span,
        p {
          font-size: 19px;
        }
      }
    }

    .TopWrapper {
      top: -10px;
      .toplabel-header {
        font-size: 25px;
      }
    }
  }
`;

export default AlumniLayout;
