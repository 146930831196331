import React from 'react';
import styled from 'styled-components';
import '@fontsource/oswald';
import '@fontsource/roboto';
import infoIcon from './assets/img/icon/info-icon.png';
import infoHoverIcon from './assets/img/icon/info-hover.png';

type Props = {
  onClick?: React.MouseEventHandler<HTMLImageElement> | undefined;
  className?: string | undefined;
};

const InfoIconButton = ({ onClick, className }: Props) => {
  const [urL, setUrl] = React.useState<string>(infoIcon);
  return (
    <MainWrapper
      className={className}
      onMouseEnter={() => setUrl(infoHoverIcon)}
      onMouseLeave={() => setUrl(infoIcon)}
      onClick={onClick}
    >
      <img src={urL} alt="info-icon" />
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  border: 1px solid #606060;
  border-radius: 6px;
  width: 10%;
  min-width: 40px;
  max-width: 50px;
  height: 35px;
  min-height: 3px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (min-width: 100px) and (max-width: 575px) {
    height: 30px;
  }
`;
export default InfoIconButton;
