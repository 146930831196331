export const breadCrumpitemsTeams = [
  {
    id: '',
    text: 'Showyeda admin',
    disabled: true,
  },
  {
    id: '/admin/teams',
    text: 'Teams',
  },
  {
    id: '',
    text: 'Profile Info',
    disabled: true,
  },
];

export const financialResInitialData = {
  name: '',
  phone: '',
  street: '',
  city: '',
  state: { id: '', name: '', children: null },
  zip: '',
};
