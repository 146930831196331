import { useErrorHandlingSlice } from 'app/error/slice';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useApi from 'services/mavapi/useApi';
import {
  ManageCoachService,
  CoachListWrapper,
  AdminCoachInfo,
  GeneralLookup,
  ChangeCoachActiveStatusRequest,
  Coach,
} from 'services/openapi';
import { useNavigateToRoute } from 'utils/common';
const useCoachApi = () => {
  const [loading, setLoading] = useState(false);
  const [coachDataLoading,setCoachDataLoading] = useState(false)
  const [, setError] = useState<unknown | null>();
  const { error: apiError, handleRequest } = useApi();
  const { action } = useParams();
  const redirect = useNavigateToRoute();
  const {
    manageCoachGetCoaches,
    manageCoachGetCoachInfo,
    manageCoachCreateOrUpdateCoach,
    manageCoachUploadCertificateOfInsurance,
    manageCoachUploadCoachPhoto,
     manageCoachMarkCoachActiveOrInactive,
    manageCoachDeleteCoach,
  } = ManageCoachService;
  const [coachProfileData, setCoachProfileData] = useState<CoachListWrapper>();
  const [coachViewData, setCoachViewData] = useState<AdminCoachInfo>();
  const { actions } = useErrorHandlingSlice();
  const dispatch = useDispatch();
  const fetchManageGetCoaches = (
    season?: string,
    searchInput?: string,
    status?: string,
    sortBy?: string,
    sortOrder?: number,
    pageNumber: number = 0,
    pageSize: number = 10,
  ) => {
    setLoading(true);
    let pageNumCall = pageNumber ? pageNumber / 10 + 1 : 1;
    handleRequest(
      manageCoachGetCoaches(
        season,
        searchInput,
        status,
        sortBy,
        sortOrder,
        pageNumCall,
        pageSize,
      ),
    )
      .then(res => {
        if (res) {
          setCoachProfileData(res);
        }
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };
  const deleteCoachList = (
    id?: string,
    season?: string,
    searchInput?: string,
    status?: string,
    sortBy?: string,
    sortOrder?: number,
    pageNumber: number = 0,
    pageSize: number = 10,
  ) => {
    setLoading(true);
    handleRequest(manageCoachDeleteCoach(id ?? ''))
      .then(res => {
        if (res) {
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Coach deleted successfully',
              show: true,
              errorType: 'success',
            }),
          );
          fetchManageGetCoaches(
            season,
            searchInput,
            status,
            sortBy,
            sortOrder,
            pageNumber,
            pageSize,
          );
        }
        setLoading(false);
      })
      .catch(apiError => {
        setError(apiError);
        setLoading(false);
      });
  };

  const getCoachProfileViewData = (id: string) => {
    setCoachDataLoading(true);
    handleRequest(manageCoachGetCoachInfo(id))
      .then(res => {
        if (res) {
          setCoachViewData(res);
        }
        setCoachDataLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setCoachDataLoading(false);
      });
  };
const CoachActiveOrInactive = (
  coachId: string,
  season?: string,
  requestBody?: ChangeCoachActiveStatusRequest,
  searchInput?: string,
  status?: string,
  sortBy?: string,
  sortOrder?: number,
  pageNumber: number = 0,
  pageSize: number = 10,
  item?: string,
) => {
  setLoading(true);
  handleRequest(manageCoachMarkCoachActiveOrInactive(coachId, requestBody))
    .then(res => {
      if (res) {
        fetchManageGetCoaches(
          season,
          searchInput,
          status,
          sortBy,
          sortOrder,
          pageNumber,
          pageSize,
        );
        if (item === 'Make active') {
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Successfully Active',
              show: true,
              errorType: 'success',
            }),
          );
        } else {
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Successfully Inactive',
              show: true,
              errorType: 'success',
            }),
          );
        }
      }
      setLoading(false);
    })
    .catch(() => {
      setError(apiError);
      setLoading(false);
    });
};

  const updateOrCreateCoach = (
    requestBody: Coach,
    imageFile?: Blob,
    insurenceFile?: Blob,
  ) => {
    setLoading(true);
    handleRequest(manageCoachCreateOrUpdateCoach(requestBody))
      .then(res => {
        if (res.code === 'EmailAlreadyInUse') {
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Email is already in use',
              show: true,
              errorType: 'error',
            }),
          );
        } else {
          if (insurenceFile) {
            let formData = {
              certificateOfInsurance: insurenceFile,
            };
            uploadCertificateOfInsurance(
              requestBody?.id === null ? res?.id : requestBody?.id,
              formData,
            );
          }
          if (imageFile) {
            let formData = {
              file: imageFile,
            };
            updateCoachImage(
              requestBody?.id === null ? res?.id : requestBody?.id,
              formData,
            );
          }
          if (action === 'update') {
            dispatch(
              actions.dispatchToast({
                errorTxt: 'Coach Updated Successfully',
                show: true,
                errorType: 'success',
              }),
            );
            redirect(`/admin/coaches/view/${requestBody?.id}`);
          }
          if (action === 'create') {
            dispatch(
              actions.dispatchToast({
                errorTxt: 'Coach Added Successfully',
                show: true,
                errorType: 'success',
              }),
            );
            redirect(`/admin/coaches/view/${res?.id}`);
          }
        }
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };

  const uploadCertificateOfInsurance = (
    id: string,
    formData?: {
      certificateOfInsurance?: Blob;
    },
  ) => {
    setLoading(true);
    handleRequest(manageCoachUploadCertificateOfInsurance(id, formData))
      .then(res => {
        setLoading(false);
        redirect(`/admin/coaches/view/${id}`);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };

  const updateCoachImage = (
    id: string,
    formData?: {
      file?: Blob;
    },
  ) => {
    setLoading(true);
    handleRequest(manageCoachUploadCoachPhoto(id, formData))
      .then(res => {
        setLoading(false);
        redirect(`/admin/coaches/view/${id}`);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };

  return {
    loading,
    coachProfileData,
    fetchManageGetCoaches,
    deleteCoachList,
    getCoachProfileViewData,
    coachViewData,
    CoachActiveOrInactive,
    updateOrCreateCoach,
    coachDataLoading,
  };
};
export default useCoachApi;


