import React, { useState, useEffect, useRef } from 'react';
import {
  Calendar,
  CalendarChangeEvent,
  CalendarCell,
  CalendarCellProps,
} from '@progress/kendo-react-dateinputs';
import styled from 'styled-components';

const CustomCalendar = props => {
  const { onChange, value, max, min } = props;
  const [showCalendar, setShowCalendar] = useState(true);
  const calendarRef = useRef<HTMLDivElement>(null);

  const handleDateChange = event => {
    onChange({
      value: event.target.value,
      syntheticEvent: event.syntheticEvent,
    });
    setShowCalendar(false);
  };

  const handleClickOutside = event => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Wrapper ref={calendarRef}>
      {showCalendar && (
        <Calendar
          className="custom-datepicker"
          value={props.value}
          onChange={handleDateChange}
          navigation={false}
          max={max}
          min={min}
        />
      )}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  .k-calendar-infinite .k-calendar-view {
    height: 316px !important;
  }
  .custom-datepicker {
    background-color: #ffffff !important;
    border-radius: 4px;
    font-family: 'Lato';
    .k-content {
      background: #ffffff !important;
    }
  }
  .k-calendar-infinite .k-calendar-view::after {
    box-shadow: 0 0 38px 19px rgb(255 255 255) !important;
  }
  .k-button {
    color: #212529;
    font-family: 'Lato';
    &:hover {
      background: #eeeeee !important;
    }
  }

  .k-calendar .k-calendar-th {
    color: #6c757d;
  }
  .k-calendar .k-link {
    color: #212529;
    font-family: 'Lato';
    &:hover {
      background: #eeeeee !important;
    }
  }
  .k-calendar .k-calendar-td.k-selected .k-link {
    &:hover {
      color: #0d6efd !important;
    }
  }
  .k-button-text {
    /* color: #0d6efd ; */
  }
  .k-calendar-nav-today {
    color: #0d6efd ;
  }
`;

export default CustomCalendar;
