import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActionsBar,
  DialogCloseEvent,
  DialogProps,
} from '@progress/kendo-react-dialogs';
import styled from 'styled-components';
import { FormSecondaryLabel, InfoLabel } from 'app/components/SHLabel';
import RadioButtonGroup from 'app/components/RadioButtonGroup';
import { RadioGroupChangeEvent } from '@progress/kendo-react-inputs';
import '@fontsource/arimo';
import { TextField } from '../TextField';
import SHButton from '../SHButton';
import { ShowyedaLogo } from '../ShowyedaLogo';
import bgImg from './assets/Img/bg-fogot_password.png';
import { AuthService, UserService, ApiError } from 'services/openapi';
import useApi from 'services/mavapi/useApi';
import Cookies from 'js-cookie';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useToast from 'hooks/Toast';
import isApiError from 'utils/isApiError';
import { useDispatch } from 'react-redux';
import { useErrorHandlingSlice } from 'app/error/slice';

type Props = {
  onClose?: (event: DialogCloseEvent) => void;
  show?: boolean;
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
  onChange?: (event: RadioGroupChangeEvent) => void;
  page?: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
};

const PasswordResetEmail = ({
  onClose,
  show,
  setShow,
  onChange,
  page,
  setPage,
}: Props) => {
  const { userChangePassword } = UserService;
  const { authForgotPassword, authResetPassword } = AuthService;
  const { error: apiError, handleRequest } = useApi();
  const navigate = useNavigate();
  const [changePassword, setChangePassword] = useState<{
    CurrentPassword: string;
    NewPassword: string;
  }>({
    CurrentPassword: '',
    NewPassword: '',
  });
  const dispatch = useDispatch();

  const { actions } = useErrorHandlingSlice();
  const [emailId, setEmailId] = useState({ Email: '' });
  const [confirmPassword, setConfirmPassword] = useState({
    ConfirmPassword: '',
  });
  const [passwordError, setPasswordErr] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [isvalueOld, setIsvalueOld] = useState(false);
  const [isvalueNew, setIsvalueNew] = useState(false);
  const [isvalueConfirm, setIsvalueConfirm] = useState(false);
  const [showResetPage, setShowResetPage] = useState(false);
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const token = searchParams.get('token');
  const [, setError] = React.useState<ApiError | unknown>();

  const toggleDialog = e => {
    if (show) {
      setShow?.(!show);
    } else if (showResetPage) {
      setShowResetPage?.(!showResetPage);
      navigate('/login');
    }
  };
  const handleEmailChange = event => {
    setIsDisabled(event.target.value.trim());
    setEmailId({ ...emailId, Email: event.target.value });
  };

  const handleContinueClick = event => {
    const bearerToken = Cookies.get('accessToken');
    let formData = {
      Email: emailId,
      bearerToken,
    };
    handleRequest(
      authForgotPassword(emailId)
        .then(res => {
          if (res) {
            dispatch(
              actions.dispatchToast({
                errorTxt: 'Mail sent successfully',
                show: true,
                errorType: 'success',
              }),
            );
            Cookies.set('emailid', emailId.Email);
            console.log(emailId.Email, 'emailId.Email');
            setShow?.(!show);
          }
        })
        .catch(error => {
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Mail could not be sent',
              show: true,
              errorType: 'error',
            }),
          );
        }),
    );
  };
  useEffect(() => {
    if (email && token) {
      setShowResetPage(true);
    }
  }, []);
  // useEffect(() => {
  //   const currentLocation = window.location.pathname;
  //   if (currentLocation.includes('/reset-password')) {
  //     const params = new URLSearchParams(window.location.search);
  //     const email = params.get('email');
  //     const tokenParam = params.get('token');
  //     console.log(email, tokenParam, 'tokenParam');

  //     if (email && tokenParam) {
  //       setResetEmail(email);
  //       setResetToken(tokenParam);
  //       setPasswordRestPage(true);
  //       setShowResetPage(true);
  //     }
  //   } else {
  //     setPasswordRestPage(false);
  //   }
  // }, []);
  const newPasswordChangeSubmit = () => {
    if (token && email) {
      const decodedToken = decodeURIComponent(token);
      handleRequest(
        authResetPassword({
          Email: email,
          Password: changePassword.NewPassword,
          Token: decodedToken,
        }),
      )
        .then(res => {
          if (res && !isApiError(res)) {
            dispatch(
              actions.dispatchToast({
                errorTxt: 'Password changed successfully',
                show: true,
                errorType: 'success',
              }),
            );
            navigate('/login');
          } else {
            dispatch(
              actions.dispatchToast({
                errorTxt: res.description,
                show: true,
                errorType: 'error',
              }),
            );
          }
        })
        .catch(() => {
          setError(apiError);
        });
    } else {
      dispatch(
        actions.dispatchToast({
          errorTxt: 'Email or token is invalid',
          show: true,
          errorType: 'error',
        }),
      );
    }
  };
  const handleOldChange = event => {
    if (page === 3) {
      setChangePassword({
        ...changePassword,
        CurrentPassword: event.target.value,
      });
      setIsvalueOld(event.target.value.trim());
    }
  };
  const handleNewChange = event => {
    const passwordInputValue = event.target.value.trim();
    setIsvalueNew(passwordInputValue);
    setChangePassword({ ...changePassword, NewPassword: passwordInputValue });

    //for password
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{8,}/;
    const passwordLength = passwordInputValue.length;
    const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
    const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
    const digitsPassword = digitsRegExp.test(passwordInputValue);
    const specialCharPassword = specialCharRegExp.test(passwordInputValue);
    const minLengthPassword = minLengthRegExp.test(passwordInputValue);
    let errMsg = '';

    if (passwordLength === 0) {
      errMsg = 'Password is empty';
    } else if (!uppercasePassword) {
      errMsg = 'At least one Uppercase required';
    } else if (!lowercasePassword) {
      errMsg = 'At least one Lowercase required';
    } else if (!digitsPassword) {
      errMsg = 'At least one numeric value required';
    } else if (!specialCharPassword) {
      errMsg = 'At least one Special Character required';
    } else if (!minLengthPassword) {
      errMsg = 'At least 8 characters required';
    } else {
      errMsg = '';
      if ((errMsg = '')) {
        setIsDisabled(true);
      }
    }
    setPasswordErr(errMsg);
  };
  const handleConfirmChange = event => {
    const passwordInputValue = event.target.value.trim();
    setIsvalueConfirm(passwordInputValue);
    setConfirmPassword({
      ...confirmPassword,
      ConfirmPassword: passwordInputValue,
    });
  };
  console.log(isDisabled, 'isDisabled');
  useEffect(() => {
    if (confirmPassword.ConfirmPassword !== changePassword.NewPassword) {
      setConfirmPasswordError('Entered Password is not same as New Password');
      setIsDisabled(true);
    } else {
      setConfirmPasswordError('');
      setIsDisabled(false);
    }
  }, [confirmPassword, changePassword]);
  const handleSubmitPassword = () => {
    const bearerToken = Cookies.get('accessToken');
    if (page === 3) {
      let formData = {
        CurrentPassword: changePassword.CurrentPassword,
        NewPassword: changePassword.NewPassword,
        bearerToken,
      };

      handleRequest(
        userChangePassword(formData)
          .then(res => {
            console.log(res, 'sssss');
            if (res == true) {
              navigate('/login');
              setShow?.(false);
              dispatch(
                actions.dispatchToast({
                  errorTxt: 'Password changed successfully',
                  show: true,
                  errorType: 'success',
                }),
              );
            }
          })
          .catch(error => {
            dispatch(
              actions.dispatchToast({
                errorTxt: 'Old Password is incorrect',
                show: true,
                errorType: 'error',
              }),
            );
          }),
      );
    }
  };
  return (
    <>
      {show && (
        <StyledDialog onClose={toggleDialog}>
          <CloseIcon className="CloseIcon" onClick={toggleDialog}>
            <span className="k-icon k-i-close"></span>
          </CloseIcon>
          <ContentWrapper className="ContentWrapper">
            {page === 1 && (
              <>
                <ShowyedaLogo />
                <TextWrapper>
                  <ParaWrapper>
                    Enter the email address associated with your account and
                    we'll send you a link to reset your password.
                  </ParaWrapper>
                  <StyledTextField
                    label="Email"
                    type="email"
                    className="StyledTextField-One"
                    onChange={handleEmailChange}
                  />
                  <SHButton
                    buttonType="primary"
                    className="Btn-One"
                    onClick={handleContinueClick}
                    disabled={!isDisabled}
                  >
                    Continue
                  </SHButton>
                </TextWrapper>
              </>
            )}

            {page === 3 && (
              <>
                <ResetTitleWrapper className="ResetTitleWrapper">
                  <h3>Create Password</h3>
                </ResetTitleWrapper>
                <StyledTextField
                  label="Old Password"
                  type="password"
                  className="StyledTextField-Two"
                  onChange={handleOldChange}
                />
                <SubWrapper>
                  <StyledTextField
                    label="New Password"
                    type="password"
                    className="StyledTextField-Two"
                    onChange={handleNewChange}
                  />
                  <p className="error-msg">{passwordError}</p>
                </SubWrapper>
                <SubWrapper>
                  <StyledTextField
                    label="Confirm Password"
                    type="password"
                    className="StyledTextField-Two"
                    onChange={handleConfirmChange}
                  />
                  <p className="error-msg">{confirmPasswordError}</p>
                </SubWrapper>
                <SHButton
                  buttonType="primary"
                  className="Btn-Two"
                  onClick={handleSubmitPassword}
                  disabled={
                    isDisabled || !isvalueOld || !isvalueNew || !isvalueConfirm
                  }
                >
                  Submit
                </SHButton>
              </>
            )}
          </ContentWrapper>
        </StyledDialog>
      )}

      {showResetPage && (
        <StyledDialog onClose={toggleDialog}>
          <CloseIcon className="CloseIcon" onClick={toggleDialog}>
            <span className="k-icon k-i-close"></span>
          </CloseIcon>
          <ContentWrapper className="ContentWrapper">
            <ResetTitleWrapper className="ResetTitleWrapper">
              <h3>Create Password</h3>
            </ResetTitleWrapper>
            <SubWrapper>
              <StyledTextField
                label="Password"
                type="password"
                className="StyledTextField-Two"
                onChange={handleNewChange}
              />
              <p className="error-msg">{passwordError}</p>
            </SubWrapper>
            <SubWrapper>
              <StyledTextField
                label="Confirm Password"
                type="password"
                className="StyledTextField-Two"
                onChange={handleConfirmChange}
              />
              <p className="error-msg">{confirmPasswordError}</p>
            </SubWrapper>
            <SHButton
              buttonType="primary"
              className="Btn-Two"
              onClick={newPasswordChangeSubmit}
              disabled={isDisabled}
            >
              Submit
            </SHButton>
          </ContentWrapper>
        </StyledDialog>
      )}
    </>
  );
};
const StyledDialog = styled(Dialog)<DialogProps>`
  backdrop-filter: blur(8px);
  &.k-dialog-wrapper {
    z-index: 100 !important;
  }
  .k-overlay {
    background: url(${bgImg});
    opacity: unset;
    z-index: 100 !important;
  }
  .k-window.k-dialog {
    border: none !important;
    max-height: 80% !important;
    min-height: 50% !important;
    background: transparent !important;
    width: 50%;
    z-index: 100 !important;
  }
  .k-dialog-content {
    background: transparent !important;
    border-radius: 13px !important;
    position: relative;
  }
  .button-wrapper {
    width: 100%;
    padding: 10px 0px 0px 10px;

    .k-button {
      max-width: unset !important;
      width: 100%;
    }
  }

  @media (min-width: 100px) and (max-width: 319px) {
    .k-window.k-dialog {
      width: 95%;
    }
    .ResetTitleWrapper {
      h3 {
        font-size: 12px !important;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .k-window.k-dialog {
      width: 85%;
    }
    .ResetTitleWrapper {
      h3 {
        font-size: 14px !important;
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .k-window.k-dialog {
      width: 65%;
    }
    .ResetTitleWrapper {
      h3 {
        font-size: 15px !important;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .k-window.k-dialog {
      width: 50%;
    }
    .ResetTitleWrapper {
      h3 {
        font-size: 15px !important;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .k-window.k-dialog {
      width: 45%;
    }
    .Btn-Two {
      padding-top: 35px;
    }
    .ResetTitleWrapper {
      h3 {
        font-size: 15px !important;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .k-window.k-dialog {
      width: 40%;
    }
    .Btn-Two {
      padding-top: 40px;
    }
    .ResetTitleWrapper {
      h3 {
        font-size: 16px !important;
      }
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .k-window.k-dialog {
      width: 35%;
    }
    .Btn-Two {
      padding-top: 45px;
    }
    .ResetTitleWrapper {
      h3 {
        font-size: 16px !important;
      }
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    .k-window.k-dialog {
      width: 30%;
    }
    .Btn-Two {
      padding-top: 50px;
    }
    .ResetTitleWrapper {
      h3 {
        font-size: 15px !important;
      }
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    .k-window.k-dialog {
      width: 25%;
    }
    .Btn-Two {
      padding-top: 40px;
    }
    .ResetTitleWrapper {
      h3 {
        font-size: 16px !important;
      }
    }
  }
`;
const ResetTitleWrapper = styled.div`
  width: 100%;
  text-align: start;
  margin-bottom: 15px;
  padding: 5px 0;
  border-bottom: 1px solid #5d5c5c;

  h3 {
    margin: 0px;
    color: #ffffff;
    font-weight: 300;
    font-family: 'Roboto';
  }
`;
const TextWrapper = styled.div`
  padding: 0px 25px;
  width: 100%;
`;
const SubWrapper = styled.div`
  width: 100%;
  .error-msg {
    color: #f44336;
    padding: 0 12px;
    font-size: 12px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .error-msg {
      font-size: 12px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .error-msg {
      font-size: 12px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .error-msg {
      font-size: 12px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .error-msg {
      font-size: 13px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .error-msg {
      font-size: 13px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .error-msg {
      font-size: 14px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .error-msg {
      font-size: 16px;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    .error-msg {
      font-size: 17px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    .error-msg {
      font-size: 17px;
    }
  }
`;

const ParaWrapper = styled.p`
  color: #ff9900;
  @media (min-width: 100px) and (max-width: 319px) {
    font-size: 10px !important;
    padding: 5px 0px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    font-size: 11px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 12px !important;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 13px !important;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 14px !important;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    font-size: 14px !important;
    padding: 25px 0px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    font-size: 15px !important;
    padding: 25px 0px;
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    font-size: 16px !important;
    padding: 25px 0px;
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    font-size: 17px !important;
    padding: 25px 0px;
  }
`;
const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  background: #27292d !important;
  border-radius: 10px;
  padding: 25px 25px 40px;
  @media (min-width: 100px) and (max-width: 575) {
    padding: 25px 10px 30px;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    padding: 25px 15px 40px;
  }
  @media (min-width: 992px) and (max-width: 1365px) {
    padding: 25px 30px 40px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    padding: 25px 40px 40px;
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    padding: 25px 50px 50px;
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    padding: 25px 55px 50px;
  }
`;

const CloseIcon = styled.div`
  color: #5a5a5a;
  position: absolute;
  right: 4px;
  top: 3px;
  background: #262626;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid #5a5a5a;
  cursor: pointer;
`;
const StyledTextField = styled(TextField)`
  width: 100%;
  &.StyledTextField-One {
    margin: 30px 0px !important;
    padding-left: 10px;
  }
  &.StyledTextField-Two {
    margin: 10px 0px !important;
    padding-left: 10px;
  }
  input {
    width: 100% !important;
    border: 1px solid #434343;
    &.back-number {
      &.green-border {
        border: 1px solid green !important;
      }
      &.red-border {
        border: 1px solid red !important;
      }
    }
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
  .label {
    color: #9597a6;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    &.StyledTextField-Two {
      margin: 15px 0px !important;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    &.StyledTextField-Two {
      margin: 15px 0px !important;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    &.StyledTextField-Two {
      margin: 20px 0px !important;
    }
  }
`;
export default PasswordResetEmail;
