import React from 'react';
import styled from 'styled-components';
import { BackNextButton } from 'app/components/BackNextButton';
import RadioButtonGroup from 'app/components/RadioButtonGroup';
import { GeneralLookup, RegisterRider } from 'services/openapi';
import SHdropdown from 'app/components/SHdropdown';
import { prevRadioModel } from 'app/pages/RiderRegistrationPage/components/NotReturningRider';

type Props = {
  onClickPreviousNxt?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onClickBackPreviousBack?:
    | React.MouseEventHandler<HTMLButtonElement>
    | undefined;
  previousData: {
    seasonList: GeneralLookup;
    category: GeneralLookup;
    division: GeneralLookup;
  };
  previousEnrolRadioValues: prevRadioModel;
};

const PreviousEnrollmentReturning = ({
  onClickPreviousNxt,
  onClickBackPreviousBack,
  previousData,
  previousEnrolRadioValues,
}: Props) => {
  return (
    <MainWrapper className="form-MainWrapper">
      <OverflowWrapper className="OverflowWrapper">
        <p className="heading-para">2022-2023 data</p>
        <p className="para">
          Please verify your below data and inform discrepancies if any
        </p>
        <TextFieldGrid className="TextFieldGrid">
          <DropdownWrapper className="TextFieldWrapper ">
            <SHdropdown
              className="drop-down"
              label="Previous season list"
              defaultValue={previousData.seasonList}
              disabled
            />
          </DropdownWrapper>
          <DropdownWrapper className="TextFieldWrapper ">
            <SHdropdown
              className="drop-down"
              label="Competed Category"
              defaultValue={previousData.category}
              disabled
            />
          </DropdownWrapper>
          <DropdownWrapper className="TextFieldWrapper ">
            <SHdropdown
              className="drop-down"
              label="Competed Division"
              defaultValue={previousData.division}
              disabled
            />
          </DropdownWrapper>
        </TextFieldGrid>
        <ContentWrapper>
          <RadioWrapper>
            <p className="radiobtn-title">
              Did you finish in the top 3 point earning value in your division
              in 2023-2024 show season?
            </p>
            <RadioButtonWrapper>
              <RadioButtonGroup
                value={previousEnrolRadioValues.achievedTopThreeInDivision}
                disabled
              />
            </RadioButtonWrapper>
          </RadioWrapper>
          <RadioWrapper>
            <p className="radiobtn-title">
              Did you finish 1st or 2nd in any 2023 national individual
              championship class?
            </p>
            <RadioButtonWrapper>
              <RadioButtonGroup
                disabled
                value={
                  previousEnrolRadioValues.achievedTopTwoInIndividualNationals
                }
              />
            </RadioButtonWrapper>
          </RadioWrapper>
          <FormWrapper>
            <p className="para-one">Information</p>
            <p className="para-two">
              Answering “YES” to EITHER of the above 2 questions will
              automatically move you up to the next division. When answering the
              below placement questions any new points or titles earned prior to
              the start of the new YEDA season must be disclosed. If moving from
              Junior High to Senior High a lateral move is considered the next
              division.
            </p>
          </FormWrapper>
          <RadioWrapper className="radio-three">
            <p className="radiobtn-title">
              Does the division you have selected comply with section 29,
              individual point procedures & requirements in the current rulebook
              as summarized below? If no, then the rider shall move up to the
              next division.
            </p>
            <RadioButtonWrapper>
              <RadioButtonGroup
                disabled
                value={
                  previousEnrolRadioValues.divisionCompliesWithSectionThirty
                }
              />
            </RadioButtonWrapper>
          </RadioWrapper>
          <RadioWrapper className="radio-three">
            <p className="radiobtn-title">
              Have you competed in the same division for two seasons?
            </p>
            <RadioButtonWrapper>
              <RadioButtonGroup
                disabled
                value={
                  previousEnrolRadioValues.hasStayedInSameDivisionForTwoYears
                }
              />
            </RadioButtonWrapper>
          </RadioWrapper>
          <FormWrapper>
            <p className="para-one">Information</p>
            <p className="para-two">
              Summary of Section 29 Individual Point Procedures & Requirements
              in the Rulebook: Riders have 2 years of eligibility in each
              division within the category and must move up to the higher
              division once they have used all their years of eligibility
              regardless of points earned. With the initial sign-up student
              riders can remain in (or be seeded in) that division for 2 years
              regardless of any type of show experience, points earned or
              National titles during their membership in YEDA. See rulebook for
              more details. If you have competed in “recognized shows” you may
              be asked to submit a printed copy of your competition/points
              records along with this form. For YEDA purposes, “recognized
              shows” includes AQHA, NRHA, NSBA, APHA, PtHA, ApHC, IBHA, ABRA &
              POA, USEF, and any other association that keeps national records.
            </p>
          </FormWrapper>
        </ContentWrapper>
        <NxtButtonWrapper className="bg-color-change">
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={onClickPreviousNxt}
            onClickBack={onClickBackPreviousBack}
            disableNext={
              previousEnrolRadioValues.hasStayedInSameDivisionForTwoYears ===
                null ||
              previousEnrolRadioValues.achievedTopTwoInIndividualNationals ===
                null ||
              previousEnrolRadioValues.achievedTopThreeInDivision === null ||
              previousEnrolRadioValues.divisionCompliesWithSectionThirty ===
                null
            }
            next="Next"
            back="Back"
          />
        </NxtButtonWrapper>
      </OverflowWrapper>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  height: calc(100vh - 210px);
  position: relative;
  width: 100%;
  @media (min-width: 100px) and (max-width: 319px) {
    .form-MainWrapper {
      padding-top: 10px;
      .OverflowWrapper {
        padding: 20px 12px 100px 12px;
        .radiobtn-title {
          font-size: 11px;
        }
      }
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .form-MainWrapper {
      padding-top: 10px;
      .OverflowWrapper {
        padding: 20px 12px 100px 12px;
        .radiobtn-title {
          font-size: 11px;
        }
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
  @media (min-width: 992px) and (max-width: 1199px) {
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
  }
`;
const OverflowWrapper = styled.div`
  height: 100%;
  overflow: auto;
  padding: 15px 0px 100px;
  .heading-para {
    margin: 0px;
    font-size: 13px;
  }
  .para {
    color: #ff9900;
    margin: 5px 0px 25px;
    border-bottom: 1px solid #5d5c5c;
    font-size: 12px;
  }
  .radiobtn-title {
    font-size: 11px;
  }
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 5px;
    background: transparent !important;
    border-radius: 10px;
  }
  .overflow-header-para {
    color: #a7a7a7;
    font-size: 12px;
  }
`;
const DropdownWrapper = styled.div`
  width: 50%;
  margin: 20px 0px;
  .drop-down {
    height: 35px;
    color: #9597a6;
  }
  .label {
    margin: 5px 0px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    padding-bottom: 20px;
    width: 70%;
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    padding-bottom: 50px;
    width: 50%;
  }
`;
const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;
const RadioButtonWrapper = styled.div`
  display: flex;
  gap: 40px;
  padding-bottom: 20px;
  label {
    color: #9597a6;
    font-family: 'Roboto';
    font-size: 12px;
  }
  .k-radio {
    background-color: transparent;
    border: 1px solid #ced4da;
  }
  .k-radio:checked,
  .k-radio.k-checked {
    border-color: 1px solid #0d6efd;
    color: white;
    background-color: #0d6efd;
  }
  .k-radio-list {
    flex-flow: row-reverse;
    justify-content: flex-end;
    gap: 50px;
  }
  .k-radio-item,
  .k-radio-list-item {
    color: #9597a6;
    gap: 10px;
  }
`;

const RadioWrapper = styled.div`
  &.radio-three {
    margin-top: 30px;
  }
`;
const ContentWrapper = styled.div`
  margin-top: 20px;
`;
const FormWrapper = styled.div`
  width: 100%;
  border: 1px solid #585858;
  border-radius: 7px;
  padding: 0px 20px 20px 20px;
  background: #27292d;
  border: 1px solid #5e5e5e;
  margin-bottom: 10px;
  p {
    font-family: 'Roboto';
  }
  .para-one {
    color: #ff9900;
    font-size: 13px;
    border-bottom: 1px solid #585858;
  }
  .para-two {
    padding-bottom: 10px;
    font-size: 13px;
  }
  span {
    color: #306fdb;
  }
`;

const NxtButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    z-index: 2;
    bottom: -13px;
    left: 0px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;

export default PreviousEnrollmentReturning;
