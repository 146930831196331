import { useErrorHandlingSlice } from 'app/error/slice';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useApi from 'services/mavapi/useApi';
import { ReportService, RiderPointSummary } from 'services/openapi';

const useRiderReportsApi = () => {
  const [loading, setLoading] = useState(false);
  const { reportGetRiderPointReportDetails, reportGetRiderPointReport } =
    ReportService;
  const [, setError] = useState<unknown | null>();
  const { error: apiError, handleRequest } = useApi();
  const [riderReportData, setRiderReportData] = useState<RiderPointSummary>();
  const { actions } = useErrorHandlingSlice();
  const dispatch = useDispatch();
  const getRiderReports = (
    seasonId: string,
    categoryId: string,
    divisionId: string,
    classId: string,
    pageNumber: number = 0,
    pageSize: number = 10,
  ) => {
    setLoading(true);
    let pageNumCall = pageNumber ? pageNumber / 10 + 1 : 1;
    handleRequest(
      reportGetRiderPointReportDetails(
        seasonId,
        categoryId,
        divisionId,
        classId,
        pageNumCall,
        pageSize,
      ),
    )
      .then(res => {
        if (res) {
          setRiderReportData(res);
        }
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };

  const downloadRiderPoints = (
    seasonId: string,
    categoryId: string,
    divisionId: string,
    classId: string,
  ): Promise<string> => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      handleRequest(
        reportGetRiderPointReport(seasonId, categoryId, divisionId, classId),
      )
        .then(res => {
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Rider Points Report Downloaded Successfully',
              show: true,
              errorType: 'success',
            }),
          );
          resolve(res);
          setLoading(false);
        })
        .catch(() => {
          reject(apiError);
          setLoading(false);
        });
    });
  };

  return {
    getRiderReports,
    riderReportData,
    setRiderReportData,
    loading,
    downloadRiderPoints,
  };
};
export default useRiderReportsApi;
