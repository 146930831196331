/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GeneralLookup } from '../models/GeneralLookup';
import type { RiderInfo } from '../models/RiderInfo';
import type { RiderWrapper } from '../models/RiderWrapper';
import type { TeamCoach } from '../models/TeamCoach';
import type { TeamCoachInfo } from '../models/TeamCoachInfo';
import type { TeamRequest } from '../models/TeamRequest';
import type { TeamsWrapper } from '../models/TeamsWrapper';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ManageTeamService {

    /**
     * API to get team profile.
     * @param id
     * @returns TeamRequest Success
     * @throws ApiError
     */
    public static manageTeamGetTeamProfile(
        id: string,
    ): CancelablePromise<TeamRequest> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/team/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to Delete Teams
     * @param id
     * @returns string Success
     * @throws ApiError
     */
    public static manageTeamDeleteTeam(
        id: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/admin/team/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to Get Team coach list
     * @param id
     * @returns TeamCoach Success
     * @throws ApiError
     */
    public static manageTeamGetTeamCoachList(
        id: string,
    ): CancelablePromise<Array<TeamCoach>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/team/{id}/coaches',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get Team list
     * @param searchInput
     * @param status
     * @param seasonId
     * @param sortBy
     * @param sortOrder
     * @param pageNumber
     * @param pageSize
     * @returns TeamsWrapper Success
     * @throws ApiError
     */
    public static manageTeamGetTeams(
        searchInput?: string,
        status?: string,
        seasonId?: string,
        sortBy?: string,
        sortOrder?: number,
        pageNumber: number = 1,
        pageSize: number = 10,
    ): CancelablePromise<Array<TeamsWrapper>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/teams',
            query: {
                'searchInput': searchInput,
                'status': status,
                'seasonId': seasonId,
                'sortBy': sortBy,
                'sortOrder': sortOrder,
                'pageNumber': pageNumber,
                'pageSize': pageSize,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get team coach info.
     * @param id
     * @returns TeamCoachInfo Success
     * @throws ApiError
     */
    public static manageTeamGetTeamCoachInfo(
        id: string,
    ): CancelablePromise<TeamCoachInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/team/coach/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to delete team coach
     * @param id
     * @returns string Success
     * @throws ApiError
     */
    public static manageTeamDeleteTeamCoach(
        id: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/admin/team/coach/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to change team active/inactive
     * @param id
     * @param activeStatusId
     * @param seasonId
     * @returns string Success
     * @throws ApiError
     */
    public static manageTeamMakeTeamActiveOrInactive(
        id: string,
        activeStatusId?: string,
        seasonId?: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/team/{id}/status',
            path: {
                'id': id,
            },
            query: {
                'activeStatusId': activeStatusId,
                'seasonId': seasonId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns string Success
     * @throws ApiError
     */
    public static manageTeamDeleteRiderInfo(
        id: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/admin/team/rider/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to Get Rider Info
     * @param id
     * @returns RiderInfo Success
     * @throws ApiError
     */
    public static manageTeamGetRiderInfo(
        id: string,
    ): CancelablePromise<Array<RiderInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/team/rider/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to update Rider Status
     * @param id
     * @param status
     * @returns string Success
     * @throws ApiError
     */
    public static manageTeamUpdateRiderStatus(
        id: string,
        status?: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/team/rider/{id}/status',
            path: {
                'id': id,
            },
            query: {
                'status': status,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to add coach to team.
     * @param id
     * @param coachId
     * @param isMain
     * @returns string Success
     * @throws ApiError
     */
    public static manageTeamAddCoachToTeam(
        id: string,
        coachId?: string,
        isMain?: boolean,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/team/{id}/addCoach',
            path: {
                'id': id,
            },
            query: {
                'coachId': coachId,
                'isMain': isMain,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to create team
     * @param requestBody
     * @returns string Created
     * @throws ApiError
     */
    public static manageTeamCreateOrEditTeam(
        requestBody?: TeamRequest,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/team',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to upload Rider Photo
     * @param id
     * @param formData
     * @returns any Created
     * @throws ApiError
     */
    public static manageTeamUploadRiderImage(
        id: string,
        formData?: {
            file?: Blob;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/team/rider/{id}/photo',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @param formData
     * @returns any Created
     * @throws ApiError
     */
    public static manageTeamUploadTeamImage(
        id: string,
        formData?: {
            file?: Blob;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/team/{id}/photo',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to list all coach
     * @param isAssistant
     * @returns GeneralLookup Success
     * @throws ApiError
     */
    public static manageTeamGetAllCoach(
        isAssistant?: boolean,
    ): CancelablePromise<Array<GeneralLookup>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/coachList',
            query: {
                'isAssistant': isAssistant,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get team rider list
     * @param id
     * @param searchInput
     * @param season
     * @param status
     * @param sortBy
     * @param sortOrder
     * @param pageNumber
     * @param pageSize
     * @returns RiderWrapper Success
     * @throws ApiError
     */
    public static manageTeamGetRiders(
        id: string,
        searchInput?: string,
        season?: string,
        status?: string,
        sortBy?: string,
        sortOrder?: number,
        pageNumber: number = 1,
        pageSize: number = 10,
    ): CancelablePromise<Array<RiderWrapper>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/team/{id}/riders',
            path: {
                'id': id,
            },
            query: {
                'searchInput': searchInput,
                'season': season,
                'status': status,
                'sortBy': sortBy,
                'sortOrder': sortOrder,
                'pageNumber': pageNumber,
                'pageSize': pageSize,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

}
