import React, { useState } from 'react';
import styled from 'styled-components';
import '@fontsource/oswald';
import horseBg from './assets/img/bg/riderReturning.jpg';
import '@fontsource/roboto';
import { ShowyedaLogo } from 'app/components/ShowyedaLogo';
import { Outlet } from 'react-router-dom';

type Props = {};

const RiderRegistrationPlacementLayout = (props: Props) => {
  return (
    <MainWrapper>
      <LeftWrapper className="LeftWrapper">
        <ShowyedaLogo />
        <TextWrapper className="left-header-block">
          <p>Note:</p>
          <ol>
            <li>
              A Student must be enrolled in school full-time at the time of any
              YEDA Competition. Proper documentation must be on file prior to
              the first competition of each academic term.
            </li>
            <li>
              If you consider yourself a lower-level rider than this form
              indications, please attach an explanation page.
            </li>
            <li>
              For those students coming to YEDA from another team riding
              national organization that places their riders in divisions based
              on experience those students must compete in the equivalent
              division equal or greater than that division they have completed.
            </li>
          </ol>
        </TextWrapper>
      </LeftWrapper>
      <RightWrapper className="RightWrapper">
        <BorderWrapper className="BorderWrapper">
          <TopWrapper className="TopWrapper">
            <h3 className="toplabel-header">
              YEDA Individual Placement Form Rule
            </h3>
          </TopWrapper>
          <Outlet />
        </BorderWrapper>
      </RightWrapper>
    </MainWrapper>
  );
};

const LeftWrapper = styled.div`
  width: 36%;
  /* background: #1b1b1b; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 35px;

  position: relative;
  background: url(${horseBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  .left-header-block {
    background: black;
    opacity: 0.7;
    padding: 0 20px;
    position: absolute;
    top: 30%;
  }
  p {
    color: #898989;
    font-family: 'Oswald';
    font-size: 16px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding: 50px 15px;
    p {
      font-size: 11px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 50px 20px;
    p {
      font-size: 13px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    padding: 50px 28px;
    p {
      font-size: 14px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    padding: 50px 35px;
    p {
      font-size: 16px;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    p {
      font-size: 17px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    p {
      font-size: 19px;
    }
  }
`;
const TextWrapper = styled.div`
  padding-top: 80px;
  li {
    font-family: 'Oswald';
    color: #898989;
    font-size: 13px;
    padding-bottom: 15px;
  }
  ol {
    text-align: justify;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    li {
      font-size: 10px;
      padding-bottom: 10px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    li {
      font-size: 11px;
      padding-bottom: 12px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    li {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    li {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    li {
      font-size: 14px;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    li {
      font-size: 16px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    li {
      font-size: 17px;
    }
  }
`;
const RightWrapper = styled.div`
  width: 64%;
  height: 100%;
  background: #2a2a2a;
  padding: 30px 35px 20px;
  /* p {
    color: #9597a6;
    font-family: 'Roboto';
    font-size: 14px;
  } */
  // display: flex;
  // flex-direction: column;
  // align-items: center;
`;
const BorderWrapper = styled.div`
  height: 100%;
  border: 1px solid #585858;
  border-radius: 20px;
  padding: 20px 60px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const TopWrapper = styled.div`
  background: #2a2a2a;
  padding: 0px 10px;
  position: absolute;
  top: -12px;
  // left: 240px;
  h3 {
    font-weight: 500;
    color: #ffffff;
    margin: 0px !important;
    font-family: 'Roboto';
    text-align: center;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    // padding: 15px 40px 0px;
    h3 {
      font-size: 16px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    // padding: 20px 60px 0px;
    h3 {
      font-size: 17px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    // padding: 20px 80px 0px;
    h3 {
      font-size: 18px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    // padding: 20px 80px 0px;
    h3 {
      font-size: 18px;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    h3 {
      font-size: 19px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    h3 {
      font-size: 20px;
    }
  }
`;
const MainWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  //Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    ${LeftWrapper} {
      display: none;
    }
    .TopWrapper {
      top: -8px;
      .toplabel-header {
        font-size: 12px;
      }
    }
    ${RightWrapper} {
      width: 100%;
      padding: 30px 15px 20px 15px;
      .BorderWrapper {
        padding: 20px 5px 10px 5px;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    ${LeftWrapper} {
      display: none;
    }
    ${RightWrapper} {
      width: 100%;
      padding: 30px 15px 20px 15px;
      .BorderWrapper {
        padding: 20px 15px 10px 15px;
      }
    }
    .TopWrapper {
      top: -10px;
      .toplabel-header {
        font-size: 14px;
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    ${LeftWrapper} {
      min-width: 200px !important;
      .left-header-block {
        p {
          font-size: 14px;
        }
      }
    }
    ${RightWrapper} {
      width: calc(100% - 200px) !important;
      padding: 30px 20px 20px 20px;
      .BorderWrapper {
        padding: 20px 20px 10px 20px;
      }
    }

    .TopWrapper {
      top: -10px;
      .toplabel-header {
        font-size: 14px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    //height: 40px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    //height: 45px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    //height: 50px;
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    //height: 60px;
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    //height: 70px;
  }
`;

export default RiderRegistrationPlacementLayout;
