import { GridCellProps } from '@progress/kendo-react-grid';
import { format } from 'date-fns';

type Props = {
  classname?: string;
  e: GridCellProps;
  onRowClick?: () => void;
};

const SIFDateCell = ({ classname, e, onRowClick }: Props) => {
  const formattedDate = e?.dataItem?.sifJoinDate
    ? format(e?.dataItem?.sifJoinDate, 'dd-MM-yyyy')
    : '';
  return (
    <td className={classname ?? ''}>
      <div>{formattedDate}</div>
    </td>
  );
};

export default SIFDateCell;
