import React from 'react';
import {
  DatePicker,
  DatePickerChangeEvent,
} from '@progress/kendo-react-dateinputs';
import styled from 'styled-components';
import SimpleReactValidator from 'simple-react-validator';
import dateIcon from './assets/img/icon/date-picker-icon.png';
import CustomCalendar from './customCalendar';

type Props = {
  value?: Date | null | undefined;
  onChange?: ((event: DatePickerChangeEvent) => void) | undefined;
  placeholder?: string | null | undefined;
  name?: string;
  dateLabel?: string;
  className?: string;
  validator?: SimpleReactValidator;
  validations?: string;
};
const SHDatePicker = ({
  value,
  onChange,
  placeholder,
  name,
  dateLabel,
  className,
  validations,
  validator,
}: Props) => {
  const simpleValidator = React.useRef(validator);
  const currentDate = new Date();
  const maxDate = new Date(
    currentDate.getFullYear() - 9,
    currentDate.getMonth(),
    currentDate.getDate(),
  );

  return (
    <MainWrapper
      className={
        validator && !simpleValidator?.current?.fieldValid(dateLabel!)
          ? 'error'
          : 'default'
      }
    >
      <div className="label">{dateLabel ?? 'Label'}</div>
      <StyledDatePicker
        calendar={CustomCalendar}
        value={value}
        onChange={onChange}
        width={300}
        placeholder={placeholder}
        name={name}
        max={maxDate}
        className={`${className} ${
          !simpleValidator?.current?.fieldValid(dateLabel!)
            ? 'error'
            : 'default'
        }`}
      />
      {simpleValidator?.current?.message(
        dateLabel ? dateLabel : 'field',
        value,
        validations ?? '',
      )}
    </MainWrapper>
  );
};
const StyledDatePicker = styled(DatePicker)``;
const MainWrapper = styled.div`
  .srv-validation-message {
    color: #f44336;
    font-size: 12px;
    display: inline-block;
  }

  .k-input .k-input,
  .k-input .k-picker,
  .k-picker .k-input,
  .k-picker .k-picker {
    background: #434343;
    color: #ffffff;
  }
  .k-button-md.k-icon-button {
    background: #434343;
    border-color: #434343;
  }
  .k-input .k-input,
  .k-input .k-picker,
  .k-picker .k-input,
  .k-picker .k-picker {
    background: #434343;
    color: #ffffff;
  }
  .k-button-md.k-icon-button {
    background: #434343;
    border-color: #434343;
    .k-svg-icon > svg {
      fill: #ffffff;
    }
  }
  .k-svg-icon {
    width: 19px;
  }
  &.error {
    .k-input-solid {
      border-bottom: 1px solid #f44336 !important;
    }
  }
  &.default {
    .k-input-solid {
      border: transparent !important;
    }
  }
  .k-input-solid {
    border-color: #434343;
    background: #434343;
    margin-top: unset !important;
  }
  p {
    margin: 0px;
  }
  /* .k-svg-icon {
    display: none;
  }
  button {
    background-image: url ${dateIcon};
    color: red;
  } */
  .k-button-md.k-icon-button {
    svg {
      display: none;
    }
    /* background-image: url (${dateIcon}); */
    span {
      background: url(${dateIcon});
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  .label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  //Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    .label {
      font-size: 11px;
    }
    .k-svg-icon {
      width: 16px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .label {
      font-size: 12px;
    }
    .k-svg-icon {
      width: 17px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .label {
      font-size: 12px;
    }
    .k-svg-icon {
      width: 18px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .label {
      font-size: 13px;
    }
    .k-svg-icon {
      width: 18px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .label {
      font-size: 13px;
    }
    .k-svg-icon {
      width: 18px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .label {
      font-size: 14px;
    }
    .k-svg-icon {
      width: 18px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .label {
      font-size: 16px;
    }
    .k-svg-icon {
      width: 19px;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    .label {
      font-size: 17px;
    }
    .k-svg-icon {
      width: 19px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    .label {
      font-size: 17px;
    }
    .k-svg-icon {
      width: 20px;
    }
  }
`;
export default SHDatePicker;
