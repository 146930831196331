import { GridCellProps } from '@progress/kendo-react-grid';
import { SHToolTip } from 'app/components/SHToolTip';
import styled from 'styled-components';

type Props = {
  onClick?: (e) => void;
  firstTitle?: string;
  e?: GridCellProps;
  secondTitle?: string;
};

export const RosterValueCell = ({
  onClick,
  e,
  firstTitle,
  secondTitle,
}: Props) => {
  return (
    <td style={e?.style} className={e?.className} onClick={onClick}>
      {firstTitle ? (
        <>
          <SHToolTip position="top">
            <StyledDiv title={firstTitle}>{firstTitle}</StyledDiv>
          </SHToolTip>
          {secondTitle && (
            <SHToolTip position="top">
              <StyledDiv title={secondTitle}>{secondTitle}</StyledDiv>
            </SHToolTip>
          )}
        </>
      ) : (
        <StyledDiv>-</StyledDiv>
      )}
    </td>
  );
};
const StyledDiv = styled.div`
  width: 100% !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
`;
