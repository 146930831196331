import { BackNextButton } from 'app/components/BackNextButton';
import { CheckBox } from 'app/components/CheckBox';
import { TextField } from 'app/components/TextField';
import React, { useState } from 'react';
import { GeneralEWDs, GeneralLookup } from 'services/openapi';
import styled from 'styled-components';
import SimpleReactValidator from 'simple-react-validator';
import CustomDragAndDropFiles from 'app/components/CustomDragAndDropFiles';

type Props = {
  onClickEligibilityConditionsBack: () => void;
  medicalConditionsData?: GeneralLookup[];
  handleEligibilityConditionsNext?: () => void;
  setDiaganosisData?: React.Dispatch<React.SetStateAction<GeneralEWDs>>;
  diaganosisData?: GeneralEWDs;
  setOtherData: React.Dispatch<React.SetStateAction<string>>;
  otherData: string;
  setDiagonosisUploadPayload: React.Dispatch<
    React.SetStateAction<Blob | undefined>
  >;
  diagonosisFileAvailability: boolean;
  diagonosisUploadFile: File | null | undefined;
  setDiagonosisFileAvailability: React.Dispatch<React.SetStateAction<boolean>>;
  setDiagonosisUploadFile: React.Dispatch<
    React.SetStateAction<File | null | undefined>
  >;
};
const EligibilityConditionsReturning = ({
  onClickEligibilityConditionsBack,
  medicalConditionsData,
  handleEligibilityConditionsNext,
  setDiaganosisData,
  diaganosisData,
  setOtherData,
  otherData,
  setDiagonosisUploadPayload,
  diagonosisFileAvailability,
  diagonosisUploadFile,
  setDiagonosisFileAvailability,
  setDiagonosisUploadFile,
}: Props) => {
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [valid, setValid] = useState<boolean>(false);
  const isNotChecked = medicalConditionsData?.every(
    value =>
      !diaganosisData?.medicalConditions?.some(item => item.id === value.id),
  );
  const handleChange = event => {
    const { name, value } = event.target;
    setDiaganosisData?.(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (id: string, name: string) => {
    setDiaganosisData?.(prevState => {
      const existingItemIndex = prevState?.medicalConditions?.findIndex(
        item => item.id === id,
      );
      if (existingItemIndex! > -1) {
        const updatedCategory = [...prevState?.medicalConditions!];
        updatedCategory.splice(existingItemIndex!, 1);
        return { ...prevState, medicalConditions: updatedCategory };
      } else {
        return {
          ...prevState,
          medicalConditions: [...prevState?.medicalConditions!, { id, name }],
        };
      }
    });
  };

  const handleOtherDataChange = e => {
    setOtherData(e.target.value);
    if (diaganosisData) {
      setDiaganosisData?.({
        ...diaganosisData,
        medicalConditions: diaganosisData?.medicalConditions?.map(data => {
          if (data?.name === 'Other') {
            data.otherName = e.target.value;
          }
          return data;
        }),
      });
    }
  };
  const handleFileUpload = e => {
    var file = e.target.files[0];

    setDiagonosisUploadFile?.(file);
    setDiagonosisUploadPayload(file);
  };
  const handleRemoveFile = e => {
    setDiagonosisUploadFile?.(undefined);
    setDiagonosisUploadPayload(undefined);
  };
  const handleFileOnCancelClick = e => {
    setDiagonosisUploadFile?.(undefined);
    setDiagonosisUploadPayload(undefined);
  };
  React.useEffect(() => {
    if (diagonosisUploadFile === undefined) {
      setDiagonosisFileAvailability?.(true);
    } else {
      setDiagonosisFileAvailability?.(false);
    }
  }, [diagonosisUploadFile]);

  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [diaganosisData]);
  return (
    <MainWrapper>
      <OverflowWrapper>
        <p className="first-para">
          A membership registration forms for Equestrian with Disabilities must
          be accompanied by a Diagnosis and Adaptive Equipment Form.
        </p>
        <p className="para-two">
          <i>
            Eligible Conditions: From the list below, please indicate each
            condition which applies to the applicant. Other conditions will be
            considered upon request (please list in space provided).
          </i>
        </p>
        <CheckboxWrapper>
          {medicalConditionsData?.map(value => (
            <CheckBox
              key={value.id}
              label={value.name!}
              onChange={() => handleCheckboxChange(value.id!, value.name!)}
              checked={diaganosisData?.medicalConditions?.some(
                e => e.id === value.id,
              )}
            />
          ))}
        </CheckboxWrapper>
        {diaganosisData?.medicalConditions?.some(e => {
          return e.name == 'Other';
        }) ? (
          <TextFieldGrid>
            <TextFieldWrapper>
              <StyledTextField
                onChange={handleOtherDataChange}
                value={otherData}
                label="Enter your medical condition"
                type="text"
                name="otherDetail"
              />
            </TextFieldWrapper>
          </TextFieldGrid>
        ) : (
          ''
        )}
        <InfoWrapper>
          <p className="para-one">
            <i>Medicial Statement:</i>
          </p>
          <p>
            {' '}
            In accordance with YEDA Rulebook, this applicant has been diagnosed
            with the above designated condition(s).
          </p>
        </InfoWrapper>
        <TextFieldGrid>
          <TextFieldWrapper>
            <StyledTextField
              onChange={handleChange}
              value={diaganosisData?.physicianName!}
              label="Name of Physician"
              type="text"
              name="physicianName"
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              onChange={handleChange}
              value={diaganosisData?.physicianLicense!}
              label="License"
              type="text"
              name="physicianLicense"
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              onChange={handleChange}
              value={diaganosisData?.physicianPracticeLocation!}
              label="City & State of Practice"
              type="text"
              name="physicianPracticeLocation"
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <DiagonosisUploadWrapper>
            <h3 className="photo-title">
              {' '}
              Upload your filled Ewd Diagnosis form here.{' '}
            </h3>
            <CustomDragAndDropFiles
              className="dragNdrop"
              onCancel={e => handleFileOnCancelClick(e)}
              onChange={e => handleFileUpload(e)}
              uploadBtnDisabled={!diagonosisFileAvailability}
              onRemoveFile={handleRemoveFile}
              chooseFileBtnDisabled={!diagonosisFileAvailability}
              uploadFile={diagonosisUploadFile}
            />
          </DiagonosisUploadWrapper>
        </TextFieldGrid>
        <ButtonWrapper className="bg-color-change">
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={handleEligibilityConditionsNext}
            onClickBack={onClickEligibilityConditionsBack}
            disableNext={valid || isNotChecked}
            next="Submit and Pay"
            back="Cancel"
          />
        </ButtonWrapper>
      </OverflowWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  color: #9597a6;
  width: 100%;
  height: calc(100vh - 264px);
  position: relative;
  padding: 20px 0px 0px;

  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  .para-two {
    color: #ff9900;
    border-bottom: 1px solid #585858;
    padding: 0px 0px 10px;
    font-size: 11px;
    margin: 20px 0px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    label {
      font-size: 14px;
    }
  }
`;
const OverflowWrapper = styled.div`
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  padding-bottom: 100px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
`;
const CheckboxWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 0 20px 20px;
  align-items: flex-start;
  gap: 5px;
  @media (min-width: 100px) and (max-width: 319px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 320px) and (max-width: 575px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 576px) and (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    grid-template-columns: repeat(5, 1fr);
    padding: 0 20px 60px;
  }
`;
const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  ::placeholder {
    color: #8f8e8e !important;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    display: block;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    display: block;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    display: block;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    display: block;
  }
`;
const TextFieldWrapper = styled.div`
  flex: 0 0 calc(50% - 20px) !important;
  margin: 0px 0px 10px !important;
  @media (min-width: 100px) and (max-width: 767px) {
    width: 100%;
  }
`;
const DiagonosisUploadWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  .photo-title {
    color: #a7a7a7;
    font-family: 'Roboto';
    font-size: 15px;
    text-align: center;
  }
`;
const StyledTextField = styled(TextField)`
  flex: 0 0 calc(50% - 20px);
  margin: 0px 0px 10px !important;
  ::placeholder {
    color: #8f8e8e !important;
  }
`;

const ButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: -5px;
    left: -1px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    backdrop-filter: blur(5px);
  }
  @media (min-width: 100px) and (max-width: 767px) {
    height: 50px;
    &.bg-color-change {
      bottom: -20px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;
const InfoWrapper = styled.div`
  width: 100%;
  border: 1px solid #585858;
  border-radius: 10px;
  margin: 30px 0px 20px;
  padding: 10px 20px;
  background: #27292d;
  .para-one {
    font-family: 'Roboto';
    color: #ff9900;
    border-bottom: 1px solid #585858;
  }
  .notice-para {
    text-align: center;
    border-top: 1px solid #585858;
    border-bottom: 1px solid #585858;
    margin: 10px 35px;
    padding: 10px 0px;
  }
`;
export default EligibilityConditionsReturning;
