import { GridCellProps } from '@progress/kendo-react-grid';
import { SHToolTip } from 'app/components/SHToolTip';
import { Key } from 'react';
import styled from 'styled-components';

type Props = {
  onClick?: (e) => void;
  title: string;
  e?: GridCellProps;
  value: string;
};

export const RosterMappedValueCell = ({ onClick, e, title, value }: Props) => {
  return (
    <div  onClick={onClick}>
      {title ? (
        <SHToolTip position="top">
          <StyledDiv title={title}>{`${title} : ${value} `}</StyledDiv>
        </SHToolTip>
      ) : (
        <StyledDiv>-</StyledDiv>
      )}
    </div>
  );
};
const StyledDiv = styled.div`
  width: 100% !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  display: block;
`;
