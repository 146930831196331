import { CardSubWrapper, CardWrapper } from 'admin/components/wrappers';
import IconCell from 'app/components/CustomDataGrid/Cells/IconCell';
import CustomDataGrid from 'app/components/CustomDataGrid/Index';
import { CustomDeletePopup } from 'app/components/CustomDeletePopup';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigateToRoute } from 'utils/common';
import ShowNameCell from 'app/components/CustomDataGrid/Cells/ShowNameCell';
import ShowDateCell from 'app/components/CustomDataGrid/Cells/ShowDateCell';
import ShowAddressCell from 'app/components/CustomDataGrid/Cells/ShowAddressCell';
import ShowHostCell from 'app/components/CustomDataGrid/Cells/ShowHostCell';
import ShowLocationCell from 'app/components/CustomDataGrid/Cells/ShowLocationCell';
import ShowStatusCell from 'app/components/CustomDataGrid/Cells/ShowStatusCell';
import { GeneralLookup, ShowWrapper } from 'services/openapi';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { SortDescriptor } from '@progress/kendo-data-query';
import { GridSortChangeEvent } from '@progress/kendo-react-grid';
import { TableSkeletonLoader } from 'app/components/TableSkeletonLoader';
import HorizontalKebabCell from 'app/components/CustomDataGrid/Cells/HorizontalKebabCell';

type Props = {
  setPageSkip: React.Dispatch<React.SetStateAction<number | undefined>>;
  pageSkip: number | undefined;
  debounceShowSearch: string;
  fetchManageGetShows: (
    season?: string | undefined,
    location?: string[] | undefined,
    status?: string | undefined,
    searchInput?: string | undefined,
    sortBy?: string | undefined,
    sortOrder?: number | undefined,
    pageNumber?: number,
    pageSize?: number,
  ) => void;
  showData?: ShowWrapper;
  selectedItemId: string | null;
  deleteShow: (
    id?: string | undefined,
    season?: string | undefined,
    location?: string[] | undefined,
    status?: string | undefined,
    searchInput?: string | undefined,
    sortBy?: string | undefined,
    sortOrder?: number | undefined,
    pageNumber?: number,
    pageSize?: number,
  ) => void;
  fetchShowActiveOrInactive: (
    id: string,
    activeStatusId?: string,
    item?: string,
    season?: string,
    location?: Array<string>,
    status?: string,
    searchInput?: string,
    sortBy?: string,
    sortOrder?: number,
    pageNumber?: number,
    pageSize?: number,
  ) => void;
  lookup: GeneralLookup[];
  sortField: string | undefined;
  sortOrder: number | undefined;
  setSortField: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSortOrder: React.Dispatch<React.SetStateAction<number | undefined>>;
  setIsOrder: React.Dispatch<React.SetStateAction<boolean>>;
  isOrder: boolean;
  loading: boolean;
};
const CurrentSeasonTable = ({
  setPageSkip,
  pageSkip,
  debounceShowSearch,
  fetchManageGetShows,
  showData,
  selectedItemId,
  deleteShow,
  fetchShowActiveOrInactive,
  lookup,
  sortField,
  sortOrder,
  setSortField,
  setSortOrder,
  setIsOrder,
  isOrder,
  loading,
}: Props) => {
  const redirect = useNavigateToRoute();
  const currentLocation = useLocation();
  const isPastDataFlag = currentLocation.pathname.includes('past-data');
  const [showDeletePopup, setShowDeletePopup] = React.useState<boolean>(false);
  const activeId = lookup.find(item => item.name === 'Active');
  const inActiveId = lookup.find(item => item.name === 'Inactive');
  const [showId, setShowId] = useState('');
  const initialSort: Array<SortDescriptor> = [
    { field: 'showName', dir: 'asc' },
    // { field: 'showDate', dir: 'desc' },
    // { field: 'showLocation', dir: 'desc' },
  ];
  const [sort, setSort] = React.useState(initialSort);
  const handleMoreClick = (item: string, e) => {
    const id = e.dataItem.id;
    if (item === 'Delete show') {
      setShowId(e.dataItem.id);
      setShowDeletePopup(true);
    } else if (item === 'Make active') {
      fetchShowActiveOrInactive(
        id,
        activeId?.id,
        item,
        '',
        [],
        selectedItemId ?? '',
        debounceShowSearch,
        sortField,
        sortOrder,
        pageSkip,
        10,
      );
    } else if (item === 'Make inactive') {
      fetchShowActiveOrInactive(
        id,
        inActiveId?.id,
        item,
        '',
        [],
        selectedItemId ?? '',
        debounceShowSearch,
        sortField,
        sortOrder,
        pageSkip,
        10,
      );
    } else if (item === 'Edit showinfo') {
      redirect(`/admin/shows/edit/${id}`);
    } else if (item === 'Duplicate show') {
      Cookies.set('showId', id);
      redirect(`/admin/shows/duplicate`);
    } else if (item === 'Generate split') {
      redirect(`/admin/shows/generate-split`);
    } else if (item === 'Show result') {
      redirect(`/admin/shows/show-result/show-status/${id}`);
    }
  };
  const handleViewClick = e => {
    if (isPastDataFlag) {
      redirect(`/admin/past-data/shows/view/show-info/${e.id}`);
    } else {
      redirect(`/admin/shows/view/${e.id}/show-info`); //pass dynamic id from table
    }
  };
  const ShowColumn = [
    {
      field: 'showName',
      title: 'Show Name',
      cell: e => <ShowNameCell e={e} onViewClick={e => handleViewClick(e)} />,
      width: '300',
      locked: true,
      sortable: true,
    },
    {
      field: 'showDate',
      title: 'Show Date',
      cell: e => <ShowDateCell e={e} />,
      width: '125',
      sortable: true,
    },

    {
      field: 'showAddress',
      title: 'Show Address',
      cell: e => <ShowAddressCell e={e} />,
      width: '200',
      sortable: false,
    },
    {
      field: 'showLocation',
      title: 'Show Location',
      cell: e => <ShowLocationCell e={e} />,
      width: '125',
      sortable: false,
    },
    {
      field: 'hostedBy',
      title: 'Hosted By',
      cell: e => <ShowHostCell e={e} />,
      width: '150',
      sortable: false,
    },
    {
      field: 'status',
      title: 'Status',
      cell: e => <ShowStatusCell e={e} />,
      width: '100',
      sortable: false,
    },
    {
      field: 'action',
      title: 'Action',
      cell: e => (
        <HorizontalKebabCell
          isPastDataFlag={isPastDataFlag}
          isAssigned={true}
          e={e}
          onMoreClick={handleMoreClick}
          isCurrentSeason={true}
        />
      ),
      width: '70',
      sortable: false,
    },
  ];
  const ShowPastDataColumn = ShowColumn.slice(0, -1);
  const handlePageChange = event => {
    setPageSkip(event.page.skip);
    fetchManageGetShows(
      '',
      [],
      selectedItemId ?? '',
      debounceShowSearch,
      sortField,
      sortOrder,
      event.page.skip,
      10,
    );
  };

  const handleCurrentSeasonShow = () => {
    setShowDeletePopup(false);
    deleteShow(
      showId,
      '',
      [],
      selectedItemId ?? '',
      debounceShowSearch,
      sortField,
      sortOrder,
      pageSkip,
      10,
    );
  };
  const handleSortChange = e => {
    let sortOrderValue = e.dir === 'asc' ? 0 : 1;
    setSortOrder(sortOrderValue);
    setSortField(e.field);
    fetchManageGetShows(
      '',
      [],
      selectedItemId ?? '',
      debounceShowSearch,
      e.field,
      sortOrderValue,
      pageSkip,
      10,
    );
  };
  return (
    <>
      {loading ? (
        <TableSkeletonLoader rowContent={8} width={100} headingWidth={80} />
      ) : (
        <CardWrapper style={{ paddingTop: '15px' }}>
          <CardSubWrapper className="d-flex"></CardSubWrapper>
          <GridWrapper>
            <CustomDataGrid
              showPagination
              data={showData?.shows ?? []}
              columns={isPastDataFlag ? ShowPastDataColumn : ShowColumn}
              pageSize={10}
              totalCount={showData?.pageDetails?.count}
              onPageChange={event => {
                handlePageChange(event);
              }}
              skip={pageSkip}
              sortable={{
                allowUnsort: false,
                mode: 'single',
              }}
              sort={sort}
              onSortChange={(e: GridSortChangeEvent) => {
                let sortvalue = e.sort[0];
                setSort(e.sort);
                handleSortChange(sortvalue);
              }}
            />
            <CustomDeletePopup
              deleteIcon={true}
              headerUnderline={false}
              popupSubLabel="Are you sure you want to delete this show?"
              className="body-scroll-lock height-full delete-update"
              popupLabel="Delete show"
              setVisible={setShowDeletePopup}
              visible={showDeletePopup}
              align="centre"
              btnLabel1="Cancel"
              btnLabel2="Delete"
              handlebtn2Click={handleCurrentSeasonShow}
              handlebtn1Click={() => {
                setShowDeletePopup(false);
              }}
            />
          </GridWrapper>
        </CardWrapper>
      )}
    </>
  );
};

export default CurrentSeasonTable;
const GridWrapper = styled.div`
  .k-grid-content tr {
    &:hover {
      td {
        background: #f0f0f0 !important;
      }
    }
  }
  .k-grid-content tr td {
    cursor: pointer;
  }
`;
