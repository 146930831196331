import CloseIcon from 'app/components/AdminSearchField/assets/close';
import Multiselect from 'multiselect-react-dropdown';
import React, { useEffect } from 'react';
import { GeneralLookup, GeneralLookupWrapper } from 'services/openapi';
import styled from 'styled-components';
import search from '../../assets/icon/search.png';
import arrow from '../../../app/components/AdminDropDown/assets/arrow.svg';

type Props = {
  preDataList:
    | {
        name: string;
        id: string;
        type: string;
      }[]
    | undefined;
  setPreDataList: React.Dispatch<
    React.SetStateAction<
      | {
          name: string;
          id: string;
          type: string;
        }[]
      | undefined
    >
  >;
  data?: GeneralLookup[];
  setLookupData: React.Dispatch<React.SetStateAction<string>>;
  setTeamIds?: React.Dispatch<React.SetStateAction<string>> | undefined;
  setDivisionIds?: React.Dispatch<React.SetStateAction<string>> | undefined;
  lookupData: string;
  lookUps: GeneralLookupWrapper[];
};

const CustomFilteredDropDown = ({
  preDataList,
  setPreDataList,
  data,
  setLookupData,
  setTeamIds,
  setDivisionIds,
  lookupData,
  lookUps,
}: Props) => {
  const wrapperRefs = React.useRef<(HTMLDivElement | null)[]>([]);
  const multiselectRef = React.useRef(null);
  const [filteredDataList, setFilteredDataList] = React.useState<
    { name: string; id: string }[]
  >([]);
  const [showDropdowns, setShowDropdowns] = React.useState<
    Record<string, boolean>
  >({});
  const removeHandle = (e, index, item) => {
    let filterValue = preDataList?.filter((item, filterIndex) => {
      return filterIndex !== index;
    });
    let covertedPreData = filterValue?.map((item, convertIndex) => {
      return {
        name: item.name,
        id: item.id,
        type: item.type,
      };
    });
    setPreDataList?.(covertedPreData!);
    if (item?.type === 'DIVISION') {
      const divisionLookups =
        lookUps.find(obj => obj.type === 'DIVISION')?.values ?? [];
      const divisionIds = divisionLookups.map(lookup => lookup.id);

      const filteredDivision = filteredDataList.filter(
        filteredItem => !divisionIds.includes(filteredItem.id),
      );
      setFilteredDataList(filteredDivision);
      setDivisionIds?.('');
    } else if (item?.type === 'TEAM') {
      const teamLookups =
        lookUps.find(obj => obj.type === 'TEAM')?.values ?? [];
      const teamIds = teamLookups.map(lookup => lookup.id);

      const filteredDivision = filteredDataList.filter(
        filteredItem => !teamIds.includes(filteredItem.id),
      );
      setFilteredDataList(filteredDivision);
      setTeamIds?.('');
    }
  };
  const toggleDropdown = (itemId: string) => {
    setShowDropdowns(prevShowDropdowns => ({
      ...prevShowDropdowns,
      [itemId]: !prevShowDropdowns[itemId],
    }));
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOutsideClick = (event: MouseEvent) => {
    wrapperRefs.current.forEach((ref, index) => {
      if (ref && !ref.contains(event.target as Node)) {
        const itemId = preDataList?.[index].id;
        if (itemId) {
          setShowDropdowns(prevShowDropdowns => ({
            ...prevShowDropdowns,
            [itemId]: false,
          }));
        }
      }
    });
  };
  let covertedPreDataList = filteredDataList?.map(item => {
    return {
      text: item?.name,
      id: item?.id,
    };
  });
  let covertedData = data?.map(item => {
    return {
      text: item.name,
      id: item.id,
    };
  });
  const onSelect = (selectedList: any) => {
    let covertedSelectedList = selectedList?.map(item => {
      return {
        name: item.text,
        id: item.id,
      };
    });
    setFilteredDataList?.(covertedSelectedList);

    const teamIds = lookUps.find(obj => obj.type === 'TEAM')?.values ?? [];
    const divisionIds =
      lookUps.find(obj => obj.type === 'DIVISION')?.values ?? [];
    const teamCommaSeparatedIds = teamIds
      .filter(item =>
        covertedSelectedList.some(selected => selected.id === item.id),
      )
      .map(item => item.id)
      .join(',');

    const divisionCommaSeparatedIds = divisionIds
      .filter(item =>
        covertedSelectedList.some(selected => selected.id === item.id),
      )
      .map(item => item.id)
      .join(',');
    if (lookupData === 'TEAM') {
      setTeamIds?.(teamCommaSeparatedIds);
    } else if (lookupData === 'DIVISION') {
      setDivisionIds?.(divisionCommaSeparatedIds);
    }
  };
  const onRemove = (selectedList: any, selectedItem: any) => {
    let covertedSelectedList = selectedList?.map(item => {
      return {
        name: item.text,
        id: item.id,
      };
    });

    setFilteredDataList(covertedSelectedList);

    const teamIds = lookUps.find(obj => obj.type === 'TEAM')?.values ?? [];
    const divisionIds =
      lookUps.find(obj => obj.type === 'DIVISION')?.values ?? [];

    const teamCommaSeparatedIds = teamIds
      .filter(item =>
        covertedSelectedList.some(selected => selected.id === item.id),
      )
      .map(item => item.id)
      .join(',');

    const divisionCommaSeparatedIds = divisionIds
      .filter(item =>
        covertedSelectedList.some(selected => selected.id === item.id),
      )
      .map(item => item.id)
      .join(',');

    if (lookupData === 'TEAM') {
      setTeamIds?.(teamCommaSeparatedIds);
    } else if (lookupData === 'DIVISION') {
      setDivisionIds?.(divisionCommaSeparatedIds);
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [handleOutsideClick]);
  useEffect(() => {
    if (preDataList?.length === 0) {
      setFilteredDataList([]);
      setDivisionIds?.('');
      setTeamIds?.('');
    }
  }, [preDataList]);
  return (
    <>
      <MainWrapper>
        {preDataList?.map((item, index) => (
          <>
            <MultiWrapper
              key={item.id}
              ref={el => (wrapperRefs.current[index] = el)}
            >
              <BtnWrapper>
                <button
                  onClick={() => {
                    toggleDropdown(item.id);
                    setLookupData(item.type);
                  }}
                >
                  {item?.name}
                </button>
                <img
                  src={arrow}
                  alt="arrow"
                  onClick={() => {
                    toggleDropdown(item.id);
                    setLookupData(item.type);
                  }}
                />
                <span onClick={e => removeHandle(e, index, item)}>
                  <CloseIcon />
                </span>
              </BtnWrapper>
              {showDropdowns[item.id] && (
                <MultiSelectWrapper>
                  <Multiselect
                    ref={multiselectRef}
                    options={covertedData}
                    selectedValues={covertedPreDataList}
                    onSelect={onSelect}
                    onRemove={onRemove}
                    displayValue="text"
                    placeholder={'Search'}
                    showCheckbox
                    keepSearchTerm
                    disablePreSelectedValues={true}
                    loading={covertedData?.length! > 0 ? false : true}
                  />
                </MultiSelectWrapper>
              )}
            </MultiWrapper>
          </>
        ))}
      </MainWrapper>
    </>
  );
};

export default CustomFilteredDropDown;
const BtnWrapper = styled.div`
  gap: 10px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border: 1px solid #dfdfdf;
  background-color: #fff;
  padding: 2.5px 10px 2.5px 5px;
  border-radius: 6px;
  button {
    background: transparent;
    border: transparent;
    width: 100%;
    height: 100%;
    min-height: 20px;
    text-align: left;
    cursor: pointer;
    font-family: 'Inter';
  }
  img {
    cursor: pointer;
  }
  span {
    cursor: pointer;
    margin: 0;
    svg {
      width: 10px;
      height: 10px;
    }
  }
`;
const MultiWrapper = styled.div`
  position: relative;
`;
const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  &.focused {
    .optionListContainer {
      &.displayBlock {
        display: none !important;
      }
    }
  }
  .multiSelectContainer {
    position: relative;
    overflow: auto !important;
    margin-top: 4px !important;
    background: #ffffff !important;
    color: rgb(28 28 28);
    border-radius: 5px;
    box-shadow: 0 4px 8px -2px #091e4240, 0 0 1px #091e424f;
    min-width: 270px;
    .search-wrapper {
      position: relative;
      overflow: auto !important;
      background: #ffffff !important;
      padding: 10px 15px 0px 15px !important;
      border-bottom: none;
      border: 0;
      color: rgb(28 28 28);
      border-radius: 0;
      &::before {
        content: '';
        position: absolute;
        background-image: url(${search});
        top: 30px;
        left: 25px;
        z-index: 9999;
        font-family: 'WebComponentsIcons';
        width: 15px;
        height: 15px;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        background-size: 12px;
      }
    }
    .optionListContainer {
      position: relative;
      overflow: auto !important;
      margin: 0 !important;
      background: #ffffff !important;
      max-height: 190px;
      overflow-y: auto;

      ::-webkit-scrollbar {
        width: 2px;
        margin-left: 3px;
      }
      ::-webkit-scrollbar-track {
        background: transparent !important;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        width: 9px;
        background: #727272 !important;
        border-radius: 10px;
      }
    }
    ul {
      border-radius: 5px;
      border: none !important;
      display: block;
      margin: 0;
      padding: 5px 0px 8px 0px !important;
      max-height: unset;
      .notFound {
        font-size: 13px;
        font-family: 'Inter';
      }
      li {
        padding: 10px 15px;
        font-size: 12px;
        display: flex;
        align-items: flex-start;
        font-family: 'Inter';
        border-left: 1px solid transparent;
        :hover {
          border-left: 1px solid #001fff;
          background: #f1f2f4 !important;
        }
        .checkbox {
          min-width: 15px;
          width: 15px;
          margin: 4px 15px 0 0;
        }
        :hover {
          color: rgb(28 28 28);
          background: transparent;
        }
      }
    }
  }
  .highlightOption {
    background: transparent;
    color: rgb(28 28 28);
  }
  .searchWrapper {
    border: 1px solid #e8e8ec;
    border-radius: 4px;
    min-height: 36px;
    position: relative;
    padding: 0;
    background: #e1e9ff;
    .chip {
      display: none;
    }
    .searchBox {
      margin: 0px !important;
      font-family: 'Inter';
      padding: 0 10px 0 30px;
      font-size: 13px;
      height: 34px;
      width: 100%;
      border: none;
      text-align: left;
      color: #111111 !important;
      border: 1px solid #eeeeee !important;
      background: #dad4d445 !important;
      border-radius: 5px;
      &::placeholder {
        font-weight: 500;
      }
    }
  }
`;
const MultiSelectWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 238px;
  z-index: 9;
  width: 366px;
  .optionListContainer {
    display: block !important;
    &.displayNone {
      display: block !important;
    }
    .optionContainer {
      li {
        &.option {
          &.disableSelection {
            opacity: 1 !important;
            pointer-events: unset !important;
          }
        }
      }
    }
  }
`;
