import { GridCellProps } from '@progress/kendo-react-grid';
import { DetailArrowIcon } from 'app/assets/icons';
import { AdminProfileInfo } from 'app/components/AdminProfileInfo';
import styled from 'styled-components';

type Props = {
  className?: string;
  onViewClick?: (e) => void;
  e: GridCellProps;
};

const AdminCoachProfileCell = ({ className, e, onViewClick }: Props) => {
  const data = e?.dataItem;
  return (
    <StyledTd
      style={e.style}
      className={e.className}
      onClick={() => onViewClick?.(data)}
    >
      <CustomTableColumnWrapper>
        <AdminProfileInfo
          src={data?.imageUrl ?? ''}
          name={data?.coachName}
          subLabel={data?.isAssistant ? 'Assistant Coach' : 'Coach'}
        />
        <div>
          <DetailArrowIcon />
        </div>
      </CustomTableColumnWrapper>
    </StyledTd>
  );
};

export default AdminCoachProfileCell;
const StyledTd = styled.td`
  cursor: pointer;
  border-right: 1px solid #eaecf0 !important;
  .sub-name-label {
    color: #980707 !important;
  }
`;
const CustomTableColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 0 0px;
`;
