import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ProfileImageUploadCard } from 'app/components/ProfileImageUploadCard';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import {
  FieldLabelSmall,
  LabelMedium,
  SubLabelSemiBold,
} from 'app/components/SHLabel';
import {
  AdminCoachInfo,
  GeneralLookup,
  RegisteredTeam,
} from 'services/openapi';
import { AdminTextField } from 'app/components/AdminTextField';

import { AdminEditIcon } from 'admin/assets/icon';
import useCommonLookUpDatas from 'hooks/useCommonLookupData';
import { getLookupArray } from 'utils/common';
import { AdminDragAndDropFiles } from 'app/components/AdminDragAndDropFiles';
import { MappedCheckboxGrid } from 'app/components/mappedCheckboxGrid';
import { CardSkeletonLoader } from 'app/components/CardSkeletonLoader';
import { useLocation } from 'react-router-dom';

type Props = {
  loading?: boolean;
  coachViewData: AdminCoachInfo | undefined;
  handleEditClick: () => void;
};
export type coachViewModel = {
  id: string;
  firstName: string;
  lastName: string;
  isAssistant: boolean;
  email: string;
  primaryPhone: string;
  street: string;
  city: string;
  state: GeneralLookup;
  zip: string;
  team: RegisteredTeam;
  hasInsurance: boolean;
  isTeamAcceptingMembers: boolean;
  isInterestedInReferalProgram: boolean;
  affiliations: GeneralLookup[];
  status: GeneralLookup;
  isTrial: boolean;
  emergencyContactName: string;
  emergencyContactPhone: string;
  photo: string | null;
  insuranceCertificate: string | null;
  season: GeneralLookup;
  
};

const CoachDefaultData = {
  id: '',
  firstName: '',
  lastName: '',
  isAssistant: false,
  email: '',
  primaryPhone: '',
  street: '',
  city: '',
  state: {
    id: '',
    name: '',
  },
  zip: '',
  team: {},
  hasInsurance: false,
  isTeamAcceptingMembers: false,
  isInterestedInReferalProgram: false,
  affiliations: [],
  status: {
    id: '',
    name: '',
  },
  isTrial: false,
  emergencyContactName: '',
  emergencyContactPhone: '',
  photo: '',
  insuranceCertificate: '',
  season: {
    id: '',
    name: '',
  },
};

export const CoachViewProfile = ({
  coachViewData,
  loading,
  handleEditClick,
}: Props) => {
  const location = useLocation();
  const { getAllLookupData, lookUps } = useCommonLookUpDatas();
  const currentLocation = useLocation();
  const isPastDataFlag = currentLocation.pathname.includes('past-data');
  const [coachData, setCoachdata] = useState<AdminCoachInfo>(
    CoachDefaultData ?? {},
  );
  useEffect(() => {
    getAllLookupData('PROFESSIONALAFFILIATIONS');
  }, []);
  useEffect(() => {
    setCoachdata(coachViewData ?? {});
  }, [coachViewData]);
  return (
    <>
      <Wrapper>
        {loading ? (
          <CardSkeletonLoader
            isProfileImageLoader
            isTextLoader
            isHeaderLoader
            headerWidth={300}
            lineContent={3}
          />
        ) : (
          <ProfileImageUploadCard
            buttonLabel="Edit"
            buttonType="secondary"
            svgIcon={<AdminEditIcon />}
            onButtonClick={handleEditClick}
            className="ProfileImageUploadCard"
            imgSrc={coachData?.photo}
            isPastDataFlag={isPastDataFlag}
          >
            <TitleWrapper className="first-item">
              <h3 className="header-title">
                {`${coachData?.firstName ?? ''} ${coachData?.lastName ?? ''}`}
                {location.pathname.includes('past-data') ? (
                  ''
                ) : (
                  <span>{` (${coachData?.season?.name ?? ''})`}</span>
                )}
                </h3>
              <Titleh5>
                {coachData?.isAssistant && (
                  <CustomLabelMedium color="#9F0404">
                    Assistant Coach
                  </CustomLabelMedium>
                )}
              </Titleh5>
            </TitleWrapper>
            <GridLayout
              gap={{ rows: 0, cols: 0 }}
              rows={[{ height: 'auto' }, { height: 'auto' }]}
              cols={[{ width: '50%' }, { width: '50%' }]}
              className="profilecard-grid"
            >
              <GridLayoutItem row={1} col={1} className="first-item">
                <GridLayoutItem row={2} col={1}>
                  <CustomLabelMedium color="#475467">
                    <TitleSpan>Team :</TitleSpan>
                    <ValueSpan> {coachData?.team?.name}</ValueSpan>
                  </CustomLabelMedium>
                </GridLayoutItem>
                <GridLayoutItem row={3} col={1} className="email-style">
                  <CustomLabelMedium color="#475467">
                    <TitleSpan>Email :</TitleSpan>
                    <ValueSpan>{coachData?.email}</ValueSpan>
                  </CustomLabelMedium>
                </GridLayoutItem>
              </GridLayoutItem>
              <GridLayoutItem row={1} col={2} className="first-item">
                <GridLayoutItem row={1} col={1} className="right-side">
                  <CustomLabelMedium color="#475467">
                    <TitleSpan>Address :</TitleSpan>
                    <ValueSpan>
                      {`${coachData?.street ?? ''}  ${coachData?.city ?? ''} ${
                        coachData?.state?.name ?? ''
                      }`}
                    </ValueSpan>
                  </CustomLabelMedium>
                </GridLayoutItem>
                <GridLayoutItem row={2} col={1} className="right-side">
                  <CustomLabelMedium color="#475467">
                    <TitleSpan>Phone :</TitleSpan>
                    <ValueSpan>{coachData?.primaryPhone}</ValueSpan>
                  </CustomLabelMedium>
                </GridLayoutItem>
              </GridLayoutItem>
            </GridLayout>
          </ProfileImageUploadCard>
        )}

        <TabWrapper>
          <SubLabelSemiBold>Coach Extras</SubLabelSemiBold>
        </TabWrapper>
        <GridLayout
          gap={{ rows: 6, cols: 10 }}
          rows={[{ height: 'auto' }, { height: 'auto' }]}
          cols={[
            { width: 'calc(33.33% - 7px)' },
            { width: 'calc(33.33% - 7px)' },
            { width: 'calc(33.33% - 7px)' },
          ]}
        >
          <GridLayoutItem row={1} col={1} colSpan={1} className="first-item">
            <AdminTextField
              label="Insurance"
              value={coachData?.hasInsurance ? 'Yes' : 'No'}
              className="field-style"
              disabled
              isLoader={loading}
            />
          </GridLayoutItem>
          <GridLayoutItem row={1} col={2} colSpan={1} className="first-item">
            <AdminTextField
              label="Referal Program"
              value={coachData?.isInterestedInReferalProgram ? 'Yes' : 'No'}
              className="field-style"
              disabled
              isLoader={loading}
            />
          </GridLayoutItem>
          <GridLayoutItem row={1} col={3} colSpan={1} className="first-item">
            <AdminTextField
              label="Accepting New Members"
              value={coachData?.isTeamAcceptingMembers ? 'Yes' : 'No'}
              className="field-style"
              disabled
              isLoader={loading}
            />
          </GridLayoutItem>
          {coachData?.hasInsurance && (
            <AdminDragAndDropFiles
              isView={true}
              uploadStringFile={coachData?.insuranceCertificate}
            />
          )}
        </GridLayout>
        <FieldLabelSmall>Afiliation</FieldLabelSmall>
        <MappedCheckboxGrid
          checked={coachData?.affiliations ?? []}
          data={getLookupArray('PROFESSIONALAFFILIATIONS', lookUps)}
        />
        <GridLayout
          gap={{ rows: 6, cols: 10 }}
          rows={[{ height: 'auto' }, { height: 'auto' }]}
          cols={[
            { width: 'calc(33.33% - 7px)' },
            { width: 'calc(33.33% - 7px)' },
            { width: 'calc(33.33% - 7px)' },
          ]}
        >
          <GridLayoutItem row={1} col={1} colSpan={1} className="first-item">
            <AdminTextField
              label="Status"
              value={coachData?.status?.name}
              className="field-style"
              disabled
              isLoader={loading}
            />
          </GridLayoutItem>
          <GridLayoutItem row={1} col={2} colSpan={1} className="first-item">
            <AdminTextField
              label="Trial"
              value={coachData?.isTrial ? 'Yes' : 'No'}
              className="field-style"
              disabled
              isLoader={loading}
            />
          </GridLayoutItem>
          <GridLayoutItem row={1} col={3} colSpan={1} className="first-item">
            <AdminTextField
              label="Emergency Contact Name"
              value={coachData?.emergencyContactName ?? ''}
              className="field-style"
              disabled
              isLoader={loading}
            />
          </GridLayoutItem>
          <GridLayoutItem row={2} col={1} colSpan={1} className="first-item">
            <AdminTextField
              label=" Emergency Contact Phone"
              value={coachData?.emergencyContactPhone ?? ''}
              className="field-style"
              disabled
              isLoader={loading}
            />
          </GridLayoutItem>
        </GridLayout>
      </Wrapper>
    </>
  );
};

const Titleh5 = styled.div`
  padding: 0 0 5px 0;
  h6 {
    font-size: 14px;
    color: rgb(159, 4, 4);
    text-transform: capitalize;
    position: relative;
    font-family: 'Inter';
    z-index: 1;
  }
`;
const TitleSpan = styled.div`
  white-space: nowrap;
`;
const ValueSpan = styled.div`
  font-weight: 600;
  position: relative;
  white-space: pre-line;
  overflow: auto;
  padding: 0 0 0 5px;
`;
const TitleWrapper = styled.div`
  padding: 0 0 0 0;
`;
const Wrapper = styled.div`
  .CheckBoxGrid {
    input {
      cursor: default !important;
    }
  }
  .ProfileImageUploadCard {
    align-items: center;
    .field-style {
      border-color: #515e71;
    }
    &.CheckAfiliation {
      font-size: 13px !important;
      font-weight: 500 !important;
      color: #a3a3a3 !important;
    }
    .header-title {
      color: #515e71;
      margin: 0px;
      span {
        color: #0028ff;
        font-size: 19px;
        margin-left: 10px;
      }
    }
    .profilecard-grid {
      span {
        font-weight: 600;
      }
      .right-side {
        padding-left: 20px;
        border-left: 1px solid #a6b1dc;
      }
      .email-style {
        span {
          color: #0028ff;
        }
      }
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .ProfileImageUploadCard {
      flex-direction: column;
      padding: 20px !important;
      button {
        position: unset !important;
        transform: unset !important;
      }
      .k-grid-layout {
        width: 100%;
      }
      .child-wrapper {
        padding: 0px;
      }
    }
  }
  @media (min-width: 100px) and (max-width: 767px) {
    .ProfileImageUploadCard {
      flex-direction: column;
      padding: 20px !important;
      .right-side {
        padding-left: 0px !important;
        border-left: 0px solid !important;
      }
      button {
        position: unset !important;
        transform: unset !important;
      }
      .k-grid-layout {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      .child-wrapper {
        padding: 0px;
      }
    }
  }
`;
const TabWrapper = styled.div`
  margin-top: 20px;
`;
const CustomLabelMedium = styled(LabelMedium)`
  display: flex;
  padding: 1px 15px 1px 0;
  span {
    color: #475467;
  }
`;
