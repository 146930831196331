import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ProfileImageUploadCard } from 'app/components/ProfileImageUploadCard';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import { LabelMedium } from 'app/components/SHLabel';
import { GeneralLookup, UserInfo } from 'services/openapi';
import { useNavigateToRoute } from 'utils/common';
import { useParams } from 'react-router-dom';
import { AdminEditIcon } from 'admin/assets/icon';
import { CardSkeletonLoader } from 'app/components/CardSkeletonLoader';
type Props = {
  userViewData?: UserInfo | undefined;
  loading?: boolean;
};
export type userViewModel = {
  id: string; // GUID string
  firstName: string;
  lastName: string;
  email: string;
  primaryPhone: string;
  photo: string | null;
  street: string;
  city: string;
  state: GeneralLookup;
  zip: string;
  userName: string;
  UserType: string[];
};

export const UserViewProfile = ({ userViewData, loading }: Props) => {
  const redirect = useNavigateToRoute();
  const { id: userId } = useParams();
  const [userData, setUserdata] = useState<UserInfo>(userViewData ?? {});

  const handleEditClick = () => {
    redirect(`/admin/users/${'update'}/${userId}`);
  };
  useEffect(() => {
    setUserdata(userViewData ?? {});
  }, [userViewData]);

  return (
    <>
      <Wrapper>
        {loading ? (
          <CardSkeletonLoader
            isProfileImageLoader
            isHeaderLoader
            isTextLoader
            headerWidth={300}
            lineContent={3}
          />
        ) : (
          <ProfileImageUploadCard
            buttonLabel="Edit"
            buttonType="secondary"
            svgIcon={<AdminEditIcon />}
            onButtonClick={handleEditClick}
            className="ProfileImageUploadCard"
            imgSrc={userData?.photo}
          >
            <TitleWrapper className="first-item">
              <h3 className="header-title">
                {userData?.firstName} {userData?.lastName}
              </h3>
            </TitleWrapper>
            <GridLayout
              gap={{ rows: 0, cols: 0 }}
              rows={[{ height: 'auto' }, { height: 'auto' }]}
              cols={[{ width: '50%' }, { width: '50%' }]}
              className="profilecard-grid"
            >
              <GridLayoutItem row={1} col={1} className="first-item">
                <GridLayoutItem row={2} col={1}>
                  <CustomLabelMedium color="#475467">
                    <TitleSpan>User Type :</TitleSpan>
                    <ValueSpan>{userData.userTypes}</ValueSpan>
                  </CustomLabelMedium>
                </GridLayoutItem>
                <GridLayoutItem row={3} col={1}>
                  <CustomLabelMedium color="#475467">
                    <TitleSpan>User Name :</TitleSpan>
                    <ValueSpan>{userData?.userName}</ValueSpan>
                  </CustomLabelMedium>
                </GridLayoutItem>
                <GridLayoutItem row={4} col={1}>
                  <CustomLabelMedium color="#475467">
                    <TitleSpan>Phone no :</TitleSpan>
                    <ValueSpan>{userData?.primaryPhone}</ValueSpan>
                  </CustomLabelMedium>
                </GridLayoutItem>
              </GridLayoutItem>
              <GridLayoutItem row={1} col={2} className="first-item">
                <GridLayoutItem row={1} col={1} className="right-side">
                  <CustomLabelMedium color="#475467">
                    <TitleSpan>Address :</TitleSpan>
                    <ValueSpan>
                      {userData?.street}
                      {userData?.city}
                      {userData?.state?.name}
                    </ValueSpan>
                  </CustomLabelMedium>
                </GridLayoutItem>
                <GridLayoutItem row={2} col={1} className="right-side">
                  <CustomLabelMedium color="#475467">
                    <TitleSpan>Zip :</TitleSpan>
                    <ValueSpan> {userData?.zip}</ValueSpan>
                  </CustomLabelMedium>
                </GridLayoutItem>
                <GridLayoutItem row={3} col={1} className="right-side">
                  <CustomLabelMedium color="#475467">
                    <TitleSpan>Email :</TitleSpan>
                    <ValueSpan> {userData?.email}</ValueSpan>
                  </CustomLabelMedium>
                </GridLayoutItem>
              </GridLayoutItem>
            </GridLayout>
          </ProfileImageUploadCard>
        )}
      </Wrapper>
    </>
  );
};

const TitleSpan = styled.div`
  white-space: nowrap;
`;
const ValueSpan = styled.div`
  font-weight: 600;
  position: relative;
  white-space: pre-line;
  overflow: auto;
  padding: 0 0 0 5px;
`;
const TitleWrapper = styled.div`
  padding: 0 0 15px 0;
`;
const Wrapper = styled.div`
  .ProfileImageUploadCard {
    align-items: center;
    .field-style {
      border-color: #515e71;
    }
    &.CheckAfiliation {
      font-size: 13px !important;
      font-weight: 500 !important;
      color: #a3a3a3 !important;
    }
    .header-title {
      color: #515e71;
      margin: 0px;
      span {
        color: #0028ff;
        font-size: 19px;
        margin-left: 10px;
      }
    }
    .profilecard-grid {
      span {
        font-weight: 600;
      }
      .right-side {
        padding-left: 20px;
        border-left: 1px solid #a6b1dc;
      }
      .email-style {
        span {
          color: #0028ff;
        }
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .ProfileImageUploadCard {
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .ProfileImageUploadCard {
      flex-direction: column;
      padding: 20px !important;
      button {
        position: unset !important;
        transform: unset !important;
      }
      .k-grid-layout {
        width: 100%;
      }
      .child-wrapper {
        padding: 0px;
      }
    }
  }
  @media (min-width: 100px) and (max-width: 767px) {
    .ProfileImageUploadCard {
      flex-direction: column;
      padding: 20px !important;
      .right-side {
        padding-left: 0px !important;
        border-left: 0px solid !important;
      }
      button {
        position: unset !important;
        transform: unset !important;
      }
      .k-grid-layout {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      .child-wrapper {
        padding: 0px;
      }
    }
  }
`;

const CustomLabelMedium = styled(LabelMedium)`
  display: flex;
  padding: 1px 15px 1px 0;
  span {
    color: #475467;
  }
`;
