import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  Breadcrumb,
  BreadcrumbLinkMouseEvent,
} from '@progress/kendo-react-layout';
import { useNavigate } from 'react-router-dom';

type Props = {
  preData?: breadCrumbDataModel[];
  setPreData?: React.Dispatch<React.SetStateAction<breadCrumbDataModel[]>>;
  onItemSelect?: (event: BreadcrumbLinkMouseEvent) => void;
  className?: string;
};
export interface breadCrumbDataModel {
  id: string;
  text?: string;
}

export const CustomBreadCrumb = ({
  preData,
  className,
  onItemSelect,
}: Props) => {
  const navigate = useNavigate();
  const [data, setData] = React.useState<breadCrumbDataModel[]>(preData ?? []);

  const handleItemSelect = (event: BreadcrumbLinkMouseEvent) => {
    onItemSelect?.(event);
    const itemIndex = data.findIndex(curValue => curValue.id === event.id);
    const newData = data?.slice(0, itemIndex + 1);
    setData(newData);
    if (event.id) {
      if (event.id === 'navigateBack') {
        navigate(-1);
      } else {
        navigate(event?.id);
      }
    }
  };

  useEffect(() => {
    const currentLocation = window.location.href;
    const filteredItem = data?.findIndex(item =>
      currentLocation.includes(item.id),
    );
    if (filteredItem) {
      const filteredData = data?.slice(0, filteredItem + 1);
      setData(filteredData);
    }
  }, []);

  return (
    <MainWrapper className={`hrms-bread-crumbs ${className}`}>
      <div>
        <Breadcrumb data={data ?? []} onItemSelect={handleItemSelect} />
      </div>
    </MainWrapper>
  );
};

export default CustomBreadCrumb;

const MainWrapper = styled.div<Props>`
  .k-breadcrumb {
    font-family: 'Inter';
    background-color: transparent;
    ol {
      li {
        :nth-child(1) {
          .k-breadcrumb-link {
            padding-left: 0;
          }
        }
      }
    }
  }
  .k-breadcrumb-root-link:focus,
  .k-breadcrumb-root-link.k-focus {
    box-shadow: unset;
  }
  .k-breadcrumb-item {
    :nth-child(1):before {
      content: '';
      background-image: unset;
      width: 0;
    }
    :before {
      content: '/';
      margin: 0;
      color: #a3a3a3;
    }
  }
  .k-svg-icon {
    display: none;
  }
  .k-breadcrumb-root-link {
    margin: 0;
    font-family: 'Inter';
    color: #1e1e1e;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    padding: 0 5px 0 3px;
    line-height: 32px;
  }
  .k-icon {
    display: none;
  }
  .k-breadcrumb-link {
    font-weight: 400;
    font-family: 'Inter';
    font-size: 14px;
    text-decoration: unset;
    padding: 0 5px 0 3px;
    line-height: 32px;
    color: #1e1e1e;
    :focus {
      box-shadow: unset;
    }
  }
  .k-breadcrumb-link[aria-current='true'] {
    font-weight: 400;
    font-family: 'Inter';
    font-size: 14px;
    color: #4764ff;
    text-decoration: underline;
    text-underline-offset: 3px;
    line-height: 32px;
  }
`;
