import styled from 'styled-components';
import { BlurredLabel } from 'app/components/SHLabel';
import AdminCustomButton from 'app/components/AdminCustomButton';
import DropdownComponent from 'app/components/CustomFilterPopup';
import { FilterIcon } from 'app/assets/icons';
import { useEffect, useState } from 'react';
import { CommonPageWrapper, HeadingWrapper } from 'admin/components/wrappers';
import { AdminSearch } from 'app/components/AdminSearchField';
import TeamRiderTable from './TeamRiderTable';
import useTeamApi from 'admin/hooks/useTeamApi';
import { useDebounce } from 'hooks/useDebounce';
import useCommonLookUpDatas from 'hooks/useCommonLookupData';
import { useLocation, useParams } from 'react-router-dom';
import { getLookupArray } from 'utils/common';

const TeamRiderListing = () => {
  const [active, setActive] = useState<boolean>();
  const [inActive, setInActive] = useState<boolean>();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const {
    fetchTeamRiderList,
    teamRiderData,
    loading,
    deleteTeamRiderList,
    riderList,
    teamRiderActiveOrInactive,
  } = useTeamApi();
  const [pageSkip, setPageSkip] = useState<number | undefined>(0);
  const debounceTeamSearch = useDebounce(searchTerm);
  const { getAllLookupData, lookUps } = useCommonLookUpDatas();
  const [selectedItemId, setSelectedItemId] = useState<string | null>();

  const data = ['All', 'Active - Paid', 'Active - Not Paid', 'Inactive'];
  const currentLocation = useLocation();
  const isPastDataFlag =currentLocation.pathname.includes("past-data")

  const handleDropDownClick = (item: string) => {
    if (item === 'Active') {
      setActive(true);
    }
    if (item === 'Inactive') {
      setInActive(true);
    }
    if (item === 'All') {
      setActive(true);
      setInActive(true);
    }
  };

  const handleItemIdClick = (itemId: string) => {
    setSelectedItemId(itemId === selectedItemId ? null : itemId);
  };
  const handleRiderSearch = e => {
    setSearchTerm(e.target.value);
  };

  const handleOnCloseClick = () => {
    setSearchTerm('');
  };
  const { id } = useParams();

  useEffect(() => {
    if (debounceTeamSearch.length > 0) {
      fetchTeamRiderList(
        id ?? '',
        debounceTeamSearch,
        '',
        '',
        '',
        undefined,
        0,
        10,
      );
    } else if (
      (debounceTeamSearch.length === 0 && selectedItemId) ||
      selectedItemId === null
    ) {
      fetchTeamRiderList(
        id ?? '',
        debounceTeamSearch,
        '',
        selectedItemId ?? '',
        '',
        undefined,
        pageSkip,
        10,
      );
    } else {
      fetchTeamRiderList(
        id ?? '',
        debounceTeamSearch,
        '',
        '',
        '',
        undefined,
        pageSkip,
        10,
      );
    }
  }, [debounceTeamSearch, selectedItemId]);
  useEffect(() => {
    if (pageSkip !== 0) {
      if (debounceTeamSearch?.length! >= 0) {
        setPageSkip(0);
      }
    }
  }, [debounceTeamSearch]);
  useEffect(() => {
    getAllLookupData('ACTIVESTATUS');
  }, []);

  return (
    <>
      <CustomPageWrapper>
        <HeadingWrapper>
          <ButtonWrapper>
            <BlurredLabel style={{ whiteSpace: 'nowrap' }}>
              Popular filter
            </BlurredLabel>
            {getLookupArray('ACTIVESTATUS', lookUps).map(item => (
              <AdminCustomButton
                buttonType="primary"
                isActive={false}
                className={
                  selectedItemId?.includes(item?.id)
                    ? 'active-inactive'
                    : 'Inactive-button'
                }
                onClick={() => {
                  handleItemIdClick(item?.id);
                }}
              >
                {item?.name}
              </AdminCustomButton>
            ))}
            {/* <DropdownComponent
              data={data}
              onItemClick={handleDropDownClick}
              label="More filters"
              svgIcon={<FilterIcon />}
            /> */}
          </ButtonWrapper>
          <SearchWrapper>
            <AdminSearch
              placeholder="Search for a rider"
              onSearchTextEntering={handleRiderSearch}
              searchTerm={searchTerm}
              onCloseClick={handleOnCloseClick}
            />
          </SearchWrapper>
        </HeadingWrapper>
        <TeamRiderTable
          teamRiderData={teamRiderData}
          loading={loading}
          fetchTeamRiderList={fetchTeamRiderList}
          debounceTeamSearch={debounceTeamSearch}
          setPageSkip={setPageSkip}
          pageSkip={pageSkip}
          teamRiderActiveOrInactive={teamRiderActiveOrInactive}
          lookup={getLookupArray('ACTIVESTATUS', lookUps)}
          deleteTeamRiderList={deleteTeamRiderList}
          riderList={riderList}
          selectedItemId={selectedItemId}
          isPastDataFlag={isPastDataFlag}
        />
      </CustomPageWrapper>
    </>
  );
};
const CustomPageWrapper = styled(CommonPageWrapper)`
  padding: 0px;
  height: calc(100% - 145px) !important;
  .k-grid-container {
    .k-grid-content {
      height: calc(100vh - 356px) !important;
    }
  }
`;

const SearchWrapper = styled.div`
  width: 283px;
`;

const ButtonWrapper = styled.div`
  gap: 10px;
  display: flex;
`;

export default TeamRiderListing;
