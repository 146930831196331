import * as React from 'react';
import styled from 'styled-components';
import {
  TextArea,
  TextAreaChangeEvent,
  TextAreaFocusEvent,
} from '@progress/kendo-react-inputs';
import SimpleReactValidator from 'simple-react-validator';
import { useState } from 'react';
import { FormSkeletonLoader } from '../FormSkeletonLoader';
export type TextAreaProps = {
  onChangeText?: (event: TextAreaChangeEvent) => void;
  children?: React.ReactNode;
  disabled?: boolean;
  defaultValue?: any;
  label?: string;
  rows?: number;
  placeholder?: string;
  autoSize?: boolean;
  className?: string;
  value?: any | null;
  validator?: SimpleReactValidator;
  validations?: string;
  permissionId?: string;
  autoFocus?: boolean;
  onFocus?: ((event: TextAreaFocusEvent) => void) | undefined;
  onClick?: React.MouseEventHandler<HTMLTextAreaElement> | undefined;
  onBlurCapture?: React.FocusEventHandler<HTMLTextAreaElement> | undefined;
  readOnly?: boolean | undefined;
  hideStar?: boolean;
  name?: string;
  isLoader?: boolean;
};
export const AdminTextArea = ({
  onChangeText,
  label,
  disabled,
  rows,
  placeholder,
  autoSize,
  className,
  value,
  validator,
  validations,
  autoFocus,
  onFocus,
  onClick,
  onBlurCapture,
  readOnly,
  hideStar,
  name,
  isLoader,
}: TextAreaProps) => {
  const simpleValidator = React.useRef(validator);
  const [validateField, setValidateField] = useState(false);
  const handleChange = e => {
    onChangeText?.(e);
    setValidateField(validateField);
  };
  const handleBlur = e => {
    if (!e?.target?.value && validations) {
      simpleValidator?.current?.showMessageFor(
        label?.toString()! ? label?.toString()! : 'field',
      );
      setValidateField(!validateField);
    }
  };
  return (
    <>
      <Wrapper className={className}>
        {!isLoader ? (
          <>
            <div className="label">
              {label}{' '}
              {!disabled && !hideStar && validations ? (
                <span className="red-star">*</span>
              ) : null}
            </div>
            <TextArea
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleBlur}
              rows={rows ? rows : 3}
              placeholder={placeholder}
              autoSize={autoSize}
              value={value}
              autoFocus={autoFocus}
              onFocus={onFocus}
              onClick={onClick}
              onBlurCapture={onBlurCapture}
              readOnly={readOnly}
              name={name}
            />
            <Errortext className="error-text">
              {simpleValidator?.current?.message(
                label?.toString() ? label?.toString() : 'field',
                value?.toString() && value?.toString(),
                validations ?? '',
              )}
            </Errortext>
          </>
        ) : (
          <FormSkeletonLoader textHeight={137} labelHeight={20} isLabel />
        )}
      </Wrapper>
    </>
  );
};
const Wrapper = styled.div`
  .red-star {
    color: red;
    font-weight: bold;
  }

  TextArea {
    background: white;
    border: 1px solid #caced8;
    height: 137px;
    width: 100%;
    padding: 0 10px;
    border-radius: 8px;
    text-size-adjust: 100%;
    color: #475467;
    font-family: 'Inter';
    font-size: 14px;
    resize: none;
    ::placeholder {
      color: #9597a6;
      padding-left: 10px;
      font-weight: 500;
      font-size: 14px;
      font-family: 'Inter';
    }
    :focus-visible {
      outline: unset !important;
    }
    :disabled {
      opacity: 1;
      border-color: #f5f5f5 !important;
      background-color: #fafafa !important;
      &.red-star {
        display: none !important;
      }
    }
  }
  .k-input {
    opacity: 1;
    border-color: #f5f5f5 !important;
    background-color: #fafafa !important;
  }
  .k-input-solid:focus-within {
    border-color: unset;
    color: unset;
    background-color: unset;
    box-shadow: none;
  }
  /* Chrome, Safari, Edge, Opera */
  TextArea ::-webkit-outer-spin-button,
  TextArea::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .label {
    font-family: 'Inter';
    color: #a3a3a3;
    font-size: 13px;
  }
  .error-text {
    font-size: 13px;
  }

  //Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    .label,
    .error-text {
      font-size: 10px;
    }
    TextArea {
      font-size: 11px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .label,
    .error-text {
      font-size: 11px;
    }
    TextArea {
      font-size: 11px;
    }
  }
  @media (min-width: 576px) and (max-width: 1365px) {
    .label,
    .error-text {
      font-size: 12px;
    }
    TextArea {
      font-size: 13px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .label,
    .error-text {
      font-size: 13px;
    }
  }
  //Component Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    input {
      height: 30px;
      font-size: 12px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    input {
      height: 30px;
      font-size: 12px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    input {
      font-size: 12px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    input {
      font-size: 13px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    input {
      font-size: 13px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    input {
      font-size: 15px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    input {
      font-size: 16px;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    input {
      font-size: 17px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    input {
      font-size: 17px;
    }
  }
`;
const Errortext = styled.span`
  display: block;
  font-size: 13px;
  color: red;
  padding-top: 4px !important;
  width: 280px;
  text-align: left;
  font-family: 'Inter';
`;
