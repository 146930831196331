import { GridCellProps } from '@progress/kendo-react-grid';
import { DetailArrowIcon } from 'app/assets/icons';
import { SHToolTip } from 'app/components/SHToolTip';
import styled from 'styled-components';

type Props = {
  className?: string;
  e: GridCellProps;
  onClick?: (e) => void;
  onViewClick?: (e) => void;
};

const TeamNameCell = ({ className, e, onClick, onViewClick }: Props) => {
  const data = e?.dataItem?.teamName;
  return (
    <StyledTd
      style={e.style}
      className={e.className}
      onClick={() => onViewClick?.(e?.dataItem?.id)}
    >
      <CustomTableColumnWrapper className="new-test">
        <SHToolTip position="top" className="new-check">
          <TeamWrapper
            style={{ color: '#101828' }}
            title={data}
            className="new-check"
          >
            {data}
          </TeamWrapper>
        </SHToolTip>
        <div>
          <DetailArrowIcon />
        </div>
      </CustomTableColumnWrapper>
    </StyledTd>
  );
};

export default TeamNameCell;
const TeamWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const StyledTd = styled.td`
  cursor: pointer;
  border-right: 1px solid #eaecf0 !important;
  border-left: 1px solid #eaecf0 !important;
`;
const CustomTableColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 0 0px;
  > div {
    max-width: calc(100% - 30px);
  }
`;
