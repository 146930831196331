import { BackNextButton } from 'app/components/BackNextButton';
import React from 'react';
import styled from 'styled-components';
import '@fontsource/roboto';
import { useState } from 'react';
import SHdropdown from 'app/components/SHdropdown';
import {
  GeneralLookup,
  GeneralLookupWrapper,
  RegisterRider,
  RiderDivisionResponse,
} from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';
import Cookies from 'js-cookie';
import { getLookupArray } from 'utils/common';

type Props = {
  onClickBackEnrolment?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onClickNxtEnrolment?: () => void;
  riderData: RegisterRider;
  setRiderData: React.Dispatch<React.SetStateAction<RegisterRider>>;
  categoryData?: GeneralLookup[];
  teamFilteredCategory: GeneralLookup[] | undefined;
  seasonDivison: (string[] | undefined)[] | undefined;
  riderDivisionRes: RiderDivisionResponse | undefined;
  setIsTermsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  setDatas: React.Dispatch<any>;
  datas: any;
  lookUp: GeneralLookupWrapper[];
  setTeamFilteredCategory: React.Dispatch<
    React.SetStateAction<GeneralLookup[] | undefined>
  >;
};

const EnrolmentData = ({
  onClickNxtEnrolment,
  onClickBackEnrolment,
  riderData,
  setRiderData,
  categoryData,
  teamFilteredCategory,
  seasonDivison,
  riderDivisionRes,
  setIsTermsChecked,
  setDatas,
  datas,
  lookUp,
  setTeamFilteredCategory,
}: Props) => {
  const simpleValidator = React.useRef(new SimpleReactValidator());

  let categoryLookUpList = getLookupArray('CATEGORY', lookUp);
  let divisionDefaultValue = riderData.divisionClassOne;
  let divisionTwoDefaultValue = riderData.divisionClassTwo;
  React.useEffect(() => {
    setDivisionOneDefault(divisionDefaultValue);
    setDivisionTwoDefault(divisionTwoDefaultValue);
  }, [divisionDefaultValue, divisionTwoDefaultValue]);
  const [valid, setValid] = useState<boolean>(false);

  const [divisionOneDefault, setDivisionOneDefault] = React.useState<
    GeneralLookup | undefined
  >(riderData.divisionClassOne);
  const [divisionTwoDefault, setDivisionTwoDefault] = React.useState<
    GeneralLookup | undefined
  >(riderData.divisionClassTwo);

  const [filteredDivisonTwo, setFilteredDivisonTwo] = useState<GeneralLookup[]>(
    [],
  );
  const handleCategorydataChange = (data: GeneralLookup) => {
    if (riderData) {
      setRiderData({
        ...riderData,
        category: data,
        divisionClassOne: { id: '', name: '' },
        divisionClassTwo: undefined,
      });

      setDatas(prevDatas => {
        const filteredData = categoryData?.filter(
          item => item?.name === data.name,
        );
        const filtered = filteredData?.[0]?.children || [];

        if (riderDivisionRes?.division?.name === 'Opal') {
          return filtered;
        } else if (riderDivisionRes?.division?.name === 'Emerald') {
          return filtered.filter(gem => !gem.name.includes('Opal'));
        } else if (riderDivisionRes?.division?.name === 'Ruby') {
          return filtered.filter(
            gem => !gem.name.includes('Opal') && !gem.name.includes('Emerald'),
          );
        } else if (riderDivisionRes?.division?.name === 'Sapphire') {
          return filtered?.filter(
            gem =>
              !gem.name.includes('Opal') &&
              !gem.name.includes('Emerald') &&
              !gem.name.includes('Ruby'),
          );
        } else if (riderDivisionRes?.division?.name === 'Diamond') {
          return filtered?.filter(
            gem =>
              !gem.name.includes('Opal') &&
              !gem.name.includes('Emerald') &&
              !gem.name.includes('Ruby') &&
              !gem.name.includes('Sapphire'),
          );
        } else if (riderDivisionRes?.division?.name === 'Pearl') {
          return filtered?.filter(gem => gem.name.includes('Pearl'));
        }
        return prevDatas;
      });
    }
  };
  const handleTeamChange = data => {
    let defaultData = {
      id: '',
      name: '',
      code: null,
      children: [],
      otherName: null,
      isAdminLive: false,
    };

    if (data) {
      if (riderData) {
        setRiderData?.({
          ...riderData,
          team: data,
          category: defaultData,
          divisionClassOne: defaultData,
          divisionClassTwo: defaultData,
        });

        setTeamFilteredCategory(
          data?.children!.filter(
            item =>
              riderDivisionRes?.category?.name.toLowerCase() ===
              item?.name.toLowerCase(),
          ),
        );
        setDatas(prevDatas => {
          const filteredData = categoryLookUpList?.filter(
            item =>
              item?.name ===
              data?.children!.filter(
                item =>
                  riderDivisionRes?.category?.name.toLowerCase() ===
                  item?.name.toLowerCase(),
              )[0]?.name,
          );
          const filtered = filteredData?.[0]?.children || [];

          if (riderDivisionRes?.division?.name === 'Opal') {
            return filtered;
          } else if (riderDivisionRes?.division?.name === 'Emerald') {
            return filtered.filter(gem => !gem.name.includes('Opal'));
          } else if (riderDivisionRes?.division?.name === 'Ruby') {
            return filtered.filter(
              gem =>
                !gem.name.includes('Opal') && !gem.name.includes('Emerald'),
            );
          } else if (riderDivisionRes?.division?.name === 'Sapphire') {
            return filtered?.filter(
              gem =>
                !gem.name.includes('Opal') &&
                !gem.name.includes('Emerald') &&
                !gem.name.includes('Ruby'),
            );
          } else if (riderDivisionRes?.division?.name === 'Diamond') {
            return filtered?.filter(
              gem =>
                !gem.name.includes('Opal') &&
                !gem.name.includes('Emerald') &&
                !gem.name.includes('Ruby') &&
                !gem.name.includes('Sapphire'),
            );
          } else if (riderDivisionRes?.division?.name === 'Pearl') {
            return filtered?.filter(gem => gem.name.includes('Pearl'));
          }
          return prevDatas;
        });
      }
    }
  };
  const handleDivisionOne = e => {
    const selectedGem =
      seasonDivison &&
      seasonDivison[0]?.filter(item => {
        return e.name?.includes(item);
      });
    const filteredDivisonArray = datas?.filter(item =>
      item.name.includes(selectedGem),
    );
    const divisonTwofiltered = filteredDivisonArray?.filter(item => item !== e);
    setRiderData({
      ...riderData,
      divisionClassOne: e,
      divisionClassTwo: undefined,
    });
    setFilteredDivisonTwo(divisonTwofiltered);
  };
  const handleDivisionTwo = e => {
    setRiderData({ ...riderData, divisionClassTwo: e });
  };

  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [divisionOneDefault, riderData?.category, divisionTwoDefault]);
  React.useEffect(() => {
    const paymentCancelStatus = Cookies.get('paymentCancel');
    if (paymentCancelStatus) setIsTermsChecked(true);
  }, []);
  return (
    <MainWrapper className="form-MainWrapper">
      <OverflowWrapper className="OverflowWrapper">
        <p>Your new year (2023-2024) enrolment data</p>
        <p className="proceed-para">
          * In order to proceed, you need to choose a team name to unlock the
          category selection. After selecting a category, you can then choose
          Division 1. Following this, you will have the option to choose
          Division 2, which is optional.
        </p>
        <TextFieldGrid className="TextFieldGrid">
          <TextFieldWrapper className="TextFieldWrapper ">
            <SHdropdown
              onDataChange={handleTeamChange}
              data={getLookupArray('ACTIVETEAM', lookUp)}
              label="Team Name"
              defaultValue={riderData?.team!}
              className="drop-down"
              validations={'required'}
              validator={simpleValidator.current}
              categoryCheck={teamFilteredCategory}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <SHdropdown
              className="drop-down"
              label="Category"
              data={teamFilteredCategory}
              onDataChange={data => handleCategorydataChange(data)}
              defaultValue={riderData?.category}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <SHdropdown
              className="drop-down"
              label="Division 1"
              data={datas}
              onDataChange={handleDivisionOne}
              defaultValue={divisionOneDefault}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <SHdropdown
              className="drop-down"
              label="Division 2"
              data={filteredDivisonTwo}
              onDataChange={handleDivisionTwo}
              defaultValue={divisionTwoDefault}
              validations={'required'}
              validator={simpleValidator.current}
              // disabled={isDivisionOnevalue}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
      </OverflowWrapper>

      <ButtonWrapper className="bg-color-change">
        <BackNextButton
          classNameBack="btn-back"
          classNameNxt="btn-nxt"
          onClickNxt={() => onClickNxtEnrolment?.()}
          onClickBack={onClickBackEnrolment}
          next="Next"
          back="Back"
          disableNext={valid}
        />
      </ButtonWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  color: #9597a6;
  width: 100%;
  height: calc(100vh - 182px);
  padding: 20px 0px 0px;
  position: relative;
  .bottom-para {
    font-size: 12px;
    position: absolute;
    z-index: 3;
    bottom: 43px !important;
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 9px;
      bottom: 50px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 10px;
      bottom: 55px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 11px;
      bottom: 40px;
    }
    @media (min-width: 1200px) and (max-width: 1365px) {
      font-size: 12px;
      bottom: 60px;
    }
    @media (min-width: 1366px) and (max-width: 1448px) {
      font-size: 12px;
      bottom: 65px;
    }
    @media (min-width: 1449px) and (max-width: 1719px) {
      font-size: 13px;
      bottom: 65px;
    }
    @media (min-width: 1720px) and (max-width: 4000px) {
      font-size: 14px;
      bottom: 70px;
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    label {
      font-size: 14px;
    }
  }
`;
const TextFieldWrapper = styled.div`
  width: 50%;
  margin-bottom: 20px;
`;
const OverflowWrapper = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
  padding: 20px 60px 100px 60px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 5px;
    background: transparent !important;
    border-radius: 10px;
  }
  .enrollment-bottom-para bottom-para {
    padding: 10px 0px;
  }
`;
const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 30px 12px 0 12px;
  span {
    color: #306fdb;
    text-decoration: underline;
  }
  p {
    margin: 0px;
  }
`;
const ButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;
  position: absolute;
  bottom: -5px;
  background: linear-gradient(
    to bottom,
    rgb(36 36 36 / 27%) 0%,
    rgb(42 42 42) 100%
  );
  z-index: 2;
  border: 1px solid rgb(42 42 42);
  border-radius: 0 0 20px 20px;
  backdrop-filter: blur(5px);

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;

export default EnrolmentData;
