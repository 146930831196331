/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActiveRiderWrapper } from '../models/ActiveRiderWrapper';
import type { AdminRiderRegistration } from '../models/AdminRiderRegistration';
import type { RiderRegistrationResponse } from '../models/RiderRegistrationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ManageRidersService {

    /**
     * API to update/create rider.
     * @param requestBody
     * @returns RiderRegistrationResponse Success
     * @throws ApiError
     */
    public static manageRidersUpdateOrCreateRider(
        requestBody?: AdminRiderRegistration,
    ): CancelablePromise<RiderRegistrationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/riders',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get rider list
     * @param season
     * @param teamFilter
     * @param divisionFilter
     * @param searchInput
     * @param status
     * @param sortBy
     * @param sortOrder
     * @param pageNumber
     * @param pageSize
     * @returns ActiveRiderWrapper Success
     * @throws ApiError
     */
    public static manageRidersGetActiveRiders(
        season?: string,
        teamFilter?: Array<string>,
        divisionFilter?: Array<string>,
        searchInput?: string,
        status?: string,
        sortBy?: string,
        sortOrder?: number,
        pageNumber: number = 1,
        pageSize: number = 10,
    ): CancelablePromise<Array<ActiveRiderWrapper>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/riders/admin/riders',
            query: {
                'season': season,
                'teamFilter': teamFilter,
                'divisionFilter': divisionFilter,
                'searchInput': searchInput,
                'status': status,
                'sortBy': sortBy,
                'sortOrder': sortOrder,
                'pageNumber': pageNumber,
                'pageSize': pageSize,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * API to delete rider info
     * @param id
     * @returns string Success
     * @throws ApiError
     */
    public static manageRidersDeleteRiderInfo(
        id: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/admin/riders/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to update Rider Status
     * @param id
     * @param activeStatusId
     * @returns string Success
     * @throws ApiError
     */
    public static manageRidersUpdateRiderStatus(
        id: string,
        activeStatusId?: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/riders/{id}/status',
            path: {
                'id': id,
            },
            query: {
                'activeStatusId': activeStatusId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to check back number is taken or not
     * @param backNumber
     * @param isTryIt
     * @param riderId
     * @returns boolean Success
     * @throws ApiError
     */
    public static manageRidersRiderBackNumberTakenOrNot(
        backNumber?: string,
        isTryIt?: boolean,
        riderId?: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/riders/backNumber',
            query: {
                'backNumber': backNumber,
                'isTryIt': isTryIt,
                'riderId': riderId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

}
