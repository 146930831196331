import React from 'react';
import {
  Dialog,
  DialogActionsBar,
  DialogCloseEvent,
  DialogProps,
} from '@progress/kendo-react-dialogs';
import styled from 'styled-components';
import { FormSecondaryLabel } from 'app/components/SHLabel';
import RadioButtonGroup from 'app/components/RadioButtonGroup';
import { RadioGroupChangeEvent } from '@progress/kendo-react-inputs';
import { log } from 'console';

type Props = {
  onClose?: (event: DialogCloseEvent) => void;
  show?: boolean;
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
  onChange?: (event: RadioGroupChangeEvent) => void;
  setFormNumber?: React.Dispatch<React.SetStateAction<number>>;
  setInsuranceRadioVal?: React.Dispatch<
    React.SetStateAction<string | null | undefined>
  >;
  heading?: string;
  setShowInsurance?: React.Dispatch<React.SetStateAction<boolean>>;
};

const InsurancePopUp = ({
  onClose,
  show,
  setShow,
  onChange,
  setFormNumber,
  setInsuranceRadioVal,
  heading,
  setShowInsurance,
}: Props) => {
  const toggleDialog = e => {
    setShow?.(!show);
    onClose?.(e);
  };
  const isHandleChange = e => {
    console.log(e.value, 'isHandleChangepopup');

    if (e.value === false) {
      // setShowInsurance?.(true);
      setShow?.(false);
      setInsuranceRadioVal?.('');
      // setSelectFile(false);
    } else {
      // setShowInsurance?.(false);
      setShow?.(false);
      setFormNumber?.(2);
    }
  };
  return (
    <>
      {show ? (
        <StyledDialog onClose={toggleDialog}>
          <CloseIcon className="CloseIcon" onClick={toggleDialog}>
            <span className="k-icon k-i-close"></span>
          </CloseIcon>
          <ContentWrapper className="ContentWrapper">
            <QuestionWrapper>
              <StyledFormSecondaryLabel>{heading}</StyledFormSecondaryLabel>
              <RadioWrapper>
                <RadioButtonGroup onChange={isHandleChange} />
              </RadioWrapper>
            </QuestionWrapper>
          </ContentWrapper>
        </StyledDialog>
      ) : (
        ''
      )}
    </>
  );
};
const StyledDialog = styled(Dialog)<DialogProps>`
  backdrop-filter: blur(8px);
  .k-overlay {
  }
  .k-window.k-dialog {
    border: none !important;
    max-height: 230px !important;
    min-height: 230px !important;
    background: transparent !important;
    width: 600px !important;
  }
  .k-dialog-content {
    background: transparent !important;
    border-radius: 13px !important;
    position: relative;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .k-window.k-dialog {
      width: 95% !important;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .k-window.k-dialog {
      width: 95% !important;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .k-window.k-dialog {
      width: 430px !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
  @media (min-width: 992px) and (max-width: 1199px) {
  }
`;
const StyledFormSecondaryLabel = styled(FormSecondaryLabel)`
  font-size: 16px !important;
  color: #d1d1d1 !important;
  @media (min-width: 100px) and (max-width: 319px) {
    font-size: 10px !important;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    font-size: 12px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 13px !important;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 14px !important;
  }
`;
const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 25px;
`;
const QuestionWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: 0 20px;
`;
const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #27292d !important;
  border-radius: 10px;
  padding: 10px;
`;

const CloseIcon = styled.div`
  color: #5a5a5a;
  position: absolute;
  right: 4px;
  top: 3px;
  background: #262626;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid #5a5a5a;
  cursor: pointer;
`;
export default InsurancePopUp;
