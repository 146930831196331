import React from 'react';
import { Card, CardBody } from '@progress/kendo-react-layout';
import styled from 'styled-components';

type Props = {
  heading?: string;
  contentOne?: string;
  contentTwo?: string;
  bgColor?: 'one' | 'two' | 'three';
  titleType?: 'show' | 'hidden' | 'keepSpace';
};

const SifCard = ({
  heading,
  contentOne,
  contentTwo,
  bgColor,
  titleType,
}: Props) => {
  return (
    <MainWrapper>
      <Card className={`card-wrapper ${bgColor}`}>
        <StyledCardBody>
          <h4 className={`title ${titleType}`}>{heading}</h4>
          <p>{contentOne}</p>
          <p>{contentTwo}</p>
        </StyledCardBody>
      </Card>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  @media (min-width: 100px) and (max-width: 576px) {
    height: 100%;
  }
  width: 100%;
  height: 100%;
  .card-wrapper {
    border-radius: 15px;
    width: 100%;
    height: 100%;
    border: 1px solid #d9f0ff;

    &.one {
      background-color: #fff8f5;
    }
    &.two {
      background-color: #f7f8ff;
    }
    &.three {
      background-color: #edf7fd;
    }
  }
  .title {
    &.show {
      display: block;
    }
    &.hidden {
      display: none;
    }
    &.keepSpace {
      visibility: hidden;
    }
  }
  .k-card-title {
    font-size: 11px;
  }
`;
const StyledCardBody = styled(CardBody)`
  @media (min-width: 100px) and (max-width: 576px) {
    padding-top: 30px;
    p {
      line-height: 1.5 !important;
      font-size: 12px !important;
    }
  }
  padding: 40px 10px 10px;
  text-align: center;
  height: 100%;

  p {
    margin: 0px;
    font-size: 15px;
    line-height: 2.5;
  }
  h4 {
    font-size: 16px;
    margin: 0px 0px 10px;
  }
  img {
    padding-bottom: 10px;
    width: 70px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    p {
      margin: 0px;
    }
    h4 {
      font-size: 13px;
      margin: 0px;
    }
    img {
      padding-bottom: 5px;
      width: 45px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    h4 {
      font-size: 11px;
    }
    img {
      padding-bottom: 5px;
      width: 50px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    h4 {
      font-size: 13px;
    }
    img {
      padding-bottom: 10px;
      width: 60px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    p {
      font-size: 16px;
    }
    h4 {
      font-size: 15px;
    }
    img {
      padding-bottom: 10px;
      width: 70px;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    h4 {
      font-size: 13px;
    }
    img {
      padding-bottom: 10px;
      width: 70px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    h4 {
      font-size: 13px;
    }
    img {
      padding-bottom: 10px;
      width: 70px;
    }
  }
`;
export default SifCard;
