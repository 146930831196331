import { GridCellProps } from '@progress/kendo-react-grid';
import { SHToolTip } from 'app/components/SHToolTip';
import styled from 'styled-components';

type Props = {
  className?: string;
  e: GridCellProps;
};

const ShowHostCell = ({ className, e }: Props) => {
  const data = e?.dataItem;
  return (
    <td className={className ?? ''}>
      <SHToolTip position="top">
        <ContactWrapper title={data?.hostedBy}>{data?.hostedBy}</ContactWrapper>
      </SHToolTip>
    </td>
  );
};

export default ShowHostCell;
const ContactWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
  max-width: 150px;
`;
