import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { BackNextButton } from 'app/components/BackNextButton';
import RadioButtonGroup from 'app/components/RadioButtonGroup';
import {
  FormSecondaryLabel,
  InfoLabel,
  TeamListLabel,
} from 'app/components/SHLabel';
import { useManageCoachSlice } from '../../slice';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

type Props = {};

const ApplyingForCoachResgistration = (props: Props) => {
  const navigate = useNavigate();
  const { actions } = useManageCoachSlice();
  const dispatch = useDispatch();
  const coachUrl = '/coach-registration/coach';
  const assistantCoachUrl = '/coach-registration/assistant-coach';
  const [insuranceRadioVal, setInsuranceRadioVal] = React.useState<
    number | undefined
  >(undefined);
  const [visible, setVisible] = React.useState<boolean>(false);
  //DummyData
  const Defaultdata1 = [
    { label: 'Coach', value: 1 },
    { label: 'Assistant Coach', value: 2 },
  ];
  const handleCancel = () => {
    console.log('Going back from Next');
    Cookies.remove('returningPayload');
    Cookies.remove('newPayload');
    Cookies.remove('paymentCancel');
    Cookies.remove('issuranceRadioButtonpayload');
    Cookies.remove('insurancePayload');
    navigate('/login');
  };
  const handleNxt = () => {
    if (insuranceRadioVal === 1) {
      navigate(coachUrl);
    } else {
      navigate(assistantCoachUrl);
    }
  };
  const isHandleChange = e => {
    console.log(e, 'eww');
    setInsuranceRadioVal(e.value);
  };
  const handleShowTeamList = () => {
    setVisible(!visible);
  };
  const handleCloseTeamList = () => {
    setVisible(false);
  };
  return (
    <MainWrapper>
      <SubMainWrapper className="SubMainWrapper">
        <BorderWrapper className="inner-BorderWrapper">
          {/* <InfoLabelWrapper id="InfoLabelWrapper">
            <div>
              <InfoLabel>
                f Kindly check the list of Teams which have renewed memberships
                for the year till now. If you do not find the team you wish to
                be a part of, check back later.
              </InfoLabel>
            </div>
            <TeamListLabel
              onClick={handleShowTeamList}
              className="TeamListLabel"
            >
              Team list 2023-24
            </TeamListLabel>
          </InfoLabelWrapper> */}
          <TextFieldGrid>
            <QuestionWrapper className="QuestionWrapper">
              <FormSecondaryLabel className="apply-label">
                Applying for?
              </FormSecondaryLabel>
              <RadioWrapper>
                <RadioButtonGroup
                  onChange={isHandleChange}
                  value={insuranceRadioVal}
                  data={Defaultdata1}
                />
              </RadioWrapper>
            </QuestionWrapper>
          </TextFieldGrid>

          <MainAddressWrapper></MainAddressWrapper>
        </BorderWrapper>
      </SubMainWrapper>
      <BottomRightWrapper id="BottomRightWrapper">
        <ButtonWrapper>
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickBack={handleCancel}
            onClickNxt={handleNxt}
            next="Next"
            back="Back"
            disableNext={insuranceRadioVal === undefined}
          />
        </ButtonWrapper>
      </BottomRightWrapper>
    </MainWrapper>
  );
};

export default ApplyingForCoachResgistration;

const MainWrapper = styled.div`
  color: #9597a6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-top: 50px;
  z-index: 2;
  position: relative;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
  .first-para,
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 40px;
    .first-para,
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    padding-top: 45px;
    .first-para,
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    padding-top: 50px;
    .first-para,
    label {
      font-size: 14px;
    }
  }
`;
const SubMainWrapper = styled.div`
  height: 100%;
  width: 100%;
`;
const BorderWrapper = styled.div`
  height: 100%;
  overflow: auto;
  /* border: 1px solid #585858; */
  border-radius: 20px;
  padding: 20px 60px 0px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  position: relative;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: #201e1e !important;
    border-radius: 10px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    padding: 20px 25px 0px 25px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding: 20px 15px 0px 15px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
  }
`;
const InfoLabelWrapper = styled.div`
  padding: 0 20px 10px 20px;
  text-align: start;
  width: 100%;
  div {
    /* border-bottom: 1px solid #434343; */
    padding: 6px 0;
    label {
      font-style: normal;
    }
  }
`;

const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 15px 20px;
  .RadioButtonGroupWrapper {
    margin: 0px 12px;
    /* ul {
      padding: 0 !important;
    } */
  }

  @media (min-width: 100px) and (max-width: 319px) {
    padding: 15px 10px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding: 15px 10px;
  }

  /* gap: 15px; */
  @media (min-width: 992px) and (max-width: 1199px) {
    /* gap: 5px; */
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    /* gap: 10px; */
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    /* gap: 15px; */
  }
`;

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 100px) and (max-width: 319px) {
  }
  @media (min-width: 320px) and (max-width: 374px) {
  }
`;
const QuestionWrapper = styled.div`
  width: 100%;
  padding: 30px 0 30px 0;
  /* border-bottom: 1px solid #434343; */
  display: flex;
  flex-direction: column;
  ${FormSecondaryLabel} {
    margin: 0 0 15px 0;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    ${FormSecondaryLabel} {
      font-size: 11px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    ${FormSecondaryLabel} {
      font-size: 11px !important;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    ${FormSecondaryLabel} {
      font-size: 12px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    ${FormSecondaryLabel} {
      font-size: 12px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    ${FormSecondaryLabel} {
      font-size: 14px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    ${FormSecondaryLabel} {
      font-size: 14px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    ${FormSecondaryLabel} {
      font-size: 15px;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    ${FormSecondaryLabel} {
      font-size: 15px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    ${FormSecondaryLabel} {
      font-size: 16px;
    }
  }
`;

const MainAddressWrapper = styled.div`
  margin-bottom: 50px;
  padding: 0 0 50px 0;
  width: 100%;
  .horizontal-line {
    display: flex;
    align-items: baseline;
    margin: 20px 20px 15px 20px;
  }
  .horizontal-line span {
    margin: 0 10px 0 0;
    white-space: nowrap;
    color: #4f4f4f;
    font-size: 15px;
  }
  .horizontal-line::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #434343;
    height: 3px;
  }
`;
const BottomRightWrapper = styled.div`
  z-index: 1;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 20px 20px;
  backdrop-filter: blur(10px);
  position: absolute;
  bottom: 0;
  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    /* position: absolute;
    bottom: 40px;
    padding-top: 30px;
    background: linear-gradient(
      to bottom,
      rgb(48 48 48 / 79%) 0%,
      rgb(42 42 42) 100%
    ); */
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    /* height: 40px; */
    &.bg-color-change {
      bottom: 25px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    /* height: 45px; */
    &.bg-color-change {
      bottom: 30px;
      padding-top: 20px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    /* height: 60px; */
    &.bg-color-change {
      bottom: 20px;
      padding-top: 20px;
    }
  }
`;
const ButtonWrapper = styled.div``;
