import React, { useState } from 'react';
import styled from 'styled-components';
import SimpleReactValidator from 'simple-react-validator';
import { FormSkeletonLoader } from '../FormSkeletonLoader';
import { SHToolTip } from '../SHToolTip';
type Props = {
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string | number;
  placeholder?: string;
  label?: string;
  type?: string;
  style?: React.CSSProperties | undefined;
  name?: string;
  className?: string;
  disabled?: boolean;
  inputClassName?: string;
  validator?: SimpleReactValidator;
  validations?: string;
  maxLength?: number | undefined;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  hideStar?: boolean;
  isLoader?: boolean;
  validationLabel?: string;
  defaultValue?: string;
  autoFocus?: boolean;
  min?: number;
  max?: number;
};

export const AdminTextField = ({
  onChange,
  value,
  placeholder,
  label,
  type,
  style,
  name,
  className,
  disabled,
  inputClassName,
  validator,
  validations,
  maxLength,
  onKeyDown,
  onKeyUp,
  hideStar,
  isLoader,
  validationLabel,
  defaultValue,
  autoFocus,
  min,
  max,
}: Props) => {
  const simpleValidator = React.useRef(validator);
  const [validateField, setValidateField] = useState(false);
  const handleBlur = e => {
    e.preventDefault();
    if (!e?.target?.value && validations) {
      simpleValidator?.current?.showMessageFor(
        validationLabel?.toString()!
          ? validationLabel?.toString()!
          : label?.toString()!
          ? label?.toString()!
          : 'field',
      );
      setValidateField(!validateField);
    }
  };
  return (
    <>
      <InputWrapper className={`${className}`}>
        {!isLoader ? (
          <>
            <div className="label">
              {label}{' '}
              <span className="red-star">
                {validations && !hideStar ? '*' : ''}
              </span>
            </div>
            <SHToolTip position="top">
              <input
                title={
                  disabled
                    ? defaultValue
                      ? String(defaultValue)
                      : String(value)
                    : ''
                }
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                type={type}
                style={style}
                name={name}
                disabled={disabled}
                className={inputClassName}
                onBlur={handleBlur}
                maxLength={maxLength}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                defaultValue={defaultValue}
                autoFocus={autoFocus}
                min={min}
                max={max}
              />
            </SHToolTip>
            <Errortext className="error-text">
              {simpleValidator?.current?.message(
                validationLabel?.toString()
                  ? validationLabel?.toString()
                  : label?.toString()
                  ? label?.toString()
                  : 'field',
                value?.toString() && value?.toString(),
                validations ?? '',
              )}
            </Errortext>
          </>
        ) : (
          <FormSkeletonLoader textHeight={44} labelHeight={20} isLabel />
        )}
      </InputWrapper>
    </>
  );
};
const InputWrapper = styled.div`
  .srv-validation-message {
    color: #f44336;
    font-size: 12px;
    display: inline-block;
  }
  .red-star {
    color: red;
    font-weight: bold;
  }

  :has(input:disabled) {
    .red-star {
      display: none !important;
    }
  }
  input {
    background: white;
    border: 1px solid #caced8;
    height: 44px;
    width: 100%;
    padding: 0 10px;
    border-radius: 8px;
    text-size-adjust: 100%;
    color: #475467;
    font-family: 'Inter';
    font-size: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 500;
    line-height: 18.15px;
    ::placeholder {
      color: #9597a6;
      padding-left: 10px;
      font-weight: 500;
      font-size: 15px;
      font-family: 'Inter';
    }
    :focus-visible {
      outline: unset !important;
    }
    :disabled {
      opacity: 1;
      cursor: default;
      border-color: #f5f5f5 !important;
      background-color: #fafafa !important;
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .label {
    font-family: 'Inter';
    color: #a3a3a3;
    margin-bottom: 5px;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
  }
  //Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    .label {
      font-size: 10px;
    }
    input {
      height: 30px;
      font-size: 11px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .label {
      font-size: 11px;
    }
    input {
      height: 30px;
      font-size: 12px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .label {
      font-size: 12px;
    }
    input {
      font-size: 13px;
    }
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    .label {
      font-size: 13px;
    }
    input {
      font-size: 14px;
    }
  }
  input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px white inset;
  }
`;
const Errortext = styled.span`
  display: block;
  font-size: 13px;
  color: red;
  padding-top: 4px !important;
  text-align: left;
  font-family: 'Inter';
`;
