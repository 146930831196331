import { GeneralLookup, HighSchoolTeam } from 'services/openapi';

export type showViewModel = {
  id: string;
  showName: string;
  imageUrl: string;
  season: GeneralLookup;
  hostedBy: string;
  showLocation: string;
  isNationalFinal: boolean;
  pointedShow: GeneralLookup;
  riderAddress: string;
  city: string;
  state: GeneralLookup;
  zip: string;
  status: GeneralLookup;
  showDate: string;
};
export const ShowDefaultData = {
  id: '4d6b20a0-c780-4ddb-9696-eddfc129ac79',
  showName: 'John Doe',
  imageUrl: 'https://picsum.photos/200/300',
  season: {
    id: '4d6b20a0-c780-4ddb-9696-eddfc129ac79',
    name: 'Active',
    children: null,
  },
  hostedBy: 'YEDA Founders',
  showLocation: 'Delaware',
  isNationalFinal: true,
  pointedShow: {
    id: '4d6b20a0-c780-4ddb-9696-eddfc129ac79',
    name: 'Active',
    children: null,
  },
  riderAddress: 'street, city, state, zip',
  city: 'city',
  state: {
    id: '4d6b20a0-c780-4ddb-9696-eddfc129ac79',
    name: 'state',
    children: null,
  },
  zip: '12345',
  status: {
    id: '4d6b20a0-c780-4ddb-9696-eddfc129ac79',
    name: 'Active',
    children: null,
  },
  showDate: '2024-05-27 10:28:32.1040000',
};
export const showsViewbreadCrumpitems = [
  {
    id: '',
    text: 'Showyeda admin',
    disabled: true,
  },
  {
    id: '/admin/shows',
    text: 'Shows',
  },
  {
    id: '',
    text: 'Shows Info',
    disabled: true,
  },
];
export const tabShowsNamesArray = [
  { tab: 'Show-Info', id: 0 },
  { tab: 'Manage-Rider', id: 1 },
];

export const showsResultbreadCrumpitems = [
  {
    id: '',
    text: 'Showyeda admin',
    disabled: true,
  },
  {
    id: '/admin/shows',
    text: 'Shows',
    disabled: false,
  },
  {
    id: '',
    text: 'Shows result',
    disabled: true,
  },
];

export const tabShowResultTabArray = [
  { tab: 'Show Status', id: 0 },
  { tab: 'High Point SR & JR', id: 1 },
  { tab: 'Score Board', id: 2 },
  { tab: 'Leader Point', id: 3 },
  { tab: 'Elementary', id: 4 },
];

export type FilterPayLoad = {
  showId: {
    id: string;
    name: string;
  };
  divisionId: {
    id: string;
    name: string;
  };
  categoryId: {
    id: string;
    name: string;
  };
  classFilter: {
    id: string;
    name: string;
  };
};
