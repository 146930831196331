import { GridCellProps } from '@progress/kendo-react-grid';
import { SHToolTip } from 'app/components/SHToolTip';
import styled from 'styled-components';

type Props = {
  className?: string;
  e: GridCellProps;
  onClick?: (e) => void;
};

const ShowAddressCell = ({ className, e, onClick }: Props) => {
  const data = e?.dataItem;
  return (
    <td className={className ?? ''}>
      <ContactWrapper>
        <SHToolTip position="top">
          <label title={data?.showAddress}>{data?.showAddress}</label>
        </SHToolTip>
      </ContactWrapper>
    </td>
  );
};

export default ShowAddressCell;

const ContactWrapper = styled.div`
  height: 100%;
  width: fit-content;
  min-height: 40px;
  align-items: center;
  display: flex;
  label {
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    font-family: 'Inter';
    line-height: 14.52px;
    height: fit-content;
  }
`;
