import { useNavigate } from 'react-router-dom';
import { GeneralLookup, GeneralLookupWrapper } from 'services/openapi';
import { format, parseISO } from 'date-fns';

export type RedirectPath = (redirectPath: string) => void;

export const getLookupArray = (
  lookup: string,
  lookUps: GeneralLookupWrapper[],
) => {
  return (
    lookUps.find(obj => {
      return obj.type === lookup;
    })?.values ?? []
  );
};

export function useNavigateToRoute(): RedirectPath {
  const navigate = useNavigate();

  function redirect(redirectPath: string) {
    navigate(redirectPath);
  }
  return redirect;
}
// to check empty array
export function isEmptyArray<T>(arr: T[]): boolean {
  return arr.length === 0;
}

export function findObjectById(
  array: GeneralLookup[],
  id: string,
): GeneralLookup | undefined {
  return array?.find(obj => obj.id === id);
}

export function filterByName(
  data: GeneralLookup[],
  name: string,
): GeneralLookup[] {
  return data.filter(obj => obj.name?.startsWith(name));
}

export const formatDate = date => {
  if (!date) {
    return '';
  }
  const formattedDate = new Date(date);
  return format(formattedDate, 'dd MMM yyyy');
};

export const isoString = date => {
  if (!date) {
    return '';
  }

  const formattedDate = format(
    parseISO(date.toISOString()),
    "yyyy-MM-dd'T'HH:mm:ss",
  );

  return formattedDate;
};

export function findMatchingDivisionClassAndRemoveSelected(
  e: GeneralLookup, // the selected object from division one
  divisionLookup: GeneralLookup[], // DIVISION lookup list
  divisionOneList: GeneralLookup[], // division one dropdown data
) {
  const nameList = divisionLookup?.map(obj => obj?.name?.toLowerCase());
  const searchWords = e?.name?.toLowerCase().split(' ');
  const commonStrings = nameList.filter(name => searchWords?.includes(name));
  const objects = divisionOneList.filter(obj =>
    obj.name?.toLowerCase()?.includes(commonStrings[0]),
  );
  const filteredData = objects.filter(obj => obj.id !== e.id);
  return filteredData;
}

export const isEmtyString = data => {
  const isEmptyString = data === '';
  return isEmptyString;
};

export const isNullOrZero = data => {
  const isEmtyNumber = data === null || data === 0;
  return isEmtyNumber;
};

export const capitalizeFirstLetter = text => {
  return text?.charAt(0).toUpperCase() + text?.slice(1);
};

export const seasonValue = lookUps => {
  let filteredArray = getLookupArray('SEASON', lookUps).filter(
    obj => obj.isAdminLive,
  );
  return filteredArray[0];
};
