import styled from 'styled-components';
import { ProfileImageUploadCard } from 'app/components/ProfileImageUploadCard';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import { LabelMedium } from 'app/components/SHLabel';
import { capitalizeFirstLetter, useNavigateToRoute } from 'utils/common';
import { useLocation, useParams } from 'react-router-dom';
import { AdminEditIcon } from 'admin/assets/icon';
import { formatDate } from 'utils/common';
import { ShowRegistration } from 'services/openapi';
import { CardSkeletonLoader } from 'app/components/CardSkeletonLoader';
import { SHToolTip } from 'app/components/SHToolTip';

type Props = {
  showData: ShowRegistration | undefined;
  loading?: boolean;
};

export const ShowViewProfile = ({ showData, loading }: Props) => {
  const redirect = useNavigateToRoute();
  const currentLocation = useLocation();
  const isPastDataFlag = currentLocation.pathname.includes('past-data');
  const { id: showId } = useParams();
  const handleEditClick = () => {
    redirect(`/admin/shows/edit/${showId}`);
  };

  return (
    <StyledCommonPageWrapper>
      {loading ? (
        <CardSkeletonLoader
          isProfileImageLoader
          isHeaderLoader
          isTextLoader
          lineContent={3}
          headerWidth={300}
        />
      ) : (
        <ProfileImageUploadCard
          buttonLabel="Edit"
          buttonType="secondary"
          svgIcon={<AdminEditIcon />}
          onButtonClick={handleEditClick}
          className="ProfileImageUploadCard"
          imgSrc={showData?.imageUrl}
          isPastDataFlag={isPastDataFlag}
        >
          <GridLayout
            gap={{ rows: 0, cols: 10 }}
            rows={[{ height: 'auto' }, { height: 'auto' }]}
            cols={[
              { width: '25%' },
              { width: '25%' },
              { width: '25%' },
              { width: '25%' },
            ]}
            className="profilecard-grid"
          >
            <GridLayoutItem row={1} col={1} colSpan={4} className="first-item">
              <h3 className="header-title back-number">
                {capitalizeFirstLetter(showData?.showName) ?? ''}
                <span>({showData?.season?.name} )</span>{' '}
              </h3>
            </GridLayoutItem>
          </GridLayout>
          <GridLayout
            gap={{ rows: 0, cols: 10 }}
            rows={[{ height: 'auto' }, { height: 'auto' }]}
            cols={[
              { width: '25%' },
              { width: '25%' },
              { width: '25%' },
              { width: '25%' },
            ]}
            className="profilecard-grid"
          >
            <GridLayoutItem
              row={2}
              col={1}
              colSpan={2}
              className="hosted-style"
            >
              <CustomLabelMedium color="#475467">
                Hosted By : <span color="#9F0404">{showData?.hostedBy} </span>
              </CustomLabelMedium>
            </GridLayoutItem>
            <GridLayoutItem row={3} col={1} colSpan={2}>
              <CustomLabelMedium color="#475467">
                Date : <span>{formatDate(showData?.showStartDate)}</span>
              </CustomLabelMedium>
            </GridLayoutItem>
            <GridLayoutItem
              row={4}
              col={1}
              colSpan={2}
              className="location-style"
            >
              <CustomLabelMedium color="#475467">
                Location : <span>{showData?.showLocation}</span>
              </CustomLabelMedium>
            </GridLayoutItem>
            <GridLayoutItem row={2} col={3} colSpan={2} className="right-side">
              <CustomLabelMedium color="#475467">
                Address : <span>{showData?.address}</span>
              </CustomLabelMedium>
            </GridLayoutItem>
            <GridLayoutItem row={3} col={3} colSpan={2} className="right-side">
              <CustomLabelMedium color="#475467"></CustomLabelMedium>
            </GridLayoutItem>
            <GridLayoutItem row={4} col={3} colSpan={2} className="right-side">
              <CustomLabelMedium color="#475467"></CustomLabelMedium>
            </GridLayoutItem>
          </GridLayout>
        </ProfileImageUploadCard>
      )}
    </StyledCommonPageWrapper>
  );
};

const StyledCommonPageWrapper = styled.div`
  .ProfileImageUploadCard {
    padding: 24px 40px 34px 40px !important;
    align-items: center;
    button {
      span {
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.36px;
      }
    }
    .image-upload {
      width: 122.11px;
      min-width: 122.11px !important;
      height: 122.11px;
    }
    .child-wrapper {
      width: calc(100% - 215px);
      .first-item {
        padding-bottom: 17px;
      }
    }
    .hosted-style {
      span {
        color: #9f0404;
      }
    }
    .location-style {
      span {
        color: #0028ff;
      }
    }
    .field-style {
      border-color: #515e71;
    }
    &.CheckAfiliation {
      font-size: 13px !important;
      font-weight: 500 !important;
      color: #a3a3a3 !important;
    }
    .header-title {
      font-family: 'Inter';
      color: #515e71;
      margin: 0px;
      span {
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;
        color: #0028ff;
        margin-left: 10px;
        white-space: nowrap;
      }
    }
    .profilecard-grid {
      span {
        font-weight: 600;
      }
      .right-side {
        padding-left: 20px;
        border-left: 1px solid #a6b1dc;
      }
      .email-style {
        span {
          color: #0028ff;
        }
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .ProfileImageUploadCard {
      button {
        span {
          font-family: Inter;
          font-size: 16px;
          font-weight: 400;
          line-height: 19.36px;
        }
      }
      .child-wrapper {
        width: 70%;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .ProfileImageUploadCard {
      flex-direction: column;
      padding: 20px !important;
      button {
        span {
          font-family: Inter;
          font-size: 16px;
          font-weight: 400;
          line-height: 19.36px;
        }
        position: unset !important;
        transform: unset !important;
      }
      .k-grid-layout {
        width: 100%;
      }
      .child-wrapper {
        width: 100% !important;
        padding: 0px;
        .first-item {
          grid-area: 1 / 1 / auto / span 4 !important;
        }
        .height-style {
          grid-area: 4 / 1 / auto / span 2 !important;
        }
        .weight-style {
          grid-area: 4 / 2 / auto / span 2 !important;
        }
        .email-style {
          grid-area: 4 / 1 / auto / span 2 !important;
        }
      }
    }
  }
  @media (min-width: 100px) and (max-width: 767px) {
    .ProfileImageUploadCard {
      flex-direction: column;
      padding: 20px !important;

      .right-side {
        padding-left: 0px !important;
        border-left: 0px solid !important;
      }
      button {
        span {
          font-family: Inter;
          font-size: 16px;
          font-weight: 400;
          line-height: 19.36px;
        }
        position: unset !important;
        transform: unset !important;
      }
      .k-grid-layout {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      .child-wrapper {
        width: 100% !important;
        padding: 0px;
        .first-item {
          grid-area: 1 / 1 / auto / span 4 !important;
        }
        .height-style {
          grid-area: 4 / 1 / auto / span 2 !important;
        }
        .weight-style {
          grid-area: 4 / 2 / auto / span 2 !important;
        }
        .email-style {
          grid-area: 4 / 3 / auto / span 2 !important;
        }
      }
    }
  }
`;
const CustomLabelMedium = styled(LabelMedium)`
  font-family: 'Inter';
  font-weight: 400;
  display: flex;
  padding: 1px 15px 1px 0;
  align-items: center;
  span {
    font-family: 'Inter';
    font-size: 14px;
    line-height: 16.94px;
    color: #475467;
    padding: 2px 0 0 5px;
  }
`;
