import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import AdminCustomButton from '../AdminCustomButton';
interface AdminCustomPopUpProps {
  popupLabel?: string;
  children?: ReactNode;
  setVisible?: (visible: boolean) => void;
  visible?: boolean;
  className?: string;
  Btn1Label?: string;
  Btn2Label?: string;
  onClickBtn1?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  onClickBtn2?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  align?: 'centre' | 'right';
  defaultPopupFooter?: boolean;
  DeleteBtn?: boolean;
  headerUnderline?: boolean;
  isView?: boolean;
  colorStrip?: string;
  disabled?: boolean;
  disabledBtn2?: boolean;
  cancelBtn?: boolean;
  closeToggle?: () => void;
}
export const AdminCustomPopUp = ({
  popupLabel,
  children,
  setVisible,
  visible,
  className,
  align,
  headerUnderline = false,
  defaultPopupFooter = true,
  isView,
  colorStrip,
  closeToggle,
  disabled,
  Btn1Label,
  Btn2Label,
  onClickBtn1,
  onClickBtn2,
  disabledBtn2,
}: AdminCustomPopUpProps) => {
  const toggleDialog = () => {
    closeToggle?.();
  };

  return (
    <>
      {visible && (
        <StyledDialog
          title={
            <div>
              {isView && (
                <HeaderColorWrapper
                  colorStrip={colorStrip}
                  className="header-color-wrapper"
                />
              )}
              {popupLabel}
            </div>
          }
          onClose={toggleDialog}
          className={`${className} body-scroll-lock`}
          align={align}
          headerUnderline={headerUnderline}
        >
          {children}
          {defaultPopupFooter && (
            <DialogActionsBar>
              <AdminCustomButton
                className="popup-primary-btn1"
                buttonType="cancel"
                onClick={closeToggle}
                disabled={disabled}
              >
                {Btn1Label || 'Cancel'}
              </AdminCustomButton>
              <AdminCustomButton
                className="popup-primary-btn2"
                buttonType="save"
                onClick={onClickBtn2}
                disabled={disabledBtn2}
              >
                {Btn2Label || 'Save'}
              </AdminCustomButton>
            </DialogActionsBar>
          )}
        </StyledDialog>
      )}
    </>
  );
};

const StyledDialog = styled(({ align, ...props }) => <Dialog {...props} />)`
  .k-window-action {
    opacity: unset;
  }
  .k-button-icon::before {
    color: unset;
  }
  .k-overlay {
    opacity: 0.2 !important;
  }

  .k-dialog {
    width: ${props => (props.align === 'centre' ? 'unset' : '28%')};
    min-width: 28%;
    height: ${props => (props.align === 'centre' ? 'unset' : '100vh')};
    border-radius: ${props =>
      props.align === 'centre' ? '6px' : 'unset'} !important;
    position: absolute !important;
    right: ${props => (props.align === 'centre' ? 'unset' : '0')};
    top: ${props => (props.align === 'centre' ? 'unset' : '0')};
    box-shadow: 0px 0px 4px 0px '0px 1px 8px 2px #0000001A';
  }
  .k-window {
    background-color: #ffffff;
  }
  .k-window-title {
    font-size: 18px !important;
    font-family: 'Inter';
    font-weight: 600;
    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .k-window-titlebar {
    border-color: ${props =>
      props.headerUnderline ? ' #404040' : 'transparent'};
    color: '';
    align-items: baseline;
  }
  .k-window-content {
    padding: 2px 4px 2px !important;
    ::-webkit-scrollbar {
      width: 2px;
    }
    ::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      width: 9px;
      background: #213065;
      border-radius: 10px;
    }
  }
  .k-dialog-buttongroup {
    padding: 22px !important;
    border-color: #e5e5e5;
  }
  .k-dialog-buttongroup {
    gap: 1rem;
  }
  .popup-primary-btn1 {
    button {
      height: 36px;
    }
  }
  .popup-primary-btn2 {
    button {
      height: 36px;
    }
  }
  .k-button {
    width: 100%;
  }
  .k-actions {
    border-width: 1px 0 0 0;
    padding: 1rem 1rem;
  }
  .k-window-actions {
    margin: unset;
  }
  .fwGQhM.k-button:hover {
    background: #4764ff !important;
    color: #ffffff !important;
  }
`;

const HeaderColorWrapper = styled.div<{ colorStrip?: string }>`
  display: block;
  background-color: ${props => props.colorStrip || ''};
  height: 8px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
