import { GridColumnProps } from '@progress/kendo-react-grid';
import { CardSubWrapper, CardWrapper } from 'admin/components/wrappers';
import CustomDataGrid from 'app/components/CustomDataGrid/Index';
import styled from 'styled-components';

type Props = {
  data: any[] | undefined;
  columns?: GridColumnProps[] | undefined;
};

const TeamPointsTable = ({ data, columns }: Props) => {
  return (
    <>
      <CardWrapper style={{ paddingTop: '15px' }}>
        <CardSubWrapper className="d-flex"></CardSubWrapper>
        <GridWrapper>
          <CustomDataGrid
            showPagination={false}
            className="custom-data-grid"
            data={data}
            columns={columns}
          />
        </GridWrapper>
      </CardWrapper>
    </>
  );
};

export default TeamPointsTable;
const GridWrapper = styled.div`
  margin-bottom: 10px;
  .k-grid-header {
    border-top: 1px solid #eaecf0;
  }
  .k-header {
    align-content: center;
  }
  .k-grid-content tr {
    &:hover {
      td {
        background: #f0f0f0 !important;
      }
    }
  }
  .k-grid-content tr td {
  }
  .custom-data-grid {
    th {
      padding: 0 !important;
      vertical-align: baseline;
    }
    .k-virtual-content {
      height: unset !important;
      min-height: unset;
    }
  }
  .k-grid-content tr {
    .k-grid-content-sticky {
      :hover {
        background: #f0f0f0 !important;
      }
    }
  }
  .k-grid-content tr td.k-grid-content-sticky {
    cursor: unset;
  }
`;
