import React, { useState } from 'react';
import styled from 'styled-components';
import '@fontsource/roboto';
import { TextField } from 'app/components/TextField';
import SHdropdown from 'app/components/SHdropdown';
import { BackNextButton } from 'app/components/BackNextButton';
import { EWD, GeneralLookup } from 'services/openapi';

type Props = {
  onClickEligibilityDetailsNext: () => void;
  onClickEligibilityDetailsBack: () => void;
  setNewMemberData: React.Dispatch<React.SetStateAction<EWD>>;
  newMemberData: EWD;
  stateData?: GeneralLookup[];
  teamData?: GeneralLookup[];
};

export const EligibilityDetailsNew = ({
  onClickEligibilityDetailsNext,
  onClickEligibilityDetailsBack,
  setNewMemberData,
  newMemberData,
  stateData,
  teamData,
}: Props) => {
  const handleChange = event => {
    const { name, value } = event.target;
    setNewMemberData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEmergencyPhChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
      setNewMemberData({
        ...newMemberData,
        emergencyContactPhone: inputValue,
      });
    }
  };
  // React.useEffect(() => {
  //   const formValid = simpleValidator.current.allValid();
  //   setValid(!formValid);
  // }, [newMemberData]);
  return (
    <MainWrapper>
      <p className="first-para">
        A membership registration forms for Equestrian with Disabilities must be
        accompanied by a Diagnosis and Adaptive Equipment Form.
      </p>
      <OverflowWrapper>
        <TextFieldGrid>
          <TextFieldWrapper>
            <StyledTextField
              onChange={handleChange}
              value={newMemberData.firstName}
              label="First Name"
              type="text"
              name="firstName"
              disabled={true}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              onChange={handleChange}
              value={newMemberData.lastName}
              label="Last Name"
              type="text"
              name="lastName"
              disabled={true}
            />
          </TextFieldWrapper>
          <DropdownWrapper>
            <SHdropdown
              className="drop-down"
              label="Team Name"
              data={teamData}
              onDataChange={data =>
                setNewMemberData({
                  ...newMemberData,
                  team: data,
                })
              }
              defaultValue={newMemberData.team}
              disabled={true}
            />
          </DropdownWrapper>
          <TextFieldWrapper>
            <StyledTextField
              onChange={handleChange}
              value={newMemberData.primaryPhone}
              label="Phone Number"
              type="number"
              name="primaryPhone"
              disabled={true}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
        <MainAddressWrapper>
          <div className="horizontal-line">
            <span>Rider's Address</span>
          </div>
          <TextFieldGrid>
            <TextFieldWrapper>
              <StyledTextField
                onChange={handleChange}
                value={newMemberData.street}
                label="Street Address"
                type="text"
                name="street"
                disabled={true}
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <StyledTextField
                onChange={handleChange}
                value={newMemberData.city}
                label="City"
                type="text"
                name="city"
                maxLength={40}
                disabled={true}
              />
            </TextFieldWrapper>
            <DropdownWrapper>
              <SHdropdown
                className="drop-down"
                label="State"
                data={stateData}
                onDataChange={data =>
                  setNewMemberData({
                    ...newMemberData,
                    state: data,
                  })
                }
                defaultValue={newMemberData.state}
                disabled={true}
              />
            </DropdownWrapper>
            <TextFieldWrapper>
              <StyledTextField
                onChange={handleChange}
                value={newMemberData.zip}
                label="Zip"
                type="number"
                name="zip"
                disabled={true}
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <StyledTextField
                onChange={handleChange}
                value={newMemberData.email}
                label=" Email"
                type="email"
                name="email"
                maxLength={60}
                disabled={true}
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <StyledTextField
                onChange={handleChange}
                value={newMemberData.emergencyContactName!}
                label="Emergency Contact"
                type="text"
                name="emergencyContactName"
                disabled={true}
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <StyledTextField
                label="Emergency Contact Number"
                type="tel"
                name="emergencyContactNumber"
                onChange={e =>
                  setNewMemberData({
                    ...newMemberData,
                    emergencyContactPhone: e.target.value,
                  })
                }
                value={newMemberData?.emergencyContactPhone ?? ''}
                disabled={true}
              />
            </TextFieldWrapper>
          </TextFieldGrid>
        </MainAddressWrapper>
        <ButtonWrapper className="bg-color-change">
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={onClickEligibilityDetailsNext}
            onClickBack={onClickEligibilityDetailsBack}
            next="Next"
            back="Cancel"
          />
        </ButtonWrapper>
      </OverflowWrapper>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  color: #9597a6;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  height: calc(100vh - 230px);
  overflow: auto;
  padding-top: 50px;

  .first-para,
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 40px;
    .first-para,
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    padding-top: 45px;
    .first-para,
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    padding-top: 50px;
    .first-para,
    label {
      font-size: 14px;
    }
  }
`;
const OverflowWrapper = styled.div`
  height: 100%;
  overflow: auto;
  padding-bottom: 100px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
`;

const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 20px;
  ::placeholder {
    color: #8f8e8e !important;
  }

  @media (min-width: 100px) and (max-width: 319px) {
    display: block;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    display: block;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    display: block;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    display: block;
  }
`;
const TextFieldWrapper = styled.div`
  flex: 0 0 calc(50% - 20px) !important;
  margin: 0px 0px 10px !important;
`;
const StyledTextField = styled(TextField)`
  flex: 0 0 calc(50% - 20px);
  margin: 0px 0px 10px !important;
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const MainAddressWrapper = styled.div`
  padding-bottom: 50px;
  .horizontal-line {
    display: flex;
    align-items: baseline;
    margin: 20px 0 15px;
  }
  .horizontal-line span {
    margin: 0 10px 0 0;
    white-space: nowrap;
    color: #4f4f4f;
    font-size: 15px;
  }
  .horizontal-line::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #434343;
    height: 3px;
  }
`;
const DropdownWrapper = styled.div`
  flex: 0 0 calc(50% - 20px);
  p {
    margin: 0px;
    font-family: 'Roboto';
    font-size: 14px;
  }
  .SHdropdown {
    margin: 0px;
    width: 100% !important;
    height: 35px;
    color: #9597a6;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    padding-bottom: 25px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding-bottom: 25px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-bottom: 25px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-bottom: 25px;
  }
`;
const ButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: 1px;
    left: 0px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
    z-index: 2;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;
