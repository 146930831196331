import { useErrorHandlingSlice } from 'app/error/slice';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useApi from 'services/mavapi/useApi';
import { useNavigateToRoute } from 'utils/common';
import { UserService, UserProfile } from 'services/openapi';

const useUserProfileApi = () => {
  const { userViewUserProfile, userEditUserProfile, userUploadUserImage } =
    UserService;

  const [loading, setLoading] = useState(false);
  const [userViewLoading, setUserViewLoading] = useState(false);
  const [, setError] = useState<unknown | null>();
  const { error: apiError, handleRequest } = useApi();
  const [userViewData, setUserViewData] = useState<UserProfile>();
  const dispatch = useDispatch();
  const { actions } = useErrorHandlingSlice();
  const redirect = useNavigateToRoute();

  const getUserProfileViewData = () => {
    setUserViewLoading(true);
    handleRequest(userViewUserProfile())
      .then(res => {
        if (res) {
          setUserViewData(res);
        }
        setUserViewLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setUserViewLoading(false);
      });
  };
  const updateUserImageUpload = (formData?: { file?: Blob }) => {
    setLoading(true);
    handleRequest(userUploadUserImage(formData))
      .then(res => {
        redirect(`/admin/settings/profile-settings`);
        dispatch(
          actions.dispatchToast({
            errorTxt: 'User updated profile successfully',
            show: true,
            errorType: 'success',
          }),
        );

        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };
  const EditUser = (requestBody?: UserProfile, imageFile?: Blob) => {
    setLoading(true);
    const formData = {
      file: imageFile,
    };
    handleRequest(userEditUserProfile(requestBody))
      .then(res => {
        setUserViewData(res);
        setLoading(false);
        if (res.code === 'EmailAlreadyInUse') {
          setLoading(false);
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Email is already in use',
              show: true,
              errorType: 'error',
            }),
          );
        } else {
          if (imageFile) {
            updateUserImageUpload(formData);
          } else {
            redirect(`/admin/settings/profile-settings`);
            dispatch(
              actions.dispatchToast({
                errorTxt: 'User updated profile successfully',
                show: true,
                errorType: 'success',
              }),
            );
          }
        }
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };
  return {
    loading,
    userViewLoading,
    userViewData,
    getUserProfileViewData,
    EditUser,
  };
};

export default useUserProfileApi;
