import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Sidebar from 'app/components/admin/sidebar';

type Props = {};

const AdminLayout = (props: Props) => {
  return (
    <PageWrapper>
      <Sidebar />
      <Outlet />
    </PageWrapper>
  );
};

export default AdminLayout;
const PageWrapper = styled.div`
  height: 100%;
  display: flex;
  background: #ffffff;
`;
