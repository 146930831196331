import React from 'react';
import {
  DropDownList,
  DropDownListChangeEvent,
  DropDownListProps,
  DropDownListBlurEvent,
  DropDownListOpenEvent,
  DropDownListCloseEvent,
} from '@progress/kendo-react-dropdowns';
import styled, { css } from 'styled-components';
import '@fontsource/arimo';
import { GeneralLookup } from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';
import { filterBy } from '@progress/kendo-data-query';
import '@fontsource/roboto';

export type DropDownModel = {
  id?: number;
  text?: string;
};
type Props = {
  disabled?: boolean; // make the button disabled or not
  data?: GeneralLookup[];
  defaultValue?: GeneralLookup;
  onChange?: (event: DropDownListChangeEvent) => void;
  onDataChange?: (data: GeneralLookup) => void;
  onGeneralDataChange?: (data: DropDownModel) => void;
  genericData?: DropDownModel[];
  genericDefaultValue?: DropDownModel;
  label?: string;
  onClick?: (e: any) => void;
  className?: string;
  placeholder?: string;
  validator?: SimpleReactValidator;
  validations?: string;
};

const SHLightModeDropDown = ({
  disabled,
  data,
  defaultValue,
  onChange,
  label,
  className,
  onDataChange,
  placeholder,
  genericData,
  genericDefaultValue,
  onGeneralDataChange,
  validations,
  validator,
}: Props) => {
  const simpleValidator = React.useRef(validator);
  const [open, setOpen] = React.useState<boolean>(false);
  const [dropData, setDropData] = React.useState<any>();
  const [dropValue, setDropValue] = React.useState<DropDownModel>({
    id: undefined,
    text: '',
  });
  var convertedData = data?.map((item: GeneralLookup, index) => {
    return { id: index, text: item.name ?? '', value: item.id };
  });
  var convertedDefaultValue = {
    id: 599,
    text: defaultValue?.name ? defaultValue?.name : placeholder,
    value: defaultValue?.id ? defaultValue?.id : '',
  };
  var convertedDefault = {
    id: 599,
    text: defaultValue?.name ?? '',
    value: defaultValue?.id,
  };
  var genericConvertedDefault = {
    id: 599,
    text: genericDefaultValue?.text ?? '',
    value: genericDefaultValue?.id,
  };
  var genericConvertedData = genericData?.map((item: DropDownModel, index) => {
    return { id: index, text: item.text ?? '', value: item.id };
  });

  const handleBlur = (e: DropDownListBlurEvent) => {
    setOpen(false);
    simpleValidator?.current?.showMessageFor(label ? label : 'field');
  };
  const handleOnClose = (e: DropDownListCloseEvent) => {
    setOpen(false);
  };
  const handleClick = (e: DropDownListOpenEvent) => {
    setOpen(!open);
  };

  const handleChange = (event: DropDownListChangeEvent) => {
    var value = event.target.value;
    if (data) {
      var generalLookup = data.find(obj => {
        return obj.id === value.value;
      });
      if (onDataChange && generalLookup) {
        onDataChange(generalLookup);
      }
    } else if (genericData) {
      var generalData = genericData?.find(obj => {
        return obj.id === value.value;
      });
      if (onGeneralDataChange && generalData) {
        onGeneralDataChange(generalData);
      }
    }
  };
  const [filterValue, setFilterValue] = React.useState<string>();
  const filterData = filter => {
    const newData = convertedData?.slice();
    return filterBy(newData!, filter);
  };
  const filterChange = event => {
    setFilterValue(event.filter.value);
    setDropData(filterData(event.filter));
  };
  React.useEffect(() => {
    console.log('React.useEffect called');
    setDropData(genericData ? genericConvertedData : convertedData?.slice());
  }, [data]);
  return (
    <Wrapper
      className={`SHdropdown ${className} ${
        validator && !simpleValidator?.current?.fieldValid(label!)
          ? 'error'
          : 'default'
      }`}
    >
      <div className="label">{label ?? 'Label'}</div>

      <StyleDropDownList
        className={`${StyleDropDownList} ${
          open ? 'inverted-arrow' : 'default'
        }`}
        filterable={true}
        textField="text"
        dataItemKey="id"
        data={dropData}
        onFilterChange={filterChange}
        filter={filterValue}
        // value={convertedDefault ?? genericConvertedDefault}
        value={genericData ? genericConvertedDefault : convertedDefaultValue}
        disabled={disabled}
        onOpen={handleClick}
        onChange={handleChange}
        // opened={true}
        onClose={handleOnClose}
        onBlur={handleBlur}
        // onFocus={() => setOpen(!open)}
        // className={`${open ? 'inverted-arrow' : 'default'}`}
      />
      {simpleValidator?.current?.message(
        label ? label : 'field',
        convertedDefault?.value || genericConvertedDefault?.value,
        validations ?? '',
      )}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  margin: 0px 12px;
  /* width: 100%; */

  .srv-validation-message {
    color: #f44336;
    font-size: 12px;
    display: inline-block;
  }
  &.error {
    .k-picker-solid {
      border-bottom: 1px solid #f44336 !important;
    }
  }
  &.default {
    .k-picker-solid {
      border: transparent !important;
    }
  }
  margin: 0px 12px;
  .label {
    font-family: 'Roboto';
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  //Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    .label,
    .k-input-value-text {
      font-size: 11px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .label,
    .k-input-value-text {
      font-size: 12px !important;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .label {
      font-size: 12px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .label,
    .k-input-value-text {
      font-size: 13px !important;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .label,
    .k-input-value-text {
      font-size: 13px !important;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .label,
    .k-input-value-text {
      font-size: 14px !important;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .label,
    .k-input-value-text {
      font-size: 16px !important;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    .label,
    .k-input-value-text {
      font-size: 17px !important;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    .label,
    .k-input-value-text {
      font-size: 17px !important;
    }
  }
`;
const StyleDropDownList = styled(DropDownList)`
  /* min-height: 35px; */
  max-height: 35px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 2px;
  background: #ededed;
  color: #000000;
  border-radius: 6px !important;
  border: none !important;
  box-shadow: none !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  border-radius: 10px;
  font-family: 'arimo' !important;
  font-weight: 400 !important;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    box-shadow: none;
  }

  &.k-popup {
    .k-input-solid {
      background: #f44336 !important;
    }
  }
  &.k-popup {
    background: #ededed;
    color: #000000;
  }

  .k-input-inner {
    /* padding: 0px 0px 0 15px;
    line-height: 1.6;
    align-items: flex-start;
    border: none !important;
    border-radius: 30px;
    color: ${p => p.theme.colorTextTheme}; */
  }
  .k-input-value-text {
    margin: 2px 0px 0px -4px;
    font-family: 'Roboto' !important;
    font-weight: 400 !important;
    font-size: 14px;
    letter-spacing: 0.5px;
  }

  &.inverted-arrow {
    .k-button {
      /* background: transparent;
    box-shadow: none !important; */
      border: none !important;
      color: #ffffff;
      transform: rotate(180deg) !important;
      transition: 0.5s;
      /* content: ''; */
      /* padding: 20px 10px 0 0;
    display: block;
    display: ${p => (p.disabled ? 'none' : 'block')}; */
      .k-button-icon {
        svg {
          width: 25px;
          color: #c4c4c4;
        }
        /* content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.5' height='6.5' viewBox='0 0 15.5 8.5'%3E%3Cpath id='Stroke-1' d='M.47.47A.75.75 0 0 1 1.446.4L1.53.47 8 6.939 14.47.47A.75.75 0 0 1 15.446.4L15.53.47a.75.75 0 0 1 .073.977l-.073.084-7 7a.75.75 0 0 1-.977.073L7.47 8.53l-7-7A.75.75 0 0 1 .47.47Z' transform='translate(-0.25 -0.25)'/%3E%3C/svg%3E");
      height: auto;
      margin: -45px 8px 0 -20px; */
        &:before {
          display: none !important;
        }
      }
    }
  }
  &.default {
    .k-button {
      /* background: transparent;
    box-shadow: none !important; */
      border: none !important;
      color: #ffffff;
      transition: 0.5s;
      /* content: ''; */
      /* padding: 20px 10px 0 0;
    display: block;
    display: ${p => (p.disabled ? 'none' : 'block')}; */
      .k-button-icon {
        svg {
          width: 25px;
          color: #c4c4c4;
        }
        /* content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.5' height='6.5' viewBox='0 0 15.5 8.5'%3E%3Cpath id='Stroke-1' d='M.47.47A.75.75 0 0 1 1.446.4L1.53.47 8 6.939 14.47.47A.75.75 0 0 1 15.446.4L15.53.47a.75.75 0 0 1 .073.977l-.073.084-7 7a.75.75 0 0 1-.977.073L7.47 8.53l-7-7A.75.75 0 0 1 .47.47Z' transform='translate(-0.25 -0.25)'/%3E%3C/svg%3E");
      height: auto;
      margin: -45px 8px 0 -20px; */
        &:before {
          display: none !important;
        }
      }
    }
  }
  //Component Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    height: 30px !important;
    font-size: 12px;
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 21px;
          }
        }
      }
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    height: 30px !important;
    font-size: 12px;
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 23px;
          }
        }
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 22px;
          }
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 23px;
          }
        }
      }
    }
    //height: 35px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    //height: 40px;
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 23px;
          }
        }
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    //height: 45px;
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 24px;
          }
        }
      }
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    //height: 50px;
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 25px;
          }
        }
      }
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    //height: 60px;
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 27px;
          }
        }
      }
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    //height: 70px;
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 30px;
          }
        }
      }
    }
  }
`;
export default SHLightModeDropDown;
