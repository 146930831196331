/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeDocumentActiveStatus } from '../models/ChangeDocumentActiveStatus';
import type { Document } from '../models/Document';
import type { GeneralLookup } from '../models/GeneralLookup';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DocumentService {

    /**
     * API to upload document
     * @param formData
     * @returns any Created
     * @throws ApiError
     */
    public static documentUploadDocument(
        formData?: {
            File: Blob;
            'Season.Id': string;
            'Season.Name': string;
            'Season.Code'?: string;
            'Season.Children'?: Array<GeneralLookup>;
            'Season.OtherName'?: string;
            'Season.IsAdminLive'?: boolean;
            'Type.Id': string;
            'Type.Name': string;
            'Type.Code'?: string;
            'Type.Children'?: Array<GeneralLookup>;
            'Type.OtherName'?: string;
            'Type.IsAdminLive'?: boolean;
            SetAsActive: boolean;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/document/upload',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API for changing the active status of a document
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static documentChangeDocumentActiveStatus(
        requestBody?: ChangeDocumentActiveStatus,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/document/changeActiveStatus',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API for deleting documents
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static documentDeleteDocuments(
        requestBody?: Array<string>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/document/delete',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API for listing documents
     * @param seasonId
     * @param typeId
     * @returns Document Success
     * @throws ApiError
     */
    public static documentListDocuments(
        seasonId?: string,
        typeId?: string,
    ): CancelablePromise<Array<Document>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/document/list',
            query: {
                'seasonId': seasonId,
                'typeId': typeId,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to fetch the currently active document for the current season
     * @param typeId
     * @returns Document Success
     * @throws ApiError
     */
    public static documentActiveDocumentByType(
        typeId: string,
    ): CancelablePromise<Document> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/document/activeByType/{typeId}',
            path: {
                'typeId': typeId,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

}
