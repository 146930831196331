import { PageMainLabel, SubLabelSemiBold } from 'app/components/SHLabel';
import styled from 'styled-components';
import BackButton from 'app/components/BackButton';
import { CommonPageWrapper } from 'admin/components/wrappers';
import CustomBreadCrumb from 'app/components/CustomBreadCrumb';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigateToRoute } from 'utils/common';
import { UserViewProfile } from './Components/UserViewProfile/UserViewProfile';
import useUserApi from 'admin/hooks/useUserApi';

const breadCrumpitems = [
  {
    id: '',
    text: 'Showyeda admin',
    disabled: true,
  },
  {
    id: '/admin/users',
    text: 'Users',
  },
  {
    id: '',
    text: 'Profile Info',
    disabled: true,
  },
];
export const UserIndividual = () => {
  const redirect = useNavigateToRoute();
  const { id: userId, action } = useParams();
  const { getUserProfileViewData, userViewData, userViewLoading } =
    useUserApi();

  const handleBackClick = () => {
    redirect('/admin/users');
  };
  useEffect(() => {
    if (action !== 'update' && action !== 'create') {
      getUserProfileViewData(userId ?? '');
    }
  }, [userId]);

  return (
    <CommonPageWrapper>
      <HeadingWrapper>
        <PageMainLabel>User Information</PageMainLabel>
        <CustomBreadCrumb preData={breadCrumpitems} />
      </HeadingWrapper>

      <HeaderProfileWrapper>
        <SubLabelSemiBold>User Profile</SubLabelSemiBold>
        <BackButton className="back-button" onClick={handleBackClick} />
      </HeaderProfileWrapper>
      <UserViewProfile userViewData={userViewData} loading={userViewLoading} />
    </CommonPageWrapper>
  );
};
const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderProfileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
