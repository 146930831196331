/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { GlobalStyle } from 'styles/global-styles';
import { ToastContainer } from 'react-toastify';

import { HomePage } from './pages/HomePage/Loadable';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { LoginPage } from './pages/LoginPage';
import { RegistrationPage } from './pages/RegistrationPage';
import { TryitRegistrationPage } from './pages/TryitRegistrationPage';
import { NonCompetingMembershipPage } from './pages/NonCompetingMembershipPage';
import { AssociateMembershipPage } from './pages/AssociateMembershipPage';
import RiderRegistrationLayout from './Layouts/RiderRegistrationLayout';
import ReturningRider from './pages/RiderRegistrationPage/components/ReturningRider';
import RiderInformation from './pages/RiderRegistrationPage/components/ReturningRider/components/RiderInformation';
import TermsandConditons from './components/TermsandConditions';
import IndividualPlacementFormNewRider from './pages/RiderRegistrationPage/components/NotReturningRider/components/IndividualPlacementForm';
import IndividualPlacementFormTwoNewRider from './pages/RiderRegistrationPage/components/NotReturningRider/components/IndividualPlacementFormTwo';
import IndividualPlacementForm from './pages/RiderRegistrationPage/components/ReturningRider/components/RiderInformation/components/IndividualPlacementForm';
import RiderRegistrationPlacementLayout from './Layouts/RiderRegistrationPlacementLayout';
import TryitRiderRegistrationLayout from './Layouts/TryitRiderRegistrationLayout';
import ReturningMember from './pages/TryitRiderRegistrationPage/components/ReturningMember';
import TryitRiderInformation from './pages/TryitRiderRegistrationPage/components/ReturningMember/components/TryitRiderInformation';
import NewMember from './pages/TryitRiderRegistrationPage/components/NewMember';
import TryitRiderRegistrationPage from './pages/TryitRiderRegistrationPage';
import RiderRegistrationPage from './pages/RiderRegistrationPage';
import TryitRiderRegistrationPlacementLayout from './Layouts/TryitRiderRegistrationPlacementLayout';
import TryitMembershipForm from './pages/TryitRiderRegistrationPage/components/ReturningMember/components/TryitRiderInformation/components/TryitMembershipForm';
import TryitRiderRegistrationPlacementPaymentLayout from './Layouts/TryitRiderRegistrationPlacementPaymentlayout';
import NewMemberMembershipForm from './pages/TryitRiderRegistrationPage/components/NewMember/components/MembershipForm';
import NewMemberIndividualPlacementFormTwoNewRider from './pages/TryitRiderRegistrationPage/components/NewMember/components/IndividualPlacementFormTwo';
import NewMemberIndividualPlacementFormNewRider from './pages/TryitRiderRegistrationPage/components/NewMember/components/IndividualPlacementForm';
import EWDMembershipFormLayout from './Layouts/EWDMembershipFormLayout';
import EWDMembershipFormPage from './pages/EWDMembershipFormPage';
import EWDReturningMember from './pages/EWDMembershipFormPage/component/ReturningMember';
import EWDNewMember from './pages/EWDMembershipFormPage/component/NewMember';
import EWDMembershipEligibilityLayout from './Layouts/EWDMembershipEligilityLayout';
import EWDMembershipEquipmentLayout from './Layouts/EWDMembershipEquipmentLayout';
import AlumniLayout from './Layouts/AlumniLayout';
import AlumniNewMemberResgistration from './pages/AlumniRegistrationPage/components/AlumniNewMemberResgistration';
import TryitEWDMembershipLayout from './Layouts/TryitEWDMembershipLayout';
import TryitEWDMembershipPage from './pages/TryitEWDMembershipPage';
import TryitEWDReturningMember from './pages/TryitEWDMembershipPage/component/ReturningMember';
import TryitEWDRiderInformation from './pages/TryitEWDMembershipPage/component/ReturningMember/components/TryitEWDRiderInformation';
import TryitEWDMembershipEligibilityLayout from './Layouts/TryitEWDMembershipEligilityLayout';
import { TryitEligibilityDetailsReturning } from './pages/TryitEWDMembershipPage/component/ReturningMember/components/TryitEWDRiderInformation/components/ElgibilityDetails';
import TryitEligibilityConditionsReturning from './pages/TryitEWDMembershipPage/component/ReturningMember/components/TryitEWDRiderInformation/components/ElgibilityConditions';
import TryitEWDMembershipEquipmentLayout from './Layouts/TryitEWDMembershipEquipmentLayout';
import { TryitEquipmentDetailsReturning } from './pages/TryitEWDMembershipPage/component/ReturningMember/components/TryitEWDRiderInformation/components/EquimentDetails';
import TryitAdaptiveEquipmentsReturning from './pages/TryitEWDMembershipPage/component/ReturningMember/components/TryitEWDRiderInformation/components/AdaptiveEquipments';
import TryitEWDNewMember from './pages/TryitEWDMembershipPage/component/NewMember';
import AlumniReturningMemberRegistration from './pages/AlumniRegistrationPage/components/AlumniReturningMemberRegistration';
import AlumniRegistration from './pages/AlumniRegistrationPage/components/AlumniRegistration';
import AlumniTryItLayout from './Layouts/AlumniTryItLayout';
import AlumniTryItResgistration from './pages/AlumniRegistrationPage/components/AlumniTryItResgistration';
import RiderLandingPage from './pages/RiderLandingPage';
import SIF from './pages/RiderLandingPage/components/SIF';
import EwdNewEnrollment from './pages/RiderLandingPage/components/SIF/component/EwdNewEnrollment';
import EwdSustainingEnrollment from './pages/RiderLandingPage/components/SIF/component/EwdSustainingEnrollment';
import RiderSustainingEnrollment from './pages/RiderLandingPage/components/SIF/component/RiderSustainingEnrollment';
import RiderNewEnrollment from './pages/RiderLandingPage/components/SIF/component/RiderNewEnrollment';
import CoachRegistrationLayout from './Layouts/CoachRegistrationLayout';
import CoachResgistration from './pages/CoachRegistrationPage/components/CoachResgistration';
import CoachInsurance from './pages/CoachRegistrationPage/components/CoachInsurance';
import ApplyingForCoachResgistration from './pages/CoachRegistrationPage/components/ApplyingForCoachResgistration';
import AssistantCoachResgistration from './pages/CoachRegistrationPage/components/AssistantCoachResgistration';
import CoachTryItLayout from './Layouts/CoachTryItLayout';
import CoachTryItResgistration from './pages/CoachRegistrationPage/components/CoachTryItResgistration';
import NotReturningRider from './pages/RiderRegistrationPage/components/NotReturningRider';
import IndividualPlacementFormTwo from './pages/RiderRegistrationPage/components/ReturningRider/components/RiderInformation/components/IndividualPlacementFormTwo';
import Dashboard from './pages/RiderLandingPage/components/Dashboard';
import PaymentPage from './pages/PaymentPage';
import { useLocation } from 'react-router-dom';
import NonCompetingRegistration from './pages/NonCompetingMembershipPage/components/NonCompetingRegistration';
import NonCompetingLayout from './Layouts/NonCompetingLayout';
import NonCompetingReturningRider from './pages/NonCompetingMembershipPage/components/NonCompetingReturningMember';
import { CoachLogin } from './pages/CoachLogin';
import CoachDashBoard from './pages/RiderLandingPage/components/CoachLogin/Index';
import CoachLandingPage from './pages/CoachLandingPage/Index';
import CoachInsuranceApplication from './pages/CoachLandingPage/Components/InsuranceApplication/Index';
import PasswordResetEmail from './components/PasswordResetEmail';
import AdminPanelPage from './pages/AdminPanel';
import { Components } from './pages';
import {
  TeamsPage,
  TeamIndividual,
  RiderCreateViewPage,
  RiderIndividual,
} from 'admin/pages';
import { MHToast } from './components/MHToast';
import AdminLayout from './Layouts/AdminLayout';
import RidersPage from 'admin/pages/RidersPage';
import CoachesPage from 'admin/pages/CoachesPage';
import UserPage from 'admin/pages/UserPage';
import { UserIndividual } from 'admin/pages/UserIndividual';
import { UserCreateEditPage } from 'admin/pages/UserIndividual/Components/UserCreateEditPage/components';
import { CoachIndividualPage } from 'admin/pages/CoachIndividualPage';
import { CoachCreateEditPage } from 'admin/pages/CoachIndividualPage/components/coachCreateEditPage';
import CurrentSeasonPage from 'admin/pages/ShowsPage/CurrentSeasonPage';
import { ShowIndividual } from 'admin/pages/ShowIndividual';
import ProtectedRoute, { ProtectedRouteProps } from './ProtectedRoute';
import Cookies from 'js-cookie';
import { ShowCreateEditPage } from 'admin/pages/ShowIndividual/Components/ShowCreateEditPage';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsAccessToken } from './pages/slice/selectors';
import { useManagePageSlice } from './pages/slice';
import { CreateShowsRider } from 'admin/pages/ShowIndividual/Components/ShowManageRider/CreateShowsRider';
import PastTeamList from 'admin/pages/PastDataPage';
import PastCoachList from 'admin/pages/PastDataPage/PastCoachList';
import PastRiderList from 'admin/pages/PastDataPage/PastRiderList';
import SponsersPage from 'admin/pages/SponsersPage';
import ShowResultPage from 'admin/pages/ShowsPage/ShowResultPage';
import SettingsPage from 'admin/pages/SettingsPage';
import SeasonSettings from 'admin/pages/SettingsPage/SettingsCard/Components/SettingsPages/seasonSettings';
import ProfileSettingsPage from 'admin/pages/SettingsPage/SettingsCard/Components/SettingsPages/ProfileSettingPage';
import { ProfileUpdatePage } from 'admin/pages/SettingsPage/SettingsCard/Components/SettingsPages/ProfileUpdatePage';
import RiderPointsReportsTable from 'admin/pages/ReportsPage/RiderPointsReportsTable';
import TeamPointsReportsTable from 'admin/pages/ReportsPage/TeamPointsReportsTable';
import { TeamRoster } from 'admin/pages/ReportsPage/TeamRoster';
import { SIFReports } from 'admin/pages/SIFReportPage';
import { ShowRiderReportGroups } from 'admin/pages/ReportsPage/ShowRiderReportGroups';
import { ShowGenerateSplitPage } from 'admin/pages/ShowGenerateSplitPage';

window.addEventListener('popstate', () => {
  if (!window.location.pathname.includes('/payment/')) {
    const iframe = document.querySelector('[title="INTERMEDIATE"]');
    const cloverFooter = document.querySelector('.clover-footer');
    const cloverScriptWrapper = document.querySelector('scriptWrapper');

    if (cloverScriptWrapper) cloverScriptWrapper.remove();

    if (cloverFooter) cloverFooter.remove();

    if (iframe) {
      const parentDiv = iframe.parentElement;

      if (parentDiv) parentDiv.remove();
    }
  }
});

export function App() {
  const dispatch = useDispatch();
  const { actions: PageActions } = useManagePageSlice();
  const IsAccessToken = useSelector(selectIsAccessToken);
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
    isAuthenticated: isAuthenicated(),
    authenticationPath: '/',
  };
  function isAuthenicated(): boolean {
    const accessToken = Cookies.get('accessToken');
    if (accessToken) {
      dispatch(PageActions.setIsAccessToken(accessToken ?? undefined));
      return true;
    } else {
      return false;
    }
  }

  React.useEffect(() => {
    isAuthenicated();
  }, [IsAccessToken]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/coach-login" element={<CoachLogin />} />
        <Route path="/" element={<LoginPage />} />
        <Route path="/reset-password" element={<PasswordResetEmail />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/registration" element={<RegistrationPage />} />
        <Route
          path="/payment/:payerType/:payerId/:isSif/:isSifSustain"
          element={<PaymentPage />}
        />
        {/* Admin Section Start */}
        <Route element={<AdminLayout />}>
          <Route
            path="/components"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/components',
                }}
                outlet={<Components />}
              />
            }
          />
          {/* Team Admin Section */}
          <Route
            path="/admin/teams"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/teams',
                }}
                outlet={<TeamsPage />}
              />
            }
          />
          <Route
            path="/admin/teams/:action/:tab/:id?"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/teams',
                }}
                outlet={<TeamIndividual />}
              />
            }
          />
          {/* Rider Admin Section */}
          <Route
            path="/admin/riders"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/riders',
                }}
                outlet={<RidersPage />}
              />
            }
          />
          <Route
            path="/admin/riders/:action"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/riders',
                }}
                outlet={<RiderCreateViewPage />}
              />
            }
          />
          <Route
            path="/admin/riders/:action/:id"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/riders',
                }}
                outlet={<RiderCreateViewPage />}
              />
            }
          />
          <Route
            path="/admin/riders/view/:tab/:id"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/riders',
                }}
                outlet={<RiderIndividual />}
              />
            }
          />
          {/* Coaches Admin Section */}
          <Route
            path="/admin/coaches"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/coaches',
                }}
                outlet={<CoachesPage />}
              />
            }
          />
          <Route
            path="/admin/coaches/view/:id"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/coaches',
                }}
                outlet={<CoachIndividualPage />}
              />
            }
          />
          <Route
            path="/admin/coaches/:action/:id?"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/coaches',
                }}
                outlet={<CoachCreateEditPage />}
              />
            }
          />
          {/* User Admin Section */}
          <Route
            path="/admin/users"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/users',
                }}
                outlet={<UserPage />}
              />
            }
          />
          <Route
            path="/admin/users/view/:id"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/users',
                }}
                outlet={<UserIndividual />}
              />
            }
          />
          <Route
            path="/admin/users/:action/:id?"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/users',
                }}
                outlet={<UserCreateEditPage />}
              />
            }
          />
          {/* Show Admin Section */}
          <Route
            path="/admin/shows/:action/:id?"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/shows',
                }}
                outlet={<ShowCreateEditPage />}
              />
            }
          />
          <Route
            path="/admin/shows"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/shows',
                }}
                outlet={<CurrentSeasonPage />}
              />
            }
          />
          <Route
            path="/admin/shows/view/:id/:tab"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/shows',
                }}
                outlet={<ShowIndividual />}
              />
            }
          />
          <Route
            path="/admin/shows/show-result/:tab/:id"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/shows',
                }}
                outlet={<ShowResultPage />}
              />
            }
          />
          <Route
            path="/admin/shows/generate-split/:id?"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/shows',
                }}
                outlet={<ShowGenerateSplitPage />}
              />
            }
          />
          {/* past data Admin Section */}
          <Route
            path="/admin/past-data/teams"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/past-data/teams',
                }}
                outlet={<PastTeamList />}
              />
            }
          />
          <Route
            path="/admin/past-data/teams/:action/:tab/:id"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/past-data/teams',
                }}
                outlet={<TeamIndividual />}
              />
            }
          />
          {/* Show Past Data */}
          <Route
            path="/admin/past-data/shows"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/past-data/shows',
                }}
                outlet={<CurrentSeasonPage />}
              />
            }
          />
          <Route
            path="/admin/past-data/shows/:action/:tab/:id?"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/past-data/shows',
                }}
                outlet={<ShowIndividual />}
              />
            }
          />{' '}
          <Route
            path="/admin/shows/view/:id/:tab/:action"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/shows',
                }}
                outlet={<CreateShowsRider />}
              />
            }
          />
          <Route
            path="/admin/past-data/coaches"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/past-data/coaches',
                }}
                outlet={<PastCoachList />}
              />
            }
          />
          <Route
            path="/admin/past-data/coaches/view/:id"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/past-data/coaches',
                }}
                outlet={<CoachIndividualPage />}
              />
            }
          />
          <Route
            path="/admin/past-data/riders"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/past-data/riders',
                }}
                outlet={<PastRiderList />}
              />
            }
          />
          <Route
            path="/admin/past-data/riders/view/:tab/:id"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/past-data/riders',
                }}
                outlet={<RiderIndividual />}
              />
            }
          />
          {/* Admin Sponser Section*/}
          <Route
            path="/admin/sponsors"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/sponsors',
                }}
                outlet={<SponsersPage />}
              />
            }
          />
          {/* Admin Settings Section*/}
          <Route
            path="/admin/settings"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/settings',
                }}
                outlet={<SettingsPage />}
              />
            }
          />
          <Route
            path="/admin/settings/seasonSettings"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/settings',
                }}
                outlet={<SeasonSettings />}
              />
            }
          />
          <Route
            path="/admin/settings/profile-settings"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/settings',
                }}
                outlet={<ProfileSettingsPage />}
              />
            }
          />
          <Route
            path="/admin/settings/profile-settings/edit"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/settings',
                }}
                outlet={<ProfileUpdatePage />}
              />
            }
          />
          {/* Admin Reports Page */}
          <Route
            path="/admin/reports/rider-points"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/reports/rider-points',
                }}
                outlet={<RiderPointsReportsTable />}
              />
            }
          />
          <Route
            path="/admin/reports/team-points"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/reports/team-points',
                }}
                outlet={<TeamPointsReportsTable />}
              />
            }
          />
          <Route
            path="/admin/reports/sif-report"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/reports/sif-report',
                }}
                outlet={<SIFReports />}
              />
            }
          />
          <Route
            path="/admin/reports/team-roster"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/reports/team-roster',
                }}
                outlet={<TeamRoster />}
              />
            }
          />
          <Route
            path="/admin/reports/show-rider-report-groups"
            element={
              <ProtectedRoute
                {...{
                  ...defaultProtectedRouteProps,
                  redirectPath: '/admin/reports/show-rider-report-groups',
                }}
                outlet={<ShowRiderReportGroups />}
              />
            }
          />
        </Route>
        {/* Admin Section End */}
        <Route
          path="/try-it-registration"
          element={<TryitRegistrationPage />}
        />
        <Route element={<NonCompetingLayout />}>
          {/* <Route
            path="/non-competing-membership"
            element={<NonCompetingRegistration />}
          /> */}
          <Route
            path="/non-competing-membership/returning-member"
            element={<NonCompetingReturningRider />}
          />
        </Route>
        <Route element={<AdminPanelPage />} path="/admin-panel" />
        <Route
          path="/non-competing-membership/new-member"
          element={<NonCompetingMembershipPage />}
        />
        <Route
          path="/associate-membership"
          element={<AssociateMembershipPage />}
        />
        {/* Coach login */}
        <Route element={<CoachLandingPage />}>
          <Route
            path="/rider-landing-page/coach-dashboard"
            element={<CoachDashBoard />}
          />
          <Route
            path="/rider-landing-page/coach-insurance-application"
            element={<CoachInsuranceApplication />}
          />
        </Route>
        {/* Rider Landing Page */}
        <Route element={<RiderLandingPage />}>
          <Route path="/rider-landing-page/dashboard" element={<Dashboard />} />

          <Route path="/rider-landing-page/sif" element={<SIF />} />
          <Route
            path="/rider-landing-page/sif/ewd-new-enrollment"
            element={<EwdNewEnrollment />}
          />
          <Route
            path="/rider-landing-page/sif/ewd-sustaining-enrollment"
            element={<EwdSustainingEnrollment />}
          />
          <Route
            path="/rider-landing-page/sif/rider-new-enrollment"
            element={<RiderNewEnrollment />}
          />
          <Route
            path="/rider-landing-page/sif/rider-sustaining-enrollment"
            element={<RiderSustainingEnrollment />}
          />
        </Route>

        {/* Rider Registration page route */}
        <Route element={<RiderRegistrationLayout />}>
          <Route
            path="/rider-registration-page"
            element={<RiderRegistrationPage />}
          />
          <Route
            path="/rider-registration-page/returning-rider"
            element={<ReturningRider />}
          />
          {/* <Route
            path="/rider-registration-page/returning-rider/details"
            element={<RiderInformation />}
          /> */}

          <Route
            path="/rider-registration-page/terms-and-conditions"
            element={<TermsandConditons />}
          />
          <Route
            path="/rider-registration-page/new-rider"
            element={<NotReturningRider />}
          />
          {/* <Route
            path="/rider-registration-page/new-rider/personal-info"
            element={<PersonalInfo />}
          /> */}
        </Route>

        <Route element={<RiderRegistrationPlacementLayout />}>
          {/* <Route
            path="/rider-registration-page/returning-rider/individual-placement"
            element={<IndividualPlacementForm />}
          /> */}
          {/* <Route
            path="/rider-registration-page/new-rider/individual-placement"
            element={<IndividualPlacementFormNewRider />}
          /> */}
          {/* <Route
            path="/rider-registration-page/returning-rider/individual-placement-payment"
            element={<IndividualPlacementFormTwo />}
          /> */}
          {/* <Route
            path="/rider-registration-page/new-rider/individual-placement-payment"
            element={<IndividualPlacementFormTwoNewRider />}
          /> */}
        </Route>

        {/* Try it Rider Registration page route */}
        <Route element={<TryitRiderRegistrationLayout />}>
          <Route
            path="/tryit-rider-registration-page"
            element={<TryitRiderRegistrationPage />}
          />
          <Route
            path="/tryit-rider-registration-page/returning-member"
            element={<ReturningMember />}
          />
          <Route
            path="/tryit-rider-registration-page/returning-member/details"
            element={<TryitRiderInformation />}
          />
          <Route
            path="/tryit-rider-registration-page/returning-member/membership-placement"
            element={<TryitMembershipForm />}
          />
          <Route
            path="/tryit-rider-registration-page/terms-and-conditions"
            element={<TermsandConditons />}
          />
          <Route
            path="/tryit-rider-registration-page/new-member"
            element={<NewMember />}
          />
          {/* <Route
            path="/tryit-rider-registration-page/new-member/membership-form"
            element={<NewMemberMembershipForm />}
          /> */}
          {/* <Route
            path="/tryit-rider-registration-page/new-member/personal-info"
            element={<NewMemberPersonalInfo />}
          /> */}
        </Route>

        <Route element={<TryitRiderRegistrationPlacementLayout />}>
          {/* <Route
            path="/tryit-rider-registration-page/returning-member/tryit-individual-placement"
            element={<TryitIndividualPlacementForm />}
          /> */}
        </Route>

        <Route element={<TryitRiderRegistrationPlacementPaymentLayout />}>
          {/* <Route
            path="/tryit-rider-registration-page/returning-member/tryit-individual-placement-payment"
            element={<IndividualPlacementFormTwo />}
          /> */}
          {/* <Route
            path="/tryit-rider-registration-page/new-member/individual-placement-payment"
            element={<NewMemberIndividualPlacementFormTwoNewRider />}
          /> */}
        </Route>

        {/* EWD Membership form page route */}
        <Route element={<EWDMembershipFormLayout />}>
          {/* <Route
            path="/ewd-membership-form"
            element={<EWDMembershipFormPage />}
          /> */}
          <Route
            path="/ewd-membership-form/returning-member"
            element={<EWDReturningMember />}
          />
          <Route
            path="/ewd-membership-form/new-member"
            element={<EWDNewMember />}
          />
        </Route>
        <Route element={<EWDMembershipEligibilityLayout />}>
          {/* <Route
            path="/ewd-membership-form/returning-member/eligibile-details"
            element={<EligibilityDetailsReturning />}
          /> */}
          {/* <Route
            path="/ewd-membership-form/returning-member/eligibile-conditions"
            element={<EligibilityConditionsReturning />}
          /> */}
          {/* <Route
            path="/ewd-membership-form/new-member/eligibile-details"
            element={<EligibilityDetailsNew />}
          />
          <Route
            path="/ewd-membership-form/new-member/eligibile-conditions"
            element={<EligibilityConditionsNew />}
          /> */}
        </Route>
        <Route element={<EWDMembershipEquipmentLayout />}>
          {/* <Route
            path="/ewd-membership-form/returning-member/equipment-details"
            element={<EquipmentDetailsReturning />}
          /> */}
          {/* <Route
            path="/ewd-membership-form/returning-member/adaptive-equipments"
            element={<AdaptiveEquipmentsReturning />}
          /> */}
          {/* <Route
            path="/ewd-membership-form/new-member/equipment-details"
            element={<EquipmentDetailsNew />}
          />
          <Route
            path="/ewd-membership-form/new-member/adaptive-equipments"
            element={<AdaptiveEquipmentsNew />}
          /> */}
        </Route>

        {/* Try it EWD Membership form page route */}
        <Route element={<TryitEWDMembershipLayout />}>
          <Route
            path="/tryit-ewd-membership-form"
            element={<TryitEWDMembershipPage />}
          />
          <Route
            path="/tryit-ewd-membership-form/returning-member"
            element={<TryitEWDReturningMember />}
          />
          <Route
            path="/tryit-ewd-membership-form/returning-member/details"
            element={<TryitEWDRiderInformation />}
          />

          <Route
            path="/tryit-ewd-membership-form/new-member"
            element={<TryitEWDNewMember />}
          />
        </Route>

        <Route element={<TryitEWDMembershipEligibilityLayout />}>
          <Route
            path="/tryit-ewd-membership-form/returning-member/eligibile-details"
            element={<TryitEligibilityDetailsReturning />}
          />
          <Route
            path="/tryit-ewd-membership-form/returning-member/eligibile-conditions"
            element={<TryitEligibilityConditionsReturning />}
          />
          {/* <Route
            path="/tryit-ewd-membership-form/new-member/eligibile-details"
            element={<TryitEligibilityDetailsNew />}
          />
          <Route
            path="/tryit-ewd-membership-form/new-member/eligibile-conditions"
            element={<TryitEligibilityConditionsNew />}
          /> */}
        </Route>

        <Route element={<TryitEWDMembershipEquipmentLayout />}>
          <Route
            path="/tryit-ewd-membership-form/returning-member/equipment-details"
            element={<TryitEquipmentDetailsReturning />}
          />
          <Route
            path="/tryit-ewd-membership-form/returning-member/adaptive-equipments"
            element={<TryitAdaptiveEquipmentsReturning />}
          />
          {/* <Route
            path="/tryit-ewd-membership-form/new-member/equipment-details"
            element={<TryitEquipmentDetailsNew />}
          />
          <Route
            path="/tryit-ewd-membership-form/new-member/adaptive-equipments"
            element={<TryitAdaptiveEquipmentsNew />}
          /> */}
        </Route>
        <Route element={<AlumniLayout />}>
          {/* <Route path="/alumni-registration" element={<AlumniRegistration />} /> */}
          <Route
            path="/alumni-registration/new-member"
            element={<AlumniNewMemberResgistration />}
          />
          <Route
            path="/alumni-registration/returning-member"
            element={<AlumniReturningMemberRegistration />}
          />
        </Route>
        <Route element={<AlumniTryItLayout />}>
          <Route
            path="/alumni-try-it-registration"
            element={<AlumniTryItResgistration />}
          />
        </Route>
        {/* <Route path="/coach-registration" element={<CoachRegistrationPage />} /> */}
        <Route element={<CoachRegistrationLayout />}>
          <Route
            path="/coach-registration"
            element={<ApplyingForCoachResgistration />}
          />
          <Route
            path="/coach-registration/coach"
            element={<CoachResgistration />}
          />
          <Route
            path="/coach-registration/coach-insurance"
            element={<CoachInsurance />}
            caseSensitive={true}
          />
          <Route
            path="/coach-registration/assistant-coach"
            element={<AssistantCoachResgistration />}
          />
        </Route>
        <Route element={<CoachTryItLayout />}>
          <Route
            path="/coach-registration/try-it"
            element={<CoachTryItResgistration />}
          />
        </Route>
      </Routes>
      <GlobalStyle />
      {/* <ToastContainer /> */}
      <MHToast />
    </BrowserRouter>
  );
}
