import styled from 'styled-components';
import { TextField } from 'app/components/TextField';
import '@fontsource/roboto';
import React from 'react';
import { useState } from 'react';
import { CheckBox } from 'app/components/CheckBox';
import { BackNextButton } from 'app/components/BackNextButton';
import SHdropdown from 'app/components/SHdropdown';
import { EWD, GeneralLookup } from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';

type Props = {
  onClickBackParent?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onClickNxtParent?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  riderData?: EWD | undefined;
  setRiderData: React.Dispatch<React.SetStateAction<EWD>>;
  stateData: GeneralLookup[] | undefined;
};

const EWDParentDetailsReturning = ({
  onClickBackParent,
  onClickNxtParent,
  riderData,
  setRiderData,
  stateData,
}: Props) => {
  const [valid, setValid] = useState<boolean>(false);
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const handleChange = event => {
    const { name, value } = event.target;
    setRiderData(prevData => ({
      ...prevData,
      parent: {
        ...prevData.parent,
        [name]: value,
      },
    }));
  };
  const handleCheckbox = e => {
    console.log(e, 'eeee');
    if (e.value) {
      if (riderData) {
        setRiderData({
          ...riderData,
          parent: {
            ...riderData.parent,
            state: riderData.state,
            street: riderData.street,
            city: riderData.city,
            zip: riderData.zip,
          },
        });
      }
    } else {
      if (riderData) {
        setRiderData({
          ...riderData,
          parent: {
            ...riderData.parent,
            state: {
              id: '',
              name: '',
              children: null,
            },
            street: '',
            city: '',
            zip: '',
          },
        });
      }
    }
  };
  const handlePhChange = e => {
    const inputValue = e.target.value;
    if (riderData) {
      if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
        setRiderData({
          ...riderData,
          parent: {
            ...riderData.parent,
            phone: e.target.value,
          },
        });
      }
    }
  };
  const handleZipChange = e => {
    const inputValue = e.target.value;
    if (riderData) {
      if (inputValue.length <= 9 && /^[a-zA-Z0-9]*$/.test(inputValue)) {
        setRiderData({
          ...riderData,
          parent: {
            ...riderData.parent,
            zip: e.target.value,
          },
        });
      }
    }
  };

  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [riderData]);
  return (
    <MainWrapper>
      <OverflowWrapper>
        <TextFieldGrid>
          <TextFieldWrapper>
            <StyledTextField
              onChange={handleChange}
              value={riderData?.parent.firstName!}
              label="Parent/Guardian First Name"
              type="text"
              name="firstName"
              validations={'required|max:30'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              onChange={handleChange}
              value={riderData?.parent.lastName!}
              label="Parent/Guardian Last Name"
              type="text"
              name="lastName"
              validations={'required|max:30'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              onChange={handleChange}
              value={riderData?.parent.email!}
              label="Parent/Guardian Email"
              type="email"
              name="email"
              validations={'required|email'}
              validator={simpleValidator.current}
              maxLength={60}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              onChange={handlePhChange}
              value={riderData?.parent.phone!}
              label="Parent/Guardian Ph#"
              type="tel"
              name="phone"
              validations={'required|numeric|min:10|max:10'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
        <HomeAddressWrapper className="horizontal-line">
          <p className="home-address">Parent's Home Address</p>
          <CheckBox
            label="(same as rider address)"
            onChange={handleCheckbox}
            checked={riderData?.parent.state === riderData?.state}
          />
        </HomeAddressWrapper>
        <TextFieldGrid>
          <TextFieldWrapper>
            <StyledTextField
              onChange={handleChange}
              value={riderData?.parent.street!}
              label="Street Address"
              type="text"
              name="street"
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              onChange={handleChange}
              value={riderData?.parent.city!}
              label="City"
              type="text"
              name="city"
              validations={'required|max:40'}
              validator={simpleValidator.current}
              maxLength={40}
            />
          </TextFieldWrapper>
          <DropdownWrapper>
            <SHdropdown
              className="drop-down"
              label="State"
              data={stateData}
              onDataChange={data => {
                if (riderData) {
                  setRiderData!({
                    ...riderData,
                    parent: {
                      ...riderData.parent,
                      state: data,
                    },
                  });
                }
              }}
              defaultValue={riderData?.parent.state}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </DropdownWrapper>
          <TextFieldWrapper>
            <StyledTextField
              onChange={handleZipChange}
              value={riderData?.parent.zip!}
              label="Zip"
              type="text"
              name="zip"
              validations={'required|min:5|max:9'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
        <ButtonWrapper className="bg-color-change">
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={onClickNxtParent}
            onClickBack={onClickBackParent}
            next="Next"
            back="Cancel"
            disableNext={valid}
          />
        </ButtonWrapper>
      </OverflowWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  color: #9597a6;
  width: 100%;
  height: calc(100vh - 260px);
  position: relative;
  padding: 20px 0px 0px;
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    // padding-top: 20px;
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    // padding-top: 20px;
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    // padding-top: 20px;
    label {
      font-size: 14px;
    }
  }
`;
const OverflowWrapper = styled.div`
  height: 100%;
  overflow: auto;
  padding-bottom: 100px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
`;

const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  @media (min-width: 100px) and (max-width: 319px) {
    display: block;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    display: block;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    display: block;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    display: block;
  }
`;
const HomeAddressWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 12px;
  gap: 10px;
  label {
    color: #e98d04;
    font-style: oblique;
  }

  ::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #585858;
  }

  p {
    margin: 0 10px 0px 0px;
    white-space: nowrap;
  }
`;
const TextFieldWrapper = styled.div`
  flex: 0 0 calc(50% - 20px) !important;
  margin: 0px 0px 10px !important;
`;
const StyledTextField = styled(TextField)``;

const DropdownWrapper = styled.div`
  flex: 0 0 calc(50% - 20px);
  p {
    margin: 0px;
    font-family: 'Roboto';
    font-size: 14px;
  }

  .SHdropdown {
    padding: 0 12px;
    margin: 0px;
    width: 100% !important;
    height: 35px;
    color: #9597a6;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    padding-bottom: 25px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding-bottom: 25px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-bottom: 25px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-bottom: 25px;
  }
`;
const ButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;
  z-index: 2;
  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: -1px;
    left: 0px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    backdrop-filter: blur(5px);
  }
  @media (min-width: 100px) and (max-width: 319px) {
    height: 50px;
    &.bg-color-change {
      bottom: -12px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    height: 50px;
    &.bg-color-change {
      bottom: -12px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    height: 50px;
    &.bg-color-change {
      bottom: -12px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
    @media (min-width: 1200px) and (max-width: 1365px) {
      height: 55px;
    }
    @media (min-width: 1366px) and (max-width: 1448px) {
      height: 70px;
    }
  }
`;

export default EWDParentDetailsReturning;
