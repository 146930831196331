import { GridCellProps } from '@progress/kendo-react-grid';
import { formatDate } from 'utils/common';

type Props = {
  className?: string;
  e: GridCellProps;
};

const ShowDateCell = ({ className, e }: Props) => {
  const data = e?.dataItem;
  return (
    <td className={className ?? ''}>
      <div>{formatDate(data.showStartDate)}</div>
    </td>
  );
};

export default ShowDateCell;
