import React, { useState } from 'react';
import styled from 'styled-components';
import '@fontsource/roboto';
import { TextField } from 'app/components/TextField';
import SHdropdown from 'app/components/SHdropdown';
import { useNavigate } from 'react-router-dom';
import { BackNextButton } from 'app/components/BackNextButton';
import { EWD, GeneralLookup, GeneralLookupWrapper } from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';

type Props = {
  riderData: EWD | undefined;
  setRiderData: React.Dispatch<React.SetStateAction<EWD>>;
  stateData: GeneralLookup[] | undefined;
  onClickEligibilityDetailsNext: () => void;
  onClickEligibilityDetailsBack: () => void;
  setTeamIdPayload: (
    value: React.SetStateAction<GeneralLookup | undefined>,
  ) => void;
  teamIdPayload: GeneralLookup | undefined;
  lookUp: GeneralLookupWrapper[] | undefined;
};

export const EligibilityDetailsReturning = ({
  riderData,
  setRiderData,
  stateData,
  onClickEligibilityDetailsNext,
  onClickEligibilityDetailsBack,
  setTeamIdPayload,
  teamIdPayload,
  lookUp,
}: Props) => {
  // const simpleValidator = React.useRef(new SimpleReactValidator());
  // const [valid, setValid] = useState<boolean>(false);
  const handleChange = event => {
    const { name, value } = event.target;
    console.log('changed', value);
    setRiderData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleTeam = data => {
    setRiderData(prevData => ({
      ...prevData,
      team: data,
    }));
  };
  const handleEmergencyPhChange = e => {
    const inputValue = e.target.value;
    if (riderData) {
      if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
        setRiderData({
          ...riderData,
          emergencyContactPhone: inputValue,
        });
      }
    }
  };
  // React.useEffect(() => {
  //   const formValid = simpleValidator.current.allValid();
  //   setValid(!formValid);
  // }, [riderData]);
  return (
    <MainWrapper>
      <p className="first-para">
        A membership registration forms for Equestrian with Disabilities must be
        accompanied by a Diagnosis and Adaptive Equipment Form.
      </p>
      <OverflowWrapper>
        <TextFieldGrid>
          <TextFieldWrapper>
            <StyledTextField
              onChange={handleChange}
              value={riderData?.firstName}
              label="First Name"
              type="text"
              name="firstName"
              // validations={'required|max:30'}
              // validator={simpleValidator.current}
              disabled={true}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              onChange={handleChange}
              value={riderData?.lastName}
              label="Last Name"
              type="text"
              name="lastName"
              // validations={'required|max:30'}
              // validator={simpleValidator.current}
              disabled={true}
            />
          </TextFieldWrapper>
          <DropdownWrapper>
            <SHdropdown
              className="drop-down"
              label="Team Name"
              onDataChange={handleTeam}
              data={lookUp?.find(obj => obj.type === 'TEAM')?.values ?? []}
              defaultValue={riderData?.team}
              // validations={'required'}
              // validator={simpleValidator.current}
              disabled={true}
            />
          </DropdownWrapper>
          <TextFieldWrapper>
            <StyledTextField
              onChange={handleChange}
              value={riderData?.primaryPhone}
              label="Phone Number"
              type="number"
              name="primaryPhone"
              // validations={'required|numeric|min:10|max:10'}
              // validator={simpleValidator.current}
              disabled={true}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
        <MainAddressWrapper>
          <div className="horizontal-line">
            <span>Rider's Address</span>
          </div>
          <TextFieldGrid>
            <TextFieldWrapper>
              <StyledTextField
                onChange={handleChange}
                value={riderData?.street}
                label="Street Address"
                type="text"
                name="street"
                // validations={'required'}
                // validator={simpleValidator.current}
                disabled={true}
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <StyledTextField
                onChange={handleChange}
                value={riderData?.city}
                label="City"
                type="text"
                name="city"
                // validations={'required|max:40'}
                // validator={simpleValidator.current}
                maxLength={40}
                disabled={true}
              />
            </TextFieldWrapper>
            <DropdownWrapper>
              <SHdropdown
                className="drop-down"
                label="State"
                data={stateData}
                onDataChange={data => {
                  if (riderData) {
                    setRiderData!({
                      ...riderData,
                      state: data,
                    });
                  }
                }}
                defaultValue={riderData?.state}
                // validations={'required'}
                // validator={simpleValidator.current}
                disabled={true}
              />
            </DropdownWrapper>
            <TextFieldWrapper>
              <StyledTextField
                onChange={handleChange}
                value={riderData?.zip}
                label="Zip"
                type="number"
                name="zip"
                // validations={'required|min:5|max:9'}
                // validator={simpleValidator.current}
                disabled={true}
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <StyledTextField
                onChange={handleChange}
                value={riderData?.email}
                label=" Email"
                type="email"
                name="email"
                // validations={'required|email'}
                // validator={simpleValidator.current}
                maxLength={60}
                disabled={true}
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <StyledTextField
                onChange={handleChange}
                value={riderData?.emergencyContactName!}
                label="Emergency Contact"
                type="text"
                name="emergencyContactName"
                // validations={'required'}
                // validator={simpleValidator.current}
                disabled={true}
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <StyledTextField
                label="Emergency Contact Number"
                type="tel"
                name="emergencyContactNumber"
                onChange={handleEmergencyPhChange}
                value={riderData?.emergencyContactPhone ?? ''}
                // validations={'required|numeric|min:10|max:10'}
                // validator={simpleValidator.current}
                disabled={true}
              />
            </TextFieldWrapper>
          </TextFieldGrid>
        </MainAddressWrapper>
        <ButtonWrapper className="bg-color-change">
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={onClickEligibilityDetailsNext}
            onClickBack={onClickEligibilityDetailsBack}
            next="Next"
            back="Cancel"
            // disableNext={valid}
          />
        </ButtonWrapper>
      </OverflowWrapper>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  color: #9597a6;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  height: calc(100vh - 100px);
  overflow: auto;
  padding-top: 50px;

  .first-para,
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 40px;
    .first-para,
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    padding-top: 45px;
    .first-para,
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    padding-top: 50px;
    .first-para,
    label {
      font-size: 14px;
    }
  }
`;
const OverflowWrapper = styled.div`
  height: 100%;
  overflow: auto;
  padding-bottom: 100px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
`;

const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 20px;
  ::placeholder {
    color: #8f8e8e !important;
  }

  @media (min-width: 100px) and (max-width: 319px) {
    display: block;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    display: block;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    display: block;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    display: block;
  }
`;
const TextFieldWrapper = styled.div`
  flex: 0 0 calc(50% - 20px) !important;
  margin: 0px 0px 10px !important;
`;
const StyledTextField = styled(TextField)`
  flex: 0 0 calc(50% - 20px);
  margin: 0px 0px 10px !important;
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const MainAddressWrapper = styled.div`
  padding-bottom: 50px;
  .horizontal-line {
    display: flex;
    align-items: baseline;
    margin: 20px 0 15px;
  }
  .horizontal-line span {
    margin: 0 10px 0 0;
    white-space: nowrap;
    color: #4f4f4f;
    font-size: 15px;
  }
  .horizontal-line::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #434343;
    height: 3px;
  }
`;
const DropdownWrapper = styled.div`
  flex: 0 0 calc(50% - 20px);

  p {
    margin: 0px;
    font-family: 'Roboto';
    font-size: 14px;
  }
  .SHdropdown {
    margin: 0px;
    color: #9597a6;
    width: 100%;
    height: 35px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    padding-bottom: 25px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding-bottom: 25px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-bottom: 25px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-bottom: 25px;
  }
`;
const ButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: 7px;
    left: -1px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: 2;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
    @media (min-width: 1200px) and (max-width: 1365px) {
      height: 55px;
    }
    @media (min-width: 1366px) and (max-width: 1448px) {
      height: 70px;
    }
  }
`;
