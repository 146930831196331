import { GridCellProps } from '@progress/kendo-react-grid';

type Props = {
  className?: string;
  e: GridCellProps;
  onClick?: (e) => void;
};

const RiderResultName = ({ className, e, onClick }: Props) => {
  const data = e.dataItem;
  return <td className={className ?? ''}>{data?.riderName}</td>;
};

export default RiderResultName;
