import * as React from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import styled from 'styled-components';
import { CheckBox } from 'app/components/CheckBox';

const data = [
  {
    Id: 1,
    Checkbox: false,
    FirstName: 'Vrithanth',
    SecondName: 'M k',
    Team: 'Jackpot',
    GraduationYear: 2023,
    Zip: 'xxxx',
  },
  {
    Id: 2,
    Checkbox: false,
    FirstName: 'Chang',
    SecondName: 'N',
    Team: 'New',
    GraduationYear: 2021,
    Zip: 'xxxx',
  },
  {
    Id: 3,
    Checkbox: false,
    FirstName: '',
    SecondName: '',
    Team: '',
    GraduationYear: '',
    Zip: 'xxxx',
  },
  {
    Id: 4,
    Checkbox: false,
    FirstName: '',
    SecondName: '',
    Team: '',
    GraduationYear: '',
    Zip: 'xxxx',
  },
  {
    Id: 5,
    FirstName: '',
    SecondName: '',
    Team: '',
    GraduationYear: '',
    Zip: 'xxxx',
  },
];

const EWDRiderTable = () => {
  return (
    <MainWrapper>
      <Grid style={{ height: '100%' }} data={data}>
        <GridColumn
          field="Checkbox"
          title=""
          width="80px"
          cell={props => (
            <td>
              <StyledCheckBox />
            </td>
          )}
        />
        <GridColumn field="FirstName" title="First Name" width="120px" />
        <GridColumn field="LastName" title="Second Name" width="120px" />
        <GridColumn field="Team" title="Team" width="120px" />
        <GridColumn
          field="GraduationYear"
          title="Graduation Year"
          width="80px"
        />
        <GridColumn field="Zip" title="Zip" width="80px" />
      </Grid>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  border: 1px solid;
  border-color: transparent;
  border-radius: 12px !important;
  table,
  tr {
    color: #9597a6;
  }
  .k-grid tr {
    border-color: unset;
    background: #2d2d2e;
  }
  th {
    background: #373737;
    color: #717171;
    border-color: unset;
  }
  th {
    border-color: transparent;
  }
  .k-grid .k-alt {
    background: #373737;
    border-color: unset;
  }
  .k-grid {
    border-color: unset;
    color: #unset;
    background-color: unset;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  .k-table,
  .k-data-table {
    border-color: transparent !important;
  }
  .k-grid td,
  .k-grid .k-grid-content,
  .k-grid .k-grid-header-locked,
  .k-grid .k-grid-content-locked {
    border-color: transparent !important;
    ::-webkit-scrollbar {
      width: 5px;
      margin-left: 3px;
    }
    ::-webkit-scrollbar-track {
      background: transparent !important;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      width: 9px;
      background: transparent !important;
      border-radius: 10px;
    }
  }
  .k-grid-header {
    border-color: transparent !important;
    background-color: transparent;
  }
  .k-table-thead,
  .k-table-tbody,
  .k-table-tfoot,
  .k-table-row,
  .k-table-alt-row {
    border-color: unset;
    background-color: #2d2d2e;
  }
  .table {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  .k-grid-header-wrap {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
  .k-grid-footer-wrap,
  colgroup {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  ..k-grid-content,
  .k-grid-content-locked {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  .k-grid-content {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
`;
const StyledCheckBox = styled(CheckBox)`
  display: flex;
  justify-content: center;
  label {
    display: none;
  }
`;
export default EWDRiderTable;
