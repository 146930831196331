import { CommonPageWrapper, HeadingWrapper } from 'admin/components/wrappers';
import CustomBreadCrumb from 'app/components/CustomBreadCrumb';
import { PageMainLabel } from 'app/components/SHLabel';
import { breadCrumpitems } from './StaticData';
import SettingsCard from './SettingsCard';
import { CommonLoader } from 'app/components/CommonLoader';
import useSeasonApi from 'admin/hooks/useSeasonApi';

type Props = {};

const SettingsPage = (props: Props) => {
  return ( 
    <CommonPageWrapper>
      <HeadingWrapper>
        <PageMainLabel>Manage Settings</PageMainLabel>
        <CustomBreadCrumb preData={breadCrumpitems} />
      </HeadingWrapper>
      <SettingsCard/>
    </CommonPageWrapper>
  )
};
export default SettingsPage;
