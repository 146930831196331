import { useState } from 'react';
import useApi from 'services/mavapi/useApi';
import {
  GeneralLookupWrapper,
  LookupService,
  ReportService,
  TeamPointWrapper,
} from 'services/openapi';

const useTeamReportsApi = () => {
  const [, setError] = useState<unknown | null>();
  const { error: apiError, handleRequest } = useApi();
  const [loading, setLoading] = useState(false);
  const { reportGetTeamPointsDetails, reportGetTeamPointReport } =
    ReportService;

  // States
  const [showInfoData, setShowInfoData] = useState<TeamPointWrapper>();

  const getTeamPointsDetails = (seasonId: string) => {
    setLoading(true);
    handleRequest(reportGetTeamPointsDetails(seasonId))
      .then(res => {
        setShowInfoData(res);
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };
  const downloadTeamPoints = (seasonId: string): Promise<string> => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      handleRequest(reportGetTeamPointReport(seasonId))
        .then(res => {
          resolve(res);
          setLoading(false);
        })
        .catch(() => {
          reject(apiError);
          setLoading(false);
        });
    });
  };

  return {
    loading,
    getTeamPointsDetails,
    showInfoData,
    downloadTeamPoints,
  };
};

export default useTeamReportsApi;
