import styled from 'styled-components';
import { Pager, PagerProps } from '@progress/kendo-react-data-tools';

type pagerTypes = {
  props: PagerProps;
  totalCount?: number;
};

const CustomPagination = ({ props, totalCount }: pagerTypes) => {
  const { total, skip, take } = props!;

  return (
    <CustomPaginationWrapper className={` custom-pagination-wrapper`}>
      <PagerWrapper>
        <Pager
          {...props}
          className={`${totalCount === 0 ? 'no-entries' : ''}`}
        />
      </PagerWrapper>
    </CustomPaginationWrapper>
  );
};
export default CustomPagination;
const CustomPaginationWrapper = styled.div`
  width: 100%;
  padding: 20px 0px;
  .k-pager {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    padding: 8px 8px 8px 0px;
    border-radius: 6px;
    max-height: 40px;
    background: transparent;
    &.no-entries {
      visibility: hidden;
    }
    border: none;
    .k-pager-first,
    .k-pager-last {
      display: none;
    }
    .k-link {
      margin: 0 0 0 0;
      :not(.k-disabled) {
        &:hover {
        }
      }
    }
    .k-pager-numbers-wrap {
      position: relative;
      right: 3px;
      .k-svg-icon > svg {
        opacity: 0;
      }
      .k-button-flat::after {
        box-shadow: none;
      }
      .k-button::before {
        transition: none;
      }
      .k-button:hover::before {
        background-color: #ffffff !important;
      }
      .k-button-md.k-icon-button {
        min-width: 75px;
      }
      .k-svg-i-caret-alt-left {
        ::before {
          content: 'Previous';
          font-family: 'Inter';
          font-weight: 500;
          font-size: 14px;
          display: inline-block;
          left: -15px;
          position: absolute;
          color: #9e9e9e;
        }
      }
      .k-svg-i-caret-alt-right {
        ::before {
          content: 'Next';
          font-family: 'Inter';
          font-weight: 500;
          font-size: 14px;
          display: inline-block;
          left: -15px;
          position: absolute;
          color: #9e9e9e;
        }
      }
      .k-pager-numbers {
        gap: 15px;
        align-items: center;
        button {
          text-align: center;
          color: #000000 !important;
          background-color: #e0e0e0 !important;
          border-radius: 0px !important;
          max-width: 31px;
          min-width: 31px;
          height: 31px;
          font-family: 'Inter';
          font-weight: 600;
          font-size: 12px;
          border-radius: 8px !important;
          &:focus {
            box-shadow: none !important;
          }
          &.k-selected {
            background-color: #4764ff !important;
            color: #ffffff !important;
            span {
              color: #ffffff !important;
            }
          }
        }
      }
    }
    .k-pager-info {
      justify-content: end;
    }
    .k-button {
      font-family: 'Inter' !important;
    }
  }
`;
const PagerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: flex-start;
  padding: 4px 0;
  .pagerInfo {
    align-items: center;
    display: flex;
    font-size: 10px;
    font-family: 'lato';
    font-weight: 600;
    margin-right: 20px;
  }
`;
