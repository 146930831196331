import React from 'react';
import styled from 'styled-components';

type Props = {
  children?: React.ReactNode; // make the component able to receive children elements
  bgTheme?: 'light' | 'dark';
};

const LoaderWrapper = ({ children, bgTheme }: Props) => {
  return (
    <LoaderComponentWrapper className={`loader ${bgTheme}`}>
      {children}
    </LoaderComponentWrapper>
  );
};

export default LoaderWrapper;
const LoaderComponentWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2a2a2a;
  z-index: 10;
  /* position: relative; */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  &.loader {
    &.light {
      background: #ededed !important;
    }
    &.dark {
      background: #2a2a2a;
    }
  }
`;
