import { GridCellProps } from '@progress/kendo-react-grid';
import { DeleteIcon } from 'app/assets/icons';
import styled from 'styled-components';

type Props = {
  className?: string;
  e: GridCellProps;
  deleteClick?: (event: React.MouseEvent<SVGSVGElement>) => void;
};

const DeleteIconCell = ({ className, e, deleteClick }: Props) => {
  const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
    if (deleteClick) {
      deleteClick(event);
    }
  };

  return (
    <StyledTd className={className ?? ''}>
      <div>
        <DeleteIcon onClick={handleClick} />
      </div>
    </StyledTd>
  );
};

export default DeleteIconCell;
const StyledTd = styled.td`
  svg {
    cursor: pointer;
  }
`;
