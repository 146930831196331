import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import styled from 'styled-components';
import { FormSkeletonLoader } from '../FormSkeletonLoader';

type Props = {
  label?: string;
  className?: string;
  onChange?: (event: CheckboxChangeEvent) => void;
  checked?: boolean | null;
  value?: string | number | string[] | boolean | null;
  isDisabled?: boolean;
  isLoader?: boolean;
  readOnly?: boolean | undefined;
};

export const AdminCheckBox = ({
  className,
  label,
  onChange,
  checked,
  value,
  isDisabled,
  isLoader,
  readOnly,
}: Props) => {
  return (
    <StyledDiv className={className}>
      {!isLoader ? (
        <>
          <label>
            <Checkbox
              onChange={onChange}
              checked={isDisabled ? false : checked}
              value={value}
              disabled={isDisabled}
              readOnly={readOnly}
            />
            {label}
          </label>
        </>
      ) : (
        <FormSkeletonLoader textHeight={44} labelHeight={20} isLabel />
      )}
    </StyledDiv>
  );
};
const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  margin: 6px 0;
  .k-checkbox {
    background-color: #fff !important;
  }
  .k-checkbox-wrap {
    padding-right: 10px !important;
  }

  .k-checkbox:focus {
    box-shadow: none !important;
    border-color: #a5a5a5 !important;
  }
  &:focus {
    outline-style: none;
    box-shadow: none;
    border-color: #a5a5a5;
  }

  input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid #808080;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s;
    cursor: pointer;
  }

  input[type='checkbox']:checked {
    background-color: #2dce98 !important;
  }

  input[type='checkbox']:checked::before {
    content: '';
    display: block;
    width: 9px;
    height: 5px;
    border: 2px solid white;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
    margin: 3px;
  }
  label {
    font-size: 14px;
    font-family: 'Inter';
    cursor: pointer;
    font-weight: 500;
    line-height: 19.6px;
    display: inline-flex !important;
  }
  //Component Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    label {
      font-size: 10px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    label {
      font-size: 11px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    label {
      font-size: 13px;
    }
  }
`;
