import { GridCellProps } from '@progress/kendo-react-grid';
import { SHToolTip } from 'app/components/SHToolTip';

type Props = {
  className?: string;
  e: GridCellProps;
  onClick?: (e) => void;
};

const ClassCell = ({ className, e, onClick }: Props) => {
  const data = e.dataItem.class.name;
  return (
    <td className={className ?? ''}>
      <SHToolTip position="top">
        <div title={data} className="custom-cell-style">
          {data}
        </div>
      </SHToolTip>
    </td>
  );
};

export default ClassCell;
