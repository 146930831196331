import React from 'react';
import styled from 'styled-components';
import arrow from './assets/img/icon/arrow.png';

type Props = {
  userType?: string;
};

export const LiabilityInsuranceInfo = ({ userType }: Props) => {
  return (
    <MainWrapper className="LiabilityInsuranceInfo">
      <p className="para-one">
        <i>Liability Insurance Information</i>
      </p>
      <p className="para-two">
        In order to qualify as a YEDA Coach, you much attach to this application
        a valid Certificate of Insurance (COI), or other adequate proof of
        insurance. Your status as a YEDA Coach will not commence until a valid
        COI for the competition year is received by YEDA.
      </p>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  width: 100%;
  border: 1px solid #585858;
  border-radius: 7px;
  margin: 30px 0px 20px;
  padding: 20px;
  p {
    font-family: 'Roboto';
  }
  .para-one {
    color: #ff9900;
    font-size: 16px;
    border-bottom: 1px solid #585858;
  }
  .para-two {
    padding-bottom: 10px;
  }
`;

const MappedDataWrapper = styled.div`
  display: flex;
  align-items: baseline;
  li {
    padding-left: 10px;
    list-style-type: none;
    padding-bottom: 10px;
    font-family: 'Roboto';
    font-size: 14px;
  }
  img {
    width: 11px;
    height: 11px;
  }
`;
