import { GridCellProps } from '@progress/kendo-react-grid';

type Props = {
  e: GridCellProps;
  onClick?: (e) => void;
};

export const RideShowBackNoCell = ({ e, onClick }: Props) => {
  const data = e.dataItem.riderBackNo;
  return <td style={{ alignItems: 'center' }}>{data}</td>;
};
