import React from 'react';
import styled from 'styled-components';
import Icon from './assets/img/icon.png';
import SHButton from '../SHButton';
import AdminCustomButton from '../AdminCustomButton';
import { SHToolTip } from '../SHToolTip';

type Props = {
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onCancel?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onUpload?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onRemoveFile?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  uploadBtnDisabled?: boolean;
  chooseFileBtnDisabled?: boolean;
  uploadFile?: File | null | undefined;
  cancelBtnDisabled?: boolean;
  isView?: boolean;
  uploadStringFile?: string | null;
  isHideButtons?: boolean;
  isEdit?: boolean;
};

export const AdminDragAndDropFiles = ({
  onUpload,
  onCancel,
  onChange,
  onRemoveFile,
  className,
  uploadBtnDisabled,
  chooseFileBtnDisabled,
  uploadFile,
  cancelBtnDisabled,
  isView = false,
  uploadStringFile,
  isHideButtons = false,
  isEdit = false,
}: Props) => {
  // drag state
  const [dragActive, setDragActive] = React.useState(false);
  //Files
  const [files, setfiles] = React.useState<File | null>(null);
  // ref
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const lastSlashIndex = uploadStringFile?.lastIndexOf(`\\`);
  const extractedPart = uploadStringFile?.substring(lastSlashIndex ?? 0);
  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setfiles(e.dataTransfer.files ? e.dataTransfer.files[0] : null);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      // handleFile(e.target.files);
      setfiles(e.target.files && e.target.files[0]);
    }
    onChange?.(e);
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef?.current?.click();
  };
  const handleCancelClick = e => {
    inputRef.current!.value = '';
    setfiles(null);
    onCancel?.(e);
  };
  const handleUploadClick = e => {
    onUpload?.(e);
  };
  const handleFileCancel = e => {
    inputRef.current!.value = '';
    setfiles(null);
    onRemoveFile?.(e);
  };
  return (
    <>
      {isView === false ? (
        <DragAndDropWrapper className={className}>
          <div
            id="form-file-upload"
            onDragEnter={handleDrag}
            // onSubmit={e => e.preventDefault()}
            onDrop={handleDrop}
          >
            <input
              ref={inputRef}
              type="file"
              id="input-file-upload"
              multiple={false}
              onChange={handleChange}
            />
            <label
              id="label-file-upload"
              className={dragActive ? 'drag-active' : ''}
              onClick={e => e.preventDefault()}
            >
              <InnerWrapper>
                <SubWrapper>
                  <img src={Icon} alt="" className="upload-logo" />
                  <div>
                    <p>
                      Drag & drop your files here or
                      <button
                        className="upload-button"
                        onClick={onButtonClick}
                        disabled={chooseFileBtnDisabled}
                      >
                        choose the file
                      </button>
                    </p>
                  </div>
                </SubWrapper>

                <BottomWrapper className="BottomWrapper">
                  {isEdit && (
                    <SelectedFileBlock className="SelectedFileBlock">
                      <SelectedExtensionNameWrapper className="SelectedExtensionNameWrapper">
                        <span className="extension-block">
                          {uploadStringFile?.split('.').pop() ??
                            uploadStringFile?.split('.').pop()}
                        </span>
                        <span className="filename-block">
                          {uploadStringFile ?? uploadStringFile}
                        </span>
                      </SelectedExtensionNameWrapper>
                      <span
                        className="k-icon k-i-close fileCancel"
                        onClick={onRemoveFile}
                      ></span>
                    </SelectedFileBlock>
                  )}
                  {(uploadFile || files) && !isEdit ? (
                    <SelectedFileBlock className="SelectedFileBlock">
                      <SelectedExtensionNameWrapper className="SelectedExtensionNameWrapper">
                        <span className="extension-block">
                          {files?.name?.split('.').pop() ??
                            uploadFile?.name.split('.').pop()}
                        </span>
                        <SHToolTip position="top">
                          <span title={files?.name} className="filename-block">
                            {files?.name ?? uploadFile?.name}
                          </span>
                        </SHToolTip>
                      </SelectedExtensionNameWrapper>
                      <span
                        className="k-icon k-i-close fileCancel"
                        onClick={handleFileCancel}
                      ></span>
                    </SelectedFileBlock>
                  ) : (
                    ''
                  )}
                  {!isHideButtons && (
                    <ButtonWrapper>
                      <AdminCustomButton
                        buttonType="cancel"
                        onClick={handleCancelClick}
                        disabled={cancelBtnDisabled}
                      >
                        Cancel
                      </AdminCustomButton>
                      <AdminCustomButton
                        buttonType="save"
                        className="uploadBtn"
                        onClick={handleUploadClick}
                        disabled={uploadBtnDisabled}
                      >
                        Save
                      </AdminCustomButton>
                    </ButtonWrapper>
                  )}
                </BottomWrapper>
              </InnerWrapper>
            </label>
            {dragActive && (
              <div
                id="drag-file-element"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              ></div>
            )}
          </div>
        </DragAndDropWrapper>
      ) : (
        <SelectedFileBlock className="sc-jipzzP dzieYn SelectedFileBlock">
          <div className="sc-fPBtGE lbJfks SelectedExtensionNameWrapper">
            <span className="extension-block">
              {files?.name?.split('.').pop() ??
                uploadStringFile?.split('.').pop()}
            </span>
            <SHToolTip position="top">
              <div
                title={extractedPart?.replace(/\\/g, '')}
                className="filename-block"
              >
                {extractedPart?.replace(/\\/g, '')}
              </div>
            </SHToolTip>
          </div>
        </SelectedFileBlock>
      )}
    </>
  );
};

const DragAndDropWrapper = styled.div`
  width: 100%;
  .uploadBtn:has(.k-disabled) {
    cursor: not-allowed !important;
  }

  .k-dropzone-inner {
    color: #9597a6;
    background-color: transparent;
  }
  #form-file-upload {
    height: auto;
    width: 100%;
    max-width: 100%;
    text-align: center;
    position: relative;
  }

  #input-file-upload {
    display: none;
  }

  #label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    background-color: rgba(231, 236, 255, 1);
    > div {
      padding: 0 12px;
    }
  }

  #label-file-upload.drag-active {
    background-color: #ffffff;
    border: 1px solid rgba(231, 236, 255, 1);
  }

  .upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 15px;
    border: none;
    font-family: 'arimo';
    background-color: transparent;
    color: #4764ff;
    text-decoration: underline;
    margin: 0 5px;
    :where([aria-disabled='true' i], [disabled]) {
      cursor: not-allowed !important;
    }
  }

  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
`;
const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 0 20px;
`;
const SubWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid #707070;
  width: 100%;
  margin: 0 20px;
  position: relative;
  padding: 30px 0 0 0;
  p {
    font-family: 'Inter';
    font-size: 14px;
    .upload-button {
      font-family: 'Inter';
      font-size: 14px;
    }
  }
`;
const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px 0 0 0;
  padding: 0 10px 30px 10px;
  width: 100%;
  .button-wrapper {
    margin: 0px 5px;
  }
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0 0 0;
  gap: 20px;
  .k-button {
    padding: 5px 30px !important;
  }
`;
const SelectedExtensionNameWrapper = styled.div`
  align-items: center;
  width: 100%;
  & > div {
    max-width: calc(100% - 50px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    & > div {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
`;
const SelectedFileBlock = styled.div`
  &.SelectedFileBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    padding: 7px 20px 7px 7px;
    background: #bfc9ff;
    border-radius: 10px;
    span.fileCancel {
      cursor: pointer;
      filter: contrast(0.5);
    }
    .SelectedExtensionNameWrapper {
      display: flex !important;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    span.extension-block {
      padding: 10px 10px;
      background: rgba(231, 236, 255, 1);
      color: #000000;
      text-transform: uppercase;
      border-radius: 10px;
      font-family: 'Inter';
      font-size: 13px;
      font-weight: bold;
      display: inline-flex;
      height: 44px;
      width: 44px;
      align-items: center;
      justify-content: center;
    }
    .filename-block {
      padding: 0 0 0 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #000000;
      font-family: 'Inter';
      font-size: 14px;
      width: 100%;
      display: inline-block;
    }
  }
`;
