/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { User } from '../models/User';
import type { UserInfo } from '../models/UserInfo';
import type { UserListWrapper } from '../models/UserListWrapper';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ManageUserService {

    /**
     * API to delete users
     * @param id
     * @returns string Success
     * @throws ApiError
     */
    public static manageUserDeleteUsers(
        id: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/admin/users/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param searchInput
     * @param sortBy
     * @param sortOrder
     * @param pageNumber
     * @param pageSize
     * @returns UserListWrapper Success
     * @throws ApiError
     */
    public static manageUserGetUsers(
        searchInput?: string,
        sortBy?: string,
        sortOrder?: number,
        pageNumber: number = 1,
        pageSize: number = 10,
    ): CancelablePromise<UserListWrapper> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/users',
            query: {
                'searchInput': searchInput,
                'sortBy': sortBy,
                'sortOrder': sortOrder,
                'pageNumber': pageNumber,
                'pageSize': pageSize,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * API to create user.
     * @param requestBody
     * @returns string Created
     * @throws ApiError
     */
    public static manageUserCreateOrEditUser(
        requestBody?: User,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to edit user.
     * @param requestBody
     * @returns string Success
     * @throws ApiError
     */
    public static manageUserEditUser(
        requestBody?: User,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get user info
     * @param id
     * @returns UserInfo Success
     * @throws ApiError
     */
    public static manageUserGetUserInfo(
        id: string,
    ): CancelablePromise<UserInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/admin/users/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @param formData
     * @returns any Created
     * @throws ApiError
     */
    public static manageUserUploadUserImage(
        id: string,
        formData?: {
            file?: Blob;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/user/{id}/photo',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

}
