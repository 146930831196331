import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router';

export type ProtectedRouteProps = {
  isAuthenticated: boolean;
  authenticationPath: string;
  redirectPath?: string;
  setRedirectPath?: (path: string) => void;
  outlet: JSX.Element;
};

export default function ProtectedRoute({
  isAuthenticated,
  outlet,
  redirectPath,
  setRedirectPath,
  authenticationPath,
}: ProtectedRouteProps) {
  const currentLocation = useLocation();

  if (
    isAuthenticated &&
    currentLocation.pathname.includes(redirectPath!.toString())
  ) {
    return outlet;
  } else {
    return <Navigate to={{ pathname: authenticationPath }} />;
  }
}
