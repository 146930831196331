import { useEffect, useState } from 'react';
import AdminTab from 'app/components/AdminTab';
import styled from 'styled-components';
import { ProfileImageUploadCard } from 'app/components/ProfileImageUploadCard';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import { LabelMedium } from 'app/components/SHLabel';
import { RiderInfo } from 'services/openapi';
import { AdminTextField } from 'app/components/AdminTextField';
import { useLocation, useParams } from 'react-router-dom';
import { AdminEditIcon } from 'admin/assets/icon';
import { SHToolTip } from 'app/components/SHToolTip';
import { CheckBox } from 'app/components/CheckBox';
import useRiderApi from 'admin/hooks/useRiderApi';
import { formatDate, useNavigateToRoute } from 'utils/common';
import { CardSkeletonLoader } from 'app/components/CardSkeletonLoader';

export const tabRiderNamesArray = [
  { tab: 'Rider Information', id: 1 },
  { tab: 'School Details', id: 2 },
  { tab: 'Parent Details', id: 3 },
];
export const RiderViewProfile = () => {
  const {
    fetchRiderInfo,
    riderInfo,
    riderDataLoading: loading,
  } = useRiderApi();
  const { id: riderId } = useParams();
  const redirect = useNavigateToRoute();
  const [tabSelected, setTabSelected] = useState<number>(0);
  const [riderData, setRiderdata] = useState<RiderInfo>({});
  const currentLocation = useLocation();
  const isPastDataFlag = currentLocation.pathname.includes('past-data');

  const handleEditClick = () => {
    redirect(`/admin/riders/${'edit'}/${riderId}`);
  };

  useEffect(() => {
    setRiderdata(riderInfo!);
  }, [riderInfo]);

  useEffect(() => {
    if (riderId) {
      fetchRiderInfo(riderId);
    }
  }, [riderId]);

  return (
    <>
      <Wrapper>
        {loading ? (
          <CardSkeletonLoader
            isProfileImageLoader
            isTextLoader
            lineContent={3}
            isHeaderLoader
            headerWidth={300}
          />
        ) : (
          <ProfileImageUploadCard
            buttonLabel="Edit"
            buttonType="secondary"
            svgIcon={<AdminEditIcon />}
            onButtonClick={handleEditClick}
            className="ProfileImageUploadCard"
            imgSrc={riderData?.imageUrl}
            isPastDataFlag={isPastDataFlag}
          >
            <GridLayout
              gap={{ rows: 0, cols: 10 }}
              rows={[{ height: 'auto' }, { height: 'auto' }]}
              cols={[
                { width: '25%' },
                { width: '25%' },
                { width: '25%' },
                { width: '25%' },
              ]}
              className="profilecard-grid"
            >
              <GridLayoutItem
                row={1}
                col={1}
                colSpan={4}
                className="first-item"
              >
                <h3 className="header-title back-number">
                  {riderData?.firstName + ' ' + riderData?.lastName}
                  <span className="small-title">
                    {'('}
                    Back number :
                    <div className="title-span" title={riderData?.backNumber!}>
                      {riderData?.backNumber}
                    </div>
                    {')'}
                  </span>
                </h3>
              </GridLayoutItem>
            </GridLayout>
            <GridLayout
              gap={{ rows: 0, cols: 0 }}
              rows={[{ height: 'auto' }, { height: 'auto' }]}
              cols={[{ width: '50%' }, { width: '50%' }]}
              className="profilecard-grid"
            >
              <GridLayoutItem row={1} col={1} className="first-item">
                <GridLayoutItem row={1} col={1}>
                  <SHToolTip position="top">
                    <CustomLabelMedium color="#475467">
                      <TitleSpan>Divisions/Classes :</TitleSpan>
                      <ValueSpan
                        title={
                          riderData?.divisionClassOne?.name! +
                          ', ' +
                          riderData?.divisionClassTwo?.name!
                        }
                      >
                        {riderData?.divisionClassOne?.name ?? ''}
                        {', '}
                        {riderData?.divisionClassTwo?.name ?? ''}
                      </ValueSpan>
                    </CustomLabelMedium>
                  </SHToolTip>
                </GridLayoutItem>
                <GridLayoutItem row={2} col={1}>
                  <SHToolTip position="top">
                    <CustomLabelMedium color="#475467">
                      <TitleSpan>Team :</TitleSpan>
                      <ValueSpan title={riderData?.team?.name}>
                        {riderData?.team?.name}
                      </ValueSpan>
                    </CustomLabelMedium>
                  </SHToolTip>
                </GridLayoutItem>
                <GridLayoutItem row={3} col={1} className="height-style">
                  <CustomLabelMedium color="#475467">
                    <TitleSpan>Height :</TitleSpan>
                    <ValueSpan>
                      {riderData?.height ? riderData?.height : ''}{' '}
                    </ValueSpan>
                  </CustomLabelMedium>
                  <CustomLabelMedium color="#475467">
                    <TitleSpan>Weight :</TitleSpan>
                    <ValueSpan>
                      {' '}
                      {riderData?.weight ? riderData?.weight : ''}
                    </ValueSpan>
                  </CustomLabelMedium>
                </GridLayoutItem>
              </GridLayoutItem>
              <GridLayoutItem row={1} col={2} className="first-item">
                <GridLayoutItem row={1} col={1} className="right-side">
                  <SHToolTip position="top">
                    <CustomLabelMedium color="#475467">
                      <TitleSpan>Address :</TitleSpan>
                      <ValueSpan title={riderData?.street!}>
                        {riderData?.street}
                      </ValueSpan>
                    </CustomLabelMedium>
                  </SHToolTip>
                </GridLayoutItem>
                <GridLayoutItem row={2} col={1} className="right-side">
                  <CustomLabelMedium color="#475467">
                    <TitleSpan>Phone :</TitleSpan>
                    <ValueSpan>{riderData?.primaryPhone}</ValueSpan>
                  </CustomLabelMedium>
                </GridLayoutItem>
                <GridLayoutItem
                  row={3}
                  col={1}
                  className="email-style right-side"
                >
                  <SHToolTip position="top">
                    <CustomLabelMedium color="#475467">
                      <TitleSpan>Email :</TitleSpan>
                      <ValueSpan title={riderData?.email!}>
                        {riderData?.email}
                      </ValueSpan>
                    </CustomLabelMedium>
                  </SHToolTip>
                </GridLayoutItem>
              </GridLayoutItem>
            </GridLayout>
          </ProfileImageUploadCard>
        )}

        <TabWrapper>
          <AdminTab
            data={tabRiderNamesArray}
            tabSelected={tabSelected}
            setTabSelected={setTabSelected}
          />
        </TabWrapper>
        {tabSelected === 0 ? (
          <GridLayout
            gap={{ rows: 6, cols: 10 }}
            rows={[{ height: 'auto' }, { height: 'auto' }]}
            cols={[
              { width: 'calc(33.33% - 7px)' },
              { width: 'calc(33.33% - 7px)' },
              { width: 'calc(33.33% - 7px)' },
            ]}
          >
            <GridLayoutItem
              row={1}
              col={1}
              colSpan={1}
              className="checkbox-grid-layout"
            >
              <div className="checkbox">
                {loading ? (
                  <CardSkeletonLoader
                    isTextLoader
                    textWidth={50}
                    lineContent={1}
                  />
                ) : (
                  <CheckBox label="Try-it" value={riderData?.isTrial} />
                )}
              </div>
            </GridLayoutItem>
            <GridLayoutItem row={2} col={1} colSpan={1} className="first-item">
              <AdminTextField
                label="Rider Phone"
                value={riderData?.primaryPhone ?? ''}
                className="field-style"
                disabled
                isLoader={loading}
              />
            </GridLayoutItem>
            <GridLayoutItem row={2} col={2} colSpan={1} className="first-item">
              <AdminTextField
                label="Rider Email"
                value={riderData?.email ?? ''}
                className="field-style"
                disabled
                isLoader={loading}
              />
            </GridLayoutItem>
            <GridLayoutItem row={2} col={3} colSpan={1} className="first-item">
              <AdminTextField
                label="SIF Join Date"
                value={formatDate(riderData?.sifJoinDate)}
                className="field-style"
                disabled
                isLoader={loading}
              />
            </GridLayoutItem>
            <GridLayoutItem row={3} col={1} colSpan={1} className="first-item">
              <AdminTextField
                label="SIF Amt. Paid / Year"
                value={riderData?.sifAmountPaidPerYear ?? ''}
                className="field-style"
                disabled
                isLoader={loading}
              />
            </GridLayoutItem>
            <GridLayoutItem row={3} col={2} colSpan={1} className="first-item">
              <AdminTextField
                label="SIF Points"
                value={riderData?.sifPoint ?? ''}
                className="field-style"
                disabled
                isLoader={loading}
              />
            </GridLayoutItem>
            <GridLayoutItem row={3} col={3} colSpan={1} className="first-item">
              <AdminTextField
                label="Rider Address"
                value={riderData?.street ?? ''}
                className="field-style"
                disabled
                isLoader={loading}
              />
            </GridLayoutItem>
            <GridLayoutItem row={4} col={1} colSpan={1} className="first-item">
              <AdminTextField
                label="City"
                value={riderData?.city ?? ''}
                className="field-style"
                disabled
                isLoader={loading}
              />
            </GridLayoutItem>
            <GridLayoutItem row={4} col={2} colSpan={1} className="first-item">
              <AdminTextField
                label="State"
                value={riderData?.state?.name}
                className="field-style"
                disabled
                isLoader={loading}
              />
            </GridLayoutItem>
            <GridLayoutItem row={4} col={3} colSpan={1} className="first-item">
              <AdminTextField
                label="Zip "
                value={riderData?.zip ?? ''}
                className="field-style"
                disabled
                isLoader={loading}
              />
            </GridLayoutItem>
            <GridLayoutItem row={5} col={1} colSpan={1} className="first-item">
              <AdminTextField
                label="Rider Status "
                value={riderData?.riderStatus?.name ?? ''}
                className="field-style"
                disabled
                isLoader={loading}
              />
            </GridLayoutItem>
          </GridLayout>
        ) : tabSelected === 1 ? (
          <GridLayout
            gap={{ rows: 6, cols: 10 }}
            rows={[{ height: 'auto' }, { height: 'auto' }]}
            cols={[
              { width: 'calc(33.33% - 7px)' },
              { width: 'calc(33.33% - 7px)' },
              { width: 'calc(33.33% - 7px)' },
            ]}
          >
            <GridLayoutItem row={1} col={1} colSpan={1} className="first-item">
              <AdminTextField
                label="School Name"
                value={riderData?.school?.schoolName ?? ''}
                className="field-style"
                disabled
                isLoader={loading}
              />
            </GridLayoutItem>
            <GridLayoutItem row={1} col={2} colSpan={1} className="first-item">
              <AdminTextField
                label=" Grade"
                placeholder=""
                value={riderData?.grade?.name ?? ''}
                className="field-style"
                disabled
                isLoader={loading}
              />
            </GridLayoutItem>
            <GridLayoutItem row={1} col={3} colSpan={1} className="first-item">
              <AdminTextField
                label="Graduation Year"
                value={riderData?.expectedGraduationYear ?? ''}
                className="field-style"
                disabled
                isLoader={loading}
              />
            </GridLayoutItem>
            <GridLayoutItem row={2} col={1} colSpan={1} className="first-item">
              <AdminTextField
                label="School Address"
                value={riderData?.school?.street ?? ''}
                className="field-style"
                disabled
                isLoader={loading}
              />
            </GridLayoutItem>
            <GridLayoutItem row={2} col={2} colSpan={1} className="first-item">
              <AdminTextField
                label="School City"
                value={riderData?.school?.city ?? ''}
                className="field-style"
                disabled
                isLoader={loading}
              />
            </GridLayoutItem>
            <GridLayoutItem row={2} col={3} colSpan={1} className="first-item">
              <AdminTextField
                label="School State"
                value={riderData?.school?.state?.name ?? ''}
                className="field-style"
                disabled
                isLoader={loading}
              />
            </GridLayoutItem>
            <GridLayoutItem row={3} col={1} colSpan={1} className="first-item">
              <AdminTextField
                label="School Zip"
                value={riderData?.school?.zip ?? ''}
                className="field-style"
                disabled
                isLoader={loading}
              />
            </GridLayoutItem>
          </GridLayout>
        ) : (
          <GridLayout
            gap={{ rows: 6, cols: 10 }}
            rows={[{ height: 'auto' }, { height: 'auto' }]}
            cols={[
              { width: 'calc(33.33% - 7px)' },
              { width: 'calc(33.33% - 7px)' },
              { width: 'calc(33.33% - 7px)' },
            ]}
          >
            <GridLayoutItem row={1} col={1} colSpan={1} className="first-item">
              <AdminTextField
                label="Parents First Name"
                placeholder=""
                value={riderData?.parent?.firstName ?? ''}
                name="parent.firstName"
                className="field-style"
                disabled
                isLoader={loading}
              />
            </GridLayoutItem>
            <GridLayoutItem row={1} col={2} colSpan={1} className="first-item">
              <AdminTextField
                label="Parents Last Name"
                placeholder=""
                value={riderData?.parent?.lastName ?? ''}
                name="parent.lastName"
                className="field-style"
                disabled
                isLoader={loading}
              />
            </GridLayoutItem>

            <GridLayoutItem row={1} col={3} colSpan={1} className="first-item">
              <AdminTextField
                label="Parents Phone"
                placeholder=""
                value={riderData?.parent?.phone ?? ''}
                name="parent.phone"
                className="field-style"
                disabled
                isLoader={loading}
              />
            </GridLayoutItem>
            <GridLayoutItem row={2} col={1} colSpan={1} className="first-item">
              <AdminTextField
                label="Parents Email"
                placeholder=""
                value={riderData?.parent?.email ?? ''}
                name="parent.email"
                className="field-style"
                disabled
                isLoader={loading}
              />
            </GridLayoutItem>
            <GridLayoutItem row={2} col={2} colSpan={1} className="first-item">
              <AdminTextField
                label="Parents Address"
                placeholder=""
                value={riderData?.parent?.street ?? ''}
                name="parent.street"
                className="field-style"
                disabled
                isLoader={loading}
              />
            </GridLayoutItem>
            <GridLayoutItem row={2} col={3} colSpan={1} className="first-item">
              <AdminTextField
                label="City"
                placeholder=""
                value={riderData?.parent?.city ?? ''}
                name="parent.city"
                className="field-style"
                disabled
                isLoader={loading}
              />
            </GridLayoutItem>
            <GridLayoutItem row={3} col={1} colSpan={1} className="first-item">
              <AdminTextField
                label=" State"
                placeholder=""
                value={riderData?.parent?.state?.name ?? ''}
                className="field-style"
                disabled
                isLoader={loading}
              />
            </GridLayoutItem>
            <GridLayoutItem row={3} col={2} colSpan={1} className="first-item">
              <AdminTextField
                label="Zip"
                placeholder=""
                value={riderData?.parent?.zip ?? ''}
                name="parent.zip"
                className="field-style"
                disabled
                isLoader={loading}
              />
            </GridLayoutItem>
          </GridLayout>
        )}
      </Wrapper>
    </>
  );
};
const TitleSpan = styled.div`
  white-space: nowrap;
`;
const ValueSpan = styled.div`
  font-weight: 600;
  position: relative;
  white-space: pre-line;
  overflow: auto;
  padding: 0 0 0 5px;
`;
const Wrapper = styled.div`
  .checkbox-grid-layout {
    display: flex;
    align-items: end;
  }
  .checkbox {
    .k-checkbox {
      background-color: #fff !important;
    }
    input[type='checkbox']:checked {
      background-color: #4764ff !important;
    }
    label {
      font-family: 'Inter';
      color: #a3a3a3;
      font-size: 12px;
    }
  }
  .ProfileImageUploadCard {
    align-items: center;
    .header-title {
      color: #515e71;
      margin: 0px;
      .title-span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 52%;
      }
      span {
        color: #0028ff;
        font-size: 14px;
        margin-left: 10px;
        display: flex;
        max-width: 48%;
        align-items: center;
      }
      .small-title {
        max-width: unset;
        display: inline-flex;
      }
    }
    .profilecard-grid {
      span {
        font-weight: 600;
      }
      .right-side {
        padding-left: 20px;
        border-left: 1px solid #a6b1dc;
      }
      .email-style {
        span {
          color: #0028ff;
        }
      }
      .tooltip-style {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .ProfileImageUploadCard {
      flex-direction: column;
      padding: 20px !important;
      button {
        position: unset !important;
        transform: unset !important;
      }
      .k-grid-layout {
        width: 100%;
      }
      .child-wrapper {
        padding: 0px;
      }
    }
  }
  @media (min-width: 100px) and (max-width: 767px) {
    .ProfileImageUploadCard {
      flex-direction: column;
      padding: 20px !important;
      .right-side {
        padding-left: 0px !important;
        border-left: 0px solid !important;
      }
      button {
        position: unset !important;
        transform: unset !important;
      }
      .k-grid-layout {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      .child-wrapper {
        padding: 0px;
        .back-number {
          width: 50%;
        }
      }
    }
  }
`;
const TabWrapper = styled.div`
  margin-top: 20px;
`;
const CustomLabelMedium = styled(LabelMedium)`
  display: flex;
  padding: 1px 15px 1px 0;
  span {
    color: #475467;
  }
`;
