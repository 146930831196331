import { CommonPageWrapper, HeadingWrapper } from 'admin/components/wrappers';
import AdminCustomButton from 'app/components/AdminCustomButton';
import { AdminSearch } from 'app/components/AdminSearchField';
import CustomBreadCrumb from 'app/components/CustomBreadCrumb';
import { BlurredLabel, PageMainLabel } from 'app/components/SHLabel';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDebounce } from 'hooks/useDebounce';
import useCommonLookUpDatas from 'hooks/useCommonLookupData';
import { getLookupArray, useNavigateToRoute } from 'utils/common';
import CurrentSeasonTable from './CurrentSeasonTable';
import useShowApi from 'admin/hooks/useShowApi';
import { TableSkeletonLoader } from 'app/components/TableSkeletonLoader';
import { useLocation } from 'react-router-dom';
import AdminDropDown from 'app/components/AdminDropDown';
import { GeneralLookup } from 'services/openapi';
import Cookies from 'js-cookie';

const CurrentSeasonPage = () => {
  const currentLocation = useLocation();
  const isPastDataFlag = currentLocation.pathname.includes('past-data');
  const breadCrumbItems = [
    {
      id: '',
      text: 'Showyeda admin',
      disabled: true,
    },
    {
      id: '',
      text: isPastDataFlag ? 'Past-Data' : 'Shows',
      disabled: true,
    },
  ];

  if (isPastDataFlag) {
    breadCrumbItems.push({
      id: '',
      text: 'Shows',
      disabled: true,
    });
  }
  const {
    loading,
    fetchManageGetShows,
    showDataList,
    deleteShow,
    fetchShowActiveOrInactive,
  } = useShowApi();
  const { getAllLookupData, lookUps } = useCommonLookUpDatas();
  const redirect = useNavigateToRoute();
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [active, setActive] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pageSkip, setPageSkip] = useState<number | undefined>(0);
  const debounceShowSearch = useDebounce(searchTerm);
  const handleItemClick = (item: string) => {};
  const handleItemIdClick = (itemId: string) => {
    setSelectedItemId(itemId === selectedItemId ? null : itemId);
  };
  const [seasonPayload, setSeasonPayload] = useState<GeneralLookup>({
    id: '',
    name: '',
  });
  const [sortField, setSortField] = useState<string | undefined>(undefined);
  const [sortOrder, setSortOrder] = useState<number | undefined>(0);
  const [isOrder, setIsOrder] = useState(false);
  const prevPagSkipRef = useRef<number | undefined>(undefined);
  const handleSearch = e => {
    setSearchTerm(e.target.value);
  };

  const handleDropdownChange = (data: GeneralLookup) => {
    setSeasonPayload(data);
    fetchManageGetShows(
      data?.id,
      [],
      selectedItemId ?? '',
      debounceShowSearch,
      sortField,
      sortOrder,
      0,
      10,
    );
  };
  useEffect(() => {
    if (debounceShowSearch.length > 0) {
      fetchManageGetShows(
        '',
        [],
        selectedItemId ?? '',
        debounceShowSearch,
        sortField,
        sortOrder,
        0,
        10,
      );
    } else if (
      (debounceShowSearch.length === 0 && selectedItemId) ||
      selectedItemId === null
    ) {
      fetchManageGetShows(
        '',
        [],
        selectedItemId ?? '',
        debounceShowSearch,
        sortField,
        sortOrder,
        0,
        10,
      );
    } else {
      fetchManageGetShows(
        '',
        [],
        selectedItemId ?? '',
        debounceShowSearch,
        sortField,
        sortOrder,
        0,
        10,
      );
    }
    if (pageSkip !== 0) {
      if (debounceShowSearch?.length! >= 0 || selectedItemId) {
        setPageSkip(0);
      }
    }
  }, [debounceShowSearch, selectedItemId]);
  useEffect(() => {
    getAllLookupData(`ACTIVESTATUS,DIVISION,TEAM,SEASON`);
    Cookies.remove('showId');
  }, []);
  useEffect(() => {
    if (
      pageSkip &&
      pageSkip !== 0 &&
      showDataList?.shows?.length === 0 &&
      prevPagSkipRef.current !== pageSkip
    ) {
      const newSkip = pageSkip - 10;
      prevPagSkipRef.current = newSkip;

      setPageSkip(newSkip);
      fetchManageGetShows(
        '',
        [],
        selectedItemId ?? '',
        debounceShowSearch,
        '',
        0,
        newSkip,
        10,
      );
    }
  }, [showDataList]);
  return (
    <>
      <CommonPageWrapper>
        <HeadingWrapper>
          <PageMainLabel>Shows</PageMainLabel>
          <CustomBreadCrumb preData={breadCrumbItems} />
        </HeadingWrapper>
        <HeadingWrapper>
          <ButtonWrapper>
            {!isPastDataFlag && (
              <AdminCustomButton
                buttonType="primary"
                isActive={false}
                className="fill-button"
                onClick={() => {
                  setActive(!active);
                  redirect(`/admin/shows/create`);
                }}
              >
                Add New Show
              </AdminCustomButton>
            )}

            <BlurredLabel style={{ whiteSpace: 'nowrap' }}>
              Popular filter
            </BlurredLabel>
            {getLookupArray('ACTIVESTATUS', lookUps).map(item => (
              <AdminCustomButton
                key={item.id}
                buttonType="primary"
                isActive={false}
                className={
                  selectedItemId?.includes(item?.id)
                    ? 'active-inactive'
                    : 'Inactive-button'
                }
                onClick={() => {
                  handleItemIdClick(item?.id);
                }}
              >
                {item?.name}
              </AdminCustomButton>
            ))}
            {isPastDataFlag && (
              <CustomDropDown
                label=""
                placeholder="Select season"
                onDataChange={data => handleDropdownChange(data)}
                defaultValue={seasonPayload}
                data={getLookupArray('SEASON', lookUps)}
                className="field-style"
              />
            )}
          </ButtonWrapper>
          <div style={{ width: '283px', paddingTop: '30px' }}>
            <AdminSearch
              placeholder="Search by shows name"
              onSearchTextEntering={handleSearch}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </div>
        </HeadingWrapper>
        {/* Riders Table */}
        <CurrentSeasonTable
          setPageSkip={setPageSkip}
          pageSkip={pageSkip}
          debounceShowSearch={debounceShowSearch}
          fetchManageGetShows={fetchManageGetShows}
          showData={showDataList}
          selectedItemId={selectedItemId}
          deleteShow={deleteShow}
          fetchShowActiveOrInactive={fetchShowActiveOrInactive}
          lookup={getLookupArray('ACTIVESTATUS', lookUps)}
          sortField={sortField}
          sortOrder={sortOrder}
          setSortField={setSortField}
          setSortOrder={setSortOrder}
          setIsOrder={setIsOrder}
          isOrder={isOrder}
          loading={loading}
        />
      </CommonPageWrapper>
    </>
  );
};

export default CurrentSeasonPage;
const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding: 30px 0 0;
  align-items: center;
  .field-style {
    display: flex;
  }
`;
const CustomDropDown = styled(AdminDropDown)`
  width: 160px;
  .k-dropdownlist {
    max-height: 31px;
    border-radius: 8px !important;
  }
  .k-input-inner {
    padding: 0 5px !important;
  }
  .k-input-value-text {
    margin: 2px 4px 2px 4px;
    font-size: 12px !important;
    letter-spacing: 0px;
    color: #1e1e1e;
    text-align: left;
  }
`;
