import React from 'react';
import { Input } from '@progress/kendo-react-inputs';
import styled from 'styled-components';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardSubtitle,
} from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import userImg from './assets/images/user.png';
import passwordImg from './assets/images/password.png';
import { Login } from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';
import hidePassword from './assets/images/hidePassword.svg';
import showIconPassword from './assets/images/showPassword.svg';

type Props = {
  onClickLogin?: () => void;
  setLoginData: React.Dispatch<React.SetStateAction<Login>>;
  loginData: Login;
  setShowFirstFooter?: React.Dispatch<React.SetStateAction<boolean>>;
  onForgotPassword: (e: any) => void;
  heading: string;
};

export const LoginCard = ({
  onClickLogin,
  loginData,
  setLoginData,
  setShowFirstFooter,
  onForgotPassword,
  heading,
}: Props) => {
  const [valid, setValid] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  //Simple validator Ref
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const handlePassword = e => {
    setLoginData({ ...loginData, password: e.target.value });
  };
  const handleUsername = e => {
    setLoginData({ ...loginData, userName: e.target.value });
  };
  //Handle Username  Blur
  const handleBlurUsername = () => {
    simpleValidator?.current?.showMessageFor('Username');
  };
  //Handle password Blur
  const handleBlurPassword = () => {
    simpleValidator?.current?.showMessageFor('Password');
  };
  //Handle password Blur
  const [submitClick, setSubmitClick] = React.useState<boolean>(false);
  const handleSubmit = e => {
    setShowFirstFooter?.(false);
    setSubmitClick(true);
    e.preventDefault();
    e.stopPropagation();
    if (simpleValidator?.current?.allValid()) {
      onClickLogin?.();
    } else {
      simpleValidator?.current?.showMessageFor('Username');
      simpleValidator?.current?.showMessageFor('Password');
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    } else return;
  };
  const handleForgotpassword = e => {
    setShowFirstFooter?.(false);
    onForgotPassword?.(e);
  };

  //Password Toggle
  const handleTogglePassword = () => {
    setShowPassword(prevState => !prevState);
  };

  //UseEffect for validation
  React.useEffect(() => {
    if (submitClick) {
      simpleValidator.current.visibleFields = ['Username', 'Password'];
    }
  }, [submitClick]);
  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(formValid);
  }, [loginData]);

  return (
    <MainWrapper>
      <StyledCard
        style={{
          width: 260,
          boxShadow: '0 0 4px 0 rgba(0, 0, 0, .1)',
        }}
      >
        <CardHeader className="k-hbox" style={{ background: 'transparent' }}>
          <div>
            <CardTitle className="k-title">{heading}</CardTitle>
            <CardSubtitle className="k-subtitle">
              Use your <span>Showyeda</span> Account
            </CardSubtitle>
          </div>
        </CardHeader>

        <CardBody>
          <LoginWrapper>
            <div className="col-3 col-md-3 example-col">
              <SubWrapper>
                <FieldWrapper className="FieldWrapper">
                  <InputWrapper className="InputWrapper">
                    <ImgWrapper className="input-Img">
                      <img src={userImg} alt="user-icon" />
                    </ImgWrapper>
                    <Wrapper className="input-class">
                      <Input
                        placeholder="Username"
                        value={loginData.userName!}
                        onChange={handleUsername}
                        onBlur={handleBlurUsername}
                        onClick={() => setShowFirstFooter?.(false)}
                      />
                    </Wrapper>
                    <ImgWrapper className="eye"></ImgWrapper>
                  </InputWrapper>
                  {simpleValidator?.current?.message(
                    'Username',
                    loginData?.userName,
                    'required',
                  ) && (
                    <ErrorText className="ErrorText">
                      {simpleValidator?.current?.message(
                        'Username',
                        loginData?.userName,
                        'required',
                      )}
                    </ErrorText>
                  )}
                </FieldWrapper>
                <FieldWrapper className="FieldWrapper">
                  <InputWrapper className="InputWrapper">
                    <ImgWrapper>
                      <img src={passwordImg} alt="password-icon" />
                    </ImgWrapper>
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      placeholder="************"
                      value={loginData.password!}
                      onChange={handlePassword}
                      onBlur={handleBlurPassword}
                      onKeyDown={handleKeyPress}
                      onClick={() => setShowFirstFooter?.(false)}
                    />
                    <ImgWrapper className="eye" onClick={handleTogglePassword}>
                      {showPassword ? (
                        <img src={hidePassword} alt="hide" />
                      ) : (
                        <img src={showIconPassword} alt="show" />
                      )}
                    </ImgWrapper>
                  </InputWrapper>
                  {simpleValidator?.current?.message(
                    'Password',
                    loginData?.password,
                    'required',
                  ) && (
                    <ErrorText className="ErrorText">
                      {simpleValidator?.current?.message(
                        'Password',
                        loginData?.password,
                        'required',
                      )}
                    </ErrorText>
                  )}
                </FieldWrapper>
              </SubWrapper>
              <StyledButton onClick={e => handleSubmit(e)}>Login</StyledButton>
            </div>
            <BottomWrapper>
              <div onClick={handleForgotpassword} className="forgot-password">
                <p>Forgot password ?</p>
              </div>
            </BottomWrapper>
          </LoginWrapper>
        </CardBody>
      </StyledCard>
    </MainWrapper>
  );
};
const FieldWrapper = styled.div`
  border-radius: 0px;
  background: rgb(184 184 184);
  border: none;
  height: 100%;
  width: 100%;
  padding: 0px;
  color: #000000;
  position: relative;
  margin: 0 0 -1px 0;
  &:nth-child(1) {
    &:after {
      content: '';
      position: absolute;
      bottom: 1px;
      left: 6%;
      right: 6%;
      border-bottom: 1px solid black !important;
    }
  }
`;

const StyledCard = styled(Card)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100% !important;
  background: rgb(255 255 255 / 10%);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  border-color: transparent;

  .k-card-title {
    color: white;
  }
  .k-card-subtitle {
    color: #9d9d9d;
  }
  .k-title {
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 5%;
    font-size: 20px;
    font-weight: 500;
  }
  .k-subtitle {
    font-size: 11px;
    text-align: center;
    padding-bottom: 5%;
    span {
      text-transform: uppercase;
      font-size: 12px;
    }
  }
  .k-card-header {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 10%;
  }
  .k-card-body {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
const ErrorText = styled.span`
  width: 100%;
  color: #c42727;
  display: block;
  padding: 0 0 15px 70px;
  line-height: 1.4;
  font-size: 12px;
  position: relative;
  margin: -10px 0 0px 0;
`;
const MainWrapper = styled.div`
  padding-left: 20%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;
const LoginWrapper = styled.div`
  width: 100%;
  height: auto;
`;
const SubWrapper = styled.div`
  padding-bottom: 3%;
  width: 100%;
  height: auto;
`;
const ImgWrapper = styled.div`
  background: rgb(184 184 184);
  display: flex;
  align-items: center;
  justify-content: center;
  &.eye {
    background: transparent;
    img {
      width: 20px;
    }
  }
  img {
    width: 11px;
    height: auto;
  }
  width: 20%;
  height: 45px;
  @media (min-width: 576px) and (max-width: 767px) {
    height: 35px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    height: 35px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 40px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 45px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 50px;
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    height: 60px;
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    height: 70px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 45px;

  input {
    border-radius: 0px;
    background: rgb(184 184 184);
    border-color: transparent;
    height: 100%;
    width: 100%;
    padding: 0px;
    color: #000000;
    ::placeholder {
      color: #616161;
      font-weight: 400;
      font-size: 14px;
    }
    :focus-visible {
      outline: unset !important;
    }
  }
  .k-input-solid:focus-within {
    border-color: transparent;
    color: #000000;
    background-color: rgb(184 184 184);
    box-shadow: unset;
  }
  .k-input:-webkit-autofill {
    animation-name: autoFillStart;
    background: red !important;
    box-shadow: inset 0 0 0 40px #b8b8b8 !important;
    border: none !important;
  }
  .input-class {
    position: relative;
    padding: 0px;
    border: 0px;
    width: 100%;
    height: 100%;
    /* &:after {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 93%;
      border-bottom: 1px solid black !important;
    } */
  }
  .input-Img {
    position: relative;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    height: 35px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    height: 35px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 40px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 45px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 50px;
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    height: 60px;
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    height: 70px;
  }
`;
const Wrapper = styled.div``;

const StyledButton = styled(Button)`
  background: #383c41 0% 0% no-repeat padding-box !important;
  height: 45px;
  width: 100%;
  border-color: transparent;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 100;
  .k-button-solid-base:focus,
  .k-button-solid-base.k-focus {
    box-shadow: unset !important;
  }
  &:focus {
    box-shadow: none;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    height: 35px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    height: 35px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 40px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 45px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 50px;
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    height: 60px;
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    height: 70px;
  }
`;
const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 4%;
  padding-bottom: 4%;
  label {
    font: normal normal 11px/12px Helvetica;
    color: #9d9d9d;
    padding-right: 2px;
  }
  p {
    text-transform: capitalize;
    font: oblique normal normal 11px/12px Helvetica;
    color: #9d9d9d;
    padding-right: 2px;
    margin: 0px;
  }
  .green-checkbox .k-checkbox-label::before {
    background-color: green !important;
  }
  .forgot-password {
    cursor: pointer;
    width: fit-content;
  }
`;
