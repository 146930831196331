import { GridCellProps } from '@progress/kendo-react-grid';
import styled from 'styled-components';

type Props = {
  classname?: string;
  e?: GridCellProps;
  value?: any;
};

const TeamNameLabelCell = ({ classname, e, value }: Props) => {
  return (
    <CustomLabelWrapper className={classname ?? ''}>
      <div className="label-wrapper">{value}</div>
    </CustomLabelWrapper>
  );
};

export default TeamNameLabelCell;
const CustomLabelWrapper = styled.td`
  .label-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    text-align: left;
    letter-spacing: 0.3px;
    @media (min-width: 100px) and (max-width: 1400px) {
      font-size: 10px;
    }
  }
`;
