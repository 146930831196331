import { GridCellProps } from '@progress/kendo-react-grid';
import styled from 'styled-components';
type Props = {
  className?: string;
  e: GridCellProps;
};

const ShowRiderBMICell = ({ className, e }: Props) => {
  const data = e?.dataItem;
  return (
    <td className={className ?? ''}>
      <Wrapper><span className='content'>Height:{data.height} ft</span></Wrapper>
      <Wrapper><span className='content'>Weight:{data.weight} lbs </span></Wrapper>
    </td>
  );
};

export default ShowRiderBMICell;
const Wrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 90px;
  display: block;
`;


