import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import '@fontsource/roboto';
import { FormSecondaryLabel, InfoLabel } from 'app/components/SHLabel';
import { BackNextButton } from 'app/components/BackNextButton';
import RadioButtonGroup from 'app/components/RadioButtonGroup';
import CustomDragAndDropFiles from 'app/components/CustomDragAndDropFiles';
import InsurancePopUp from '../InsurancePopUp';
import useApi from 'services/mavapi/useApi';
import { CoachService, GeneralLookup, LookupService } from 'services/openapi';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectPageLoader } from 'app/pages/slice/selectors';
import { useManagePageSlice } from 'app/pages/slice';
import useToast from 'hooks/Toast';
import LoaderWrapper from 'app/components/CircularLoader/LoaderWrapper';
import CircularLoader from 'app/components/CircularLoader';
import { selectInsuranceUpload } from 'app/pages/CoachRegistrationPage/slice/selectors';
import { useManageCoachSlice } from 'app/pages/CoachRegistrationPage/slice';
import { useErrorHandlingSlice } from 'app/error/slice';
// import { DefaultValue } from '../..';

type Props = {
  formNumber?: number;
  setFormNumber?: React.Dispatch<React.SetStateAction<number>>;
  handleNextForm?: (event) => void;
  handleBackForm?: (event) => void;
  selectedCoachId?: string;
  backDisable: boolean;
};

const defaultValue = {
  formData: {
    certificateOfInsurance: undefined,
  },
};

export const InsuranceUploadForm = ({
  formNumber,
  setFormNumber,
  // handleNextForm,
  handleBackForm,
  selectedCoachId,
  backDisable,
}: Props) => {
  const currentUserId = Cookies.get('coachId');
  const navigate = useNavigate();
  const { error: apiError, isLoading, handleRequest } = useApi();
  const { actions } = useErrorHandlingSlice();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const { coachUploadCertificateOfInsurance } = CoachService;
  const [coachInsuranceUploadPayload, setCoachInsuranceUploadPayload] =
    useState<{
      formData?: {
        certificateOfInsurance?: Blob;
      };
    }>(defaultValue);

  const [visible, setVisible] = React.useState<boolean>(false);
  const [selectFile, setSelectFile] = React.useState<boolean>(false);
  const [newInsurance, setNewInsurance] = React.useState<boolean>(false);
  const [insuranceRadioVal, setInsuranceRadioVal] = React.useState<
    string | null | undefined
  >('');
  const { actions: PageActions } = useManagePageSlice();
  const pageLoader = useSelector(selectPageLoader);
  const dispatch = useDispatch();
  const [cancelPaymentCookie, setCancelPaymentCookie] = useState();
  const insuranceUploadDataRedux = useSelector(selectInsuranceUpload);

  const isHandleChange = e => {
    console.log(e, '........e.......');
    setInsuranceRadioVal(e.value);
    if (e.value === false) {
      setVisible(true);
      setSelectFile(false);
    } else {
      setSelectFile(true);
    }
  };
  const handleFileOnCancelClick = e => {
    setInsuranceRadioVal('');
    setUploadInsuranceFile(undefined);
    setSelectFile(false);
  };
  const handleRemoveFile = e => {
    setUploadInsuranceFile(undefined);
  };
  const handleNewInsuranceChange = e => {
    console.log(e, 'handleNewInsuranceChange');
    if (e.value === 'Yes') {
      setNewInsurance(true);
      setVisible(false);
    } else {
      setNewInsurance(false);
      setNewInsurance(false);
    }
  };
  const handleOnCancelNewInsuranceClick = e => {
    setInsuranceRadioVal('');
    setNewInsurance(false);
    setVisible(false);
  };

  const [uploadInsuranceFile, setUploadInsuranceFile] = useState<Blob>();
  const handleFileUpload = e => {
    var file = e.target.files[0];
    setUploadInsuranceFile(file);
    setCoachInsuranceUploadPayload({
      ...coachInsuranceUploadPayload,
      formData: { certificateOfInsurance: file },
    });
  };
  const { actions: manageInsuranceActions } = useManageCoachSlice();
  const { lookupGetAllLookups } = LookupService;
  const [payerTypes, setPayerTypes] = useState<GeneralLookup>();
  const handleGetLookups = () => {
    handleRequest(lookupGetAllLookups('PAYER_TYPES'))
      .then(res => {
        console.log('res');

        let varPayerTypes = res?.filter(item => {
          if (item?.type === 'PAYER_TYPES') {
            return item.values;
          }
        });
        let payerTypeObj = varPayerTypes?.[0]?.values?.find(item => {
          if (item?.name === 'Coach') {
            return item ?? {};
          }
        });
        setPayerTypes(payerTypeObj);
      })

      .catch(error => {
        console.log('error');
      });
  };
  const [fileAvailability, setFileAvailability] = React.useState<boolean>(true);
  React.useEffect(() => {
    if (uploadInsuranceFile === undefined) {
      setFileAvailability(true);
    } else {
      setFileAvailability(false);
    }
  }, [uploadInsuranceFile]);
  React.useEffect(() => {
    handleGetLookups();
    const paymentCancelStatus = Cookies.get('paymentCancel');
    setCancelPaymentCookie(paymentCancelStatus);
  }, []);
  const handleNextForm = () => {
    dispatch(PageActions.setPageLoader(true));
    console.log(coachInsuranceUploadPayload, 'coachInsuranceUploadPayload');
    handleRequest(
      coachUploadCertificateOfInsurance(
        selectedCoachId!,
        coachInsuranceUploadPayload?.formData!,
      ),
    )
      .then(res => {
        if (res && !apiError) {
          if (cancelPaymentCookie) {
            Cookies.remove('paymentCancel');
          }
          Cookies.set('issuranceRadioButtonpayload', insuranceRadioVal);
          dispatch(PageActions.setPageLoader(false));
          dispatch(
            actions.dispatchToast({
              errorTxt: 'File uploaded successfully',
              show: true,
              errorType: 'success',
            }),
          );
          navigate(`/payment/${payerTypes?.id}/${currentUserId}/false/false`);

          dispatch(
            manageInsuranceActions.setInsuranceUpload?.({
              formData: {
                certificateOfInsurance:
                  coachInsuranceUploadPayload &&
                  coachInsuranceUploadPayload.formData &&
                  coachInsuranceUploadPayload.formData.certificateOfInsurance
                    ? coachInsuranceUploadPayload.formData
                        .certificateOfInsurance
                    : new Blob(),
              },
            }),
          );
        } else {
          dispatch(PageActions.setPageLoader(false));
          dispatch(
            actions.dispatchToast({
              errorTxt: 'File upload failed',
              show: true,
              errorType: 'error',
            }),
          );
        }

        console.log('insurance uploadded ', res);
      })
      .catch(error => {
        console.error('Error adding team:', error);
      });
  };
  console.log(insuranceUploadDataRedux, 'insuranceUploadDataRedux');
  useEffect(() => {
    const insuranceUploadData = Cookies.get('issuranceRadioButtonpayload');
    setInsuranceRadioVal(insuranceUploadData);
    setCoachInsuranceUploadPayload(insuranceUploadDataRedux ?? {});
    setUploadInsuranceFile(
      insuranceUploadDataRedux?.formData?.certificateOfInsurance as File,
    );
  }, [cancelPaymentCookie]);
  return (
    <>
      {pageLoader ? (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      ) : (
        <MainWrapper id="PersonalInfoFormMainWrapper">
          <SubMainWrapper className="SubMainWrapper">
            <BorderWrapper className="inner-BorderWrapper">
              <QuestionWrapper className="QuestionWrapper">
                <FormSecondaryLabel>
                  Do you have a coach liability insurance?
                </FormSecondaryLabel>
                <RadioWrapper>
                  <RadioButtonGroup
                    onChange={isHandleChange}
                    value={insuranceRadioVal}
                  />
                </RadioWrapper>
              </QuestionWrapper>
              {selectFile ? (
                <ComponentWrapper className="ComponentWrapper">
                  <StyledInfoLabelWrapper className="StyledInfoLabelWrapper">
                    <InfoLabel>
                      Please upload the insurance copy to proceed with the
                      registration
                    </InfoLabel>
                  </StyledInfoLabelWrapper>

                  <CustomDragAndDropFiles
                    className="dragNdrop"
                    onCancel={handleFileOnCancelClick}
                    onChange={e => handleFileUpload(e)}
                    uploadBtnDisabled={!fileAvailability}
                    onRemoveFile={handleRemoveFile}
                    chooseFileBtnDisabled={!fileAvailability}
                  />
                </ComponentWrapper>
              ) : (
                ''
              )}
            </BorderWrapper>
            <InsurancePopUp
              show={visible}
              setShow={setVisible}
              onChange={handleNewInsuranceChange}
              onClose={handleOnCancelNewInsuranceClick}
              setFormNumber={setFormNumber}
              setInsuranceRadioVal={setInsuranceRadioVal}
              heading="Do you wish to submit application for a new insurance and pay
              for it now?"
            />
          </SubMainWrapper>
          <BottomRightWrapper id="BottomRightWrapper">
            <ButtonWrapper>
              <BackNextButton
                classNameBack="btn-back"
                classNameNxt="btn-nxt"
                onClickNxt={handleNextForm}
                onClickBack={handleBackForm}
                disableNext={fileAvailability}
                next={uploadInsuranceFile ? 'Sumbit and Pay' : 'submit'}
                // disableBack={backDisable}
                back="Back"
              />
            </ButtonWrapper>
          </BottomRightWrapper>
        </MainWrapper>
      )}
    </>
  );
};

const MainWrapper = styled.div`
  color: #9597a6;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-top: 50px;
  position: relative;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
  .first-para,
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  .dragNdrop {
    width: 100%;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .inner-BorderWrapper {
      padding: 20px 20px 111px 20px;
    }
    .dragNdrop {
      img {
        height: 35px;
      }
      p {
        font-size: 12px;
        button {
          font-size: 13px;
        }
      }
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .inner-BorderWrapper {
      padding: 20px 20px 111px 20px;
    }
    .dragNdrop {
      img {
        height: 35px;
      }
      p {
        font-size: 12px;
        button {
          font-size: 13px;
        }
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .inner-BorderWrapper {
      padding: 20px 20px 111px 20px;
    }
    .dragNdrop {
      img {
        height: 45px;
      }
      p {
        font-size: 13px;
        button {
          font-size: 14px;
        }
      }
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .inner-BorderWrapper {
      padding: 20px 20px 111px 20px;
    }
    .dragNdrop {
      img {
        height: 45px;
      }
      p {
        font-size: 13px;
        button {
          font-size: 14px;
        }
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 40px;
    .inner-BorderWrapper {
      padding-bottom: 111px;
    }
    .first-para,
    label {
      font-size: 12px;
    }
    .dragNdrop {
      img {
        height: 45px;
      }
      p {
        font-size: 13px;
        button {
          font-size: 14px;
        }
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    padding-top: 45px;
    .inner-BorderWrapper {
      padding-bottom: 111px;
    }
    .first-para,
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1439px) {
    padding-top: 50px;
    .inner-BorderWrapper {
      padding-bottom: 111px;
    }
    .first-para,
    label {
      font-size: 14px;
    }
  }
  @media (min-width: 1440px) and (max-width: 1599px) {
    .inner-BorderWrapper {
      padding-bottom: 111px;
    }
  }
  @media (min-width: 1600px) and (max-width: 1719px) {
    .inner-BorderWrapper {
      padding-bottom: 111px;
    }
  }
`;
const SubMainWrapper = styled.div`
  height: calc(100vh - 120px);
  width: 100%;
`;
const BorderWrapper = styled.div`
  height: 100%;
  overflow: auto;
  /* border: 1px solid #585858; */
  border-radius: 20px;
  padding: 20px 20px 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: 850px;
  margin: 0 auto;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: #201e1e !important;
    border-radius: 10px;
  }
`;
const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  .RadioButtonGroupWrapper {
    margin: 0 !important;
    ul {
      padding: 0 !important;
    }
  }
`;
const QuestionWrapper = styled.div`
  width: 100%;
  padding: 30px 20px 30px 20px;
  /* border-bottom: 1px solid #434343; */
  display: flex;
  flex-direction: column;
  ${FormSecondaryLabel} {
    margin: 0 0 15px 0;
  }
`;
const ComponentWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
  .LiabilityInsuranceInfo {
    height: auto;
  }
  #form-file-upload {
    margin: 0 auto 0 0;
  }
`;
const StyledInfoLabelWrapper = styled.div`
  padding: 20px 0;
  @media (min-width: 100px) and (max-width: 319px) {
    label {
      font-size: 12px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    label {
      font-size: 13px !important;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    label {
      font-size: 13px !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    label {
      font-size: 14px !important;
    }
  }
`;

const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 35px 0;

  /* gap: 15px; */
  @media (min-width: 992px) and (max-width: 1199px) {
    /* gap: 5px; */
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    /* gap: 10px; */
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    /* gap: 15px; */
  }
`;

const MainAddressWrapper = styled.div`
  margin-bottom: 40px;
  .horizontal-line {
    display: flex;
    align-items: baseline;
    margin: 20px 20px 15px 20px;
  }
  .horizontal-line span {
    margin: 0 10px 0 0;
    white-space: nowrap;
    color: #4f4f4f;
    font-size: 15px;
  }
  .horizontal-line::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #434343;
    height: 3px;
  }
`;
const BottomRightWrapper = styled.div`
  z-index: 1;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 20px 20px;
  backdrop-filter: blur(10px);
  position: absolute;
  bottom: 0;
  margin-bottom: 5px;
  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 40px;
    &.bg-color-change {
      bottom: 25px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 45px;
    &.bg-color-change {
      bottom: 30px;
      padding-top: 20px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 60px;
    &.bg-color-change {
      bottom: 20px;
      padding-top: 20px;
    }
  }
`;
const ButtonWrapper = styled.div``;
