import React, { useEffect, useState } from 'react';
import { InsuranceUploadForm } from './components/InsuranceUploadForm';
import styled from 'styled-components';
import { NewInsuranceForm } from './components/NewInsuranceForm';
import { selectCoachId } from '../../slice/selectors';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

type Props = {};

const CoachInsurance = (props: Props) => {
  const selectedCoachId = useSelector(selectCoachId);
  const [formNumber, setFormNumber] = useState<number>(1);
  const [backDisable, setBackDisable] = useState(true);
  console.log(selectedCoachId, 'selectedCoachId');
  const navigate = useNavigate();

  const handleNextForm = () => {
    setFormNumber(formNumber => formNumber + 1);
  };
  const handleBackForm = () => {
    if (formNumber === 1) {
      // setBackDisable(true);
      navigate('/coach-registration/coach');
      Cookies.set('paymentCancel', true);
    }
    if (formNumber === 2) {
      setBackDisable(false);
      setFormNumber(formNumber => formNumber - 1);
    }
  };
  useEffect(() => {
    const paymentCancelStatus = Cookies.get('paymentCancel');
    if (paymentCancelStatus) {
      const insuranceUploadData = Cookies.get('issuranceRadioButtonpayload');
      if (insuranceUploadData === 'true') {
        setFormNumber(1);
      } else {
        setFormNumber(2);
      }
    }
  }, []);
  return (
    <MainWrapper id="CoachInsurance">
      {formNumber === 1 ? (
        <InsuranceUploadForm
          handleNextForm={handleNextForm}
          handleBackForm={handleBackForm}
          formNumber={formNumber}
          setFormNumber={setFormNumber}
          selectedCoachId={selectedCoachId}
          backDisable={backDisable}
        />
      ) : formNumber === 2 ? (
        <NewInsuranceForm
          handleNextForm={handleNextForm}
          handleBackForm={handleBackForm}
          formNumber={formNumber}
          setFormNumber={setFormNumber}
          selectedCoachId={selectedCoachId}
        />
      ) : (
        <></>
      )}
    </MainWrapper>
  );
};

export default CoachInsurance;
const MainWrapper = styled.div`
  height: 100%;
`;
