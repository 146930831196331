import { CommonPageWrapper, HeadingWrapper } from 'admin/components/wrappers';
import { PageMainLabel } from 'app/components/SHLabel';
import styled from 'styled-components';
import RiderInformationTable from './RiderInformationTable';
import AdminDropDown from 'app/components/AdminDropDown';
import { findObjectById, getLookupArray } from 'utils/common';
import {
  GeneralLookup,
  GeneralLookupWrapper,
  RiderPoint,
  RiderPointListWrapper,
} from 'services/openapi';
import AdminCustomButton from 'app/components/AdminCustomButton';
import { defaultAddToShowData } from '../static';
import { TableSkeletonLoader } from 'app/components/TableSkeletonLoader';
import { FilterPayLoad } from 'admin/pages/ShowIndividual/Components/ShowViewProfile/staticData';
type Props = {
  lookUps: GeneralLookupWrapper[];
  showId: string | undefined;
  setPayload: React.Dispatch<React.SetStateAction<FilterPayLoad>>;
  payload: FilterPayLoad;
  setClassData: React.Dispatch<
    React.SetStateAction<GeneralLookup[] | undefined>
  >;
  getShowRiderPoints: (
    showId: string,
    divisionId?: string | undefined,
    categoryId?: string | undefined,
    classFilter?: string[] | undefined,
    pageNumber?: number,
    pageSize?: number,
  ) => void;
  setDivisionData: React.Dispatch<
    React.SetStateAction<GeneralLookup[] | undefined>
  >;
  pageSkip: number | undefined;
  addRiderPoint: (
    requestBody: RiderPoint,
    listParameters: {
      showId: string;
      divisionId: string;
      categoryId: string;
      classFilter: string[] | undefined;
      pageNumber: number | undefined;
    },
  ) => void;
  divisonData: GeneralLookup[] | undefined;
  classData: GeneralLookup[] | undefined;
  loading: boolean;
  riderShowList: RiderPointListWrapper | undefined;
  setPageSkip: React.Dispatch<React.SetStateAction<number | undefined>>;
  setShowRiderId: React.Dispatch<React.SetStateAction<string | undefined>>;
  showRiderId: string | undefined;
};

export const ShowStatusPage = ({
  lookUps,
  showId,
  setPayload,
  payload,
  setClassData,
  getShowRiderPoints,
  setDivisionData,
  pageSkip,
  addRiderPoint,
  divisonData,
  classData,
  loading,
  riderShowList,
  setPageSkip,
  setShowRiderId,
  showRiderId,
}: Props) => {
  const handleCategoryChange = data => {
    setPayload({
      ...payload,
      categoryId: data,
      divisionId: { id: '', name: '' },
      classFilter: { id: '', name: '' },
    });
    setClassData([]);
    let categoryLookUpList = getLookupArray('TEAM_CATEGORY', lookUps);
    const matchingObject = findObjectById(categoryLookUpList, data.id);
    if (matchingObject) {
      const { children } = matchingObject;
      setDivisionData(children!);
    }
  };
  const handleDivisionChange = data => {
    setPayload({
      ...payload,
      divisionId: data,
      classFilter: { id: '', name: '' },
    });
    setClassData(data.children!);
  };
  const handleClassChange = data => {
    setPayload({ ...payload, classFilter: data });
  };
  const handleSaveClick = () => {
    if (payload) {
      getShowRiderPoints(
        showId ?? '',
        payload.divisionId.id,
        payload.categoryId.id,
        payload.classFilter.id ? [payload.classFilter.id] : undefined,
        pageSkip,
      );
    }
  };
  const handleCancelClick = () => {
    getShowRiderPoints(showId ?? '', undefined, undefined, undefined, pageSkip);
    setPayload(defaultAddToShowData);
    setClassData([]);
    setDivisionData([]);
  };
  const onSaveClick = (requestBody: RiderPoint) => {
    let listParameters = {
      showId: showId ?? '',
      divisionId: payload.divisionId.id,
      categoryId: payload.categoryId.id,
      classFilter: payload.classFilter.id
        ? [payload.classFilter.id]
        : undefined,
      pageNumber: pageSkip,
    };
    addRiderPoint(requestBody, listParameters);
  };
  const refreshFunction = page => {
    getShowRiderPoints(
      showId ?? '',
      payload.divisionId.id,
      payload.categoryId.id,
      payload.classFilter.id ? [payload.classFilter.id] : undefined,
      page,
      10,
    );
  };

  return (
    <StyledCommonPageWrapper>
      <FilterWrapper>
        <DropdownWrapper>
          <AdminDropDown
            label="Category"
            placeholder=""
            data={getLookupArray('TEAM_CATEGORY', lookUps)}
            onDataChange={handleCategoryChange}
            defaultValue={payload.categoryId}
            isLoader={false}
          />
          <AdminDropDown
            label="Division"
            placeholder=""
            data={divisonData}
            onDataChange={handleDivisionChange}
            defaultValue={payload.divisionId}
            isLoader={false}
            disabled={divisonData === undefined || divisonData?.length === 0}
          />
          <AdminDropDown
            label="Class"
            placeholder=""
            data={classData}
            onDataChange={handleClassChange}
            defaultValue={payload.classFilter}
            isLoader={false}
            disabled={classData === undefined || classData?.length === 0}
          />
        </DropdownWrapper>
        <ActionWrapper>
          <AdminCustomButton
            buttonType="cancel"
            onClick={handleCancelClick}
            disabled={payload.categoryId.id === ''}
          >
            Cancel
          </AdminCustomButton>
          <AdminCustomButton
            buttonType="save"
            onClick={handleSaveClick}
            disabled={payload.categoryId.id === ''}
          >
            Filter Show Results
          </AdminCustomButton>
        </ActionWrapper>
      </FilterWrapper>
      <TitleCardWrapper>
        <HeadingWrapper className="heading-wrapper">
          <div className="center-wrapper">
            {' '}
            <PageMainLabel className="label-wrapper">
              Rider Information
            </PageMainLabel>
          </div>
          <div className="point-wrapper">
            <PageMainLabel className="Point-label-wrapper">
              Points Earned
            </PageMainLabel>
          </div>
        </HeadingWrapper>
      </TitleCardWrapper>
      {/* rider information table */}
      {loading ? (
        <TableSkeletonLoader rowContent={8} width={100} headingWidth={80} />
      ) : (
        <RiderInformationTable
          riderShowList={riderShowList}
          setPageSkip={setPageSkip}
          pageSkip={pageSkip}
          refreshFunction={refreshFunction}
          setShowRiderId={setShowRiderId}
          showRiderId={showRiderId}
          onSaveClick={onSaveClick}
        />
      )}
    </StyledCommonPageWrapper>
  );
};
const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 0px 20px 0px;
`;
const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 60%;
  gap: 15px;
  .SHdropdown {
    width: 33.33%;
  }
`;
const ActionWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: start;
  width: 40%;
  padding-top: 22px;
  padding-left: 12px;
`;
const StyledCommonPageWrapper = styled(CommonPageWrapper)`
  padding: unset !important;
`;
const TitleCardWrapper = styled.div`
  margin: 0px 0px 0px 0px;
  background-color: #e7ecff;
  .heading-wrapper {
    padding: 5px 0px 5px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .center-wrapper {
      display: flex;
      justify-content: center;
      flex: 1;
    }
    .point-wrapper {
      display: flex;
      justify-content: left;
      width: 200px;
    }
    .label-wrapper {
      color: #000000;
      font-size: 14px;
      font-weight: 600;
      margin: unset !important;
      margin-left: 30px;
      letter-spacing: 0.2px;
      font-family: 'inter';
    }
    .Point-label-wrapper {
      color: #000000;
      font-size: 14px;
      font-weight: 600;
      margin: unset !important;
      letter-spacing: 0.2px;
      font-family: 'inter';
    }
  }
`;
