import { GridCellProps } from '@progress/kendo-react-grid';
import styled from 'styled-components';
import { SHToolTip } from 'app/components/SHToolTip';

type Props = {
  className?: string;
  e: GridCellProps;
  onClick?: (e) => void;
};

const ShowRiderBackNumberCell = ({ className, e, onClick }: Props) => {
  const data = e?.dataItem;
  return (
    <td className={className ?? ''}>
      <SHToolTip position="top">
        <BackNoWrapper title={data?.backNumber}>
          {data?.backNumber}
        </BackNoWrapper>
      </SHToolTip>
    </td>
  );
};

export default ShowRiderBackNumberCell;
const BackNoWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 80px;
`;
