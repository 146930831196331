import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { EwdRegistrationState } from './types';
import { PayloadAction } from '@reduxjs/toolkit';

export const initialState: EwdRegistrationState = {
  formValue: 0,
  diagonosisPayloadUpload: undefined,
};

const slice = createSlice({
  name: 'ewdRegistrationState',
  initialState,
  reducers: {
    setFormValue(state, action: PayloadAction<number>) {
      state.formValue = action.payload;
    },
    setDiagonosisPayloadUpload(state, action: PayloadAction<Blob | undefined>) {
      state.diagonosisPayloadUpload = action.payload;
    },
  },
});

export const { actions: manageEwdActions } = slice;
export const useEwdRegistrationStateSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
