import styled from 'styled-components';
import CustomDataGrid from '../CustomDataGrid/Index';
import IdProfileCell from '../CustomDataGrid/Cells/IdProfileCell';
import CoachProfileCell from '../CustomDataGrid/Cells/CoachProfileCell';
import TeamPositionCell from '../CustomDataGrid/Cells/TeamPositionCell';
import IconCell from '../CustomDataGrid/Cells/IconCell';

const handleRowClick = e => {};
const dataList = [
  {
    id: '01',
    position: 'Assistant Coach',
    name: 'John Doe',
    email: 'johndoe@example.com',
    imageUrl: 'https://picsum.photos/200/300',
  },
  {
    id: '01',
    position: 'Defensive Coordinator',
    name: 'Jane Smith',
    email: 'janesmith@example.com',
    imageUrl: 'https://picsum.photos/200/300',
  },
  {
    id: '01',
    position: 'Offensive Coordinator',
    name: 'Michael Johnson',
    email: 'michaeljohnson@example.com',
    imageUrl: 'https://picsum.photos/200/300',
  },
  {
    id: '01',
    position: 'Special Teams Coach',
    name: 'Emily Davis',
    email: 'emilydavis@example.com',
    imageUrl: 'https://picsum.photos/200/300',
  },
  {
    id: '01',
    position: 'Quarterbacks Coach',
    name: 'David Brown',
    email: 'davidbrown@example.com',
    imageUrl: 'https://picsum.photos/200/300',
  },
];
const mockData = [
  {
    field: 'id',
    title: 'id',
    cell: e => <IdProfileCell e={e} onClick={handleRowClick} />,
    width: '70',
  },
  {
    field: 'position',
    title: 'Position',
    cell: e => <TeamPositionCell e={e} onClick={handleRowClick} />,
    width: '200',
  },

  {
    field: 'name',
    title: 'Name',
    cell: e => <CoachProfileCell e={e} onClick={handleRowClick} />,
  },
];
const TeamProfileTable = () => {
  return (
    <>
      <CardWrapper className="CardWrapper">
        <CardSubWrapper className="d-flex">
          <CardHeadingLabel>Team Coaches</CardHeadingLabel>
        </CardSubWrapper>
        <GridWrapper>
          <CustomDataGrid
            showPagination
            data={dataList}
            columns={mockData}
            pageSize={2}
            totalCount={mockData.length}
            onRowClick={() => {}}
            skip={1}
          />
        </GridWrapper>
      </CardWrapper>
    </>
  );
};

export default TeamProfileTable;

const StyledLabel = styled.label`
  font-family: 'Inter';
`;

const CardHeadingLabel = styled(StyledLabel)`
  font-size: 15px;
  font-weight: 400;
  color: #1e1e1e;
  margin: 5px 0;
  text-transform: capitalize;
  display: block;
  line-height: 28px;
`;

const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: #ffffff;
`;

const CardSubWrapper = styled.div`
  width: 100%;
  padding: 5px 24px;
  &.d-flex {
    display: flex;
    align-items: center;
    &.end {
      justify-content: end;
      padding: 20px 24px;
    }
  }
`;

const GridWrapper = styled.div`
  .k-grid-content tr {
    &:hover {
      td {
        background: #f0f0f0 !important;
      }
    }
  }
  .k-grid-content tr td {
    cursor: pointer;
  }
`;
