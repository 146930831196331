import { GridCellProps } from '@progress/kendo-react-grid';

type Props = {
  className?: string;
  data?: string;
  e: GridCellProps;
  onClick?: (e) => void;
};

const RidesCell = ({ className, e, onClick, data }: Props) => {
  return (
    <td className={className ?? ''}>
      <div
        style={{
          color: '#475467',
          fontSize: '15px',
          fontFamily: 'Inter',
          fontWeight: '500',
        }}
      >
        {data}
      </div>
    </td>
  );
};

export default RidesCell;
