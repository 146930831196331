import styled from 'styled-components/macro';
import '@fontsource/roboto';
import '@fontsource/arimo';
import { MouseEventHandler } from 'react';
interface SHLabelProps {
  color?: string;
  weight?: string;
  children?: React.ReactNode;
  className?: string;
  onclick?: MouseEventHandler<HTMLLabelElement>;
}
export function SHLabel({
  color,
  weight,
  children,
  className,
  onclick,
}: SHLabelProps) {
  return (
    <LabelMedium
      onClick={onclick}
      color={color}
      weight={weight}
      className={className}
    >
      {children}
    </LabelMedium>
  );
}

export const InfoLabel = styled.label<SHLabelProps>`
  font-family: 'arimo' !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  color: #a7a7a7 !important;
`;
export const FormSecondaryLabel = styled.label<SHLabelProps>`
  font-family: 'arimo' !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  color: #9597a6 !important;
`;
export const TeamListLabel = styled.h4<SHLabelProps>`
  font-family: 'roboto' !important;
  font-style: normal;
  color: #306fdb !important;
  margin: 20px 0 0 0;
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
`;
export const LabelNormal = styled.label<SHLabelProps>`
  font-size: 12px;
  font-weight: '400';
  color: ${props => props.color || '#000000'};
  text-transform: capitalize;
  position: relative;
  font-family: 'Inter';
  z-index: 1;
`;
export const PageMainLabel = styled.label<SHLabelProps>`
  font-weight: 600;
  font-size: 24px;
  color: #171717;
  margin: 0 0 5px 0;
  line-height: 32px;
  font-family: 'Inter';
`;
export const BlurredLabel = styled.label<SHLabelProps>`
  font-family: 'Inter';
  font-size: 12px;
  margin-top: auto;
  margin-bottom: auto;
  color: ${props => props.color || '#4754678f'};
  font-weight: 500;
`;
export const LabelMedium = styled.label<SHLabelProps>`
  font-size: 14px;
  font-weight: '400';
  color: ${props => props.color || '#a3a3a3'};
  text-transform: capitalize;
  position: relative;
  font-family: 'Inter';
  z-index: 1;
`;
export const MainLabelSemiBold = styled.h1<SHLabelProps>`
  font-size: 24px;
  font-weight: 600;
  color: #171717;
  font-family: 'Inter';
`;
export const H2LabelSemiBold = styled.h2<SHLabelProps>`
  font-size: 22px;
  font-weight: 600;
  color: ${props => props.color || '#171717'};
  font-family: 'Inter';
`;
export const SubLabelSemiBold = styled.h3<SHLabelProps>`
  font-size: 15px;
  font-weight: 600;
  color: ${props => props.color || '#475467'};
  font-family: 'Inter';
`;
export const CardHeadingLabel = styled.label<SHLabelProps>`
  font-size: 15px;
  font-weight: 400;
  color: #1e1e1e;
  margin: 5px 0;
  text-transform: capitalize;
  font-family: 'Inter';
`;
export const FieldLabelSmall = styled.label<SHLabelProps>`
  font-family: 'Inter';
  font-size: 13px;
  font-weight: 500;
  color: #a3a3a3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 5px;
`;
export const PageTitle = styled.label<SHLabelProps>`
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 600;
  color: #171717;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 5px;
  line-height: 32px;
`;
