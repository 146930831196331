import { GridCellProps } from '@progress/kendo-react-grid';
import { AdminProfileInfo } from 'app/components/AdminProfileInfo';
import styled from 'styled-components';

type Props = {
  className?: string;
  e: GridCellProps;
};

export const TeamNameCell = ({ className, e }: Props) => {
  const data = e.dataItem;
  return (
    <td className={className ?? ''}>
      <MainWrapper>
        <AdminProfileInfo src={data?.teamImgUrl} name={data?.teamName} />
      </MainWrapper>
    </td>
  );
};
const MainWrapper = styled.div``;
