import styled from 'styled-components';
import DarkNoDataFoundIcon from './assets/img/icon-dark.png';

type Props = {
  noDataLabel?: string;
};

const NoDataFound = ({ noDataLabel }: Props) => {
  return (
    <DivWrapper className="no-data-wrapper">
      <IconWrapper className="no-data-icon-wrapper">
        <img src={DarkNoDataFoundIcon} alt="" />
      </IconWrapper>
      <TextWrapper>
        <NoDataFoundLabel>No Data Found</NoDataFoundLabel>
        <NoDataFoundSubLabel>{noDataLabel}</NoDataFoundSubLabel>
      </TextWrapper>
    </DivWrapper>
  );
};

export default NoDataFound;

const DivWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const IconWrapper = styled.div`
  padding: 20px 0 6px 0;
`;

const TextWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: 20px 0 6px 0;
`;

const StyledLabel = styled.label`
  font-family: 'Inter';
`;

const NoDataFoundLabel = styled(StyledLabel)`
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  margin: 5px 0 12px 0;
  text-transform: capitalize;
  display: block;
  line-height: 20px;
`;

const NoDataFoundSubLabel = styled(StyledLabel)`
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  margin: 5px 0;
  display: block;
  line-height: 24px;
`;
