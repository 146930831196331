import React from 'react';
import styled from 'styled-components';

interface svgProps {
  color?: string;
  width?: string;
  height?: string;
  strokeWidth?: string;
  stroke?: string;
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}
//EditIcon
const AdminEditIcon = ({
  color,
  width,
  height,
  stroke,
  strokeWidth,
  className,
  onClick,
}: svgProps) => {
  return (
    <SvgWrapper
      onClick={onClick}
      className={`${className}`}
      width={width ? width : '14'}
      height={height ? height : '16'}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.43225 12.4977H0.25V9.31549L8.82625 0.739243C8.9669 0.59864 9.15763 0.519653 9.3565 0.519653C9.55537 0.519653 9.7461 0.59864 9.88675 0.739243L12.0085 2.86024C12.0782 2.9299 12.1336 3.01261 12.1713 3.10366C12.209 3.19471 12.2285 3.29231 12.2285 3.39087C12.2285 3.48943 12.209 3.58702 12.1713 3.67807C12.1336 3.76912 12.0782 3.85184 12.0085 3.92149L3.43225 12.4977ZM0.25 13.9977H13.75V15.4977H0.25V13.9977Z"
        fill="#F8F8F8"
      />
    </SvgWrapper>
  );
};
export { AdminEditIcon };
const SvgWrapper = styled('svg')({});
