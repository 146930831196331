import React, { useState } from 'react';
import { CheckBox } from 'app/components/CheckBox';
import styled from 'styled-components';
import '@fontsource/roboto';
import { TextField } from 'app/components/TextField';
import SHdropdown from 'app/components/SHdropdown';
import { GeneralLookup, TeamRegistration } from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';
import { BackNextButton } from 'app/components/BackNextButton';
import Cookies from 'js-cookie';

type Props = {
  addTeamData: TeamRegistration;
  setAddTeamData: React.Dispatch<React.SetStateAction<TeamRegistration>>;
  handleBack: () => void;
  handleNxt: () => void;
  isReturningValue: boolean | null;
  stateLookupData: GeneralLookup[];
  categoryLookupData: GeneralLookup[];
  handleGetReturningTeamDetails: (teamId: string) => void;
  setTeamDefault: React.Dispatch<
    React.SetStateAction<GeneralLookup | undefined>
  >;
  teamDefault: GeneralLookup | undefined;
  assistantCoachData: GeneralLookup[];
  coachData: GeneralLookup[];
  teamDropdownData: GeneralLookup[];
};

export const PersonalInfoForm = ({
  addTeamData,
  setAddTeamData,
  handleBack,
  handleNxt,
  isReturningValue,
  categoryLookupData,
  stateLookupData,
  handleGetReturningTeamDetails,
  setTeamDefault,
  teamDefault,
  assistantCoachData,
  coachData,
  teamDropdownData,
}: Props) => {
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [valid, setValid] = useState<boolean>(false);

  const handleStateOptionChange = (id: string, name: string) => {
    setAddTeamData(prevState => {
      const existingItemIndex = prevState?.category?.findIndex(
        item => item.id === id,
      );
      if (existingItemIndex! > -1) {
        const updatedCategory = [...prevState?.category!];
        updatedCategory.splice(existingItemIndex!, 1);
        return { ...prevState, category: updatedCategory };
      } else {
        return {
          ...prevState,
          category: [...prevState?.category!, { id, name }],
        };
      }
    });
  };

  const handleTeamChange = data => {
    if (data) {
      setTeamDefault(data);
      setAddTeamData(prevData => ({
        ...prevData,
        teamIdOld: data.id,
      }));
      handleGetReturningTeamDetails(data.id);
      const teamRes = JSON.stringify(data);
      Cookies.set('teamDropdownData', teamRes);
    }
  };
  const handlePhChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
      setAddTeamData({
        ...addTeamData,
        phone: inputValue,
      });
    }
  };
  const handleEmailChange = e => {
    setAddTeamData({ ...addTeamData, email: e.target.value });
  };
  const handleZipChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 9 && /^[a-zA-Z0-9]*$/.test(inputValue)) {
      setAddTeamData({ ...addTeamData, zip: e.target.value });
    }
  };
  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [addTeamData]);
  return (
    <MainWrapper>
      <OverflowWrapper>
        <p className="first-para">
          Please indicate all the categories that your team will have:
        </p>
        <CheckboxWrapper>
          {categoryLookupData?.map(value => (
            <div className="checkbox-data" key={value?.id}>
              <CheckBox
                label={value?.name!}
                value={value.id}
                onChange={() =>
                  handleStateOptionChange(value?.id!, value?.name!)
                }
                checked={addTeamData.category?.some(
                  item => item.id === value.id,
                )}
              />
            </div>
          ))}
        </CheckboxWrapper>
        <TextFieldGrid>
          <TextFieldWrapper>
            {isReturningValue ? (
              <SHdropdown
                data={teamDropdownData}
                onDataChange={handleTeamChange}
                defaultValue={teamDefault}
                className="drop-down"
                label="Team"
                validations={'required'}
                validator={simpleValidator.current}
              />
            ) : (
              <StyledTextField
                className="drop-down"
                label="Team Name"
                onChange={e =>
                  setAddTeamData({ ...addTeamData, name: e.target.value })
                }
                value={addTeamData?.name}
                validations={'required'}
                validator={simpleValidator.current}
              />
            )}
          </TextFieldWrapper>
          {/* <TextFieldWrapper>
            <SHdropdown
              onDataChange={e =>
                setAddTeamData({
                  ...addTeamData,
                  mainCoach: e,
                })
              }
              defaultValue={addTeamData?.mainCoach}
              data={coachData}
              label="Main Coach"
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>

          <MultiTextFieldWrapper>
            <MultiSelectDropdown
              data={coachData?.filter(
                coach => coach.id !== addTeamData?.mainCoach?.id,
              )}
              defaultValue={addTeamData?.associateCoachList!}
              onChange={e => {
                setAddTeamData({
                  ...addTeamData,
                  associateCoachList: e?.value,
                });
              }}
              label="Coach"
            />
          </MultiTextFieldWrapper>
          <MultiTextFieldWrapper>
            <MultiSelectDropdown
              data={assistantCoachData}
              defaultValue={addTeamData?.assistantCoachList!}
              onChange={e => {
                setAddTeamData({
                  ...addTeamData,
                  assistantCoachList: e?.value,
                });
              }}
              label="Assistant Coach"
            />
          </MultiTextFieldWrapper> */}
          <TextFieldWrapper>
            <StyledTextField
              label="Main Contact"
              type="text"
              name="mainContact"
              onChange={e =>
                setAddTeamData({
                  ...addTeamData,
                  mainContact: e.target.value,
                })
              }
              value={addTeamData?.mainContact!}
              validations={'required|alpha_space|max:30'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              label="Phone Number"
              type="tel"
              name="phoneNumber"
              onChange={handlePhChange}
              value={addTeamData?.phone!}
              validations={'required|numeric|min:10|max:10'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              label="Email"
              type="email"
              name="email"
              onChange={handleEmailChange}
              value={addTeamData?.email!}
              validations={'required|email'}
              validator={simpleValidator.current}
              maxLength={60}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
        <MainAddressWrapper>
          <div className="horizontal-line">
            <span>Main Address</span>
          </div>
          <TextFieldGrid>
            <TextFieldWrapper>
              <StyledTextField
                label="Street Address"
                type="text"
                name="streetAddress"
                onChange={e =>
                  setAddTeamData({
                    ...addTeamData,
                    street: e.target.value,
                  })
                }
                value={addTeamData?.street!}
                validations={'required'}
                validator={simpleValidator.current}
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <StyledTextField
                label="City"
                type="text"
                name="city"
                onChange={e =>
                  setAddTeamData({
                    ...addTeamData,
                    city: e.target.value,
                  })
                }
                value={addTeamData?.city!}
                validations={'required|max:40'}
                validator={simpleValidator.current}
                maxLength={40}
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <SHdropdown
                data={stateLookupData}
                onDataChange={data => {
                  if (addTeamData) {
                    setAddTeamData({
                      ...addTeamData,
                      state: data,
                    });
                  }
                }}
                defaultValue={addTeamData?.state}
                className="drop-down"
                label="State"
                validations={'required'}
                validator={simpleValidator.current}
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <StyledTextField
                label="Zip"
                type="text"
                name="zip"
                onChange={handleZipChange}
                value={addTeamData?.zip!}
                validations={'required|alpha_num|min:5|max:9'}
                validator={simpleValidator.current}
              />
            </TextFieldWrapper>
          </TextFieldGrid>
          <BottomRightWrapper className="bg-color-change">
            <ButtonWrapper>
              <BackNextButton
                classNameBack="btn-back"
                classNameNxt="btn-nxt"
                onClickNxt={handleNxt}
                onClickBack={handleBack}
                next="Next"
                back="Back"
                disableNext={valid || !addTeamData!?.category?.[0]}
              />
            </ButtonWrapper>
          </BottomRightWrapper>
        </MainAddressWrapper>
      </OverflowWrapper>
    </MainWrapper>
  );
};
const BottomRightWrapper = styled.div`
  z-index: 1;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;
  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: 3px;
    left: 0;
    z-index: 2;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;
const ButtonWrapper = styled.div``;
const MainWrapper = styled.div`
  width: 100%;
  color: #9597a6;
  display: flex;
  flex-direction: column;
  justify-content: left;
  height: calc(100vh - 210px);
  .first-para {
    margin: 0px 12px;
  }
  .first-para {
    font-family: 'Roboto';
    font-size: 14px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .first-para {
      font-size: 11px;
    }
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    .first-para {
      font-size: 11px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .first-para {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .first-para {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .first-para {
      font-size: 14px;
    }
  }
`;
const OverflowWrapper = styled.div`
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
  padding-bottom: 100px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
`;
const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: start;
  padding: 10px 0px 25px;
  margin: 0px 12px;
  width: 100%;
  flex-wrap: wrap;
  .checkbox-data {
    @media (min-width: 100px) and (max-width: 319px) {
      width: 100%;
    }
    @media (min-width: 320px) and (max-width: 767.98px) {
      width: 50%;
    }
    @media (min-width: 767.99px) and (max-width: 991px) {
      width: 50%;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      width: 33.33%;
    }
    @media (min-width: 1200px) and (max-width: 1365px) {
      width: 25%;
    }
    @media (min-width: 1366px) and (max-width: 1448px) {
      width: 25%;
    }
    @media (min-width: 1449px) and (max-width: 1719px) {
      width: 20%;
    }
    @media (min-width: 1720px) and (max-width: 4000px) {
      width: 20%;
    }
  }
`;

const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 20px;
`;
const TextFieldWrapper = styled.div`
  width: 50%;
  margin-bottom: 20px;
  &.styled-TextFieldWrapper {
    display: grid;
    align-items: end;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    width: 100% !important;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    width: 100% !important;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 50% !important;
  }
`;
const StyledTextField = styled(TextField)`
  flex: 0 0 50%;
  &.styled-field {
    margin-bottom: 0px !important;
  }
`;
const MainAddressWrapper = styled.div`
  .horizontal-line {
    padding-bottom: 3%;
    display: flex;
    align-items: baseline;
    margin: 0px 12px;
  }
  .horizontal-line span {
    margin: 0 10px 0 0;
    white-space: nowrap;
    color: #4f4f4f;
    font-size: 15px;
  }
  .horizontal-line::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #434343;
    height: 3px;
  }
`;
