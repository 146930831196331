import { CommonPageWrapper, HeadingWrapper } from 'admin/components/wrappers';
import { AdminSearch } from 'app/components/AdminSearchField';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDebounce } from 'hooks/useDebounce';
import { FilterIcon } from 'app/assets/icons';
import DropdownComponent from 'app/components/CustomFilterPopup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ShowGenerateSplitTable from './ShowGenerateSplitTable';
import CustomBreadCrumb from 'app/components/CustomBreadCrumb';
import { PageMainLabel } from 'app/components/SHLabel';
import { GeneralLookup } from 'services/openapi';
import AdminCustomButton from 'app/components/AdminCustomButton';
import useShowApi from 'admin/hooks/useShowApi';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { TableSkeletonLoader } from 'app/components/TableSkeletonLoader';
import BackButton from 'app/components/BackButton';
const data = ['Team', 'Rider'];

export const ShowGenerateSplitPage = () => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const isPastDataFlag = currentLocation.pathname.includes('past-data');
  const [classASplitNumber, setClassASplitNumber] = useState<{
    [key: string]: GeneralLookup;
  }>({});

  const [classBSplitNumber, setClassBSplitNumber] = useState<{
    [key: string]: GeneralLookup;
  }>({});
  const breadCrumbItems = [
    {
      id: '',
      text: 'Showyeda admin',
      disabled: true,
    },
    {
      id: '/admin/shows',
      text: 'Shows',
      disabled: false,
    },
    {
      id: '',
      text: isPastDataFlag ? 'Past-Data' : 'Generate-split',
      disabled: true,
    },
  ];

  if (isPastDataFlag) {
    breadCrumbItems.push({
      id: '',
      text: 'Shows',
      disabled: true,
    });
  }
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debounceShowSearch = useDebounce(searchTerm);
  const [expandedIndex, setExpandedIndex] = useState<number>();
  const [transformedData, setTransformedData] = useState<any[]>([]);
  const handleItemClick = (item: string) => {};
  const handleSearch = e => {
    setSearchTerm(e.target.value);
  };
  const { getGenerateSplitApi, splitData, loading, setSplitData } =
    useShowApi();
  const { id } = useParams();
  const { updateClassSplitApi } = useShowApi();
  const handleSelect = (e: any) => {
    setExpandedIndex(e.target.props.index);
  };

  const handleSaveSelection = () => {
    let payload = transformedData?.flatMap(item =>
      item.riders.flatMap(rider =>
        rider.class.map(cls => ({
          showRiderId: cls?.showRiderId,
          riderClass: cls?.riderClass,
          split: cls?.split,
          scratch: cls?.scratch === null ? false : cls?.scratch,
        })),
      ),
    );
    if (payload) {
      updateClassSplitApi(true, payload, id, [], '', '', '', '', 0, 10, 10);
    }
  };
  const handleBackClick = () => {
    navigate(`/admin/shows/view/${id}/manage-rider`);
  };
  useEffect(() => {
    getGenerateSplitApi(id ?? '');
  }, []);

  useEffect(() => {
    if (splitData)
      setTransformedData(
        splitData?.map(item => ({
          ...item,
          riders:
            item.riders?.map((rider, rideIndex) => ({
              ...rider,
              index: rideIndex,
              splitLookup: item.splitLookup || [],
              division: `${item.categoryName} ${item.divisionName}`,
              class:
                rider.classes?.map(cls => ({
                  ...cls,
                  splitLookup: item.splitLookup || [],
                })) || [],
            })) || [],
        })),
      );
  }, [splitData]);
  return (
    <>
      <StyledCommonPageWrapper>
        <HeadingWrapper>
          <PageMainLabel>Generate Split</PageMainLabel>
          <CustomBreadCrumb preData={breadCrumbItems} />
        </HeadingWrapper>
        <ButtonWrapper>
          <SubWrapper>
            <BackButton className="back-button" onClick={handleBackClick} />
          </SubWrapper>
          <SubWrapper>
            <AdminCustomButton buttonType="save" onClick={handleSaveSelection}>
              Save Selections
            </AdminCustomButton>
            <div style={{ width: '283px' }}>
              <AdminSearch
                placeholder="Search by shows name"
                onSearchTextEntering={handleSearch}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
            </div>
          </SubWrapper>
        </ButtonWrapper>
        {/* Show Generate Split Table*/}
        {loading ? (
          <TableSkeletonLoader rowContent={10} width={100} headingWidth={40} />
        ) : (
          <PanelBar expandMode="single" onSelect={handleSelect}>
            {transformedData?.map((item, itemIndex) => {
              let title = item.categoryName + '-' + item.divisionName;
              return (
                <PanelBarItem
                  key={itemIndex}
                  title={title}
                  expanded={itemIndex === expandedIndex}
                  animation
                >
                  <ShowGenerateSplitTable
                    debounceShowSearch={debounceShowSearch}
                    classASplitNumber={classASplitNumber}
                    setClassASplitNumber={setClassASplitNumber}
                    classBSplitNumber={classBSplitNumber}
                    setClassBSplitNumber={setClassBSplitNumber}
                    data={item.riders}
                    setTransformedData={setTransformedData}
                    itemIndex={itemIndex}
                    transformedData={transformedData}
                  />
                </PanelBarItem>
              );
            })}
          </PanelBar>
        )}
      </StyledCommonPageWrapper>
    </>
  );
};
const StyledCommonPageWrapper = styled(CommonPageWrapper)`
  .k-panelbar > .k-panelbar-header > .k-link.k-selected,
  .k-panelbar > .k-panelbar-header > .k-link {
    background-color: #e7ecff;
    color: black;
    .k-focus,
    :focus {
      box-shadow: unset !important;
    }
  }
`;
const SubWrapper = styled.div`
  display: flex;
  gap: 15px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0px;
  .field-style {
    display: flex;
  }
  .k-dropdownlist {
    max-height: 31px;
  }
  .k-button .inverted-custom-arrow {
    right: 8px !important;
  }
  .back-button {
    cursor: pointer;
    z-index: 2;
    span {
      font-weight: 500;
    }
  }
`;
