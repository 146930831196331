import { GridCellProps } from '@progress/kendo-react-grid';
import { SHToolTip } from 'app/components/SHToolTip';
import styled from 'styled-components';

type Props = {
  classname?: string;
  e: GridCellProps;
  onClick: () => void;
};

const DivisionClassCell = ({ classname, e, onClick }: Props) => {
  const data = e?.dataItem;
  return (
    <td className={classname ?? ''}>
      <SHToolTip position="top">
        <DivisionWrapper title={data?.classOne}>
          {data?.classOne}
        </DivisionWrapper>
      </SHToolTip>
      <SHToolTip position="top">
        <DivisionWrapper title={data?.classTwo}>
          {data?.classTwo}
        </DivisionWrapper>
      </SHToolTip>
    </td>
  );
};

export default DivisionClassCell;
const DivisionWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  min-width: 150px;
  color: #475467;
`;
