import { useState } from 'react';
import useApi from 'services/mavapi/useApi';
import { ReportService, TeamRosterSummary } from 'services/openapi';

const useTeamRosterApi = () => {
  const [, setError] = useState<unknown | null>();
  const { error: apiError, handleRequest } = useApi();
  const [loading, setLoading] = useState(false);
  const { reportGetTeamRosterReportDetails, reportGetTeamRosterReport } =
    ReportService;

  // States
  const [teamRosterData, setTeamRosterData] = useState<TeamRosterSummary>();

  const getTeamRosterReportDetails = (
    seasonId?: string,
    teamId?: string,
    pageNumber: number = 0,
    pageSize: number = 10,
  ) => {
    setLoading(true);
    let pageNumCall = pageNumber ? pageNumber / 10 + 1 : 1;
    handleRequest(
      reportGetTeamRosterReportDetails(seasonId, teamId, pageNumCall, pageSize),
    )
      .then(res => {
        setTeamRosterData(res);
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };
  const downloadTeamRosterReport = (
    seasonId?: string,
    teamId?: string,
  ): Promise<string> => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      handleRequest(reportGetTeamRosterReport(seasonId, teamId))
        .then(res => {
          resolve(res);
          setLoading(false);
        })
        .catch(() => {
          reject(apiError);
          setLoading(false);
        });
    });
  };

  return {
    loading,
    getTeamRosterReportDetails,
    teamRosterData,
    setTeamRosterData,
    downloadTeamRosterReport,
  };
};

export default useTeamRosterApi;
