export const breadCrumpitems = [
  {
    id: '',
    text: 'Showyeda admin',
    disabled: true,
  },
  {
    id: '/admin/sponsors',
    text: 'Sponsors',
  },
];

export const dummyData = {
  pageDetails: { count: 9, currentPage: 1 },
  sponsors: [
    {
      sponsorId: '5c4035ac-3d41-41c5-e27f-08dc8915dfb8',
      sponsorName: 'Pranav Bhai',
      class: {
        id: 'd2b97f88-343c-49d8-9f4d-0889dc46f37a',
        name: 'EWD Amber Pattern Asst.',
        code: null,
        children: null,
        otherName: null,
        isAdminLive: null,
      },
    },
    {
      sponsorId: 'f3bb340f-930b-4983-18be-08dc891cdd03',
      sponsorName: 'Drina',
      class: {
        id: '3a938bd4-ada6-41c9-85b3-0b6bfef8f579',
        name: 'Jr. High Sapphire Ranch',
        code: null,
        children: null,
        otherName: null,
        isAdminLive: null,
      },
    },
    {
      sponsorId: '1eb5ace9-86cd-4874-894f-0e1f0e47c3c3',
      sponsorName: 'Sidharth Zid',
      class: {
        id: '3a938bd4-ada6-41c9-85b3-0b6bfef8f579',
        name: 'Jr. High Sapphire Ranch',
        code: null,
        children: null,
        otherName: null,
        isAdminLive: null,
      },
    },
    {
      sponsorId: 'd89be491-6b1b-4078-b4bc-7015d5e14b1f',
      sponsorName: 'Vishnu SPONSORED SPONSOREDSPONSOREDSPONSORED',
      class: {
        id: 'd2b97f88-343c-49d8-9f4d-0889dc46f37a',
        name: 'EWD Amber Pattern Asst.',
        code: null,
        children: null,
        otherName: null,
        isAdminLive: null,
      },
    },
    {
      sponsorId: 'a79eb45b-6f8c-48e9-ab98-701ce9c02704',
      sponsorName: 'Shawn Micheals',
      class: {
        id: '3a938bd4-ada6-41c9-85b3-0b6bfef8f579',
        name: 'Jr. High Sapphire Ranch',
        code: null,
        children: null,
        otherName: null,
        isAdminLive: null,
      },
    },
    {
      sponsorId: '98fcaad3-4516-46af-8c78-7689eca7d341',
      sponsorName: 'Sponsored by Me',
      class: {
        id: '88c265c3-9f97-443b-9627-0751be9a194c',
        name: 'Sr. High Opal Rail',
        code: null,
        children: null,
        otherName: null,
        isAdminLive: null,
      },
    },
    {
      sponsorId: '9984334f-1e7c-464b-a33c-8770faf00230',
      sponsorName: 'Sponsored by Salman',
      class: {
        id: 'd2b97f88-343c-49d8-9f4d-0889dc46f37a',
        name: 'EWD Amber Pattern Asst.',
        code: null,
        children: null,
        otherName: null,
        isAdminLive: null,
      },
    },
    {
      sponsorId: '81b7ef86-93c5-4cc0-b5af-91e734bf504e',
      sponsorName: 'Zaheer khan',
      class: {
        id: '3a938bd4-ada6-41c9-85b3-0b6bfef8f579',
        name: 'Jr. High Sapphire Ranch',
        code: null,
        children: null,
        otherName: null,
        isAdminLive: null,
      },
    },
    {
      sponsorId: '4ef5973e-44dd-45eb-aa65-ee3de228ac58',
      sponsorName: 'Seth Rollins',
      class: {
        id: '3a938bd4-ada6-41c9-85b3-0b6bfef8f579',
        name: 'Jr. High Sapphire Ranch',
        code: null,
        children: null,
        otherName: null,
        isAdminLive: null,
      },
    },
  ],
};
