import * as React from 'react';
import styled from 'styled-components';
import Multiselect from 'multiselect-react-dropdown';
import arrow from '../AdminDropDown/assets/arrow.svg';
import search from '../../../admin/assets/icon/search.png';
import AdminCustomButton from '../AdminCustomButton';
import SimpleReactValidator from 'simple-react-validator';
import { FormSkeletonLoader } from '../FormSkeletonLoader';
import { GeneralLookup } from 'services/openapi';

export type MultiSelectDropdownProps = {
  onRemove?: (selectedList: any, selectedItem: any) => void;
  onChange?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onSelect?: (selectedList: any, selectedItem: any) => void;
  data?: GeneralLookup[];
  preDataList: GeneralLookup[];
  textField?: string;
  placeholder?: string;
  className?: string;
  label?: string;
  setPreDataList?: React.Dispatch<React.SetStateAction<GeneralLookup[]>>;
  onDropdownOpen?: boolean;
  searchText?: string;
  validator?: SimpleReactValidator;
  validations?: string;
  hideStar?: boolean;
  isLoader?: boolean;
  disabled?: boolean;
  isCustomValidationError?: boolean;
  customErrorText?: string;
};

export const AdminMultiSelectDropDown = ({
  data,
  preDataList,
  setPreDataList,
  placeholder,
  label,
  onSelect,
  onRemove,
  className,
  onDropdownOpen,
  validations,
  validator,
  hideStar,
  searchText,
  isLoader,
  disabled,
  isCustomValidationError,
  customErrorText,
}: MultiSelectDropdownProps) => {
  const simpleValidator = React.useRef(validator);
  const multiselectRef = React.useRef(null);
  const names = preDataList?.map(entry => entry?.name);
  const concatenatedNames = names?.join(', ');

  const [show, setShow] = React.useState<boolean>(false);
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);
  const toggleDropdown = () => {
    setShow(!show);
  };
  const handleOutsideClick = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShow(false);
    }
  };
  React.useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);
  return (
    <Wrapper className={`className ${disabled ? 'disabled-field' : ''}`}>
      {!isLoader ? (
        <>
          <LabelWrapper className="label">
            {label}{' '}
            <span className="red-star">
              {validations && !hideStar ? '*' : ''}
            </span>
          </LabelWrapper>

          <MultiWrapper ref={wrapperRef}>
            <AdminCustomButton onClick={toggleDropdown}>
              {concatenatedNames ? concatenatedNames : placeholder}
            </AdminCustomButton>
            {show && (
              <MultiSelectWrapper>
                <Multiselect
                  ref={multiselectRef}
                  options={data}
                  selectedValues={preDataList}
                  onSelect={onSelect}
                  onRemove={onRemove}
                  displayValue="name"
                  placeholder={searchText ? searchText : 'Search'}
                  showCheckbox
                  keepSearchTerm
                  disablePreSelectedValues={true}
                  disable={disabled}
                  className={disabled ? 'disabled-dropdown' : ''}
                />
              </MultiSelectWrapper>
            )}
          </MultiWrapper>
          {isCustomValidationError ? (
            <label className="srv-validation-message ">{customErrorText}</label>
          ) : (
            <>
              {simpleValidator?.current?.message(
                label ? label : 'field',
                concatenatedNames,
                validations ?? '',
              )}
            </>
          )}
        </>
      ) : (
        <FormSkeletonLoader textHeight={44} labelHeight={20} isLabel />
      )}
    </Wrapper>
  );
};

export const MultiSelectWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 100px;
  z-index: 9;
  .optionListContainer {
    display: block !important;
    &.displayNone {
      display: block !important;
    }
    .optionContainer {
      li {
        &.option {
          &.disableSelection {
            opacity: 1 !important;
            pointer-events: unset !important;
          }
        }
      }
    }
  }
  .disabled-dropdown {
    display: none !important;
  }
`;
export const MultiWrapper = styled.div`
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 13px;
    height: 13px;
    background-image: url(${arrow});
    top: 14px;
    right: 15px;
  }
  button {
    margin: 0;
    width: 100%;
    height: 44px;
    background-color: #ffffff !important;
    border: 1px solid #caced8 !important;
    box-shadow: none !important;
    border-radius: 5px;
    margin: 0px !important;
    font-family: 'Inter';
    color: #475467;
    padding: 0 10px;
    font-size: 14px;
    width: 100%;
    border: none;
    text-align: left;
    font-family: 'Inter' !important;
    font-weight: 500 !important;
    justify-content: unset !important;
    ::before {
      background: unset !important;
    }
    & > div {
      -webkit-justify-content: unset !important;
      justify-content: unset !important;
      span {
        text-transform: capitalize;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 90%;
      }
    }
  }
  //Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    button {
      font-size: 11px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    button {
      font-size: 12px !important;
    }
  }
  @media (min-width: 576px) and (max-width: 1365px) {
    button {
      font-size: 13px;
    }
  }
`;
export const Wrapper = styled.div`
  &.disabled-field {
    .k-button {
      opacity: 1;
      cursor: default;
      border-color: #f5f5f5 !important;
      background-color: #fafafa !important;
      &::after {
        box-shadow: none !important;
        outline: none !important;
      }
    }
    & > div {
      &::after {
        opacity: 0 !important;
      }
    }
  }
  .label {
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 500;
    color: #a3a3a3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 5px;
  }
  .red-star {
    color: red;
    font-weight: bold;
  }
  .srv-validation-message {
    color: #f44336;
    font-size: 12px;
    display: inline-block;
  }
  //Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    .label {
      font-size: 10px !important;
    }
    li {
      font-size: 11px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .label {
      font-size: 11px !important;
    }
    li {
      font-size: 12px !important;
    }
  }
  @media (min-width: 576px) and (max-width: 1365px) {
    .label {
      font-size: 12px;
      li {
        font-size: 13px !important;
      }
    }
  }
  &.focused {
    .optionListContainer {
      &.displayBlock {
        display: none !important;
      }
    }
  }
  .multiSelectContainer {
    position: relative;
    overflow: auto !important;
    margin-top: 4px !important;
    background: #ffffff !important;
    border: 1px solid #caced8;
    color: #475467;
    border-radius: 5px;
    .search-wrapper {
      position: relative;
      overflow: auto !important;
      background: #ffffff !important;
      padding: 5px !important;
      margin: 5px;
      border: 1px solid #caced8;
      color: #475467;
      &::before {
        content: '';
        position: absolute;
        background-image: url(${search});
        top: 50%;
        left: 18px;
        z-index: 9999;
        font-family: 'WebComponentsIcons';
        width: 15px;
        height: 15px;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        background-size: 12px;
      }
    }
    .optionListContainer {
      position: relative;
      overflow: auto !important;
      margin: 0 !important;
      background: #ffffff !important;
      max-height: 190px;
      overflow-y: auto;
      ::-webkit-scrollbar {
        width: 2px;
        margin-left: 3px;
      }
      ::-webkit-scrollbar-track {
        background: transparent !important;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        width: 9px;
        background: #727272 !important;
        border-radius: 10px;
      }
    }
    ul {
      border-radius: 5px;
      border: none !important;
      display: block;
      margin: 0;
      padding: 5px 10px 8px 10px !important;
      max-height: unset;
      .notFound {
        font-size: 13px;
        font-family: 'Inter';
      }
      li {
        padding: 10px 9px !important;
        font-size: 13px;
        display: flex;
        font-family: 'Inter';
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        .checkbox {
          width: 13px;
          height: 14px;
          margin: 1px 15px 0 0;
        }
        :hover {
          color: #475467;
          background: #8888881f !important;
        }
      }
    }
  }
  .highlightOption {
    background: transparent !important;
    color: #475467;
  }
  .searchWrapper {
    border: 1px solid #e8e8ec;
    border-radius: 4px;
    min-height: 36px;
    position: relative;
    padding: 0;
    background: #727272;
    .chip {
      display: none;
    }
    .searchBox {
      margin: 0px !important;
      font-family: 'Inter';
      padding: 0 10px 0 40px;
      font-size: 13px;
      height: 34px;
      width: 100%;
      border: none;
      text-align: left;
      background: #fff !important;
      color: #475467 !important;
      &::placeholder {
        font-weight: 500;
      }
    }
  }
`;

export const LabelWrapper = styled.label`
  font-family: 'Inter';
  color: #717171;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 12px;
  text-transform: capitalize;
  display: block;
  line-height: unset;
  position: relative;
  margin: 0;
  z-index: 1;
`;
