import React, { useState } from 'react';
import styled from 'styled-components';
type Props = {
  setNumber: React.Dispatch<React.SetStateAction<string>>;
  number: string;
  maxLength?: number;
  disabled?: boolean;
};

const AdminPhoneInput = ({ setNumber, number, maxLength, disabled }: Props) => {
  const handleChange = event => {
    const input = event.target.value.replace(/\D/g, '');
    const formattedInput = formatPhoneNumber(input, event.target.value);
    event.target.value = formattedInput;
    setNumber(formattedInput);
  };

  const formatPhoneNumber = (input, val) => {
    let match;
    if (String(input).length > 6) {
      match = input.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
      if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
      }
    } else if (String(input).length > 3) {
      match = input.match(/^(\d{0,3})(\d{0,3})$/);
      if (match) {
        return `(${match[1]}) ${match[2]}`;
      }
    }

    return input;
  };
  return (
    <div>
      <Input
        value={number}
        onChange={handleChange}
        inputMode="tel"
        type="tel"
        maxLength={maxLength ? maxLength : 14}
        disabled={disabled ? disabled : false}
      ></Input>
    </div>
  );
};

export default AdminPhoneInput;

const Input = styled.input`
  background-color: white;
  padding: 0px 12px 0px 12px;
  border-radius: 8px;
  border: 1px #caced8 solid;
  height: 44px;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Inter';
  color: #475467;
  gap: 10px;
  outline: none;
  box-shadow: none;
  width: 100%;
`;
