import React from 'react';
import { CustomgridLayoutWrapper } from 'admin/components/wrappers';
import { GridLayoutItem } from '@progress/kendo-react-layout';
import { AdminTextField } from 'app/components/AdminTextField';
import AdminDropDown from 'app/components/AdminDropDown';
import styled from 'styled-components';
import { AdminRiderRegistration, GeneralLookupWrapper } from 'services/openapi';
import { getLookupArray } from 'utils/common';
import AdminCustomYearPicker from 'app/components/AdminCustomYear';
import SimpleReactValidator from 'simple-react-validator';

type Props = {
  riderData: AdminRiderRegistration;
  setRiderdata: React.Dispatch<React.SetStateAction<AdminRiderRegistration>>;
  handleChange: (event: any) => void;
  lookUps: GeneralLookupWrapper[];
  simpleValidator: React.MutableRefObject<SimpleReactValidator>;
  riderDataLoading: boolean;
};

const SchoolDetails = ({
  riderData,
  handleChange,
  setRiderdata,
  lookUps,
  simpleValidator,
  riderDataLoading,
}: Props) => {
  return (
    <>
      <GridWrapper>
        <CustomgridLayoutWrapper
          rows={[{ height: 'auto' }]}
          cols={[{ width: '31.8%' }, { width: '33%' }, { width: '33%' }]}
          gap={{ rows: 20, cols: 10 }}
        >
          <GridLayoutItem>
            <AdminTextField
              label="School Name"
              placeholder=""
              value={riderData?.school?.schoolName ?? ''}
              onChange={handleChange}
              name="school.schoolName"
              validations="required|max:100"
              validator={simpleValidator.current}
              className="field-style"
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminDropDown
              label="Grade "
              placeholder=""
              data={getLookupArray('GRADE', lookUps)}
              onDataChange={data => {
                setRiderdata({
                  ...riderData,
                  grade: data,
                });
              }}
              defaultValue={riderData?.grade}
              validations="required"
              validator={simpleValidator.current}
              className="field-style"
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            {/* year picker */}

            <AdminCustomYearPicker
              validations="required"
              label="Graduation Year "
              onChange={data => {
                setRiderdata({
                  ...riderData,
                  expectedGraduationYear: Number(data),
                });
              }}
              value={
                riderData?.expectedGraduationYear === 0
                  ? undefined
                  : riderData?.expectedGraduationYear!
              }
              validator={simpleValidator.current}
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>

          <GridLayoutItem>
            <AdminTextField
              label="School Address"
              placeholder=""
              value={riderData?.school?.street ?? ''}
              onChange={handleChange}
              name="school.street"
              validations="required|max:100"
              validator={simpleValidator.current}
              className="field-style"
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminTextField
              label="School City"
              placeholder=""
              value={riderData?.school?.city ?? ''}
              onChange={handleChange}
              name="school.city"
              validations="required|max:100"
              validator={simpleValidator.current}
              className="field-style"
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminDropDown
              label="School State "
              placeholder=""
              data={getLookupArray('STATE', lookUps)}
              onDataChange={data => {
                setRiderdata({
                  ...riderData,
                  school: {
                    ...riderData.school,
                    state: data,
                  },
                });
              }}
              defaultValue={riderData?.school?.state}
              validations="required|max:100"
              validator={simpleValidator.current}
              className="field-style"
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminTextField
              label="School Zip"
              placeholder=""
              value={riderData?.school?.zip ?? ''}
              onChange={handleChange}
              name="school.zip"
              className="field-style"
              validations={'required|max:10'}
              validator={simpleValidator.current}
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
        </CustomgridLayoutWrapper>
      </GridWrapper>
    </>
  );
};

export default SchoolDetails;
const GridWrapper = styled.div`
  margin-bottom: 20px;
  @media (min-width: 1899px) and (max-width: 4000px) {
    .k-grid-layout {
      gap: 20px 15px !important;
      grid-template-columns: 30% 33% 33% !important;
    }
  }
  @media (max-width: 991px) {
    .k-grid-layout {
      grid-template-columns: 100% !important;
    }
  }
`;
