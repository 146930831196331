import { AdminSearch } from 'app/components/AdminSearchField';
import CustomDataGrid from 'app/components/CustomDataGrid/Index';
import React, { useState } from 'react';
import styled from 'styled-components';
import RidesCell from 'app/components/CustomDataGrid/Cells/RidesCell';

type Props = {};

const RidesInformation = (props: Props) => {
  const handleSearch = e => {
    setSearchTerm(e.target.value);
  };
  const [searchTerm, setSearchTerm] = useState<string>('');
  const dataList = [
    {
      name: 'Champion Center Springfield',
      date: '26-04-2024',
      class: 'Class 1 - Group 20 Senior High Diamond Reining',
      points: '106',
    },
    {
      name: 'Champion Center Springfield',
      date: '26-04-2024',
      class: 'Class 1 - Group 20 Senior High Diamond Reining',
      points: '106',
    },
    {
      name: 'Champion Center Springfield',
      date: '26-04-2024',
      class: 'Class 1 - Group 20 Senior High Diamond Reining',
      points: '106',
    },
    {
      name: 'Champion Center Springfield',
      date: '26-04-2024',
      class: 'Class 1 - Group 20 Senior High Diamond Reining',
      points: '106',
    },
    {
      name: 'Champion Center Springfield',
      date: '26-04-2024',
      class: 'Class 1 - Group 20 Senior High Diamond Reining',
      points: '106',
    },
    {
      name: 'Champion Center Springfield',
      date: '26-04-2024',
      class: 'Class 1 - Group 20 Senior High Diamond Reining',
      points: '106',
    },
    {
      name: 'Champion Center Springfield',
      date: '26-04-2024',
      class: 'Class 1 - Group 20 Senior High Diamond Reining',
      points: '106',
    },
    {
      name: 'Champion Center Springfield',
      date: '26-04-2024',
      class: 'Class 1 - Group 20 Senior High Diamond Reining',
      points: '106',
    },
    {
      name: 'Champion Center Springfield',
      date: '26-04-2024',
      class: 'Class 1 - Group 20 Senior High Diamond Reining',
      points: '106',
    },
    {
      name: 'Champion Center Springfield',
      date: '26-04-2024',
      class: 'Class 1 - Group 20 Senior High Diamond Reining',
      points: '106',
    },
  ];
  const mockData = [
    {
      field: 'name',
      title: 'Show Name',
      cell: e => <RidesCell e={e} data={e.dataItem.name} />,
      width: '300',
    },
    {
      field: 'date',
      title: 'Date',
      cell: e => <RidesCell e={e} data={e.dataItem.date} />,
      width: '150',
    },

    {
      field: 'class',
      title: 'Class',
      cell: e => <RidesCell e={e} data={e.dataItem.class} />,
      width: '450',
    },
    {
      field: 'points',
      title: 'Points',
      cell: e => <RidesCell e={e} data={e.dataItem.points} />,
    },
  ];
  return (
    <Wrapper>
      <SearchWrapper>
        <AdminSearch
          placeholder="Search for a ride"
          onSearchTextEntering={handleSearch}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          className="search-ride"
        />
      </SearchWrapper>
      <Line />
      <GridWrapper>
        <CustomDataGrid
          showPagination
          data={dataList}
          columns={mockData}
          pageSize={2}
          totalCount={mockData.length}
          onRowClick={() => {}}
          skip={1}
        />
      </GridWrapper>
    </Wrapper>
  );
};

export default RidesInformation;

const Wrapper = styled.div``;
const SearchWrapper = styled.div`
  display: flex;
  justify-content: end;
  padding-right: 10px;
  .search-ride {
    width: 20%;
    padding-bottom: 20px;
  }
`;
const GridWrapper = styled.div`
  .k-grid-content tr {
    &:hover {
      td {
        background: #f0f0f0 !important;
      }
    }
  }
  .k-grid-header tr th .k-cell-inner .k-link {
    font-weight: 500;
    font-size: 15px;
    color: #475467;
    font-family: 'Inter';
  }
  .k-grid-content tr td {
    cursor: pointer;
  }
`;
const Line = styled.div`
  padding-top: 2px;
  width: 100%;
  border-bottom: 1px solid #eaecf0;
`;
