import styled from 'styled-components';
import '@fontsource/roboto';
import React, { useState } from 'react';
import { BackNextButton } from 'app/components/BackNextButton';
import RoundRadioBtn from 'app/components/RoundRadioBtn';
import { Option } from 'app/pages/TryitRiderRegistrationPage';
import arrowImg from './assets/img/icon/arrow.png';
import { TextField } from 'app/components/TextField';
import SHDatePicker from 'app/components/SHDatePicker';
import { useNavigate } from 'react-router-dom';

type Props = {
  onClickBackDivions?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onClickNxtDivions?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};
const data: Option[] = [
  { label: 'Amber - Walk Only Assisted', value: 'first' },
  { label: 'Amber - Walk Only Independent', value: 'second' },
  { label: 'Topaz - Walk-Trot Assisted', value: 'thrid' },
  { label: 'Topaz - Walk-Trot Independent', value: 'fourth' },
];
const newData: Option[] = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

const TryitEWDDivisions = ({
  onClickBackDivions,
  onClickNxtDivions,
}: Props) => {
  const [detailsData, setDetailsData] = useState({
    coachSignature: '',
    parentGuardianSignature: '',
    riderSignature: '',
  });
  const navigate = useNavigate();
  const [value, setValue] = useState(new Date());
  const handleChange = event => {
    const { name, value } = event.target;
    console.log('changed', value);
    setDetailsData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const changeDate = event => {
    setValue(event.value);
  };
  const handleBack = () => {
    navigate(-1);
  };
  const handleReturningSelection = () => {};
  return (
    <MainWrapper>
      <OverflowWrapper>
        <p className="para-two">
          <i>EWD Division: (select one)</i>
        </p>
        <RadioBtnWrapper>
          <RoundRadioBtn data={data} onChange={handleReturningSelection} />
        </RadioBtnWrapper>
        <PhotoWrapper className="horizontal-line">
          <p className="photo">Photo Release</p>
        </PhotoWrapper>
        <p>
          May your likeness in photos taken in photos by our official
          photographers be used in promotional literature for YEDA.
        </p>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YesNoWrapper>
            <RoundRadioBtn data={newData} onChange={handleReturningSelection} />
          </YesNoWrapper>
          <span className="small-span">
            (If no, please enclose a current photo of the rider so that we can
            ensure their likeness is not used.)
          </span>
        </div>
        <InfoWrapper>
          <p className="para-one">
            <i>PLEASE NOTE</i>
          </p>
          <p>
            Per Rule YEDA Rulebook each participant in the equestrians with
            disabilities competition must be 10 years of age or in the 4th grade
            or older. With a diagnosed mental or physical condition attested to
            by a licensed medical physician. The Special Diagnosis form must be
            completed, signed by a licensed medical doctor and returned to YEDA
            with this membership form.
          </p>
          <p className="para-one">
            <i>NOTICE</i>
          </p>
          <p>
            YEDA DOES NOT ASSUME ANY RESPONSIBILITY FOR THE SAFETY OF
            PARTICIPANTS AND/OR ATTENDEES AT ALL EQUINE EVENTS. EQUINE EVENTS
            ARE INHERENTLY RISKY. “INHERENT RISK OF AN EQUINE ACTIVITY" MEANS A
            DANGER OR CONDITION THAT IS AN INTEGRAL PART OF AN EQUINE ACTIVITY,
            INCLUDING, BUT NOT LIMITED TO, ANY OF THE FOLLOWING:(A) THE
            PROPENSITY OF AN EQUINE TO BEHAVE IN WAYS THAT MAY RESULT IN INJURY,
            DEATH, OR LOSS TO PERSONS ON OR AROUND THE EQUINE; (B) THE
            UNPREDICTABILITY OF AN EQUINE'S REACTION TO SOUNDS, SUDDEN MOVEMENT,
            UNFAMILIAR OBJECTS, PERSONS, OR OTHER ANIMALS; (C) HAZARDS,
            INCLUDING, BUT NOT LIMITED TO, SURFACE OR SUBSURFACE CONDITIONS; (D)
            A COLLISION WITH ANOTHER EQUINE, ANOTHER ANIMAL, A PERSON, OR AN
            OBJECT; OR (E) THE POTENTIAL OF AN EQUINE ACTIVITY PARTICIPANT TO
            ACT IN A NEGLIGENT MANNER THAT MAY CONTRIBUTE TO INJURY, DEATH, OR
            LOSS TO THE PERSON OF THE PARTICIPANT OR TO OTHER PERSONS,
            INCLUDING, BUT NOT LIMITED TO, FAILING TO MAINTAIN CONTROL OVER AN
            EQUINE OR FAILING TO ACT WITHIN THE ABILITY OF THE PARTICIPANT. THE
            PARENT OR GUARDIAN, ON BEHALF OF THEIR PARTICIPATING MINOR, ASSUMES
            ALL RISK OF PERSONAL INJURY OR PROPERTY DAMAGE OCCURRING AS A RESULT
            OF THE PARTICIPATION AND DOES HEREBY RELEASE AND DISCHARGE YEDA AND
            SHOW MANAGEMENT, THEIR RESPECTIVE OFFICERS, DIRECTORS,
            REPRESENTATIVES, AND EMPLOYEES FROM ANY AND ALL LIABILITY, WHENEVER
            OR HOWEVER ARISING FROM SUCH PARTICIPATION.
          </p>
          <ListDataWrapper>
            <ul>
              <div className="list-style">
                <img src={arrowImg} alt="List-arrow" />
                <li>
                  COACH GUARANTEES THEY ARE RESPONSIBLE FOR ALUMNI AND ASKING
                  FOR RE-RIDES
                </li>
              </div>
              <div className="list-style">
                <img src={arrowImg} />
                <li>
                  COACH AND TEAM WILL BE RESPONSIBLE FOR PROVIDING HORSES TO
                  COVER ALUMNI RIDES
                </li>
              </div>
              <div className="list-style">
                <img src={arrowImg} />
                <li>
                  COACH GUARANTEES THE ALUMNI IS QUALIFIED IN THEIR ABILITY TO
                  RIDE UNFAMILIAR HORSES IN A GROUP
                </li>
              </div>
            </ul>
          </ListDataWrapper>
          <p className="notice-para">
            RIDING INSTRUCTION IN REGULAR LESSONS
            <br />
            OR <br />
            RIDING THEIR OWN HORSES OFF PREMISES, BOTH ON OWNED AND NON-OWNED
            HORSES
          </p>
          <p>
            YOUR SIGNATURE BELOW ACKNOWLEDGES THAT COACH, GUARDIAN, AND RIDER
            HAVE FULLY REVIEWED THE YEDA RULE BOOK 2023-2024 AND UNDERSTAND THE
            CONDUCT, COMPETITION REQUIREMENTS, AND POSSIBLE SANCTIONS OUTLINED
            THEREIN. YOU ARE HEREBY GUARANTEEING THAT COACH, GUARDIAN, AND RIDER
            WILL ADHERE TO YEDA SOCIAL MEDIA POLICY AND RULES OF THE
            ORGANIZATION. YOUR SIGNATURE ADDITIONALLY ACKNOWLEDGES THAT THE
            RIDER IS A FULLTIME STUDENT IN GOOD ACADEMIC STANDING. COACH,
            GUARDIAN, AND RIDER ARE SUBJECT TO ALL RULES, FINES AND DISCIPLINARY
            ACTIONS FOR VIOLATIONS OF THE YEDA RULES.
          </p>
        </InfoWrapper>
        <TextFieldGrid>
          <StyledTextField
            onChange={handleChange}
            value={detailsData.coachSignature || ''}
            label="Coach's Signature"
            type="text"
            name="coachSignature"
          />
          <StyledSHDatePicker
            onChange={changeDate}
            placeholder=""
            value={value || ''}
            name="dob"
            className="datepicker-style"
            dateLabel="Date"
          />
          <StyledTextField
            onChange={handleChange}
            value={detailsData.riderSignature || ''}
            label="Rider Signature "
            type="text"
            name="riderSignature"
          />
          <StyledSHDatePicker
            onChange={changeDate}
            placeholder=""
            value={value || ''}
            name="dob"
            className="datepicker-style"
            dateLabel="Date"
          />
          <StyledTextField
            onChange={handleChange}
            value={detailsData.parentGuardianSignature || ''}
            label="Parent Signature"
            type="text"
            name="parentGuardianSignature"
          />
          <StyledSHDatePicker
            onChange={changeDate}
            placeholder=""
            value={value || ''}
            name="dob"
            className="datepicker-style"
            dateLabel="DATE"
          />
        </TextFieldGrid>
        <ButtonWrapper className="bg-color-change">
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={onClickNxtDivions}
            onClickBack={onClickBackDivions}
            next="Submit"
            back="Cancel"
          />
        </ButtonWrapper>
      </OverflowWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  color: #9597a6;
  width: 100%;
  height: calc(100vh - 260px);
  position: relative;
  padding: 20px 0px 0px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
  .para-one {
    font-family: 'Roboto';
    font-size: 14px;
    color: #ff9900;
  }
  .para-two {
    color: #ff9900;
    border-bottom: 1px solid #585858;
    padding: 0px 0px 10px;
    font-size: 11px;
    margin-bottom: 20px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    // padding-top: 20px;
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    // padding-top: 20px;
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    // padding-top: 20px;
    label {
      font-size: 14px;
    }
  }
`;
const OverflowWrapper = styled.div`
  height: 100%;
  overflow: auto;
  .small-span {
    font-size: 11px;
    color: #4f4f4f;
  }
`;
const RadioBtnWrapper = styled.div`
  padding: 30px 10px;
  .k-radio {
    background-color: transparent;
    border: 1px solid #ced4da;
  }
  .k-radio:checked,
  .k-radio.k-checked {
    border-color: 1px solid #0d6efd;
    color: white;
    background-color: #0d6efd;
  }
  .k-radio-list {
    flex-flow: row;
    justify-content: flex-start;
    gap: 50px;
    display: grid;
    grid-template-columns: repeat(2, 250px);
    grid-gap: 10px;
  }
  .k-radio-item,
  .k-radio-list-item {
    color: #9597a6;
    gap: 10px;
  }
`;
const PhotoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  label {
    color: #4f4f4f;
    font-style: oblique;
  }

  ::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #585858;
  }

  p {
    margin: 0 10px 0px 0px;
    white-space: nowrap;
  }
  .photo {
    color: #4f4f4f;
    // opacity: 0.5;
  }
`;
const YesNoWrapper = styled.div`
  .k-radio {
    background-color: transparent;
    border: 1px solid #ced4da;
  }
  .k-radio:checked,
  .k-radio.k-checked {
    border-color: 1px solid #0d6efd;
    color: white;
    background-color: #0d6efd;
  }
  .k-radio-list {
    flex-flow: row;
    justify-content: flex-start;
    gap: 50px;
    display: grid;
    grid-template-columns: repeat(2, 80px);
    grid-gap: 10px;
  }
  .k-radio-item,
  .k-radio-list-item {
    color: #9597a6;
    gap: 10px;
  }
`;
const InfoWrapper = styled.div`
  width: 100%;
  border: 1px solid #585858;
  border-radius: 10px;
  margin: 30px 0px 20px;
  padding: 10px 20px;
  background: #27292d;
  p {
    font-family: 'Roboto';
    color: #a7a7a7;
  }
  .notice-para {
    text-align: center;
    border-top: 1px solid #585858;
    border-bottom: 1px solid #585858;
    margin: 10px 35px;
    padding: 10px 0px;
  }
`;
const ListDataWrapper = styled.div`
  display: flex;
  align-items: baseline;
  li {
    padding-left: 10px;
    list-style-type: none;
    padding-bottom: 10px;
    font-family: 'Roboto';
    font-size: 11px;
    color: #a7a7a7;
  }
  img {
    width: 11px;
    height: 11px;
  }
  .list-style {
    display: flex;
    align-items: baseline;
  }
  ul {
    margin: 0px;
  }
`;
const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const StyledTextField = styled(TextField)`
  flex: 0 0 calc(50% - 20px);
  margin: 0px 0px 10px !important;
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const StyledSHDatePicker = styled(SHDatePicker)`
  flex: 0 0 calc(50% - 20px);
  margin: 0px 0px 10px !important;
  &.k-input-solid {
    width: 100% !important;
  }
`;

const ButtonWrapper = styled.div`
  height: 70px;
  width: 101%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: -55px;
    left: -1px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;

export default TryitEWDDivisions;
