import { Button } from '@progress/kendo-react-buttons';
import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';

type Props = {
  onClickBack?: MouseEventHandler<HTMLButtonElement>;
  onClickNxt?: MouseEventHandler<HTMLButtonElement>;
  classNameBack?: string;
  classNameNxt?: string;
  className?: string;
  back?: string;
  next?: string;
  disableNext?: boolean;
  disableBack?: boolean;
  buttonTheme?: 'light' | 'dark';
  showIcon?: boolean;
};

export const BackNextButton = ({
  onClickBack,
  onClickNxt,
  classNameBack,
  classNameNxt,
  className,
  back,
  next,
  disableNext,
  disableBack,
  buttonTheme,
  showIcon,
}: Props) => {
  return (
    <Wrapper className={className}>
      <Button
        className={`btn-back ${classNameBack} ${buttonTheme}`}
        onClick={onClickBack}
        disabled={disableBack}
      >
        {back}
      </Button>
      <Button
        className={`btn-nxt ${classNameNxt} ${
          disableNext ? 'disableNext' : ''
        }`}
        onClick={onClickNxt}
        disabled={disableNext}
      >
        {next}
      </Button>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  button {
    width: fit-content;
    min-width: 100px;
    height: 30px;
    margin: 0px 10px;
    border-radius: 6px;
    box-shadow: none !important;
    outline: none !important;
    font-family: 'Roboto';
    &.btn-back {
      background-color: #2a2a2a;
      border: 1px solid #606060;
      color: #909090;
      &.light {
        background-color: #ededed;
      }
      &.dark {
        background-color: #2a2a2a;
      }
    }
    &.btn-nxt {
      background-color: #306fdb;
      border: 1px solid #2a2a2a;
      color: #ffffff;
    }
    &.disableNext {
      background: #9e9ea0 !important;
      opacity: 1 !important;
      /* color: #9e9ea0 !important; */
    }
  }
  @media (min-width: 100px) and (max-width: 319px) {
    button {
      min-width: 65px;
      height: 30px;
      font-size: 9px;
    }
  }
  @media (min-width: 320px) and (max-width: 576px) {
    button {
      min-width: 70px;
      font-size: 10px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    button {
      min-width: 75px;
      font-size: 13px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    button {
      min-width: 75px;
      font-size: 13px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    button {
      min-width: 80px;
      font-size: 14px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
  }
`;
