/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CurrentSeason } from '../models/CurrentSeason';
import type { SeasonRequest } from '../models/SeasonRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CommonService {

    /**
     * API to upload season
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static commonUpdateSeason(
        requestBody?: SeasonRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/common/season',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get current season
     * @returns CurrentSeason Success
     * @throws ApiError
     */
    public static commonGetCurrentSeason(): CancelablePromise<CurrentSeason> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/common/currentSeason',
            errors: {
                500: `Server Error`,
            },
        });
    }

}
