import React, { useRef, useState } from 'react';
import {
  Dialog,
  DialogCloseEvent,
  DialogProps,
} from '@progress/kendo-react-dialogs';
import styled from 'styled-components';
import { FormSecondaryLabel, InfoLabel } from 'app/components/SHLabel';
import { RadioGroupChangeEvent } from '@progress/kendo-react-inputs';
import '@fontsource/arimo';
import { TextField } from '../TextField';
import { CheckBox } from '../CheckBox';
import SHButton from '../SHButton';
import UploadIcon from './assets/img/icons/upload.png';
import DocumnetIcon from './assets/img/icons/document.png';
import { DocumentService, GeneralLookup } from 'services/openapi';
import useApi from 'services/mavapi/useApi';
import addIcon from './assets/img/icons/add.png';
import { doc } from 'prettier';

type Props = {
  onClose?: (event: DialogCloseEvent) => void;
  show?: boolean;
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
  onChange?: (event: RadioGroupChangeEvent) => void;
  className?: string;
  themeButton?: 'light';
  documentData: {
    season: GeneralLookup[];
    type: GeneralLookup[];
    file: Blob | undefined;
  };
  setDocumentData: React.Dispatch<
    React.SetStateAction<{
      season: GeneralLookup[];
      type: GeneralLookup[];
      file: Blob | undefined;
    }>
  >;
  seasonData: GeneralLookup;
  typeData: GeneralLookup;
  onSubmit?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  // onChange?: ((event: RadioGroupChangeEvent) => void) | undefined;
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  isChecked: boolean;
};

const AddDocumentPopup = ({
  show,
  setShow,
  className,
  themeButton,
  documentData,
  setDocumentData,
  onChange,
  seasonData,
  typeData,
  onSubmit,
  setIsChecked,
  isChecked,
}: Props) => {
  const [file, setFile] = useState(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const toggleDialog = () => {
    setShow?.(!show);
    setFile(null);
    setIsChecked(false);
  };
  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };
  const handleFileChange = event => {
    const { files } = event.target;
    setFile(files[0].name);
    setDocumentData(prevData => ({
      ...prevData,
      file: event.target.files[0],
    }));
    console.log(event, 'eventevent');
  };
  console.log(documentData, 'documentData');

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleFileCancel = e => {
    fileInputRef.current!.value = '';
    setFile(null);
  };
  return (
    <>
      {show ? (
        <StyledDialog
          onClose={toggleDialog}
          className={`AddDocumentPopup ${themeButton} ${className}`}
        >
          <CloseIcon className="CloseIcon" onClick={toggleDialog}>
            <span className="k-icon k-i-close"></span>
          </CloseIcon>
          <ContentWrapper className="ContentWrapper">
            <TitleWrapper className="TitleWrapper">
              <div>
                <img src={addIcon} alt="add-document" />
              </div>
              <InfoLabel>Add Document</InfoLabel>
            </TitleWrapper>
            <TextFieldGrid className="TextFieldGrid">
              <TextFieldWrapper>
                <p>Season</p>
                <StyledTextField
                  // onChange={onChange}
                  value={seasonData.name}
                  label="Season"
                  type="text"
                  name="firstName"
                  // validations={'required|max:30'}
                  // validator={simpleValidator.current}
                  disabled={true}
                />
              </TextFieldWrapper>
              <TextFieldWrapper>
                <p>Type</p>
                <StyledTextField
                  // onChange={onChange}
                  value={typeData.name}
                  label="Type"
                  type="text"
                  name="firstName"
                  // validations={'required|max:30'}
                  // validator={simpleValidator.current}
                  disabled={true}
                />
              </TextFieldWrapper>
              <TextFieldWrapper className="file-upload-wrapper">
                <p className="file-para">File</p>
                <div className="file-upload">
                  <div className={file ? 'file-div' : 'no-file-div'}>
                    <div>
                      <img src={file ? DocumnetIcon : UploadIcon} />
                    </div>
                    <span onClick={handleUploadClick}>
                      {file ? file : 'Upload file'}
                    </span>
                    <input
                      ref={fileInputRef}
                      type="file"
                      id="input-file-upload"
                      multiple={false}
                      onChange={handleFileChange}
                      className="hidden-input"
                      // validations={'required|max:30'}
                      // validator={simpleValidator.current}
                    />
                    {file && (
                      <span
                        className="k-icon k-i-close fileCancel"
                        onClick={handleFileCancel}
                      ></span>
                    )}
                  </div>
                </div>
              </TextFieldWrapper>
              <CheckboxWrapper>
                <CheckBox
                  label="Set Active"
                  onChange={handleCheckboxChange}
                  value={isChecked}
                />
              </CheckboxWrapper>
              <p className="set-active-para">
                Check set active to make file active
              </p>
            </TextFieldGrid>
            <SHButton
              onClick={onSubmit}
              children="Submit"
              className="submit-button"
              disabled={file === null}
            />
          </ContentWrapper>
        </StyledDialog>
      ) : (
        ''
      )}
    </>
  );
};
const StyledDialog = styled(Dialog)<DialogProps>`
  backdrop-filter: blur(8px);
  &.AddDocumentPopup {
    &.light {
      .ContentWrapper {
        background: rgb(255 255 255 / 87%) !important;
      }
      .TextFieldGrid {
        ::-webkit-scrollbar {
          width: 5px;
          margin-left: 3px;
        }
        ::-webkit-scrollbar-track {
          background: transparent !important;
          border-radius: 10px;
        }
        ::-webkit-scrollbar-thumb {
          width: 9px;
          background: rgb(255 255 255 / 87%) !important;
          border-radius: 10px;
        }
      }
      .CloseIcon {
        background: rgb(255 255 255 / 87%) !important;
      }
    }
  }

  .k-overlay {
  }
  .k-window.k-dialog {
    border: none !important;
    max-height: 90% !important;
    min-height: 50% !important;
    background: transparent !important;
    width: 50%;
  }
  .k-dialog-content {
    background: transparent !important;
    border-radius: 13px !important;
    position: relative;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .k-window.k-dialog {
      width: 98%;
      width: 80%;
    }
    .TitleWrapper {
      label {
        font-size: 12px !important;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .k-window.k-dialog {
      width: 90%;
    }
    .TitleWrapper {
      label {
        font-size: 14px !important;
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .k-window.k-dialog {
      width: 80%;
    }
    .TitleWrapper {
      label {
        font-size: 15px !important;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .k-window.k-dialog {
      width: 55%;
    }
    .TitleWrapper {
      label {
        font-size: 15px !important;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .k-window.k-dialog {
      width: 50%;
    }
    .TitleWrapper {
      label {
        font-size: 15px !important;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .k-window.k-dialog {
      width: 45%;
    }
    .TitleWrapper {
      label {
        font-size: 16px !important;
      }
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .k-window.k-dialog {
      width: 40%;
    }
    .TitleWrapper {
      label {
        font-size: 16px !important;
      }
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    .k-window.k-dialog {
      width: 35%;
    }
    .TitleWrapper {
      label {
        font-size: 15px !important;
      }
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    .k-window.k-dialog {
      width: 30%;
    }
    .TitleWrapper {
      label {
        font-size: 17px !important;
      }
    }
  }
`;
const TitleWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 8px;
  padding: 5px 0 10px;
  border-bottom: 1px solid #5d5c5c;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  > div {
    width: 15px;
    height: auto;
    padding-bottom: 5px;
    img {
      width: 100%;
      height: auto;
    }
  }
`;
const TextFieldWrapper = styled.div`
  width: 80% !important;
  display: flex;
  align-items: center;
  &.file-upload-wrapper {
    padding-top: 15px !important;
  }
  p {
    width: 20%;
    color: #9597a6;
    font-size: 13px;
    padding-top: 17px;
  }
  .file-para {
    padding-top: 5px;
  }
  .file-upload {
    position: relative;
    width: 80%;
    margin: 0 12px;
    height: 35px;

    input {
      width: 80%;
      margin: 0px 12px;
      border-radius: 6px;
    }
  }

  .file-div {
    width: 100% !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 15px;
    position: absolute;
    cursor: pointer;
    padding: 8px 12px;
    background-color: #434343;
    color: #fff;
    border-radius: 6px;
    left: 0px;
    top: 0px;
    > div {
      width: 15px;
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
    }
    .hidden-input {
      display: none;
    }
  }
  .no-file-div {
    width: 100% !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    position: absolute;
    cursor: pointer;
    padding: 8px 12px;
    background-color: #434343;
    color: #fff;
    border-radius: 6px;
    left: 0px;
    top: 0px;
    > div {
      width: 15px;
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
    }
    .hidden-input {
      display: none;
    }
  }
`;
const CheckboxWrapper = styled.div`
  width: 45%;
  > div {
    margin: 12px 0 0;
  }
`;
const StyledTextField = styled(TextField)`
  width: 80%;
  &.styled-field {
    margin-bottom: 0px !important;
  }
  input {
    width: 100% !important;
    border: 1px solid #434343;
    &.back-number {
      &.green-border {
        border: 1px solid green;
      }
      &.red-border {
        border: 1px solid #c62828;
      }
    }
  }
`;
const TextFieldGrid = styled.div`
  width: 100%;
  label {
    color: #9597a6;
  }
  .set-active-para {
    /* width: 20%; */
    color: #ff9900;
    font-size: 9px;
    width: 45%;
    margin: 0px;
    font-family: 'Roboto';
    font-style: italic;
  }

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: column;

  overflow: auto;
  width: 100%;
  height: 100%;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: #201e1e !important;
    border-radius: 10px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    label {
      font-size: 10px !important;
      padding-bottom: 5px;
    }
    span {
      font-size: 10px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    label {
      font-size: 12px !important;
      padding-bottom: 5px;
    }
    span {
      font-size: 12px !important;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    label {
      font-size: 12px !important;
      padding-bottom: 5px;
    }
    span {
      font-size: 12px !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    label {
      font-size: 13px !important;
      padding-bottom: 5px;
    }
    span {
      font-size: 13px !important;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    label {
      font-size: 14px !important;
      padding-bottom: 5px;
    }
    span {
      font-size: 14px !important;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    label {
      font-size: 15px !important;
      padding-bottom: 5px;
    }
    span {
      font-size: 15px !important;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    label {
      font-size: 16px !important;
      padding-bottom: 5px;
    }
    span {
      font-size: 16px !important;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    label {
      font-size: 17px !important;
      padding-bottom: 5px;
    }
    span {
      font-size: 17px !important;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    label {
      font-size: 18px !important;
      padding-bottom: 5px;
    }
    span {
      font-size: 18px !important;
    }
  }
`;
const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #27292d !important;
  border-radius: 10px;
  padding: 25px 25px;
  .submit-button {
    &.button-wrapper .button {
      background-color: #306fdb;
      border-radius: 8px !important;
      border: 1px solid #306fdb;
      margin: 20px 0px;
      font-size: 13px;
      color: #ffffff;
      height: 30px;
    }
  }
  @media (min-width: 100px) and (max-width: 575) {
    padding: 25px 10px;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    padding: 25px 15px;
  }
  @media (min-width: 992px) and (max-width: 1365px) {
    padding: 25px 30px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    padding: 25px 40px;
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    padding: 25px50px;
  }
`;

const CloseIcon = styled.div`
  color: #5a5a5a;
  position: absolute;
  right: 4px;
  top: 3px;
  background: #262626;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid #5a5a5a;
  cursor: pointer;
`;
export default AddDocumentPopup;
