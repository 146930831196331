import styled from 'styled-components';
import { TextField } from 'app/components/TextField';
import '@fontsource/roboto';
import React from 'react';
import { useState } from 'react';
import { BackNextButton } from 'app/components/BackNextButton';
import SHDatePicker from 'app/components/SHDatePicker';
import SHdropdown from 'app/components/SHdropdown';
import { EWD, GeneralLookup, GeneralLookupWrapper } from 'services/openapi';
import SHYear from 'app/components/SHYear';
import SimpleReactValidator from 'simple-react-validator';
import { heightWeightModel } from 'app/pages/RiderRegistrationPage/components/NotReturningRider';
import SHBackNumberDropdown from 'app/components/SHBackNumberDropdown';
import { getLookupArray } from 'utils/common';

type Props = {
  onClickBackPersonal?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onClickNxtPersonal?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  riderData?: EWD | undefined;
  setRiderData: React.Dispatch<React.SetStateAction<EWD>>;
  stateData: GeneralLookup[] | undefined;
  lookUp: GeneralLookupWrapper[] | undefined;
  categoryCheck: GeneralLookup[] | undefined;
  setHeightWeight: React.Dispatch<
    React.SetStateAction<heightWeightModel | undefined>
  >;
  heightWeight: heightWeightModel | undefined;
  backNumberData: string[] | undefined;
  setCategoryCheck: React.Dispatch<
    React.SetStateAction<GeneralLookup[] | undefined>
  >;
};

const EWDPersonalInfoFieldsReturning = ({
  onClickNxtPersonal,
  onClickBackPersonal,
  riderData,
  setRiderData,
  stateData,
  lookUp,
  categoryCheck,
  setHeightWeight,
  heightWeight,
  backNumberData,
  setCategoryCheck,
}: Props) => {
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [valid, setValid] = useState<boolean>(false);

  const handleChange = event => {
    const { name, value } = event.target;
    setRiderData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const changeDate = event => {
    setRiderData(prevData => ({ ...prevData, dateOfBirth: event.value }));
  };
  const handleYearChange = e => {
    setRiderData(prevData => ({
      ...prevData,
      expectedGraduationYear: e,
    }));
  };
  const handleFeetChange = event => {
    const newFeet = parseInt(event.target.value);
    if (event.target.value && newFeet >= 0 && newFeet <= 9) {
      setHeightWeight(prevData => ({
        ...prevData!,
        feet: Math.floor(newFeet),
      }));
      if (riderData) {
        setRiderData({ ...riderData, height: newFeet });
      }
    } else if (!event.target.value) {
      setHeightWeight(prevData => ({ ...prevData!, feet: undefined }));
    }
  };

  const handleInchesChange = event => {
    const newInches = parseInt(event.target.value);
    if (event.target.value && newInches >= 0 && newInches <= 11) {
      setHeightWeight(prevData => ({
        ...prevData!,
        inches: Math.floor(newInches),
      }));
      const newHeightInch = heightWeight?.feet! + newInches / 12;
      if (riderData) {
        setRiderData({ ...riderData, height: newHeightInch });
      }
    } else if (!event.target.value) {
      setHeightWeight(prevData => ({
        ...prevData!,
        inches: undefined,
      }));
    }
  };
  const handleWeightChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 3 && /^[0-9]*$/.test(inputValue)) {
      if (riderData) {
        setRiderData({
          ...riderData,
          weight: inputValue,
        });
        setHeightWeight(prevData => ({
          ...prevData!,
          weight: inputValue,
        }));
      }
    }
  };

  const handleBackNumberChange = name => {
    if (riderData) {
      setRiderData?.({
        ...riderData,
        backNumber: name.value,
      });
    }
  };
  const handlePhChange = e => {
    const inputValue = e.target.value;
    if (riderData) {
      if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
        setRiderData({
          ...riderData,
          primaryPhone: inputValue,
        });
      }
    }
  };
  const handleZipChange = e => {
    const inputValue = e.target.value;
    if (riderData) {
      if (inputValue.length <= 9 && /^[a-zA-Z0-9]*$/.test(inputValue)) {
        setRiderData({
          ...riderData,
          zip: inputValue,
        });
      }
    }
  };
  const handleEmergencyPhChange = e => {
    const inputValue = e.target.value;
    if (riderData) {
      if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
        setRiderData({
          ...riderData,
          emergencyContactPhone: inputValue,
        });
      }
    }
  };
  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [riderData]);
  console.log(riderData, 'riderData');
  return (
    <MainWrapper>
      <OverflowWrapper>
        <TextFieldGrid>
          <TextFieldWrapper>
            <StyledTextField
              onChange={handleChange}
              value={riderData?.firstName}
              label="First Name"
              type="text"
              name="firstName"
              validations={'required|max:30'}
              validator={simpleValidator.current}
              placeholder="First Name"
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              onChange={handleChange}
              value={riderData?.lastName}
              label="Last Name"
              type="text"
              name="lastName"
              validations={'required|max:30'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <BackFieldWrapper className="BackFieldWrapper">
              <SHBackNumberDropdown
                onChange={e => handleBackNumberChange(e.value)}
                data={backNumberData}
                defaultValue={riderData?.backNumber!}
                label="Back Number (3 or 4 digits)"
                validations={'required|max:30'}
                validator={simpleValidator.current}
                isAbove={true}
              />
            </BackFieldWrapper>
          </TextFieldWrapper>
          <DropdownWrapper>
            <SHdropdown
              onDataChange={e => {
                setRiderData(prevData => ({
                  ...prevData,
                  team: e,
                }));
                const findAlumni = e?.children?.filter(item => {
                  return item.name === 'EWD';
                });
                setCategoryCheck(findAlumni);
              }}
              data={getLookupArray('ACTIVETEAM', lookUp!)}
              label="Team Name"
              defaultValue={riderData?.team!}
              className="drop-down"
              validations={'required'}
              validator={simpleValidator.current}
              categoryCheck={categoryCheck}
            />
          </DropdownWrapper>
          <SubWrapper>
            <GraduationDobWrapper>
              <StyledGraduationDropdownWrapper>
                <SHYear
                  className="drop-down"
                  label="Expected Graduation Year"
                  onChange={handleYearChange}
                  defaultValue={riderData?.expectedGraduationYear}
                  value={riderData?.expectedGraduationYear}
                  validations={'required'}
                  validator={simpleValidator.current}
                />
              </StyledGraduationDropdownWrapper>
              <StyledDatePickerWrapper>
                <StyledSHDatePicker
                  onChange={changeDate}
                  placeholder=""
                  value={
                    riderData?.dateOfBirth
                      ? new Date(riderData?.dateOfBirth!)
                      : undefined
                  }
                  name="dateOfBirth"
                  className="datepicker-style"
                  dateLabel="Date of Birth"
                  validations={'required'}
                  validator={simpleValidator.current}
                />
              </StyledDatePickerWrapper>
            </GraduationDobWrapper>
            <HeightInchWeightWrapper>
              <StyledHeightTextField
                onChange={handleFeetChange}
                value={heightWeight?.feet}
                label="Height(Ft)"
                type="number"
                name="height"
                placeholder="Feet"
                validations={'required'}
                validator={simpleValidator.current}
              />

              <StyledInchTextField
                onChange={handleInchesChange}
                value={heightWeight?.inches}
                label="Inches"
                type="number"
                name="inches"
                placeholder="Inches"
              />
              <SpanInch>eg. 5ft 10in</SpanInch>
              <StyledWeightTextField
                onChange={handleWeightChange}
                value={heightWeight?.weight}
                label="Weight"
                type="text"
                name="weight"
                placeholder="lbs"
                validations={'required'}
                validator={simpleValidator.current}
              />
            </HeightInchWeightWrapper>
          </SubWrapper>
          <TextFieldWrapper>
            <StyledTextField
              onChange={handlePhChange}
              value={riderData?.primaryPhone}
              label="Cell/Primary Ph#"
              type="tel"
              name="primaryPhone"
              placeholder=""
              validations={'required|numeric|min:10|max:10'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              onChange={handleChange}
              value={riderData?.email}
              label="Email"
              type="email"
              name="email"
              validations={'required|email'}
              validator={simpleValidator.current}
              maxLength={60}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
        <HomeAddressWrapper className="horizontal-line">
          <p className="home-address">Home mailing address</p>
        </HomeAddressWrapper>
        <TextFieldGrid>
          <TextFieldWrapper>
            <StyledTextField
              onChange={handleChange}
              value={riderData?.street}
              label="Street Address"
              type="text"
              name="street"
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              onChange={handleChange}
              value={riderData?.city}
              label="City"
              type="text"
              name="city"
              validations={'required|max:40'}
              validator={simpleValidator.current}
              maxLength={40}
            />
          </TextFieldWrapper>
          <DropdownWrapper>
            <SHdropdown
              className="drop-down"
              label="State"
              data={stateData}
              onDataChange={data => {
                if (riderData) {
                  setRiderData!({
                    ...riderData,
                    state: data,
                  });
                }
              }}
              defaultValue={riderData?.state}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </DropdownWrapper>
          <TextFieldWrapper>
            <StyledTextField
              onChange={handleZipChange}
              value={riderData?.zip}
              label="Zip"
              type="text"
              name="zip"
              validations={'required|alpha_num|min:5|max:9'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              label="Emergency Contact Name"
              type="text"
              name="emergencyContactName"
              onChange={e => {
                if (riderData) {
                  setRiderData({
                    ...riderData,
                    emergencyContactName: e.target.value,
                  });
                }
              }}
              value={riderData?.emergencyContactName ?? ''}
              validations={'required|max:30'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              label="Emergency Contact Number"
              type="tel"
              name="emergencyContactNumber"
              onChange={handleEmergencyPhChange}
              value={riderData?.emergencyContactPhone ?? ''}
              validations={'required|numeric|min:10|max:10'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
        <ButtonWrapper className="bg-color-change">
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={onClickNxtPersonal}
            onClickBack={onClickBackPersonal}
            next="Next"
            back="Cancel"
            disableNext={valid || categoryCheck?.length === 0}
          />
        </ButtonWrapper>
      </OverflowWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  color: #9597a6;
  width: 100%;
  height: calc(100vh - 261px);
  padding: 20px 0px 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    label {
      font-size: 14px;
    }
  }
`;
const SubWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  width: 100%;
  padding-top: 15px;
  @media (min-width: 992px) and (max-width: 1199px) {
    display: block;
  }
  @media (min-width: 100px) and (max-width: 991px) {
    display: block;
  }
`;
const OverflowWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  padding-bottom: 100px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
`;
const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  ::placeholder {
    color: #8f8e8e !important;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    display: block;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    display: block;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    display: block;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    display: block;
  }
`;
const HomeAddressWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  width: 100%;

  ::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #585858;
  }

  p {
    margin: 0 10px 0px 0px;
    white-space: nowrap;
  }
`;
const TextFieldWrapper = styled.div`
  flex: 0 0 calc(50% - 20px) !important;
  margin: 0px 0px 10px !important;
`;
const SpanInch = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-size: 12px;
`;
const BackFieldWrapper = styled.div`
  width: 80%;
  .SHdropdown {
    margin: 0px !important;
  }
  .BackNumber {
    flex: 0 0 65% !important;
  }
  .StyledTextField {
    flex: 0 0 calc(70% - 20px);
  }
  .button-wrapper.third,
  .info-icon {
    margin: 0 0 0 10px !important;
  }
`;
const StyledTextField = styled(TextField)`
  flex: 0 0 calc(50% - 20px);
  margin: 0px 0px 10px !important;
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const GraduationDobWrapper = styled.div`
  margin: 0px 0px 10px !important;
  display: flex;
  align-items: end;
  gap: 10px;
  flex: 0 0 calc(50% - 20px);
  .k-input:not(:-webkit-autofill) {
    animation-name: unset !important;
  }
  .k-input-solid {
    width: 100% !important;
    height: 35px;
  }
  &.datepicker-style {
    display: flex;
    align-items: end;
  }
  .date {
    display: flex;
    flex-direction: column;
    p {
      margin: 0px;
    }
  }
  @media (min-width: 100px) and (max-width: 992px) {
    display: block;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    gap: 40px;
  }
`;
const StyledGraduationDropdownWrapper = styled.div`
  padding-top: 2px;
  display: flex;
  align-items: end;
  width: 50%;

  button {
    align-items: center;
    justify-content: end;
  }
  .drop-down {
    margin: 0px;
    width: 100%;
  }
  .drop-down > div {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    width: 100%;
  }
  @media (min-width: 100px) and (max-width: 992px) {
    width: 100%;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 100%;
  }
`;
const StyledDatePickerWrapper = styled.div`
  width: 50%;
  @media (min-width: 100px) and (max-width: 992px) {
    width: 100%;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 100%;
  }
`;
const StyledSHDatePicker = styled(SHDatePicker)`
  display: flex;
  align-items: end;
  width: 30% !important;

  input {
    width: 100%;
    height: 35px;
  }
  input {
    width: 100%;
    height: 35px;
  }
  span {
    border-color: #434343;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 100%;
  }
`;
const HeightInchWeightWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex: 0 0 calc(50% - 20px);
  span {
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
  }
`;
const StyledWeightTextField = styled(TextField)`
  margin: 0px 0px 10px !important;
  width: 27%;
  input {
    width: 100%;
    height: 35px;
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const StyledInchTextField = styled(TextField)`
  margin: 0px 0px 10px !important;
  width: 27%;
  input {
    width: 100%;
    height: 35px;
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const StyledHeightTextField = styled(TextField)`
  margin: 0px 0px 10px !important;
  width: 27%;
  input {
    width: 100%;
    height: 35px;
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const DropdownWrapper = styled.div`
  flex: 0 0 calc(50% - 20px);
  p {
    margin: 0px;
    font-family: 'Roboto';
    font-size: 14px;
  }

  .SHdropdown {
    margin: 0px;
    color: #9597a6;
    width: 100%;
    height: 35px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    padding-bottom: 25px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding-bottom: 25px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-bottom: 25px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-bottom: 25px;
  }
`;
const ButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;
  z-index: 2;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: -3px;
    left: -1px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    backdrop-filter: blur(5px);
  }
  @media (min-width: 100px) and (max-width: 767px) {
    height: 50px;
    &.bg-color-change {
      bottom: -16px;
    }
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    height: 55px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;
export default EWDPersonalInfoFieldsReturning;
