import React from 'react';
import styled from 'styled-components';
import arrow from './assets/img/icon/arrow.png';

type Props = {
  userType?: string;
};

export const CoachIsuranceInfoOne = ({ userType }: Props) => {
  return (
    <MainWrapper>
      <p className="para-one">
        <i>Information</i>
      </p>
      <p className="para-two">
        This Coach Liability policy will cover you for your activities as a
        Coach for Youth Equestrian Development Association only. This policy
        does not cover you as an employee of the Youth Equestrian Development
        Association OR for private lessons where you have a private training
        agreement. YEDA is the named insured on this policy. Each coach will
        have Liability Limits of $1,000,000 per occurrence/$1,000,000 aggregate.
      </p>
      <p className="para-two">
        Coverage will be effective only after receiving a completed application
        and premium payment validated. The effective date of coverage will be no
        earlier than 8-15-22 or the date received if after 8-15-22. All coverage
        will expire on 8-15-23.
      </p>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  width: 100%;
  border: 1px solid #585858;
  border-radius: 7px;
  margin: 30px 0px 20px;
  padding: 20px;
  p {
    font-family: 'Roboto';
  }
  .para-one {
    color: #ff9900;
    font-size: 16px;
    border-bottom: 1px solid #585858;
  }
  .para-two {
    padding-bottom: 10px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .para-one {
      font-size: 13px !important;
    }
    p {
      font-size: 11px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .para-one {
      font-size: 13px !important;
    }
    p {
      font-size: 11px !important;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .para-one {
      font-size: 14px !important;
    }
    p {
      font-size: 12px !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .para-one {
      font-size: 15px !important;
    }
    p {
      font-size: 13px !important;
    }
  }
`;

const MappedDataWrapper = styled.div`
  display: flex;
  align-items: baseline;
  li {
    padding-left: 10px;
    list-style-type: none;
    padding-bottom: 10px;
    font-family: 'Roboto';
    font-size: 14px;
  }
  img {
    width: 11px;
    height: 11px;
  }
`;
