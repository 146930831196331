import React from 'react';
import styled from 'styled-components';
import { InfoLabel } from '../SHLabel';
// import arrow from './assets/img/icon/arrow.png';

type Props = {
  userType?: string;
};
const data = [
  { id: 1, text: 'Know the current YEDA rules and abide by them.' },
  {
    id: 2,
    text: 'Put the emotional and physical wellbeing of All YEDA riders, staff, and coaches ahead of your personal desire to win.',
  },
  {
    id: 3,
    text: ' Respect all YEDA staff and officials and refrain from questioning them in a disrespectful or abusive manner.',
  },
  {
    id: 4,
    text: 'Maintain control over your emotions and avoid language, actions, or gestures that could be interpreted as disrespectful, demeaning, hostile, or humiliating.',
  },
  {
    id: 5,
    text: 'Set the example of being humble and generous when winning, and proud and courteous in defeat.',
  },
  {
    id: 6,
    text: 'Ensure that all riders, parents, and other personal associated with your team always demonstrate good sportsmanship and maturity.',
  },
];
export const QualifyingInformation = ({ userType }: Props) => {
  return (
    <MainWrapper className="QualifyingInformationInfo">
      <InfoLabelWrapper className="InfoLabelWrapper">
        <div>
          <InfoLabel>QUALIFYING INFORMATION</InfoLabel>
        </div>
        <div className="border-bottom">
          <InfoLabel>
            ALL CLASS DESCRIPTIONS REFER TO THE GUIDELINES SPECIFIED IN RULE
            R36.
          </InfoLabel>
        </div>
      </InfoLabelWrapper>

      <p className="para-two">
        EVERY YEAR, A NEW PLACEMENT/MEMBERSHIP FORM MUST BE FILLED OUT
        COMPLETELY. RETURNING MEMBERS, PLEASE INDICATE WHAT DIVISION YOU SHOWED
        IN LAST SEASON
      </p>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  width: 100%;
  background: #27292d;
  border: 1px solid #585858;
  border-radius: 7px;
  margin: 30px 0px 20px;
  padding: 20px;
  p {
    font-family: 'Roboto';
  }
  .para-two {
    padding: 0 20px 10px 20px;
    color: #a7a7a7;
    font-size: 16px;
  }
  // Responsive
  &.QualifyingInformationInfo {
    @media (min-width: 100px) and (max-width: 319px) {
      .InfoLabelWrapper {
        padding: 0px 0px 10px 0px !important;
        label {
          font-size: 10px !important;
        }
      }
      .para-two {
        padding: 0 0px 10px 0px;
        font-size: 9px;
      }
    }
    @media (min-width: 320px) and (max-width: 575px) {
      .InfoLabelWrapper {
        padding: 0px 0px 10px 0px !important;
        label {
          font-size: 10px !important;
        }
      }
      .para-two {
        padding: 0 0px 10px 0px;
        font-size: 9px;
      }
    }
    @media (min-width: 576px) and (max-width: 767px) {
    }
    @media (min-width: 768px) and (max-width: 991px) {
      //height: 35px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      //height: 40px;
    }
    @media (min-width: 1200px) and (max-width: 1365px) {
      //height: 45px;
    }
    @media (min-width: 1366px) and (max-width: 1448px) {
      //height: 50px;
    }
    @media (min-width: 1449px) and (max-width: 1719px) {
      //height: 60px;
    }
    @media (min-width: 1720px) and (max-width: 4000px) {
      //height: 70px;
    }
  }
`;
const InfoLabelWrapper = styled.div`
  padding: 0 20px 10px 20px;
  text-align: start;
  width: 100%;
  div {
    /* padding: 6px 0; */
    &.border-bottom {
      border-bottom: 1px solid #434343;
    }
  }
`;
