import { GridLayoutItem } from '@progress/kendo-react-layout';
import { CustomgridLayoutWrapper } from 'admin/components/wrappers';
import AdminDropDown from 'app/components/AdminDropDown';
import { AdminTextField } from 'app/components/AdminTextField';
import React from 'react';
import styled from 'styled-components';
import { AdminRiderRegistration, GeneralLookupWrapper } from 'services/openapi';
import { getLookupArray, isoString } from 'utils/common';
import { AdminDatePicker } from 'app/components/AdminDatePicker';
import SimpleReactValidator from 'simple-react-validator';

type Props = {
  riderData: AdminRiderRegistration;
  setRiderdata: React.Dispatch<React.SetStateAction<AdminRiderRegistration>>;
  handleChange: (event: any) => void;
  lookUps: GeneralLookupWrapper[];
  simpleValidator: React.MutableRefObject<SimpleReactValidator>;
  action: string | undefined;
  riderDataLoading: boolean;
};

const RiderInformationFrom = ({
  riderData,
  handleChange,
  setRiderdata,
  lookUps,
  simpleValidator,
  action,
  riderDataLoading,
}: Props) => {
  return (
    <MainWrapper>
      <GridWrapper>
        <CustomgridLayoutWrapper
          rows={[{ height: 'auto' }]}
          cols={[{ width: '31.8%' }, { width: '33%' }, { width: '33%' }]}
          gap={{ rows: 20, cols: 10 }}
        >
          <GridLayoutItem>
            <AdminTextField
              label="Rider Phone"
              placeholder=""
              value={riderData?.primaryPhone}
              onChange={e => {
                const inputValue = e.target.value;
                if (inputValue.length <= 15 && /^\d*$/.test(inputValue)) {
                  setRiderdata?.({
                    ...riderData,
                    primaryPhone: inputValue,
                  });
                }
              }}
              name="primaryPhone"
              validations="required"
              validator={simpleValidator.current}
              className="field-style"
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminTextField
              label="Rider Email"
              placeholder=""
              value={riderData?.email}
              onChange={handleChange}
              name="email"
              validations={'required|email|max:100'}
              validator={simpleValidator.current}
              className="field-style"
              disabled={action === 'edit'}
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminDatePicker
              title="SIF Join Date "
              placeholder="day/month/year"
              value={
                riderData?.sifJoinedDate
                  ? new Date(riderData.sifJoinedDate)
                  : undefined
              }
              onChange={e =>
                setRiderdata({
                  ...riderData,
                  sifJoinedDate: isoString(e.value),
                })
              }
              className="field-style"
              validations="required"
              validator={simpleValidator.current}
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminTextField
              label="SIF Amt. Paid / Year"
              placeholder=""
              value={
                riderData?.sifAmountPaid === null
                  ? ''
                  : riderData?.sifAmountPaid
              }
              onChange={e => {
                const inputValue = e.target.value;
                if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
                  setRiderdata?.({
                    ...riderData,
                    sifAmountPaid:
                      inputValue === '' ? null : parseFloat(inputValue),
                  });
                }
              }}
              name="sifAmountPaid"
              className="field-style"
              hideStar
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminTextField
              label="SIF Points"
              placeholder=""
              value={riderData?.sifPoint === null ? '' : riderData?.sifPoint}
              onChange={e => {
                const inputValue = e.target.value;
                if (
                  inputValue === '' ||
                  /^\d{0,3}(\.\d{0,3})?$/.test(inputValue)
                ) {
                  setRiderdata?.({
                    ...riderData,
                    sifPoint: Number(inputValue),
                  });
                }
              }}
              name="sifPoints"
              className="field-style"
              hideStar
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminTextField
              label="Rider Address"
              placeholder=""
              value={riderData?.street}
              onChange={handleChange}
              name="street"
              validations="required|max:300"
              validator={simpleValidator.current}
              className="field-style"
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminTextField
              label="City"
              placeholder=""
              value={riderData?.city}
              onChange={handleChange}
              name="city"
              validations="required|max:100"
              validator={simpleValidator.current}
              className="field-style"
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminDropDown
              label="State "
              placeholder=""
              data={getLookupArray('STATE', lookUps)}
              onDataChange={data => {
                setRiderdata({
                  ...riderData,
                  state: data,
                });
              }}
              defaultValue={riderData?.state ?? undefined}
              validations="required|max:100"
              validator={simpleValidator.current}
              className="field-style"
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminTextField
              label="Zip"
              placeholder=""
              value={riderData?.zip}
              onChange={handleChange}
              name="zip"
              validations={'required|max:10'}
              validator={simpleValidator.current}
              className="field-style"
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
        </CustomgridLayoutWrapper>
      </GridWrapper>
    </MainWrapper>
  );
};

export default RiderInformationFrom;
const GridWrapper = styled.div`
  margin-bottom: 20px;
  @media (min-width: 1899px) and (max-width: 4000px) {
    .k-grid-layout {
      gap: 20px 15px !important;
      grid-template-columns: 30% 33% 33% !important;
    }
  }
  @media (max-width: 991px) {
    .k-grid-layout {
      grid-template-columns: 100% !important;
    }
  }
`;
const MainWrapper = styled.div`
  margin-bottom: 20px;
`;
