/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Login } from '../models/Login';
import type { LoginUser } from '../models/LoginUser';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * API to login a user with username and password
     * @param requestBody
     * @returns LoginUser Success
     * @throws ApiError
     */
    public static authLogin(
        requestBody?: Login,
    ): CancelablePromise<LoginUser> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param formData
     * @returns boolean Success
     * @throws ApiError
     */
    public static authForgotPassword(
        formData?: {
            Email: string;
        },
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/forgotPassword',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @param formData
     * @returns boolean Success
     * @throws ApiError
     */
    public static authResetPassword(
        formData?: {
            Email: string;
            Password: string;
            Token: string;
        },
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/resetPassword',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
            },
        });
    }

}
