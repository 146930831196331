import { useEffect, useState } from 'react';
import styled from 'styled-components';
import '@fontsource/oswald';
import horseBg from './assets/img/bg/nonCompetingReturning.jpg';
import '@fontsource/roboto';
import { NonCompetingFormFields } from './components/NonCompetingFormFields';
import {
  GeneralLookup,
  LookupService,
  NonCompeting,
  RiderService,
} from 'services/openapi';
import useApi from 'services/mavapi/useApi';
import { useNavigate } from 'react-router-dom';
import { BackNextButton } from 'app/components/BackNextButton';
import { ShowyedaLogo } from 'app/components/ShowyedaLogo';
import Cookies from 'js-cookie';
import LoaderWrapper from 'app/components/CircularLoader/LoaderWrapper';
import CircularLoader from 'app/components/CircularLoader';
import { useManagePageSlice } from '../slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectPageLoader } from 'app/pages/slice/selectors';
import { useErrorHandlingSlice } from 'app/error/slice';

type Props = {};
const defaultValue: NonCompeting = {
  firstName: '',
  lastName: '',
  primaryPhone: '',
  email: '',
  street: '',
  city: '',
  state: {
    id: '',
    name: '',
    children: null,
  },
  zip: '',
  emergencyContactName: '',
  emergencyContactPhone: '',
  isReturningRider: false,
};

export const NonCompetingMembershipPage = (props: Props) => {
  const { error: apiError, handleRequest } = useApi();
  const { actions } = useErrorHandlingSlice();
  const { riderCreateNonCompeting } = RiderService;
  const { lookupGetAllLookups } = LookupService;
  const [nonCompetingpayload, setNonCompetingpayload] =
    useState<NonCompeting>(defaultValue);
  const [stateData, setStateData] = useState<GeneralLookup[]>([]);
  const [isTermsChecked, setIsTermsChecked] = useState<boolean>(false);
  const navigate = useNavigate();
  const [valid, setValid] = useState<boolean>(false);
  const [cancelPaymentCookie, setCancelPaymentCookie] = useState();
  const { actions: PageActions } = useManagePageSlice();
  const pageLoader = useSelector(selectPageLoader);
  const dispatch = useDispatch();

  const handleBack = () => {
    Cookies.remove('payload');
    Cookies.remove('paymentCancel');
    navigate(-1);
  };
  const [payerTypes, setPayerTypes] = useState<GeneralLookup>();
  const handleGetLookups = () => {
    dispatch(PageActions.setPageLoader(true));
    handleRequest(lookupGetAllLookups('STATE,PAYER_TYPES'))
      .then(res => {
        let varPayerTypes = res?.filter(item => {
          if (item?.type === 'PAYER_TYPES') {
            return item.values;
          }
        });
        let payerTypeObj = varPayerTypes?.[0]?.values?.find(item => {
          if (item?.name === 'Rider') {
            return item ?? {};
          }
        });

        setPayerTypes(payerTypeObj);
        let varState = res?.filter(item => {
          if (item?.type === 'STATE') {
            return item.values;
          }
        });

        setStateData(varState?.[0]?.values!);

        dispatch(PageActions.setPageLoader(false));
      })

      .catch(error => {
        dispatch(PageActions.setPageLoader(false));
      });
  };
  const handleSubmit = () => {
    dispatch(PageActions.setPageLoader(true));
    handleRequest(riderCreateNonCompeting(nonCompetingpayload))
      .then(res => {
        if (res && !apiError) {
          if (cancelPaymentCookie) {
            Cookies.remove('paymentCancel');
          }
          const nonCompetingDataString = JSON.stringify(nonCompetingpayload);
          Cookies.set('payload', nonCompetingDataString);
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Submitted successfully',
              show: true,
              errorType: 'success',
            }),
          );
          navigate(`/payment/${payerTypes?.id}/${res}/false/false`);
        } else {
          dispatch(
            actions.dispatchToast({
              errorTxt: 'User already exits',
              show: true,
              errorType: 'error',
            }),
          );
        }
        dispatch(PageActions.setPageLoader(false));
      })
      .catch(error => {
        dispatch(PageActions.setPageLoader(false));
      });
  };
  useEffect(() => {
    handleGetLookups();
    const paymentCancelStatus = Cookies.get('paymentCancel');
    setCancelPaymentCookie(paymentCancelStatus);
  }, []);
  useEffect(() => {
    const nonCompetingData = Cookies.get('payload');
    if (nonCompetingData) {
      const nonCompetingDataParse = JSON.parse(nonCompetingData);
      setNonCompetingpayload(nonCompetingDataParse);
      setIsTermsChecked(!isTermsChecked);
    }
  }, [cancelPaymentCookie]);

  return (
    <>
      {pageLoader ? (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      ) : (
        <MainWrapper className="MainWrapper">
          <LeftWrapper className="LeftWrapper">
            <ShowyedaLogo />
            <div className="title-left-block">
              <h4>Examples of Non-Competing Membership:</h4>
              <p>
                Seniors who are not showing but applying for membership to be
                eligible for the Scholarship Incentive Fund (SIF).
              </p>
            </div>
          </LeftWrapper>
          <RightWrapper className="RightWrapper">
            <BorderWrapper className="BorderWrapper">
              <TopWrapper className="TopWrapper">
                <h3 className="toplabel-header">
                  YEDA Non-Competing Membership Form
                  <br /> 2023-2024
                </h3>
              </TopWrapper>
              <p className="Team-Heading">Returning Member</p>
              <FirstParaWrapper className="FirstParaWrapper">
                <p>
                  <i>
                    Payment of $70 fee and must be accompanied with this form
                  </i>
                </p>
              </FirstParaWrapper>
              <NonCompetingFormFields
                stateData={stateData}
                nonCompetingpayload={nonCompetingpayload}
                setNonCompetingpayload={setNonCompetingpayload}
                setValid={setValid}
                valid={valid}
                setIsTermsChecked={setIsTermsChecked}
                isTermsChecked={isTermsChecked}
              />

              <ButtonWrapper className="bg-color-change">
                <BackNextButton
                  classNameBack="btn-back"
                  classNameNxt="btn-nxt"
                  onClickNxt={handleSubmit}
                  onClickBack={handleBack}
                  disableNext={valid || isTermsChecked}
                  next="Submit"
                  back="Cancel"
                />
              </ButtonWrapper>
            </BorderWrapper>
          </RightWrapper>
        </MainWrapper>
      )}
    </>
  );
};

const LeftWrapper = styled.div`
  width: 36%;
  height: 100%;
  background: url(${horseBg});
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-position: right;
  padding: 20px 35px 0px;

  .title-left-block {
    background: black;
    opacity: 0.7;
    padding: 0 20px;
    position: absolute;
    top: 30%;
    width: 100%;
    @media (min-width: 100px) and (max-width: 319px) {
      h4,
      p {
        font-size: 11px !important;
      }
    }
    @media (min-width: 320px) and (max-width: 575px) {
      h4,
      p {
        font-size: 12px !important;
      }
    }
    @media (min-width: 576px) and (max-width: 767px) {
      h4,
      p {
        font-size: 13px !important;
      }
    }
    @media (min-width: 768px) and (max-width: 991px) {
      h4,
      p {
        font-size: 14px !important;
      }
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      h4,
      p {
        font-size: 14px !important;
      }
    }
    @media (min-width: 1200px) and (max-width: 1365px) {
      h4,
      p {
        font-size: 15px;
      }
    }
    @media (min-width: 1366px) and (max-width: 1448px) {
      h4,
      p {
        font-size: 16px;
      }
    }
    @media (min-width: 1449px) and (max-width: 1719px) {
      h4,
      p {
        font-size: 17px;
      }
    }
    @media (min-width: 1720px) and (max-width: 4000px) {
      h4,
      p {
        font-size: 19px;
      }
    }
  }
  h4,
  p {
    color: #898989;
    font-family: 'Oswald';
    font-size: 14px;
    padding: 0px 20px;
  }
`;

const RightWrapper = styled.div`
  width: 64%;
  height: 100%;
  background: #2a2a2a;
  padding: 30px 35px 20px;
  .Team-Heading {
    padding-top: 20px;
    text-align: center;
    color: #9597a6;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
  }
`;
const FirstParaWrapper = styled.p`
  padding: 20px 10px 0 10px;
  width: 100%;
  p {
    color: #ff9900;
    font-family: 'Roboto';
    font-size: 12px;
    padding: 20px 0;
    border-bottom: 1px solid #585858;
  }
`;
const BorderWrapper = styled.div`
  height: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px 20px 0px 20px;
  margin: 0 auto;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  border-radius: 0px 0px 20px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0px 10px;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    padding-top: 30px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
  }
`;

const TopWrapper = styled.div`
  background: #2a2a2a;
  padding: 0px 10px;
  position: absolute;
  top: -12px;
  h3 {
    font-weight: 500;
    color: #ffffff;
    margin: 0px !important;
    font-family: 'Roboto';
    text-align: center;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    h3 {
      font-size: 16px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    h3 {
      font-size: 17px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    h3 {
      font-size: 18px;
    }
  }
`;
const MainWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  .TermsWrapper {
    & > p {
      margin: 9px 0 0;
    }
  }
  //Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    ${LeftWrapper} {
      display: none;
    }
    .TopWrapper {
      top: -8px;
      .toplabel-header {
        font-size: 12px;
      }
    }
    ${RightWrapper} {
      width: 100%;
      padding: 30px 15px 20px 15px;
    }
    .BorderWrapper {
      padding: 20px 20px 10px 20px;
    }
    .FirstParaWrapper {
      padding: 0px;
    }
    .TermsWrapper {
      padding-bottom: 0px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    ${LeftWrapper} {
      display: none;
    }
    ${RightWrapper} {
      width: 100%;
      padding: 30px 15px 20px 15px;
    }
    .TopWrapper {
      top: -10px;
      .toplabel-header {
        font-size: 14px;
      }
    }
    .BorderWrapper {
      padding: 20px 20px 10px 20px;
    }
    .FirstParaWrapper {
      padding: 0px;
    }
    .TermsWrapper {
      padding-bottom: 0px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    ${LeftWrapper} {
      min-width: 200px !important;
      .left-header-block {
        p {
          font-size: 14px;
        }
      }
    }
    ${RightWrapper} {
      width: calc(100% - 200px) !important;
      padding: 30px 15px 20px 15px;
    }

    .TopWrapper {
      top: -10px;
      .toplabel-header {
        font-size: 14px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
  @media (min-width: 992px) and (max-width: 1199px) {
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
  }
`;
