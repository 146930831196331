import styled from 'styled-components';

type Props = {
  backNo?: any;
  riderName?: any;
};

export const LeaderPointCell = ({ backNo, riderName }: Props) => {
  return (
    <CustomCellWrapper className={`${backNo ? 'has-value' : ''}`}>
      <div className="label-wrapper">{backNo ? backNo : ''}</div>
      <div className="label-wrapper">{riderName ? riderName : '--'}</div>
    </CustomCellWrapper>
  );
};
const CustomCellWrapper = styled.td`
  align-items: center;
  background-color: #f6f6f6 !important;
  .label-wrapper {
    display: flex;
    justify-content: center;
  }
  &.has-value {
    background-color: #ffffff !important;
    .label-wrapper {
      display: flex;
      justify-content: center;
      font-weight: 600;
    }
  }
`;
