import { CommonPageWrapper, HeadingWrapper } from 'admin/components/wrappers';
import AdminCustomButton from 'app/components/AdminCustomButton';
import { AdminSearch } from 'app/components/AdminSearchField';
import CustomBreadCrumb from 'app/components/CustomBreadCrumb';
import { BlurredLabel, PageMainLabel } from 'app/components/SHLabel';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import RidersTable from './RidersTable';
import useRiderApi from 'admin/hooks/useRiderApi';
import { useDebounce } from 'hooks/useDebounce';
import useCommonLookUpDatas from 'hooks/useCommonLookupData';
import { getLookupArray, useNavigateToRoute } from 'utils/common';
import AdminMultiSelectDropDown from 'admin/components/AdminMultiSelectDropdown';
import { RiderFilteredData } from 'admin/components/AdminMultiSelectDropdown/FilteredList';
import { useLocation } from 'react-router-dom';
import AdminDropDown from 'app/components/AdminDropDown';
import { GeneralLookup } from 'services/openapi';
import React from 'react';

const RidersPage = () => {
  const currentLocation = useLocation();
  const isPastDataFlag = currentLocation.pathname.includes('past-data');
  const breadCrumbItems = [
    {
      id: '',
      text: 'Showyeda admin',
      disabled: true,
    },
    {
      id: '',
      text: isPastDataFlag ? 'Past-Data' : 'Riders',
      disabled: true,
    },
  ];

  if (isPastDataFlag) {
    breadCrumbItems.push({
      id: '',
      text: 'Riders',
      disabled: true,
    });
  }
  const {
    fetchActiveRiderList,
    activeRiderList,
    deleteRiderInfo,
    fetchRiderActiveOrInactive,
    loading,
  } = useRiderApi();
  const { getAllLookupData, lookUps } = useCommonLookUpDatas();
  const redirect = useNavigateToRoute();
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [active, setActive] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pageSkip, setPageSkip] = useState<number | undefined>(0);
  const [lookupData, setLookupData] = useState('');
  const [teamIds, setTeamIds] = useState('');
  const [divisionIds, setDivisionIds] = useState('');
  const debounceRiderSearch = useDebounce(searchTerm);
  const [preDataList, setPreDataList] =
    useState<{ name: string; id: string; type: string }[]>();
  const handleItemIdClick = (itemId: string) => {
    setSelectedItemId(itemId === selectedItemId ? null : itemId);
  };
  const isTeam = preDataList?.find(item => item.type === 'TEAM');
  const isDivision = preDataList?.find(item => item.type === 'DIVISION');
  const teamIdsArray = teamIds ? teamIds.split(',') : [];
  const divisionIdsArray = divisionIds ? divisionIds.split(',') : [];
  const teamId = isTeam ? teamIdsArray : [];
  const divisionId = isDivision ? divisionIdsArray : [];
  const [seasonPayload, setSeasonPayload] = React.useState<GeneralLookup>({
    id: '',
    name: '',
  });
  const [sortField, setSortField] = useState<string>('name');
  const [sortOrder, setSortOrder] = useState<number>(0);
  const handleSearch = e => {
    setSearchTerm(e.target.value);
  };
  const handleDropdownChange = (data: GeneralLookup) => {
    setSeasonPayload(data);
    fetchActiveRiderList(
      data?.id,
      teamId,
      divisionId,
      debounceRiderSearch,
      selectedItemId ?? '',
      sortField,
      0,
      0,
      10,
    );
  };
  useEffect(() => {
    if (debounceRiderSearch.length > 0) {
      fetchActiveRiderList(
        '',
        teamId,
        divisionId,
        debounceRiderSearch,
        selectedItemId ?? '',
        sortField,
        0,
        0,
        10,
      );
    } else if (
      (debounceRiderSearch.length === 0 && selectedItemId) ||
      selectedItemId === null ||
      (teamId.length === 0 && divisionId.length === 0)
    ) {
      fetchActiveRiderList(
        '',
        teamId,
        divisionId,
        debounceRiderSearch,
        selectedItemId ?? '',
        sortField,
        0,
        0,
        10,
      );
    } else {
      fetchActiveRiderList(
        '',
        teamId,
        divisionId,
        debounceRiderSearch,
        selectedItemId ?? '',
        sortField,
        0,
        pageSkip,
        10,
      );
    }
  }, [
    debounceRiderSearch,
    selectedItemId,
    divisionIds,
    teamIds,
    isTeam,
    isDivision,
  ]);
  useEffect(() => {
    if (pageSkip !== 0) {
      if (
        debounceRiderSearch?.length! >= 0 ||
        selectedItemId ||
        teamId.length === 0 ||
        divisionId.length === 0
      ) {
        setPageSkip(0);
      }
    }
  }, [debounceRiderSearch, selectedItemId, divisionIds, teamIds]);
  useEffect(() => {
    getAllLookupData(`ACTIVESTATUS,DIVISION,TEAM,SEASON`);
  }, []);
  return (
    <>
      <CommonPageWrapper>
        <HeadingWrapper>
          <PageMainLabel>Riders</PageMainLabel>
          <CustomBreadCrumb preData={breadCrumbItems} />
        </HeadingWrapper>
        <HeadingWrapper>
          <ButtonWrapper>
            {!isPastDataFlag && (
              <AdminCustomButton
                buttonType="primary"
                isActive={false}
                className="fill-button"
                onClick={() => {
                  setActive(!active);
                  redirect(`/admin/riders/create`);
                }}
              >
                New Rider
              </AdminCustomButton>
            )}
            <BlurredLabel style={{ whiteSpace: 'nowrap' }}>
              Popular filter
            </BlurredLabel>
            {getLookupArray('ACTIVESTATUS', lookUps).map(item => (
              <AdminCustomButton
                key={item.id}
                buttonType="primary"
                isActive={false}
                className={
                  selectedItemId?.includes(item?.id)
                    ? 'active-inactive'
                    : 'Inactive-button'
                }
                onClick={() => {
                  handleItemIdClick(item?.id);
                }}
              >
                {item?.name}
              </AdminCustomButton>
            ))}
            <AdminMultiSelectDropDown
              data={RiderFilteredData}
              setLookupData={setLookupData}
              filteredData={getLookupArray(lookupData, lookUps)}
              lookUps={lookUps}
              setTeamIds={setTeamIds}
              lookupData={lookupData}
              setDivisionIds={setDivisionIds}
              setPreDataList={setPreDataList}
              preDataList={preDataList}
            />
            {isPastDataFlag && (
              <div>
                <CustomDropDown
                  label=""
                  placeholder="Select season"
                  onDataChange={data => handleDropdownChange(data)}
                  defaultValue={seasonPayload}
                  data={getLookupArray('SEASON', lookUps)}
                  className="field-style filter-dropdown-select"
                  popupStyles="medium-dropdown"
                />
              </div>
            )}
          </ButtonWrapper>
          <div style={{ width: '283px', paddingTop: '30px' }}>
            <AdminSearch
              placeholder="Search by name or back number"
              onSearchTextEntering={handleSearch}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </div>
        </HeadingWrapper>
        {/* Riders Table */}
        <RidersTable
          activeRiderList={activeRiderList}
          setPageSkip={setPageSkip}
          pageSkip={pageSkip}
          fetchActiveRiderList={fetchActiveRiderList}
          debounceRiderSearch={debounceRiderSearch}
          deleteRiderInfo={deleteRiderInfo}
          selectedItemId={selectedItemId}
          fetchRiderActiveOrInactive={fetchRiderActiveOrInactive}
          lookup={getLookupArray('ACTIVESTATUS', lookUps)}
          teamId={teamId}
          divisionId={divisionId}
          sortField={sortField}
          sortOrder={sortOrder}
          setSortField={setSortField}
          setSortOrder={setSortOrder}
          loading={loading}
        />
      </CommonPageWrapper>
    </>
  );
};

export default RidersPage;
const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding: 30px 0 0;
  align-items: center;
  .field-style {
    display: flex;
  }
`;
const CustomDropDown = styled(AdminDropDown)`
  width: 160px;
  .k-dropdownlist {
    max-height: 31px;
    border-radius: 8px !important;
  }
  .k-input-inner {
    padding: 0 5px !important;
  }
  .k-input-value-text {
    margin: 2px 4px 2px 4px;
    font-size: 12px !important;
    letter-spacing: 0px;
    color: #1e1e1e;
    text-align: left;
  }
`;
