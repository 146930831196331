import { GridCellProps } from '@progress/kendo-react-grid';
import { AdminProfileInfo } from 'app/components/AdminProfileInfo';

type Props = {
  className?: string;
  e: GridCellProps;
  onClick?: (e) => void;
};

const RiderProfileCell = ({ className, e, onClick }: Props) => {
  const data = e.dataItem.rider;
  return (
    <td className={className ?? ''}>
      <AdminProfileInfo src={data.imageUrl} name={data.name} subLabel="" />
    </td>
  );
};

export default RiderProfileCell;
