import React from 'react';
import styled from 'styled-components';
import Logo from './assets/img/logo/logo.png';

type Props = { className?: string };

export const ShowyedaLogo = ({ className }: Props) => {
  return (
    <ImgWrapper className={`${className} logo`}>
      <img src={Logo} alt=" horse" />
    </ImgWrapper>
  );
};
const ImgWrapper = styled.div`
  padding: 15px 0px;
  img {
    width: 130px;
  }
  @media (min-width: 100px) and (max-width: 575px) {
  }
  @media (min-width: 576px) and (max-width: 767px) {
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
  @media (min-width: 992px) and (max-width: 1199px) {
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    img {
      width: 130px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    img {
      width: 160px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    img {
      width: 160px;
    }
  }
`;
