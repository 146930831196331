import { CommonPageWrapper, HeadingWrapper } from 'admin/components/wrappers';
import CustomBreadCrumb from 'app/components/CustomBreadCrumb';
import { PageMainLabel } from 'app/components/SHLabel';
import BackButton from 'app/components/BackButton';
import styled from 'styled-components';
import AdminCustomButton from 'app/components/AdminCustomButton';
import SimpleReactValidator from 'simple-react-validator';
import React, { useEffect, useRef } from 'react';
import { ShowInfoFormBlock } from './components/ShowInfoForm';
import {
  showDefaultData,
  showsBreadCrumpitems,
} from './components/ShowInfoForm/staticData';
import useShowApi from 'admin/hooks/useShowApi';
import { ShowRegistration } from 'services/openapi';
import { useParams } from 'react-router-dom';
import { CommonLoader } from 'app/components/CommonLoader';
import Cookies from 'js-cookie';
import useCommonLookUpDatas from 'hooks/useCommonLookupData';
import { seasonValue, useNavigateToRoute } from 'utils/common';

export const ShowCreateEditPage = () => {
  const {
    loading,
    getShowInfo,
    showInfoData,
    createShowInfo,
    updateShowInfo,
    showDataLoading,
  } = useShowApi();
  const {
    getAllLookupData,
    lookUps,
    loading: lookUpLoading,
  } = useCommonLookUpDatas();
  const redirect = useNavigateToRoute();
  const { id: showId, action } = useParams();
  const [triggor, setTriggor] = React.useState(false);
  const [triggorTwo, setTriggorTwo] = React.useState(false);
  const [cancelTrigger, setCancelTrigger] = React.useState(false);
  const [showPayload, setShowPayload] =
    React.useState<ShowRegistration>(showDefaultData);
  const [valid, setValid] = React.useState<boolean>(false);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [imgSrc, setImgSrc] = React.useState<string | null>('');
  const [imageFile, setImageFile] = React.useState<Blob>();
  const handleSaveClick = () => {
    let isFormValid = simpleValidator.current.allValid();
    if (valid || isFormValid) {
      if (action === 'edit') {
        const editPayload = {
          ...showPayload,
          season: showPayload.season
            ? showPayload.season
            : seasonValue(lookUps),
        };
        updateShowInfo(editPayload, imageFile);
      } else if (action === 'create' || action === 'duplicate') {
        const createPayload = { ...showPayload, season: seasonValue(lookUps) };
        createShowInfo(createPayload, imageFile);
      }
    } else {
      simpleValidator.current.showMessages();
      setTriggor(!triggor);
    }
  };
  const onCancelClick = () => {
    if (action === 'edit' || action === 'duplicate') {
      setShowPayload(showInfoData as ShowRegistration);
      setImgSrc(showInfoData?.imageUrl!);
      setImageFile(undefined);
    } else if (action === 'create') {
      setShowPayload(showDefaultData);
      setImageFile(undefined);
      setImgSrc(showInfoData?.imageUrl!);
    }
    setTriggorTwo(!triggorTwo);
    simpleValidator.current.hideMessages();
    simpleValidator.current.visibleFields = [];
  };

  const handleBackClick = () => {
    if (action === 'edit') {
      redirect(`/admin/shows/view/${showId}/show-info`);
    } else {
      redirect(`/admin/shows`);
    }
    Cookies.remove('showId');
  };

  React.useEffect(() => {
    if (showInfoData) {
      setShowPayload(showInfoData as ShowRegistration);
      setImgSrc(showInfoData.imageUrl!);
    }
  }, [showInfoData]);
  React.useEffect(() => {
    const cookieShowId = Cookies.get('showId');
    if (showId || cookieShowId) {
      getShowInfo(showId ?? cookieShowId);
    }
  }, [showId]);
  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(formValid);
  }, [showPayload, imageFile]);

  useEffect(() => {
    const handlePopState = () => {
      Cookies.remove('showId');
    };
    window.addEventListener('popstate', handlePopState);
    // Cleanup event listener on component unmount
    return;
  }, []);
  return (
    <>
      <CommonPageWrapper>
        {loading && <CommonLoader loaderType="main" />}
        <HeadingWrapper>
          <PageMainLabel>Shows Information</PageMainLabel>
          <CustomBreadCrumb preData={showsBreadCrumpitems} />
        </HeadingWrapper>
        <ContentWrapper>
          <BtnWrapper>
            <div>
              <BackButton onClick={handleBackClick} />
            </div>
            <div className="btn-action">
              <AdminCustomButton buttonType="cancel" onClick={onCancelClick}>
                Cancel
              </AdminCustomButton>
              <AdminCustomButton buttonType="save" onClick={handleSaveClick}>
                Save
              </AdminCustomButton>
            </div>
          </BtnWrapper>
          <ShowInfoFormBlock
            simpleValidator={simpleValidator}
            showPayload={showPayload}
            setShowPayload={setShowPayload}
            setImgSrc={setImgSrc}
            imgSrc={imgSrc}
            setImageFile={setImageFile}
            showDataLoading={showDataLoading || lookUpLoading}
            getAllLookupData={getAllLookupData}
            lookUps={lookUps}
            cancelTrigger={triggorTwo}
          />
        </ContentWrapper>
      </CommonPageWrapper>
    </>
  );
};
const ContentWrapper = styled.div`
  width: 100%;
  padding: 15px 30px 15px 0;
`;
const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 15px 5px;
  border-bottom: 1px solid #eaecf0;
  .btn-action {
    display: flex;
    align-items: center;
    gap: 15px;
  }
`;
