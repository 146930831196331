import { useErrorHandlingSlice } from 'app/error/slice';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useApi from 'services/mavapi/useApi';
import {
  ElementaryPoints,
  GeneralLookup,
  HighSchoolTeam,
  LeaderPoint,
  ManageShowService,
  RiderPoint,
  RiderPointList,
  RiderPointListWrapper,
  ScoreBoard,
  ShowRegistration,
  ShowRider,
  ShowWrapper,
  ShowRiderListWrapper,
  RiderInfo,
  RiderSplitClass,
  GeneratedSplit,
} from 'services/openapi';
import { useNavigateToRoute } from 'utils/common';
import Cookies from 'js-cookie';

const useShowApi = () => {
  const [loading, setLoading] = useState(false);
  const [showDataLoading, setShowDataLoading] = useState(false);
  const [, setError] = useState<unknown | null>();
  const { error: apiError, handleRequest } = useApi();
  const {
    manageShowGetShows,
    manageShowDeleteShow,
    manageShowShowStatus,
    manageShowCreateShow,
    manageShowUpdateShow,
    manageShowGetShowInfo,
    manageShowUploadShowImage,
    manageShowAddRiderToShow,
    manageShowGetAllRiders,
    manageShowGetShowRiderList,
    manageShowDeleteRider,
    manageShowGetShowRiderPoints,
    manageShowAddRiderPoints,
    manageShowGetSeniorJuniorHighPoints,
    manageShowGetShowScoreBoard,
    manageShowGetLeaderPoint,
    manageShowGetRiderElmentaryHighPoint,
    manageShowGetShowRiderInfo,
    manageShowUpdateRiderSplit,
    manageShowGenerateSplit,
  } = ManageShowService;
  const [showDataList, setShowDataList] = useState<ShowWrapper>();
  const [isResponseRider, setIsResponseRider] = useState(false);
  const [riderDataList, setRiderDataList] = useState<GeneralLookup[]>();
  const [showInfoData, setShowInfoData] = useState<ShowRegistration>();
  const { actions } = useErrorHandlingSlice();
  const dispatch = useDispatch();
  const redirect = useNavigateToRoute();
  const [showRiderDataList, setShowRiderDataList] =
    useState<ShowRiderListWrapper>();
  const [riderShowList, setRiderShowList] = useState<RiderPointListWrapper>();
  const [highPointList, setHighPointList] = useState<HighSchoolTeam>();
  const [showScoreBoardList, setShowScoreBoardList] = useState<ScoreBoard>();
  const [showLeaderPointList, setShowLeaderPointList] = useState<LeaderPoint>();
  const [showElementaryHighPointList, setShowElementaryHighPointList] =
    useState<ElementaryPoints>();
  const [showRiderInfo, setShowRiderInfo] = useState<RiderInfo>();
  const [splitData, setSplitData] = useState<Array<GeneratedSplit>>();
  const fetchManageGetShows = (
    season?: string,
    location?: Array<string>,
    status?: string,
    searchInput?: string,
    sortBy?: string,
    sortOrder?: number,
    pageNumber: number = 1,
    pageSize: number = 10,
  ) => {
    setLoading(true);
    let pageNumCall = pageNumber ? pageNumber / 10 + 1 : 1;
    handleRequest(
      manageShowGetShows(
        season,
        location,
        status,
        searchInput,
        sortBy,
        sortOrder,
        pageNumCall,
        pageSize,
      ),
    )
      .then(res => {
        if (res) {
          setShowDataList(res);
        }
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };

  const deleteShow = (
    id?: string,
    season?: string,
    location?: Array<string>,
    status?: string,
    searchInput?: string,
    sortBy?: string,
    sortOrder?: number,
    pageNumber: number = 1,
    pageSize: number = 10,
  ) => {
    setLoading(true);
    handleRequest(manageShowDeleteShow(id ?? ''))
      .then(res => {
        if (res) {
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Show deleted successfully',
              show: true,
              errorType: 'success',
            }),
          );
          fetchManageGetShows(
            season,
            location,
            status,
            searchInput,
            sortBy,
            sortOrder,
            pageNumber,
            pageSize,
          );
        }
        setLoading(false);
      })
      .catch(apiError => {
        setError(apiError);
        setLoading(false);
      });
  };

  const fetchShowActiveOrInactive = (
    id: string,
    activeStatusId?: string,
    item?: string,
    season?: string,
    location?: Array<string>,
    status?: string,
    searchInput?: string,
    sortBy?: string,
    sortOrder?: number,
    pageNumber: number = 1,
    pageSize: number = 10,
  ) => {
    setLoading(true);
    handleRequest(manageShowShowStatus(id, activeStatusId))
      .then(res => {
        if (res) {
          fetchManageGetShows(
            season,
            location,
            status,
            searchInput,
            sortBy,
            sortOrder,
            pageNumber,
            pageSize,
          );

          if (item === 'Make active') {
            dispatch(
              actions.dispatchToast({
                errorTxt: 'Successfully Active',
                show: true,
                errorType: 'success',
              }),
            );
          } else {
            dispatch(
              actions.dispatchToast({
                errorTxt: 'Successfully Inactive',
                show: true,
                errorType: 'success',
              }),
            );
          }
          setLoading(false);
        }
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };

  const getShowInfo = (id: string) => {
    setShowDataLoading(true);
    handleRequest(manageShowGetShowInfo(id))
      .then(res => {
        setShowInfoData(res);
        setShowDataLoading(false);
      })
      .catch(apiError => {
        setError(apiError);
        setShowDataLoading(false);
      });
  };

  const createShowInfo = (requestBody: ShowRegistration, file?: Blob) => {
    setLoading(true);
    handleRequest(manageShowCreateShow(requestBody))
      .then(res => {
        setLoading(false);
        if (res && res.code !== 'InternalServerError') {
          if (file) {
            let formData = { file: file };
            uploadShowProfileImage(res, formData);
            dispatch(
              actions.dispatchToast({
                errorTxt: 'Show Created Successfully',
                show: true,
                errorType: 'success',
              }),
            );
          } else {
            Cookies.remove('showId');

            redirect(`/admin/shows/view/${res}/show-info`);
          }
        }
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };

  const updateShowInfo = (requestBody: ShowRegistration, file?: Blob) => {
    setLoading(true);
    handleRequest(manageShowUpdateShow(requestBody))
      .then(res => {
        setLoading(false);
        redirect(`/admin/shows/view/${res}/show-info`);
        if (file) {
          let formData = { file: file };
          uploadShowProfileImage(res, formData);
        } else {
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Show Updated Successfully',
              show: true,
              errorType: 'success',
            }),
          );
          redirect(`/admin/shows/view/${res}/show-info`);
        }
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };
  const uploadShowProfileImage = (
    id: string,
    formData?: {
      file?: Blob;
    },
  ) => {
    setLoading(true);
    handleRequest(manageShowUploadShowImage(id, formData))
      .then(res => {
        Cookies.remove('showId');
        setLoading(false);
        redirect(`/admin/shows/view/${id}/show-info`);
        dispatch(
          actions.dispatchToast({
            errorTxt: 'Updated Show Image',
            show: true,
            errorType: 'success',
          }),
        );
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };
  const GetRiderListInShows = (
    teamId?: string,
    categoryId?: string,
    divisionId?: string,
  ) => {
    setLoading(true);
    handleRequest(manageShowGetAllRiders(teamId, categoryId, divisionId))
      .then(res => {
        if (res) {
          setRiderDataList(res);
          setIsResponseRider(true);
        } else {
          setIsResponseRider(false);
        }
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };
  const fetchManageGetShowRiderList = (
    showId: string,
    teamFilter?: Array<string>,
    categoryId?: string,
    divisionId?: string,
    searchInput?: string,
    sortBy?: string,
    sortOrder?: number,
    pageNumber: number = 0,
    pageSize: number = 10,
  ) => {
    setLoading(true);
    let pageNumCall = pageNumber ? pageNumber / 10 + 1 : 1;
    handleRequest(
      manageShowGetShowRiderList(
        showId,
        teamFilter,
        categoryId,
        divisionId,
        searchInput,
        sortBy,
        sortOrder,
        pageNumCall,
        pageSize,
      ),
    )
      .then(res => {
        if (res) {
          setShowRiderDataList(res);
        }
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };

  const SaveRiderInShow = (requestBody?: ShowRider, cookieShowId?: string) => {
    setLoading(true);
    handleRequest(manageShowAddRiderToShow(requestBody))
      .then(res => {
        setLoading(false);
        if (res === 'Success') {
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Save successfully',
              show: true,
              errorType: 'success',
            }),
          );
          redirect(`/admin/shows/view/${cookieShowId}/manage-rider`);
        }
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };
  const deleteShowRider = (
    riderId?: string,
    showId?: string,
    teamFilter?: Array<string>,
    categoryId?: string,
    divisionId?: string,
    searchInput?: string,
    sortBy?: string,
    sortOrder?: number,
    pageNumber: number = 1,
    pageSize: number = 10,
  ) => {
    setLoading(true);
    handleRequest(manageShowDeleteRider(riderId ?? '', showId))
      .then(res => {
        if (res) {
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Rider deleted successfully',
              show: true,
              errorType: 'success',
            }),
          );
          fetchManageGetShowRiderList(
            showId ?? '',
            teamFilter,
            categoryId,
            divisionId,
            searchInput,
            sortBy,
            sortOrder,
            pageNumber,
            pageSize,
          );
        }
        setLoading(false);
      })
      .catch(apiError => {
        setError(apiError);
        setLoading(false);
      });
  };
  const getShowRiderPoints = (
    showId: string,
    divisionId?: string,
    categoryId?: string,
    classFilter?: Array<string>,
    pageNumber: number = 1,
    pageSize: number = 10,
  ) => {
    const pageNum = pageNumber ? pageNumber / 10 + 1 : 1;
    setLoading(true);
    handleRequest(
      manageShowGetShowRiderPoints(
        showId,
        divisionId,
        categoryId,
        classFilter,
        pageNum,
        pageSize,
      ),
    )
      .then(res => {
        setLoading(false);
        if (res) {
          setRiderShowList(res);
        }
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };
  const addRiderPoint = (
    requestBody: RiderPoint,
    listParameters: {
      showId: string;
      divisionId: string;
      categoryId: string;
      classFilter: string[] | undefined;
      pageNumber: number | undefined;
    },
  ) => {
    setLoading(true);
    handleRequest(manageShowAddRiderPoints(requestBody))
      .then(res => {
        setLoading(false);
        getShowRiderPoints(
          listParameters.showId,
          listParameters.divisionId,
          listParameters.categoryId,
          listParameters.classFilter,
          listParameters.pageNumber,
        );
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };

  const getSeniorJuniorHighPoints = (showId: string) => {
    setLoading(true);
    handleRequest(manageShowGetSeniorJuniorHighPoints(showId))
      .then(res => {
        setLoading(false);
        setHighPointList(res);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };

  const getShowScoreBoard = (showId: string) => {
    setLoading(true);
    handleRequest(manageShowGetShowScoreBoard(showId))
      .then(res => {
        setLoading(false);
        setShowScoreBoardList(res);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };

  const getShowLeaderPoint = (showId: string) => {
    setLoading(true);
    handleRequest(manageShowGetLeaderPoint(showId))
      .then(res => {
        setLoading(false);
        setShowLeaderPointList(res);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };

  const getShowElementaryHighPoint = (showId: string) => {
    setLoading(true);
    handleRequest(manageShowGetRiderElmentaryHighPoint(showId))
      .then(res => {
        setLoading(false);
        setShowElementaryHighPointList(res);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };
  const getShowRiderInfo = (riderId: string) => {
    handleRequest(manageShowGetShowRiderInfo(riderId))
      .then(res => {
        setShowRiderInfo(res);
      })
      .catch(() => {
        setError(apiError);
      });
  };
  const updateClassSplitApi = (
    isGenerateSplit: boolean,
    requestBody?: Array<RiderSplitClass>,
    showId?: string,
    teamFilter?: Array<string>,
    categoryId?: string,
    divisionId?: string,
    searchInput?: string,
    sortBy?: string,
    sortOrder?: number,
    pageNumber: number = 1,
    pageSize: number = 10,
  ) => {
    setLoading(true);
    handleRequest(manageShowUpdateRiderSplit(requestBody))
      .then(res => {
        setLoading(false);
        if (res) {
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Updated successfully',
              show: true,
              errorType: 'success',
            }),
          );
          if (isGenerateSplit === true) {
            redirect(`/admin/shows/view/${showId}/manage-rider`);
          } else {
            fetchManageGetShowRiderList(
              showId ?? '',
              teamFilter,
              categoryId,
              divisionId,
              searchInput,
              sortBy,
              sortOrder,
              pageNumber,
              pageSize,
            );
          }
        }
      })
      .catch(() => {
        setLoading(false);
        setError(apiError);
      });
  };
  const getGenerateSplitApi = (showId: string) => {
    setLoading(true);
    handleRequest(manageShowGenerateSplit(showId))
      .then(res => {
        setLoading(false);
        setSplitData(res);
      })
      .catch(() => {
        setLoading(false);
        setError(apiError);
      });
  };
  return {
    loading,
    fetchManageGetShows,
    showDataList,
    deleteShow,
    fetchShowActiveOrInactive,
    showInfoData,
    getShowInfo,
    createShowInfo,
    updateShowInfo,
    showDataLoading,
    GetRiderListInShows,
    setRiderDataList,
    riderDataList,
    SaveRiderInShow,
    fetchManageGetShowRiderList,
    showRiderDataList,
    deleteShowRider,
    isResponseRider,
    getShowRiderPoints,
    riderShowList,
    addRiderPoint,
    getSeniorJuniorHighPoints,
    highPointList,
    showScoreBoardList,
    getShowScoreBoard,
    getShowLeaderPoint,
    showLeaderPointList,
    getShowElementaryHighPoint,
    showElementaryHighPointList,
    getShowRiderInfo,
    showRiderInfo,
    updateClassSplitApi,
    getGenerateSplitApi,
    splitData,
    setSplitData,
  };
};
export default useShowApi;
