import * as React from 'react';
import styled from 'styled-components';
export type CommonLoaderProps = {
  loaderType?: 'main' | 'inner';
  circleSize?: string;
  thickness?: String;
};
export const CommonLoader = ({
  loaderType,
  circleSize,
  thickness,
}: CommonLoaderProps) => {
  return (
    <Wrapper
      loaderType={loaderType}
      circleSize={circleSize}
      thickness={thickness}
    >
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div<CommonLoaderProps>`
  height: 100%;
  width: 100%;
  .loader-container {
    position: ${({ loaderType }) =>
      loaderType === 'main' ? 'absolute' : 'relative'};
    background-color: #7987cb30;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1200;
    transform: translate(-50%, -50%);
  }

  .loader {
    border: ${({ thickness }) => (thickness ? `${thickness}px` : '8px')} solid
      rgba(0, 0, 0, 0.1);
    border-left-color: #7986cb; /* Change color as needed */
    border-radius: 50%;

    width: ${({ circleSize }) => (circleSize ? `${circleSize}px` : '40px')};
    height: ${({ circleSize }) => (circleSize ? `${circleSize}px` : '40px')};
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
