import React from 'react';
import {
  Dialog,
  DialogActionsBar,
  DialogCloseEvent,
  DialogProps,
} from '@progress/kendo-react-dialogs';
import styled from 'styled-components';
import { FormSecondaryLabel, InfoLabel } from 'app/components/SHLabel';
import RadioButtonGroup from 'app/components/RadioButtonGroup';
import { RadioGroupChangeEvent } from '@progress/kendo-react-inputs';
import '@fontsource/arimo';

type Props = {
  onClose?: (event: DialogCloseEvent) => void;
  show?: boolean;
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
  onChange?: (event: RadioGroupChangeEvent) => void;
  className?: string;
  themeButton?: 'light';
};

const FraudWarningPopUp = ({
  onClose,
  show,
  setShow,
  onChange,
  className,
  themeButton,
}: Props) => {
  const toggleDialog = () => {
    setShow?.(!show);
  };
  return (
    <>
      {show ? (
        <StyledDialog
          onClose={toggleDialog}
          className={`FraudWarningPopup ${themeButton} ${className}`}
        >
          <CloseIcon className="CloseIcon" onClick={toggleDialog}>
            <span className="k-icon k-i-close"></span>
          </CloseIcon>
          <ContentWrapper className="ContentWrapper">
            <FraudTitleWrapper className="FraudTitleWrapper">
              <InfoLabel>FRAUD WARNING NOTICES</InfoLabel>
            </FraudTitleWrapper>
            <QuestionWrapper className="QuestionWrapper">
              <FormSecondaryLabel>
                <FraudLabel className="FraudLabel">STANDARD: </FraudLabel> Any
                person, who knowingly and with intent to defraud any insurance
                company or other person, files an applications for insurance or
                statement of claim containing any materially false information
                or conceals, for the purpose of misleading, information
                concerning any fact material hereto, commits a fraudulent act,
                which is a crime, and may subject such person to criminal and
                civil penalties.
              </FormSecondaryLabel>
              <FormSecondaryLabel>
                <FraudLabel className="FraudLabel">
                  NOTICE TO ARKANSAS APPLICANTS:{' '}
                </FraudLabel>
                Any person who knowingly presents a false or fraudulent claim
                for payment of a loss or benefit or knowingly presents false
                information in an application for insurance is guilty of a crime
                and may be subject to fines and confinement in prison.
              </FormSecondaryLabel>
              <FormSecondaryLabel>
                <FraudLabel className="FraudLabel">
                  NOTICE TO COLORADO APPLICANTS:{' '}
                </FraudLabel>
                It is unlawful to knowingly provide false, incomplete or
                misleading facts or information to an insurance company for the
                purpose of defrauding or attempting to defraud the company.
                Penalties may include imprisonment, fines, denial of insurance
                and civil damages. Any insurance company or agent of an
                insurance company who knowingly provides false, incomplete, or
                misleading facts or information to a policyholder or claimant
                for the purpose of defrauding or attempting to defraud the
                policyholder or claimant with regard to a settlement or award
                payable from insurance proceeds shall be reported to the
                Colorado Division of Insurance within the Department of
                Regulatory Agencies.
              </FormSecondaryLabel>

              <FormSecondaryLabel>
                <FraudLabel className="FraudLabel">
                  NOTICE TO DISTRICT OF COLUMBIA APPLICANTS:
                </FraudLabel>
                Warning: It is a crime to provide false or misleading
                information to an insurer for the purpose of defrauding the
                insurer or any other person. Penalties include imprisonment
                and/or fines. In addition, an insurer may deny insurance
                benefits if false information materially related to a claim was
                provided by the applicant. NOTICE TO FLORIDA APPLICANTS: Any
                person who knowingly and with intent to injure, defraud, or
                deceive any insurer files a statement of claim or an application
                containing any false, incomplete, or misleading information is
                guilty of a felony of the third degree.
              </FormSecondaryLabel>
              <FormSecondaryLabel>
                <FraudLabel className="FraudLabel">
                  NOTICE TO KENTUCKY APPLICANTS:{' '}
                </FraudLabel>
                Any person who knowingly, and with intent to defraud any
                insurance company or other person files an application for
                insurance containing any materially false information or
                conceals for the purpose of misleading, information concerning
                any fact material thereto commits a fraudulent insurance act,
                which is a crime.
              </FormSecondaryLabel>
              <FormSecondaryLabel>
                <FraudLabel className="FraudLabel">
                  NOTICE TO LOUISIANA APPLICANTS:{' '}
                </FraudLabel>
                Any person who knowingly presents a false or fraudulent claim
                for payment of a loss or benefit or knowingly presents false
                information in an application for insurance is guilty of a crime
                and may be subject to fines and confinement in prison.
              </FormSecondaryLabel>
              <FormSecondaryLabel>
                <FraudLabel className="FraudLabel">
                  NOTICE TO MAINE APPLICANTS:{' '}
                </FraudLabel>{' '}
                is a crime to knowingly provide false, incomplete or misleading
                information to an insurance company for the purpose of
                defrauding the company. Penalties may include imprisonment,
                fines or a denial of insurance benefits.
              </FormSecondaryLabel>
              <FormSecondaryLabel>
                <FraudLabel className="FraudLabel">
                  NOTICE TO MINNESOTA APPLICANTS :{' '}
                </FraudLabel>{' '}
                A person who submits an application or files a claim with intent
                to defraud or helps commit a fraud against an insurer is guilty
                of a crime.
              </FormSecondaryLabel>
              <FormSecondaryLabel>
                <FraudLabel className="FraudLabel">
                  NOTICE TO NEW JERSEY APPLICANTS :{' '}
                </FraudLabel>
                Any person who includes any false or misleading information on
                an application for an insurance policy is subject to criminal
                and civil penalties.
              </FormSecondaryLabel>
              <FormSecondaryLabel>
                <FraudLabel className="FraudLabel">
                  NOTICE TO NEW MEXICO APPLICANTS :{' '}
                </FraudLabel>
                Any person who knowingly presents a false or fraudulent claim
                for payment of a loss or benefit or knowingly presents false
                information in an application for insurance is guilty of a crime
                and may be subject to civil fines and criminal penalties. The
                insurer shall not offer an optional extension period for this
                policy in New Mexico.
              </FormSecondaryLabel>
              <FormSecondaryLabel>
                <FraudLabel className="FraudLabel">
                  NOTICE TO OHIO APPLICANTS:{' '}
                </FraudLabel>
                Any person who, with intent to defraud or knowing that he is
                facilitating a fraud against an insurer, submits an application
                or files a claim containing a false or deceptive statement is
                guilty of insurance fraud.
              </FormSecondaryLabel>
              <FormSecondaryLabel>
                <FraudLabel className="FraudLabel">
                  NOTICE TO OKLAHOMA APPLICANTS:{' '}
                </FraudLabel>
                Any person who knowingly, and with intent to injure, defraud or
                deceive any insurer, makes any claim for the proceeds of an
                insurance policy containing any false, incomplete or misleading
                information is guilty of a felony.
              </FormSecondaryLabel>
              <FormSecondaryLabel>
                <FraudLabel className="FraudLabel">
                  NOTICE TO OREGON APPLICANTS:{' '}
                </FraudLabel>
                Any person who knowingly and with intent to defraud any
                insurance company or other person files an application for
                insurance or statement of claim containing any materially false
                information or conceals for the purpose of misleading,
                information concerning any fact material thereto may be guilty
                of insurance fraud which may subject such person to criminal and
                civil penalties, including but not limited to fines, denial of
                insurance benefits, civil damages, criminal prosecution and
                confinement in state prisons.
              </FormSecondaryLabel>
              <FormSecondaryLabel>
                <FraudLabel className="FraudLabel">
                  NOTICE TO PENNSYLVANIA APPLICANTS:{' '}
                </FraudLabel>
                Any person who knowingly and with intent to defraud any
                insurance company or other person files an application for
                insurance or statement of claim containing any materially false
                information or conceals for the purpose of misleading,
                information concerning any fact material thereto commits a
                fraudulent insurance act, which is a crime and subjects such
                person to criminal and civil penalties.
              </FormSecondaryLabel>
              <FormSecondaryLabel>
                <FraudLabel className="FraudLabel">
                  NOTICE TO TENNESSEE, VIRGINIA AND WASHINGTON APPLICANTS:
                </FraudLabel>
                It is a crime to knowingly provide false, incomplete or
                misleading information to an insurance company for the purpose
                of defrauding the company. Penalties include imprisonment, fines
                and denial of insurance benefits.
              </FormSecondaryLabel>
              <FormSecondaryLabel>
                <FraudLabel className="FraudLabel">
                  NOTICE TO NEW YORK APPLICANTS:{' '}
                </FraudLabel>
                Any person who knowingly and with intent to defraud any
                insurance company or any person files an application for
                insurance or statement of claim containing any materially false
                information or conceals for the purpose of misleading,
                information concerning any fact material thereto, commits a
                fraudulent insurance act which is a crime, and shall also be
                subject to a civil penalty not to exceed five thousand dollars
                ($5,000) and the stated value of the claim for each such
                violation.
              </FormSecondaryLabel>
            </QuestionWrapper>
          </ContentWrapper>
        </StyledDialog>
      ) : (
        ''
      )}
    </>
  );
};
const StyledDialog = styled(Dialog)<DialogProps>`
  backdrop-filter: blur(8px);
  &.FraudWarningPopup {
    &.light {
      .ContentWrapper {
        background: rgb(255 255 255 / 87%) !important;
      }
      .FraudLabel {
        color: rgb(0 0 0 / 87%) !important;
      }
      .QuestionWrapper {
        ::-webkit-scrollbar {
          width: 5px;
          margin-left: 3px;
        }
        ::-webkit-scrollbar-track {
          background: transparent !important;
          border-radius: 10px;
        }
        ::-webkit-scrollbar-thumb {
          width: 9px;
          background: rgb(255 255 255 / 87%) !important;
          border-radius: 10px;
        }
      }
      .CloseIcon {
        background: rgb(255 255 255 / 87%) !important;
      }
    }
  }

  .k-overlay {
  }
  .k-window.k-dialog {
    border: none !important;
    max-height: 80% !important;
    min-height: 50% !important;
    background: transparent !important;
    width: 50%;
  }
  .k-dialog-content {
    background: transparent !important;
    border-radius: 13px !important;
    position: relative;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .k-window.k-dialog {
      width: 98%;
      width: 80%;
    }
    .FraudTitleWrapper {
      label {
        font-size: 12px !important;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .k-window.k-dialog {
      width: 90%;
      width: 80%;
    }
    .FraudTitleWrapper {
      label {
        font-size: 14px !important;
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .k-window.k-dialog {
      width: 90%;
      width: 80%;
    }
    .FraudTitleWrapper {
      label {
        font-size: 15px !important;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .k-window.k-dialog {
      width: 90%;
      width: 80%;
    }
    .FraudTitleWrapper {
      label {
        font-size: 15px !important;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .k-window.k-dialog {
      width: 90%;
      width: 80%;
    }
    .FraudTitleWrapper {
      label {
        font-size: 15px !important;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .k-window.k-dialog {
      width: 90%;
      width: 80%;
    }
    .FraudTitleWrapper {
      label {
        font-size: 16px !important;
      }
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .k-window.k-dialog {
      width: 90%;
      width: 80%;
    }
    .FraudTitleWrapper {
      label {
        font-size: 16px !important;
      }
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    .k-window.k-dialog {
      width: 90%;
      width: 80%;
    }
    .FraudTitleWrapper {
      label {
        font-size: 15px !important;
      }
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    .k-window.k-dialog {
      width: 90%;
      width: 80%;
    }
    .FraudTitleWrapper {
      label {
        font-size: 17px !important;
      }
    }
  }
`;
const FraudTitleWrapper = styled.div`
  width: 100%;
  text-align: start;
  margin-bottom: 8px;
  padding: 5px 0;
  border-bottom: 1px solid #5d5c5c;
`;
const FraudLabel = styled.span`
  color: #ffffff !important;
`;
const QuestionWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  overflow: auto;
  width: 100%;
  height: 100%;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: #201e1e !important;
    border-radius: 10px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    label {
      font-size: 10px !important;
      padding-bottom: 5px;
    }
    span {
      font-size: 10px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    label {
      font-size: 12px !important;
      padding-bottom: 5px;
    }
    span {
      font-size: 12px !important;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    label {
      font-size: 12px !important;
      padding-bottom: 5px;
    }
    span {
      font-size: 12px !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    label {
      font-size: 13px !important;
      padding-bottom: 5px;
    }
    span {
      font-size: 13px !important;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    label {
      font-size: 14px !important;
      padding-bottom: 5px;
    }
    span {
      font-size: 14px !important;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    label {
      font-size: 15px !important;
      padding-bottom: 5px;
    }
    span {
      font-size: 15px !important;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    label {
      font-size: 16px !important;
      padding-bottom: 5px;
    }
    span {
      font-size: 16px !important;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    label {
      font-size: 17px !important;
      padding-bottom: 5px;
    }
    span {
      font-size: 17px !important;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    label {
      font-size: 18px !important;
      padding-bottom: 5px;
    }
    span {
      font-size: 18px !important;
    }
  }
`;
const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #27292d !important;
  border-radius: 10px;
  padding: 25px 25px;
  @media (min-width: 100px) and (max-width: 575) {
    padding: 25px 10px;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    padding: 25px 15px;
  }
  @media (min-width: 992px) and (max-width: 1365px) {
    padding: 25px 30px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    padding: 25px 40px;
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    padding: 25px50px;
  }
`;

const CloseIcon = styled.div`
  color: #5a5a5a;
  position: absolute;
  right: 4px;
  top: 3px;
  background: #262626;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid #5a5a5a;
  cursor: pointer;
`;
export default FraudWarningPopUp;
