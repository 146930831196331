import React, { useState } from 'react';
import styled from 'styled-components';
import '@fontsource/oswald';
import '@fontsource/roboto';
import { BackNextButton } from 'app/components/BackNextButton';
import { useNavigate } from 'react-router-dom';
import SHdropdown from 'app/components/SHdropdown';
import RadioButtonGroup from 'app/components/RadioButtonGroup';
import {
  ApiError,
  DocumentService,
  GeneralLookup,
  GeneralLookupWrapper,
  LookupService,
} from 'services/openapi';
import useApi from 'services/mavapi/useApi';
import TeamListPopUp from 'app/components/TeamListPopUp';
import SimpleReactValidator from 'simple-react-validator';
import { useDispatch, useSelector } from 'react-redux';
import { useRiderRegistrationStateSlice } from './slice';
import LoaderWrapper from 'app/components/CircularLoader/LoaderWrapper';
import CircularLoader from 'app/components/CircularLoader';
import { useManagePageSlice } from '../slice';
import { selectPageLoader } from 'app/pages/slice/selectors';
import Cookies from 'js-cookie';
import SHButton from 'app/components/SHButton';

type Props = {};
const DefaultValue = {
  id: '',
  name: '',
  children: null,
};

const RiderRegistrationPage = (props: Props) => {
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const { error: apiError, handleRequest } = useApi();
  const [, setError] = React.useState<ApiError | unknown>();
  const [isReturning, setIsReturning] = useState<boolean | null>(null);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const navigate = useNavigate();
  const [visible, setVisible] = React.useState<boolean>(false);
  const [lookUps, setLookUps] = React.useState<Array<GeneralLookupWrapper>>([]);
  const { lookupGetAllLookups } = LookupService;
  const { documentActiveDocumentByType } = DocumentService;
  const [categoryData, setCategoryData] = useState<GeneralLookup>(DefaultValue);
  const [valid, setValid] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { actions: categoryActions } = useRiderRegistrationStateSlice();
  const pageLoader = useSelector(selectPageLoader);
  const { actions: PageActions } = useManagePageSlice();
  const [documentTypeData, setDocumentTypeData] = useState<GeneralLookup[]>([]);
  const handleBack = () => {
    navigate('/login');
  };

  const handleNxt = () => {
    if (isReturning === true) {
      if (
        categoryData.name === 'Senior High' ||
        categoryData.name === 'Elementary' ||
        categoryData.name === 'Junior High'
      ) {
        navigate('/rider-registration-page/returning-rider');
      } else if (categoryData.name === 'Alumni') {
        navigate('/alumni-registration/returning-member');
      } else if (categoryData.name === 'EWD') {
        navigate('/ewd-membership-form/returning-member');
      }
    }
    if (isReturning === false) {
      if (
        categoryData.name === 'Senior High' ||
        categoryData.name === 'Elementary' ||
        categoryData.name === 'Junior High'
      ) {
        navigate('/rider-registration-page/new-rider');
      } else if (categoryData.name === 'Alumni') {
        navigate('/alumni-registration/new-member');
      } else if (categoryData.name === 'EWD') {
        navigate('/ewd-membership-form/new-member');
      }
    }
    if (categoryData.name === 'Non Competing') {
      navigate('/non-competing-membership/returning-member');
    }
  };

  const handleReturningSelection = event => {
    const value = event.value;
    setIsReturning(value === true);
    setIsNextDisabled(false);
  };
  const handleInfoIcon = () => {
    setVisible(!visible);
  };

  const handlegetAllLookupData = (names?: string) => {
    dispatch(PageActions.setPageLoader(true));
    handleRequest(lookupGetAllLookups(names))
      .then(data => {
        if (data) {
          setLookUps(data);
          dispatch(PageActions.setPageLoader(false));
          let typeData = data?.filter(item => {
            if (item?.type === 'DOCUMENT_TYPES') {
              return item.values;
            }
          });
          setDocumentTypeData(typeData[0]?.values);
        }
      })
      .catch(() => {
        setError(apiError);
        dispatch(PageActions.setPageLoader(false));
      });
  };
  const handleDiagonosisFormDownload = () => {
    const payload = documentTypeData[0].id;
    handleRequest(documentActiveDocumentByType(payload)).then(res => {
      const url = res.filePath;
      const name = res.name;
      const downloadUrl = `https://yeda-api-dev.onesmarter.in/${url}`;
      fetch(downloadUrl)
        .then(response => response.blob())
        .then(blob => {
          const objectUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = objectUrl;
          window.open(objectUrl);
          link.download = encodeURIComponent(name);
          link.click();
        });
    });
  };
  React.useEffect(() => {
    handlegetAllLookupData('CATEGORY,DOCUMENT_TYPES');
    Cookies.remove('paymentCancel');
    Cookies.remove('payload');
    Cookies.remove('diaganosisPayload');
  }, []);
  const handleRiderCategorySelection = data => {
    setCategoryData(data);
    dispatch(categoryActions.setCategory(data));
  };
  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [categoryData]);
  return (
    <>
      {pageLoader ? (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      ) : (
        <MainWrapper className="form-MainWrapper">
          <OverflowWrapper>
            <ContentWrapper>
              <p className="para">
                Kindly check the list of Teams which have renewed memberships
                for the year till now. If you do not find the team you wish to
                be a part of, check back later.
              </p>
              <h4 onClick={handleInfoIcon} className="team-list-header">
                Team list 2023-24
              </h4>
              {visible && <TeamListPopUp onClose={handleInfoIcon} />}

              <DropdownWrapper>
                <SHdropdown
                  className="drop-down"
                  label="Select your rider category"
                  data={
                    lookUps.find(obj => {
                      return obj.type === 'CATEGORY';
                    })?.values ?? []
                  }
                  onDataChange={handleRiderCategorySelection}
                  defaultValue={categoryData}
                  validations={'required'}
                  validator={simpleValidator.current}
                />
              </DropdownWrapper>
              {categoryData.name === 'Non Competing' ? (
                ''
              ) : (
                <ReturningWrapper>
                  <p>
                    Are you a returning{' '}
                    {categoryData.name === 'EWD'
                      ? 'member'
                      : categoryData.name === 'Alumni'
                      ? 'Alumni rider'
                      : 'rider'}
                    ?
                  </p>
                  <RadioButtonWrapper>
                    <RadioButtonGroup onChange={handleReturningSelection} />
                  </RadioButtonWrapper>
                </ReturningWrapper>
              )}
              {categoryData.name === 'EWD' && (
                <DownloadWrapper>
                  <p>Download the Ewd Diagnosis form below</p>
                  <SHButton
                    children="Download Form"
                    onClick={handleDiagonosisFormDownload}
                  />
                </DownloadWrapper>
              )}

              <FormWrapper>
                <p className="para-three">Information</p>
                <p className="para-four">
                  <span>Non-Competing Membership</span>: Seniors who are not
                  showing but applying for membership to be eligible for the
                  Scholarship Incentive Fund (SIF).
                </p>
                <p>
                  A membership registration forms for{' '}
                  <span>Equestrian with Disabilities</span> must be accompanied
                  by a Diagnosis and Adaptive Equipment Form.
                </p>{' '}
                {categoryData.name === 'EWD' && (
                  <>
                    <p>
                      Payment of $80 fee must be accompanied with this form ($10
                      of the $80 will go towards the SIF as the YEDA Back # fee)
                    </p>
                  </>
                )}
              </FormWrapper>
            </ContentWrapper>

            <ButtonWrapper className="bg-color-change">
              <BackNextButton
                classNameBack="btn-back"
                classNameNxt={`btn-nxt ${
                  isReturning === null || isNextDisabled ? 'grey' : 'blue'
                }`}
                onClickNxt={handleNxt}
                onClickBack={handleBack}
                next="Next"
                back="Cancel"
                disableNext={
                  (categoryData.name === 'Non Competing'
                    ? ''
                    : isNextDisabled) || valid
                }
              />
            </ButtonWrapper>
          </OverflowWrapper>
        </MainWrapper>
      )}
    </>
  );
};
const MainWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
  background: #2a2a2a;
  position: relative;
  max-width: 850px;
  margin: 0 auto;
  p {
    color: #9597a6;
    font-family: 'Roboto';
    font-size: 14px;
  }
  .para {
    color: #ff9900;
    padding: 20px 0px 5px;
  }
  .para-two {
    color: #ff9900;
    border-bottom: 1px solid #585858;
    padding: 0px 0px 15px;
  }
  h4 {
    color: #306fdb;
    text-decoration: underline;
    font-weight: 700;
    width: fit-content;
    cursor: pointer;
    margin: 15px 0px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    padding: 0 5px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding: 0 10px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 0 20px;
  }
`;
const OverflowWrapper = styled.div`
  overflow: auto;
  height: calc(100vh - 92px);
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 5px;
    background: transparent !important;
    border-radius: 10px;
  }
`;
const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0px 100px;
`;

const DropdownWrapper = styled.div`
  width: 60%;
  padding-bottom: 40px;
  .drop-down {
    margin: 0px;
    height: 35px;
    color: #9597a6;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    padding-bottom: 20px;
    width: 70%;
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    padding-bottom: 50px;
    width: 50%;
  }
`;

const ReturningWrapper = styled.div``;
const DownloadWrapper = styled.div`
  button {
    background-color: #306fdb;
    border-color: #306fdb;
    color: #ffffff;
    cursor: pointer;
  }
`;

const FormWrapper = styled.div`
  width: 100%;
  border: 1px solid #585858;
  border-radius: 7px;
  margin-top: 20px;
  padding: 20px;
  background: #27292d;
  border: 1px solid #5e5e5e;
  p {
    font-family: 'Roboto';
  }
  .para-three {
    color: #ff9900;
    font-size: 16px;
    border-bottom: 1px solid #585858;
  }
  .para-four {
    padding-bottom: 10px;
  }
  span {
    color: rgb(255 255 255 / 87%);
  }
`;
const RadioButtonWrapper = styled.div`
  display: flex;
  gap: 40px;
  padding: 0 0 50px 0;
  .RadioButtonGroupWrapper {
    margin: 0 !important;
    ul {
      padding: 0 !important;
    }
  }
  p,
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  .k-radio {
    background-color: transparent;
    border: 1px solid #ced4da;
  }
  .k-radio:checked,
  .k-radio.k-checked {
    border-color: 1px solid #0d6efd;
    color: white;
    background-color: #0d6efd;
  }
  .k-radio-list {
    flex-flow: row-reverse;
    gap: 50px;
  }
  .k-radio-item .k-radio-label {
    color: #ffffff;
  }
`;
const ButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: -9px;
    left: 0px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    backdrop-filter: blur(5px);
  }
  .blue {
    background-color: #306fdb;
    color: white;
  }

  /* Button styles for grey color */
  .grey {
    background-color: #595959;
    color: white;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
    @media (min-width: 1200px) and (max-width: 1365px) {
      height: 55px;
    }
    @media (min-width: 1366px) and (max-width: 1448px) {
      height: 70px;
    }
  }
`;

export default RiderRegistrationPage;
