import { GridLayoutItem } from '@progress/kendo-react-layout';
import { CustomgridLayoutWrapper } from 'admin/components/wrappers';
import AdminDropDown from 'app/components/AdminDropDown';
import { AdminTextField } from 'app/components/AdminTextField';
import { ProfileImageUploadCard } from 'app/components/ProfileImageUploadCard';
import styled from 'styled-components';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import {
  GeneralLookup,
  GeneralLookupWrapper,
  UserProfile,
} from 'services/openapi';
import { getLookupArray } from 'utils/common';
import { DropDownModel } from 'app/components/SHdropdown';
type Props = {
  simpleValidator: MutableRefObject<SimpleReactValidator>;
  setUserPayload: Dispatch<SetStateAction<UserProfile>>;
  userPayload: UserProfile;
  setImageUploadBlobPayload?: Dispatch<SetStateAction<Blob | null>>;
  imgSrc: string | null;
  setImgSrc: Dispatch<SetStateAction<string | null>>;
  setUserPreDataList: Dispatch<SetStateAction<DropDownModel[]>>;
  userPreDataList: DropDownModel[];
  loading: boolean;
  lookUps: GeneralLookupWrapper[];
};

const ProfileUpdateForm = ({
  simpleValidator,
  setUserPayload,
  userPayload,
  setImageUploadBlobPayload,
  imgSrc,
  setImgSrc,
  userPreDataList,
  loading,
  lookUps,
}: Props) => {
  const handleChange = event => {
    const { name, value } = event.target;
    setUserPayload(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleDropdownChange = (
    data: DropDownModel | GeneralLookup,
    name: string,
  ) => {
    setUserPayload(prevData => ({
      ...prevData,
      [name]: data,
    }));
  };
  const handleImageSelect = e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setImageUploadBlobPayload?.(file);
      };
      reader.readAsArrayBuffer(file);
    }
  };
  return (
    <>
      <GridWrapper>
        <CustomgridLayoutWrapper
          rows={[{ height: 'auto' }]}
          cols={[
            { width: 'calc(32.33% - 14px)' },
            { width: 'calc(68.67% - 14px)' },
          ]}
          gap={{ rows: 20, cols: 30 }}
        >
          <GridLayoutItem>
            <ProfileImageUploadCard
              uploadButtonLabel={'Change image'}
              uploadButtonType="uploadFile"
              imgSrc={imgSrc}
              setImgSrc={setImgSrc}
              onSelectFile={handleImageSelect}
              isLoader={loading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <CustomgridLayoutWrapper
              rows={[{ height: 'auto' }]}
              cols={[
                { width: 'calc(50% - 14px)' },
                { width: 'calc(50% - 14px)' },
              ]}
              gap={{ rows: 20, cols: 10 }}
            >
              <GridLayoutItem>
                <AdminTextField
                  label="First Name"
                  placeholder=""
                  value={userPayload.firstName ?? ''}
                  onChange={handleChange}
                  name="firstName"
                  validations="required"
                  validator={simpleValidator.current}
                  className="field-style"
                  isLoader={loading}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <AdminTextField
                  label="Last Name"
                  placeholder=""
                  value={userPayload.lastName ?? ''}
                  onChange={handleChange}
                  name="lastName"
                  validations="required"
                  validator={simpleValidator.current}
                  className="field-style"
                  isLoader={loading}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <AdminTextField
                  label="User Name"
                  placeholder=""
                  value={userPayload.userName ?? ''}
                  onChange={handleChange}
                  name="userName"
                  validations="required"
                  validator={simpleValidator.current}
                  className="field-style"
                  isLoader={loading}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <AdminTextField
                  label="Phone Number"
                  placeholder=""
                  value={userPayload.primaryPhone ?? ''}
                  onChange={e => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
                      setUserPayload?.({
                        ...userPayload,
                        primaryPhone: inputValue,
                      });
                    }
                  }}
                  name="primaryPhone"
                  validations="required"
                  validator={simpleValidator.current}
                  className="field-style"
                  isLoader={loading}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <AdminTextField
                  label="Email"
                  placeholder=""
                  value={userPayload.email ?? ''}
                  onChange={handleChange}
                  name="email"
                  validations="required|email"
                  validator={simpleValidator.current}
                  className="field-style"
                  isLoader={loading}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <AdminTextField
                  label="City"
                  placeholder=""
                  value={userPayload.city ?? ''}
                  onChange={handleChange}
                  name="city"
                  validations="required"
                  validator={simpleValidator.current}
                  className="field-style"
                  isLoader={loading}
                />
              </GridLayoutItem>
            </CustomgridLayoutWrapper>
          </GridLayoutItem>
        </CustomgridLayoutWrapper>
        <CustomgridLayoutWrapper
          rows={[{ height: 'auto' }]}
          cols={[
            { width: 'calc(33.33% - 8px)' },
            { width: 'calc(33.33% - 6px)' },
            { width: 'calc(33.33% - 9px)' },
          ]}
          gap={{ rows: 20, cols: 10 }}
          style={{ paddingTop: '20px' }}
        >
          <GridLayoutItem>
            <AdminTextField
              label="Street"
              placeholder=""
              value={userPayload.street ?? ''}
              onChange={handleChange}
              name="street"
              validations="required"
              validator={simpleValidator.current}
              className="field-style"
              isLoader={loading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminDropDown
              label="State"
              placeholder=""
              onDataChange={data => handleDropdownChange(data, 'state')}
              data={getLookupArray('STATE', lookUps)}
              defaultValue={userPayload.state}
              validations="required"
              validator={simpleValidator.current}
              className="field-style"
              isLoader={loading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminTextField
              label="Zip"
              placeholder=""
              value={userPayload.zip ?? ''}
              onChange={handleChange}
              name="zip"
              validations={'required|max:10'}
              validator={simpleValidator.current}
              className="field-style"
              isLoader={loading}
            />
          </GridLayoutItem>
        </CustomgridLayoutWrapper>
      </GridWrapper>
    </>
  );
};

export default ProfileUpdateForm;
const GridWrapper = styled.div`
  padding: 10px 0 20px;
  .k-grid-layout {
    .checkbox {
      margin-left: 15px;
      margin-top: 30px;
      label {
        color: #a3a3a3;
      }
      @media (min-width: 100px) and (max-width: 1365px) {
        margin-left: 5px;
        margin-right: unset;
      }
    }
  }
`;
