import React, { useState } from 'react';
import styled from 'styled-components';
import '@fontsource/roboto';
import { InfoLabel } from 'app/components/SHLabel';
import SHdropdown from 'app/components/SHdropdown';
import { TextField } from 'app/components/TextField';
import { BackNextButton } from 'app/components/BackNextButton';
import { Alumni, GeneralLookup, GeneralLookupWrapper } from 'services/openapi';
import BackNumberTableComponent from 'app/components/BackNumberListComponent';
import SimpleReactValidator from 'simple-react-validator'; // import { DefaultValue } from '../..';
import { heightWeightModel } from 'app/pages/RiderRegistrationPage/components/NotReturningRider';
import SHBackNumberDropdown from 'app/components/SHBackNumberDropdown';
import { getLookupArray } from 'utils/common';

type Props = {
  handleNextForm?: (event) => void;
  handleBackForm?: (event) => void;
  aluminiRiderData: Alumni;
  setAluminiRiderData: React.Dispatch<React.SetStateAction<Alumni>>;
  lookUp?: GeneralLookupWrapper[];
  categoryCheck: GeneralLookup[] | undefined;
  setHeightWeight: React.Dispatch<
    React.SetStateAction<heightWeightModel | undefined>
  >;
  heightWeight: heightWeightModel | undefined;
  backNumberData: string[] | undefined;
  setCategoryCheck: React.Dispatch<
    React.SetStateAction<GeneralLookup[] | undefined>
  >;
};

export const PersonalInfoForm = ({
  handleNextForm,
  handleBackForm,
  aluminiRiderData,
  setAluminiRiderData,
  lookUp,
  categoryCheck,
  setHeightWeight,
  heightWeight,
  backNumberData,
  setCategoryCheck,
}: Props) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [valid, setValid] = useState<boolean>(true);

  const handleCloseBackNumberPopUp = () => {
    setVisible(!visible);
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setAluminiRiderData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleBackNumberChange = name => {
    if (aluminiRiderData) {
      setAluminiRiderData?.({
        ...aluminiRiderData,
        backNumber: name.value,
      });
    }
  };
  const handlePhChange = e => {
    const inputValue = e.target.value;
    if (inputValue?.length <= 10 && /^\d*$/.test(inputValue)) {
      setAluminiRiderData({
        ...aluminiRiderData,
        primaryPhone: inputValue,
      });
    }
  };
  const handleEmergencyPhChange = e => {
    const inputValue = e.target.value;
    if (inputValue?.length <= 10 && /^\d*$/.test(inputValue)) {
      setAluminiRiderData({
        ...aluminiRiderData,
        emergencyContactPhone: inputValue,
      });
    }
  };
  const handleZipChange = e => {
    const inputValue = e.target.value;
    if (inputValue?.length <= 9 && /^[a-zA-Z0-9]*$/.test(inputValue)) {
      setAluminiRiderData({
        ...aluminiRiderData,
        zip: inputValue,
      });
    }
  };
  const handleFeetChange = event => {
    const newFeet = parseInt(event.target.value);
    if (event.target.value && newFeet >= 0 && newFeet <= 9) {
      setHeightWeight(prevData => ({
        ...prevData!,
        feet: Math.floor(newFeet),
      }));
      if (aluminiRiderData) {
        setAluminiRiderData({
          ...aluminiRiderData,
          height: newFeet,
        });
      }
    } else if (!event.target.value) {
      setHeightWeight(prevData => ({ ...prevData!, feet: undefined }));
    }
  };

  const handleInchesChange = event => {
    const newInches = parseInt(event.target.value);
    if (event.target.value && newInches >= 0 && newInches <= 11) {
      setHeightWeight(prevData => ({
        ...prevData!,
        inches: Math.floor(newInches),
      }));
      const newHeightInch = heightWeight?.feet! + newInches / 12;
      if (aluminiRiderData) {
        setAluminiRiderData({
          ...aluminiRiderData,
          height: newHeightInch,
        });
      }
    } else if (!event.target.value) {
      setHeightWeight(prevData => ({
        ...prevData!,
        inches: undefined,
      }));
    }
  };
  const handleWeightChange = e => {
    const inputValue = e.target.value;
    if (inputValue?.length <= 3 && /^[0-9]*$/.test(inputValue)) {
      setAluminiRiderData({
        ...aluminiRiderData,
        weight: inputValue,
      });
      setHeightWeight(prevData => ({
        ...prevData!,
        weight: inputValue,
      }));
    }
  };
  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [aluminiRiderData]);

  return (
    <MainWrapper className="form-MainWrapper" id="PersonalInfoFormMainWrapper">
      <SubMainWrapper className="SubMainWrapper">
        <BorderWrapper className="inner-BorderWrapper">
          <InfoLabelWrapper id="InfoLabelWrapper">
            <div>
              <InfoLabel>
                Payment of $20 fee and proof of insurance must be accompanied
                with this form
              </InfoLabel>
            </div>
          </InfoLabelWrapper>
          <TextFieldGrid className="TextFieldGrid">
            <TextFieldWrapper className="TextFieldWrapper ">
              <StyledTextField
                className="StyledTextField"
                onChange={handleChange}
                value={aluminiRiderData?.firstName}
                label="First Name"
                type="text"
                name="firstName"
                validations={'required|max:30'}
                validator={simpleValidator.current}
              />
            </TextFieldWrapper>
            <TextFieldWrapper className="TextFieldWrapper ">
              <StyledTextField
                className="StyledTextField"
                onChange={handleChange}
                value={aluminiRiderData?.lastName}
                label="Last Name"
                type="text"
                name="lastName"
                validations={'required|max:30'}
                validator={simpleValidator.current}
              />
            </TextFieldWrapper>

            <TextFieldWrapper className="TextFieldWrapper">
              <BackFieldWrapper className="BackFieldWrapper">
                <SHBackNumberDropdown
                  onChange={e => handleBackNumberChange(e.value)}
                  data={backNumberData}
                  defaultValue={aluminiRiderData?.backNumber}
                  label="Back Number (3 or 4 digits)"
                  validations={'required|max:30'}
                  validator={simpleValidator.current}
                  isAbove={true}
                />
              </BackFieldWrapper>
            </TextFieldWrapper>
            <TextFieldWrapper className="TextFieldWrapper ">
              <SHdropdown
                onDataChange={e => {
                  setAluminiRiderData(prevData => ({
                    ...prevData,
                    team: e,
                  }));
                  const findAlumni = e?.children?.filter(item => {
                    return item.name === 'Alumni';
                  });
                  setCategoryCheck(findAlumni);
                }}
                data={getLookupArray('ACTIVETEAM', lookUp!)}
                label="Team Name"
                defaultValue={aluminiRiderData.team!}
                className="drop-down"
                validations={'required'}
                validator={simpleValidator.current}
                categoryCheck={categoryCheck}
              />
            </TextFieldWrapper>
          </TextFieldGrid>
          <MainAddressWrapper className="MainAddressWrapper">
            <div className="horizontal-line">
              <span>Coach Address</span>
            </div>
            <TextFieldGrid className="TextFieldGrid">
              <TextFieldWrapper className="TextFieldWrapper ">
                <StyledTextField
                  className="StyledTextField"
                  onChange={handleChange}
                  value={aluminiRiderData?.street}
                  label=" Address"
                  type="text"
                  name="street"
                  validations={'required'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
              <TextFieldWrapper className="TextFieldWrapper ">
                <StyledTextField
                  className="StyledTextField"
                  onChange={handleChange}
                  value={aluminiRiderData?.city}
                  label=" City"
                  type="text"
                  name="city"
                  validations={'required|max:40'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
              <TextFieldWrapper className="TextFieldWrapper ">
                <SHdropdown
                  onDataChange={data => {
                    if (aluminiRiderData) {
                      setAluminiRiderData({
                        ...aluminiRiderData,
                        state: data,
                      });
                    }
                  }}
                  label="State"
                  data={lookUp?.find(obj => obj.type === 'STATE')?.values ?? []}
                  defaultValue={aluminiRiderData?.state}
                  validations={'required'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
              <TextFieldWrapper className="TextFieldWrapper ">
                <StyledTextField
                  className="StyledTextField"
                  onChange={handleZipChange}
                  value={aluminiRiderData?.zip}
                  label="Zip"
                  type="text"
                  name="zip"
                  validations={'required|alpha_num|min:5|max:9'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
              <TextFieldWrapper className="TextFieldWrapper ">
                <StyledTextField
                  className="StyledTextField"
                  onChange={handlePhChange}
                  value={aluminiRiderData?.primaryPhone}
                  label="Phone Number:"
                  type="tel"
                  name="primaryPhone"
                  validations={'required|numeric|min:10|max:10'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
              <TextFieldWrapper className="TextFieldWrapper ">
                <StyledTextField
                  className="StyledTextField"
                  onChange={handleChange}
                  value={aluminiRiderData?.email}
                  label="Email:"
                  type="email"
                  name="email"
                  validations={'required|email'}
                  validator={simpleValidator.current}
                  maxLength={60}
                />
              </TextFieldWrapper>
              <TextFieldWrapper className="TextFieldWrapper ">
                <StyledTextField
                  className="StyledTextField"
                  onChange={handleChange}
                  value={aluminiRiderData?.emergencyContactName}
                  label="Emergency Contact"
                  type="text"
                  name="emergencyContactName"
                  validations={'required'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
              <TextFieldWrapper className="TextFieldWrapper ">
                <StyledTextField
                  className="StyledTextField"
                  onChange={handleEmergencyPhChange}
                  value={aluminiRiderData?.emergencyContactPhone}
                  label="PH:"
                  type="tel"
                  name="emergencyContactPhone"
                  validations={'required|numeric|min:10|max:10'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
            </TextFieldGrid>
            <TextFieldGrid className="height-note-grid TextFieldGrid">
              <TextFieldWrapper className="TextFieldWrapper NoteWrapper">
                <NoteFieldWrapper className="NoteFieldWrapper">
                  <span className="note-block long-note">
                    <span className="note-head ">YEDA Back #:</span> Returning
                    Members wright your back number, new members leave blank a
                    number will be assigned to you. The $10 for the YEDA Back
                    Number go towards the YEDA Scholarship Incentive Fund.
                  </span>
                </NoteFieldWrapper>
              </TextFieldWrapper>

              <TextFieldWrapper className="TextFieldWrapper flex-TextFieldWrapper height-weight-wrapper">
                <FlexFieldWrapper className="FlexFieldWrapper">
                  <HeightFieldWrapper className="HeightFieldWrapper">
                    <StyledHeightTextField
                      onChange={handleFeetChange}
                      value={heightWeight?.feet}
                      label="Height"
                      type="number"
                      name="height"
                      placeholder="Feet"
                      validations={'required'}
                      validator={simpleValidator.current}
                      className="StyledHeightTextField  StyledHeightTextField-first"
                    />
                    <StyledHeightTextField
                      onChange={handleInchesChange}
                      value={heightWeight?.inches}
                      type="number"
                      name="inch"
                      label="Inches"
                      placeholder="inches"
                      className="StyledHeightTextField"
                    />
                    <NoteFieldWrapper className="NoteFieldWrapper flex-NoteWrapper">
                      <span className="note-block">
                        <span className="note-head"></span> eg. 5ft 10in
                      </span>
                    </NoteFieldWrapper>
                  </HeightFieldWrapper>
                  <StyledWeightTextField
                    onChange={handleWeightChange}
                    label="Weight"
                    value={heightWeight?.weight}
                    type="text"
                    name="weight"
                    placeholder="lbs"
                    validations={'required'}
                    validator={simpleValidator.current}
                    className="StyledWeightTextField"
                  />
                </FlexFieldWrapper>
              </TextFieldWrapper>
            </TextFieldGrid>
          </MainAddressWrapper>
        </BorderWrapper>

        {visible && (
          <BackNumberTableComponent onClose={handleCloseBackNumberPopUp} />
        )}
      </SubMainWrapper>
      <BottomRightWrapper id="BottomRightWrapper">
        <ButtonWrapper>
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={handleNextForm}
            onClickBack={handleBackForm}
            disableNext={valid || categoryCheck?.length === 0}
            next="Next"
            back="Back"
          />
        </ButtonWrapper>
      </BottomRightWrapper>
    </MainWrapper>
  );
};
const InfoLabelWrapper = styled.div`
  padding: 0 20px 10px 20px;
  text-align: start;
  width: 100%;
  div {
    border-bottom: 1px solid #434343;
    padding: 6px 0;
  }
`;
const StyledTextField = styled(TextField)`
  flex: 0 0 50%;
  &.styled-field {
    margin-bottom: 0px !important;
  }
`;
const StyledWeightTextField = styled(TextField)`
  margin: 0px 20px 0px 0px !important;
  input {
    width: 120px;
    height: 35px;
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const MainWrapper = styled.div`
  color: #9597a6;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
  .first-para,
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  @media (min-width: 100px) and (max-width: 767px) {
    padding-top: 25px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    ${StyledTextField} {
      margin: 0px 12px;
    }
    ${StyledWeightTextField} {
      width: 50px;
      /* input {
        width: 50px;
      } */
    }
    .height-weight-wrapper {
      width: 100%;
    }

    .note-block {
      font-size: 10px;
    }
    .inner-BorderWrapper {
      padding: 20px 25px 0px 25px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    ${InfoLabelWrapper} {
      label {
        font-size: 10px !important;
      }
    }
    .height-weight-wrapper {
      .note-block {
        font-size: 11px !important;
        padding-top: 22px !important;
      }
    }
    .note-block {
      font-size: 10px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    ${StyledTextField} {
      margin: 0px 8px;
    }
    .height-weight-wrapper {
      .note-block {
        font-size: 11px !important;
        padding-top: 22px !important;
      }
    }
    .note-block {
      font-size: 10px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .first-para,
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .first-para,
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .first-para,
    label {
      font-size: 14px;
    }
  }
`;
const SubMainWrapper = styled.div`
  height: calc(100vh - 275px);
  width: 100%;
`;
const BorderWrapper = styled.div`
  height: 100%;
  overflow: auto;
  /* border: 1px solid #585858; */
  border-radius: 20px;
  padding: 20px 60px 0px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  position: relative;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: #201e1e !important;
    border-radius: 10px;
  }
  @media (min-width: 100px) and (max-width: 575px) {
    padding: 10px 5px 0px 5px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding: 10px 10px 0px;
  }
`;

const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 35px 0 0;

  /* gap: 15px; */
  @media (min-width: 992px) and (max-width: 1199px) {
    /* gap: 5px; */
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    /* gap: 10px; */
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    /* gap: 15px; */
  }
`;
const NoteFieldWrapper = styled.div`
  margin: 0px 20px;
  text-align: left;
  .note-block {
    /* display: flex;
    align-items: center; */
    font-size: 12px;
    font-style: italic;
    .note-head {
      font-style: normal;
    }
  }
  &.flex-NoteWrapper {
    white-space: nowrap;
    .note-block {
      display: inline-grid;
      font-size: 12px;
      padding-top: 30px;
      .note-head {
      }
    }
  }
`;
const TextFieldWrapper = styled.div`
  /* flex: 0 0 50%;
  margin-bottom: 20px;*/
  width: 50%;
  margin-bottom: 20px;
  &.styled-TextFieldWrapper {
    display: grid;
    align-items: end;
  }

  /* padding: 0px 20px; */
  /* @media (min-width: 320px) and (max-width: 767.98px) {
    width: 100% !important;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 50% !important;
  } */
`;
const FlexFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const HeightFieldWrapper = styled.div`
  display: flex;
`;

const StyledHeightTextField = styled(TextField)`
  margin: 0px 0px 0px 20px !important;
  input {
    width: 60px;
    height: 35px;
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const BackFieldWrapper = styled.div`
  .BackNumber {
    width: 80% !important;
    input {
      width: 100% !important;
    }
  }
  /* @media (min-width: 992px) and (max-width: 1200px) {
    input {
      width: 100% !important;
    }
  }
  @media (min-width: 100px) and (max-width: 991px) {
    input {
      width: 100% !important;
    }
  } */
  width: 80%;
  margin-bottom: 20px;
`;

const MainAddressWrapper = styled.div`
  margin-bottom: 40px;
  .horizontal-line {
    display: flex;
    align-items: baseline;
    margin: 20px 20px 15px 20px;
  }
  .horizontal-line span {
    margin: 0 10px 0 0;
    white-space: nowrap;
    color: #4f4f4f;
    font-size: 15px;
  }
  .horizontal-line::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #434343;
    height: 3px;
  }
`;
const BottomRightWrapper = styled.div`
  z-index: 1;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 20px 20px;
  backdrop-filter: blur(10px);
  position: absolute;
  bottom: 0;
  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    /* position: absolute;
    bottom: 40px;
    padding-top: 30px;
    background: linear-gradient(
      to bottom,
      rgb(48 48 48 / 79%) 0%,
      rgb(42 42 42) 100%
    ); */
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 40px;
    &.bg-color-change {
      bottom: 25px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 45px;
    &.bg-color-change {
      bottom: 30px;
      padding-top: 20px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 60px;
    &.bg-color-change {
      bottom: 20px;
      padding-top: 20px;
    }
  }
`;
const ButtonWrapper = styled.div``;
