import { BackNextButton } from 'app/components/BackNextButton';
import React from 'react';
import styled from 'styled-components';
import { TextField } from 'app/components/TextField';
import '@fontsource/roboto';
import { useState } from 'react';
import SHdropdown, { DropDownModel } from 'app/components/SHdropdown';

type Props = {
  onClickBackSchool?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onClickNxtSchool?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

const TryitSchoolDetails = ({ onClickNxtSchool, onClickBackSchool }: Props) => {
  const [detailsData, setDetailsData] = useState({
    firstName: '',
    lastName: '',
    ph: '',
    email: '',
    streetAddress: '',
    city: '',
    state: '',
    zip: '',
  });
  const handleChange = event => {
    const { name, value } = event.target;
    console.log('changed', value);
    setDetailsData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <MainWrapper>
      <TextFieldGrid>
        <StyledTextField
          onChange={handleChange}
          value={detailsData.firstName || ''}
          label="School Name"
          type="text"
          name="firstName"
        />
      </TextFieldGrid>
      <HomeAddressWrapper className="horizontal-line">
        <p className="home-address">School Address</p>
      </HomeAddressWrapper>
      <TextFieldGrid>
        <StyledTextField
          onChange={handleChange}
          value={detailsData.streetAddress || ''}
          label="Street Address"
          type="text"
          name="streetAddress"
        />
        <StyledTextField
          onChange={handleChange}
          value={detailsData.city || ''}
          label="City"
          type="text"
          name="city"
        />
        <DropdownWrapper>
          <SHdropdown className="drop-down" label="State" />
        </DropdownWrapper>
        <StyledTextField
          onChange={handleChange}
          value={detailsData.zip || ''}
          label="Zip"
          type="text"
          name="zip"
        />
      </TextFieldGrid>
      <ButtonWrapper className="bg-color-change">
        <BackNextButton
          classNameBack="btn-back"
          classNameNxt="btn-nxt"
          onClickNxt={onClickNxtSchool}
          onClickBack={onClickBackSchool}
          next="Next"
          back="Back"
        />
      </ButtonWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  color: #9597a6;
  width: 100%;
  height: calc(100vh - 130px);
  padding: 20px 0px 0px;
  position: relative;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    // padding-top: 20px;
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    // padding-top: 20px;
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    // padding-top: 20px;
    label {
      font-size: 14px;
    }
  }
`;

const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;
const HomeAddressWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  label {
    color: #e98d04;
    font-style: oblique;
  }

  ::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #585858;
  }

  p {
    margin: 0 10px 0px 0px;
    white-space: nowrap;
    color: #4f4f4f;
  }
`;
const StyledTextField = styled(TextField)`
  flex: 0 0 calc(50% - 20px);
  margin: 0px 0px 10px !important;
`;

const DropdownWrapper = styled.div`
  p {
    margin: 0px;
    font-family: 'Roboto';
    font-size: 14px;
  }
  .drop-down {
    margin: 0px;
    width: 326px !important;
    height: 35px;
    color: #9597a6;
  }
`;
const ButtonWrapper = styled.div`
  height: 70px;
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: 40px;
    left: -4px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;

export default TryitSchoolDetails;
