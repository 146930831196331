import { CardSubWrapper, CardWrapper } from 'admin/components/wrappers';
import IconCell from 'app/components/CustomDataGrid/Cells/IconCell';
import CustomDataGrid from 'app/components/CustomDataGrid/Index';
import { CustomDeletePopup } from 'app/components/CustomDeletePopup';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigateToRoute } from 'utils/common';
import {
  GridColumnProps,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import EmailCell from 'app/components/CustomDataGrid/Cells/EmailCell';
import CoachphoneCell from 'app/components/CustomDataGrid/Cells/CoachPhoneCell';
import UserProfileCell from 'app/components/CustomDataGrid/Cells/UserProfileCell';
import UserNameCell from 'app/components/CustomDataGrid/Cells/UserNameCell';
import UserTypeCell from 'app/components/CustomDataGrid/Cells/UserTypeCell';
import { UserListWrapper } from 'services/openapi';
import { SortDescriptor } from '@progress/kendo-data-query';
import { TableSkeletonLoader } from 'app/components/TableSkeletonLoader';
import HorizontalKebabCell from 'app/components/CustomDataGrid/Cells/HorizontalKebabCell';
type Props = {
  setPageSkip: React.Dispatch<React.SetStateAction<number | undefined>>;
  pageSkip: number | undefined;
  debounceUserSearch: string;
  fetchManageGetUser: (
    searchInput?: string | undefined,
    sortBy?: string | undefined,
    sortOrder?: number | undefined,
    pageNumber?: number,
    pageSize?: number,
  ) => void;
  userProfileData?: UserListWrapper | undefined;
  deleteUser: (
    id: string,
    searchInput?: string | undefined,
    sortBy?: string | undefined,
    sortOrder?: number | undefined,
    pageNumber?: number,
    pageSize?: number,
  ) => void;
  sortField: string | undefined;
  sortOrder: number | undefined;
  setSortField: React.Dispatch<React.SetStateAction<string>>;
  setSortOrder: React.Dispatch<React.SetStateAction<number>>;
  loading: boolean;
};
const UserTable = ({
  setPageSkip,
  pageSkip,
  debounceUserSearch,
  fetchManageGetUser,
  userProfileData,
  deleteUser,
  sortField,
  sortOrder,
  setSortField,
  setSortOrder,
  loading,
}: Props) => {
  const redirect = useNavigateToRoute();
  const [showDeletePopup, setShowDeletePopup] = React.useState<boolean>(false);
  const [userId, setUserId] = useState('');
  const initialSort: Array<SortDescriptor> = [{ field: 'name', dir: 'asc' }];
  const [sort, setSort] = React.useState<Array<SortDescriptor>>(initialSort);
  const UserColumn: GridColumnProps[] = [
    {
      field: 'userName',
      title: 'Login',
      cell: e => <UserNameCell e={e} />,
      width: '200',
      sortable: false,
      locked: true,
    },
    {
      field: 'name',
      title: 'Name',
      cell: e => (
        <UserProfileCell e={e} onViewClick={e => handleViewClick(e)} />
      ),
      width: '250',
      locked: true,
    },
    {
      field: 'email',
      title: 'Email',
      cell: e => <EmailCell e={e} />,
      width: '200',
      sortable: false,
    },
    {
      field: 'primaryPhone',
      title: 'Phone',
      cell: e => <CoachphoneCell e={e} />,
      width: '125',
      sortable: false,
    },
    {
      field: 'userType',
      title: 'Types',
      cell: e => <UserTypeCell e={e} />,
      width: '125',
    },
    {
      field: 'action',
      title: 'Action',
      cell: e => (
        <HorizontalKebabCell
          isAssigned={true}
          e={e}
          onMoreClick={handleMoreClick}
          isUser={true}
        />
      ),
      width: '90',
      sortable: false,
    },
  ];
  const handleMoreClick = (item: string, e) => {
    if (item === 'Delete user') {
      setUserId(e.dataItem.id);
      setShowDeletePopup(true);
    } else if (item === 'Edit user info') {
      redirect(`/admin/users/${'update'}/${e.dataItem.id}`);
    }
  };
  const handleViewClick = e => {
    redirect(`/admin/users/${'view'}/${e.id}`);
  };
  const handlePageChange = event => {
    setPageSkip(event.page.skip);
    fetchManageGetUser(
      debounceUserSearch,
      sortField,
      sortOrder,
      event.page.skip,
      10,
    );
  };
  const handleDeleteUserList = () => {
    //delete coaches
    deleteUser(userId, debounceUserSearch, sortField, sortOrder, pageSkip, 10);
    setShowDeletePopup(false);
  };
  const handleSortChange = e => {
    let sortOrderValue = e.dir === 'asc' ? 0 : 1;
    setSortOrder(sortOrderValue);
    setSortField(e.field);
    fetchManageGetUser(
      debounceUserSearch,
      e.field,
      sortOrderValue,
      pageSkip,
      10,
    );
  };

  return (
    <>
      {loading ? (
        <TableSkeletonLoader rowContent={8} width={100} headingWidth={80} />
      ) : (
        <CardWrapper style={{ paddingTop: '15px' }}>
          <CardSubWrapper className="d-flex"></CardSubWrapper>
          <GridWrapper>
            <CustomDataGrid
              showPagination
              data={userProfileData?.users ?? []}
              columns={UserColumn}
              pageSize={10}
              totalCount={userProfileData?.pageDetails?.count}
              onPageChange={event => {
                handlePageChange(event);
              }}
              skip={pageSkip}
              sortable={{
                allowUnsort: false,
                mode: 'single',
              }}
              sort={sort}
              onSortChange={(e: GridSortChangeEvent) => {
                let sortvalue = e.sort[0];
                setSort(e.sort);
                handleSortChange(sortvalue);
              }}
            />
            <CustomDeletePopup
              deleteIcon={true}
              headerUnderline={false}
              popupSubLabel="Are you sure you want to delete this user?"
              className="body-scroll-lock height-full delete-update"
              popupLabel="Delete user"
              setVisible={setShowDeletePopup}
              visible={showDeletePopup}
              align="centre"
              btnLabel1="Cancel"
              btnLabel2="Delete"
              handlebtn2Click={handleDeleteUserList}
              handlebtn1Click={() => {
                setShowDeletePopup(false);
              }}
            />
          </GridWrapper>
        </CardWrapper>
      )}
    </>
  );
};
export default UserTable;

const GridWrapper = styled.div``;
