import { GridCellProps } from '@progress/kendo-react-grid';
import useShowApi from 'admin/hooks/useShowApi';
import { splitNumberLookup } from 'admin/pages/ReportsPage/static';
import { AdminCheckBox } from 'app/components/AdminCheckbox';
import AdminCustomButton from 'app/components/AdminCustomButton';
import AdminDropDown from 'app/components/AdminDropDown';
import { SetStateAction, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GeneratedSplit, RiderSplitClass } from 'services/openapi';
import styled from 'styled-components';

type Props = {
  e: GridCellProps;
  onViewClick?: (e) => void;
  onClassACheckboxChange?: (e: any, event: any, className: any) => void;
  isDisabled?: true;
  onSaveClick?: (requestBody: Array<RiderSplitClass>) => void;
  setSplitData?: React.Dispatch<
    SetStateAction<Array<GeneratedSplit> | undefined>
  >;
  setTransformedData?: React.Dispatch<SetStateAction<Array<any>>>;
  itemIndex?: number | undefined;
  transformedData?: any[];
};

const ManageRiderClassSplitUpdateCell = ({
  e,
  onViewClick,
  onClassACheckboxChange,
  isDisabled,
  onSaveClick,
  setSplitData,
  setTransformedData,
  itemIndex,
  transformedData,
}: Props) => {
  const data = e.dataItem.class;
  const dataindex = e.dataItem.index;
  const [payload, setPayload] = useState<Array<RiderSplitClass>>();
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const currentLocation = useLocation();
  const isPastDataFlag = currentLocation.pathname.includes('past-data');
  let prePayload = data?.map(item => {
    return {
      showRiderId: item?.showRiderId,
      riderClass: item?.riderClass,
      split: item?.split,
      scratch: item?.scratch,
    };
  });

  const onDropdownChange = (e, index, itemIndex) => {
    let newPayload: RiderSplitClass[] = (payload ?? [])
      .filter(
        (item): item is RiderSplitClass =>
          item !== undefined &&
          item.showRiderId !== undefined &&
          item.riderClass !== undefined &&
          item.split !== undefined &&
          item.scratch !== undefined,
      )
      .map((item, i) => {
        if (index === i) {
          return {
            showRiderId: item.showRiderId ?? '',
            riderClass: item.riderClass,
            split: { id: e.id, name: e.name },
            scratch: item.scratch,
          };
        }
        return item; // return unchanged item if index does not match
      });
    if (newPayload) {
      let filteredPayload: RiderSplitClass[] = newPayload.filter(
        (item): item is RiderSplitClass => item !== undefined,
      );
      setPayload(filteredPayload);
    }
    if (!transformedData) return;
    else {
      const mappedData = transformedData.map((item, tindex) => {
        if (tindex === itemIndex) {
          return {
            ...item,
            riders: item.riders.map((rider, rideIndex) => {
              if (rideIndex === dataindex) {
                return {
                  ...rider,
                  class: rider.class.map((cls, clsIndex) => {
                    if (clsIndex === index) {
                      return { ...cls, split: { id: e.id, name: e.name } };
                    }
                    return cls;
                  }),
                };
              }
              return rider;
            }),
          };
        }
        return item;
      });

      setTransformedData?.(mappedData);
    }
  };
  const onSplitCheckboxChange = (e, index) => {
    let newPayload: RiderSplitClass[] = (payload ?? [])
      .filter(
        (item): item is RiderSplitClass =>
          item !== undefined &&
          item.showRiderId !== undefined &&
          item.riderClass !== undefined &&
          item.split !== undefined &&
          item.scratch !== undefined,
      )
      .map((item, i) => {
        if (index === i) {
          return {
            showRiderId: item.showRiderId ?? '',
            riderClass: item.riderClass,
            split: item.split,
            scratch: e.value,
          };
        }
        return item;
      });
    if (newPayload) {
      let filteredPayload: RiderSplitClass[] = newPayload.filter(
        (item): item is RiderSplitClass => item !== undefined,
      );
      setPayload(filteredPayload);
    }
  };
  useEffect(() => {
    setPayload(prePayload);
  }, []);
  return (
    <StyledTd
      style={e.style}
      className={e.className}
      onClick={() => onViewClick?.(data)}
    >
      <CustomTableColumnWrapper>
        <div>
          {data.map((item, index) => {
            const usePrePayload = payload && payload.length > 0;
            const defaultValue = usePrePayload ? payload[index] : item;
            return (
              <SplitWrapper>
                <div>
                  <CheckboxSplitWrapper>
                    <AdminCheckBox
                      onChange={event => {
                        if (event) {
                          if (isPastDataFlag) {
                            return;
                          } else {
                            onClassACheckboxChange?.(
                              e,
                              event,
                              data?.divisionClassOne,
                            );
                          }
                        }
                      }}
                      value={item.riderClass !== null ? true : false}
                      label={`${item?.group + ' ' + item?.riderClass?.name}`}
                      className="checkbox-style"
                    />
                    <AdminDropDown
                      label=""
                      onDataChange={event => {
                        if (event) {
                          onDropdownChange(event, index, itemIndex);
                          setIsSaveDisabled(false);
                        }
                      }}
                      defaultValue={defaultValue.split}
                      placeholder="Choose Split Number"
                      data={splitNumberLookup}
                      className="field-style"
                      disabled={isPastDataFlag ? true : false}
                    />
                  </CheckboxSplitWrapper>
                  {!isDisabled && (
                    <AdminCheckBox
                      onChange={event => {
                        if (event) {
                          if (isPastDataFlag) {
                            return;
                          } else {
                            onSplitCheckboxChange(event, index);
                            setIsSaveDisabled(false);
                          }
                        }
                      }}
                      value={defaultValue.scratch}
                      label="Scratch"
                      className="checkbox-style"
                    />
                  )}
                </div>
              </SplitWrapper>
            );
          })}
        </div>
        {!isPastDataFlag && !isDisabled && (
          <ButtonWrapper>
            <AdminCustomButton
              buttonType="save"
              onClick={() => onSaveClick?.(payload ?? [])}
              disabled={isSaveDisabled}
            >
              Save
            </AdminCustomButton>
          </ButtonWrapper>
        )}
      </CustomTableColumnWrapper>
    </StyledTd>
  );
};

export default ManageRiderClassSplitUpdateCell;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;
const StyledTd = styled.td`
  .checkbox-style label {
    display: block !important;
  }
`;
const CustomTableColumnWrapper = styled.div`
  display: flex;
  gap: 20px;
  .SHdropdown {
    width: 60%;
    .k-dropdownlist {
      max-height: 31px;
      width: 200px;
      border-radius: 8px !important;
      font-size: 13px;
      .k-input-value-text {
        font-size: 13px;
        font-family: 'Inter';
        cursor: pointer;
        font-weight: 500;
        line-height: 19.6px;
      }
    }
  }
`;
const SplitWrapper = styled.div`
  display: block;
  gap: 20px;
  .checkbox-style {
    label {
      width: 175px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;
const CheckboxSplitWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
