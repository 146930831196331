import React, { useState } from 'react';
import styled from 'styled-components';
import '@fontsource/oswald';
import '@fontsource/roboto';
import { ShowyedaLogo } from 'app/components/ShowyedaLogo';
import { Outlet } from 'react-router-dom';
import tryitImg from './assets/img/bg/tryit.png';
import horseBg from './assets/img/bg/ewdReturning.jpg';
import { useSelector } from 'react-redux';
import { selectFormValue } from 'app/pages/EWDMembershipFormPage/slice/selectors';

type Props = {};

const TryitEWDMembershipLayout = (props: Props) => {
  const formValue = useSelector(selectFormValue);
  return (
    <MainWrapper>
      <LeftWrapper className="LeftWrapper">
        <ShowyedaLogo />
        <div className="left-header-block"></div>

        <BottomImgWrapper className="BottomImgWrapper">
          <TryImg src={tryitImg} />
        </BottomImgWrapper>
      </LeftWrapper>
      <RightWrapper className="RightWrapper">
        <BorderWrapper className="BorderWrapper">
          <TopWrapper className="TopWrapper">
            <h3 className="toplabel-header">
              YEDA Equestrian with Disabilities (EWD) <br />
              {formValue && formValue >= 4 ? 'Diagnosis Form ' : ''}
              TRY IT 2023-2024
            </h3>
          </TopWrapper>
          <Outlet />
        </BorderWrapper>
      </RightWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  @media (min-width: 100px) and (max-width: 319px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 12px;
      }
    }
    .LeftWrapper {
      display: none;
    }
    .RightWrapper {
      width: 100%;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 13px;
      }
    }
    .LeftWrapper {
      display: none;
    }
    .RightWrapper {
      width: 100%;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .LeftWrapper {
      display: none;
      .left-header-block {
        span,
        p {
          font-size: 13px;
        }
      }
    }
    .RightWrapper {
      width: 100%;
    }
    .TopWrapper {
      top: -10px;
      .toplabel-header {
        font-size: 14px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .LeftWrapper {
      .left-header-block {
        span,
        p {
          font-size: 14px;
        }
      }
    }
    .TopWrapper {
      .toplabel-header {
        font-size: 18px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .LeftWrapper {
      .left-header-block {
        span,
        p {
          font-size: 14px;
        }
      }
    }
    .TopWrapper {
      .toplabel-header {
        font-size: 19px;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .LeftWrapper {
      .left-header-block {
        span,
        p {
          font-size: 15px;
        }
      }
    }
    .TopWrapper {
      .toplabel-header {
        font-size: 20px;
      }
    }
  }
  @media (min-width: 1366px) and (max-width: 1449px) {
    .LeftWrapper {
      .left-header-block {
        span,
        p {
          font-size: 16px;
        }
      }
    }
    .TopWrapper {
      .toplabel-header {
        font-size: 21px;
      }
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    .LeftWrapper {
      .left-header-block {
        span,
        p {
          font-size: 17px;
        }
      }
    }
    .TopWrapper {
      .toplabel-header {
        font-size: 23px;
      }
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    .LeftWrapper {
      .left-header-block {
        span,
        p {
          font-size: 19px;
        }
      }
    }
    .TopWrapper {
      .toplabel-header {
        font-size: 25px;
      }
    }
  }
`;
const LeftWrapper = styled.div`
  width: 36%;
  /* background: #1b1b1b; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: url(${horseBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  /* padding: 60px 35px 0px; */
  .left-header-block {
    background: black;
    opacity: 0.7;
    padding: 0 20px;
    position: absolute;
    top: 30%;
  }
  p {
    color: #898989;
    font-family: 'Oswald';
    font-size: 14px;
    text-align: center;
  }
  span {
    color: #ffffff;
  }
`;
const BottomImgWrapper = styled.div`
  /* position: relative;
  width: 100%;
  height: auto;
  display: grid;
  place-items: center; */
  filter: drop-shadow(2px 4px 6px black);
  position: absolute;
  top: 22%;
  right: 2%;
  width: 25%;
  @media (min-width: 576px) and (max-width: 767px) {
    top: 27%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    top: 25%;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 25%;
    top: 24%;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    width: 23%;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    width: 23%;
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    width: 21%;
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    width: 20%;
  }
`;
const ImgTag = styled.img`
  width: 100%;
  max-width: 530px;
  min-height: calc(100vh - 367px);
  max-height: 500px;
  object-fit: contain;
  object-position: center;
  @media (min-width: 576px) and (max-width: 767px) {
    max-height: calc(100vh - 350px);
    width: 160px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    max-height: calc(100vh - 338px);
    width: 180px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 210px;
    max-height: calc(100vh - 266px);
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    width: 250px;
    max-height: calc(100vh - 274px);
  }
  @media (min-width: 1366px) and (max-width: 1439px) {
    width: 280px;
    height: calc(100vh - 304px);
  }
  @media (min-width: 1440px) and (max-width: 1719px) {
    width: 360px;
    height: calc(100vh - 304px);
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    width: 400px;
    height: calc(100vh - 304px);
  }
`;
const TryImg = styled.img`
  width: 100%;
  /* position: absolute; */
  z-index: 2;
  top: 10%;
  left: 30%;
  @media (min-width: 576px) and (max-width: 767px) {
    /* width: 37%; */
    top: 10%;
    left: 30%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    /* width: 30%; */
    top: 10%;
    left: 30%;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    /* width: 23%; */
    top: 10%;
    left: 30%;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    /* width: 20%; */
    top: 10%;
    left: 30%;
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    /* width: 23%; */
    bottom: 68%;
    left: 12%;
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    /* width: 23%; */
    top: 10%;
    left: 30%;
  }
`;
const RightWrapper = styled.div`
  width: 64%;
  height: 100%;
  background: #2a2a2a;
  padding: 30px 35px 20px;
  p {
    color: #9597a6;
    font-family: 'Roboto';
    font-size: 14px;
  }
  // display: flex;
  // flex-direction: column;
  // align-items: center;

  @media (min-width: 100px) and (max-width: 319px) {
    padding: 30px 15px 20px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding: 30px 15px 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 30px 15px 20px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding: 30px 15px 20px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 30px 15px 20px;
  }
`;
const BorderWrapper = styled.div`
  height: 100%;
  border: 1px solid #585858;
  border-radius: 20px;
  padding: 20px 20px 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  position: relative;
  @media (min-width: 100px) and (max-width: 319px) {
    padding: 20px 20px 0px 20px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    padding: 20px 20px 0px 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 20px 20px 0px 20px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding: 20px 20px 0px 20px;
  }
`;

const TopWrapper = styled.div`
  background: #2a2a2a;
  padding: 0px 10px;
  position: absolute;
  top: -12px;
  z-index: 2;
  h3 {
    font-weight: 500;
    color: #ffffff;
    margin: 0px !important;
    font-family: 'Roboto';
    text-align: center;
  }
`;

export default TryitEWDMembershipLayout;
