import { ShowyedaLogo } from 'app/components/ShowyedaLogo';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout';
import SearchTextField from 'app/components/SearchTextField';
import SHdropdown from 'app/components/SHdropdown';
import { BackNextButton } from 'app/components/BackNextButton';
import { AddRemoveIconButton } from 'app/components/AddRemoveIconButton';
import { SHToggleButton } from 'app/components/ToggleButton';
import { CheckBox } from 'app/components/CheckBox';
import addIcon from './assets/img/icon/add.png';
import removeIcon from './assets/img/icon/remove.png';
import downloadIcon from './assets/img/icon/download.png';
import deleteIcon from './assets/img/icon/delete.png';
import { ProfileDropdown } from '../RiderLandingPage/components/PopupLogout';
import notiticationIcon from './assets/img/icon/notifications.png';
import notiticationDotIcon from './assets/img/icon/notifications-dot.png';
import AddDocumentPopup from 'app/components/AddDocumentPopup';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from './assets/img/icon/logout.png';
import DocumentIcon from './assets/img/icon/document.png';
import { useManagePageSlice } from '../slice';
import {
  DocumentService,
  GeneralLookup,
  LookupService,
} from 'services/openapi';
import { useDispatch, useSelector } from 'react-redux';
import useApi from 'services/mavapi/useApi';

import {
  Avatar,
  AppBar,
  AppBarSection,
  AppBarSpacer,
} from '@progress/kendo-react-layout';
import { SvgIcon } from '@progress/kendo-react-common';
import { menuIcon } from '@progress/kendo-svg-icons';
import { Popup } from '@progress/kendo-react-popup';
import { Document } from './components/Document';
import CircularLoader from 'app/components/CircularLoader';
import LoaderWrapper from 'app/components/CircularLoader/LoaderWrapper';
import { selectPageLoader } from '../slice/selectors';
import isApiError from 'utils/isApiError';
import useToast from 'hooks/Toast';
import { useErrorHandlingSlice } from 'app/error/slice';

type Props = {};
export type DefaultValueItemType = {
  id: string;
  name: string;
  filePath: string;
  season: {
    id: string;
    name: string;
    code: null;
    children: null;
    otherName: null;
    isAdminLive: null;
  };
  type: {
    id: string;
    name: string;
    code: null;
    children: null;
    otherName: null;
    isAdminLive: null;
  };
  isActive: boolean;
  createdOn: string;
  modifiedOn: string;
};
const defaultValue: DefaultValueItemType[] = [
  {
    id: '',
    name: '',
    filePath: '',
    season: {
      id: '',
      name: '',
      code: null,
      children: null,
      otherName: null,
      isAdminLive: null,
    },
    type: {
      id: '',
      name: '',
      code: null,
      children: null,
      otherName: null,
      isAdminLive: null,
    },
    isActive: false,
    createdOn: '',
    modifiedOn: '',
  },
];
const AdminPanelPage = (props: Props) => {
  const dispatch = useDispatch();
  const { error: apiError, handleRequest } = useApi();
  const { lookupGetAllLookups } = LookupService;
  const { documentListDocuments } = DocumentService;
  const { actions: PageActions } = useManagePageSlice();
  const [selected, setSelected] = React.useState<number>(0);
  const [showProfileDropDown, setShowProfileDropDown] = React.useState(false);
  const [isNewNotifications, setIsNewNotifications] = useState(true);
  const [isRemoveClicked, setIsRemoveClicked] = useState(false);
  const [showAddDocument, setShowAddDocument] = React.useState<boolean>(false);
  const [documentData, setDocumentData] = useState<{
    season: GeneralLookup[];
    type: GeneralLookup[];
    file: Blob | undefined;
  }>({
    season: [],
    type: [],
    file: undefined,
  });
  const [seasonData, setSeasonData] = useState<GeneralLookup>({
    id: '',
    name: '',
    code: '',
    children: null,
    otherName: '',
    isAdminLive: null,
  });
  const [typeData, setTypeData] = useState<GeneralLookup>({
    id: '',
    name: '',
    code: '',
    children: null,
    otherName: '',
    isAdminLive: null,
  });
  const navigate = useNavigate();
  const menuRef = React.useRef(null);
  const imgRef = React.useRef(null);
  const [show, setShow] = React.useState(false);
  const anchor = React.useRef<HTMLButtonElement>(null);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [fileData, setFileData] =
    useState<Array<DefaultValueItemType>>(defaultValue);
  const pageLoader = useSelector(selectPageLoader);
  const [, setError] = React.useState<unknown | null>();
  const { actions } = useErrorHandlingSlice();

  window.addEventListener('click', e => {
    if (e.target !== menuRef.current && e.target !== imgRef.current) {
      setShowProfileDropDown(false);
    }
  });
  //HAndle get lookups
  const handleGetLookups = () => {
    dispatch(PageActions.setPageLoader(true));
    handleRequest(lookupGetAllLookups('SEASON,DOCUMENT_TYPES'))
      .then(res => {
        if (res) {
          let seasonData = res?.filter(item => {
            if (item?.type === 'SEASON') {
              return item.values;
            }
          });
          let typeData = res?.filter(item => {
            if (item?.type === 'DOCUMENT_TYPES') {
              return item.values;
            }
          });
          console.log(typeData, 'typeData');
          setDocumentData(prevData => ({
            ...prevData,
            season: seasonData[0]?.values,
          }));
          setDocumentData(prevData => ({
            ...prevData,
            type: typeData[0]?.values,
          }));
          console.log(documentData, 'documentData');

          dispatch(PageActions.setPageLoader(false));
        }
      })

      .catch(error => {
        // dispatch(PageActions.setPageLoader(false));
      });
  };
  const handleProfileClick = () => {
    setShowProfileDropDown(!showProfileDropDown);
  };

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };

  const handleRemoveClick = e => {
    setIsRemoveClicked(!isRemoveClicked);
  };
  const handleChangePassword = () => {};

  const handleLogout = () => {
    navigate('/login');
  };
  const handleClick = () => {
    setShow(!show);
  };

  const handleGetFileList = () => {
    handleRequest(documentListDocuments(seasonData.id, typeData.id))
      .then(res => {
        if (res && !isApiError(res)) {
          console.log(res, 'res');
          setFileData(res);
        } else {
          dispatch(
            actions.dispatchToast({
              errorTxt: res.description,
              show: true,
              errorType: 'error',
            }),
          );
        }
      })
      .catch(error => setError(apiError));
  };
  // const handleToggleChange = e => {
  //   handleGetFileList();
  // };
  useEffect(() => {
    handleGetLookups();
  }, []);
  useEffect(() => {
    handleGetFileList();
  }, [seasonData, typeData]);
  console.log(fileData, 'fileData');
  return (
    <>
      {pageLoader ? (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      ) : (
        <MainWrapper className="MainWrapper">
          <HeaderWrapper>
            <LogoWrapper>
              <ShowyedaLogo />
              <SearchTextField placeholder="Search" />
            </LogoWrapper>
            <LogoMenuWrapper>
              <NotificationWrapper>
                {isNewNotifications ? (
                  <div className="Notification-img-wrapper">
                    <img
                      src={notiticationDotIcon}
                      alt="notitication-dot-icon"
                    />
                  </div>
                ) : (
                  <div className="Notification-img-wrapper">
                    <img src={notiticationIcon} alt="notitication-icon" />
                  </div>
                )}
              </NotificationWrapper>
              <div className="logout-btn">
                <button onClick={handleProfileClick} ref={imgRef}>
                  {/* {getFirstLetter} */}E
                </button>
              </div>
              <p>Admin</p>
              {showProfileDropDown && (
                <ProfileDropdown
                  setShowProfileDropDown={setShowProfileDropDown}
                  menuRef={menuRef}
                  onChangePassword={handleChangePassword}
                />
              )}
            </LogoMenuWrapper>
          </HeaderWrapper>
          <ContentWrapper className="ContentWrapper">
            <AppBar>
              <AppBarSection className="appbar-items large-screen">
                <ul>
                  <li
                    onClick={e => {
                      setSelected(0);
                    }}
                    className={selected == 0 ? 'active' : ''}
                  >
                    <TabTitleWrapper>
                      <div>
                        <img src={DocumentIcon} alt="document-icon" />
                      </div>
                      <span>Document</span>
                    </TabTitleWrapper>
                  </li>
                  <li
                    onClick={e => {
                      setSelected(1);
                    }}
                    className={selected == 1 ? 'active' : ''}
                  >
                    <TabTitleWrapper>
                      <div>
                        <img src={DocumentIcon} alt="document-icon" />
                      </div>
                      <span>Menu 1</span>
                    </TabTitleWrapper>
                  </li>
                  <li
                    onClick={e => {
                      setSelected(2);
                    }}
                    className={selected == 2 ? 'active' : ''}
                  >
                    <TabTitleWrapper>
                      <div>
                        <img src={DocumentIcon} alt="document-icon" />
                      </div>
                      <span>Menu 2</span>
                    </TabTitleWrapper>
                  </li>
                  <li
                    onClick={e => {
                      setSelected(3);
                    }}
                    className={selected == 3 ? 'active' : ''}
                  >
                    <TabTitleWrapper>
                      <div>
                        <img src={DocumentIcon} alt="document-icon" />
                      </div>
                      <span>Menu 3</span>
                    </TabTitleWrapper>
                  </li>
                  <li
                    onClick={e => {
                      setSelected(4);
                    }}
                    className={selected == 4 ? 'active' : ''}
                  >
                    <TabTitleWrapper>
                      <div>
                        <img src={DocumentIcon} alt="document-icon" />
                      </div>
                      <span>Menu 4</span>
                    </TabTitleWrapper>
                  </li>
                </ul>
                <LogoutWrapper onClick={handleLogout}>
                  <div className="logout-img-wrapper">
                    <img src={LogoutIcon} alt="Logout-icon" />
                  </div>
                  <p>Logout</p>
                </LogoutWrapper>
              </AppBarSection>
              <AppBarSection className="small-screen-menu">
                <button
                  className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base overflow-button"
                  ref={anchor}
                  onClick={handleClick}
                >
                  <SvgIcon icon={menuIcon} />
                </button>
                <StyledPopup
                  anchor={anchor.current}
                  show={show}
                  style={{ marginLeft: -10, top: '75px !important' }}
                >
                  <div className="content">
                    <ul>
                      <li
                        onClick={e => {
                          setSelected(0);
                        }}
                        className={selected == 0 ? 'active' : ''}
                      >
                        <TabTitleWrapper>
                          <div>
                            <img src={DocumentIcon} alt="document-icon" />
                          </div>
                          <span>Document</span>
                        </TabTitleWrapper>
                      </li>
                      <li
                        onClick={e => {
                          setSelected(1);
                        }}
                        className={selected == 1 ? 'active' : ''}
                      >
                        <TabTitleWrapper>
                          <div>
                            <img src={DocumentIcon} alt="document-icon" />
                          </div>
                          <span>Menu 1</span>
                        </TabTitleWrapper>
                      </li>
                      <li
                        onClick={e => {
                          setSelected(2);
                        }}
                        className={selected == 2 ? 'active' : ''}
                      >
                        <TabTitleWrapper>
                          <div>
                            <img src={DocumentIcon} alt="document-icon" />
                          </div>
                          <span>Menu 2</span>
                        </TabTitleWrapper>
                      </li>
                      <li
                        onClick={e => {
                          setSelected(3);
                        }}
                        className={selected == 3 ? 'active' : ''}
                      >
                        <TabTitleWrapper>
                          <div>
                            <img src={DocumentIcon} alt="document-icon" />
                          </div>
                          <span>Menu 3</span>
                        </TabTitleWrapper>
                      </li>
                      <li
                        onClick={e => {
                          setSelected(4);
                        }}
                        className={selected == 4 ? 'active' : ''}
                      >
                        <TabTitleWrapper>
                          <div>
                            <img src={DocumentIcon} alt="document-icon" />
                          </div>
                          <span>Menu 4</span>
                        </TabTitleWrapper>
                      </li>
                    </ul>
                    <LogoutWrapper onClick={handleLogout}>
                      <div className="logout-img-wrapper">
                        <img src={LogoutIcon} alt="Logout-icon" />
                      </div>
                      <p>Logout</p>
                    </LogoutWrapper>
                  </div>
                </StyledPopup>
              </AppBarSection>
            </AppBar>
            <RightWrapper className="RightWrapper">
              <SearchTextField placeholder="Search" className="search-field" />
              {selected === 0 && (
                <Document
                  setTypeData={setTypeData}
                  typeData={typeData}
                  setSeasonData={setSeasonData}
                  seasonData={seasonData}
                  documentData={documentData}
                  setDocumentData={setDocumentData}
                  setIsRemoveClicked={setIsRemoveClicked}
                  setShowAddDocument={setShowAddDocument}
                  isRemoveClicked={isRemoveClicked}
                  showAddDocument={showAddDocument}
                  setIsActive={setIsActive}
                  isActive={isActive}
                  fileData={fileData}
                  setFileData={setFileData}
                  // onToggleChange={handleToggleChange}
                  handleGetFileList={handleGetFileList}
                />
              )}
            </RightWrapper>
          </ContentWrapper>
        </MainWrapper>
      )}
    </>
  );
};

export default AdminPanelPage;
const StyledPopup = styled(Popup)`
  &.k-animation-container {
    top: 75px !important;
    width: 150px;
  }
  .k-popup {
    border-color: unset;
  }
  ul {
    margin: unset;
    padding: 10px 5px;
    /* text-align: left; */
  }
  li {
    list-style: none;
    padding: 5px 0px;
    color: rgb(255 255 255 / 87%);
    &.active {
      background-color: #001fff !important;
      border-radius: 8px;
    }
  }

  .content {
    background-color: #2a2a2a;
    padding: 10px;
  }
`;
const MainWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: block;
  background-color: #1b1b1b;
  position: relative;
`;
const HeaderWrapper = styled.div`
  padding: 10px 50px;
  height: 15%;
  background-color: #1b1b1b;
  display: flex;
  align-items: center;
  gap: 20px;
`;
const LogoWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 45%;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  .logo {
    img {
      width: 70px;
    }
  }
  .MuiInputBase-root {
    height: 50%;
    width: 55%;
    .MuiInputBase-input {
      font-family: 'Roboto' !important;
      font-size: 10px;
      opacity: 1;
      color: #717171;
      padding-left: unset;
    }
    padding-left: 20px;
    .MuiButtonBase-root {
      width: 30px;
      height: auto;
      svg {
        filter: brightness(0.4);
      }
    }
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .MuiInputBase-root {
      display: none;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .MuiInputBase-root {
      display: none;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .MuiInputBase-root {
      display: none;
    }
  }
`;
const LogoMenuWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: end;
  align-items: center;
  width: 55%;
  height: 100%;
  .logout-btn {
    padding-right: 10px !important;
  }
  p {
    color: #8b8b8b;
  }
  .logout-btn {
    display: flex;
    justify-content: end;
    padding: 20px;
    button {
      cursor: pointer;
      font-size: 20px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      border: none;
      box-shadow: none;
    }
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: end;
    background-color: #ffffff;
    height: 70px;
    width: 100%;
  }
  .logo {
    position: absolute;
    top: 5px;
    left: 20px;
    padding: 0px !important;
    img {
      width: 80px;
      width: auto;
    }
  }
  @media (min-width: 100px) and (max-width: 319px) {
  }
  @media (min-width: 320px) and (max-width: 575px) {
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .logout-btn {
      button {
        width: 30px;
        height: 30px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .logout-btn {
      button {
        width: 30px;
        height: 30px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .logout-btn {
      button {
        width: 35px;
        height: 35px;
      }
      padding-right: 25px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .logout-btn {
      button {
        width: 35px;
        height: 35px;
      }
      padding-right: 25px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .logout-btn {
      button {
        width: 40px;
        height: 40px;
      }
      padding-right: 30px;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    .logout-btn {
      button {
        width: 40px;
        height: 40px;
      }
      padding-right: 35px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    .logout-btn {
      button {
        width: 45px;
        height: 45px;
      }
      padding-right: 40px;
    }
  }
`;
const NotificationWrapper = styled.div`
  background-color: #363636;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  .Notification-img-wrapper {
    width: 20px;
    height: auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
`;
const ContentWrapper = styled.div`
  width: 100%;
  height: 85%;
  display: flex;
  padding-bottom: 20px;
  gap: 20px;
  background-color: #1b1b1b;
  .k-appbar {
    background-color: #2a2a2a;
    width: 15%;
    height: 100%;
    padding: 0px 30px;
    /* position: relative; */
    /* border: 1px solid #707070; */
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-color: unset;
  }
  .appbar-items {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  ul {
    list-style-type: none;
    padding-left: unset;
    width: 100%;
  }
  li {
    padding: 10px 20px;
    &.active {
      background-color: #001fff !important;
      border-radius: 8px;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media (min-width: 100px) and (max-width: 767px) {
    .small-screen-menu {
      position: absolute;
      left: 0px;
      top: 25px;
      z-index: 5;
    }
    .large-screen {
      display: none;
    }
    .k-svg-icon > svg {
      filter: invert(0.9);
    }
    .k-appbar {
      width: 0px;
      height: 0px;
      padding: 0 10px !important;
    }
    &.ContentWrapper {
      gap: unset !important;
      width: 100%;
      padding-right: 20px;
    }
    .RightWrapper {
      width: 100%;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .user-actions .k-badge-container,
    .k-appbar-separator,
    .k-avatar {
      display: block;
      height: 100%;
    }
    .overflow-button {
      display: none;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .k-appbar {
      width: 18%;
      padding: 0px 15px;
    }
  }
  @media (min-width: 992px) and (max-width: 1365px) {
    .k-appbar {
      padding: 0px 20px;
    }
  }
`;
const TabTitleWrapper = styled.div`
  display: flex;
  gap: 10px;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  width: 100%;
  > div {
    width: 13px;
    height: auto;
    img {
      width: 100%;
      height: auto;
      filter: contrast(0.5);
    }
  }
  span {
    width: 80%;
    color: rgb(255 255 255 / 87%);
  }
  @media (min-width: 100px) and (max-width: 319px) {
    span {
      width: 50%;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    span {
      width: 50%;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    span {
      width: 50%;
    }
  }
`;
const RightWrapper = styled.div`
  height: 100%;
  padding: 10px 120px 10px 30px;
  background-color: #161616;
  width: 85%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  .search-field {
    display: none;
  }

  @media (min-width: 100px) and (max-width: 319px) {
    padding: 5px 5px 5px 5px !important;
    position: relative;
    .search-field {
      display: flex;
      position: absolute;
      top: 25px;
      right: 25px;
    }
    .MuiInputBase-root {
      width: 50%;
      height: 7%;
    }
    .MuiInputBase-input {
      padding: unset !important;
      font-family: 'Roboto' !important;
      font-size: 10px;
      opacity: 1;
    }
    .MuiButtonBase-root {
      padding: 0px !important;
      svg {
        width: 13px;
        height: auto;
        filter: brightness(0.4);
      }
    }
  }

  @media (min-width: 320px) and (max-width: 575px) {
    padding: 10px 10px 10px 10px !important;
    position: relative;
    .search-field {
      display: flex;
      position: absolute;
      top: 20px;
      right: 10px;
    }
    .MuiInputBase-root {
      width: 50%;
      height: 8%;
    }
    .MuiInputBase-input {
      padding: unset !important;
      font-family: 'Roboto' !important;
      font-size: 10px;
      opacity: 1;
    }
    .MuiButtonBase-root {
      padding: 0px !important;
      svg {
        width: 15px;
        height: auto;
        filter: brightness(0.4);
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 10px 30px 10px 15px !important;
    position: relative;
    .search-field {
      display: flex;
      position: absolute;
      top: 20px;
      right: 50px;
    }
    .MuiInputBase-input {
      padding: unset !important;
      font-family: 'Roboto' !important;
      font-size: 12px;
      opacity: 1;
    }
    .MuiInputBase-root {
      width: 35%;
      height: 8%;
      svg {
        width: 20px;
        height: auto;
        filter: brightness(0.4);
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 82%;
    padding: 10px 80px 10px 20px !important;
  }
`;
const LogoutWrapper = styled.div`
  width: 100%;
  height: 20%;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0px 10px;
  border-top: 1px solid #505050;
  .logout-img-wrapper {
    width: 20px;
    height: auto;
    cursor: pointer;

    img {
      width: 100%;
      height: auto;
    }
  }
  p {
    cursor: pointer;
    color: #7e9fff;
    margin: 10px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .logout-img-wrapper {
      width: 15px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .logout-img-wrapper {
      width: 15px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .logout-img-wrapper {
      width: 15px;
    }
  }
`;
