import styled from 'styled-components';
import { ReactComponent as HorseProfile } from '../../../admin/assets/icon/default-upload-icon.svg';
import { CameraIcon } from 'app/assets/icons';
import { useRef } from 'react';
import AdminCustomButton from '../AdminCustomButton';
import Skeleton from 'react-loading-skeleton';
import { FormSkeletonLoader } from '../FormSkeletonLoader';

interface Props {
  className?: string;
  onSelectFile?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setImgSrc?: React.Dispatch<React.SetStateAction<string | null>>;
  imgSrc?: string | null;
  content?: string;
  title?: string;
  uploadButtonLabel?: string;
  uploadButtonType?:
    | 'primary'
    | 'secondary'
    | 'icon'
    | 'edit'
    | 'card'
    | 'nav-icon'
    | 'delete'
    | 'cancel'
    | 'save'
    | 'uploadFile';
  uploadSvgIcon?: React.ReactNode;
  isImgErrorMessage?: boolean;
  imageWidth?: number;
  imageHeight?: number;
  editWidth?: number;
  editHeight?: number;
  isLoader?: boolean;
}

export const ImageUpload = ({
  className,
  onSelectFile,
  setImgSrc,
  imgSrc,
  uploadButtonLabel,
  uploadButtonType,
  uploadSvgIcon,
  isImgErrorMessage,
  imageWidth,
  imageHeight,
  editWidth,
  editHeight,
  isLoader,
}: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    onSelectFile?.(e);

    if (e.target.files && e.target.files.length > 0) {
      let fileUrl = URL.createObjectURL(e?.target?.files?.[0]);
      setImgSrc?.(fileUrl ?? '');
    }
    // Clear the file input
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  }
  const handleIconClick = e => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <Wrapper className={uploadButtonLabel ? 'upload-button-style' : ''}>
      <ImageWrapper
        className={
          isLoader ? 'upload-user-image-style image-upload' : 'image-upload'
        }
      >
        {isLoader ? (
          <>
            <Skeleton
              width={imageWidth ? imageWidth : 130}
              height={imageHeight ? imageHeight : 130}
              className="profile-img"
            />
            <Skeleton
              width={editWidth ? editWidth : 34.37}
              height={editHeight ? editHeight : 34.37}
              className="camera-icon"
            />
          </>
        ) : (
          <>
            <div className="profile-img">
              {imgSrc ? (
                <img src={imgSrc!} alt="" />
              ) : (
                <HorseProfile className="default-img" />
              )}
            </div>
            <div className="border-div"></div>
            <input
              ref={inputRef}
              type="file"
              id="input-file-upload"
              onChange={handleChange}
              accept="image/*"
            />
            {uploadButtonLabel && (
              <div className="camera-icon" onClick={handleIconClick}>
                <CameraIcon width="20px" height="20px" />
              </div>
            )}
          </>
        )}
      </ImageWrapper>
      {isLoader ? (
        <FormSkeletonLoader textHeight={44} labelHeight={20} />
      ) : (
        <>
          {uploadButtonLabel && (
            <div className="button-error-wrapper">
              <AdminCustomButton
                buttonType={uploadButtonType}
                svgIcon={uploadSvgIcon}
                onClick={handleIconClick}
              >
                {uploadButtonLabel}
              </AdminCustomButton>
              {isImgErrorMessage && (
                <span className="validation-label">Image is required.</span>
              )}
            </div>
          )}
        </>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  &.upload-button-style {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    gap: 10px;
    .button-error-wrapper {
      position: relative;
      .validation-label {
        color: #f44336;
        font-size: 13px;
        position: absolute;
        bottom: -25px;
        font-family: 'Inter';
        padding-top: 20px;
        left: 15px;
      }
    }
  }
`;
const ImageWrapper = styled.div`
  position: relative;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 130px;
  .profile-img {
    border-radius: 50%;
  }
  &.upload-user-image-style {
    gap: 10px;
  }
  .border-div {
    border: 5px solid rgba(71, 100, 255, 0.62);
    border-radius: 50%;
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
  }
  .camera-icon {
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: 2;
    background-color: rgba(127, 145, 243, 1);
    border-radius: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  input {
    display: none;
  }
  .profile-img {
    .default-img {
      left: 7px;
    }
    svg,
    img {
      display: flex;
      align-items: center;
      justify-content: center;
      object-fit: cover;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
`;
