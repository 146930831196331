import { createGlobalStyle } from 'styled-components';
import '@fontsource/arimo';
import '@fontsource/inter';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    position: relative;
    background-color: #2a2a2a;
  }

  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
 
    @media (min-height: 100px) and (max-height: 560px) {
      overflow: hidden;
    }
    .logout-popup {
    .k-dialog {
      min-width: 25%;
      .k-icon-button{
        padding: 0rem  0.375rem  0.375rem  0.375rem;
        &.k-button-flat:focus::after, &.k-button-flat.k-focus::after{
          opacity: 0 !important;
        }
      }
     .k-button.k-hover::before {
        opacity: 0 !important;
      }
      .k-button:active::before, .k-button.k-active::before {
        opacity: 0 !important;

      }
      .k-button:hover::before, .k-button.k-hover::before {
       opacity: 0 !important;
      }
      .k-button-icon{
        min-width: 1.25rem;
        min-height: 1.25rem;
      }
    }
  }
  }

  #root {
    min-height: 100%;
    min-width: 100%;
    background-color: #2a2a2a;
  }

  p,
  label {
    font-family: 'arimo';
    line-height: 1.5em;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }
  .k-calendar-container {
    .k-popup {
      border-color: transparent !important;
      background-color: transparent !important;
      .custom-datepicker{
        .k-calendar-yearview{
          button{
            font-family: 'Inter';
          }
          table{
            font-family: 'Inter';
            tr td span{
              font-family: 'Inter';
            }
          }
        }
        .k-calendar-monthview{
          button{
            font-family: 'Inter';
          }
          table{
            font-family: 'Inter';
            tr td span{
              font-family: 'Inter';
            }
          }
        }
      }
    }
  }
  .admin-dropdown-component-custom {
  z-index: 10002 !important;
  .k-popup {
    z-index: 900 !important;
    border-radius: 5px !important;
    border: 1px solid #caced8 !important;
    overflow: auto !important;
    margin: 4px 0 0 0 !important;
    background: #ffffff !important;
    font-family: 'Inter' !important;
    font-weight: 500 !important;
    font-size: 14px !important;

    .k-list-filter {
      padding: 0.5rem !important;
    }

    .k-input-solid {
      outline: none;
      box-shadow: none;
      border: 1px solid #caced8 !important;
      border-radius: 5px;
      background: #dad4d445;

      .k-input-prefix {
        margin-left: 10px;
      }
    }

    .k-list {
      max-height: 200px;
      background: transparent !important;
      color: #171717 !important;

      .k-list-content {
        max-height: unset;
        border-radius: 5px;
        border: none;

        ::-webkit-scrollbar {
          width: 5px;
          margin-left: 3px;
        }

        ::-webkit-scrollbar-track {
          background: transparent !important;
          border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb {
          width: 9px;
          background: #e5e5e5 !important;
          border-radius: 10px;
        }

        .k-list-ul {
          list-style: none !important;
          padding: 0 0 !important;

          li {
            padding: 10px 9px !important;
            background: transparent;
            border-bottom: none;
            cursor: pointer !important;

            :nth-child(1) {
              border-radius: 0;
              box-shadow: none;
            }

            :nth-last-child(1) {
              border-radius: 0;
              box-shadow: none;
            }

            &:hover {
              background: #8888881f !important;
            }

            &.k-selected {
              color: #ffffff !important;
              background: #888888 !important;
              border-radius: 5px;

              &:hover {
                border-radius: 5px;
              }
            }
          }
        }
      }
    }
  }
}
.k-datepicker{
  box-shadow: none !important;
}
.filter-dropdown-select{
  .k-dropdownlist{
    height: 31px;
    border-radius: 6px !important;
    padding: 0;
    .k-input-value-text{
      margin: 0;
    }
    .k-button{
      position: relative;
    }
    .k-input-inner {
        padding: 0 10px 0 10px !important;
    }
  }
}
.hide-search {
  .k-list-filter{
    .k-textbox {
    display: none;
    }
  }
}
  .custom-dropdown-component {
    z-index: 999 !important;
    .k-popup{
      z-index: 900 !important;
      border-radius: 5px !important;
      border-color: transparent !important;
      overflow: auto !important;
      margin: 4px 0 0 0 !important;
      background: #434343 !important;
      font-family: 'arimo' !important;
      font-weight: 400 !important;
      font-size: 14px !important;
     
          .k-list-filter{
          }
          .k-input-solid{
            outline: none;
            box-shadow: none;
            border: none;
          border-radius: 5px;
          background: #b1b1b1;
          .k-input-prefix {
            margin-left: 10px;
          }
        }
        .k-list {
          max-height: 200px;
          background: transparent !important;
          color: #9597A6 !important;
          .k-list-content {
            max-height: unset;
            border-radius: 5px;
            border:none ;
            ::-webkit-scrollbar {
        width: 5px;
        margin-left: 3px;
      }
      ::-webkit-scrollbar-track {
        background: transparent !important;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        width: 9px;
        background: #2a2a2a !important;
        border-radius: 10px;
      }
            .k-list-ul {
              list-style: none !important;
              padding: 0 0 !important;
              li {
                padding: 10px 9px !important;
                background: transparent;
                border-bottom:none;
                cursor: pointer !important;
                .k-list-item-text{
                }
                :nth-child(1) {
                  border-radius: 0;
                  box-shadow: none;
                }
                :nth-last-child(1) {
                  border-radius: 0;
                  box-shadow: none;
                }
                &:hover {
                    background: #8888881f !important;
                    color: #ffffff;
                  }
              
                &.k-selected {
                  color: #ffffff !important;
                  background: #888888 !important;
                  border-radius: 5px;
                  &:hover {
                    color: #ffffff;
                    border-radius: 5px;
                  }
                }

              }
            }
            ::-webkit-scrollbar {
              width: 2px;
            }
            ::-webkit-scrollbar-track {
              background: #dce4fe;
              border-radius: 0 20px 20px 0;
            }
            ::-webkit-scrollbar-thumb {
              width: 5px;
              background: #9e9e9e;
              border-radius: 0 20px 20px 0;
            }
          }
          .k-nodata{
            div{
                font-family: 'arimo' !important;
                font-weight: 400 !important;
                font-size: 14px !important;
                text-transform: lowercase !important;
                letter-spacing: 0.2px;
                ::first-letter {
                  text-transform: capitalize;
                }
            }
          }
          .k-i-close{
            display: none !important;
          }
        }
    
    }
    .k-list-filter {padding:1rem 0.6rem !important;}
   }
 .admincustom-dropdown-component{
    z-index: 10002 !important;
  .k-popup {
    z-index: 900 !important;
    border-radius: 5px !important;
    border: 1px solid #caced8 !important;
    overflow: auto !important;
    margin: 4px 0 0 0 !important;
    background: #ffffff !important;
    font-family: 'Inter' !important;
    font-weight: 500 !important;
    font-size: 14px !important;

    .k-list-filter {
      padding: 0.5rem !important;
    }

    .k-input-solid {
      outline: none;
      box-shadow: none;
      border: 1px solid #caced8 !important;
      border-radius: 5px;
      background: #dad4d445;

      .k-input-prefix {
        margin-left: 10px;
      }
    }

    .k-list {
      max-height: 200px;
      background: transparent !important;
      color: #171717 !important;

      .k-list-content {
        max-height: unset;
        border-radius: 5px;
        border: none;

        ::-webkit-scrollbar {
          width: 5px;
          margin-left: 3px;
        }

        ::-webkit-scrollbar-track {
          background: transparent !important;
          border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb {
          width: 9px;
          background: #e5e5e5 !important;
          border-radius: 10px;
        }

        .k-list-ul {
          list-style: none !important;
          padding: 0 0 !important;

          li {
            padding: 10px 9px !important;
            background: transparent;
            border-bottom: none;
            cursor: pointer !important;
           

            .k-list-item-text {
            
            }

            :nth-child(1) {
              border-radius: 0;
              box-shadow: none;
            }

            :nth-last-child(1) {
              border-radius: 0;
              box-shadow: none;
            }

            &:hover {
              background: #8888881f !important;
            }

            &.k-selected {
              color: #ffffff !important;
              background: #888888 !important;
              border-radius: 5px;

              &:hover {
                border-radius: 5px;
              }
            }
          }
        }
      }
    }
  }
   }
   .MultiSelect-dropdown-component {
    z-index: 999 !important;
    .k-popup{
      z-index: 900 !important;
      border-radius: 5px !important;
      border-color: transparent !important;
      overflow: auto !important;
      margin: 4px 0 0 0 !important;
      background: #434343 !important;
      font-family: 'arimo' !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      ::-webkit-scrollbar {
        width: 5px;
        margin-left: 3px;
      }
      ::-webkit-scrollbar-track {
        background: transparent !important;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        width: 9px;
        background: #2a2a2a !important;
        border-radius: 10px;
      }
          .k-list-filter{
          }
          .k-input-solid{
            outline: none;
            box-shadow: none;
            border: none;
          border-radius: 5px;

        }
        .k-list {
          max-height: 200px;
          background: transparent !important;
          color: #9597A6 !important;
          .k-list-content {
            max-height: unset;
            border-radius: 5px;
            border:none ;
            .k-list-ul {
              list-style: none !important;
              
              li {
                padding: 10px 9px !important;
                background: transparent;
                border-bottom:none;
                cursor: pointer !important;
                margin: 4px 0 !important;
                .k-list-item-text{
                }
                :nth-child(1) {
                  border-radius: 0;
                  box-shadow: none;
                }
                :nth-last-child(1) {
                  border-radius: 0;
                  box-shadow: none;
                }
                &:hover {
                    background: #8888881f !important;
                    color: #ffffff;
                  }
              
                &.k-selected {
                  color: #ffffff !important;
                  background: #2a2a2a !important;
                  &:hover {
                    color: #ffffff;
                    border-radius: 5px;
                  }
                }

                
              }
            }
            ::-webkit-scrollbar {
              width: 2px;
            }
            ::-webkit-scrollbar-track {
              background: #dce4fe;
              border-radius: 0 20px 20px 0;
            }
            ::-webkit-scrollbar-thumb {
              width: 5px;
              background: #9e9e9e;
              border-radius: 0 20px 20px 0;
            }
          }
          .k-nodata{
            div{
                font-family: 'arimo' !important;
                font-weight: 400 !important;
                font-size: 14px !important;
                text-transform: lowercase !important;
                letter-spacing: 0.2px;
                ::first-letter {
                  text-transform: capitalize;
                }
            }
          }
          .k-i-close{
            display: none !important;
          }
        }
    
    }
    .k-list-filter {padding:1rem 0.6rem !important;}
   }
 

   //Clover Footer Styles
   .clover-footer{
    position: absolute;
    top: calc(50% + 150px);
    left: 50%;
    transform: translate(-50%,0px);
    width: calc(100% - 30px);
    max-width: 374px;
    background: #FAFAFA !important;
    background-color: #FAFAFA !important;
    padding: 18px 10px !important;
    .clover-secure-payments{
      color: #607D8B !important;
      font-size: 13px !important;
      font-weight: 300 !important;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        margin: 0 5px !important;
          &:nth-child(1) {
            max-width: 10px !important;
            filter: brightness(0) !important;
          }
          &:nth-child(2) {
            max-width: 15px !important;
          }
      }

    }
    .clover-privacy-link
      {
    font-weight: 400 !important;
    text-transform: capitalize;
    text-decoration: none !important;
    font-size: 13px !important;
      }
   }
   .admin-dropdown-component-custom {
    .k-popup {
      .k-input-solid{
        border: 1px solid #EEEEEE !important;
      }
      .k-list-filter {
          padding: 10px 15px 0.5rem 15px !important;
      }
      .k-nodata {
          font-size: 14px;
          font-family: 'Inter';
      }
      .k-list {
        .k-list-content {
          .k-list-ul{
            li{
              padding: 10px 15px !important;
              border-radius: 0 !important;
              &.k-selected{
                background: #E0E0E0 !important;
                background-color: #E0E0E0 !important;
                border-radius: 0 !important;
                .k-list-item-text {
                  color: #000000 !important;
              }
              }
              .k-list-item-text {
                font-size: 12px;
                color: #475467;
              }
            }
          }          
        }
      }
    }
    &.medium-dropdown{
      min-width: 170px;
    }
  }

`;
