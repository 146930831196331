import React, { useState } from 'react';
import styled from 'styled-components';
import '@fontsource/roboto';
import { FormSecondaryLabel, InfoLabel } from 'app/components/SHLabel';
import { BackNextButton } from 'app/components/BackNextButton';
import { CheckBox } from 'app/components/CheckBox';
import RadioButtonGroup from 'app/components/RadioButtonGroup';
import { CodeOfConduct } from 'app/components/CodeOfConduct';
import { CoachRegistration, GeneralLookup } from 'services/openapi';
import { useDispatch } from 'react-redux';
import { useRiderRegistrationStateSlice } from 'app/pages/RiderRegistrationPage/slice';
import CustomDragAndDropFiles from 'app/components/CustomDragAndDropFiles';

type Props = {
  formNumber?: number;
  setFormNumber?: React.Dispatch<React.SetStateAction<number>>;
  handleNextForm?: (event) => void;
  handleBackForm?: (event) => void;
  coachRegisterPayload: CoachRegistration;
  setCoachRegisterPayload: React.Dispatch<
    React.SetStateAction<CoachRegistration>
  >;
  setIsTeamAccepting: React.Dispatch<React.SetStateAction<boolean | null>>;
  isTeamAccepting: boolean | null;
  setIsInterestedIn: React.Dispatch<React.SetStateAction<boolean | null>>;
  isInterestedIn: boolean | null;
  affiliationsData: GeneralLookup[];
  setPhotoUploadPayload: React.Dispatch<
    React.SetStateAction<{
      formData?:
        | {
            file?: Blob | undefined;
          }
        | undefined;
    }>
  >;
  photoUploadPayload: {
    formData?:
      | {
          file?: Blob | undefined;
        }
      | undefined;
  };
  setFileAvailability: React.Dispatch<React.SetStateAction<boolean>>;
  fileAvailability: boolean;
  setUploadFile: React.Dispatch<React.SetStateAction<File | null | undefined>>;
  uploadFile: File | null | undefined;
};

export const ProfessionalAffiliationsForm = ({
  formNumber,
  setFormNumber,
  handleNextForm,
  handleBackForm,
  coachRegisterPayload,
  setCoachRegisterPayload,
  setIsTeamAccepting,
  isTeamAccepting,
  setIsInterestedIn,
  isInterestedIn,
  affiliationsData,
  setPhotoUploadPayload,
  photoUploadPayload,
  setFileAvailability,
  fileAvailability,
  setUploadFile,
  uploadFile,
}: Props) => {
  const dispatch = useDispatch();
  const { actions: manageAppActions } = useRiderRegistrationStateSlice();

  const handleCheckboxOptionChange = (id: string, name: string) => {
    setCoachRegisterPayload(prevState => {
      const existingItemIndex =
        prevState?.professionalAffiliationMap?.findIndex(
          item => item.id === id,
        );
      if (existingItemIndex! > -1) {
        // Item already exists, update its name
        const updatedCategory = [...prevState?.professionalAffiliationMap];
        updatedCategory.splice(existingItemIndex!, 1);
        return { ...prevState, professionalAffiliationMap: updatedCategory };
      } else {
        return {
          ...prevState,
          professionalAffiliationMap: [
            ...prevState?.professionalAffiliationMap!,
            { id, name },
          ],
        };
      }
    });
  };

  const handleRefferalRadioBtnChange = e => {
    setCoachRegisterPayload({
      ...coachRegisterPayload,
      isInterestedInReferralProgram: e?.value,
    });
    setIsInterestedIn(e.value);
  };
  const handleAcceptingRadioBtnChange = e => {
    setCoachRegisterPayload({
      ...coachRegisterPayload,
      isTeamAcceptingNewMembers: e?.value,
    });
    setIsTeamAccepting(e.value);
  };

  const handleFileUpload = e => {
    var file = e.target.files[0];
    dispatch(manageAppActions.setPhotoUpload(file));

    setUploadFile?.(file);
    setPhotoUploadPayload?.({
      ...photoUploadPayload,
      formData: { file: file },
    });
  };
  const handleRemoveFile = e => {
    setUploadFile?.(undefined);
    setPhotoUploadPayload?.({
      ...photoUploadPayload,
      formData: { file: undefined },
    });
  };
  const handleFileOnCancelClick = e => {
    setUploadFile?.(undefined);
    setPhotoUploadPayload?.({
      ...photoUploadPayload,
      formData: { file: undefined },
    });
  };
  React.useEffect(() => {
    if (uploadFile === undefined) {
      setFileAvailability?.(true);
    } else {
      setFileAvailability?.(false);
    }
  }, [uploadFile]);

  return (
    <MainWrapper id="PersonalInfoFormMainWrapper">
      <p className="Team-Heading">New Member</p>
      <SubMainWrapper className="SubMainWrapper">
        <BorderWrapper className="inner-BorderWrapper">
          <InfoLabelWrapper id="InfoLabelWrapper">
            <div>
              <InfoLabel>Please Mark all professional affiliations</InfoLabel>
            </div>
          </InfoLabelWrapper>
          <CheckBoxGrid className="CheckBoxGrid">
            {affiliationsData?.map(item => (
              <CheckBoxWrapper className="CheckBoxWrapper">
                <CheckBox
                  className="styledCheckBox"
                  label={item?.name!}
                  value={item.id}
                  onChange={() =>
                    handleCheckboxOptionChange(item?.id!, item?.name!)
                  }
                  checked={coachRegisterPayload.professionalAffiliationMap?.some(
                    checked => checked.id === item.id,
                  )}
                />
              </CheckBoxWrapper>
            ))}
          </CheckBoxGrid>
          <MainAddressWrapper className="MainAddressWrapper">
            <QuestionWrapper className="QuestionWrapper">
              <FormSecondaryLabel>
                Is your team accepting new members?{' '}
                <span className="red-star">*</span>
              </FormSecondaryLabel>

              <RadioWrapper>
                <RadioButtonGroup
                  onChange={handleAcceptingRadioBtnChange}
                  value={isTeamAccepting}
                />
              </RadioWrapper>
            </QuestionWrapper>
            <QuestionWrapper className="QuestionWrapper">
              <FormSecondaryLabel>
                Are you interested in our referral program?{' '}
                <span className="red-star">*</span>
              </FormSecondaryLabel>
              <RadioWrapper>
                <RadioButtonGroup
                  onChange={handleRefferalRadioBtnChange}
                  value={isInterestedIn}
                />
              </RadioWrapper>
            </QuestionWrapper>
            <PhotoUploadWrapper>
              <h3 className="photo-title">
                {' '}
                Upload your current photo below.{' '}
              </h3>
              <CustomDragAndDropFiles
                className="dragNdrop"
                onCancel={e => handleFileOnCancelClick(e)}
                onChange={e => handleFileUpload(e)}
                uploadBtnDisabled={!fileAvailability}
                onRemoveFile={handleRemoveFile}
                chooseFileBtnDisabled={!fileAvailability}
                uploadFile={uploadFile}
              />
            </PhotoUploadWrapper>
            <CodeOfConduct userType="Coach" />
          </MainAddressWrapper>
        </BorderWrapper>
      </SubMainWrapper>
      <BottomRightWrapper id="BottomRightWrapper">
        <ButtonWrapper>
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={handleNextForm}
            onClickBack={handleBackForm}
            next="Submit and pay"
            back="Back"
          />
        </ButtonWrapper>
      </BottomRightWrapper>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  color: #9597a6;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  height: calc(100vh - 180px);
  position: relative;
  .Team-Heading {
    margin: 0px;
    padding: 0px !important;
    text-align: center;
    color: #9597a6;
  }
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
  .first-para,
  label {
    font-family: 'Roboto';
    font-size: 14px;
    padding-right: 20px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .first-para,
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .first-para,
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .first-para,
    label {
      font-size: 14px;
    }
  }
`;
const CheckBoxWrapper = styled.div`
  display: inline-flex;
  align-items: flex-start;
  .sub-label {
    margin-left: 10px;
  }
`;
const SubMainWrapper = styled.div`
  height: calc(100vh - 135px);
  width: 100%;
`;
const BorderWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 210px);
  overflow: auto;
  overflow-x: hidden;
  border-radius: 20px;
  padding: 20px 60px 0px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  position: relative;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: #201e1e !important;
    border-radius: 10px;
  }
`;
const InfoLabelWrapper = styled.div`
  padding: 0 20px 10px 20px;
  text-align: start;
  width: 100%;
  div {
    border-bottom: 1px solid #434343;
    padding: 6px 0;
  }
`;
const CheckBoxGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 20px;

  @media (min-width: 992px) and (max-width: 1199px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;
const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const QuestionWrapper = styled.div`
  width: 100%;
  padding: 30px 20px 30px 20px;
  display: flex;
  flex-direction: column;
  .red-star {
    color: #f44336;
    font-weight: bold;
  }
  ${FormSecondaryLabel} {
    margin: 0 0 15px 0;
  }
`;
const PhotoUploadWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  .photo-title {
    color: #a7a7a7;
    font-family: 'Roboto';
    font-size: 15px;
    text-align: center;
  }
`;

const MainAddressWrapper = styled.div`
  padding-bottom: 100px;
  .horizontal-line {
    display: flex;
    align-items: baseline;
    margin: 20px 20px 15px 20px;
  }
  .horizontal-line span {
    margin: 0 10px 0 0;
    white-space: nowrap;
    color: #4f4f4f;
    font-size: 15px;
  }
  .horizontal-line::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #434343;
    height: 3px;
  }
`;
const BottomRightWrapper = styled.div`
  z-index: 1;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 20px 20px;
  backdrop-filter: blur(10px);
  position: absolute;
  bottom: 0;
  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 40px;
    &.bg-color-change {
      bottom: 25px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 45px;
    &.bg-color-change {
      bottom: 30px;
      padding-top: 20px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 60px;
    &.bg-color-change {
      bottom: 20px;
      padding-top: 20px;
    }
  }
`;
const ButtonWrapper = styled.div``;
