import useTeamReportsApi from 'admin/hooks/useTeamReportsApi';
import React, { useEffect } from 'react';
import TeamPointsTable from './teamPointsTable';
import { CommonPageWrapper, HeadingWrapper } from 'admin/components/wrappers';
import styled from 'styled-components';
import { PageMainLabel } from 'app/components/SHLabel';
import { updateData } from './static';
import AdminCustomButton from 'app/components/AdminCustomButton';
import { TableSkeletonLoader } from 'app/components/TableSkeletonLoader';
import { GeneralLookup } from 'services/openapi';
import AdminDropDown from 'app/components/AdminDropDown';
import { getLookupArray } from 'utils/common';
import useCommonLookUpDatas from 'hooks/useCommonLookupData';
import { SHToolTip } from 'app/components/SHToolTip';
import CustomBreadCrumb from 'app/components/CustomBreadCrumb';
import { useLocation } from 'react-router-dom';

type Props = {};
export interface breadCrumbDataModel {
  id: string;
  text?: string;
}

const TeamPointsReportsTable = (props: Props) => {
  const { getTeamPointsDetails, showInfoData, downloadTeamPoints, loading } =
    useTeamReportsApi();
  const { getAllLookupData, lookUps } = useCommonLookUpDatas();
  const [seasonPayload, setSeasonPayload] = React.useState<GeneralLookup>({
    id: 'eedfc31c-c171-4797-bbf2-d77d424a3259',
    name: 'Season 2023-2024',
  });
  const currentLocation = useLocation();
  const isPastDataFlag = currentLocation.pathname.includes('past-data');
  const breadCrumbItems = [
    {
      id: '',
      text: 'Showyeda admin',
      disabled: true,
    },
    {
      id: '',
      text: isPastDataFlag ? 'Past-Data' : 'Team Report',
      disabled: true,
    },
  ];
  if (isPastDataFlag) {
    breadCrumbItems.push({
      id: '',
      text: 'Teams',
      disabled: true,
    });
  }
  const [breadcrumbPreData, setBreadcrumbPreData] =
    React.useState<breadCrumbDataModel[]>(breadCrumbItems);

  const handleDownloadTeampointReport = async () => {
    try {
      const url = await downloadTeamPoints(seasonPayload.id);

      // Extract filename from URL
      const filename = url.split('/').pop();

      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const objectUrl = URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = objectUrl;
          link.download = filename!; // Use the extracted filename

          // Append link to body (required for Firefox)
          document.body.appendChild(link);

          // Trigger download
          link.click();

          // Clean up
          document.body.removeChild(link);
          URL.revokeObjectURL(objectUrl);
        })
        .catch(error => {
          console.error('Error downloading file:', error);
        });

      return url;
    } catch (error) {
      console.error('Error downloading team point report:', error);
      throw error;
    }
  };

  // Senior High School Team Result
  const updatedSeniorData = updateData(
    showInfoData?.seniorHighSchoolTeamResult!,
  );
  const updatedSeniorHighSchoolteamData = updatedSeniorData?.map(item => {
    const { shows, teamId, ...rest } = item;
    return rest;
  });

  const gridDataSenior = updatedSeniorHighSchoolteamData?.map(dataItem => {
    const result = {};

    Object.entries(dataItem).forEach(([id, value]) => {
      const showInfo = showInfoData?.shows?.find(show => show.id === id);
      if (showInfo) {
        result[showInfo?.name!] = {
          id: id,
          value: value,
          startDate: showInfo.startDate,
          endDate: showInfo.endDate,
          isNationalFinal: showInfo.isNationalFinal,
        };
      } else {
        result[id] = value;
      }
    });

    return result;
  });
  const columnsSenior =
    gridDataSenior && gridDataSenior.length > 0
      ? Object.keys(gridDataSenior[0])?.map((key, index) => {
          const columnDef = {
            field: key,
            title: key,
            headerCell: props => (
              <HeaderCell {...props} showInfo={gridDataSenior[0][key]} />
            ),
            cell: props => <DataCell {...props} />,
            width: 200,
            locked: true,
          };
          // Check if this column has a value property
          if (
            typeof gridDataSenior[0][key] === 'object' &&
            'value' in gridDataSenior[0][key]
          ) {
            columnDef.width = 150;
            columnDef.locked = false;
          }

          return columnDef;
        })
      : [];

  // Junior High School Team Result
  const updatedJuniorData = updateData(
    showInfoData?.juniorHighSchoolTeamResult!,
  );
  const updatedJuniorHighSchoolteamData = updatedJuniorData?.map(item => {
    const { shows, teamId, ...rest } = item;
    return rest;
  });

  const gridDataJunior = updatedJuniorHighSchoolteamData?.map(dataItem => {
    const result = {};

    Object.entries(dataItem).forEach(([id, value]) => {
      const showInfo = showInfoData?.shows?.find(show => show.id === id);
      if (showInfo) {
        result[showInfo?.name!] = {
          id: id,
          value: value,
          startDate: showInfo.startDate,
          endDate: showInfo.endDate,
          isNationalFinal: showInfo.isNationalFinal,
        };
      } else {
        result[id] = value;
      }
    });

    return result;
  });
  const columnsJunior =
    gridDataJunior && gridDataJunior.length > 0
      ? Object.keys(gridDataJunior[0])?.map((key, index) => {
          const columnDef = {
            field: key,
            title: key,
            headerCell: props => (
              <HeaderCell {...props} showInfo={gridDataJunior[0][key]} />
            ),
            cell: props => <DataCell {...props} />,
            width: 200,
            locked: true,
          };
          // Check if this column has a value property
          if (
            typeof gridDataJunior[0][key] === 'object' &&
            'value' in gridDataJunior[0][key]
          ) {
            columnDef.width = 150;
            columnDef.locked = false;
          }

          return columnDef;
        })
      : [];
  // Header and Data Cells
  const HeaderCell = props => {
    const { showInfo, field } = props;

    if (typeof showInfo === 'object') {
      return (
        <SHToolTip position="top">
          <TableHeaderWrapper
            style={{
              backgroundColor: showInfo.isNationalFinal ? '#8ee08e' : 'inherit',
            }}
          >
            <div className="overflow" title={props?.title}>
              {props?.title}
            </div>

            <div className="date">
              {new Date(showInfo?.startDate).toLocaleDateString()}
            </div>
          </TableHeaderWrapper>
        </SHToolTip>
      );
    }

    let headerTitle;
    switch (field) {
      case 'teamCode':
        headerTitle = 'Code';
        break;
      case 'teamName':
        headerTitle = 'Team Name';
        break;
      case 'totalPoint':
        headerTitle = 'Total Point';
        break;
      case 'totalTop10':
        headerTitle = 'Total Top 10';
        break;
      default:
        headerTitle = props?.title;
    }

    return (
      <StaticTableHeaderWrapper>
        <div>{headerTitle}</div>
      </StaticTableHeaderWrapper>
    );
  };
  const DataCell = props => {
    const { field, dataItem, className, style } = props;
    const cellData = dataItem[field];

    if (typeof cellData === 'object' && cellData?.value !== undefined) {
      return (
        <TableDataWrapper>
          <div>{cellData?.value}</div>
        </TableDataWrapper>
      );
    }

    return (
      <td className={className} style={style}>
        {cellData}
      </td>
    );
  };

  useEffect(() => {
    getAllLookupData('SEASON');
    getTeamPointsDetails(seasonPayload.id);
  }, []);
  const handleDropdownChange = (data: GeneralLookup) => {
    setSeasonPayload(data);
    getTeamPointsDetails(data.id);
  };
  return (
    <StyledCommonPageWrapper style={{ marginBottom: '50px' }}>
      <HeadingWrapper>
        <PageMainLabel>Team Points Report</PageMainLabel>
        <CustomBreadCrumb
          preData={breadcrumbPreData}
          setPreData={setBreadcrumbPreData}
        />
      </HeadingWrapper>
      <HeaderWrapper>
        <CustomDropDown
          label=""
          placeholder="Select Season"
          onDataChange={data => handleDropdownChange(data)}
          defaultValue={seasonPayload}
          data={getLookupArray('SEASON', lookUps)}
          className="field-style"
          popupStyles="medium-dropdown"
        />
        {(showInfoData?.seniorHighSchoolTeamResult?.length ?? 0) > 0 ||
        (showInfoData?.juniorHighSchoolTeamResult?.length ?? 0) > 0 ? (
          <AdminCustomButton
            buttonType="primary"
            isActive={false}
            className="fill-button"
            onClick={handleDownloadTeampointReport}
          >
            Download
          </AdminCustomButton>
        ) : null}
      </HeaderWrapper>
      <StyledTitleCardWrapper>
        <HeadingWrapper className="heading-wrapper">
          <PageMainLabel className="label-wrapper">
            Senior High Team Result
          </PageMainLabel>
        </HeadingWrapper>
      </StyledTitleCardWrapper>
      {loading ? (
        <TableSkeletonLoader rowContent={3} width={100} headingWidth={40} />
      ) : (
        <TeamPointsTable data={gridDataSenior || []} columns={columnsSenior} />
      )}
      <StyledTitleCardWrapper className="junior-high">
        <HeadingWrapper className="heading-wrapper">
          <PageMainLabel className="label-wrapper">
            Junior High Team Result
          </PageMainLabel>
        </HeadingWrapper>
      </StyledTitleCardWrapper>
      {loading ? (
        <TableSkeletonLoader rowContent={3} width={100} headingWidth={40} />
      ) : (
        <TeamPointsTable data={gridDataJunior || []} columns={columnsJunior} />
      )}
    </StyledCommonPageWrapper>
  );
};

export default TeamPointsReportsTable;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0 30px 0;
  .fill-button {
    margin-right: 0px;
  }
`;
const TitleCardWrapper = styled.div`
  &.junior-high {
    margin-top: 52px;
  }
  margin: 0px 0px 0px 0px;
  background-color: #e7ecff;
  .heading-wrapper {
    padding: 14px 0px 14px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    .label-wrapper {
      font-family: 'Inter';
      font-size: 16px;
      font-weight: 600;
      line-height: 14.52px;
      color: #000000;
      margin: unset !important;
    }
  }
`;
const StaticTableHeaderWrapper = styled.th`
  div {
    padding-left: 8px;
  }
`;
const TableHeaderWrapper = styled.th`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Inter';
  font-size: 15px;
  font-weight: 600 !important;
  line-height: 14.52px;
  color: #475467;
  margin: unset !important;
  min-height: 60px;
  place-content: center;

  .date {
    font-size: 13px;
    font-weight: 400 !important;
    padding-top: 7px;
  }
  .overflow {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
  }
`;
const TableDataWrapper = styled.td`
  width: 100% !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-align: center !important;
`;

const StyledTitleCardWrapper = styled(TitleCardWrapper)`
  background-color: #e7ecff;
`;
const StyledCommonPageWrapper = styled(CommonPageWrapper)`
  padding-top: 26px;
  margin-bottom: unset !important;
`;

const CustomDropDown = styled(AdminDropDown)`
  width: 160px;
  .k-dropdownlist {
    max-height: 31px;
    border-radius: 8px !important;
  }
  .k-input-inner {
    padding: 0 5px !important;
  }
  .k-input-value-text {
    margin: 2px 4px 2px 4px;
    font-size: 12px !important;
    letter-spacing: 0px;
    color: #1e1e1e;
    text-align: left;
  }
`;
