import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import '@fontsource/oswald';
import horseBg from './assets/img/bg/team-reg-new.png';
import { PersonalInfoForm } from './component/PersonalInfoForm';
import { FinancialInfoForm } from './component/FinancialInfoForm';
import '@fontsource/roboto';
import { ShowyedaLogo } from 'app/components/ShowyedaLogo';
import useApi from 'services/mavapi/useApi';
import {
  GeneralLookup,
  LookupService,
  TeamRegistration,
  TeamService,
} from 'services/openapi';
import { useNavigate } from 'react-router-dom';
import RadioButtonGroup from 'app/components/RadioButtonGroup';
import { BackNextButton } from 'app/components/BackNextButton';
import CircularLoader from 'app/components/CircularLoader';
import LoaderWrapper from 'app/components/CircularLoader/LoaderWrapper';
import { useManagePageSlice } from '../slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectPageLoader } from '../slice/selectors';
import Cookies from 'js-cookie';
import { FormSecondaryLabel } from 'app/components/SHLabel';
import CircularProgress from '@mui/joy/CircularProgress';
import Box from '@mui/joy/Box';
import { Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';
import check from './assets/img/icon/check.png';
import isApiError from 'utils/isApiError';
import { useErrorHandlingSlice } from 'app/error/slice';
import { selectPhotoUpload } from '../RiderRegistrationPage/slice/selectors';
import { useRiderRegistrationStateSlice } from '../RiderRegistrationPage/slice';

type Props = {};
const items = [
  { label: 'Personal Information', number: 1 },
  { label: 'Financial Information', number: 2 },
];
export const defaultPhotoValue = {
  formData: {
    file: undefined,
  },
};
export const RegistrationPage = (props: Props) => {
  const initialData: TeamRegistration = {
    name: '',
    mainCoach: {
      id: '',
      name: '',
      children: null,
    },
    mainContact: '',
    phone: '',
    street: '',
    city: '',
    state: {
      id: '',
      name: '',
      children: null,
    },
    zip: '',
    category: [],
    financialResponsibility: {
      name: '',
      phone: '',
      street: '',
      city: '',
      state: {
        id: '',
        name: '',
        children: null,
      },
      zip: '',
    },
    isTrial: false,
    teamCode: '',
    isReturning: false,
    teamIdOld: null,
    associateCoachList: [],
    assistantCoachList: [],
    email: '',
  };

  const navigate = useNavigate();

  //Api Services
  const { error: apiError, handleRequest } = useApi();
  const { actions } = useErrorHandlingSlice();
  const { actions: manageAppActions } = useRiderRegistrationStateSlice();
  const [, setError] = React.useState<unknown | null>();
  const [addTeamData, setAddTeamData] = useState<TeamRegistration>(initialData);
  //for Loader
  const { actions: PageActions } = useManagePageSlice();
  const pageLoader = useSelector(selectPageLoader);
  const dispatch = useDispatch();

  // const navigate = useNavigate();
  const [teamDefault, setTeamDefault] = useState<GeneralLookup>();
  const {
    teamAddNewTeam,
    teamGenerateTeamCode,
    teamGetTeam,
    teamUploadTeamPhoto,
  } = TeamService;
  const { lookupGetAllLookups } = LookupService;

  //Use States
  const [payerTypes, setPayerTypes] = useState<GeneralLookup>();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [stateLookup, setStateLookup] = useState<Array<GeneralLookup>>([]);
  const [categoryLookup, setCategoryLookup] = useState<Array<GeneralLookup>>(
    [],
  );
  const [teamCodeValue, setTeamCodeValue] = useState<string>('');

  //Get LookUps
  const [isReturningValue, setIsReturningValue] = useState<boolean | null>(
    null,
  );
  const [cancelPaymentCookie, setCancelPaymentCookie] = useState<boolean>();
  const [assistantCoachData, setAssistantCoachData] = useState<GeneralLookup[]>(
    [],
  );
  const [coachData, setCoachData] = useState<GeneralLookup[]>([]);
  const [formNumber, setFormNumber] = useState<number>(0);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 767);
  const totalPages = 2;
  const determinateValue = ((formNumber + 1) / totalPages) * 100;
  const [teamDropdownData, setTeamDropdownData] = useState<GeneralLookup[]>([]);
  const [photoUploadPayload, setPhotoUploadPayload] = useState<{
    formData?: {
      file?: Blob;
    };
  }>(defaultPhotoValue);
  const [fileAvailability, setFileAvailability] = React.useState<boolean>(true);
  const [uploadFile, setUploadFile] = useState<File | null>();
  const photoUploadDataRedux = useSelector(selectPhotoUpload);

  const handleGetLookups = () => {
    handleRequest(
      lookupGetAllLookups(
        'STATE,TEAM_CATEGORY,PAYER_TYPES,ASSISTANT_COACH,COACH,TEAM',
      ),
    )
      .then(res => {
        if (res) {
          const categories = res?.find(obj => obj.type === 'TEAM_CATEGORY');
          setCategoryLookup(categories?.values ?? []);
          const stateLookup = res?.find(obj => obj.type === 'STATE');
          setStateLookup(stateLookup?.values ?? []);

          let varPayerTypes = res?.filter(item => {
            if (item?.type === 'PAYER_TYPES') {
              return item.values;
            }
          });
          let payerTypeObj = varPayerTypes?.[0]?.values?.find(item => {
            if (item?.name === 'Team') {
              return item ?? {};
            }
          });
          let varAssistantCoach = res?.filter(item => {
            if (item?.type === 'ASSISTANT_COACH') {
              return item.values;
            }
          });
          let varCoach = res?.filter(item => {
            if (item?.type === 'COACH') {
              return item.values;
            }
          });
          let varTeam = res?.find(item => {
            if (item?.type === 'TEAM') {
              return item.values;
            }
          });
          setTeamDropdownData(varTeam.values);
          setCoachData(varCoach?.[0]?.values!);
          setAssistantCoachData(varAssistantCoach?.[0]?.values!);
          setPayerTypes(payerTypeObj);
          dispatch(PageActions.setPageLoader(false));
        }
      })

      .catch(error => {});
  };

  const handleGetReturningTeamDetails = (teamId: string) => {
    handleRequest(
      teamGetTeam(teamId)
        .then(res => {
          if (res) {
            setAddTeamData({
              ...res,
              phone: res?.phone ? res?.phone.replaceAll('-', '') : '',
            });
          }
        })
        .catch(error => {}),
    );
  };

  const handleRegBack = () => {
    setFormNumber(0);
  };

  //Create New teamCode
  const generateTeamCode = (teamName?: string) => {
    if (addTeamData?.teamCode) {
      return;
    } else {
      handleRequest(teamGenerateTeamCode(teamName))
        .then(res => {
          if (res && !isApiError(res)) {
            setTeamCodeValue(res);
            setAddTeamData({
              ...addTeamData,
              teamCode: res!,
            });
          } else {
            setTeamCodeValue(res.description);
          }
        })
        .catch(error => {
          setError(apiError);
        });
    }
  };
  const createNewTeam = (data: TeamRegistration) => {
    dispatch(PageActions.setPageLoader(true));
    const newPayload: TeamRegistration = {
      ...data,
      isReturning: isReturningValue ?? false,
      assistantCoachList: null,
      mainCoach: undefined,
      teamIdOld: isReturningValue ? parseInt(teamDefault?.id ?? '') : null,
    };
    handleRequest(teamAddNewTeam(newPayload))
      .then(res => {
        if (res && res.errors) {
          dispatch(
            actions.dispatchToast({
              errorTxt: res.errors,
              show: true,
              errorType: 'error',
            }),
          );
        } else if (res && !isApiError(res)) {
          if (cancelPaymentCookie) {
            Cookies.remove('paymentCancel');
          }
          dispatch(PageActions.setPageLoader(false));
          const teamReturningDataString = JSON.stringify(data);
          Cookies.set('payload', teamReturningDataString);
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Team created successfully',
              show: true,
              errorType: 'success',
            }),
          );
          if (photoUploadPayload.formData?.file !== undefined) {
            handlePhotoUpload(res.toString(), photoUploadPayload.formData);
            dispatch(manageAppActions.setPhotoUpload(photoUploadPayload));
          }
          navigate(`/payment/${payerTypes?.id}/${res}/false/false`);
        } else {
          dispatch(
            actions.dispatchToast({
              errorTxt: res.description,
              show: true,
              errorType: 'error',
            }),
          );
        }
        dispatch(PageActions.setPageLoader(false));
      })
      .catch(() => setError(apiError));
  };
  const handlePhotoUpload = (
    teamId: string,
    formData?: {
      file?: Blob;
    },
  ) => {
    handleRequest(teamUploadTeamPhoto(teamId, formData))
      .then(res => {})
      .catch(error => {});
  };
  useEffect(() => {
    handleGetLookups();
    const paymentCancelStatus = Cookies.get('paymentCancel');
    setCancelPaymentCookie(paymentCancelStatus);
  }, []);

  const handleBack = () => {
    Cookies.remove('payload');
    Cookies.remove('paymentCancel');
    Cookies.remove('teamDropdownData');
    Cookies.remove('TeamNewValue');
    setIsReturningValue(null);
    setNewValue(undefined);
    setAddTeamData(initialData);
    setTeamCodeValue('');
    setTeamDefault({ id: '', name: '' });
  };
  const handleNxt = () => {
    setFormNumber(1);
  };
  const handleChangeStepper = (e: StepperChangeEvent) => {
    const index = Number(e.value);
    if (index >= 0 && index !== formNumber) {
      setFormNumber(index);
    }
  };
  const handleItemClick = (index: number) => {};

  React.useEffect(() => {
    dispatch(PageActions.setPageLoader(true));
    handleGetLookups();
    const paymentCancelStatus = Cookies.get('paymentCancel');
    if (paymentCancelStatus) {
      const paymentCancelStatusParse = JSON.parse(paymentCancelStatus);
      setCancelPaymentCookie(paymentCancelStatusParse);
    }
  }, []);
  const [newValue, setNewValue] = useState<boolean | undefined>(undefined);
  const handleReturningSelection = event => {
    if (event) {
      const eventString = JSON.stringify(event.value);
      Cookies.set('TeamNewValue', eventString);
      setNewValue(event.value);
      setAddTeamData({ ...addTeamData, isReturning: event.value });
    }
  };
  const handleRadioNxt = event => {
    if (newValue === true) {
      setIsReturningValue(true);
    } else if (newValue === false) {
      setIsReturningValue(false);
    }
  };
  const handleRadioBack = () => {
    Cookies.remove('payload');
    Cookies.remove('paymentCancel');
    Cookies.remove('TeamNewValue');
    Cookies.remove('teamDropdownData');
    navigate('/login');
  };
  useEffect(() => {
    const teamData = Cookies.get('payload');
    const teamRes = Cookies.get('teamDropdownData');

    if (teamData) {
      const returningOrNew = Cookies.get('TeamNewValue');
      if (returningOrNew) {
        const parseValue = JSON.parse(returningOrNew);
        setIsReturningValue(parseValue);
        if (parseValue === true) {
          const teamResparse = JSON.parse(teamRes);
          setTeamDefault(teamResparse);
        }
      }
      const teamReturningDataParse = JSON.parse(teamData);
      setAddTeamData(teamReturningDataParse);
      setFormNumber(1);
      setTeamCodeValue(teamReturningDataParse.teamCode);
      setPhotoUploadPayload(photoUploadDataRedux ?? {});
      setUploadFile(photoUploadDataRedux?.formData?.file as File);
    }
  }, [cancelPaymentCookie]);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 767);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      {pageLoader ? (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      ) : (
        <MainWrapper>
          <LeftWrapper className="LeftWrapper">
            <ShowyedaLogo />
            {formNumber === 1 ? (
              <div className="left-para-content">
                <p>Payment:</p>
                <p>
                  $125 Teams who only have Elementary, Alumni, and/or EWD Riders
                </p>
                <p>
                  $150 Teams who have just a Junior or Senior Team. Elementary,
                  Alumni and EWD riders are included with this registration.
                </p>
                <p>
                  $200 Teams who have both Junior and Senior Teams. Elementary,
                  Alumni and EWD riders are included with this registration.
                </p>
              </div>
            ) : (
              <div className="left-para-content"></div>
            )}
          </LeftWrapper>
          <RightWrapper className="RightWrapper">
            <TopWrapper className="TopWrapper">
              <h3 className="toplabel-header">
                YEDA Team Registration Form 2023-2024
              </h3>

              {isReturningValue === null && (
                <RadioWrapper>
                  <ReturningWrapper>
                    <FormSecondaryLabel className="apply-label">
                      Are you a returning Team?
                    </FormSecondaryLabel>
                    <RadioButtonGroup onChange={handleReturningSelection} />
                  </ReturningWrapper>
                  <BottomRightWrapper className="bg-color-change">
                    <ButtonWrapper>
                      <BackNextButton
                        classNameBack="btn-back"
                        classNameNxt="btn-nxt"
                        onClickNxt={handleRadioNxt}
                        onClickBack={handleRadioBack}
                        next="Next"
                        back="Back"
                        disableNext={newValue === undefined}
                      />
                    </ButtonWrapper>
                  </BottomRightWrapper>
                </RadioWrapper>
              )}
              {isReturningValue !== null && (
                <>
                  <h3 className="Team-Heading">
                    {isReturningValue ? 'Returning Team' : 'New Team'}
                  </h3>
                  <div className="Stepper-wrapper">
                    {isSmallScreen ? (
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 2,
                          alignItems: 'center',
                          flexWrap: 'wrap',
                        }}
                      >
                        <CircularProgress
                          size="lg"
                          determinate
                          value={determinateValue}
                        >
                          {`${formNumber + 1} / ${totalPages}`}
                        </CircularProgress>
                        {items
                          .filter((_, index) => index === formNumber)
                          .map((item, index) => (
                            <h2
                              className="circular-stepper-heading"
                              key={index}
                            >
                              {item.label}
                            </h2>
                          ))}
                      </Box>
                    ) : (
                      <Stepper
                        value={formNumber}
                        onChange={handleChangeStepper}
                        items={items}
                        className="form-stepper"
                      >
                        {items.map((item, index) => (
                          <StepperStep
                            key={index}
                            className={`stepper-step ${
                              index < formNumber ? 'completed' : ''
                            }`}
                            onClick={() => handleItemClick(index)}
                          >
                            {index < formNumber ? (
                              <span className="tick-icon">&#10003;</span>
                            ) : (
                              item.number
                            )}
                          </StepperStep>
                        ))}
                      </Stepper>
                    )}
                  </div>
                  {formNumber === 1 ? (
                    <FinancialInfoForm
                      addTeamData={addTeamData}
                      setAddTeamData={setAddTeamData}
                      generateTeamCode={generateTeamCode}
                      teamCodeValue={teamCodeValue}
                      handleRegBack={handleRegBack}
                      createNewTeam={createNewTeam}
                      setShowLoader={setShowLoader}
                      stateLookupData={stateLookup}
                      isReturningValue={isReturningValue}
                      cancelPaymentCookie={cancelPaymentCookie}
                      setPhotoUploadPayload={setPhotoUploadPayload}
                      photoUploadPayload={photoUploadPayload}
                      setFileAvailability={setFileAvailability}
                      fileAvailability={fileAvailability}
                      setUploadFile={setUploadFile}
                      uploadFile={uploadFile}
                    />
                  ) : formNumber === 0 ? (
                    <PersonalInfoForm
                      addTeamData={addTeamData}
                      setAddTeamData={setAddTeamData}
                      handleBack={handleBack}
                      handleNxt={handleNxt}
                      isReturningValue={isReturningValue}
                      stateLookupData={stateLookup}
                      categoryLookupData={categoryLookup}
                      handleGetReturningTeamDetails={
                        handleGetReturningTeamDetails
                      }
                      setTeamDefault={setTeamDefault}
                      teamDefault={teamDefault}
                      assistantCoachData={assistantCoachData}
                      coachData={coachData}
                      teamDropdownData={teamDropdownData}
                    />
                  ) : (
                    ''
                  )}
                </>
              )}
            </TopWrapper>
          </RightWrapper>
        </MainWrapper>
      )}
    </>
  );
};

const LeftWrapper = styled.div`
  width: 40%;
  /* background: #1b1b1b; */
  height: 100%;
  background: url(${horseBg});
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  padding: 20px 35px 0px;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-position: center top;

  span {
    color: #ffffff;
    font-family: 'Oswald';
    font-size: 14px;
  }
  .left-para-content {
    background: black;
    opacity: 0.7;
    padding: 0 20px;
    position: absolute;
    top: 30%;
    left: 0;
    p {
      color: #898989;
      font-family: 'Oswald';
      font-size: 14px;
    }
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .left-para-content {
      p {
        font-size: 10px;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .left-para-content {
      span,
      p {
        font-size: 11px;
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .left-para-content {
      p {
        font-size: 12px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .left-para-content {
      p {
        font-size: 13px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .left-para-content {
      p {
        font-size: 13px;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .left-para-content {
      p {
        font-size: 14px;
      }
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .left-para-content {
      span,
      p {
        font-size: 14px;
      }
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    .left-para-content {
      p {
        font-size: 15px;
      }
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    .left-para-content {
      p {
        font-size: 16px;
      }
    }
  }
`;
const StepperStep = styled.button`
  background-color: green !important;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;

  &.completed {
    background-color: green !important;
  }
`;
const RightWrapper = styled.div`
  width: 60%;
  height: 100%;
  background: #2a2a2a;
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const TopWrapper = styled.div`
  width: 100%;
  background: #2a2a2a;
  padding: 20px 20px 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 100%;
  max-width: 1000px;
  margin: 0 auto;
  h3 {
    background: #2a2a2a !important;
    font-weight: 500;
    color: #ffffff;
    padding-top: 10px;
    margin: 0px;
    font-family: 'Roboto';
    text-align: center;
  }
  .Team-Heading {
    color: #9597a6;
  }
`;
const BottomRightWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;
  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: 10px;
    left: 0;
    z-index: 2;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;
const RadioWrapper = styled.div`
  width: 100%;
  padding: 70px 30px 0px 30px;
  height: 100%;
`;
const ReturningWrapper = styled.div`
  padding: 45px 20px;
  p {
    color: #9597a6;
  }
  .RadioButtonGroupWrapper {
    padding: 15px 0;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    ${FormSecondaryLabel} {
      font-size: 11px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    ${FormSecondaryLabel} {
      font-size: 11px !important;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    ${FormSecondaryLabel} {
      font-size: 12px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    ${FormSecondaryLabel} {
      font-size: 12px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    ${FormSecondaryLabel} {
      font-size: 14px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    ${FormSecondaryLabel} {
      font-size: 14px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    ${FormSecondaryLabel} {
      font-size: 15px;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    ${FormSecondaryLabel} {
      font-size: 15px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    ${FormSecondaryLabel} {
      font-size: 16px;
    }
  }
`;
const ButtonWrapper = styled.div``;
const MainWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;

  //stepper-style
  .circular-stepper-heading {
    color: #8a8a8a;
  }
  .MuiCircularProgress-progress,
  .MuiCircularProgress-sizeLg {
    color: #8a8a8a;
    stroke: #2dce98;
  }
  .css-zitgxn-JoyCircularProgress-track {
    stroke: #434343;
  }
  .k-step-done {
    .k-step-indicator-text {
      position: relative;
      ::after {
        position: absolute;
        content: '';
        z-index: 15;
        background: #2dce98;
        background-image: url(${check});
        background-repeat: no-repeat;
        background-size: inherit;
        background-position: center;
        border-radius: 50px;
        left: -8px;
        height: 26px;
        width: 26px;
        top: -1px;
      }
    }
  }
  .k-stepper.form-stepper {
    pointer-events: none;
  }
  input {
    ::placeholder {
      font-size: 14px;
      text-transform: capitalize;
    }
  }
  .k-stepper .k-step-indicator::after {
    box-shadow: unset !important;
  }
  .k-stepper .k-step-indicator::after {
    border-style: unset !important;
  }
  .k-stepper .k-step-label .k-step-text {
    width: 200px;
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #8a8a8a;
    font-size: 12px;
    max-width: unset !important;
  }
  .k-progressbar {
    background: #5b5b5b;
  }
  .k-progressbar .k-selected {
    background: #2dce98;
  }
  .k-stepper .k-step-done .k-step-indicator {
    border-color: #2dce98;
    color: white;
    background-color: #2dce98;
  }
  .k-stepper .k-step-current .k-step-indicator {
    border-color: #2dce98;
    color: white;
    background-color: #2dce98;
  }
  .k-stepper .k-step-indicator {
    border-color: #5b5b5b;
    color: #c4c4c4;
    background-color: #5b5b5b;
    width: 25px;
    height: auto;
  }
  .k-stepper {
    padding-bottom: 20px;
  }
  .Stepper-wrapper {
    margin-bottom: 20px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        padding: 0 10px;
        height: 55px;
        gap: 5px;
      }
      .circular-stepper-heading {
        font-size: 13px;
      }
      .MuiCircularProgress-root {
        --CircularProgress-size: 50px;
      }
    }
    .k-stepper .k-step-label .k-step-text {
      width: 55px;
      font-size: 10px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        height: 55px;
        padding: 0 15px;
      }
      .circular-stepper-heading {
        font-size: 18px;
      }
      .MuiCircularProgress-root {
        --CircularProgress-size: 55px;
      }
    }
    .k-stepper .k-step-label .k-step-text {
      width: 55px;
      font-size: 11px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        height: 55px;
        padding: 0 20px;
        gap: 25px;
      }
      .circular-stepper-heading {
        font-size: 22px;
      }
    }
  }
  //Page Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    ${LeftWrapper} {
      display: none;
    }
    .TopWrapper {
      top: -8px;
      padding: 5px 10px 0px;
      .toplabel-header {
        font-size: 12px;
      }
    }
    ${RightWrapper} {
      width: 100%;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    ${LeftWrapper} {
      display: none;
    }
    ${RightWrapper} {
      width: 100%;
    }
    .TopWrapper {
      top: -10px;
      padding: 10px 15px 0px;
      .toplabel-header {
        font-size: 14px;
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    ${LeftWrapper} {
      display: none;
    }
    ${RightWrapper} {
      width: 100%;
    }

    .TopWrapper {
      top: -10px;
      padding: 30px 15px 20px 15px;
      .toplabel-header {
        font-size: 15px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    ${LeftWrapper} {
      min-width: 200px !important;
    }
    ${RightWrapper} {
      width: calc(100% - 200px) !important;
    }

    .TopWrapper {
      top: -10px;
      padding: 30px 20px 20px 20px;
      .toplabel-header {
        font-size: 16px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    ${LeftWrapper} {
      min-width: 200px !important;
    }
    ${RightWrapper} {
      width: calc(100% - 200px) !important;
    }

    .TopWrapper {
      top: -10px;
      padding: 40px 25px 30px 25px;
      .toplabel-header {
        font-size: 17px;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    ${LeftWrapper} {
      min-width: 200px !important;
    }
    ${RightWrapper} {
      width: calc(100% - 200px) !important;
    }

    .TopWrapper {
      top: -10px;
      padding: 40px 25px 30px 25px;
      .toplabel-header {
        font-size: 18px;
      }
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    ${LeftWrapper} {
      min-width: 200px !important;
    }
    ${RightWrapper} {
      width: calc(100% - 200px) !important;
    }

    .TopWrapper {
      top: -10px;
      padding: 40px 25px 30px 25px;
      .toplabel-header {
        font-size: 19px;
      }
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
  }
`;
