import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';
import EWDPersonalInfoFieldsReturning from './components/EWDPersonalInfoFields';
import EWDParentDetailsReturning from './components/EWDParentDetails';
import EWDDivisions from './components/EWDDivisons';
import {
  EWD,
  GeneralEWDs,
  GeneralLookup,
  GeneralLookupWrapper,
} from 'services/openapi';
import check from './assets/img/icon/check.png';
import Cookies from 'js-cookie';
import Box from '@mui/joy/Box';
import CircularProgress from '@mui/joy/CircularProgress';
import { heightWeightModel } from 'app/pages/RiderRegistrationPage/components/NotReturningRider';
import { EquipmentDetailsReturning } from './components/EquimentDetails';
import EligibilityConditionsReturning from './components/ElgibilityConditions';
import { EligibilityDetailsReturning } from './components/ElgibilityDetails';
import AdaptiveEquipmentsReturning from './components/AdaptiveEquipments';

type Props = {
  onClickBackPersonal?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  handleDivionsNxt?: () => void;
  riderData?: EWD | undefined;
  setRiderData: React.Dispatch<React.SetStateAction<EWD>>;
  stateData: GeneralLookup[] | undefined;
  childData: GeneralLookup[] | undefined;
  formValue: number;
  setFormValue: React.Dispatch<React.SetStateAction<number>>;
  lookUp: GeneralLookupWrapper[] | undefined;
  categoryCheck: GeneralLookup[] | undefined;
  setIsphotoforPromotion: React.Dispatch<React.SetStateAction<boolean | null>>;
  isphotoforPromotion: boolean | null;
  setHeightWeight: React.Dispatch<
    React.SetStateAction<heightWeightModel | undefined>
  >;
  heightWeight: heightWeightModel | undefined;
  seasonDivison: (string[] | undefined)[] | undefined;
  teamIdPayload: GeneralLookup | undefined;
  setTeamIdPayload: React.Dispatch<
    React.SetStateAction<GeneralLookup | undefined>
  >;
  handleEligibilityConditionsNext: () => void;
  medicalConditionsData: GeneralLookup[];
  diaganosisData: GeneralEWDs;
  setDiaganosisData: React.Dispatch<React.SetStateAction<GeneralEWDs>>;
  setPhotoUploadPayload: React.Dispatch<
    React.SetStateAction<{
      formData?:
        | {
            file?: Blob | undefined;
          }
        | undefined;
    }>
  >;
  photoUploadPayload: {
    formData?:
      | {
          file?: Blob | undefined;
        }
      | undefined;
  };
  backNumberData: string[] | undefined;
  setOtherData: React.Dispatch<React.SetStateAction<string>>;
  otherData: string;
  setUploadFile: React.Dispatch<React.SetStateAction<File | null | undefined>>;
  uploadFile: File | null | undefined;
  setDiagonosisUploadPayload: React.Dispatch<
    React.SetStateAction<Blob | undefined>
  >;
  diagonosisFileAvailability: boolean;
  diagonosisUploadFile: File | null | undefined;
  setDiagonosisFileAvailability: React.Dispatch<React.SetStateAction<boolean>>;
  setDiagonosisUploadFile: React.Dispatch<
    React.SetStateAction<File | null | undefined>
  >;
  setCategoryCheck: React.Dispatch<
    React.SetStateAction<GeneralLookup[] | undefined>
  >;
};
const items = [
  { label: 'Personal Information', number: 1 },
  { label: 'Parent', number: 2 },
  // { label: 'Affiliations', number: 3 },
  { label: 'EWD Divisions', number: 4 },
  { label: 'Diagonosis Form', number: 5 },
  { label: 'Diagonosis Condition', number: 6 },
];

const EWDRiderInformation = ({
  onClickBackPersonal,
  handleDivionsNxt,
  riderData,
  setRiderData,
  stateData,
  childData,
  formValue,
  setFormValue,
  lookUp,
  categoryCheck,
  setIsphotoforPromotion,
  isphotoforPromotion,
  setHeightWeight,
  heightWeight,
  seasonDivison,
  teamIdPayload,
  setTeamIdPayload,
  handleEligibilityConditionsNext,
  medicalConditionsData,
  diaganosisData,
  setDiaganosisData,
  setPhotoUploadPayload,
  photoUploadPayload,
  backNumberData,
  setOtherData,
  otherData,
  setUploadFile,
  uploadFile,
  setDiagonosisUploadPayload,
  diagonosisFileAvailability,
  diagonosisUploadFile,
  setDiagonosisFileAvailability,
  setDiagonosisUploadFile,
  setCategoryCheck,
}: Props) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 767);
  const [fileAvailability, setFileAvailability] = React.useState<boolean>(true);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 767);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const totalPages = 5;
  const determinateValue = ((formValue + 1) / totalPages) * 100;
  const handleChangeStepper = (e: StepperChangeEvent) => {
    const index = Number(e.value);
    if (index >= 0 && index !== formValue) {
      setFormValue(index);
    }
  };
  const handleItemClick = (index: number) => {};

  const handlePersonalNxt = () => {
    setFormValue(1);
  };
  const handleParentNxtBack = () => {
    setFormValue(0);
  };
  const handleParentNxt = () => {
    setFormValue(2);
  };
  // const handleAffiliationsBack = () => {
  //   setFormValue(1);
  // };
  // const handleAffiliationsNxt = () => {
  //   setFormValue(3);
  // };
  const handleDivionsBack = () => {
    Cookies.remove('paymentCancel');
    Cookies.remove('payload');
    Cookies.remove('diaganosisPayload');
    setFormValue(1);
  };
  const handleEligibilityDetailsBack = () => {
    setFormValue(2);
  };
  const handleEligibilityDetailsNext = () => {
    setFormValue(4);
  };
  const handleEligibilityConditionsBack = () => {
    setFormValue(3);
  };

  const handleEqupmentDetailsBack = () => {
    setFormValue(5);
  };
  const handleEqupmentDetailsNext = () => {
    setFormValue(7);
  };
  const handlekEquipmentCheckboxBack = () => {
    setFormValue(6);
  };
  const handleEquipmentCheckboxNext = () => {
    // setValue(4);
  };

  return (
    <MainWrapper>
      <p className="Team-Heading">Returning Member</p>
      <div className="Stepper-wrapper">
        {isSmallScreen ? (
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <CircularProgress size="lg" determinate value={determinateValue}>
              {`${formValue + 1} / ${totalPages}`}
            </CircularProgress>
            {items
              .filter((_, index) => index === formValue)
              .map((item, index) => (
                <h2 className="circular-stepper-heading" key={index}>
                  {item.label}
                </h2>
              ))}
          </Box>
        ) : (
          <Stepper
            value={formValue}
            onChange={handleChangeStepper}
            items={items}
            className="form-stepper"
          >
            {items.map((item, index) => (
              <StepperStep
                key={index}
                className={`stepper-step ${
                  index < formValue ? 'completed' : ''
                }`}
                onClick={() => handleItemClick(index)}
              >
                {index < formValue ? (
                  <span className="tick-icon">&#10003;</span>
                ) : (
                  item.number
                )}
              </StepperStep>
            ))}
          </Stepper>
        )}
      </div>
      <>
        {formValue === 0 && (
          <EWDPersonalInfoFieldsReturning
            onClickBackPersonal={onClickBackPersonal}
            onClickNxtPersonal={handlePersonalNxt}
            riderData={riderData}
            setRiderData={setRiderData}
            stateData={stateData}
            lookUp={lookUp}
            categoryCheck={categoryCheck}
            setHeightWeight={setHeightWeight}
            heightWeight={heightWeight}
            backNumberData={backNumberData}
            setCategoryCheck={setCategoryCheck}
          />
        )}
        {formValue === 1 && (
          <EWDParentDetailsReturning
            onClickBackParent={handleParentNxtBack}
            onClickNxtParent={handleParentNxt}
            riderData={riderData}
            setRiderData={setRiderData}
            stateData={stateData}
          />
        )}
        {/* {formValue === 2 && (
          <EWDAffiliations
            onClickBackAffiliations={handleAffiliationsBack}
            onClickNxtAffiliations={handleAffiliationsNxt}
            riderData={riderData}
            setRiderData={setRiderData}
            affiliationsData={affiliationsData}
          />
        )} */}
        {formValue === 2 && (
          <EWDDivisions
            onClickBackDivions={handleDivionsBack}
            onClickNxtDivions={handleDivionsNxt}
            riderData={riderData}
            setRiderData={setRiderData}
            childData={childData}
            isphotoforPromotion={isphotoforPromotion}
            setIsphotoforPromotion={setIsphotoforPromotion}
            seasonDivison={seasonDivison}
            setPhotoUploadPayload={setPhotoUploadPayload}
            photoUploadPayload={photoUploadPayload}
            setFileAvailability={setFileAvailability}
            fileAvailability={fileAvailability}
            setUploadFile={setUploadFile}
            uploadFile={uploadFile}
          />
        )}
        {formValue === 3 && (
          <EligibilityDetailsReturning
            riderData={riderData}
            stateData={stateData}
            setRiderData={setRiderData}
            onClickEligibilityDetailsBack={handleEligibilityDetailsBack}
            onClickEligibilityDetailsNext={handleEligibilityDetailsNext}
            lookUp={lookUp}
            teamIdPayload={teamIdPayload}
            setTeamIdPayload={setTeamIdPayload}
          />
        )}
        {formValue === 4 && (
          <EligibilityConditionsReturning
            handleEligibilityConditionsNext={handleEligibilityConditionsNext}
            onClickEligibilityConditionsBack={handleEligibilityConditionsBack}
            medicalConditionsData={medicalConditionsData}
            diaganosisData={diaganosisData}
            setDiaganosisData={setDiaganosisData}
            setOtherData={setOtherData}
            otherData={otherData}
            setDiagonosisUploadPayload={setDiagonosisUploadPayload}
            diagonosisFileAvailability={diagonosisFileAvailability}
            diagonosisUploadFile={diagonosisUploadFile}
            setDiagonosisFileAvailability={setDiagonosisFileAvailability}
            setDiagonosisUploadFile={setDiagonosisUploadFile}
          />
        )}
        {formValue === 5 && (
          <EquipmentDetailsReturning
            riderData={riderData}
            stateData={stateData}
            setRiderData={setRiderData}
            onClickEqupmentDetailsNext={handleEqupmentDetailsNext}
            onClickEqupmentDetailsBack={handleEqupmentDetailsBack}
            lookUp={lookUp}
            teamIdPayload={teamIdPayload}
            setTeamIdPayload={setTeamIdPayload}
          />
        )}
        {formValue === 6 && (
          <AdaptiveEquipmentsReturning
            onClickEquipmentCheckboxNext={handleEquipmentCheckboxNext}
            onClickEquipmentCheckboxBack={handlekEquipmentCheckboxBack}
          />
        )}
      </>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  .Stepper-wrapper {
    padding-bottom: 20px;
  }
  .circular-stepper-heading {
    color: #8a8a8a;
  }
  .MuiCircularProgress-progress,
  .MuiCircularProgress-sizeLg {
    color: #8a8a8a;
    stroke: #2dce98;
  }
  .css-zitgxn-JoyCircularProgress-track {
    stroke: #434343;
  }
  .k-step-done {
    .k-step-indicator-text {
      position: relative;
      ::after {
        position: absolute;
        content: '';
        z-index: 15;
        background: #2dce98;
        background-image: url(${check});
        background-repeat: no-repeat;
        background-size: inherit;
        background-position: center;
        border-radius: 50px;
        left: -8px;
        height: 26px;
        width: 26px;
        top: -1px;
      }
    }
  }
  height: 100%;
  width: 100%;
  display: flex;
  padding-top: 20px;
  flex-direction: column;
  align-items: center;
  .Team-Heading {
    color: #9597a6;
    padding-bottom: 10px;
  }
  input {
    ::placeholder {
      font-size: 14px;
      text-transform: capitalize;
    }
  }
  .k-stepper.form-stepper {
    pointer-events: none;
  }
  .k-stepper .k-step-indicator::after {
    box-shadow: unset !important;
  }
  .k-stepper .k-step-indicator::after {
    border-style: unset !important;
  }
  .k-stepper .k-step-label .k-step-text {
    width: 200px;
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #8a8a8a;
    font-size: 12px;
    max-width: unset !important;
  }
  .k-progressbar {
    background: #5b5b5b;
  }
  .k-progressbar .k-selected {
    background: #2dce98;
  }
  .k-stepper .k-step-done .k-step-indicator {
    border-color: #2dce98;
    color: white;
    background-color: #2dce98;
  }
  .k-stepper .k-step-current .k-step-indicator {
    border-color: #2dce98;
    color: white;
    background-color: #2dce98;
  }
  .k-stepper .k-step-indicator {
    border-color: #5b5b5b;
    color: #c4c4c4;
    background-color: #5b5b5b;
    width: 25px;
    height: auto;
  }
  .k-stepper {
    padding-bottom: 20px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        height: 55px;
        gap: 5px;
      }
      .circular-stepper-heading {
        font-size: 11px;
      }
      .MuiCircularProgress-root {
        --CircularProgress-size: 50px;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        height: 55px;
        padding: 0 12px;
      }
      .circular-stepper-heading {
        font-size: 16px;
      }
      .MuiCircularProgress-root {
        --CircularProgress-size: 55px;
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        height: 55px;
      }
      .circular-stepper-heading {
        font-size: 22px;
      }
    }
  }
`;
const StepperStep = styled.button`
  background-color: green !important;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;

  &.completed {
    background-color: green !important;
  }
`;
export default EWDRiderInformation;
