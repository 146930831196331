import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';
import TryitEWDPersonalInfoFieldsNew from './components/EWDPersonalInfoFields';
import TryitEWDParentDetailsNew from './components/EWDParentDetails';
import TryitEWDDivisionsNew from './components/EWDDivisons';
import {
  ApiError,
  EWD,
  EwdService,
  GeneralEWDs,
  GeneralLookup,
  LookupService,
  RiderService,
} from 'services/openapi';
import useApi from 'services/mavapi/useApi';
import TryitEligibilityConditionsNew from './components/ElgibilityConditions';
import { TryitEligibilityDetailsNew } from './components/ElgibilityDetails';
import Cookies from 'js-cookie';
import check from './assets/icon/check.png';
import { useManagePageSlice } from 'app/pages/slice';
import { selectPageLoader } from 'app/pages/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import LoaderWrapper from 'app/components/CircularLoader/LoaderWrapper';
import CircularLoader from 'app/components/CircularLoader';
import Box from '@mui/joy/Box';
import CircularProgress from '@mui/joy/CircularProgress';
import { heightWeightModel } from 'app/pages/RiderRegistrationPage/components/NotReturningRider';
import { selectPhotoUpload } from 'app/pages/RiderRegistrationPage/slice/selectors';
import { useRiderRegistrationStateSlice } from 'app/pages/RiderRegistrationPage/slice';
import { useEwdRegistrationStateSlice } from 'app/pages/EWDMembershipFormPage/slice';
import isApiError from 'utils/isApiError';
import { selectDiagonosissUpload } from 'app/pages/EWDMembershipFormPage/slice/selectors';
import { useErrorHandlingSlice } from 'app/error/slice';
type Props = {
  onClickBackPersonal?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};
const items = [
  { label: 'Personal Information', number: 1 },
  { label: 'Parent', number: 2 },
  // { label: 'Affiliations', number: 3 },
  { label: 'EWD Divisions', number: 3 },
  { label: 'Diagonosis Form', number: 4 },
  { label: 'Diagonosis Condition', number: 5 },
];

const TryitEWDNewMember = ({ onClickBackPersonal }: Props) => {
  const { lookupGetAllLookups } = LookupService;
  //for Loader
  const { actions: PageActions } = useManagePageSlice();
  const pageLoader = useSelector(selectPageLoader);
  const dispatch = useDispatch();

  const nextYear = new Date().getFullYear() + 1;
  const defaultValue: EWD = {
    id: null,
    isReturningRider: false,
    firstName: '',
    lastName: '',
    expectedGraduationYear: nextYear,
    dateOfBirth: '',
    height: 0,
    weight: 0,
    primaryPhone: '',
    email: '',
    street: '',
    city: '',
    zip: '',
    backNumber: '',
    state: {
      id: '',
      name: '',
      children: null,
    },
    parent: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      street: '',
      city: '',
      state: {
        id: '',
        name: '',
        children: null,
      },
      zip: '',
    },
    team: {
      id: '',
      name: '',
      children: null,
    },
    divisionClassOne: {
      id: '',
      name: '',
      children: null,
    },
    divisionClassTwo: {
      id: '',
      name: '',
      children: null,
    },

    isTrial: true,
    professionalAffiliationMap: [],
    usePhotoForPromotion: true,
    emergencyContactName: '',
    emergencyContactPhone: '',
  };
  const defaultPhotoValue = {
    formData: {
      file: undefined,
    },
  };
  const { error: apiError, handleRequest } = useApi();
  const [, setError] = React.useState<ApiError | unknown>();
  const { ewdCreateEwd, ewdUploadEwdPhoto } = EwdService;
  const { riderGetBackNumberList } = RiderService;
  const { actions } = useErrorHandlingSlice();
  const navigate = useNavigate();
  const { actions: manageAppActions } = useRiderRegistrationStateSlice();
  const { actions: manageEwdActions } = useEwdRegistrationStateSlice();
  const [value, setValue] = React.useState<number>(0);
  const [tryItEwdData, setTryItEwdData] = useState<EWD>(defaultValue);
  const [showStepper, setShowStepper] = useState(true);
  const [tryitResponseId, setTryitResponseId] = useState<any>();
  const [payerTypes, setPayerTypes] = useState<GeneralLookup>();
  const [teamData, setTeamData] = useState<GeneralLookup[]>([]);
  const [stateData, setStateData] = useState<GeneralLookup[]>([]);
  const [categoryData, setCategoryData] = useState<GeneralLookup[]>([]);
  const [affiliationsData, setAffiliationsData] = useState<GeneralLookup[]>([]);
  const [medicalConditionsData, setMedicalConditionsData] = useState<
    GeneralLookup[]
  >([]);
  const [cancelPaymentCookie, setCancelPaymentCookie] = useState<boolean>();

  const { ewdCreateEwdDiagnosis, ewdUploadDiagnosisData } = EwdService;
  const defaultData: GeneralEWDs = {
    riderId: tryitResponseId,
    physicianName: '',
    physicianLicense: '',
    physicianPracticeLocation: '',
    medicalConditions: [],
  };
  const [diaganosisData, setDiaganosisData] =
    useState<GeneralEWDs>(defaultData);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 767);
  const [isBackNumberCheck, setIsBackNumberCheck] = useState<boolean | null>(
    null,
  );
  const [heightWeight, setHeightWeight] = useState<heightWeightModel>({
    feet: undefined,
    inches: undefined,
    weight: undefined,
  });
  const [seasonDivison, setSeasonDivision] = useState<
    (string[] | undefined)[] | undefined
  >([]);
  const [isphotoforPromotion, setIsphotoforPromotion] = useState<
    boolean | null
  >(null);
  const [photoUploadPayload, setPhotoUploadPayload] = useState<{
    formData?: {
      file?: Blob;
    };
  }>(defaultPhotoValue);
  const [fileAvailability, setFileAvailability] = React.useState<boolean>(true);
  const [uploadFile, setUploadFile] = useState<File | null>();
  const photoUploadDataRedux = useSelector(selectPhotoUpload);

  const totalPages = 5;
  const determinateValue = ((value + 1) / totalPages) * 100;
  const [backNumberData, setBackNumberData] = React.useState<
    string[] | undefined
  >([]);
  const [otherData, setOtherData] = useState<string>('');
  const [diagonosisUploadPayload, setDiagonosisUploadPayload] =
    useState<Blob>();
  const [diagonosisFileAvailability, setDiagonosisFileAvailability] =
    React.useState<boolean>(true);
  const [diagonosisUploadFile, setDiagonosisUploadFile] =
    useState<File | null>();
  const diagonosisUploadDataRedux = useSelector(selectDiagonosissUpload);

  const handleChangeStepper = (e: StepperChangeEvent) => {
    const index = Number(e.value);
    if (index >= 0 && index !== value) {
      setValue(index);
    }
  };
  function getBackNumberList() {
    handleRequest(riderGetBackNumberList())
      .then(data => {
        setBackNumberData(data);
      })
      .catch(() => setError(apiError));
  }
  const handleItemClick = (index: number) => {};

  const handlePersonalNxt = () => {
    setValue(1);
  };
  const handleParentNxtBack = () => {
    setValue(0);
  };
  const handleParentNxt = () => {
    setValue(2);
  };
  // const handleAffiliationsBack = () => {
  //   setValue(1);
  // };
  // const handleAffiliationsNxt = () => {
  //   setValue(3);
  // };

  const handleDivionsBack = () => {
    setValue(1);
  };

  const handleEwdNewMemberSubmit = (requestBody?: EWD) => {
    dispatch(PageActions.setPageLoader(true));
    handleRequest(ewdCreateEwd(requestBody))
      .then(res => {
        if (res && !isApiError(res)) {
          if (tryItEwdData.usePhotoForPromotion === false) {
            handlePhotoUpload(res.toString(), photoUploadPayload.formData);
          }
          Cookies.set('riderId', res);
          dispatch(
            actions.dispatchToast({
              errorTxt: 'EWD rider registered successful',
              show: true,
              errorType: 'success',
            }),
          );
          setTryitResponseId(res);
          setShowStepper(!showStepper);
          setValue(3);
          dispatch(manageAppActions.setPhotoUpload(photoUploadPayload));
          dispatch(PageActions.setPageLoader(false));
        } else {
          dispatch(
            actions.dispatchToast({
              errorTxt: res.description,
              show: true,
              errorType: 'error',
            }),
          );
          dispatch(PageActions.setPageLoader(false));
        }
      })
      .catch(error => {
        if (error) {
          dispatch(
            actions.dispatchToast({
              errorTxt: error.description,
              show: true,
              errorType: 'error',
            }),
          );
          dispatch(PageActions.setPageLoader(false));
          setError(apiError);
        }
      });
  };
  const handlePhotoUpload = (
    riderId: string,
    formData?: {
      file?: Blob;
    },
  ) => {
    handleRequest(ewdUploadEwdPhoto(riderId, formData))
      .then(res => {})
      .catch(error => {});
  };
  const handleDiagonosisFormUpload = (formData?: {
    File: Blob;
    ApplicationId: string;
  }) => {
    handleRequest(ewdUploadDiagnosisData(formData))
      .then(res => {})
      .catch(error => {});
  };

  const handleEligibilityDetailsBack = () => {
    setShowStepper(!showStepper);
    setValue(2);
  };
  const handleEligibilityDetailsNext = () => {
    setValue(4);
  };

  const handleEligibilityConditionsBack = () => {
    setValue(3);
  };
  //HAndle get lookups
  const handleGetLookups = () => {
    handleRequest(
      lookupGetAllLookups(
        'PAYER_TYPES,STATE,TEAM,PROFESSIONALAFFILIATIONS,CATEGORY,MEDICALCONDITIONS,DIVISION,ACTIVETEAM',
      ),
    )
      .then(res => {
        let varTeam = res?.filter(item => {
          if (item?.type === 'ACTIVETEAM') {
            return item.values;
          }
        });
        let varCategories = res?.filter(item => {
          if (item?.type === 'CATEGORY') {
            return item?.values?.map(data => {
              if (data.name === 'EWD') {
                return setCategoryData(data?.children!);
              }
            });
          }
        });
        let varState = res?.filter(item => {
          if (item?.type === 'STATE') {
            return item.values;
          }
        });
        let varAffiliations = res?.filter(item => {
          if (item?.type === 'PROFESSIONALAFFILIATIONS') {
            return item.values?.map(data => {
              return data.name;
            });
          }
        });
        let varMedicalConditions = res?.filter(item => {
          if (item?.type === 'MEDICALCONDITIONS') {
            return item.values?.map(e => {
              return e.name;
            });
          }
        });

        let varPayerTypes = res?.filter(item => {
          if (item?.type === 'PAYER_TYPES') {
            return item.values;
          }
        });
        let payerTypeObj = varPayerTypes?.[0]?.values?.find(item => {
          if (item?.name === 'Rider') {
            return item ?? {};
          }
        });
        let varDivison = res?.filter(item => {
          if (item?.type === 'DIVISION') {
            return item.values;
          }
        });
        let filteredDIvison = varDivison?.map(item =>
          item.values?.map(data => data.name),
        );
        setTeamData(varTeam?.[0]?.values!);
        setStateData(varState?.[0]?.values!);
        setAffiliationsData(varAffiliations?.[0]?.values!);
        setMedicalConditionsData(varMedicalConditions?.[0]?.values!);
        setPayerTypes(payerTypeObj);
        setSeasonDivision(filteredDIvison);

        dispatch(PageActions.setPageLoader(false));
      })

      .catch(error => {});
  };
  const handleEligibilityConditionsNext = () => {
    let riderId = Cookies.get('riderId');
    const diagnosisPayload = {
      ...diaganosisData,
      riderId: riderId ? riderId : tryitResponseId,
    };
    dispatch(PageActions.setPageLoader(true));
    handleRequest(ewdCreateEwdDiagnosis(diagnosisPayload))
      .then(res => {
        if (res && !isApiError(res)) {
          if (cancelPaymentCookie) {
            Cookies.remove('paymentCancel');
          }
          dispatch(
            manageEwdActions.setDiagonosisPayloadUpload(
              diagonosisUploadPayload,
            ),
          );
          const uploadPayload = {
            File: diagonosisUploadPayload as Blob,
            ApplicationId: res,
          };
          Cookies.set(
            'diagnosisFormUploadPayload',
            JSON.stringify(uploadPayload),
          );
          handleDiagonosisFormUpload(uploadPayload);
          const ewdNewDataString = JSON.stringify(tryItEwdData);
          Cookies.set('OtherMedicalConditionData', otherData);
          Cookies.set('payload', ewdNewDataString);
          const diaganosisDataString = JSON.stringify(diaganosisData);
          Cookies.set('diaganosisPayload', diaganosisDataString);
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Submitted successfully',
              show: true,
              errorType: 'success',
            }),
          );
          dispatch(PageActions.setPageLoader(false));
          navigate(`/payment/${payerTypes?.id}/${riderId}/false/false`);
        } else {
          dispatch(
            actions.dispatchToast({
              errorTxt: res.description,
              show: true,
              errorType: 'error',
            }),
          );
          dispatch(PageActions.setPageLoader(false));
        }
      })
      .catch(error => {
        dispatch(
          actions.dispatchToast({
            errorTxt: error.description,
            show: true,
            errorType: 'error',
          }),
        );
        dispatch(PageActions.setPageLoader(false));
      });
  };
  React.useEffect(() => {
    //loader
    dispatch(PageActions.setPageLoader(true));

    //Lookups function call
    handleGetLookups();
    getBackNumberList();

    //cookies data on cancelling pay
    const ewdDataCookie = Cookies.get('payload');
    const paymentCancelStatus = Cookies.get('paymentCancel');
    setCancelPaymentCookie(paymentCancelStatus);
    if (ewdDataCookie) {
      const updatedValue = JSON.parse(ewdDataCookie);
      setTryItEwdData(updatedValue);
      setShowStepper(false);
      setValue(4);
      setIsphotoforPromotion(updatedValue.usePhotoForPromotion);
      setHeightWeight({
        feet: Math.floor(updatedValue?.height!),
        inches: Math.round(
          (updatedValue?.height! - Math.floor(updatedValue?.height!)) * 12,
        ),
        weight: updatedValue.weight,
      });
      setPhotoUploadPayload(photoUploadDataRedux ?? {});
      setUploadFile(photoUploadDataRedux?.formData?.file as File);
      setDiagonosisUploadPayload(diagonosisUploadDataRedux);
      setDiagonosisUploadFile(diagonosisUploadDataRedux as File);
      const OtherMedicalConditionData = Cookies.get(
        'OtherMedicalConditionData',
      );
      setOtherData(OtherMedicalConditionData);
    }
    const diaganosisDataCookie = Cookies.get('diaganosisPayload');
    setCancelPaymentCookie(paymentCancelStatus);
    if (diaganosisDataCookie) {
      const updatedValue = JSON.parse(diaganosisDataCookie);
      setDiaganosisData(updatedValue);
    }

    //screen size handling for small sizes
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 767);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(manageEwdActions.setFormValue(value));
  }, [value]);
  return (
    <>
      {pageLoader ? (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      ) : (
        <MainWrapper>
          <>
            <p className="Team-Heading">New Member</p>
            <div className="Stepper-wrapper">
              {isSmallScreen ? (
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <CircularProgress
                    size="lg"
                    determinate
                    value={determinateValue}
                  >
                    {`${value + 1} / ${totalPages}`}
                  </CircularProgress>
                  {items
                    .filter((_, index) => index === value)
                    .map((item, index) => (
                      <h2 className="circular-stepper-heading" key={index}>
                        {item.label}
                      </h2>
                    ))}
                </Box>
              ) : (
                <Stepper
                  value={value}
                  onChange={handleChangeStepper}
                  items={items}
                  className="form-stepper"
                >
                  {items.map((item, index) => (
                    <StepperStep
                      key={index}
                      className={`stepper-step ${
                        index < value ? 'completed' : ''
                      }`}
                      onClick={() => handleItemClick(index)}
                    >
                      {index < value ? (
                        <span className="tick-icon">&#10003;</span>
                      ) : (
                        item.number
                      )}
                    </StepperStep>
                  ))}
                </Stepper>
              )}
            </div>
            <>
              {value === 0 && (
                <TryitEWDPersonalInfoFieldsNew
                  onClickNxtPersonal={handlePersonalNxt}
                  setTryItEwdData={setTryItEwdData}
                  tryItEwdData={tryItEwdData}
                  stateData={stateData}
                  teamData={teamData}
                  setIsBackNumberCheck={setIsBackNumberCheck}
                  setHeightWeight={setHeightWeight}
                  heightWeight={heightWeight}
                  backNumberData={backNumberData}
                />
              )}
              {value === 1 && (
                <TryitEWDParentDetailsNew
                  onClickBackParent={handleParentNxtBack}
                  onClickNxtParent={handleParentNxt}
                  setTryItEwdData={setTryItEwdData}
                  tryItEwdData={tryItEwdData}
                  stateData={stateData}
                />
              )}
              {/*  dont remove this code
              {value === 2 && (
                <TryitEWDAffiliationsNew
                  onClickBackAffiliations={handleAffiliationsBack}
                  onClickNxtAffiliations={handleAffiliationsNxt}
                  setTryItEwdData={setTryItEwdData}
                  tryItEwdData={tryItEwdData}
                  affiliationsData={affiliationsData}
                />
              )} */}
              {value === 2 && (
                <TryitEWDDivisionsNew
                  onClickBackDivions={handleDivionsBack}
                  handleEwdNewMemberSubmit={handleEwdNewMemberSubmit}
                  setTryItEwdData={setTryItEwdData}
                  tryItEwdData={tryItEwdData}
                  categoryData={categoryData}
                  seasonDivison={seasonDivison}
                  setIsphotoforPromotion={setIsphotoforPromotion}
                  isphotoforPromotion={isphotoforPromotion}
                  setPhotoUploadPayload={setPhotoUploadPayload}
                  photoUploadPayload={photoUploadPayload}
                  setFileAvailability={setFileAvailability}
                  fileAvailability={fileAvailability}
                  setUploadFile={setUploadFile}
                  uploadFile={uploadFile}
                />
              )}

              {value === 3 && (
                <TryitEligibilityDetailsNew
                  onClickEligibilityDetailsBack={handleEligibilityDetailsBack}
                  onClickEligibilityDetailsNext={handleEligibilityDetailsNext}
                  setTryItEwdData={setTryItEwdData}
                  tryItEwdData={tryItEwdData}
                  stateData={stateData}
                  teamData={teamData}
                />
              )}
              {value === 4 && (
                <TryitEligibilityConditionsNew
                  onClickEligibilityConditionsBack={
                    handleEligibilityConditionsBack
                  }
                  tryItEwdData={tryItEwdData}
                  setValue={setValue}
                  tryitResponseId={tryitResponseId}
                  payerTypes={payerTypes}
                  medicalConditionsData={medicalConditionsData}
                  setShowStepper={setShowStepper}
                  handleEligibilityConditionsNext={
                    handleEligibilityConditionsNext
                  }
                  diaganosisData={diaganosisData}
                  setDiaganosisData={setDiaganosisData}
                  setOtherData={setOtherData}
                  otherData={otherData}
                  diagonosisUploadPayload={diagonosisUploadPayload}
                  setDiagonosisUploadPayload={setDiagonosisUploadPayload}
                  diagonosisFileAvailability={diagonosisFileAvailability}
                  diagonosisUploadFile={diagonosisUploadFile}
                  setDiagonosisFileAvailability={setDiagonosisFileAvailability}
                  setDiagonosisUploadFile={setDiagonosisUploadFile}
                />
              )}

              {/*dont remove this code
          {value === 6 && (
            <TryitEquipmentDetailsNew
              onClickEqupmentDetailsNext={handleEqupmentDetailsNext}
              onClickEqupmentDetailsBack={handleEqupmentDetailsBack}
              setTryItEwdData={setTryItEwdData}
              tryItEwdData={tryItEwdData}
            />
          )}
          {value === 7 && (
            <TryitAdaptiveEquipmentsNew
              onClickEquipmentCheckboxSubmit={handleEquipmentCheckboxSubmit}
              onClickEquipmentCheckboxBack={handlekEquipmentCheckboxBack}
            />
          )} */}
            </>
          </>
        </MainWrapper>
      )}
    </>
  );
};

const MainWrapper = styled.div`
  .circular-stepper-heading {
    color: #8a8a8a;
  }
  .MuiCircularProgress-progress,
  .MuiCircularProgress-sizeLg {
    color: #8a8a8a;
    stroke: #2dce98;
  }
  .css-zitgxn-JoyCircularProgress-track {
    stroke: #434343;
  }
  .k-step-done {
    .k-step-indicator-text {
      position: relative;
      ::after {
        position: absolute;
        content: '';
        z-index: 15;
        background: #2dce98;
        background-image: url(${check});
        background-repeat: no-repeat;
        background-size: inherit;
        background-position: center;
        border-radius: 50px;
        left: -8px;
        height: 26px;
        width: 26px;
        top: -1px;
      }
    }
  }
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 30px;
  flex-direction: column;
  align-items: center;
  .k-stepper.form-stepper {
    pointer-events: none;
  }
  input {
    ::placeholder {
      font-size: 14px;
      text-transform: capitalize;
    }
  }
  .k-stepper .k-step-indicator::after {
    box-shadow: unset !important;
  }
  .k-stepper .k-step-indicator::after {
    border-style: unset !important;
  }
  .k-stepper .k-step-label .k-step-text {
    width: 200px;
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #8a8a8a;
    font-size: 12px;
    max-width: unset !important;
  }
  .k-progressbar {
    background: #5b5b5b;
  }
  .k-progressbar .k-selected {
    background: #2dce98;
  }
  .k-stepper .k-step-done .k-step-indicator {
    border-color: #2dce98;
    color: white;
    background-color: #2dce98;
  }
  .k-stepper .k-step-current .k-step-indicator {
    border-color: #2dce98;
    color: white;
    background-color: #2dce98;
  }
  .k-stepper .k-step-indicator {
    border-color: #5b5b5b;
    color: #c4c4c4;
    background-color: #5b5b5b;
    width: 25px;
    height: auto;
  }
  .k-stepper {
    padding-bottom: 20px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        height: 55px;
        gap: 5px;
      }
      .circular-stepper-heading {
        font-size: 13px;
      }
      .MuiCircularProgress-root {
        --CircularProgress-size: 50px;
      }
    }
    .k-stepper .k-step-label .k-step-text {
      width: 55px;
      font-size: 10px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        height: 55px;
        padding: 0 5px;
      }
      .circular-stepper-heading {
        font-size: 18px;
      }
      .MuiCircularProgress-root {
        --CircularProgress-size: 55px;
      }
    }
    .k-stepper .k-step-label .k-step-text {
      width: 55px;
      font-size: 11px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        height: 55px;
        padding: 0 5px;
        gap: 25px;
      }
      .circular-stepper-heading {
        font-size: 22px;
      }
    }
  }
`;
const StepperStep = styled.button`
  background-color: green !important;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;

  &.completed {
    background-color: green !important;
  }
`;
export default TryitEWDNewMember;
