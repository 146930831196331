type Props = {
  value: string;
};

export const CommonCell = ({ value }: Props) => {
  return (
    <td style={{ alignItems: 'center' }}>
      <div>{value}</div>
    </td>
  );
};
