// DropdownComponent.tsx
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import AdminCustomButton from '../AdminCustomButton';
import {
  Unstable_Popup as BasePopup,
  PopupPlacement,
} from '@mui/base/Unstable_Popup';
interface DropdownProps {
  data: string[];
  onItemClick?: (item: string) => void;
  svgIcon?: React.ReactNode;
  label?: string;
  className?: string;
  onDataClick?: any;
  placement?: PopupPlacement;
}

const DropdownComponent: React.FC<DropdownProps> = ({
  data,
  onItemClick,
  svgIcon,
  label,
  className,
  onDataClick,
  placement,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = event => {
    setIsOpen(!isOpen);
    setAnchor(anchor ? null : event.currentTarget);
  };

  const handleItemClick = (item: string) => {
    onItemClick?.(item);
    setIsOpen(false);
    onDataClick();
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!dropdownRef.current?.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
  const id = isOpen ? 'simple-popper' : undefined;
  return (
    <DropdownContainer aria-describedby={id}>
      <AdminCustomButton
        buttonType="icon"
        className={
          label ? 'filter-button dropdown-filter-button' : 'filter-icon'
        }
        onClick={toggleDropdown}
      >
        {svgIcon}
        {label}
      </AdminCustomButton>
      <StyledBasePopup
        id={id}
        open={isOpen}
        anchor={anchor}
        placement={placement}
        disablePortal
      >
        <DropdownMenu ref={dropdownRef} className={className}>
          {data.map((item, index) => (
            <DropdownItem key={index} onClick={() => handleItemClick(item)}>
              {item}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </StyledBasePopup>
    </DropdownContainer>
  );
};

export default DropdownComponent;
const StyledBasePopup = styled(BasePopup)`
  z-index: 2;
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  .filter-icon {
    button {
      width: unset !important;
      min-width: 20px !important;
      margin: 0;
      padding: 5px 10px;
      height: auto;
      border-radius: 5px;
      &:hover,
      &:focus,
      &:active {
        background-color: #a19b9b3e !important;
      }
      &::before,
      &::after {
        display: none !important;
      }
      svg {
        margin: unset !important;
      }
    }
  }
  .Mui-open {
    z-index: 1;
  }
`;

const DropdownMenu = styled.div`
  top: calc(100% + 5px);
  background-color: #ffffff;
  border: 1px solid #b6b5b538;
  border-radius: 5px;
  min-width: 125px;
  z-index: 10001;
  padding: 5px 0;
  box-shadow: var(
    --ds-shadow-overlay,
    0 4px 8px -2px rgba(9, 30, 66, 0.25),
    0 0 1px rgba(9, 30, 66, 0.31)
  );
`;

const DropdownItem = styled.div`
  color: #000000;
  padding: 10px 15px;
  border-radius: 0;
  text-decoration: none;
  display: flex;
  justify-content: start;
  cursor: pointer;
  font-size: 12px;
  &:hover {
    background-color: #f1f1f1;
  }
`;
