import React from 'react';
import {
  DropDownList,
  DropDownListChangeEvent,
  DropDownListBlurEvent,
  DropDownListOpenEvent,
  DropDownListCloseEvent,
  ListItemProps,
} from '@progress/kendo-react-dropdowns';
import styled, { css } from 'styled-components';
import '@fontsource/arimo';
import { GeneralLookup } from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';
import { filterBy } from '@progress/kendo-data-query';
import '@fontsource/roboto';
import arrow from '../AdminDropDown/assets/arrow.svg';
import { FormSkeletonLoader } from '../FormSkeletonLoader';

export type DropDownModel = {
  id?: number;
  text?: string;
};

type Props = {
  disabled?: boolean;
  data?: GeneralLookup[];
  defaultValue?: GeneralLookup;
  onChange?: (event: DropDownListChangeEvent) => void;
  onDataChange?: (data: GeneralLookup) => void;
  onGeneralDataChange?: (data: DropDownModel) => void;
  genericData?: DropDownModel[];
  genericDefaultValue?: DropDownModel;
  label?: string;
  onClick?: (e: any) => void;
  className?: string;
  placeholder?: string;
  validator?: SimpleReactValidator;
  validations?: string;
  hideStar?: boolean;
  name?: string | undefined;
  isLoader?: boolean;
  hideSearch?: boolean;
  isCustomValidationError?: boolean;
  customErrorText?: string;
  popupStyles?: string | undefined;
  onClose?: (event: DropDownListCloseEvent) => void;
};

const AdminDropDown = ({
  disabled,
  data,
  defaultValue,
  onChange,
  label,
  className,
  onDataChange,
  placeholder,
  genericData,
  genericDefaultValue,
  onGeneralDataChange,
  validations,
  validator,
  hideStar,
  name,
  isLoader,
  hideSearch,
  isCustomValidationError,
  customErrorText,
  popupStyles,
  onClose,
}: Props) => {
  const simpleValidator = React.useRef(validator);
  const [open, setOpen] = React.useState<boolean>(false);
  const [dropData, setDropData] = React.useState<any>();
  const [dropDataClone, setDropDataClone] = React.useState<any>();
  const dropdownRef = React.useRef<DropDownList | null>(null);

  var convertedData = data?.map((item: GeneralLookup, index) => {
    return { id: index, text: item.name ?? '', value: item.id };
  });
  const defaultIndex =
    convertedData?.findIndex(item => item.value === defaultValue?.id) ?? -1;

  var convertedDefaultValue = {
    id: defaultIndex,
    text: defaultValue?.name ? defaultValue?.name : placeholder,
    value: defaultValue?.id ? defaultValue?.id : '',
  };
  var convertedDefault = {
    id: 599,
    text: defaultValue?.name ?? '',
    value: defaultValue?.id,
  };
  var genericConvertedDefault = {
    id: 599,
    text: genericDefaultValue?.text ?? '',
    value: genericDefaultValue?.id,
  };
  var genericConvertedData = genericData?.map((item: DropDownModel, index) => {
    return { id: index, text: item.text ?? '', value: item.id };
  });

  const popupSettingsValue = {
    animate: false,
    className: `admin-dropdown-component-custom ${popupStyles} ${
      hideSearch && 'hide-search'
    }`,
  };

  const handleBlur = (e: DropDownListBlurEvent) => {
    setOpen(false);
    simpleValidator?.current?.showMessageFor(label ? label : 'field');
  };

  const handleOnClose = (e: DropDownListCloseEvent) => {
    setOpen(false);
    setFilterValue('');
    setDropData(dropDataClone);
    onClose?.(e);
  };

  const handleClick = (e: DropDownListOpenEvent) => {
    setOpen(!open);
  };

  const handleChange = (event: DropDownListChangeEvent) => {
    var value = event.target.value;
    if (data) {
      var generalLookup = data.find(obj => {
        return obj.id === value.value;
      });
      if (onDataChange && generalLookup) {
        onDataChange(generalLookup);
      }
    } else if (genericData) {
      var generalData = genericData?.find(obj => {
        return obj.id === value.value;
      });
      if (onGeneralDataChange && generalData) {
        onGeneralDataChange(generalData);
      }
    }
    setFilterValue('');

    // Unset focus after a short delay
    setTimeout(() => {
      if (dropdownRef.current) {
        dropdownRef?.current?.element?.blur();
      }
    }, 0);
  };

  const [filterValue, setFilterValue] = React.useState<string>();

  const filterData = filter => {
    const newData = convertedData?.slice();
    return filterBy(newData!, filter);
  };

  const filterChange = event => {
    setFilterValue(event.filter.value);
    setDropData(filterData(event.filter));
  };

  React.useEffect(() => {
    setDropData(genericData ? genericConvertedData : convertedData?.slice());
    setDropDataClone(
      genericData ? genericConvertedData : convertedData?.slice(),
    );
  }, [data]);

  return (
    <Wrapper
      className={`SHdropdown ${className}
    `}
    >
      {!isLoader ? (
        <>
          <div className="label">
            {label ?? ''}
            <span className="red-star">
              {validations && !hideStar ? '*' : ''}
            </span>
          </div>

          <StyleDropDownList
            ref={dropdownRef}
            className={`
            ${StyleDropDownList} 
            ${open ? 'inverted-arrow' : 'default'}
            `}
            filterable={true}
            textField="text"
            dataItemKey="id"
            data={dropData}
            onFilterChange={filterChange}
            filter={filterValue}
            value={
              genericData ? genericConvertedDefault : convertedDefaultValue
            }
            popupSettings={popupSettingsValue}
            disabled={disabled}
            onOpen={handleClick}
            onChange={handleChange}
            iconClassName="inverted-custom-arrow"
            onClose={handleOnClose}
            onBlur={handleBlur}
            name={name}
            opened={open}
          />
          {isCustomValidationError ? (
            <label className="srv-validation-message ">{customErrorText}</label>
          ) : (
            <>
              {simpleValidator?.current?.message(
                label ? label : 'field',
                convertedDefault?.value || genericConvertedDefault?.value,
                validations ?? '',
              )}
            </>
          )}
        </>
      ) : (
        <FormSkeletonLoader textHeight={44} labelHeight={20} isLabel />
      )}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  .k-input-button {
    width: auto !important;
  }

  .srv-validation-message {
    color: #f44336;
    font-size: 12px;
    display: inline-block;
  }
  .label {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    color: #a3a3a3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 5px;
    letter-spacing: 0.5px;
    line-height: 19.6px;
  }
  .red-star {
    color: red;
    font-weight: bold;
  }

  :has(input:disabled) {
    .red-star {
      display: none !important;
    }
  }
  //Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    .label {
      font-size: 10px !important;
    }
    .k-input-value-text {
      height: 30px;
      font-size: 11px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .label {
      font-size: 11px !important;
    }
    .k-input-value-text {
      height: 30px;
      font-size: 12px !important;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .label {
      font-size: 12px;
    }
    .k-input-value-text {
      font-size: 13px !important;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .label {
      font-size: 13px;
    }
    .k-input-value-text {
      font-size: 14px !important;
    }
  }
`;
const StyleDropDownList = styled(DropDownList)`
  max-height: 44px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 2px;
  background: #ffffff !important;
  color: #475467 !important;
  border-radius: 8px !important;
  border: 1px solid #caced8 !important;
  box-shadow: none !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  border-radius: 10px;
  font-family: 'Inter' !important;
  font-weight: 400 !important;
  &.k-disabled {
    opacity: 1;
    border-color: #f5f5f5 !important;
    background-color: #fafafa !important;
    button {
      display: none;
    }
  }

  cursor: pointer;
  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    box-shadow: none;
  }
  &.custom-dropdown-component {
    background: red;
    .k-popup {
      .k-input-solid {
        background: #f44336 !important;
      }
    }
  }

  .k-input-value-text {
    margin: 2px 0px 0px -4px;
    font-family: 'Inter' !important;
    font-weight: 500 !important;
    font-size: 15px;
    letter-spacing: 0.5px;
    line-height: 18.5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 2px;
    max-width: calc(100% - 10px);
  }

  &.inverted-arrow {
    .k-button {
      border: none !important;
      color: #ffffff;

      .inverted-custom-arrow {
        content: '';
        position: absolute;
        width: 13px;
        height: 13px;
        background-image: url(${arrow});
        bottom: 0px;
        right: 10px;
        transform: rotate(180deg) !important;

        &:before {
          display: none !important;
        }
      }
    }
  }

  &.default {
    .k-button {
      border: none !important;
      color: #ffffff;

      .inverted-custom-arrow {
        content: '';
        position: absolute;
        width: 13px;
        height: 13px;
        background-image: url(${arrow});
        top: 0px;
        right: 14px;

        &:before {
          display: none !important;
        }
      }
    }
  }
  //Component Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    height: 30px !important;
    font-size: 12px;
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 21px;
          }
        }
      }
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    height: 30px !important;
    font-size: 12px;
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 23px;
          }
        }
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 22px;
          }
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 23px;
          }
        }
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 23px;
          }
        }
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 24px;
          }
        }
      }
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 25px;
          }
        }
      }
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 27px;
          }
        }
      }
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 30px;
          }
        }
      }
    }
  }
`;
export default AdminDropDown;
