import { BackNextButton } from 'app/components/BackNextButton';
import { CheckBox } from 'app/components/CheckBox';
import SHDatePicker from 'app/components/SHDatePicker';
import { TextField } from 'app/components/TextField';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

type Props = {};
const data = [
  { id: 1, condition: 'Amputation' },
  { id: 2, condition: 'Ankylosis' },
  { id: 3, condition: 'Arthrogryposis' },
  { id: 4, condition: "Asperger's Syndrome" },
  { id: 5, condition: 'Autism' },
  { id: 6, condition: "Batten's Disease" },
  { id: 7, condition: 'Cerebrovascular Accident (stroke)' },
  { id: 8, condition: 'Cerebella Ataxia' },
  { id: 9, condition: 'Cerebral Palsy' },
  { id: 10, condition: 'Coffin Lowry Syndrome' },
  { id: 11, condition: 'Cystic Fibrosis' },
  { id: 12, condition: 'Down Syndrome' },
  { id: 13, condition: 'Dwarfism' },
  { id: 14, condition: 'Ehlers-Danlos Syndrome' },
  { id: 15, condition: 'Fragile X Syndrome' },
  { id: 16, condition: "Freidrick's Ataxia" },
  { id: 17, condition: 'Guillain-Barre Syndrome' },
  { id: 18, condition: 'Hearing Impairment' },
  { id: 19, condition: "Hunter's Syndrome" },
  { id: 20, condition: 'Intellectual Disability' },
  { id: 21, condition: 'Juvenile Rheumatoid Arthritis' },
  { id: 22, condition: 'Cognitive Disabilities' },
  { id: 23, condition: 'Mental retardation' },
  { id: 24, condition: 'Microcephaly' },
  { id: 25, condition: 'Multiple Sclerosis' },
  { id: 26, condition: 'Muscular Dystrophy' },
  { id: 27, condition: 'Paresis' },
  { id: 28, condition: 'Post Polio Syndrome' },
  { id: 29, condition: 'Prader-Willi Syndrome' },
  { id: 30, condition: 'Rhett Syndrome' },
  { id: 31, condition: 'Spina Bifida' },
  { id: 32, condition: 'Spinal Cord Injury' },
  { id: 33, condition: 'Tourette Syndrome' },
  { id: 34, condition: 'Traumatic Brain Injury' },
  { id: 35, condition: 'Trisomy Abnormalities' },
  { id: 36, condition: 'Visual Impairments' },
  { id: 37, condition: 'Upper Motor Neuron Lesions' },
  { id: 38, condition: 'Other' },
];

const TryitEligibilityConditionsReturning = (props: Props) => {
  const navigate = useNavigate();
  const [detailsData, setDetailsData] = useState({
    physicianName: '',
    physicianSignature: '',
    license: '',
    cityState: '',
  });
  const [value, setValue] = useState(new Date());
  const handleChange = event => {
    const { name, value } = event.target;
    console.log('changed', value);
    setDetailsData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const changeDate = event => {
    setValue(event.value);
  };

  const handleNxt = () => {
    navigate('/tryit-ewd-membership-form/returning-member/equipment-details');
  };
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <MainWrapper>
      <OverflowWrapper>
        <p className="first-para">
          A membership registration forms for Equestrian with Disabilities must
          be accompanied by a Diagnosis and Adaptive Equipment Form.
        </p>
        <p className="para-two">
          <i>
            Eligible Conditions: From the list below, please indicate each
            condition which applies to the applicant. Other conditions will be
            considered upon request (please list in space provided).
          </i>
        </p>
        <CheckboxWrapper>
          {data.map(value => (
            <CheckBox key={value.id} label={value.condition} />
          ))}
        </CheckboxWrapper>
        <InfoWrapper>
          <p className="para-one">
            <i>Medicial Statement:</i>
          </p>
          <p>
            {' '}
            In accordance with YEDA Rulebook, this applicant has been diagnosed
            with the above designated condition(s).
          </p>
        </InfoWrapper>
        <TextFieldGrid>
          <StyledTextField
            onChange={handleChange}
            value={detailsData.physicianName || ''}
            label="Name of Physician"
            type="text"
            name="physicianName"
          />
          <StyledSHDatePicker
            onChange={changeDate}
            placeholder=""
            value={value || ''}
            name="dob"
            className="datepicker-style"
            dateLabel="Date"
          />
          <StyledTextField
            onChange={handleChange}
            value={detailsData.physicianSignature || ''}
            label="Signature of Physician "
            type="text"
            name="physicianSignature"
          />
          <StyledTextField
            onChange={handleChange}
            value={detailsData.license || ''}
            label="License"
            type="text"
            name="license"
          />
          <StyledTextField
            onChange={handleChange}
            value={detailsData.cityState || ''}
            label="City & State of Practice"
            type="text"
            name="cityState"
          />
          <StyledSHDatePicker
            onChange={changeDate}
            placeholder=""
            value={value || ''}
            name="dob"
            className="datepicker-style"
            dateLabel="Date"
          />
          <StyledTextField
            onChange={handleChange}
            value={detailsData.cityState || ''}
            label="Signature of participant or parent/guardian (if rider is under 18)"
            type="text"
            name="cityState"
          />
        </TextFieldGrid>
        <ButtonWrapper className="bg-color-change">
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={handleNxt}
            onClickBack={handleBack}
            next="Next"
            back="Cancel"
          />
        </ButtonWrapper>
      </OverflowWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  color: #9597a6;
  width: 100%;
  height: calc(100vh - 200px);
  position: relative;
  padding: 20px 0px 0px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  .para-two {
    color: #ff9900;
    border-bottom: 1px solid #585858;
    padding: 0px 0px 10px;
    font-size: 11px;
    margin-bottom: 20px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    // padding-top: 20px;
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    // padding-top: 20px;
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    // padding-top: 20px;
    label {
      font-size: 14px;
    }
  }
`;
const OverflowWrapper = styled.div`
  height: 100%;
  overflow: auto;
  .k-input-solid {
    width: 325px !important;
  }
`;
const CheckboxWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); // Adjust the number of columns here
  grid-gap: 10px;
`;
const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const StyledTextField = styled(TextField)`
  flex: 0 0 calc(50% - 20px);
  margin: 0px 0px 10px !important;
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const StyledSHDatePicker = styled(SHDatePicker)`
  flex: 0 0 calc(50% - 20px);
  margin: 0px 0px 10px !important;
  &.k-input-solid {
    width: 100% !important;
  }
`;

const ButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: -125px;
    left: -14px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;
const InfoWrapper = styled.div`
  width: 100%;
  border: 1px solid #585858;
  border-radius: 10px;
  margin: 30px 0px 20px;
  padding: 10px 20px;
  background: #27292d;
  .para-one {
    font-family: 'Roboto';
    color: #ff9900;
    border-bottom: 1px solid #585858;
  }
  .notice-para {
    text-align: center;
    border-top: 1px solid #585858;
    border-bottom: 1px solid #585858;
    margin: 10px 35px;
    padding: 10px 0px;
  }
`;
export default TryitEligibilityConditionsReturning;
