import React from 'react';
import styled from 'styled-components';
import '@fontsource/oswald';
import horseBg from '../../assets/img/bg/nonCompetingReturning.jpg';
import '@fontsource/roboto';
import { GeneralLookup, NonCompeting } from 'services/openapi';
import { useNavigate } from 'react-router-dom';
import { BackNextButton } from 'app/components/BackNextButton';
import Cookies from 'js-cookie';
import LoaderWrapper from 'app/components/CircularLoader/LoaderWrapper';
import CircularLoader from 'app/components/CircularLoader';
import { useSelector } from 'react-redux';
import { selectPageLoader } from 'app/pages/slice/selectors';
import { NonCompetingFormFields } from '../NonCompetingFormFields';

type Props = {
  labelName?: string;
  setNonCompetingpayload?: React.Dispatch<React.SetStateAction<NonCompeting>>;
  nonCompetingpayload?: NonCompeting;
  stateData?: GeneralLookup[];
  setValid: React.Dispatch<React.SetStateAction<boolean>>;
  valid: boolean;
  setIsTermsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  isTermsChecked: boolean;
  handleSubmit: () => void;
  setPhotoUploadPayload: React.Dispatch<
    React.SetStateAction<{
      formData?:
        | {
            file?: Blob | undefined;
          }
        | undefined;
    }>
  >;
  photoUploadPayload: {
    formData?:
      | {
          file?: Blob | undefined;
        }
      | undefined;
  };
  setFileAvailability: React.Dispatch<React.SetStateAction<boolean>>;
  fileAvailability: boolean;
  setUploadFile: React.Dispatch<React.SetStateAction<File | null | undefined>>;
  uploadFile: File | null | undefined;
};

export const NonCompetingRiderInformation = ({
  labelName,
  setNonCompetingpayload,
  stateData,
  nonCompetingpayload,
  setValid,
  valid,
  setIsTermsChecked,
  isTermsChecked,
  handleSubmit,
  setPhotoUploadPayload,
  photoUploadPayload,
  setFileAvailability,
  fileAvailability,
  setUploadFile,
  uploadFile,
}: Props) => {
  const navigate = useNavigate();
  const pageLoader = useSelector(selectPageLoader);

  const handleBack = () => {
    Cookies.remove('payload');
    Cookies.remove('paymentCancel');
    navigate(-1);
  };

  return (
    <>
      {pageLoader ? (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      ) : (
        <MainWrapper className="MainWrapper">
          <BorderWrapper className="BorderWrapper">
            {/* <TopWrapper className="TopWrapper">
              <h3 className="toplabel-header">
                YEDA Non-Competing Membership Form
                <br /> 2023-2024
              </h3>
            </TopWrapper> */}
            <p className="Team-Heading">Returning Member</p>
            <FirstParaWrapper className="FirstParaWrapper">
              <p>
                <i>Payment of $70 fee and must be accompanied with this form</i>
              </p>
            </FirstParaWrapper>
            <NonCompetingFormFields
              stateData={stateData}
              nonCompetingpayload={nonCompetingpayload}
              setNonCompetingpayload={setNonCompetingpayload}
              setValid={setValid}
              valid={valid}
              setIsTermsChecked={setIsTermsChecked}
              isTermsChecked={isTermsChecked}
              setPhotoUploadPayload={setPhotoUploadPayload}
              photoUploadPayload={photoUploadPayload}
              setFileAvailability={setFileAvailability}
              fileAvailability={fileAvailability}
              setUploadFile={setUploadFile}
              uploadFile={uploadFile}
            />

            <ButtonWrapper className="bg-color-change">
              <BackNextButton
                classNameBack="btn-back"
                classNameNxt="btn-nxt"
                onClickNxt={handleSubmit}
                onClickBack={handleBack}
                disableNext={valid || !isTermsChecked}
                next="Submit"
                back="Cancel"
              />
            </ButtonWrapper>
          </BorderWrapper>
        </MainWrapper>
      )}
    </>
  );
};

const LeftWrapper = styled.div`
  width: 36%;
  height: 100%;
  background: url(${horseBg});
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-position: right;

  .title-left-block {
    background: black;
    opacity: 0.7;
    padding: 0 20px;
    position: absolute;
    top: 30%;
    width: 100%;
    @media (min-width: 100px) and (max-width: 319px) {
      h4,
      p {
        font-size: 11px !important;
      }
    }
    @media (min-width: 320px) and (max-width: 575px) {
      h4,
      p {
        font-size: 12px !important;
      }
    }
    @media (min-width: 576px) and (max-width: 767px) {
      h4,
      p {
        font-size: 13px !important;
      }
    }
    @media (min-width: 768px) and (max-width: 991px) {
      h4,
      p {
        font-size: 14px !important;
      }
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      h4,
      p {
        font-size: 14px !important;
      }
    }
    @media (min-width: 1200px) and (max-width: 1365px) {
      h4,
      p {
        font-size: 15px;
      }
    }
    @media (min-width: 1366px) and (max-width: 1448px) {
      h4,
      p {
        font-size: 16px;
      }
    }
    @media (min-width: 1449px) and (max-width: 1719px) {
      h4,
      p {
        font-size: 17px;
      }
    }
    @media (min-width: 1720px) and (max-width: 4000px) {
      h4,
      p {
        font-size: 19px;
      }
    }
  }
  h4,
  p {
    color: #898989;
    font-family: 'Oswald';
    font-size: 14px;
    padding: 0px 20px;
  }
`;

const RightWrapper = styled.div`
  width: 64%;
  height: 100%;
  background: #2a2a2a;
  padding: 30px 35px 20px;

  @media (min-width: 992px) and (max-width: 1199px) {
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
  }
`;
const FirstParaWrapper = styled.p`
  padding: 0px 10px;
  width: 100%;
  p {
    color: #ff9900;
    font-family: 'Roboto';
    font-size: 12px;
    padding: 20px 0;
    border-bottom: 1px solid #585858;
  }
`;
const BorderWrapper = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px 20px 0px 20px;
  margin: 0 auto;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: -2px;
  left: 0px;
  border-radius: 0px 0px 20px 20px;
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0px 10px;
  z-index: 2;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    padding-top: 30px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
  }
  @media (min-width: 100px) and (max-width: 575px) {
    bottom: 2px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
  }
`;

const TopWrapper = styled.div`
  background: #2a2a2a;
  padding: 0px 10px;
  position: absolute;
  top: -12px;
  h3 {
    font-weight: 500;
    color: #ffffff;
    margin: 0px !important;
    font-family: 'Roboto';
    text-align: center;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    h3 {
      font-size: 16px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    h3 {
      font-size: 17px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    h3 {
      font-size: 18px;
    }
  }
`;
const MainWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  .Team-Heading {
    padding-top: 20px;
    text-align: center;
    color: #9597a6;
  }
  .TermsWrapper {
    & > p {
      margin: 9px 0 0;
    }
  }
  //Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    ${LeftWrapper} {
      display: none;
    }
    .TopWrapper {
      top: -8px;
      .toplabel-header {
        font-size: 12px;
      }
    }
    ${RightWrapper} {
      width: 100%;
      padding: 30px 15px 20px 15px;
    }
    .BorderWrapper {
      padding: 20px 20px 10px 20px;
    }
    .FirstParaWrapper {
      padding: 0px;
    }
    .TermsWrapper {
      padding-bottom: 0px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    ${LeftWrapper} {
      display: none;
    }
    ${RightWrapper} {
      width: 100%;
      padding: 30px 15px 20px 15px;
    }
    .TopWrapper {
      top: -10px;
      .toplabel-header {
        font-size: 14px;
      }
    }
    .BorderWrapper {
      padding: 20px 20px 10px 20px;
    }
    .FirstParaWrapper {
      padding: 0px;
    }
    .TermsWrapper {
      padding-bottom: 0px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    ${LeftWrapper} {
      min-width: 200px !important;
      .left-header-block {
        p {
          font-size: 14px;
        }
      }
    }
    ${RightWrapper} {
      width: calc(100% - 200px) !important;
      padding: 30px 15px 20px 15px;
    }

    .TopWrapper {
      top: -10px;
      .toplabel-header {
        font-size: 14px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
  @media (min-width: 992px) and (max-width: 1199px) {
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
  }
`;
