import React from 'react';
import styled from 'styled-components';
import { Dialog } from '@progress/kendo-react-dialogs';

type Props = {};

const TermsandConditons = (props: Props) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const toggleDialog = () => {
    setVisible(!visible);
  };
  return (
    <MainWrapper className="TermsandConditons">
      <p className="terms-Conditons-para">
        I agree to the <span onClick={toggleDialog}>terms and conditions</span>
      </p>
      {visible && (
        <StyledDialog title={'Terms and conditions'} onClose={toggleDialog}>
          <p style={{ margin: '25px', textAlign: 'center' }}>
            Coach’s Assessment of YEDA Divisions: Note: If either the coach or
            student believes that this form places the rider into a class
            unfairly, and that this rider should be placed into a division other
            than the one indicated by the form, please explain your
            circumstances in the appropriate place on the back page and suggest
            an appropriate level. This form will be given to the National
            Steward for review and final placement decision. Notice: Yeda does
            not assume any responsibility for the safety of participants and/or
            attendees at all equine events. Equine events are inherently risky.
            “inherent risk of an equine activity" means a danger or condition
            that is an integral part of an equine activity, including, but not
            limited to, any of the following:(a) the propensity of an equine to
            behave in ways that may result in injury, death, or loss to persons
            on or around the equine; (b) the unpredictability of an equine's
            reaction to sounds, sudden movement, unfamiliar objects, persons, or
            other animals; (c) hazards, including, but not limited to, surface
            or subsurface conditions; (d) a collision with another equine,
            another animal, a person, or an object; or (e) the potential of an
            equine activity participant to act in a negligent manner that may
            contribute to injury, death, or loss to the person of the
            participant or to other persons, including, but not limited to,
            failing to maintain control over an equine or failing to act within
            the ability of the participant. The parent or guardian, on behalf of
            their participating minor, assumes all risk of personal injury or
            property damage occurring as a result of the participation and does
            hereby release and discharge Yeda and show management, their
            respective officers, directors, representatives, and employees from
            any and all liability, whenever or however arising from such
            participation. Your signature below acknowledges that coach,
            guardian, and rider have fully reviewed the Yeda rule book 2023-2024
            and understand the conduct, competition requirements, and possible
            sanctions outlined therein. You are hereby guaranteeing that coach,
            guardian, and rider will adhere to Yeda social media policy and
            rules of the organization. Your signature additionally acknowledges
            that the rider is a fulltime student in good academic standing.
            Coach, guardian, and rider are subject to all rules, fines and
            disciplinary actions for violations of the Yeda rules.
          </p>
        </StyledDialog>
      )}
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  p {
    font-family: 'Roboto';
  }
  span {
    color: #306fdb;
    text-decoration: underline;
    cursor: pointer;
  }
`;
const StyledDialog = styled(Dialog)`
  &.k-dialog-wrapper .k-dialog {
    width: 60%;
    height: 80%;
    background: #27292d;
    color: #a7a7a7;
    border-radius: 15px;
    padding: 10px 0;
  }
  .k-window-title {
    font-style: italic;
    color: #ff9900;
    background: #27292d;
    font-size: 16px;
    /* padding-left: 10px; */
    border-bottom: 1px solid #5d5c5c;
  }
  p {
    text-align: justify !important;
    margin: 10px !important;
  }
  .k-window-titlebar {
    border-bottom-color: transparent;
    padding: 0 15px;
  }
  &.k-dialog-wrapper {
    backdrop-filter: blur(10px) !important;
  }
  .k-window-content {
  }
  @media (min-width: 100px) and (max-width: 319px) {
    p {
      font-size: 9px;
    }
    .k-window-content {
      padding: 0 5px;
    }
    &.k-dialog-wrapper .k-dialog {
      width: 90%;
    }
    .k-window-title {
      font-size: 12px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    p {
      font-size: 9px;
    }
    .k-window-content {
      padding: 0 5px;
    }
    &.k-dialog-wrapper .k-dialog {
      width: 85%;
    }
    .k-window-title {
      font-size: 13px;
      /* padding-left: 15px; */
    }
    .k-window-titlebar {
      padding: 0 15px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    p {
      font-size: 10px;
    }
    .k-window-content {
      padding: 0 15px;
    }
    &.k-dialog-wrapper .k-dialog {
      width: 85%;
    }
    .k-window-title {
      font-size: 13px;
      /* padding-left: 15px; */
    }
    .k-window-titlebar {
      padding: 0 25px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    p {
      font-size: 10px;
    }
    .k-window-content {
      padding: 0px 25px;
    }
    &.k-dialog-wrapper .k-dialog {
      width: 75%;
    }
    .k-window-title {
      font-size: 14px;
      /* padding-left: 15px; */
    }
    .k-window-titlebar {
      padding: 0 35px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    p {
      font-size: 11px;
    }
    .k-window-content {
      padding: 0px 25px;
    }
    &.k-dialog-wrapper .k-dialog {
      width: 60%;
    }
    .k-window-title {
      font-size: 16px;
      /* padding-left: 15px; */
    }
    .k-window-titlebar {
      padding: 0 35px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    p {
      font-size: 11px;
    }
    .k-window-content {
      padding: 0px 25px;
    }
    &.k-dialog-wrapper .k-dialog {
      width: 55%;
    }
    .k-window-title {
      font-size: 17px;
      /* padding-left: 15px; */
    }
    .k-window-titlebar {
      padding: 0 35px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    p {
      font-size: 13px;
    }
    .k-window-content {
      padding: 0px 25px;
    }
    &.k-dialog-wrapper .k-dialog {
      width: 55%;
    }
    .k-window-title {
      font-size: 20px;
      /* padding-left: 15px; */
    }
    .k-window-titlebar {
      padding: 10px 35px;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    p {
      font-size: 15px;
    }
    .k-window-content {
      padding: 0px 25px;
    }
    &.k-dialog-wrapper .k-dialog {
      width: 50%;
    }
    .k-window-title {
      font-size: 23px;
      /* padding-left: 15px; */
    }
    .k-window-titlebar {
      padding: 15px 35px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    p {
      font-size: 17px;
    }
    .k-window-content {
      padding: 0px 25px;
    }
    &.k-dialog-wrapper .k-dialog {
      width: 50%;
    }
    .k-window-title {
      font-size: 25px;
      /* padding-left: 15px; */
    }
    .k-window-titlebar {
      padding: 20px 25px;
    }
  }
`;
export default TermsandConditons;
