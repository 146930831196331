import { GridCellProps } from '@progress/kendo-react-grid';
import { splitNumberLookup } from 'admin/pages/ReportsPage/static';
import { DetailArrowIcon } from 'app/assets/icons';
import { AdminCheckBox } from 'app/components/AdminCheckbox';
import AdminDropDown from 'app/components/AdminDropDown';
import { AdminProfileInfo } from 'app/components/AdminProfileInfo';
import React from 'react';
import { GeneralLookup } from 'services/openapi';
import styled from 'styled-components';
import { capitalizeFirstLetter } from 'utils/common';

type Props = {
  className?: string;
  e: GridCellProps;
  onViewClick?: (e) => void;
  onClassASplitNumberDataChange: (e: any, event: any, className: any) => void;
  onClassBSplitNumberDataChange: (e: any, event: any, className: any) => void;
  classASplitNumber: { [key: string]: GeneralLookup };
  setClassASplitNumber: React.Dispatch<
    React.SetStateAction<{ [key: string]: GeneralLookup }>
  >;
  classBSplitNumber: { [key: string]: GeneralLookup };
  setClassBSplitNumber: React.Dispatch<
    React.SetStateAction<{ [key: string]: GeneralLookup }>
  >;
  onClassAChange: (e: any, event: any, className: any) => void;
  classAChecked: {
    [key: string]: boolean;
  };
  setClassAChecked: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  classBChecked: {
    [key: string]: boolean;
  };
  setClassBChecked: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  onClassBChange: (e: any, event: any, className: any) => void;
};

const RiderClassUpdateCell = ({
  className,
  e,
  onViewClick,
  onClassASplitNumberDataChange,
  onClassBSplitNumberDataChange,
  setClassASplitNumber,
  classASplitNumber,
  classBSplitNumber,
  setClassBSplitNumber,
  onClassAChange,
  classAChecked,
  setClassAChecked,
  setClassBChecked,
  classBChecked,
  onClassBChange,
}: Props) => {
  const data = e.dataItem;

  const onClassASplitNumberChange = (
    e,
    event: GeneralLookup,
    divisionClassOne,
  ) => {
    if (event) {
      onClassASplitNumberDataChange(e, event, divisionClassOne);
      setClassASplitNumber(prev => ({
        ...prev,
        [data.riderId]: event,
      }));
    }
  };
  const onClassBSplitNumberChange = (
    e,
    event: GeneralLookup,
    divisionClassTwo,
  ) => {
    if (event) {
      onClassBSplitNumberDataChange(e, event, divisionClassTwo);
      setClassBSplitNumber(prev => ({
        ...prev,
        [data.riderId]: event,
      }));
    }
  };

  return (
    <StyledTd
      style={e.style}
      className={e.className}
      onClick={() => onViewClick?.(data)}
    >
      <CustomTableColumnWrapper>
        <SplitWrapper>
          <AdminCheckBox
            onChange={event => {
              setClassAChecked(prev => ({
                ...prev,
                [data.riderId]: event.value,
              }));
              onClassAChange(e, event, data.divisionClassOne);
            }}
            value={classAChecked[data.riderId]}
            label={data.divisionClassOne.name}
            className="checkbox-style"
          />
          <AdminDropDown
            label=""
            onDataChange={event => {
              if (event) {
                onClassASplitNumberChange(e, event, data.divisionClassOne);
              }
            }}
            defaultValue={classASplitNumber[data.riderId]}
            placeholder="Choose Split Number"
            data={splitNumberLookup}
            className="field-style"
          />
        </SplitWrapper>
        <SplitWrapper>
          <AdminCheckBox
            onChange={event => {
              setClassBChecked(prev => ({
                ...prev,
                [data.riderId]: event.value,
              }));
              onClassBChange(e, event, data.divisionClassTwo);
            }}
            value={classBChecked[data.riderId]}
            label={data.divisionClassTwo.name}
            className="checkbox-style"
          />
          <AdminDropDown
            onDataChange={event => {
              if (event) {
                onClassBSplitNumberChange(e, event, data.divisionClassTwo);
              }
            }}
            label=""
            defaultValue={classBSplitNumber[data.riderId]}
            key={`${data.riderId}-${data.divisionClassTwo.id}`}
            placeholder="Choose Split Number"
            data={splitNumberLookup}
            className="field-style"
          />
        </SplitWrapper>
      </CustomTableColumnWrapper>
    </StyledTd>
  );
};

export default RiderClassUpdateCell;
const StyledTd = styled.td``;
const CustomTableColumnWrapper = styled.div`
  .SHdropdown {
    width: 60%;
    .k-dropdownlist {
      max-height: 31px;
      width: 200px;
      border-radius: 8px !important;
      font-size: 13px;
      .k-input-value-text {
        font-size: 13px;
        font-family: 'Inter';
        cursor: pointer;
        font-weight: 500;
        line-height: 19.6px;
      }
    }
  }
`;
const SplitWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 20px;
  .checkbox-style {
    label {
      width: 175px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;
