import { GridCellProps } from '@progress/kendo-react-grid';
import { AdminProfileInfo } from 'app/components/AdminProfileInfo';
import styled from 'styled-components';

type Props = {
  className?: string;
  e: GridCellProps;
  onClick?: (e) => void;
};

const SplitTeamCell = ({ className, e, onClick }: Props) => {
  const data = e.dataItem;
  return (
    <StyledTd style={e.style} className={e.className}>
      <AdminProfileInfo src={data?.teamImageUrl} name={data?.teamName} />
    </StyledTd>
  );
};

export default SplitTeamCell;
const StyledTd = styled.td``;
