import { CommonPageWrapper, HeadingWrapper } from 'admin/components/wrappers';
import { PageMainLabel } from 'app/components/SHLabel';
import styled from 'styled-components';
import SchoolTeamLeaderPointTable from './SchoolTeamLeaderPointTable';
import LeaderPointTeamHeaderCell from 'admin/pages/TeamIndividual/components/TeamRiderListing/Cells/LeaderPointTeamHederCell';
import TeamNameLabelCell from 'admin/pages/TeamIndividual/components/TeamRiderListing/Cells/TeamNameLabelCell';
import { LeaderPointCell } from 'admin/pages/TeamIndividual/components/TeamRiderListing/Cells/LeaderPointCell';
import { LeaderPoint } from 'services/openapi';
import { leaderPointDataConvertion, teamHeaderObject } from '../static';

type Props = { showLeaderPointList: LeaderPoint | undefined };

const LeaderPointPage = ({ showLeaderPointList }: Props) => {
  const headerClassListJuniorHighSchoolteam =
    showLeaderPointList?.juniorHighSchoolteam?.classes;
  const headerClassListSeniorHighSchoolteam =
    showLeaderPointList?.seniorHighSchoolteam?.classes;
  const updatedJuniorHeaderCells = [
    teamHeaderObject,
    ...(typeof headerClassListJuniorHighSchoolteam === 'object' &&
    headerClassListJuniorHighSchoolteam !== null
      ? headerClassListJuniorHighSchoolteam
      : []),
  ];
  const updatedSeniorHeaderCells = [
    teamHeaderObject,
    ...(typeof headerClassListSeniorHighSchoolteam === 'object' &&
    headerClassListSeniorHighSchoolteam !== null
      ? headerClassListSeniorHighSchoolteam
      : []),
  ];
  const mappedSeniorHeaderCells = updatedSeniorHeaderCells?.map(headerCell => {
    const fieldName = headerCell?.code?.toLowerCase();
    return {
      field: fieldName,
      width: fieldName === 'team' ? '200' : '100',
      headerCell: props => (
        <LeaderPointTeamHeaderCell title={headerCell?.name!} />
      ),
      cell: e => {
        const cellComponent = ['team'].includes(fieldName!) ? (
          <TeamNameLabelCell
            e={e}
            value={
              fieldName === 'team'
                ? e.dataItem.teamName
                : fieldName === 'total_point'
                ? e.dataItem.total
                : fieldName === 'final_placing'
                ? e.dataItem.finalPlacing
                : ''
            }
          />
        ) : (
          <LeaderPointCell
            riderName={e.dataItem[`${headerCell?.code!}_NAME`]}
            backNo={e.dataItem[`${headerCell?.code!}_BACKNO`]}
          />
        );

        return cellComponent;
      },
    };
  });
  const mappedJuniorHeaderCells = updatedJuniorHeaderCells?.map(headerCell => {
    const fieldName = headerCell?.code?.toLowerCase();
    return {
      field: fieldName,
      width: fieldName === 'team' ? '200' : '100',
      headerCell: props => (
        <LeaderPointTeamHeaderCell title={headerCell?.name!} />
      ),
      cell: e => {
        const cellComponent = ['team'].includes(fieldName!) ? (
          <TeamNameLabelCell
            e={e}
            value={
              fieldName === 'team'
                ? e.dataItem.teamName
                : fieldName === 'total_point'
                ? e.dataItem.total
                : fieldName === 'final_placing'
                ? e.dataItem.finalPlacing
                : ''
            }
          />
        ) : (
          <LeaderPointCell
            riderName={e.dataItem[`${headerCell?.code!}_NAME`]}
            backNo={e.dataItem[`${headerCell?.code!}_BACKNO`]}
          />
        );

        return cellComponent;
      },
    };
  });
  return (
    <StyledCommonPageWrapper>
      <TitleCardWrapper>
        <HeadingWrapper className="heading-wrapper">
          <PageMainLabel className="label-wrapper">
            Senior High School Team
          </PageMainLabel>
        </HeadingWrapper>
      </TitleCardWrapper>
      <SchoolTeamLeaderPointTable
        data={leaderPointDataConvertion(
          showLeaderPointList?.seniorHighSchoolteam?.results,
        )}
        columns={mappedSeniorHeaderCells}
      />
      <TitleCardWrapper className="junior-high-school-team-wrapper">
        <HeadingWrapper className="heading-wrapper">
          <PageMainLabel className="label-wrapper">
            Junior High School Team
          </PageMainLabel>
        </HeadingWrapper>
      </TitleCardWrapper>
      <SchoolTeamLeaderPointTable
        data={leaderPointDataConvertion(
          showLeaderPointList?.juniorHighSchoolteam?.results,
        )}
        columns={mappedJuniorHeaderCells}
      />
    </StyledCommonPageWrapper>
  );
};

export default LeaderPointPage;
const StyledCommonPageWrapper = styled(CommonPageWrapper)`
  margin-bottom: 52px;
`;
const TitleCardWrapper = styled.div`
  &.junior-high-school-team-wrapper {
    margin-top: 54px;
  }
  margin-top: 5px;
  background-color: #e7ecff;
  .heading-wrapper {
    padding: 14px 0px 14px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    .label-wrapper {
      font-family: 'Inter';
      font-size: 14px;
      font-weight: 600;
      line-height: 14.52px;
      color: #000000;
      margin: unset !important;
    }
  }
`;
