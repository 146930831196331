import { GridCellProps } from '@progress/kendo-react-grid';
import { SHToolTip } from 'app/components/SHToolTip';
import styled from 'styled-components';

type Props = {
  className?: string;
  e: GridCellProps;
};

const ShowLocationCell = ({ className, e }: Props) => {
  const data = e?.dataItem;
  return (
    <td className={className ?? ''}>
      <ContactWrapper>
        <SHToolTip position="top">
          <label title={data?.showLocation}> {data?.showLocation}</label>{' '}
        </SHToolTip>
      </ContactWrapper>
    </td>
  );
};

export default ShowLocationCell;
const ContactWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  min-width: 120px;
  max-width: 200px;
`;
