import SHCard from './components/SHCard';
import React, { useState } from 'react';
import styled from 'styled-components';
import iconOne from './assets/img/icon/Asset-1.png';
import iconTwo from './assets/img/icon/Asset-2.png';
import iconThree from './assets/img/icon/Asset-3.png';
import {
  Card,
  CardTitle,
  CardBody,
  CardActions,
} from '@progress/kendo-react-layout';
import imageOne from './assets/img/bg/img1.png';
import imageTwo from './assets/img/bg/img2.png';
import imageThree from './assets/img/bg/img3.png';
import imageFour from './assets/img/bg/img4.png';
import imageFive from './assets/img/bg/img5.png';
import scholarship from './assets/img/bg/scholarship.png';
import SHButton from 'app/components/SHButton';
import Cookies from 'js-cookie';
import { ApiError, LandingPage, RiderService } from 'services/openapi';
import useApi from 'services/mavapi/useApi';
import { useNavigate } from 'react-router-dom';
import isApiError from 'utils/isApiError';
import useToast from 'hooks/Toast';
import { useErrorHandlingSlice } from 'app/error/slice';
import { useDispatch } from 'react-redux';

type Props = {};

const Dashboard = (props: Props) => {
  const currentUserId = Cookies.get('userId');
  const { riderGetLandingPage } = RiderService;
  const { error: apiError, handleRequest } = useApi();
  const [, setError] = React.useState<ApiError | unknown>();
  const [riderDetails, setRiderDetails] = React.useState<LandingPage>();
  const navigate = useNavigate();
  const [enrolledDate, setEnrolledDate] = useState<boolean>();
  const dispatch = useDispatch();
  const { actions } = useErrorHandlingSlice();
  function getRiderDetails(id?: string) {
    handleRequest(riderGetLandingPage(id))
      .then(res => {
        if (res && !isApiError(res)) {
          Cookies.set('sifID', res?.id);
          const currentYear = new Date().getFullYear();
          const joinedDate = new Date(res?.sifJoinedDate!);
          const joinedYear = joinedDate.getFullYear();
          if (res.hasSIFForSeason) {
            setEnrolledDate(true);
            Cookies.set('hasSIFForSeason', res.hasSIFForSeason);
          }
        } else {
          dispatch(
            actions.dispatchToast({
              errorTxt: res.description,
              show: true,
              errorType: 'error',
            }),
          );
        }
        setRiderDetails(res);
      })
      .catch(() => setError(apiError));
  }
  const handleJoinSifClick = () => {
    navigate('/rider-landing-page/sif');
  };
  React.useEffect(() => {
    getRiderDetails(currentUserId);
  }, []);

  return (
    <MainWrapper>
      <h1 className="dashboard-title">My Dashboard</h1>
      <CardWrapper>
        <SubWrapper>
          <FirstCardWrapper>
            <SHCard
              paraTitle="Team"
              title={riderDetails?.team?.name!}
              titleFullform={riderDetails?.teamCode!}
              img={iconOne}
              phNo=""
              mailId=""
              bgColor="one"
              className="card"
            />
            <SHCard
              paraTitle="Coach"
              title={riderDetails?.coachName!}
              titleType="hidden"
              img={iconTwo}
              phNo={riderDetails?.coachPrimaryPhone}
              mailId={riderDetails?.coachEmail}
              bgColor="two"
              className="card"
            />
            <SHCard
              paraTitle="Back Number"
              title={riderDetails?.backNumber}
              img={iconThree}
              phNo=""
              mailId=""
              bgColor="three"
              className="card"
            />
          </FirstCardWrapper>
          <SecondCardWrapper>
            <Card className="card-wrapper">
              <StyledCardBody>
                <Sub>
                  <CardTitle className="">
                    Scholarship <br />
                    Incentive <br />
                    Fund
                  </CardTitle>
                  {enrolledDate ? (
                    ''
                  ) : (
                    <SHButton onClick={handleJoinSifClick}>
                      Join SIF today
                    </SHButton>
                  )}
                </Sub>
                <Sub>
                  <h4>
                    Be a part of YEDA Scholarship Incentive Fund Program (SIF).
                  </h4>
                  <p>
                    Unlike other scholarship programs, YEDA recognizes an
                    educational needs and desires far broader than those offered
                    by traditional Secondary Education Programs.
                  </p>
                </Sub>
              </StyledCardBody>
            </Card>
          </SecondCardWrapper>
        </SubWrapper>
        <ThirdCardWrapper className="image-card">
          <img src={imageOne} alt="Part 1" className="card-image one" />
          <img src={imageTwo} alt="Part 2" className="card-image two" />
          <img src={imageThree} alt="Part 3" className="card-image three" />
          <img src={imageFour} alt="Part 4" className="card-image four" />
          <img src={imageFive} alt="Part 5" className="card-image five" />
        </ThirdCardWrapper>
      </CardWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  padding: 20px 40px;
  height: calc(100vh - 72px);
  background-color: #ffffff;
  width: 100%;
  .dashboard-title {
    padding: 10px 0px;
  }
`;
const CardWrapper = styled.div`
  gap: 4%;
  display: flex;
  width: 100%;
  height: calc(100vh - 221px);
`;
const SubWrapper = styled.div`
  gap: 5%;
  display: flex;
  flex-direction: column;
  width: 55%;
  height: 80%;
  justify-content: flex-start;
  @media (min-width: 100px) and (max-width: 768px) {
    width: 100%;
  }
`;
const FirstCardWrapper = styled.div`
  display: flex;
  gap: 5%;

  width: 100%;
  height: 45%;
  .card {
  }
`;
const SecondCardWrapper = styled.div`
  display: flex;
  gap: 5%;
  width: 100%;
  height: 55%;
  .k-card {
    border-color: transparent;
  }
  @media (min-width: 100px) and (max-width: 768px) {
    height: 100%;
  }
`;
const StyledCardBody = styled(CardBody)`
  gap: 3%;
  text-align: center;
  border-radius: 10px;
  width: 100%;
  padding: 15px 25px !important;
  background: url(${scholarship});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom;
  display: flex;
  background-color: #f9faf5;
  h4 {
    margin: 0px;
    font-size: 10px;
    font-weight: 600;
  }
  p {
    margin: 0px;
    font-size: 9px;
    width: 74%;
  }
  .k-card-title {
    margin: 0px !important;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    h4 {
      font-size: 10px;
    }
    p {
      font-size: 9px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    h4 {
      font-size: 10px;
    }
    p {
      font-size: 10px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    h4 {
      font-size: 14px;
    }
    p {
      font-size: 12px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    h4 {
      font-size: 15px;
    }
    p {
      font-size: 13px;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    h4 {
      font-size: 16px;
    }
    p {
      font-size: 14px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    h4 {
      font-size: 17px;
    }
    p {
      font-size: 15px;
    }
  }
`;
const Sub = styled.div`
  text-align: left;
  .button-wrapper .button {
    border-radius: 10px !important;
    width: 100px;
    font-size: 10px;
    font-weight: 700;
    color: #ffffff;
    background: #527d3c;
  }

  .k-card-title {
    font-size: 17px;
    line-height: 1.2;
    font-weight: 600;
    text-align: left;
    padding-bottom: 20px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .k-card-title {
      font-size: 13px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .k-card-title {
      font-size: 15px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .k-card-title {
      font-size: 17px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .k-card-title {
      font-size: 21px;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    .k-card-title {
      font-size: 23px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    .k-card-title {
      font-size: 25px;
    }
  }
`;
const ThirdCardWrapper = styled.div`
  @media (min-width: 100px) and (max-width: 768px) {
    display: none;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
  gap: 2%;
  width: 45%;
  height: 80%;

  .card-image {
    width: 15%;
    height: 100%;
    border-radius: 20px;
    object-fit: contain;

    transition: transform 0.3s ease;

    :nth-child(2):hover,
    :nth-child(4):hover {
      transform: translateY(-5%);
    }
    :nth-child(1):hover,
    :nth-child(3):hover,
    :nth-child(5):hover {
      transform: translateY(5%);
    }
  }
  .two {
    margin-bottom: 15%;
  }
  .three {
    margin-bottom: 5%;
  }
  .four {
    margin-bottom: -5%;
  }
  .five {
    margin-bottom: 10%;
  }
`;

export default Dashboard;
