import styled from 'styled-components';
import { TextField } from 'app/components/TextField';
import '@fontsource/roboto';
import React from 'react';
import { useState } from 'react';
import { CheckBox } from 'app/components/CheckBox';
import { BackNextButton } from 'app/components/BackNextButton';
import SHdropdown from 'app/components/SHdropdown';
import {
  ApiError,
  EWD,
  GeneralLookup,
  GeneralLookupWrapper,
  LookupService,
} from 'services/openapi';
import useApi from 'services/mavapi/useApi';
import SimpleReactValidator from 'simple-react-validator';

type Props = {
  onClickBackParent?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onClickNxtParent?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  setTryItEwdData: React.Dispatch<React.SetStateAction<EWD>>;
  tryItEwdData: EWD;
  stateData?: GeneralLookup[];
};

const TryitEWDParentDetailsNew = ({
  onClickBackParent,
  onClickNxtParent,
  setTryItEwdData,
  tryItEwdData,
  stateData,
}: Props) => {
  const { error: apiError, handleRequest } = useApi();
  const [, setError] = React.useState<ApiError | unknown>();
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [valid, setValid] = useState<boolean>(false);

  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [tryItEwdData]);

  const handleCheckbox = e => {
    if (e.value) {
      setTryItEwdData({
        ...tryItEwdData,
        parent: {
          ...tryItEwdData.parent,
          state: tryItEwdData.state,
          street: tryItEwdData.street,
          city: tryItEwdData.city,
          zip: tryItEwdData.zip,
        },
      });
    } else {
      setTryItEwdData({
        ...tryItEwdData,
        parent: {
          ...tryItEwdData.parent,
          state: {
            id: '',
            name: '',
            children: null,
          },
          street: '',
          city: '',
          zip: '',
        },
      });
    }
  };
  const handlePhChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
      setTryItEwdData({
        ...tryItEwdData,
        parent: {
          ...tryItEwdData.parent,
          phone: e.target.value,
        },
      });
    }
  };
  const handleZipChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 9 && /^[a-zA-Z0-9]*$/.test(inputValue)) {
      setTryItEwdData({
        ...tryItEwdData,
        parent: {
          ...tryItEwdData.parent,
          zip: e.target.value,
        },
      });
    }
  };
  return (
    <MainWrapper>
      <OverflowWrapper>
        <TextFieldGrid>
          <TextFieldWrapper>
            <StyledTextField
              label="Parent/Guardian First Name"
              type="text"
              name="firstName"
              onChange={e =>
                setTryItEwdData({
                  ...tryItEwdData,
                  parent: {
                    ...tryItEwdData.parent,
                    firstName: e.target.value,
                  },
                })
              }
              value={tryItEwdData.parent.firstName!}
              validations={'required|max:30'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              label="Parent/Guardian Last Name"
              type="text"
              name="lastName"
              onChange={e =>
                setTryItEwdData({
                  ...tryItEwdData,
                  parent: {
                    ...tryItEwdData.parent,
                    lastName: e.target.value,
                  },
                })
              }
              value={tryItEwdData.parent.lastName!}
              validations={'required|max:30'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              label="Parent/Guardian Email"
              type="email"
              name="email"
              onChange={e =>
                setTryItEwdData({
                  ...tryItEwdData,
                  parent: {
                    ...tryItEwdData.parent,
                    email: e.target.value,
                  },
                })
              }
              value={tryItEwdData.parent.email!}
              validations={'required|email'}
              validator={simpleValidator.current}
              maxLength={60}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              label="Parent/Guardian Ph#"
              type="tel"
              name="ph"
              onChange={handlePhChange}
              value={tryItEwdData.parent.phone!}
              validations={'required|numeric|min:10|max:10'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
        <HomeAddressWrapper className="horizontal-line">
          <p className="home-address">Parent/Guardian Address</p>
          <CheckBox
            label="(Same as rider address)"
            onChange={handleCheckbox}
            checked={tryItEwdData.parent.state === tryItEwdData.state}
          />
        </HomeAddressWrapper>
        <TextFieldGrid>
          <TextFieldWrapper>
            <StyledTextField
              label="Street Address"
              type="text"
              name="streetAddress"
              onChange={e =>
                setTryItEwdData({
                  ...tryItEwdData,
                  parent: {
                    ...tryItEwdData.parent,
                    street: e.target.value,
                  },
                })
              }
              value={tryItEwdData.parent.street!}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              label="City"
              type="text"
              name="city"
              onChange={e =>
                setTryItEwdData({
                  ...tryItEwdData,
                  parent: {
                    ...tryItEwdData.parent,
                    city: e.target.value,
                  },
                })
              }
              value={tryItEwdData.parent.city!}
              validations={'required|max:40'}
              validator={simpleValidator.current}
              maxLength={40}
            />
          </TextFieldWrapper>
          <DropdownWrapper>
            <SHdropdown
              className="drop-down"
              label="State"
              data={stateData}
              onDataChange={e =>
                setTryItEwdData({
                  ...tryItEwdData,
                  parent: {
                    ...tryItEwdData.parent,
                    state: e,
                  },
                })
              }
              defaultValue={tryItEwdData.parent.state}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </DropdownWrapper>
          <TextFieldWrapper>
            <StyledTextField
              label="Zip"
              type="text"
              name="zip"
              onChange={handleZipChange}
              value={tryItEwdData.parent.zip!}
              validations={'required|alpha_num|min:5|max:9'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
        <ButtonWrapper className="bg-color-change">
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={onClickNxtParent}
            onClickBack={onClickBackParent}
            disableNext={valid}
            next="Next"
            back="Back"
          />
        </ButtonWrapper>
      </OverflowWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  color: #9597a6;
  width: 100%;
  height: calc(100vh - 230px);
  position: relative;
  padding: 20px 0px 0px;
  // position: relative;
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    // padding-top: 20px;
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    // padding-top: 20px;
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    // padding-top: 20px;
    label {
      font-size: 14px;
    }
  }
`;
const OverflowWrapper = styled.div`
  height: 100%;
  overflow: auto;
  padding-bottom: 100px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
`;

const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  @media (min-width: 100px) and (max-width: 319px) {
    display: block;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    display: block;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    display: block;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    display: block;
  }
`;
const HomeAddressWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  label {
    color: #e98d04;
    font-style: oblique;
  }

  ::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #585858;
    margin: 0 10px 0px 10px;
  }

  p {
    margin: 0 12px;
    white-space: nowrap;
    color: #4f4f4f;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    align-items: unset;
    flex-direction: column;
    margin: 25px 12px 15px;
    p {
      margin: 0px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    align-items: unset;
    flex-direction: column;
    margin: 25px 12px 15px;
    p {
      margin: 0px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    align-items: unset;
    flex-direction: column;
    margin: 35px 12px 15px;
    p {
      margin: 0px;
    }
  }
`;
const TextFieldWrapper = styled.div`
  flex: 0 0 calc(50% - 20px) !important;
  margin: 0px 0px 10px !important;
`;
const StyledTextField = styled(TextField)``;

const DropdownWrapper = styled.div`
  flex: 0 0 calc(50% - 20px);
  p {
    margin: 0px;
    font-family: 'Roboto';
    font-size: 14px;
  }
  .SHdropdown {
    margin: 0px 12px;
    height: 35px;
    color: #9597a6;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    padding-bottom: 25px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding-bottom: 25px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-bottom: 25px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-bottom: 25px;
  }
`;
const ButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: -1px;
    left: -3px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    /* border-radius: 0 0 20px 20px; */
    backdrop-filter: blur(5px);
    z-index: 2;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    &.bg-color-change {
      bottom: -5px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    &.bg-color-change {
      bottom: -10px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
    @media (min-width: 1200px) and (max-width: 1365px) {
      height: 55px;
    }
    @media (min-width: 1366px) and (max-width: 1448px) {
      height: 70px;
    }
  }
`;

export default TryitEWDParentDetailsNew;
