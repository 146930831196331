import React from 'react';
import styled from 'styled-components';
import '@fontsource/roboto';
import { BackNextButton } from 'app/components/BackNextButton';
import TermsandConditons from 'app/components/TermsandConditions';
import { CheckBox } from 'app/components/CheckBox';
import { RegisterRider } from 'services/openapi';
import RadioButtonGroup from 'app/components/RadioButtonGroup';
import Cookies from 'js-cookie';
import { radioModel } from 'app/pages/RiderRegistrationPage/components/NotReturningRider';
import CustomDragAndDropFiles from 'app/components/CustomDragAndDropFiles';
import { useDispatch } from 'react-redux';
import { useRiderRegistrationStateSlice } from 'app/pages/RiderRegistrationPage/slice';

type Props = {
  onClickMembershipNext?:
    | React.MouseEventHandler<HTMLButtonElement>
    | undefined;
  onClickMembershipBack?:
    | React.MouseEventHandler<HTMLButtonElement>
    | undefined;
  riderData: RegisterRider;
  setRiderData: React.Dispatch<React.SetStateAction<RegisterRider>>;
  setIsTermsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  isTermsChecked: boolean;
  setPlacementRadioValues: React.Dispatch<React.SetStateAction<radioModel>>;
  placementRadioValues: radioModel;
  setPhotoUploadPayload: React.Dispatch<
    React.SetStateAction<{
      formData?:
        | {
            file?: Blob | undefined;
          }
        | undefined;
    }>
  >;
  photoUploadPayload: {
    formData?:
      | {
          file?: Blob | undefined;
        }
      | undefined;
  };
  setFileAvailability: React.Dispatch<React.SetStateAction<boolean>>;
  fileAvailability: boolean;
  setUploadFile: React.Dispatch<React.SetStateAction<File | null | undefined>>;
  uploadFile: File | null | undefined;
};
const NewMemberMembershipFormNewRider = ({
  onClickMembershipNext,
  onClickMembershipBack,
  riderData,
  setRiderData,
  setIsTermsChecked,
  isTermsChecked,
  setPlacementRadioValues,
  placementRadioValues,
  setPhotoUploadPayload,
  photoUploadPayload,
  setFileAvailability,
  fileAvailability,
  setUploadFile,
  uploadFile,
}: Props) => {
  const dispatch = useDispatch();
  const { actions: manageAppActions } = useRiderRegistrationStateSlice();
  const checkTermsChange = () => {
    setIsTermsChecked(!isTermsChecked);
  };
  const handledIsPhotoPromotion = event => {
    setPlacementRadioValues(prevData => ({
      ...prevData,
      usePhotoForPromotion: event.value,
    }));
    if (riderData) {
      setRiderData?.({
        ...riderData,
        usePhotoForPromotion: event.value,
      });
    }
  };
  const handleFileUpload = e => {
    var file = e.target.files[0];
    dispatch(manageAppActions.setPhotoUpload(file));
    console.log(file, 'file');

    setUploadFile(file);
    setPhotoUploadPayload({
      ...photoUploadPayload,
      formData: { file: file },
    });
  };
  const handleRemoveFile = e => {
    setUploadFile(undefined);
  };
  const handleFileOnCancelClick = e => {
    setUploadFile(undefined);
  };
  React.useEffect(() => {
    const paymentCancelStatus = Cookies.get('paymentCancel');
    if (paymentCancelStatus) setIsTermsChecked(true);
  }, []);
  React.useEffect(() => {
    if (uploadFile === undefined) {
      setFileAvailability(true);
    } else {
      setFileAvailability(false);
    }
  }, [uploadFile]);
  return (
    <MainWrapper>
      <OverflowWrapper>
        <InfoWrapper>
          <p className="para-three">
            <i>NOTE:</i>
          </p>
          <p>
            If you have competed in “recognized shows” you may be asked to
            submit a printed copy of your competition/points records along with
            this form. For YEDA purposes, “recognized shows” includes AQHA,
            NRHA, NSBA, APHA, PtHA, ApHC, IBHA, ABRA & POA, USEF, and any other
            association that keeps national records.
          </p>
        </InfoWrapper>
        <ParaWrapper>
          <p className="para-two">Photo Relaease:</p>
        </ParaWrapper>
        <RadioButtonWrapper>
          <p>
            May your likeness in photos taken in photos by our official
            photographers be used in promotional literature for YEDA?
          </p>
          <RadioButtonGroup
            onChange={handledIsPhotoPromotion}
            value={placementRadioValues.usePhotoForPromotion}
          />
        </RadioButtonWrapper>
        <p>
          If no, please enclose a current photo of the rider so that we can
          ensure their likeness is not used.
        </p>
        <InfoWrapper>
          <p className="para-three">
            <i>NOTE:</i>
          </p>
          <p>
            **The Youth Equestrian Development Association (YEDA) highly
            recommends that all riders be actively enrolled in a weekly lesson
            program.**
          </p>
        </InfoWrapper>
        {placementRadioValues.usePhotoForPromotion === false && (
          <PhotoUploadWrapper>
            <h3 className="photo-title"> Upload your current photo below. </h3>
            <CustomDragAndDropFiles
              className="dragNdrop"
              onCancel={e => handleFileOnCancelClick(e)}
              onChange={e => handleFileUpload(e)}
              uploadBtnDisabled={!fileAvailability}
              onRemoveFile={handleRemoveFile}
              chooseFileBtnDisabled={!fileAvailability}
              uploadFile={uploadFile}
            />
          </PhotoUploadWrapper>
        )}
        <CheckboxWrapper>
          <TermsandConditons />
          <CheckBox
            onChange={() => checkTermsChange()}
            value={isTermsChecked}
          />
        </CheckboxWrapper>
      </OverflowWrapper>
      <ButtonWrapper className="bg-color-change">
        <BackNextButton
          classNameBack="btn-back"
          classNameNxt="btn-nxt"
          onClickNxt={onClickMembershipNext}
          onClickBack={onClickMembershipBack}
          disableNext={
            !isTermsChecked ||
            placementRadioValues.usePhotoForPromotion === null
          }
          next="Save"
          back="Back"
        />
      </ButtonWrapper>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 231px);
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 20px;

  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  .bottom-para {
    font-size: 12px;
    z-index: 2;
    position: absolute;
    bottom: 0px;
    padding-left: 8%;
  }
  @media (min-width: 100px) and (max-width: 575px) {
    height: calc(100vh - 251px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    label {
      font-size: 14px;
    }
  }
`;
const OverflowWrapper = styled.div`
  overflow: auto;
  height: 100%;
  padding-bottom: 100px;
  padding: 20px 60px 100px 60px;

  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
`;
const PhotoUploadWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  .photo-title {
    color: #a7a7a7;
    font-family: 'Roboto';
    font-size: 15px;
    text-align: center;
  }
`;
const ParaWrapper = styled.div`
  width: 100%;
  .para-one {
    color: #ffffff !important;
    font-family: 'Roboto';
    font-size: 12px;
    border: none;
    margin: 0px !important;
  }
  .para-two {
    color: #ff9900;
    font-family: 'Roboto';
    font-size: 12px;
    border-bottom: 1px solid #585858;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  .para-three {
    color: #9597a6;
    font-family: 'Roboto';
    font-size: 12px;
  }
`;
const CheckboxWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 30px 0px;
  span {
    color: #306fdb;
    text-decoration: underline;
  }
  p {
    margin: 0px;
  }
`;
const RadioButtonWrapper = styled.div`
  p {
    color: #a7a7a7;
    font-size: 12px;
  }
  .radiobutton {
    gap: 30px;
    display: flex;
    color: white;
  }
  .k-radio {
    background-color: transparent;
    border: 1px solid #ced4da;
  }
  .k-radio:checked,
  .k-radio.k-checked {
    border-color: 1px solid #0d6efd;
    color: white;
    background-color: #0d6efd;
  }
  .k-radio-list {
    flex-flow: row-reverse;
    justify-content: flex-end;
    gap: 50px;
    padding-left: 20px;
  }
  .k-radio-item,
  .k-radio-list-item {
    color: #9597a6;
    gap: 10px;
  }
`;
const InfoWrapper = styled.div`
  width: 100%;
  border: 1px solid #585858;
  border-radius: 7px;
  margin: 10px 0px 20px;
  padding: 20px;
  background: #27292d;
  p {
    font-family: 'Roboto';
    font-size: 12px;
  }
  .para-three {
    color: #ff9900;
    font-size: 12px;
    border-bottom: 1px solid #585858;
  }
  .para-two {
    padding-bottom: 10px;
  }
`;
const ButtonWrapper = styled.div`
  height: 70px;
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;
  button {
    width: 100px;
    height: 30px;
    margin: 0px 10px;
    border-radius: 6px;
  }
  .btn-back {
    background-color: #2a2a2a;
    border: 1px solid #606060;
    color: #909090;
  }
  .btn-nxt {
    background-color: #306fdb;
    border: 1px solid #2a2a2a;
    color: #ffffff;
  }
  .btn-rgstr {
    background-color: #306fdb;
    border: 1px solid #2a2a2a;
    color: #ffffff;
  }

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: -45px;
    left: -3px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
    @media (min-width: 1200px) and (max-width: 1365px) {
      height: 55px;
    }
    @media (min-width: 1366px) and (max-width: 1448px) {
      height: 70px;
    }
  }
`;

export default NewMemberMembershipFormNewRider;
