import { GridCellProps } from '@progress/kendo-react-grid';
import styled from 'styled-components';
type Props = {
  title?: string | JSX.Element;
  onClick?: (e) => void;
  index?: number;
  length?: number;
  e?: GridCellProps | undefined;
  className?: string;
};

export const ReportHeaderCell = ({
  title,
  onClick,
  index,
  length,
  e,
  className,
}: Props) => {
  const handleVerticalAlign = () => {
    return index && length && index > length - 1 ? 'top-bottom' : 'base-line';
  };

  return (
    <StyledDiv
      style={e?.style}
      className={`${e?.className} ${handleVerticalAlign()} ${className} `}
      onClick={onClick}
    >
      {title}
    </StyledDiv>
  );
};
const StyledDiv = styled.div`
  padding: 0.3rem !important;
  display: flex;
  height: 100%;
  font-family: 'Inter';
  font-size: 15px;
  font-weight: 600 !important;
  line-height: 14.52px;
  color: #475467;
  margin: unset !important;
  min-height: 60px;
  place-content: center;
  &.base-line {
    align-items: center;
    white-space: pre-line;
    justify-content: flex-start;
    line-height: 20px;
  }
  &.top-bottom {
    align-items: center;
    white-space: pre-line;
  }
`;
