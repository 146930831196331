import React from 'react';
import { GeneralLookup, GeneralLookupWrapper } from 'services/openapi';
import styled from 'styled-components';
import Multiselect from 'multiselect-react-dropdown';
import AdminCustomButton from 'app/components/AdminCustomButton';
import { FilterIcon } from 'app/assets/icons';
import CustomFilteredDropDown from './CustomFilteredDropDown';
import search from '../../assets/icon/search.png';
export type DefaultData = {
  id: string;
  name: string;
  type: string;
};
type Props = {
  data?: GeneralLookup[] | DefaultData[];
  className?: string;
  title?: string;
  searchText?: string;
  setLookupData: React.Dispatch<React.SetStateAction<string>>;
  filteredData?: GeneralLookup[];
  setTeamIds?: React.Dispatch<React.SetStateAction<string>> | undefined;
  setDivisionIds?: React.Dispatch<React.SetStateAction<string>> | undefined;
  lookupData: string;
  lookUps: GeneralLookupWrapper[];
  setPreDataList: React.Dispatch<
    React.SetStateAction<
      | {
          name: string;
          id: string;
          type: string;
        }[]
      | undefined
    >
  >;
  preDataList:
    | {
        name: string;
        id: string;
        type: string;
      }[]
    | undefined;
};

const AdminMultiSelectDropDown = ({
  data,
  title,
  searchText,
  setLookupData,
  filteredData,
  setTeamIds,
  setDivisionIds,
  lookupData,
  lookUps,
  setPreDataList,
  preDataList,
}: Props) => {
  const multiselectRef = React.useRef(null);
  const [show, setShow] = React.useState<boolean>(false);
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);

  const toggleDropdown = () => {
    setShow(!show);
    if (preDataList?.length === 0) {
      setDivisionIds?.('');
      setTeamIds?.('');
      setPreDataList([]);
    }
  };
  const handleOutsideClick = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShow(false);
    }
  };
  let covertedPreDataList = preDataList?.map(item => {
    return {
      text: item?.name,
      id: item?.id,
      type: item.type,
    };
  });

  let covertedData = data?.map(item => {
    return {
      text: item.name,
      id: item.id,
      type: item.type,
    };
  });
  const onSelect = (selectedList: any) => {
    let covertedSelectedList = selectedList?.map(item => {
      return {
        name: item.text,
        id: item.id,
        type: item.type,
      };
    });
    setPreDataList?.(covertedSelectedList);
  };
  const onRemove = (selectedList: any, selectedItem: any) => {
    const updatedPreDataList = preDataList?.filter(
      item => item.id !== selectedItem.id,
    );

    setPreDataList(updatedPreDataList);
  };
  React.useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);
  return (
    <>
      <MainWrapper>
        <FilterWrapper>
          <CustomFilteredDropDown
            preDataList={preDataList}
            setPreDataList={setPreDataList}
            setLookupData={setLookupData}
            data={filteredData}
            setTeamIds={setTeamIds}
            setDivisionIds={setDivisionIds}
            lookupData={lookupData}
            lookUps={lookUps}
          />
        </FilterWrapper>
        <Wrapper>
          <LabelWrapper>{title}</LabelWrapper>
          <MultiWrapper ref={wrapperRef}>
            <AdminCustomButton onClick={toggleDropdown} buttonType="icon">
              {<FilterIcon />}
              More Filters
            </AdminCustomButton>
            {show && (
              <MultiSelectWrapper>
                <Multiselect
                  ref={multiselectRef}
                  options={covertedData}
                  selectedValues={covertedPreDataList}
                  onSelect={onSelect}
                  onRemove={onRemove}
                  displayValue="text"
                  placeholder={searchText ? searchText : 'Search'}
                  showCheckbox
                  keepSearchTerm
                  disablePreSelectedValues={true}
                />
              </MultiSelectWrapper>
            )}
          </MultiWrapper>
        </Wrapper>
      </MainWrapper>
    </>
  );
};

export default AdminMultiSelectDropDown;
const MainWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
`;
const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const MultiSelectWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 100px;
  z-index: 9;
  .optionListContainer {
    display: block !important;
    &.displayNone {
      display: block !important;
    }
    .optionContainer {
      li {
        &.option {
          &.disableSelection {
            opacity: 1 !important;
            pointer-events: unset !important;
          }
        }
      }
    }
  }
`;
const MultiWrapper = styled.div`
  position: relative;
  button {
    width: 100%;
    background: #ffff;
    background-color: #ffff;
    border-radius: 8px;
    color: rgb(28 28 28);
    padding: 0 10px !important;
    font-size: 12px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none !important;
    box-shadow: none !important;
    ::before,
    ::after {
      opacity: 0 !important;
    }
  }
  .k-button-text {
    gap: 10px;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  border: 1px solid #dfdfdf !important;
  border-radius: 6px;
  background: #ffffff !important;
  &.focused {
    .optionListContainer {
      &.displayBlock {
        display: none !important;
      }
    }
  }
  .multiSelectContainer {
    position: relative;
    overflow: auto !important;
    margin-top: 4px !important;
    background: #ffffff !important;
    color: rgb(28 28 28);
    border-radius: 5px;
    box-shadow: 0 4px 8px -2px #091e4240, 0 0 1px #091e424f;
    min-width: 190px;
    .search-wrapper {
      position: relative;
      overflow: auto !important;
      background: #ffffff !important;
      padding: 10px 15px 0px 15px !important;
      border-bottom: none;
      border: 0;
      color: rgb(28 28 28);
      border-radius: 0;
      &::before {
        content: '';
        position: absolute;
        background-image: url(${search});
        top: 30px;
        left: 25px;
        z-index: 9999;
        font-family: 'WebComponentsIcons';
        width: 15px;
        height: 15px;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        background-size: 12px;
      }
    }
    .optionListContainer {
      position: relative;
      overflow: auto !important;
      margin: 0 !important;
      background: #ffffff !important;
      max-height: 190px;
      overflow-y: auto;
      ::-webkit-scrollbar {
        width: 2px;
        margin-left: 3px;
      }
      ::-webkit-scrollbar-track {
        background: transparent !important;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        width: 9px;
        background: #727272 !important;
        border-radius: 10px;
      }
    }
    ul {
      border-radius: 5px;
      border: none !important;
      display: block;
      margin: 0;
      padding: 5px 0px 8px 0px !important;
      max-height: unset;
      .notFound {
        font-size: 13px;
        font-family: 'Inter';
      }
      li {
        padding: 10px 15px;
        font-size: 13px;
        display: flex;
        align-items: flex-start;
        font-family: 'Inter';
        border-left: 1px solid transparent;
        :hover {
          border-left: 1px solid #001fff;
          background: #f1f2f4 !important;
        }
        .checkbox {
          width: 15px;
          margin: 1px 15px 0 0;
        }
        :hover {
          color: rgb(28 28 28);
          background: transparent;
        }
      }
    }
  }
  .highlightOption {
    background: transparent;
    color: rgb(28 28 28);
  }
  .searchWrapper {
    border: 1px solid #e8e8ec;
    border-radius: 4px;
    min-height: 36px;
    position: relative;
    padding: 0;
    background: #e1e9ff;
    .chip {
      display: none;
    }
    .searchBox {
      margin: 0px !important;
      font-family: 'Inter';
      padding: 0 10px 0 30px;
      font-size: 13px;
      height: 34px;
      width: 100%;
      border: none;
      text-align: left;
      color: #111111 !important;
      border: 1px solid #eeeeee !important;
      background: #dad4d445 !important;
      border-radius: 5px;
      &::placeholder {
        font-weight: 500;
      }
    }
  }
`;

const LabelWrapper = styled.label`
  font-family: 'Inter';
  color: #717171;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 12px;
  text-transform: capitalize;
  display: block;
  line-height: unset;
  position: relative;
  margin: 0;
  z-index: 1;
`;
