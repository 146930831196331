import React, { useState } from 'react';
import { CheckBox } from 'app/components/CheckBox';
import styled from 'styled-components';
import { TextField } from 'app/components/TextField';
import '@fontsource/roboto';
import SHdropdown from 'app/components/SHdropdown';
import { GeneralLookup, TeamRegistration } from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';
import { BackNextButton } from 'app/components/BackNextButton';

type Props = {
  tryItTeamData: TeamRegistration;
  setTryItTeamData: React.Dispatch<React.SetStateAction<TeamRegistration>>;
  handleBack: () => void;
  handleNxt: () => void;
  stateData?: GeneralLookup[];
  categoryData?: GeneralLookup[];
  coachData: GeneralLookup[];
  assistantCoachData: GeneralLookup[];
};

export const PersonalInfoForm = ({
  tryItTeamData,
  setTryItTeamData,
  handleBack,
  handleNxt,
  stateData,
  categoryData,
  coachData,
  assistantCoachData,
}: Props) => {
  const simpleValidator = React.useRef(new SimpleReactValidator());

  const handleStateOptionChange = (id: string, name: string) => {
    setTryItTeamData(prevState => {
      const existingItemIndex = prevState?.category?.findIndex(
        item => item.id === id,
      );
      if (existingItemIndex! > -1) {
        const updatedCategory = [...prevState?.category!];
        updatedCategory.splice(existingItemIndex!, 1);
        return { ...prevState, category: updatedCategory };
      } else {
        return {
          ...prevState,
          category: [...prevState?.category!, { id, name }],
        };
      }
    });
  };
  const [valid, setValid] = useState<boolean>(false);
  const handlePhChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
      setTryItTeamData({
        ...tryItTeamData,
        phone: inputValue,
      });
    }
  };
  const handleEmailChange = e => {
    setTryItTeamData({ ...tryItTeamData, email: e.target.value });
  };
  const handleZipChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 9 && /^[a-zA-Z0-9]*$/.test(inputValue)) {
      setTryItTeamData({ ...tryItTeamData, zip: e.target.value });
    }
  };
  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [tryItTeamData]);
  return (
    <MainWrapper className="sub-MainWrapper">
      <OverflowWrapper>
        <p className="first-para">
          Please indicate all the categories that your team will have:
        </p>
        <CheckboxWrapper>
          {categoryData?.map(value => (
            <CheckBox
              className="personal-CheckBox"
              label={value?.name!}
              value={value.id}
              onChange={() => handleStateOptionChange(value?.id!, value?.name!)}
              checked={tryItTeamData?.category?.some(
                item => item.id === value.id,
              )}
            />
          ))}
        </CheckboxWrapper>
        <TextFieldGrid>
          <TextFieldWrapper>
            <StyledTextField
              onChange={e =>
                setTryItTeamData({ ...tryItTeamData, name: e.target.value })
              }
              value={tryItTeamData?.name}
              label="Team Name"
              type="text"
              name="Team Name"
              validations={'required|alpha_space|max:30'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          {/* <TextFieldWrapper>
            <SHdropdown
              onDataChange={e =>
                setTryItTeamData({
                  ...tryItTeamData,
                  mainCoach: e,
                })
              }
              defaultValue={tryItTeamData?.mainCoach}
              data={coachData}
              label="Main Coach"
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>

          <MultiTextFieldWrapper>
            <MultiSelectDropdown
              data={coachData?.filter(
                coach => coach.id !== tryItTeamData?.mainCoach?.id,
              )}
              defaultValue={tryItTeamData?.associateCoachList!}
              onChange={e => {
                setTryItTeamData({
                  ...tryItTeamData,
                  associateCoachList: e?.value,
                });
              }}
              label="Coach"
              // validations={'required'}
              // validator={simpleValidator.current}
            />
          </MultiTextFieldWrapper>
          <MultiTextFieldWrapper>
            <MultiSelectDropdown
              data={assistantCoachData}
              defaultValue={tryItTeamData?.assistantCoachList!}
              onChange={e => {
                setTryItTeamData({
                  ...tryItTeamData,
                  assistantCoachList: e?.value,
                });
              }}
              label="Assistant Coach"
              // validations={'required'}
              // validator={simpleValidator.current}
            />
          </MultiTextFieldWrapper> */}
          <TextFieldWrapper>
            <StyledTextField
              onChange={e =>
                setTryItTeamData({
                  ...tryItTeamData,
                  mainContact: e.target.value,
                })
              }
              value={tryItTeamData?.mainContact}
              label="Main Contact"
              type="text"
              name="mainContact"
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              onChange={handlePhChange}
              value={tryItTeamData?.phone}
              label="Phone Number"
              type="tel"
              name="phoneNumber"
              validations={'required|numeric|min:10|max:10'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              label="Email"
              type="email"
              name="email"
              onChange={handleEmailChange}
              value={tryItTeamData?.email}
              validations={'required|email'}
              validator={simpleValidator.current}
              maxLength={60}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
        <MainAddressWrapper>
          <div className="horizontal-line">
            <span>Main Address</span>
          </div>
          <TextFieldGrid>
            <TextFieldWrapper>
              <StyledTextField
                onChange={e =>
                  setTryItTeamData({ ...tryItTeamData, street: e.target.value })
                }
                value={tryItTeamData?.street}
                label="Street Address"
                type="text"
                name="streetAddress"
                validations={'required'}
                validator={simpleValidator.current}
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <StyledTextField
                onChange={e =>
                  setTryItTeamData({ ...tryItTeamData, city: e.target.value })
                }
                value={tryItTeamData?.city}
                label="City"
                type="text"
                name="city"
                validations={'required|max:40'}
                validator={simpleValidator.current}
                maxLength={40}
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <SHdropdown
                data={stateData}
                onDataChange={data => {
                  if (tryItTeamData) {
                    setTryItTeamData({
                      ...tryItTeamData,
                      state: data,
                    });
                  }
                }}
                defaultValue={tryItTeamData?.state}
                className="drop-down"
                label="State"
                validations={'required'}
                validator={simpleValidator.current}
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <StyledTextField
                onChange={handleZipChange}
                value={tryItTeamData?.zip}
                label="Zip"
                type="text"
                name="zip"
                validations={'required|alpha_num|min:5|max:9'}
                validator={simpleValidator.current}
              />
            </TextFieldWrapper>
          </TextFieldGrid>
          <BottomRightWrapper className="bg-color-change">
            <ButtonWrapper>
              <BackNextButton
                classNameBack="btn-back"
                classNameNxt="btn-nxt"
                onClickNxt={handleNxt}
                onClickBack={handleBack}
                next="Next"
                back="Back"
                disableNext={valid || tryItTeamData.category?.length === 0}
              />
            </ButtonWrapper>
          </BottomRightWrapper>
        </MainAddressWrapper>
      </OverflowWrapper>
    </MainWrapper>
  );
};
const BottomRightWrapper = styled.div`
  z-index: 1;
  height: 70px;
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;
  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: 10px;
    left: 0;
    padding-top: 30px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;

const ButtonWrapper = styled.div``;
const MainWrapper = styled.div`
  width: 100%;
  color: #9597a6;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  height: calc(100vh - 230px);

  .first-para,
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .first-para,
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .first-para,
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .first-para,
    label {
      font-size: 14px;
    }
  }
`;
const OverflowWrapper = styled.div`
  height: 100%;
  overflow: auto;
  .first-para {
    padding: 0 10px;
  }
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: #201e1e !important;
    border-radius: 10px;
  }
`;
const CheckboxWrapper = styled.div`
  display: block;
  padding: 10px 10px 25px;
  .personal-CheckBox {
    display: inline-flex;
    padding: 0 15px 0 0;
    align-items: center;
    .k-checkbox-wrap {
      padding: 0 5px 0 0;
    }
  }
`;

const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 20px;
`;
const TextFieldWrapper = styled.div`
  width: 50% !important;
  margin-bottom: 20px;
  &.styled-TextFieldWrapper {
    display: grid;
    align-items: end;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    width: 100% !important;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 50% !important;
  }
`;
const StyledTextField = styled(TextField)`
  flex: 0 0 50%;
  &.styled-field {
    margin-bottom: 0px !important;
  }
`;
const MainAddressWrapper = styled.div`
  padding-bottom: 50px;
  .horizontal-line {
    display: flex;
    align-items: baseline;
    margin: 0px 0 20px;
    padding: 0 10px;
  }
  .horizontal-line span {
    margin: 0 10px 0 0;
    white-space: nowrap;
    color: #4f4f4f;
    font-size: 15px;
  }
  .horizontal-line::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #434343;
    height: 3px;
  }
`;
