import { CoachRegistration } from 'services/openapi';

export const items = [
  { label: 'Personal Information', number: '1' },
  { label: 'Professional Affiliation', number: '2' },
];
export const defaultValue: CoachRegistration = {
  id: null,
  isAssistant: false,
  firstName: '',
  lastName: '',
  email: '',
  primaryPhone: '',
  street: '',
  city: '',
  state: {
    id: '',
    name: '',
    code: '',
    children: null,
    otherName: '',
    isAdminLive: false,
  },
  zip: '',
  emergencyContactName: '',
  emergencyContactPhone: '',
  isTrial: false,
  hasSubmittedCertificateOfInsurance: false,
  hasVerifiedCertificateOfInsurance: false,
  isTeamAcceptingNewMembers: true,
  isInterestedInReferralProgram: true,
  professionalAffiliationMap: [],
  isReturning: false,
  team: {
    id: '',
    name: '',
    code: '',
    children: null,
    otherName: '',
    isAdminLive: false,
  },
  isMainCoach: false,
};
export const defaultPhotoValue = {
  formData: {
    file: undefined,
  },
};
