import React, { useState } from 'react';
import styled from 'styled-components';
import { CheckBox } from 'app/components/CheckBox';
import { BackNextButton } from 'app/components/BackNextButton';
import useApi from 'services/mavapi/useApi';
import Cookies from 'js-cookie';
import {
  ApiError,
  CoachService,
  CreateInsuranceApplication,
  GeneralLookup,
  LandingPage,
  LookupService,
  RiderService,
  sif,
} from 'services/openapi';
import { useNavigate } from 'react-router-dom';
import LoaderWrapper from 'app/components/CircularLoader/LoaderWrapper';
import CircularLoader from 'app/components/CircularLoader';
import { CoachInsuranceForm } from '../InsuranceForm/Index';
import InsurancePopUp from 'app/pages/CoachRegistrationPage/components/CoachInsurance/components/InsurancePopUp';
import CustomDragAndDropFiles from 'app/components/CustomDragAndDropFiles';
import { InfoLabel } from 'app/components/SHLabel';
import CoachCustomDragAndDropFiles from 'app/components/CoachDragAndDropFiles';
import { useManagePageSlice } from 'app/pages/slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectPageLoader } from 'app/pages/slice/selectors';
import useToast from 'hooks/Toast';
import SHButton from 'app/components/SHButton';
import CoachInsurancePopUp from '../InsurancePopUp';
import { useErrorHandlingSlice } from 'app/error/slice';

type Props = {};

const CoachInsuranceApplication = (props: Props) => {
  const defaultValue: CreateInsuranceApplication = {
    coachId: '',
    firstName: '',
    lastName: '',
    primaryPhone: '',
    email: '',
    street: '',
    city: '',
    state: { id: '', name: '', children: null },
    zip: '',
    isAboveTwentyOne: false,
    team: { id: '', name: '', children: null },
    teamLocation: '',
    hasClaimAgainstActivity: false,
  };
  const insurnceDefaultValue = {
    formData: {
      certificateOfInsurance: undefined,
    },
  };
  const navigate = useNavigate();
  const { actions: PageActions } = useManagePageSlice();
  const pageLoader = useSelector(selectPageLoader);
  const dispatch = useDispatch();
  const { actions } = useErrorHandlingSlice();
  const [coachInsuranceUploadPayload, setCoachInsuranceUploadPayload] =
    useState<{
      formData?: {
        certificateOfInsurance?: Blob;
      };
    }>(insurnceDefaultValue);
  const [coachInsuracePayload, setCoachInsuracePayload] =
    useState<CreateInsuranceApplication>(defaultValue);
  const currentUserId = Cookies.get('userId');
  const { coachUploadCertificateOfInsurance } = CoachService;
  const [valid, setValid] = useState<boolean>(false);
  const { coachCreateInsuranceApplication } = CoachService;
  const [showLoader, setShowLoader] = React.useState<boolean>(false);
  const { error: apiError, handleRequest } = useApi();
  const [, setError] = React.useState<ApiError | unknown>();

  const handleSubmit = () => {
    dispatch(PageActions.setPageLoader(true));
    const payload = { ...coachInsuracePayload, coachId: currentUserId };
    console.log(payload, 'payloadid');
    handleRequest(coachCreateInsuranceApplication(payload))
      .then(res => {
        if (!(res instanceof ApiError)) {
          dispatch(PageActions.setPageLoader(false));
          Cookies.set('addInsurance', true);
          const coachDataString = JSON.stringify(payload);
          Cookies.set('insurancePayload', coachDataString);
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Insurance submitted successfully',
              show: true,
              errorType: 'success',
            }),
          );
          navigate(`/payment/${payerTypes?.id}/${currentUserId}/false/false`);
        } else {
          dispatch(PageActions.setPageLoader(false));
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Something went wrong',
              show: true,
              errorType: 'error',
            }),
          );
        }
      })
      .catch(error => {
        console.error('Error adding team:', error);
      });
  };
  const [payerTypes, setPayerTypes] = React.useState<GeneralLookup>();
  const [stateData, setStateData] = useState<GeneralLookup[]>([]);
  const [affiliationsData, setAffiliationsData] = useState<GeneralLookup[]>([]);
  const { lookupGetAllLookups } = LookupService;

  function getAllLookupData(names?: string) {
    handleRequest(lookupGetAllLookups(names))
      .then(data => {
        if (data) {
          setShowLoader(false);
          let varPayerTypes = data?.filter(item => {
            if (item?.type === 'PAYER_TYPES') {
              return item.values;
            }
          });
          let payerTypeObj = varPayerTypes?.[0]?.values?.find(item => {
            if (item?.name === 'Coach') {
              return item ?? {};
            }
          });
          let varState = data?.filter(item => {
            if (item?.type === 'STATE') {
              return item.values;
            }
          });
          let varAffiliations = data?.filter(item => {
            if (item?.type === 'TEAM') {
              return item.values?.map(data => {
                return data.name;
              });
            }
          });
          setStateData(varState?.[0]?.values!);
          setAffiliationsData(varAffiliations?.[0].values!);
          setPayerTypes(payerTypeObj);
        }
      })
      .catch(() => setError(apiError));
  }
  React.useEffect(() => {
    setShowLoader(true);
    getAllLookupData('PAYER_TYPES,STATE,TEAM');
  }, []);

  const [insuranceRadioVal, setInsuranceRadioVal] = React.useState<
    string | null | undefined
  >('');
  const [visible, setVisible] = React.useState<boolean>(true);
  const [showInsurance, setShowInsurance] = useState<boolean>(false);
  const handleOnCancelNewInsuranceClick = e => {
    navigate('/rider-landing-page/coach-dashboard');
    setInsuranceRadioVal('');
    setVisible(false);
  };
  const handleNewInsuranceChange = e => {
    console.log(e, 'handleNewInsuranceChange');
  };
  const handlePaymentCancel = () => {
    navigate(-1);
    Cookies.remove('addInsurance');
  };
  const handleFileOnCancelClick = e => {
    setInsuranceRadioVal('');
    setVisible(true);
    setUploadInsuranceFile(undefined);
  };
  const [uploadInsuranceFile, setUploadInsuranceFile] = useState<Blob>();
  const [fileAvailability, setFileAvailability] = React.useState<boolean>(true);
  const handleFileUpload = e => {
    var file = e.target.files[0];
    setUploadInsuranceFile(file);
    setCoachInsuranceUploadPayload({
      ...coachInsuranceUploadPayload,
      formData: { certificateOfInsurance: file },
    });
  };
  const handleRemoveFile = e => {
    setUploadInsuranceFile(undefined);
  };
  const coachID = Cookies.get('userId');
  const handleInsuranceUploadForm = coachId => {
    dispatch(PageActions.setPageLoader(true));
    console.log(coachInsuranceUploadPayload, 'coachInsuranceUploadPayload');
    handleRequest(
      coachUploadCertificateOfInsurance(
        coachId,
        coachInsuranceUploadPayload?.formData!,
      ),
    )
      .then(res => {
        if (res && !apiError) {
          Cookies.set('issuranceRadioButtonpayload', insuranceRadioVal);
          dispatch(PageActions.setPageLoader(false));
          dispatch(
            actions.dispatchToast({
              errorTxt: 'File uploaded successfully',
              show: true,
              errorType: 'success',
            }),
          );
          navigate(`/payment/${payerTypes?.id}/${currentUserId}/false/false`);
        } else {
          dispatch(PageActions.setPageLoader(false));
          dispatch(
            actions.dispatchToast({
              errorTxt: 'File upload failed',
              show: true,
              errorType: 'error',
            }),
          );
        }

        console.log('insurance uploadded ', res);
      })
      .catch(error => {
        console.error('Error adding team:', error);
      });
  };
  React.useEffect(() => {
    if (uploadInsuranceFile === undefined) {
      setFileAvailability(true);
    } else {
      setFileAvailability(false);
    }
  }, [uploadInsuranceFile]);
  return (
    <>
      {showLoader ? (
        <LoaderWrapper bgTheme="light">
          <CircularLoader />
        </LoaderWrapper>
      ) : (
        <>
          <CoachInsurancePopUp
            show={visible}
            setShow={setVisible}
            onChange={e => handleNewInsuranceChange(e)}
            setShowInsurance={setShowInsurance}
            onClose={handleOnCancelNewInsuranceClick}
            setInsuranceRadioVal={setInsuranceRadioVal}
            heading="Do you have a coach liability insurance?"
          />
          {showInsurance ? (
            <>
              <ComponentWrapper className="ComponentWrapper">
                <StyledInfoLabelWrapper className="StyledInfoLabelWrapper">
                  <InfoLabel>
                    Please upload the insurance copy to proceed with the
                    registration
                  </InfoLabel>
                </StyledInfoLabelWrapper>

                <CoachCustomDragAndDropFiles
                  className="dragNdrop"
                  onCancel={handleFileOnCancelClick}
                  onChange={e => handleFileUpload(e)}
                  uploadBtnDisabled={!fileAvailability}
                  onRemoveFile={handleRemoveFile}
                  chooseFileBtnDisabled={!fileAvailability}
                />
              </ComponentWrapper>
              <ButtonWrapper>
                <SHButton
                  className="btn"
                  onClick={() => handleInsuranceUploadForm(coachID)}
                  disabled={fileAvailability}
                >
                  Submit and Pay
                </SHButton>
              </ButtonWrapper>
            </>
          ) : (
            <MainWrapper>
              {' '}
              <h1>YEDA Coach Liability Insurance Application</h1>
              <p className="first-para">Information</p>
              <p className="second-para">
                This Coach Liability policy will cover you for your activities
                as a Coach for Youth Equestrian Development Association only.
                This policy does not cover you as an employee of the Youth
                Equestrian Development Association OR for private lessons where
                you have a private training agreement. YEDA is the named insured
                on this policy. Each coach will have Liability Limits of
                $1,000,000 per occurrence/$1,000,000 aggregate. Coverage will be
                effective only after receiving a completed application and
                premium payment validated. The effective date of coverage will
                be no earlier than 8-15-22 or the date received if after
                8-15-22. All coverage will expire on 8-15-23.
              </p>
              <CoachInsuranceForm
                setCoachInsuracePayload={setCoachInsuracePayload}
                coachInsuracePayload={coachInsuracePayload}
                stateData={stateData}
                affiliationsData={affiliationsData}
                setValid={setValid}
                valid={valid}
              />
              <BackNextButton
                next="Proceed to payment"
                back="Cancel"
                className="btn"
                classNameBack="cancel-btn"
                classNameNxt="proceed-btn"
                onClickNxt={() => handleSubmit()}
                onClickBack={handlePaymentCancel}
                disableNext={valid}
                buttonTheme="light"
              />
            </MainWrapper>
          )}
        </>
      )}
    </>
  );
};
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;
const StyledInfoLabelWrapper = styled.div`
  padding: 20px 0;
  @media (min-width: 100px) and (max-width: 319px) {
    label {
      font-size: 12px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    label {
      font-size: 13px !important;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    label {
      font-size: 13px !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    label {
      font-size: 14px !important;
    }
  }
`;
const MainWrapper = styled.div`
  @media (min-width: 100px) and (max-width: 756px) {
    .k-button-solid-base {
      width: 100% !important;
    }
  }
  padding: 20px 40px;
  min-height: 100vh;
  background-color: #ffffff;
  width: 100%;
  .first-para {
    // padding-bottom: 20px;
    font-size: 24px;
  }
  .second-para {
    color: #cc5050;
    font-size: 14px;
  }
  .third-checkbox {
    label {
      font-weight: bold;
    }
  }
  .fifth-para {
    font-weight: bold;
  }
  .btn {
    flex-direction: row-reverse;
    display: flex;
    justify-content: start;
    gap: 40px;
  }
  .k-button-solid-base {
    border-radius: 25px;
    width: 22% !important;
    height: auto;
    font-weight: 600;
  }
  .proceed-btn {
    background-color: #2961f4;
    color: #ffffff;
  }
  .cancel-btn {
    background-color: #8d8d8d;
    color: #ffffff;
  }
`;
const SubWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  .third-para {
    font-weight: bold;
    font-size: 14px;
  }
  .fourth-para {
    color: #2961f4;
    font-size: 14px;
  }
`;
const ComponentWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
  display: grid;
  justify-content: center;
  margin-top: 100px;
  .LiabilityInsuranceInfo {
    height: auto;
  }
  #form-file-upload {
    margin: 0 auto 0 0;
  }
`;
export default CoachInsuranceApplication;
