import styled, { css } from 'styled-components';
import {
  Notification,
  NotificationGroup,
} from '@progress/kendo-react-notification';

import { Fade } from '@progress/kendo-react-animation';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectShowToast } from 'app/error/slice/selectors';
import { useErrorHandlingSlice } from 'app/error/slice';
import errorIcon from '../MHToast/assets/alert-red.svg';
import successIcon from '../MHToast/assets/tick-green.svg';
export type MHToastProps = {
  background?: 'primary' | 'secondary';
  border?: 'primary' | 'secondary';
};
export const MHToast = ({ background, border }: MHToastProps) => {
  const showToast = useSelector(selectShowToast);
  const selectedShowToast = useSelector(selectShowToast);
  const dispatch = useDispatch();
  const { actions } = useErrorHandlingSlice();
  React.useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        actions.dispatchToast({ errorTxt: '', show: false, errorType: 'none' }),
      );
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [showToast]);

  return (
    <>
      {showToast?.show && (
        <Wrapper background={background} border={border}>
          <NotificationGroup
            style={{
              top: 20,
              right: 10,
              transform: 'translateX(-50%)',
              zIndex: 10010,
            }}
          >
            <Fade>
              <Notification
                type={{ style: selectedShowToast?.errorType }}
                closable={true}
                onClose={() =>
                  dispatch(
                    actions.dispatchToast({
                      errorTxt: '',
                      show: false,
                      errorType: 'none',
                    }),
                  )
                }
              >
                <ContentWarpper>
                  {selectedShowToast?.errorType === 'error' ? (
                    <img src={errorIcon} alt="Error Icon" />
                  ) : selectedShowToast?.errorType === 'success' ? (
                    <img src={successIcon} alt="Success Icon" />
                  ) : null}
                  <span>{selectedShowToast?.errorTxt}</span>
                </ContentWarpper>
              </Notification>
            </Fade>
          </NotificationGroup>
        </Wrapper>
      )}
    </>
  );
};

const ContentWarpper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  font-family: 'Inter';
  img {
    width: 25px;
    height: 20px;
    margin: 0 8px 0 0;
  }
`;
export const Wrapper = styled.div<MHToastProps>`
  ${props => props.background && backgroundBlock[props.background]}
  ${props => props.border && borderBlock[props.border]};
  .k-notification-group {
    font-family: 'Inter';
  }
  .k-notification {
    display: flex;
    align-items: center;
    max-width: 281px;
    max-width: 350px;
    width: 100%;
    justify-content: space-between;
    gap: 8px;
  }
  .mh-label-style {
    margin: 0 0 -18px 0 !important;
    font-size: 9px;
    padding: 0 0 0 9px;
    color: #716d6c;
  }
  .k-notification-error {
    background-color: #fdedef;
    border-color: #fdedef;
    color: #191919;
    font-weight: 500;
    font-family: 'lato';
  }
  .k-notification-success {
    background-color: #ebfbf5;
    border-color: #ebfbf5;
    color: #191919;
    font-weight: 500;
    font-family: 'lato';
  }
  .k-notification .k-icon.k-i-x {
    color: #a4a6ab;
  }

  .k-notification .k-icon.k-i-x-outline.k-notification-status {
    width: 20px;
    height: 20px;
    display: inline-flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
  }
`;
const backgroundBlock = {
  primary: css`
    background: #f2f3f9;
  `,
  secondary: css`
    background: #ffffff;
  `,
};
const borderBlock = {
  primary: css`
    border-color: #f2f3f9 !important;
  `,
  secondary: css`
    border-color: #bcbcbc !important;
    border-bottom-color: #bcbcbc !important;
  `,
};
