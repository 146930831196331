import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import menuJson from './menu.json';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Users from './assets/img/users.svg';
import Teams from './assets/img/teams.svg';
import Riders from './assets/img/riders.svg';
import Coaches from './assets/img/coaches.svg';
import PastData from './assets/img/pastData.svg';
import Sif from './assets/img/sif.svg';
import Shows from './assets/img/shows.svg';
import Reports from './assets/img/reports.svg';
import Settings from './assets/img/settings.svg';
import Logo from './assets/img/showeyda-logo.svg';
import LogoutIcon from 'app/pages/AdminPanel/assets/img/icon/logout.png';
import { useManagePageSlice } from 'app/pages/slice';
import { useDispatch } from 'react-redux';
import { CustomDeletePopup } from 'app/components/CustomDeletePopup';
import { useErrorHandlingSlice } from 'app/error/slice';

type Props = {};

const icons = {
  Users: Users,
  Teams: Teams,
  Riders: Riders,
  Coaches: Coaches,
  PastData: PastData,
  Sif: Sif,
  Shows: Shows,
  Reports: Reports,
  Sponsors: Users,
  Settings: Settings,
};

const Sidebar = (props: Props) => {
  const { menu: sidebarData } = menuJson;
  let location = useLocation();
  const navigate = useNavigate();
  const path = process.env.REACT_APP_API_ENDPOINT;
  const { actions: PageActions } = useManagePageSlice();
  const dispatch = useDispatch();
  //useStates
  const initialSelect = Cookies.get('selected');
  const initialExpand = Cookies.get('expanded');
  const [expandedItems, setExpandedItems] = React.useState<
    string[] | undefined
  >(initialExpand ? JSON.parse(initialExpand) : []);
  const [selectedItems, setSelectedItems] = React.useState<string>(
    initialSelect ? initialSelect : '',
  );
  const [userRoleData, setuserRoleData] = useState();
  const isAdmin = userRoleData === 'ADMIN' ? true : false;
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const { actions: toastActions } = useErrorHandlingSlice();

  const handlePanelBarSelect = (e: any) => {
    setExpandedItems(e.expandedItems);
    if (e.target.props.route && e.expandedItems.length <= 1) {
      navigate(e.target.props.route);
      Cookies.remove('expanded');
      Cookies.remove('selected');
    } else {
      if (e.target.props.route) {
        navigate(e.target.props.route);
        Cookies.set(
          'expanded',
          JSON.stringify([e.expandedItems[0], e.expandedItems[1]]),
        );
        Cookies.set('selected', e.expandedItems[2]);
      } else {
        Cookies.set('expanded', JSON.stringify([e.expandedItems[0]]));
        Cookies.set('selected', e.expandedItems[1]);
      }
    }
  };

  function getStringBeforeSecondSlash(pathname) {
    const parts = pathname.split('/');
    return `/${parts[1]}`;
  }
  useEffect(() => {
    setuserRoleData(Cookies.get('userRole'));
  }, []);
  const handleLogout = () => {
    Cookies.remove('userId');
    Cookies.remove('userName');
    Cookies.remove('userRole');
    Cookies.remove('accessToken');
    dispatch(PageActions.setIsAccessToken(undefined));
    dispatch(
      toastActions.dispatchToast({
        errorTxt: 'Logged Out Successfully',
        show: true,
        errorType: 'success',
      }),
    );
    navigate('/');
  };

  return (
    <SibarWrapper>
      <SidebarLogoWrapper>
        <img src={Logo} alt="showeyda-logo" />
      </SidebarLogoWrapper>
      <div className="content">
        <PanelBar
          expandMode={'single'}
          onSelect={e => handlePanelBarSelect(e)}
          isControlled
          selected={selectedItems}
          expanded={expandedItems}
        >
          {isAdmin && path?.includes('yeda-api-dev.onesmarter.in')
            ? sidebarData.map((item, index) => {
                return (
                  <PanelBarItem
                    title={item.name}
                    key={index}
                    imageUrl={icons[item.icon]}
                    route={item.route}
                    className={
                      item?.route === ''
                        ? 'focused-inactive'
                        : location?.pathname?.includes(item.route)
                        ? 'focused-active'
                        : 'focused-inactive'
                    }
                  >
                    {item?.subMenus?.length &&
                      item?.subMenus.map((innerItem, index) => {
                        return (
                          <PanelBarItem
                            className={
                              location.pathname.includes(innerItem.route)
                                ? 'focused-inner-active'
                                : 'focused-inactive'
                            }
                            route={innerItem.route}
                            key={index}
                            title={innerItem.name}
                          ></PanelBarItem>
                        );
                      })}
                  </PanelBarItem>
                );
              })
            : isAdmin &&
              sidebarData
                .filter(user => user.name === 'Riders' || user.name === 'Teams')
                .map((item, index) => {
                  return (
                    <PanelBarItem
                      title={item.name}
                      key={index}
                      imageUrl={icons[item.icon]}
                      route={item.route}
                      className={
                        item?.route === ''
                          ? 'focused-inactive'
                          : location?.pathname?.includes(item.route)
                          ? 'focused-active'
                          : 'focused-inactive'
                      }
                    >
                      {item?.subMenus?.length &&
                        item?.subMenus.map((innerItem, index) => {
                          return (
                            <PanelBarItem
                              className={
                                location.pathname.includes(innerItem.route)
                                  ? 'focused-inner-active'
                                  : 'focused-inactive'
                              }
                              route={innerItem.route}
                              key={index}
                              title={innerItem.name}
                            ></PanelBarItem>
                          );
                        })}
                    </PanelBarItem>
                  );
                })}
        </PanelBar>
      </div>

      <LogoutWrapper
        onClick={() => {
          setShowLogoutPopup(true);
        }}
      >
        <div className="logout-main-wrapper">
          <div className="logout-img-wrapper">
            <img src={LogoutIcon} alt="Logout-icon" />
          </div>
          <p>Logout</p>
        </div>
      </LogoutWrapper>
      <CustomDeletePopup
        headerUnderline={false}
        className="body-scroll-lock height-full logout-popup"
        popupLabel="Are you sure you want to Logout?"
        setVisible={setShowLogoutPopup}
        visible={showLogoutPopup}
        align="centre"
        btnLabel1="Cancel"
        btnLabel2="Confirm"
        handlebtn2Click={handleLogout}
        handlebtn1Click={() => {
          setShowLogoutPopup(false);
        }}
      />
    </SibarWrapper>
  );
};

export default Sidebar;
const SibarWrapper = styled.div`
  min-height: 100vh;
  width: 250px;
  background: #fafafa;
  border-right: 1px solid #e5e5e5;
  .content {
    height: calc(100vh - 166px);
    overflow-y: auto;
    padding: 0 0 30px 0;
    /* Custom scrollbar styles */
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background: #caced8;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #caced8;
    }
  }

  .focused-active {
    border-right: 2px solid #001fff !important;
    .k-link {
      background: #f5f5f5 !important;
      img {
        filter: invert(0) !important;
      }
    }
  }
  .focused-inner-active {
    border-right: 2px solid #001fff !important;
    .k-link {
      background: #ececec !important;
      img {
        filter: invert(0) !important;
      }
    }
    .k-link:focus {
      box-shadow: none !important;
    }
    .k-link.k-focus {
      box-shadow: none !important;
    }
  }

  //Sidebar styles
  .k-panelbar {
    border: none;
    li {
      border: none;

      .k-link {
        &:hover {
          cursor: pointer;
        }
        background: #fafafa;
        img {
          width: 20px;
          margin: 0 8px 0 0;
          filter: invert(38%) sepia(50%) saturate(295%) hue-rotate(219deg)
            brightness(98%) contrast(93%);
        }
        &.k-focus {
          box-shadow: none;
        }
      }
    }
    .k-panelbar-item.k-level-1 .k-link {
      padding-left: 58px;
      background: #f5f5f5;
    }
    .k-panelbar-item-text {
      color: #404040;
      font-weight: 500;
      font-family: 'Inter';
      font-size: 14px;
    }
    .k-panelbar-toggle {
      svg {
        fill: #a3a3a3;
      }
      &.k-panelbar-expand {
        svg {
          transform: rotate(-94deg);
        }
      }
      &.k-panelbar-collapse {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
  }
`;
const SidebarLogoWrapper = styled.div`
  padding: 24px 24px;
  text-align: center;
`;
const LogoutWrapper = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  .logout-main-wrapper {
    display: flex;
    border-radius: 5px;
    border: 1px solid #85a4fd !important;
    padding: 0px 20px;
    align-items: center;
    border: 1px solid;
    height: 30px;
    cursor: pointer;
  }
  .logout-img-wrapper {
    width: 20px;
    height: auto;
    cursor: pointer;

    img {
      width: 100%;
      height: auto;
    }
  }
  p {
    cursor: pointer;
    color: #7e9fff;
    margin: 10px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .logout-img-wrapper {
      width: 15px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .logout-img-wrapper {
      width: 15px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .logout-img-wrapper {
      width: 15px;
    }
  }
`;
