import React, { useEffect, useState } from 'react';
import PersonalInfo from './components/PersonalInfo';
import styled from 'styled-components';
import ParentDetails from './components/ParentDetails';
import SchoolDetails from './components/SchoolDetails';
import EnrolmentData from './components/EnrolmentDetails';
import { Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';
import { useNavigate } from 'react-router-dom';
import {
  ApiError,
  GeneralLookup,
  GeneralLookupWrapper,
  LookupService,
  RegisterRider,
  RiderDivisionResponse,
  RiderService,
} from 'services/openapi';
import useApi from 'services/mavapi/useApi';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import check from './assets/img/icon/check.png';
import { useManagePageSlice } from 'app/pages/slice';
import { selectPageLoader } from 'app/pages/slice/selectors';
import LoaderWrapper from 'app/components/CircularLoader/LoaderWrapper';
import CircularLoader from 'app/components/CircularLoader';
import IndividualPlacementFormNewRider from './components/IndividualPlacementForm';
import IndividualPlacementFormTwoNewRider from './components/IndividualPlacementFormTwo';
import {
  selectNewRiderData,
  selectPhotoUpload,
  selectPlacementRadioValue,
  selectPreviousEnrolData,
  selectPreviousEnrolRadioValue,
} from '../../slice/selectors';
import { useRiderRegistrationStateSlice } from '../../slice';
import Box from '@mui/joy/Box';
import CircularProgress from '@mui/joy/CircularProgress';
import NewMemberMembershipFormNewRider from './components/MembershipNew';
import isApiError from 'utils/isApiError';
import { useErrorHandlingSlice } from 'app/error/slice';

export type radioModel = {
  pwhQuestionOne: boolean | null;
  owhQuestionOne: boolean | null;
  ewhQuestionOne: boolean | null;
  rwhQuestionOne: boolean | null;
  swhQuestionOne: boolean | null;
  swhQuestionTwo: boolean | null;
  dwhQuestionOne: boolean | null;
  drQuestionOne: boolean | null;
  drQuestionTwo: boolean | null;
  usePhotoForPromotion?: boolean | null;
};

export type prevRadioModel = {
  hasStayedInSameDivisionForTwoYears: boolean | null;
  achievedTopTwoInIndividualNationals: boolean | null;
  achievedTopThreeInDivision: boolean | null;
  divisionCompliesWithSectionThirty: boolean | null;
};
export type heightWeightModel = {
  feet: number | undefined;
  inches: number | undefined;
  weight: number | undefined;
};
const items = [
  { label: 'Personal Information', number: '1' },
  { label: 'Parent', number: '2' },
  { label: 'School', number: '3' },
  { label: 'Placement Form', number: '4' },
  { label: 'Enrolment Data', number: '5' },
  { label: 'Membership Form', number: '6' },
  { label: 'Competition Records', number: '7' },
];
const defaultValue = {
  formData: {
    file: undefined,
  },
};
const nextYear = new Date().getFullYear() + 1;
const defaultvalue: RegisterRider = {
  id: null,
  parent: {},
  school: {},
  season: {
    id: '',
    name: '',
    code: null,
    children: [],
    otherName: null,
    isAdminLive: false,
  },
  currentGrade: {
    id: '',
    name: '',
    code: null,
    children: [],
    otherName: null,
    isAdminLive: false,
  },
  team: {
    id: '',
    name: '',
    code: null,
    children: [],
    otherName: null,
    isAdminLive: false,
  },
  category: {
    id: '',
    name: '',
    code: null,
    children: [],
    otherName: null,
    isAdminLive: false,
  },
  divisionClassOne: {
    id: '',
    name: '',
    code: null,
    children: [],
    otherName: null,
    isAdminLive: false,
  },
  divisionClassTwo: undefined,
  placementData: {
    pwhQuestionOne: false,
    owhQuestionOne: false,
    ewhQuestionOne: false,
    rwhQuestionOne: false,
    swhQuestionOne: false,
    swhQuestionTwo: false,
    dwhQuestionOne: false,
    drQuestionOne: false,
    drQuestionTwo: false,
  },
  riderAssociations: [],
  isReturningRider: false,
  backNumber: '',
  firstName: '',
  lastName: '',
  expectedGraduationYear: nextYear,
  dateOfBirth: '',
  height: 0,
  weight: 0,
  primaryPhone: '',
  email: '',
  street: '',
  city: '',
  state: {
    id: '',
    name: '',
    code: null,
    children: [],
    otherName: null,
    isAdminLive: false,
  },
  zip: '',
  emergencyContactName: null,
  emergencyContactPhone: null,
  hasStayedInSameDivisionForTwoYears: false,
  achievedTopTwoInIndividualNationals: false,
  achievedTopThreeInDivision: false,
  divisionCompliesWithSectionThirty: false,
  isTrial: false,
  usePhotoForPromotion: false,
  riderIdOld: null,
};

const periviousValue: GeneralLookup = {
  id: '',
  name: '',
  code: null,
  children: null,
  otherName: null,
  isAdminLive: null,
};
const NotReturningRider = () => {
  const { error: apiError, handleRequest } = useApi();

  const { actions: ErrorHandlingActions } = useErrorHandlingSlice();
  const newRiderData = useSelector(selectNewRiderData);
  const newRiderPreviousEnrolData = useSelector(selectPreviousEnrolData);
  const previousEnrolRadioDataRedux = useSelector(
    selectPreviousEnrolRadioValue,
  );
  const placementRadioDataRedux = useSelector(selectPlacementRadioValue);
  const photoUploadDataRedux = useSelector(selectPhotoUpload);
  const [, setError] = React.useState<ApiError | unknown>();
  const {
    riderAddNewRider,
    riderGetRiderDivision,
    riderUploadRiderPhoto,
    riderGetBackNumberList,
  } = RiderService;
  const [riderData, setRiderData] = useState<RegisterRider>(defaultvalue);
  const [value, setValue] = useState<number>(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { actions: manageAppActions } = useRiderRegistrationStateSlice();
  const { lookupGetAllLookups } = LookupService;
  //for Loader
  const { actions: PageActions } = useManagePageSlice();
  const pageLoader = useSelector(selectPageLoader);
  const [stateData, setStateData] = useState<GeneralLookup[]>([]);
  const [categoryData, setCategoryData] = useState<GeneralLookup[]>([]);
  const [currentGradeData, setCurrentGradeData] = useState<GeneralLookup[]>([]);
  const [lookUp, setLookUp] = useState<GeneralLookupWrapper[]>([]);
  const [cancelPaymentCookie, setCancelPaymentCookie] = useState<boolean>();
  const [payerTypes, setPayerTypes] = useState<GeneralLookup>();
  const [previousData, setPreviousData] = useState({
    seasonList: periviousValue,
    category: periviousValue,
    division: periviousValue,
  });
  const [teamFilteredCategory, setTeamFilteredCategory] =
    useState<GeneralLookup[]>();
  const [seasonDivison, setSeasonDivision] = useState<
    (string[] | undefined)[] | undefined
  >([]);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 767);
  const [riderDivisionRes, setRiderDivisionRes] =
    useState<RiderDivisionResponse>();
  const [isTermsChecked, setIsTermsChecked] = useState<boolean>(false);
  const [placementRadioValues, setPlacementRadioValues] = useState<radioModel>({
    pwhQuestionOne: null,
    owhQuestionOne: null,
    ewhQuestionOne: null,
    rwhQuestionOne: null,
    swhQuestionOne: null,
    swhQuestionTwo: null,
    dwhQuestionOne: null,
    drQuestionOne: null,
    drQuestionTwo: null,
    usePhotoForPromotion: null,
  });
  const [previousEnrolRadioValues, setPreviousEnrolRadioValues] =
    useState<prevRadioModel>({
      hasStayedInSameDivisionForTwoYears: null,
      achievedTopTwoInIndividualNationals: null,
      achievedTopThreeInDivision: null,
      divisionCompliesWithSectionThirty: null,
    });
  const [isBackNumberCheck, setIsBackNumberCheck] = useState<boolean | null>(
    null,
  );
  const [heightWeight, setHeightWeight] = useState<heightWeightModel>({
    feet: undefined,
    inches: undefined,
    weight: undefined,
  });
  const [datas, setDatas] = React.useState<any>(riderData.category?.children);
  const [photoUploadPayload, setPhotoUploadPayload] = useState<{
    formData?: {
      file?: Blob;
    };
  }>(defaultValue);
  const [fileAvailability, setFileAvailability] = React.useState<boolean>(true);
  const [uploadFile, setUploadFile] = useState<File | null>();
  const [backNumberData, setBackNumberData] = React.useState<
    string[] | undefined
  >([]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 767);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const totalPages = 6;
  const determinateValue = ((value + 1) / totalPages) * 100;

  const handleChangeStepper = (e: StepperChangeEvent) => {
    const index = Number(e.value);
    if (index >= 0 && index !== value) {
    }
  };
  function getBackNumberList() {
    handleRequest(riderGetBackNumberList())
      .then(data => {
        setBackNumberData(data);
      })
      .catch(() => setError(apiError));
  }

  const handleItemClick = (index: number) => {};

  const handlePersonalNxt = () => {
    setValue(1);
  };

  const handleParentNxtBack = () => {
    setValue(0);
  };

  const handleParentNxt = () => {
    setValue(2);
  };

  const handleSchoolNxtBack = () => {
    setValue(1);
  };

  const handleSchoolNxt = () => {
    setValue(3);
  };

  const handlePlacementOneBack = () => {
    setValue(2);
  };
  const handlePlacementOneNxt = () => {
    setValue(4);
    getRiderEnrollmentDivision();
  };
  // const handlePreviousEnrolBack = () => {
  //   setValue(3);
  // };

  // const handlePreviousEnrolNext = () => {
  //   setValue(5);
  //   getRiderEnrollmentDivision();
  // };
  const handleEnrolmentNxtBack = () => {
    setValue(3);
  };
  const handleEnrolmentNxt = () => {
    setValue(5);
  };
  const handleMembershipBack = () => {
    setValue(4);
  };
  const handleMembershipNext = () => {
    setValue(6);
  };
  const handlePlacementTwoBack = () => {
    setValue(5);
  };
  const handleGetLookups = () => {
    dispatch(PageActions.setPageLoader(true));
    handleRequest(
      lookupGetAllLookups(
        'STATE,TEAM,PROFESSIONALAFFILIATIONS,PAYER_TYPES,CATEGORY,GRADE,SEASON,DIVISION,ACTIVETEAM',
      ),
    )
      .then(res => {
        setLookUp(res);
        let varState = res?.filter(item => {
          if (item?.type === 'STATE') {
            return item.values;
          }
        });

        let varCategories = res?.filter(item => {
          if (item?.type === 'CATEGORY') {
            return item.values;
          }
        });
        let varCurrentGrade = res?.filter(item => {
          if (item?.type === 'GRADE') {
            return item.values;
          }
        });
        let varPayerTypes = res?.filter(item => {
          if (item?.type === 'PAYER_TYPES') {
            return item.values;
          }
        });
        let payerTypeObj = varPayerTypes?.[0]?.values?.find(item => {
          if (item?.name === 'Rider') {
            return item ?? {};
          }
        });
        let varDivison = res?.filter(item => {
          if (item?.type === 'DIVISION') {
            return item.values;
          }
        });
        let filteredDIvison = varDivison?.map(item =>
          item.values?.map(data => data.name),
        );

        const seasonValues = res?.find(item => item.type === 'SEASON')?.values;
        const isAdminLiveFiltered = seasonValues?.find(
          item => item?.isAdminLive,
        );

        setStateData(varState?.[0]?.values!);
        setCategoryData(varCategories?.[0]?.values!);
        setCurrentGradeData(varCurrentGrade?.[0].values ?? []);
        dispatch(PageActions.setPageLoader(false));
        setPayerTypes(payerTypeObj);
        setRiderData(prev => {
          return {
            ...prev,
            season: isAdminLiveFiltered!,
          };
        });
        setSeasonDivision(filteredDIvison);
      })

      .catch(error => {});
  };

  const getRiderEnrollmentDivision = () => {
    const riderEnrollmentdivison = {
      currentGrade: riderData.currentGrade,
      hasStayedInSameDivisionForTwoYears:
        riderData.hasStayedInSameDivisionForTwoYears,
      achievedTopTwoInIndividualNationals:
        riderData.achievedTopTwoInIndividualNationals,
      achievedTopThreeInDivision: riderData.achievedTopThreeInDivision,
      divisionCompliesWithSectionThirty:
        riderData.divisionCompliesWithSectionThirty,
      pwhQuestionOne: riderData.placementData.pwhQuestionOne,
      owhQuestionOne: riderData.placementData.owhQuestionOne,
      ewhQuestionOne: riderData.placementData.ewhQuestionOne,
      rwhQuestionOne: riderData.placementData.rwhQuestionOne,
      swhQuestionOne: riderData.placementData.swhQuestionOne,
      swhQuestionTwo: riderData.placementData.swhQuestionTwo,
      dwhQuestionOne: riderData.placementData.dwhQuestionOne,
      drQuestionOne: riderData.placementData.drQuestionOne,
      drQuestionTwo: riderData.placementData.drQuestionTwo,
    };
    const payload = { ...riderEnrollmentdivison, ...previousData };
    handleRequest(
      riderGetRiderDivision(payload)
        .then(res => {
          setRiderDivisionRes(res);
        })
        .catch(error => {}),
    );
  };

  const handlePlacementSubmit = () => {
    const updatedRiderData = {
      ...riderData,
      riderAssociations: riderData.riderAssociations?.filter(
        item => item !== undefined && item !== null,
      ),
    };

    dispatch(PageActions.setPageLoader(true));
    handleRequest(riderAddNewRider(updatedRiderData))
      .then(res => {
        if (res && !isApiError(res)) {
          if (riderData.usePhotoForPromotion === false) {
            if (photoUploadPayload.formData?.file) {
              handlePhotoUpload(res.toString(), photoUploadPayload.formData);
            }
          }
          if (cancelPaymentCookie) {
            Cookies.remove('paymentCancel');
          }
          dispatch(manageAppActions.setNewRiderData(riderData));
          dispatch(manageAppActions.setPreviousValue(previousData));
          dispatch(
            manageAppActions.setPreviousEnrolRadioValues(
              previousEnrolRadioValues,
            ),
          );
          dispatch(
            manageAppActions.setPlacementRadioValues(placementRadioValues),
          );
          dispatch(manageAppActions.setPhotoUpload(photoUploadPayload));
          dispatch(PageActions.setPageLoader(false));
          dispatch(
            ErrorHandlingActions.dispatchToast({
              errorTxt: 'Rider registration successfully',
              show: true,
              errorType: 'success',
            }),
          );
          navigate(`/payment/${payerTypes?.id}/${res}/false/false`);
        } else {
          dispatch(
            ErrorHandlingActions.dispatchToast({
              errorTxt: res.description,
              show: true,
              errorType: 'error',
            }),
          );
          dispatch(PageActions.setPageLoader(false));
        }
      })
      .catch(error => {
        setError(apiError);
        dispatch(
          ErrorHandlingActions.dispatchToast({
            errorTxt: error.description,
            show: true,
            errorType: 'error',
          }),
        );
        dispatch(PageActions.setPageLoader(false));
      });
  };
  const handlePhotoUpload = (
    riderId: string,
    formData?: {
      file?: Blob;
    },
  ) => {
    handleRequest(riderUploadRiderPhoto(riderId, formData))
      .then(res => {})
      .catch(error => {});
  };

  React.useEffect(() => {
    handleGetLookups();
    // handleGetTeamLookup();
    getBackNumberList();
    const paymentCancelStatus = Cookies.get('paymentCancel');
    setCancelPaymentCookie(paymentCancelStatus);
  }, []);
  React.useEffect(() => {
    if (cancelPaymentCookie && newRiderData) {
      setRiderData(newRiderData);
      setPreviousData(newRiderPreviousEnrolData!);
      setValue(6);
      setPlacementRadioValues(placementRadioDataRedux!);
      setPreviousEnrolRadioValues(previousEnrolRadioDataRedux!);
      setPhotoUploadPayload(photoUploadDataRedux ?? {});
      setUploadFile(photoUploadDataRedux?.formData?.file as File);
      setHeightWeight({
        feet: Math.floor(newRiderData?.height!),
        inches: Math.round(
          (newRiderData?.height! - Math.floor(newRiderData?.height!)) * 12,
        ),
        weight: newRiderData.weight,
      });
    }
  }, [cancelPaymentCookie]);

  return (
    <>
      {pageLoader ? (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      ) : (
        <MainWrapper className="page-content-wrapper">
          <p>New Rider</p>
          <div className="Stepper-wrapper">
            {isSmallScreen ? (
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <CircularProgress
                  size="lg"
                  determinate
                  value={determinateValue}
                >
                  {`${value + 1} / ${totalPages}`}
                </CircularProgress>
                {items
                  .filter((_, index) => index === value)
                  .map((item, index) => (
                    <h2 className="circular-stepper-heading" key={index}>
                      {item.label}
                    </h2>
                  ))}
              </Box>
            ) : (
              <Stepper
                className="form-stepper"
                value={value}
                onChange={handleChangeStepper}
                items={items}
              >
                {items.map((item, index) => (
                  <StepperStep
                    key={index}
                    className={`stepper-step ${
                      index < value ? 'completed' : ''
                    }`}
                    onClick={() => handleItemClick(index)}
                  >
                    {index < value ? (
                      <span className="tick-icon">&#10003;</span>
                    ) : (
                      item.number
                    )}
                  </StepperStep>
                ))}
              </Stepper>
            )}
          </div>

          {value === 0 && (
            <PersonalInfo
              onClickNxtPersonal={handlePersonalNxt}
              riderData={riderData}
              setRiderData={setRiderData}
              stateData={stateData}
              currentGradeData={currentGradeData}
              isBackNumberCheck={isBackNumberCheck}
              setIsBackNumberCheck={setIsBackNumberCheck}
              setHeightWeight={setHeightWeight}
              heightWeight={heightWeight}
              backNumberData={backNumberData}
            />
          )}
          {value === 1 && (
            <ParentDetails
              onClickBackParent={handleParentNxtBack}
              onClickNxtParent={handleParentNxt}
              riderData={riderData}
              setRiderData={setRiderData}
              stateData={stateData}
            />
          )}
          {value === 2 && (
            <SchoolDetails
              onClickBackSchool={handleSchoolNxtBack}
              onClickNxtSchool={handleSchoolNxt}
              riderData={riderData}
              setRiderData={setRiderData}
              stateData={stateData}
            />
          )}
          {value === 3 && (
            <IndividualPlacementFormNewRider
              onClickNxtPlacementOne={handlePlacementOneNxt}
              onClickBackPlacementOne={handlePlacementOneBack}
              riderData={riderData}
              setRiderData={setRiderData}
              placementRadioValues={placementRadioValues}
              setPlacementRadioValues={setPlacementRadioValues}
              previousEnrolRadioValues={previousEnrolRadioValues}
              setPreviousEnrolRadioValues={setPreviousEnrolRadioValues}
            />
          )}
          {/* {value === 4 && (
            <PreviousEnrollment
              onClickBackPreviousBack={handlePreviousEnrolBack}
              onClickPreviousNxt={handlePreviousEnrolNext}
              riderData={riderData}
              setRiderData={setRiderData}
              seasonData={seasonData}
              setPreviousData={setPreviousData}
              previousData={previousData}
              previousEnrolRadioValues={previousEnrolRadioValues}
              setPreviousEnrolRadioValues={setPreviousEnrolRadioValues}
            />
          )} */}

          {value === 4 && (
            <EnrolmentData
              onClickBackEnrolment={handleEnrolmentNxtBack}
              onClickNxtEnrolment={handleEnrolmentNxt}
              riderData={riderData}
              setRiderData={setRiderData}
              categoryData={categoryData}
              teamFilteredCategory={teamFilteredCategory}
              seasonDivison={seasonDivison}
              riderDivisionRes={riderDivisionRes}
              setIsTermsChecked={setIsTermsChecked}
              setDatas={setDatas}
              datas={datas}
              lookUp={lookUp}
              setTeamFilteredCategory={setTeamFilteredCategory}
            />
          )}
          {value === 5 && (
            <NewMemberMembershipFormNewRider
              onClickMembershipNext={handleMembershipNext}
              onClickMembershipBack={handleMembershipBack}
              riderData={riderData}
              setRiderData={setRiderData}
              setIsTermsChecked={setIsTermsChecked}
              isTermsChecked={isTermsChecked}
              placementRadioValues={placementRadioValues}
              setPlacementRadioValues={setPlacementRadioValues}
              setPhotoUploadPayload={setPhotoUploadPayload}
              photoUploadPayload={photoUploadPayload}
              setFileAvailability={setFileAvailability}
              fileAvailability={fileAvailability}
              setUploadFile={setUploadFile}
              uploadFile={uploadFile}
            />
          )}

          {value === 6 && (
            <IndividualPlacementFormTwoNewRider
              riderData={riderData}
              setRiderData={setRiderData}
              handlePlacementSubmit={handlePlacementSubmit}
              onClickPlacementTwoBack={handlePlacementTwoBack}
            />
          )}
        </MainWrapper>
      )}
    </>
  );
};

const MainWrapper = styled.div`
  .circular-stepper-heading {
    color: #8a8a8a;
  }
  .MuiCircularProgress-progress,
  .MuiCircularProgress-sizeLg {
    color: #8a8a8a;
    stroke: #2dce98;
  }
  .css-zitgxn-JoyCircularProgress-track {
    stroke: #434343;
  }
  .k-step-done {
    .k-step-indicator-text {
      position: relative;
      ::after {
        position: absolute;
        content: '';
        z-index: 15;
        background: #2dce98;
        background-image: url(${check});
        background-repeat: no-repeat;
        background-size: inherit;
        background-position: center;
        border-radius: 50px;
        left: -8px;
        height: 26px;
        width: 26px;
        top: -1px;
      }
    }
  }
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .k-stepper.form-stepper {
    pointer-events: none;
  }
  .k-stepper .k-step-indicator::after {
    box-shadow: unset !important;
  }
  .k-stepper .k-step-indicator::after {
    border-style: unset !important;
  }
  .k-stepper .k-step-label .k-step-text {
    width: 200px;
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #8a8a8a;
    font-size: 12px;
    max-width: unset !important;
  }
  .k-progressbar {
    background: #5b5b5b;
  }
  .k-progressbar .k-selected {
    background: #2dce98;
  }
  .k-stepper .k-step-done .k-step-indicator {
    border-color: #2dce98;
    color: white;
    background-color: #2dce98;
  }
  .k-stepper .k-step-current .k-step-indicator {
    border-color: #2dce98;
    color: white;
    background-color: #2dce98;
  }
  .k-stepper .k-step-indicator {
    border-color: #5b5b5b;
    color: #c4c4c4;
    background-color: #5b5b5b;
    width: 25px;
    height: auto;
  }
  //Responsive

  @media (min-width: 100px) and (max-width: 319px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        height: 55px;
        gap: 5px;
        padding: 0 8px;
      }
      .circular-stepper-heading {
        font-size: 11px;
      }
      .MuiCircularProgress-root {
        --CircularProgress-size: 50px;
      }
    }

    .form-MainWrapper {
      padding-top: 10px;
      .inner-BorderWrapper {
        padding: 20px 20px;
      }
      .OverflowWrapper {
        padding: 20px 12px 100px 12px;
      }
      .TextFieldWrapper {
        width: 100%;
        &.BackNumberFieldWrapper {
          .BackNumberField-label {
            margin: 0px !important;
          }
        }
        &.height-weight-wrapper {
          .FlexFieldWrapper {
            flex-direction: column;
            align-items: flex-start;
          }
          .note-block {
            font-size: 11px !important;
            padding-top: 22px !important;
            align-items: baseline;
            width: 100%;
            height: 100%;
          }
        }
      }
      .BackFieldWrapper {
        width: 100%;
        .BackNumberWrapper {
          padding: 0px;
        }
        .StyledTextField {
        }
      }
      .GraduationDobWrapper {
        width: 100%;
        flex-direction: column;
        padding: 0px;

        .GraduationSubWrapper {
          width: 100%;
          margin: 0 0 20px 0;
          .SHYear {
            margin: 0px !important;
          }
        }
      }
      .HeightInchWeightWrapper {
        width: 100%;
        padding: 0px;
      }
      .StyledTextField {
        margin: 0px 0px;
      }
      .SHdropdown {
        margin: 0px 0px !important;
      }
      #InfoLabelWrapper {
        padding: 0 0px 10px 0px !important;
        label {
          font-size: 11px !important;
        }
      }
      .TextFieldGrid {
        &.height-note-grid {
          padding: 0px 0px !important;
          flex-direction: column-reverse !important;
        }
      }
      .StyledHeightTextField {
        margin: 0px 0px 0px 20px !important;
        &.StyledHeightTextField-first {
          margin: 0px !important;
        }
      }
      .StyledWeightTextField {
        margin: 0px !important;
      }
      .MainAddressWrapper {
        margin: 0px !important;
        padding: 0px 0px 85px 0px !important;
        .text-left-NoteField {
          width: 100% !important;
          .note-block {
            font-size: 11px;
            letter-spacing: 0.1px;
          }
        }
      }
      .flex-NoteWrapper {
        margin: 0px;
        .note-block {
          font-size: 11px;
        }
      }
      .TermsandConditons {
        .terms-Conditons-para {
          font-size: 12px;
        }
      }
      .enrollment-bottom-para {
        bottom: unset;
        padding: 0 12px;
      }
      #BottomRightWrapper {
        height: 50px;
      }
      .StyledFormSecondaryLabel {
        font-size: 10px !important;
        letter-spacing: 0.2px;
      }
      .QuestionWrapper {
        .FormSecondaryLabel {
          font-size: 10px !important;
          letter-spacing: 0.2px;
        }
      }
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        height: 55px;
        padding: 0 12px;
      }
      .circular-stepper-heading {
        font-size: 16px;
      }
      .MuiCircularProgress-root {
        --CircularProgress-size: 55px;
      }
    }
    .form-MainWrapper {
      padding-top: 10px;
      .inner-BorderWrapper {
        padding: 20px 20px;
      }
      .OverflowWrapper {
        padding: 20px 12px 100px 12px;
      }

      .TextFieldWrapper {
        width: 100%;
        &.BackNumberFieldWrapper {
          .BackNumberField-label {
            margin: 0px !important;
          }
        }
        &.height-weight-wrapper {
          .FlexFieldWrapper {
            flex-direction: column;
            align-items: flex-start;
          }
          .note-block {
            font-size: 11px !important;
            padding-top: 22px !important;
            align-items: baseline;
            width: 100%;
            height: 100%;
          }
        }
      }
      .BackFieldWrapper {
        width: 100%;
        padding: 0px;
        .BackNumberWrapper {
          padding: 0px;
        }
        .StyledTextField {
          margin-left: 0px;
        }
      }
      .enrollment-bottom-para {
        bottom: unset;
        padding: 0 12px;
      }
      .GraduationDobWrapper {
        width: 100%;
        flex-direction: column;
        padding: 0px;
        .GraduationSubWrapper {
          width: 100%;
          margin: 0 0 20px 0;
          .SHYear {
            margin: 0px !important;
          }
        }
      }
      .HeightInchWeightWrapper {
        width: 100%;
        padding: 0px;
      }
      .StyledTextField {
        margin: 0px 0px;
      }
      .SHdropdown {
        margin: 0px 0px !important;
      }
      #InfoLabelWrapper {
        padding: 0 0px 10px 0px !important;
        label {
          font-size: 13px !important;
        }
      }
      .TextFieldGrid {
        padding: 10px 0px !important;
        &.height-note-grid {
          padding: 0px 0px !important;
          flex-direction: column-reverse !important;
        }
      }
      .MainAddressWrapper {
        margin: 0px !important;
        padding: 0px 0px 85px 0px !important;
        .text-left-NoteField {
          width: 100% !important;
          .note-block {
            font-size: 11px;
            letter-spacing: 0.1px;
          }
        }
      }

      .NoteFieldWrapper {
        margin: 0px !important;
        .note-block {
          font-size: 10px;
          letter-spacing: 0.2px;
        }
      }
      .StyledWeightTextField {
        margin: 0px !important;
      }

      .StyledHeightTextField {
        &.StyledHeightTextField-first {
          margin: 0px !important;
        }
      }
      .horizontal-line {
        margin: 20px 0px 15px 0px;
      }
      .TermsandConditons {
        .terms-Conditons-para {
          font-size: 12px;
        }
      }

      #BottomRightWrapper {
        height: 50px;
      }
      .StyledFormSecondaryLabel {
        font-size: 10px !important;
        letter-spacing: 0.2px;
      }
      .QuestionWrapper {
        .FormSecondaryLabel {
          font-size: 10px !important;
          letter-spacing: 0.2px;
        }
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        height: 55px;
        padding: 0 22px;
      }
      .circular-stepper-heading {
        font-size: 22px;
      }
    }

    .form-MainWrapper {
      .inner-BorderWrapper {
        padding: 20px 20px;
      }
      .OverflowWrapper {
        padding: 30px 20px 100px 20px;
      }

      #InfoLabelWrapper {
        label {
          font-size: 10px !important;
        }
      }

      .TextFieldWrapper {
        width: 100%;
        &.height-weight-wrapper {
          .note-block {
            font-size: 8px !important;
            padding-top: 22px !important;
          }
        }
        .drop-down {
          margin: 0 8px;
        }
      }
      .BackFieldWrapper {
        width: 100%;
        padding: 0px;
        .BackNumberWrapper {
          padding: 0 8px;
        }
        .StyledTextField {
          margin-left: 0px;
        }
      }
      .GraduationDobWrapper {
        width: 100%;
        flex-direction: column;
        padding: 0 8px;
        .GraduationSubWrapper {
          width: 100%;
          margin: 0 0 20px 0;
          .SHYear {
            margin: 0px !important;
          }
        }
      }
      .HeightInchWeightWrapper {
        width: 100%;
        padding: 0 8px;
      }
      .StyledTextField {
        margin: 0px 8px;
      }
      .note-block {
        font-size: 10px;
      }
      #BottomRightWrapper {
        height: 50px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .form-MainWrapper {
      .OverflowWrapper {
        padding: 20px 30px 100px 30px;
      }
      .TextFieldWrapper {
        width: 100%;
        &.height-weight-wrapper {
          .note-block {
            font-size: 8px !important;
            padding-top: 22px !important;
          }
        }
        .drop-down {
          margin: 0 8px;
        }
      }
      .BackFieldWrapper {
        width: 100%;
        padding: 0px;
        .BackNumberWrapper {
          padding: 0 8px;
        }
        .StyledTextField {
          margin-left: 0px;
        }
      }
      .GraduationDobWrapper {
        width: 100%;
        flex-direction: column;
        padding: 0 8px;
        .GraduationSubWrapper {
          width: 100%;
          margin: 0 0 20px 0;
          .SHYear {
            margin: 0px !important;
          }
        }
      }
      .HeightInchWeightWrapper {
        width: 100%;
        padding: 0 8px;
      }
      .StyledTextField {
        margin: 0px 8px;
      }
      .note-block {
        font-size: 10px;
      }
      #BottomRightWrapper {
        height: 50px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .form-MainWrapper {
      .BackFieldWrapper {
        width: 100%;
        padding: 0px;
        .BackNumberWrapper {
        }
        .StyledTextField {
          margin-left: 0px;
        }
      }
      .GraduationDobWrapper {
        width: 100%;
        flex-direction: column;
        .GraduationSubWrapper {
          width: 100%;
          margin: 0 0 20px 0;
          .SHYear {
            margin: 0px !important;
          }
        }
      }
      .HeightInchWeightWrapper {
        width: 100%;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
  }
`;

const StepperStep = styled.button`
  background-color: green !important;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;

  &.completed {
    background-color: green !important;
  }
`;

export default NotReturningRider;
