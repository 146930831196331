import { CommonPageWrapper, HeadingWrapper } from 'admin/components/wrappers';
import { PageMainLabel } from 'app/components/SHLabel';
import styled from 'styled-components';
import {
  defaultFilterData,
  defaultFilterModel,
  riderPointBreadCrumbItems,
  staticColumns,
} from '../static';
import RiderReportsGrid from './RiderReportsGrid';
import useRiderReportsApi from 'admin/hooks/useRiderReportsApi';
import { useEffect, useState } from 'react';
import { ReportHeaderCell } from '../cells/ReportHeaderCell';
import { ReportValueCell } from '../cells/ReportValueCell';
import { format } from 'date-fns';
import AdminDropDown from 'app/components/AdminDropDown';
import AdminCustomButton from 'app/components/AdminCustomButton';
import useCommonLookUpDatas from 'hooks/useCommonLookupData';
import { GeneralLookup } from 'services/openapi';
import { findObjectById, getLookupArray } from 'utils/common';
import { TableSkeletonLoader } from 'app/components/TableSkeletonLoader';
import CustomBreadCrumb, {
  breadCrumbDataModel,
} from 'app/components/CustomBreadCrumb';
import { SHToolTip } from 'app/components/SHToolTip';

type Props = {};

const RiderPointsReportsTable = ({}: Props) => {
  const {
    getRiderReports,
    riderReportData,
    setRiderReportData,
    loading,
    downloadRiderPoints,
  } = useRiderReportsApi();
  const { getAllLookupData, lookUps } = useCommonLookUpDatas();
  const [filterPayload, setFilterPayload] =
    useState<defaultFilterModel>(defaultFilterData);
  const [divisonData, setDivisionData] = useState<GeneralLookup[]>();
  const [classData, setClassData] = useState<GeneralLookup[]>();
  const [pageSkip, setPageSkip] = useState<number | undefined>(0);
  const [breadcrumbPreData, setBreadcrumbPreData] = useState<
    breadCrumbDataModel[]
  >(riderPointBreadCrumbItems);

  const handleDownloadRiderpointReport = async () => {
    try {
      const url = await downloadRiderPoints(
        filterPayload.season.id,
        filterPayload.category.id,
        filterPayload.division.id,
        filterPayload.class.id,
      );
      // Extract filename from URL
      const filename = url.split('/').pop();

      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const objectUrl = URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = objectUrl;
          link.download = filename!; // Use the extracted filename

          // Append link to body (required for Firefox)
          document.body.appendChild(link);

          // Trigger download
          link.click();

          // Clean up
          document.body.removeChild(link);
          URL.revokeObjectURL(objectUrl);
        })
        .catch(error => {});

      return url;
    } catch (error) {
      throw error;
    }
  };
  const handlePageChange = event => {
    setPageSkip(event.page.skip);
    getRiderReports(
      filterPayload.season.id,
      filterPayload.category.id,
      filterPayload.division.id,
      filterPayload.class.id,
      event.page.skip,
      10,
    );
  };
  const handleSeasonChange = data => {
    setFilterPayload({
      ...filterPayload,
      season: data,
    });
  };
  const handleCategoryChange = data => {
    setFilterPayload({
      ...filterPayload,
      category: data,
      division: { id: '', name: '' },
      class: { id: '', name: '' },
    });
    setClassData([]);
    let categoryLookUpList = getLookupArray('TEAM_CATEGORY', lookUps);
    const matchingObject = findObjectById(categoryLookUpList, data.id);
    if (matchingObject) {
      const { children } = matchingObject;
      setDivisionData(children!);
    }
  };
  const handleDivisionChange = data => {
    setFilterPayload({
      ...filterPayload,
      division: data,
      class: { id: '', name: '' },
    });
    setClassData(data.children!);
  };
  const handleClassChange = data => {
    setFilterPayload({ ...filterPayload, class: data });
  };
  const handleSaveClick = () => {
    if (filterPayload) {
      getRiderReports(
        filterPayload.season.id,
        filterPayload.category.id,
        filterPayload.division.id,
        filterPayload.class.id,
        pageSkip,
        10,
      );
    }
  };
  const handleCancelClick = () => {
    setRiderReportData(undefined);
    setFilterPayload(defaultFilterData);
    setClassData([]);
    setDivisionData([]);
  };
  const dynamicColumns = riderReportData?.shows?.map(show => ({
    field: show.id,
    title: (
      <div
        className={`${show.isNationalFinal ? 'green-bg' : ''} top-header-style`}
      >
        <SHToolTip position="top">
          <div
            style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
            title={show.name ?? ''}
            className="dynamic-title"
          >
            {show.name}
          </div>
        </SHToolTip>
        <div className="date">
          {format(new Date(show?.startDate ?? ''), 'yyyy-MM-dd')}
        </div>
      </div>
    ),
    width: '200px',
  }));

  const mappedColumns = [...staticColumns, ...(dynamicColumns ?? [])].map(
    (column, index) => ({
      field: column.field,
      cell: e => {
        if (column?.field && column?.field in e.dataItem) {
          return <ReportValueCell value={e.dataItem[column?.field]} e={e} />;
        } else {
          const showData = e.dataItem.shows.find(
            show => show.showId === column.field,
          );
          return <ReportValueCell value={showData ? showData.point : ''} />;
        }
      },
      locked: true,
      width: column.width,
      headerCell: e => (
        <ReportHeaderCell
          title={column.title}
          index={index}
          length={staticColumns.length}
          e={column.field === '' ? e : undefined}
        />
      ),
    }),
  );
  useEffect(() => {
    getAllLookupData('TEAM_CATEGORY,SEASON');
  }, []);
  return (
    <StyledCommonPageWrapper>
      <TitleWrapper>
        <PageMainLabel>Rider Points Report</PageMainLabel>
        <CustomBreadCrumb
          preData={breadcrumbPreData}
          setPreData={setBreadcrumbPreData}
        />
      </TitleWrapper>
      <ButtonWrapper>
        <FilterWrapper>
          <AdminDropDown
            label="Season"
            placeholder="Select Season"
            data={getLookupArray('SEASON', lookUps)}
            onDataChange={handleSeasonChange}
            defaultValue={filterPayload.season}
            isLoader={false}
            className="season-drop-down"
          />
          <DropdownWrapper>
            <AdminDropDown
              label="Category"
              placeholder="Select Category"
              data={getLookupArray('TEAM_CATEGORY', lookUps)}
              onDataChange={handleCategoryChange}
              defaultValue={filterPayload.category}
              isLoader={false}
            />
            <AdminDropDown
              label="Division"
              placeholder="Select Division"
              data={divisonData}
              onDataChange={handleDivisionChange}
              defaultValue={filterPayload.division}
              isLoader={false}
              disabled={divisonData === undefined || divisonData?.length === 0}
            />
            <AdminDropDown
              label="Class"
              placeholder="Select Class"
              data={classData}
              onDataChange={handleClassChange}
              defaultValue={filterPayload.class}
              isLoader={false}
              disabled={classData === undefined || classData?.length === 0}
            />
          </DropdownWrapper>
          <ActionWrapper>
            <AdminCustomButton
              buttonType="cancel"
              onClick={handleCancelClick}
              disabled={
                filterPayload.category.id === '' ||
                filterPayload.season.id === ''
              }
              className="action-button"
            >
              Cancel
            </AdminCustomButton>
            <AdminCustomButton
              buttonType="save"
              onClick={handleSaveClick}
              disabled={
                filterPayload.category.id === '' ||
                filterPayload.season.id === '' ||
                filterPayload.class.id === '' ||
                filterPayload.division.id === ''
              }
              className="action-button"
            >
              Filter
            </AdminCustomButton>
          </ActionWrapper>
        </FilterWrapper>
        <AdminCustomButton
          buttonType="primary"
          isActive={false}
          className="fill-button"
          onClick={handleDownloadRiderpointReport}
          disabled={
            typeof riderReportData !== 'object' ||
            riderReportData === null ||
            riderReportData === undefined
          }
        >
          Download
        </AdminCustomButton>
      </ButtonWrapper>
      {riderReportData?.category !== undefined && (
        <TitleCardWrapper>
          <HeadingWrapper className="heading-wrapper">
            <PageMainLabel className="label-wrapper">
              {riderReportData?.category} School
            </PageMainLabel>
          </HeadingWrapper>
        </TitleCardWrapper>
      )}

      {loading ? (
        <TableSkeletonLoader rowContent={10} width={100} headingWidth={40} />
      ) : (
        <RiderReportsGrid
          data={riderReportData?.riders ?? []}
          columns={mappedColumns}
          onPageChange={event => {
            handlePageChange(event);
          }}
          pageSize={10}
          totalCount={riderReportData?.pager?.count}
          pageSkip={pageSkip}
        />
      )}
    </StyledCommonPageWrapper>
  );
};

export default RiderPointsReportsTable;
const StyledCommonPageWrapper = styled(CommonPageWrapper)`
  padding-bottom: 52px;

  @media (min-height: 100px) and (max-height: 560px) {
    padding-bottom: 0px;
  }
  height: 100vh;
  .k-virtual-content {
    max-height: calc(100vh - 363px);
    min-height: unset !important;
    height: 100% !important;

    @media (min-height: 100px) and (max-height: 560px) {
      max-height: calc(100vh - 311px);
    }
  }
  .top-header-style {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    div:nth-child(1) {
      width: 100%;
    }
    .dynamic-title {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .date {
      font-size: 13px !important;
      font-weight: 400 !important;
      padding-top: 7px !important;
    }
  }
  .k-grid-header {
    table {
      tr {
        th {
          &:has(.green-bg) {
            background-color: #8ee08e !important;
          }
          &:nth-child(n + 7) {
            position: unset !important;
            border-right: unset !important;
            border-left: unset !important;
            .base-line {
              text-align: center;
            }
            .top-bottom {
              justify-content: center;
            }
          }
        }
      }
    }
  }
  .k-grid-container {
    table {
      tr td:nth-child(n + 7) {
        position: unset !important;
        border-right: unset !important;
        border-left: unset !important;
        > div {
          text-align: center;
        }
      }
    }
  }
  .k-grid-content tr {
    .k-grid-content-sticky {
      :hover {
        background: inherit !important;
      }
    }
    :hover {
      td {
        background: inherit !important;
      }
    }
  }
  .k-grid tbody > tr:not(.k-detail-row):hover,
  .k-grid tbody > tr:not(.k-detail-row).k-hover {
    background-color: #dddddd !important;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0 30px 0;
  .fill-button {
    margin-right: 0px;
  }
`;
const TitleCardWrapper = styled.div`
  margin-top: 5px;
  background-color: #e7ecff;
  .heading-wrapper {
    padding: 14px 0px 14px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    .label-wrapper {
      font-family: 'Inter';
      font-size: 14px;
      font-weight: 600;
      line-height: 14.52px;
      color: #000000;
      margin: unset !important;
    }
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  .fill-button {
    button {
      margin-top: 5px;
      height: 31px;
    }
    &:has(.k-disabled) {
      cursor: not-allowed;
    }
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 0px 20px 0px;
  .k-input-inner {
    padding: 0 5px !important;
  }
  .k-input-value-text {
    margin: 2px 4px 2px 4px;
    font-size: 12px !important;
    letter-spacing: 0px;
    color: #1e1e1e;
    text-align: left;
  }
  .season-drop-down {
    &.SHdropdown {
      width: 19%;
      margin-right: 15px;
      .k-dropdownlist {
        max-height: 31px;
        border-radius: 8px !important;
      }
      @media (min-width: 100px) and (max-width: 1366px) {
        width: 21%;
      }
    }
  }
`;
const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 60%;
  gap: 15px;
  .SHdropdown {
    width: 33.33%;
    .k-dropdownlist {
      max-height: 31px;
      border-radius: 8px !important;
    }
  }
  .k-input-inner {
    padding: 0 5px !important;
  }
  .k-input-value-text {
    margin: 2px 4px 2px 4px;
    font-size: 12px !important;
    letter-spacing: 0px;
    color: #1e1e1e;
    text-align: left;
  }
`;
const ActionWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: start;
  width: 40%;
  padding-top: 25px;
  padding-left: 15px;
  .k-button {
    height: 31px !important;
    span {
      margin: 0px 15px;
    }
  }
  .action-button:has(.k-disabled) {
    cursor: not-allowed;
  }
`;
