import { useState } from 'react';
import useApi from 'services/mavapi/useApi';
import {
  GeneralLookup,
  GeneralLookupWrapper,
  LookupService,
} from 'services/openapi';

const useCommonLookUpDatas = () => {
  const [loading, setLoading] = useState(false);
  const [, setError] = useState<unknown | null>();
  const { error: apiError, handleRequest } = useApi();
  const [lookUps, setLookUps] = useState<Array<GeneralLookupWrapper>>([]);
  const [showLookUps, setShowLookUps] = useState<Array<GeneralLookup>>([]);
  const [teamLookUps, setTeamLookUps] = useState<Array<GeneralLookup>>([]);

  //API Services
  const {
    lookupGetAllLookups,
    lookupGetShowsLookupData,
    lookupGetTeamsLookupData,
  } = LookupService;
  //call lookups
  const getAllLookupData = (names?: string) => {
    setLoading(true);
    handleRequest(lookupGetAllLookups(names))
      .then(data => {
        if (data) setLookUps(data);
        setLoading(false);
      })

      .catch(() => {
        setLoading(false);
        setError(apiError);
      });
  };
  const getShowLookupWithSeasinId = (seasonId?: string) => {
    setLoading(true);
    handleRequest(lookupGetShowsLookupData(seasonId ?? ''))
      .then(data => {
        if (data) setShowLookUps(data);
        setLoading(false);
      })

      .catch(() => {
        setLoading(false);
        setError(apiError);
      });
  };
  const getTeamLookupWithSeasonId = (seasonId?: string) => {
    setLoading(true);
    handleRequest(lookupGetTeamsLookupData(seasonId ?? ''))
      .then(data => {
        if (data) setTeamLookUps(data);
        setLoading(false);
      })

      .catch(() => {
        setLoading(false);
        setError(apiError);
      });
  };
  return {
    loading,
    setLoading,
    lookUps,
    setLookUps,
    getAllLookupData,
    showLookUps,
    getShowLookupWithSeasinId,
    getTeamLookupWithSeasonId,
    teamLookUps,
  };
};

export default useCommonLookUpDatas;
