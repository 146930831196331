import { GridCellProps } from '@progress/kendo-react-grid';
import styled from 'styled-components';
type Props = {
  className?: string;
  e: GridCellProps;
};
const UserTypeCell = ({ className, e }: Props) => {
  const data = e.dataItem;
  let userType = '';

  if (data?.userTypes.includes("SuperAdmin")) {
    userType = "Super Admin";
  } else if (data?.userTypes.includes("DataEntryUser")) {
    userType = "Data Entry User";
  } else if (data?.userTypes.includes("TeamUser")) {
    userType = "Team User";
  }
  return (
    <td className={className ?? ''}>
       <Wrapper>{userType}</Wrapper>
    </td>
  );
};
export default UserTypeCell;
const Wrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  min-width: 150px;
  max-width: 200px;
  color: #475467;
`;
